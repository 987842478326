const initialState = {}

const reducers = {}

reducers['RECEIVE_GROUPING_RESULT'] = (state, action) => {
  return { ...state, [action.faq_id]: action.groups }
}

reducers['CLEAR_GROUPING_RESULT'] = (state, action) => {
  return { ...state, [action.faq_id]: [] }
}

const reducer = (state = initialState, action) => {
  if (reducers[action.type]) return reducers[action.type](state, action)
  return state
}

export default reducer
