import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Stickyfill from 'stickyfilljs'

import NoticeItem from './NoticeItem'

class Notices extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    notices: PropTypes.array,
    onRemove: PropTypes.func.isRequired,
  }

  static defaultProps = {
    notices: [],
  }

  componentDidUpdate(prevProps) {
    //  For IE11/Edge compatibility
    if (prevProps.notices.length === this.props.notices.length) return
    Stickyfill.refreshAll()
  }

  render() {
    const { notices, onRemove } = this.props
    const list = notices.map(notice => <NoticeItem key={notice.id} onRemove={onRemove} {...notice} />)

    return <ul className="notices dm-notices">{list}</ul>
  }
}

export default Notices
