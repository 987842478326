import lodash from 'lodash'
import moment from 'moment-timezone'
import Papa from 'papaparse'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'
import { Field } from 'redux-form'
import classnames from 'classnames'

import { SelectField } from '../../components/common/fields/FormFields'
import Loader from '../../components/common/Loader'
import Tooltip from '../../components/common/Tooltip'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import { downloadBlob } from '../../helpers/download'
import { getCredentials } from '../../helpers/sessionHelper'

export class PredictionFailureLogs extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    application: PropTypes.object,
    botId: PropTypes.number,
    intentType: PropTypes.string,
    intents: PropTypes.array,
    faqs: PropTypes.array,
    selectedFaqId: PropTypes.number,
    predictionFailureLogs: PropTypes.array,
    maxIntents: PropTypes.number,
    isFetching: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    onRefresh: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleAddNewIntent: PropTypes.func,
    handleInsertQueryToLinkedIntent: PropTypes.func,
    handleInsertQueryToSelectedIntent: PropTypes.func,
    handleAddLearningExclusionWord: PropTypes.func,
    handleExclusionsOpen: PropTypes.func,
    fields: PropTypes.object,
  }

  ellipse = (text, limit) => {
    if (!text) return text
    if (text.length <= limit) return text

    return text.substring(0, limit) + '…'
  }

  renderTableHeader = () => {
    const { t } = this.context
    const { intentType } = this.props
    const tableHeader = (
      <thead>
        <tr key={'headerRow1'}>
          <th rowSpan={2} key={'timestampHeader'}>
            {t('analytics.predictionFailureLogs.timestamp')}
          </th>
          <th rowSpan={2} key={'queryHeader'} width={'30%'}>
            {t('analytics.predictionFailureLogs.query')}
            <Tooltip name={'analytics.tooltip.predictionFailureLogs.query'} />
          </th>
          <th rowSpan={2} key={'resultHeader'}>
            {t('analytics.predictionFailureLogs.result')}
            <Tooltip name={'analytics.tooltip.predictionFailureLogs.result'} />
          </th>
          <th colSpan={3} key={'candidateHeader'}>
            {t(`analytics.predictionFailureLogs.candidate.${intentType}`)}
            <Tooltip name={'analytics.tooltip.predictionFailureLogs.candidate'} direction={'left'} />
          </th>
        </tr>
        <tr key={'headerRow2'}>
          <th key={'intentHeader'} width={'25%'}>
            {t(`analytics.predictionFailureLogs.intent.${intentType}`)}
          </th>
          <th key={'confidenceHeader'}>{t('analytics.predictionFailureLogs.confidence')}</th>
          <th key={'addQueryHeader'}>
            {t(`analytics.predictionFailureLogs.addQueryHeader.${intentType}`)}
            <Tooltip name={'analytics.tooltip.predictionFailureLogs.addQueryHeader'} direction={'left'} />
          </th>
        </tr>
      </thead>
    )
    return tableHeader
  }

  renderTableBody = () => {
    const { timezone } = getCredentials(this.context)
    const { predictionFailureLogs } = this.props
    if (!predictionFailureLogs) {
      return <tbody />
    }

    const tableRows = predictionFailureLogs
      .map((failureLog, _index) => {
        const { t } = this.context
        const { message_uuid, timestamp, query } = failureLog
        const predictedIntents = failureLog.intents
        const localTime = moment.tz(timestamp, timezone).format('YYYY/MM/DD HH:mm:ss')
        const rowSpan = Math.min(predictedIntents.length || 1, 3) + 2 // top 3 intents + select + createNew
        const topThreeIntentRows = this.renderTopThreeIntentRows(failureLog)

        let tableItemRows = [
          <tr key={`${message_uuid}.row1`}>
            <td rowSpan={rowSpan}>{localTime}</td>
            <td rowSpan={rowSpan}>{this.ellipse(query, 280)}</td>
            <td rowSpan={rowSpan}>{this.renderClassificationResultSentence(failureLog)}</td>

            {topThreeIntentRows.length > 0 && topThreeIntentRows[0]}
            {topThreeIntentRows.length === 0 && (
              <td colSpan="3">
                <span>{t('analytics.predictionFailureLogs.noCandidates')}</span>
              </td>
            )}
          </tr>,
        ]
        if (topThreeIntentRows.length > 1) {
          tableItemRows.push(<tr key={`${message_uuid}.row2`}>{topThreeIntentRows[1]}</tr>)
        }
        if (topThreeIntentRows.length > 2) {
          tableItemRows.push(<tr key={`${message_uuid}.row3`}>{topThreeIntentRows[2]}</tr>)
        }
        tableItemRows.push(this.renderSelectIntentRow(failureLog))
        tableItemRows.push(this.renderNewIntentRow(failureLog))
        return tableItemRows
      })
      .filter(tag => tag !== undefined)
    const tableBody = <tbody>{lodash.flatten(tableRows)}</tbody>
    return tableBody
  }

  renderClassificationResultSentence = failureLog => {
    const { t } = this.context
    const { intents, intentType, botId, selectedFaqId } = this.props

    const intentId = failureLog.selected_intent
    const intentItem = intentId ? lodash.find(intents, { id: intentId }) : null

    if (lodash.includes(['inaccurate', 'no_candidates'], failureLog.status)) {
      return <span>{t('analytics.predictionFailureLogs.noCandidates')}</span>
    } else if (failureLog.status === 'not_included') {
      return (
        <span>
          {t('analytics.predictionFailureLogs.multipleCandidates')}
          <br />
          {t(`analytics.predictionFailureLogs.chosenCandidates.${intentType}`)}
          {t('analytics.predictionFailureLogs.chosenCandidates.notIncluded')}
        </span>
      )
    } else if (failureLog.status === 'choose_another') {
      if (!intentItem) {
        return (
          <span>
            {t('analytics.predictionFailureLogs.multipleCandidates')}
            <br />
            {t(`analytics.predictionFailureLogs.chosenCandidates.${intentType}`)}
            {t('analytics.predictionFailureLogs.chosenCandidates.deleted')}
          </span>
        )
      }

      const linkTo =
        intentType === 'topic'
          ? `/bots/${botId}/topics/${intentId}`
          : `/bots/${botId}/faqs/${selectedFaqId}/items/${intentId}`
      return (
        <span>
          {t('analytics.predictionFailureLogs.multipleCandidates')}
          <br />
          {t(`analytics.predictionFailureLogs.chosenCandidates.${intentType}`)}
          <Link to={linkTo}>
            <b>{`${intentItem.name}`}</b>
          </Link>
        </span>
      )
    }
  }

  renderTopThreeIntentRows = failureLog => {
    const { t } = this.context
    const {
      botId,
      intentType,
      intents,
      selectedFaqId,
      isFetching,
      handleInsertQueryToLinkedIntent,
    } = this.props
    const { message_uuid, query } = failureLog
    const predictedIntents = failureLog.intents
    // top 3 intent rows
    const topThreeIntents = predictedIntents.slice(0, 3)
    const topThreeIntentRows = topThreeIntents.map((intent, index) => {
      const score = intent.score.toFixed(3)
      const intentId = intent.intent
      const intentItem = lodash.find(intents, { id: intentId })
      let intentRow = []
      if (intentItem) {
        let linkTo
        if (intentType === 'faq') {
          linkTo = `/bots/${botId}/faqs/${selectedFaqId}/items/${intentId}`
        } else {
          linkTo = `/bots/${botId}/topics/${intentId}`
        }
        intentRow = [
          <td key={`${message_uuid}.${index}.name`}>
            <Link to={linkTo}>{`${intentItem.name}`}</Link>
          </td>,
          <td key={`${message_uuid}.${index}.score`}>{score}</td>,
          <td key={`${message_uuid}.${index}.insert`}>
            <Link
              disabled={isFetching}
              onClick={() => handleInsertQueryToLinkedIntent(intentId, query)}
              role="button"
            >
              {t(`analytics.predictionFailureLogs.addQuery.${intentType}`)}
            </Link>
          </td>,
        ]
      } else {
        intentRow = [
          <td key={`${message_uuid}.${index}.name`}>
            {t('analytics.predictionFailureLogs.alreadyDeleted')}
          </td>,
          <td key={`${message_uuid}.${index}.score`}>{score}</td>,
          <td key={`${message_uuid}.${index}.insert`}>{'-'}</td>,
        ]
      }
      return intentRow
    })
    return topThreeIntentRows
  }

  renderSelectIntentRow = failureLog => {
    const { t } = this.context
    const { intents, intentType, isSubmitting, handleSubmit, handleInsertQueryToSelectedIntent } = this.props
    const { message_uuid, query } = failureLog

    // select intent row
    const selectIntentRow = (
      <tr key={`${message_uuid}.selectedIntent`}>
        <td colSpan={2}>
          <Field
            name={`selectedIntent.${message_uuid}`}
            items={intents}
            valueKey="id"
            displayKey="name"
            className="form-control dm-form-control"
            empty={true}
            component={SelectField}
            order="asc"
          />
        </td>
        <td>
          <Link
            type="submit"
            disabled={isSubmitting}
            onClick={handleSubmit(handleInsertQueryToSelectedIntent(message_uuid, query))}
          >
            {t(`analytics.predictionFailureLogs.addQuery.${intentType}`)}
          </Link>
        </td>
      </tr>
    )
    return selectIntentRow
  }

  renderNewIntentRow = failureLog => {
    const { t } = this.context
    const {
      intents,
      intentType,
      maxIntents,
      isSubmitting,
      handleSubmit,
      handleAddNewIntent,
      handleAddLearningExclusionWord,
    } = this.props
    const { message_uuid, query } = failureLog

    // new intent row
    const buttonClasses = ['dm-btn', 'btn', 'btn-icon-plus', 'mini']
    if (maxIntents != null && intents.length >= maxIntents) {
      buttonClasses.push('btn-alert')
    } else {
      buttonClasses.push('btn-primary')
    }

    const createNewIntentRow = (
      <tr key={`${message_uuid}.createNewIntent`}>
        <td colSpan={3}>
          <Link className={classnames(buttonClasses)} onClick={() => handleAddNewIntent(query)}>
            <span>{t(`analytics.predictionFailureLogs.addQueryAsNew.${intentType}`)}</span>
          </Link>
          <Link
            className="dm-btn btn btn-primary btn-icon-minus mini"
            type="submit"
            disabled={isSubmitting}
            onClick={handleSubmit(handleAddLearningExclusionWord(query))}
          >
            <span>{t(`analytics.predictionFailureLogs.addExclusionWordAsNew.${intentType}`)}</span>
          </Link>
        </td>
      </tr>
    )
    return createNewIntentRow
  }

  renderTableCaption = () => {
    const { t } = this.context
    const { onRefresh, handleExclusionsOpen } = this.props
    return (
      <caption>
        <div>
          <span className="dm-title-mini">{t('analytics.predictionFailureLogs.title')}</span>
          <Tooltip name={'analytics.tooltip.predictionFailureLogs.title'} />
          <Link onClick={() => handleExclusionsOpen()} className="ml-2" role="button">
            {t('analytics.learningExclusionWord.title')}
          </Link>
        </div>
        {onRefresh && (
          <div className="pull-right dm-btn btn btn-default btn-icon-refresh" onClick={onRefresh} />
        )}
      </caption>
    )
  }

  renderSelectIntentType = () => {
    const { t } = this.context
    const { faqs, intentType } = this.props
    return (
      <div className="form-group dm-form-group">
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <LabelWithTooltip htmlFor="intent_type" name="analytics.predictionFailureLogs.intentType" />
              <Field
                name="intent_type"
                className="form-control dm-form-control"
                items={[
                  { label: t('analytics.predictionFailureLogs.topic'), value: 'topic' },
                  { label: t('analytics.predictionFailureLogs.faq'), value: 'faq' },
                ]}
                valueKey="value"
                displayKey="label"
                empty={false}
                component={SelectField}
              />
            </div>
          </div>
          {intentType === 'faq' && (
            <div className="col-sm-6">
              <div className="form-group">
                <LabelWithTooltip htmlFor="faq_id" name="analytics.predictionFailureLogs.targetFaq" />
                <Field
                  name="faq_id"
                  className="form-control dm-form-control"
                  items={faqs}
                  valueKey="id"
                  displayKey="name"
                  empty={true}
                  component={SelectField}
                  order="asc"
                />
              </div>
            </div>
          )}
        </div>
        {this.renderLogDownloadButton()}
      </div>
    )
  }

  renderLogDownloadButton = () => {
    const { t } = this.context
    const { predictionFailureLogs } = this.props
    const isEmpty = lodash.isEmpty(predictionFailureLogs)
    return (
      <div className="mt-2">
        <div className="dm-note">{t('analytics.predictionFailureLogs.logDownloadNote')}</div>
        <button
          type="button"
          className="btn btn-primary dm-btn"
          onClick={this.downloadFile}
          disabled={isEmpty}
        >
          {t('common.download')}
        </button>
      </div>
    )
  }

  downloadFile = () => {
    const { t } = this.context
    const { timezone } = getCredentials(this.context)
    const { application, faqs, selectedFaqId, intents, intentType, predictionFailureLogs } = this.props

    const headerFields = [
      'timestamp',
      'query',
      'status',
      'candidate1_name',
      'candidate1_score',
      'candidate2_name',
      'candidate2_score',
      'candidate3_name',
      'candidate3_score',
    ]

    const classificationResult = {
      inaccurate: t('analytics.predictionFailureLogs.noCandidates'),
      no_candidates: t('analytics.predictionFailureLogs.noCandidates'),
      not_included:
        t('analytics.predictionFailureLogs.multipleCandidates') +
        '/' +
        t(`analytics.predictionFailureLogs.chosenCandidates.${intentType}`) +
        t('analytics.predictionFailureLogs.chosenCandidates.notIncluded'),
      choose_another:
        t('analytics.predictionFailureLogs.multipleCandidates') +
        '/' +
        t(`analytics.predictionFailureLogs.chosenCandidates.${intentType}`),
    }

    const intentNames = lodash.reduce(
      intents,
      (s, x) => {
        s[x.id] = x.name
        return s
      },
      {}
    )

    const rows = []
    predictionFailureLogs.forEach(failureLog => {
      const { timestamp, query, status, selected_intent } = failureLog
      const localTime = moment.tz(timestamp, timezone).format('YYYY/MM/DD HH:mm:ss')
      const row = { timestamp: localTime, query: query }

      if (status === 'choose_another') {
        if (intentNames[selected_intent]) {
          row['status'] = classificationResult[status] + intentNames[selected_intent]
        } else {
          row['status'] =
            classificationResult[status] + t('analytics.predictionFailureLogs.chosenCandidates.deleted')
        }
      } else {
        row['status'] = classificationResult[status]
      }

      const topThreeIntents = failureLog.intents.slice(0, 3)
      topThreeIntents.forEach((intent, index) => {
        if (intentNames[intent.intent]) {
          row[`candidate${index + 1}_name`] = intentNames[intent.intent]
        } else {
          row[`candidate${index + 1}_name`] = t('analytics.predictionFailureLogs.chosenCandidates.deleted')
        }
        row[`candidate${index + 1}_score`] = intent.score.toFixed(3)
      })
      rows.push(row)
    })
    const csv = Papa.unparse(rows, { columns: headerFields })

    const bom = new Uint8Array([0xef, 0xbb, 0xbf])
    const blob = new Blob([bom, csv], { type: 'text/csv' })
    const suffixFilename = 'prediction-failure-logs.csv'
    const selectedFaq = lodash.find(faqs, ['id', selectedFaqId])
    if (intentType === 'topic') {
      downloadBlob(blob, `${application.name}-${t('topic.title')}-${suffixFilename}`)
    } else {
      downloadBlob(blob, `${application.name}-${selectedFaq.name}-${suffixFilename}`)
    }
  }

  render() {
    const { isFetching, isSubmitting, handleSubmit } = this.props
    return (
      <div className="dm-failure">
        <Loader loaded={!isFetching && !isSubmitting} type="show">
          <form className="text-left" onSubmit={handleSubmit}>
            {this.renderSelectIntentType()}
            <table className="table table-bordered refreshable dm-table">
              {this.renderTableCaption()}
              {this.renderTableHeader()}
              {this.renderTableBody()}
            </table>
          </form>
        </Loader>
      </div>
    )
  }
}

export default PredictionFailureLogs
