import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class ErrorComponent extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  render() {
    const { t } = this.context
    return (
      <div>
        <h2>{t('error.unexpectedError.title')}</h2>
        <hr />
        <p dangerouslySetInnerHTML={{ __html: t('error.unexpectedError.description') }} />
      </div>
    )
  }
}

export default ErrorComponent
