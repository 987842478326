import lodash from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { default as ActivityUsageGraph } from '../../components/graph/ActivityGraph'
import DataBindingTable from '../../components/common/DataBindingTable'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import { fetchDailyActivities, fetchWeeklyActivities, fetchMonthlyActivities } from '../../actions/statistics'
import { updateTableState } from '../../actions/table'
import { isFetching } from '../../helpers/selector'

class ActivityGraph extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    application: PropTypes.object.isRequired,
    unitActivities: PropTypes.array,
    isFetching: PropTypes.bool,
    tableState: PropTypes.object,
  }

  constructor() {
    super()
    this.state = { period: 'day' }
  }

  componentDidMount() {
    this.fetchActivities('day')
  }

  handleRefreshClick = () => {
    const { period } = this.state
    this.fetchActivities(period)
  }

  onChangeCountUnit = e => {
    const period = e.nativeEvent.target.value
    this.setState({ period: period })
    this.fetchActivities(period)
  }

  fetchActivities = period => {
    const state = this.context.store.getState()
    const { dispatch, application } = this.props
    const now = moment()

    if (period === 'week') {
      const start = now.clone().startOf('week').isoWeekday(0).subtract(51, 'weeks').format('YYYY-MM-DD')
      const end = now.clone().endOf('week').isoWeekday(0).subtract(0, 'weeks').format('YYYY-MM-DD')
      dispatch(fetchWeeklyActivities(state.session.token, application.id, start, end))
    } else if (period === 'month') {
      const start = now.clone().startOf('month').subtract(11, 'months').format('YYYY-MM-DD')
      const end = now.clone().subtract(0, 'months').endOf('month').format('YYYY-MM-DD')
      dispatch(fetchMonthlyActivities(state.session.token, application.id, start, end))
    } else {
      const start = now.clone().subtract(60, 'days').format('YYYY-MM-DD')
      const end = now.clone().subtract(1, 'days').format('YYYY-MM-DD')
      dispatch(fetchDailyActivities(state.session.token, application.id, start, end))
    }
  }

  updateTableState = (path, tableName, tableState) => {
    const { dispatch } = this.props
    dispatch(updateTableState('activitytable', tableName, tableState))
  }

  render() {
    const { period } = this.state
    const { t } = this.context
    const { unitActivities, isFetching, tableState } = this.props // TODO: support Tooltip on table header
    const usages = unitActivities.filter(unit => unit && unit.period === period)
    const applicationActivities = usages.length === 1 ? usages[0] : {}
    const note = {
      day: t('analytics.graph.activityGraph.note.daily', { days: 60 }),
      week: t('analytics.graph.activityGraph.note.weekly'),
      month: t('analytics.graph.activityGraph.note.monthly'),
    }

    let columns = [
      {
        Header: t(`analytics.table.${period === 'week' ? 'durationWeek' : 'duration'}`),
        id: 'period',
        accessor: 'period',
        Cell: props => (
          <div>
            {' '}
            {period === 'month'
              ? moment(props.value).format(t('analytics.table.periodFormatMon'))
              : moment(props.value).format(t('analytics.table.periodFormat'))}{' '}
          </div>
        ),
      },
      {
        Header: t('analytics.table.activeUsers'),
        id: 'active_users',
        accessor: 'active_users',
        style: { textAlign: 'right' },
        Cell: props => <div> {props.value.toLocaleString()} </div>,
      },
      {
        Header: t('analytics.table.userMessages'),
        id: 'user',
        accessor: 'user',
        style: { textAlign: 'right' },
        Cell: props => <div> {props.value.toLocaleString()} </div>,
      },
    ]

    return (
      <div>
        <div className="form-group">
          <div className="form-group col-md-12">
            <LabelWithTooltip
              className="dm-title-mini graph-title"
              name="analytics.graph.activityGraph.title"
            />
            <div className="form-group radio-inline">
              <input
                key="daily"
                id="daily"
                name="applicationUsage"
                type="radio"
                value="day"
                defaultChecked
                className="form-control"
                onChange={this.onChangeCountUnit}
              />
              <LabelWithTooltip
                htmlFor="daily"
                className="dm-radio"
                name="analytics.usage.count.daily"
                direction="left"
              />
            </div>
            <div className="form-group radio-inline">
              <input
                key="weekly"
                id="weekly"
                name="applicationUsage"
                type="radio"
                value="week"
                className="form-control"
                onChange={this.onChangeCountUnit}
              />
              <LabelWithTooltip
                htmlFor="weekly"
                className="dm-radio"
                name="analytics.usage.count.weekly"
                direction="left"
              />
            </div>
            <div className="form-group radio-inline">
              <input
                key="monthly"
                id="monthly"
                name="applicationUsage"
                type="radio"
                value="month"
                className="form-control"
                onChange={this.onChangeCountUnit}
              />
              <LabelWithTooltip
                htmlFor="monthly"
                className="dm-radio"
                name="analytics.usage.count.monthly"
                direction="left"
              />
            </div>
            <div className="ml-3 mb-3">
              <ActivityUsageGraph statistics={applicationActivities} height={200} />
              <div className="dm-note">{note[period]}</div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="ml-3 mb-3">
              <DataBindingTable
                title={t('analytics.table.title')}
                items={applicationActivities ? applicationActivities.messages : []}
                columns={columns}
                isFetching={isFetching}
                tableState={tableState}
                updateTableState={this.updateTableState}
                onRefresh={this.handleRefreshClick}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const dailyActivities = lodash.find(state.statistics.dailyActivities.applications, {
    id: props.application.id,
  })
  const weeklyActivities = lodash.find(state.statistics.weeklyActivities.applications, {
    id: props.application.id,
  })
  const monthlyActivities = lodash.find(state.statistics.monthlyActivities.applications, {
    id: props.application.id,
  })
  const unitActivities = [dailyActivities, weeklyActivities, monthlyActivities]

  return {
    ...props,
    unitActivities: unitActivities,
    tableState: state.table['activitytable'],
    isFetching: isFetching(state),
  }
}

export default connect(mapStateToProps)(ActivityGraph)
