import React from 'react'

import AbstractAction from './AbstractAction'

export class RerunScenarioAction extends AbstractAction {
  getTitle() {
    return this.context.t('topic.actionList.rerun_scenario.title')
  }
  getType() {
    return 'stop'
  }

  renderBody() {
    const { t } = this.context

    return <div className="rerun_scenario">{t('topic.actionList.rerun_scenario.noParameter')}</div>
  }
}

export default RerunScenarioAction
