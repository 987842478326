import lodash from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'

import DataBindingTable from '../../components/common/DataBindingTable'
import { fetchApplications } from '../../actions/application'
import { fetchMonthlyActivities } from '../../actions/statistics'
import { updateTableState } from '../../actions/table'
import { isFetching } from '../../helpers/selector'

class ApplicationUsageIndex extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    applications: PropTypes.array.isRequired,
    monthlyActivities: PropTypes.object,
    isFetching: PropTypes.bool,
    tableState: PropTypes.object,
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    dispatch(fetchApplications(state.session.token))

    const now = moment()
    const start = now.clone().startOf('month').format('YYYY-MM-DD')
    const end = now.clone().endOf('month').format('YYYY-MM-DD')
    dispatch(fetchMonthlyActivities(state.session.token, null, start, end))
  }

  handleRefreshClick = () => {
    const state = this.context.store.getState()
    this.props.dispatch(fetchApplications(state.session.token))
  }

  updateTableState = (path, tableName, tableState) => {
    const { dispatch } = this.props
    dispatch(updateTableState(path, tableName, tableState))
  }

  render() {
    const { t } = this.context
    const { applications, monthlyActivities, isFetching, tableState } = this.props
    // TODO: support Tooltip on table header
    let columns = [
      {
        Header: t('analytics.usageList.name'),
        id: 'name',
        accessor: 'name',
        minWidth: 108,
      },
      {
        Header: t('analytics.usageList.bots'),
        id: 'botName',
        accessor: app => app.latest_bot.name,
      },
      {
        Header: t('analytics.usageList.platform'),
        id: 'platform',
        accessor: app => t(`common.platform.${app.platform}`),
      },
      {
        Header: t('analytics.usageList.userMessages'),
        id: 'userMessages',
        style: {
          textAlign: 'right',
          paddingRight: '20px',
        },
        accessor: app => {
          const monthlyActivity = lodash.find(monthlyActivities.applications, { id: app.id })
          if (!monthlyActivity) return undefined
          const thisMonth = moment().format('YYYY-MM')
          const message = lodash.find(monthlyActivity.messages, { period: thisMonth })
          if (!message) return undefined
          return Number(message.user)
        },
        Cell: props => <div> {props.value && props.value.toLocaleString()} </div>,
      },
      {
        Header: t('analytics.usageList.usage'),
        id: 'logsPage',
        style: { textAlign: 'center' },
        accessor: 'id',
        Cell: props => (
          <Link className="btn btn-primary dm-btn mini" to={`/analytics/${props.value}/usage`}>
            {t('analytics.usageList.linkToUsage')}
          </Link>
        ),
        sortable: false,
      },
    ]

    return (
      <div>
        <DataBindingTable
          title={t('analytics.usageList.title')}
          titleTooltip="analytics.tooltip.usageList.title"
          items={applications}
          columns={columns}
          isFetching={isFetching}
          tableState={tableState}
          updateTableState={this.updateTableState}
          onRefresh={this.handleRefreshClick}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const ids = state.pagination.applications.ids || []
  const applications = lodash.filter(
    ids.map(id => state.entities.applications[id]),
    'is_published'
  )
  return {
    applications: applications,
    monthlyActivities: state.statistics.monthlyActivities,
    isFetching: isFetching(state),
    tableState: state.table[window.location.pathname],
  }
}

export default connect(mapStateToProps)(ApplicationUsageIndex)
