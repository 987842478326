import lodash from 'lodash'
import { find, map, addNodeUnderParent, changeNodeAtPath } from 'react-sortable-tree'

const initialState = {
  treeData: [],
}

const getNodeKey = ({ node }) => `${node.type}:${node.id}`

const reducers = {}

const generateTreeData = (domains, topics, currentDomain) => {
  const relatedDomains = lodash.filter(domains, { parent_id: currentDomain.id })
  const relatedTopics = lodash.filter(topics, { domain_id: currentDomain.id })
  const relatedItems = lodash.sortBy([...relatedDomains, ...relatedTopics], 'order')
  const treeData = []
  relatedItems.forEach(item => {
    if (item.parent_id) {
      treeData.push({
        expanded: true,
        type: 'domain',
        id: item.id,
        title: item.name,
        children: generateTreeData(domains, topics, item),
      })
    } else {
      treeData.push({
        type: 'scenario',
        with_action: item.action_count > 0,
        is_draft: item.is_draft,
        id: item.id,
        title: item.name,
      })
    }
  })
  return treeData
}

reducers['BUILD_TREE'] = (state, { domains, topics }) => {
  const rootDomain = lodash.find(domains, { parent_id: null })
  if (!rootDomain) return state

  const treeData = generateTreeData(domains, topics, rootDomain)
  return { ...state, treeData: treeData }
}

reducers['CLEAR_TREE'] = state => {
  return { ...state, treeData: [] }
}

reducers['UPDATE_TREE'] = (state, { treeData }) => {
  const newTreeData = map({
    treeData,
    getNodeKey,
    callback: ({ node }) => {
      node.children = lodash.sortBy(node.children, ['order'])
      return node
    },
  })
  return { ...state, treeData: newTreeData }
}

reducers['START_RENAMING'] = (state, { path }) => {
  const newTreeData = changeNodeAtPath({
    treeData: state.treeData,
    path,
    newNode: ({ node }) => ({ ...node, renaming: true }),
    getNodeKey,
  })
  return { ...state, treeData: newTreeData }
}

reducers['CANCEL_EDITING'] = state => {
  const { matches } = find({
    treeData: state.treeData,
    getNodeKey,
    searchMethod: ({ node }) => node.creating || node.renaming,
  })

  let newTreeData = state.treeData
  matches.forEach(({ path }) => {
    newTreeData = changeNodeAtPath({
      treeData: newTreeData,
      path,
      newNode: ({ node }) => {
        if (node.creating) return null
        if (node.renaming) return { ...node, renaming: false }
        return node
      },
      getNodeKey,
    })
  })

  return { ...state, treeData: newTreeData }
}

reducers['FINISH_EDITING'] = (state, { path, newName }) => {
  const newTreeData = changeNodeAtPath({
    treeData: state.treeData,
    path,
    newNode: ({ node }) => ({ ...node, title: newName, renaming: false, creating: false }),
    getNodeKey,
  })
  return { ...state, treeData: newTreeData }
}

reducers['ADD_SCENARIO'] = (state, { node }) => {
  const parentKey = node ? `${node.type}:${node.id}` : null
  const { treeData } = addNodeUnderParent({
    treeData: state.treeData,
    parentKey,
    newNode: { type: 'scenario', renaming: true, creating: true, is_draft: true },
    getNodeKey,
  })
  return { ...state, treeData }
}

reducers['ADD_DOMAIN'] = (state, { node }) => {
  const parentKey = node ? `${node.type}:${node.id}` : null
  const { treeData } = addNodeUnderParent({
    treeData: state.treeData,
    parentKey,
    newNode: { type: 'domain', renaming: true, creating: true },
    getNodeKey,
  })
  return { ...state, treeData }
}

const reducer = (state = initialState, action) => {
  if (reducers[action.type]) return reducers[action.type](state, action)
  return state
}

export default reducer
