import lodash from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'
import DataBindingTable from '../common/DataBindingTable'
import { getCredentials } from '../../helpers/sessionHelper'

export class TopicList extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    bot: PropTypes.object.isRequired,
    topics: PropTypes.array.isRequired,
    tableState: PropTypes.object,
    updateTableState: PropTypes.func.isRequired,
    handleAdd: PropTypes.func.isRequired,
    topicsStyle: PropTypes.string.isRequired,
    maxScenarios: PropTypes.number.isRequired,
    updateTopicsStyle: PropTypes.func.isRequired,
  }

  static defaultProps = {
    topics: [],
  }

  render = () => {
    const { t } = this.context
    const {
      bot,
      topics,
      topicsStyle,
      updateTopicsStyle,
      tableState,
      updateTableState,
      handleAdd,
      maxScenarios,
    } = this.props
    const { timezone } = getCredentials(this.context)
    const remaining = maxScenarios - topics.length
    const buttonType = remaining <= 0 ? 'btn-alert' : 'btn-primary'
    const columns = [
      {
        Header: t('topic.name'),
        id: 'name',
        accessor: 'name',
        Cell: props => (
          <Link className="topic-item" to={`/bots/${bot.id}/topics/${props.original.id}`}>
            {props.value}
          </Link>
        ),
        getFooterProps: () => ({ className: 'footer-all' }),
        Footer: (
          <div>
            <div className="pull-left">
              <Link
                className={`dm-btn btn ${buttonType} btn-icon-plus mini`}
                onClick={() => handleAdd('scenario')}
              >
                <span>{t('bot.table.addScenario')}</span>
              </Link>
              <Link className="dm-btn btn btn-primary btn-icon-upload mini" to={`/bots/${bot.id}/import`}>
                <span>{t('common.import')}</span>
              </Link>
              <label>{t('bot.tree.displayStyle')}</label>
              <select value={topicsStyle} onChange={e => updateTopicsStyle(e.target.value)}>
                <option value="table">{t('bot.tree.displayStyles.table')}</option>
                <option value="tree">{t('bot.tree.displayStyles.tree')}</option>
              </select>
            </div>
            <div className="pull-right">
              {t('topic.remaining', { count: topics.length, total: maxScenarios })}
            </div>
          </div>
        ),
      },
      {
        Header: t('topic.scenarioMatching'),
        id: 'example',
        accessor: topic => {
          const example = lodash.first(topic.examples)
          const pattern = lodash.first(topic.patterns)
          if (example) {
            return example
          } else if (pattern) {
            const type = t(`topic.patterns.types.${pattern.type}`)
            return `[${type}] ${pattern.pattern}`
          } else {
            return ''
          }
        },
        getFooterProps: () => ({ className: 'footer-hide' }),
      },
      {
        Header: t('topic.domains'),
        id: 'domains',
        accessor: topic => lodash.map(topic.domains, 'name').join(' > '),
        getProps: (_state, rowInfo, _column, _instance) => ({ title: rowInfo && rowInfo.row.domains }),
        getFooterProps: () => ({ className: 'footer-hide' }),
      },
      {
        Header: t('common.draft'),
        id: 'is_draft',
        style: { textAlign: 'center', padding: '6px 5px' },
        accessor: 'is_draft',
        Cell: props => {
          if (!props.value) return <span />
          return <span className="label label-draft in-cell">{t('common.draft')}</span>
        },
        maxWidth: 80,
        filterMethod: (filter, row) => {
          if (filter.value === 'all') return true
          if (filter.value === 'normal' && !row.is_draft) return true
          if (filter.value === 'draft' && row.is_draft) return true
          return false
        },
        Filter: ({ filter, onChange }) => (
          <select
            className="filter"
            value={filter ? filter.value : 'all'}
            onChange={event => onChange(event.target.value)}
          >
            <option value="all">{t('topic.draftFilters.all')}</option>
            <option value="normal">{t('topic.draftFilters.normal')}</option>
            <option value="draft">{t('topic.draftFilters.draft')}</option>
          </select>
        ),
      },
      {
        Header: t('common.lastUpdatedBy'),
        id: 'lastUpdatedBy',
        accessor: topic => topic.last_updated_by.name || '',
        width: 160,
      },
      {
        Header: t('common.updatedAt'),
        id: 'updatedAt',
        accessor: topic =>
          topic.updated_at ? moment.tz(topic.updated_at, timezone).format('YYYY/MM/DD HH:mm') : '',
        width: 160,
        getFooterProps: () => ({ className: 'footer-hide' }),
        filterable: false,
      },
    ]

    return (
      <div>
        <DataBindingTable
          items={topics}
          columns={columns}
          tableName="TopicList"
          filterable={true}
          defaultSorted={[{ id: 'name', desc: false }]}
          tableState={tableState}
          updateTableState={updateTableState}
        />
      </div>
    )
  }
}

export default TopicList
