import PropTypes from 'prop-types'
import React, { Component } from 'react'

class NoticeItem extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    options: PropTypes.object,
    onRemove: PropTypes.func.isRequired,
  }

  onRemove = () => {
    this.props.onRemove(this.props.id)
  }

  render() {
    const { level, message, options } = this.props
    return (
      <li className={level}>
        <div className="container">
          <span className="icon" />
          <span>{message}</span>
          {options.linkText && (
            <span>
              <a href={options.linkTo}>{options.linkText}</a>
            </span>
          )}
          {options.removable && (
            <button className="dm-btn btn btn-danger btn-icon-delete" onClick={this.onRemove} />
          )}
        </div>
      </li>
    )
  }
}

export default NoticeItem
