import i18n from 'i18next'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes } from 'redux-form'
import { isEmail } from 'validator'

import { addNotice } from '../../actions/notice'
import { fetchUser, updateUser, sendMail, cancelMail, createUser, deleteUser } from '../../actions/user'
import { InputField, SelectField } from '../../components/common/fields/FormFields'
import { IconFieldWithDialog } from '../../components/common/IconFieldWithDialog'
import Loader from '../../components/common/Loader'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import { convertReminingTime } from '../../helpers/remainingTime'
import { isFetching } from '../../helpers/selector'
import cancelUrl from '../../helpers/cancelurl'

const validate = data => {
  const errors = {}
  if (!data.email) {
    errors.email = 'validate.required'
  } else if (!isEmail(data.email)) {
    errors.email = 'validate.invalidEmail'
  } else if (data.email.length > 255) {
    errors.email = { id: 'validate.exceededMaxLength', values: { length: 255 } }
  }
  if (!data.name) {
    errors.name = 'validate.required'
  } else if (data.name.length > 255) {
    errors.name = { id: 'validate.exceededMaxLength', values: { length: 255 } }
  }
  return errors
}

export class UserEdit extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    isFetching: PropTypes.bool,
    isSelfUpdate: PropTypes.bool,
    isMailSend: PropTypes.bool,
    user: PropTypes.object,
  }

  constructor() {
    super()
    this.state = {
      iconSrc: '',
      useDefaultIcon: false,
    }
  }

  componentDidMount = () => {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    if (this.props.params.id) {
      dispatch(fetchUser(state.session.token, this.props.params.id)).then(response => {
        const fetchedUser = response.entities.users[this.props.params.id]

        if (fetchedUser.icon_url === undefined || !fetchedUser.icon_url) {
          this.setState({ useDefaultIcon: true })
        } else {
          this.setState({ iconSrc: fetchedUser.icon_url })
        }
      })
    }
    cancelUrl.setRouteLeaveHook(this)
  }

  handleSaveToIcon = file => {
    const image_src = window.URL.createObjectURL(file)

    this.setState({ useDefaultIcon: false, iconSrc: image_src })
    this.props.change('upload_file', file)

    return true
  }

  handleSave = (data, dispatch) => {
    const { t } = this.context
    const session = this.context.store.getState().session
    const router = this.context.router

    i18n.changeLanguage(data.language)

    let user = {
      email: data.email,
      name: data.name,
    }

    if (!this.props.isSelfUpdate && session.role === 'owner') {
      user.role = data.role
    }

    // Convert to formData in case of Icon_file
    const convertFormdata = () => {
      const userFormData = new FormData()
      for (let key in user) {
        if (user.hasOwnProperty(key)) {
          if (user[key] === undefined) continue

          if (user[key] !== null && typeof user[key] === 'object') {
            userFormData.append(key, JSON.stringify(user[key]))
          } else {
            userFormData.append(key, user[key])
          }
        }
      }
      return userFormData
    }

    // Cannot change icon if selected user does not confirm yet.
    if (this.props.user.confirmed_at) {
      user.use_default_icon = this.state.useDefaultIcon
      if (!this.state.useDefaultIcon && data.upload_file) {
        user = convertFormdata()
        user.append('icon_file', data.upload_file)
      }
    }

    if (this.props.params.id) {
      user.id = data.id
      return dispatch(updateUser(session.token, user, session))
        .then(() => router.push({ pathname: `/users/${user.id}` }))
        .then(() => dispatch(addNotice('info', t('common.saveSuccessMessage'))))
        .then(() => {
          if (data.email && this.props.user.pending_email) {
            dispatch(addNotice('info', t('common.sendMailConfirmation')))
          }
        })
    } else {
      return dispatch(createUser(session.token, user))
        .then(() => router.push({ pathname: '/users', state: { ignoreBlocking: true } }))
        .then(() => dispatch(addNotice('info', t('common.sendInvitationMessage'))))
    }
  }

  handleMailSend = () => {
    const { t } = this.context
    const session = this.context.store.getState().session
    const { user, dispatch } = this.props

    return dispatch(sendMail(session.token, user.id)).then(() =>
      dispatch(addNotice('info', t('common.sendMailConfirmation')))
    )
  }

  handleMailCancel = () => {
    const { t } = this.context
    const session = this.context.store.getState().session
    const { user, dispatch } = this.props

    return dispatch(cancelMail(session.token, user.id)).then(() =>
      dispatch(addNotice('info', t('user.emailCancel')))
    )
  }

  handleDelete = () => {
    const { t } = this.context
    const { user } = this.props
    if (
      !window.confirm(
        t('common.deleteConfirmMessage', { type: t('user.title'), name: user.name || user.email })
      )
    )
      return
    const session = this.context.store.getState().session
    const router = this.context.router
    const { dispatch } = this.props
    const id = parseInt(this.props.params.id, 10)
    dispatch(deleteUser(session.token, id, session))
      .then(() => router.push({ pathname: '/users', state: { ignoreBlocking: true } }))
      .then(() => dispatch(addNotice('info', t('common.deleteSuccessMessage'))))
  }

  restoreDefaultIcon = () => {
    const { t } = this.context

    if (!window.confirm(t('user.restoreDefaultConfirm'))) return false

    this.setState({ iconSrc: '' })
    this.setState({ useDefaultIcon: true })
    this.props.change('upload_file', undefined)

    return true
  }

  renderEmailField = () => {
    const {
      user,
      isSelfUpdate,
      params: { id },
    } = this.props

    if (id && !user.email) {
      return (
        <Field
          type="email"
          name="pending_email"
          className="form-control dm-form-control"
          disabled={true}
          maxLength="255"
          component={InputField}
        />
      )
    } else {
      return (
        <Field
          type="email"
          name="email"
          className="form-control dm-form-control"
          disabled={this.props.params.id && !isSelfUpdate}
          maxLength="255"
          component={InputField}
        />
      )
    }
  }

  renderIconFieldNotes = () => {
    const { t } = this.context
    return (
      <ul>
        <li>{t('user.iconSettingDialog.authorizedFileFormat')}</li>
        <li>{t('user.iconSettingDialog.minimumImageSize')}</li>
        <li>{t('user.iconSettingDialog.maxFileSize')}</li>
      </ul>
    )
  }

  render() {
    const { t } = this.context
    const { user, isFetching, submitting, handleSubmit, isSelfUpdate, isMailSend } = this.props
    const { iconSrc } = this.state
    const roles = [
      { id: 'owner', name: t('common.roles.owner') },
      { id: 'writer', name: t('common.roles.writer') },
      { id: 'operator', name: t('common.roles.operator') },
      { id: 'readonly', name: t('common.roles.readonly') },
    ]
    const isNew = !this.props.params.id
    const isConfirmed = !!user.confirmed_at
    const roleSelectbox = (
      <div className="form-group">
        <LabelWithTooltip htmlFor="role" className="dm-title-mini" name="user.role" />
        <Field
          name="role"
          items={roles}
          valueKey="id"
          displayKey="name"
          className="form-control dm-form-control"
          disabled={isSelfUpdate}
          component={SelectField}
        />
      </div>
    )

    const authorizedIconFileFormats = ['image/png', 'image/jpeg', 'image/gif']

    const languages = [
      { code: 'ja', label: '日本語' },
      { code: 'en-US', label: 'English' },
      { code: 'zh-CN', label: '中文（简体）' },
    ]

    return (
      <div>
        <Loader loaded={!isFetching && !submitting} type="show">
          <form className="text-left col-md-9" onSubmit={handleSubmit(this.handleSave)}>
            <div className="form-group">
              <LabelWithTooltip htmlFor="email" className="dm-title-mini" name="user.email" />
              {this.renderEmailField()}
            </div>
            {isMailSend && isSelfUpdate && (
              <div className="form-group" key="pending_email">
                <LabelWithTooltip
                  htmlFor="pending_email"
                  className="dm-title-mini"
                  name="user.pending_email"
                />
                <div className="input-group">
                  <Field
                    type="email"
                    name="pending_email"
                    className="form-control dm-form-control"
                    disabled={true}
                    maxLength="255"
                    component={InputField}
                  />
                  <label className="input-group-addon btn btn-primary dm-btn is-dimgray">
                    {t('common.resend')}
                    <button type="button" onClick={() => this.handleMailSend()} />
                  </label>
                  <label className="input-group-addon btn btn-danger dm-btn is-dimgray">
                    {t('user.cancel')}
                    <button type="button" onClick={() => this.handleMailCancel()} />
                  </label>
                </div>
                <div className="dm-note">
                  {convertReminingTime(this.context, user.change_email_token_remaining_time)}
                </div>
              </div>
            )}
            {!isNew && (
              <React.Fragment>
                <div className="form-group">
                  <LabelWithTooltip htmlFor="name" className="dm-title-mini" name="user.name" />
                  <Field
                    type="text"
                    name="name"
                    className="form-control dm-form-control"
                    maxLength="255"
                    component={InputField}
                  />
                </div>
                {isConfirmed && (
                  <div className="form-group">
                    <IconFieldWithDialog
                      name="user.icon"
                      iconUrl={iconSrc}
                      defaultUrl="/image/avator_operator.png"
                      iconHeight={48}
                      iconWidth={48}
                      iconFileFormats={authorizedIconFileFormats}
                      renderNotes={this.renderIconFieldNotes}
                      restoreDefault={this.restoreDefaultIcon}
                      handleSave={this.handleSaveToIcon}
                    />
                  </div>
                )}
                {isSelfUpdate && (
                  <div className="form-group">
                    <LabelWithTooltip htmlFor="language" className="dm-title-mini" name="user.language" />
                    <Field
                      name="language"
                      items={languages}
                      valueKey="code"
                      displayKey="label"
                      className="form-control dm-form-control"
                      component={SelectField}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
            {roleSelectbox}
            <div className="form-group text-right">
              {this.props.params.id ? (
                <button type="submit" className="btn btn-primary dm-btn" disabled={submitting}>
                  {t('common.save')}
                </button>
              ) : (
                <button type="submit" className="btn btn-primary dm-btn" disabled={submitting}>
                  {t('common.sendInvitation')}
                </button>
              )}
              {this.props.params.id && !isSelfUpdate && (
                <button
                  type="button"
                  className="btn btn-danger dm-btn"
                  onClick={this.handleDelete}
                  disabled={submitting}
                >
                  {t('common.delete')}
                </button>
              )}
            </div>
          </form>
        </Loader>
      </div>
    )
  }
}

const UserEditForm = reduxForm({
  form: 'UserEdit',
  enableReinitialize: true,
  validate,
})(UserEdit)

export const mapStateToProps = (state, props) => {
  const user = state.entities.users[props.params.id] || { role: 'writer' }
  const languageMapping = {
    ja: 'ja',
    en: 'en-US',
    'en-US': 'en-US',
    zh: 'zh-CN',
    'zh-CN': 'zh-CN',
  }
  let initialValues = { ...user, language: languageMapping[i18n.language] ?? 'en-US' }

  return {
    isFetching: isFetching(state),
    isSelfUpdate: props.params.id === state.session.id.toString(),
    isMailSend: !!user.pending_email,
    initialValues,
    user,
  }
}

export default connect(mapStateToProps)(UserEditForm)
