import lodash from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactTable, { ReactTableDefaults } from 'react-table'
import { Link } from 'react-router'
import Tooltip from '../../components/common/Tooltip'
import { getCredentials } from '../../helpers/sessionHelper'
import { isCarouselResponse, convertCarouselResponse } from '../../helpers/messageHelper'

// Popup entire text when hover cursor
const PopupableText = ({ value }) => {
  return <span title={value}>{value}</span>
}
PopupableText.propTypes = {
  value: PropTypes.string,
}

export class ApplicationLogs extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    application: PropTypes.object,
    topics: PropTypes.array,
    users: PropTypes.array,
    threads: PropTypes.array,
    isFetching: PropTypes.bool,
    onRefresh: PropTypes.func,
    onFetchData: PropTypes.func,
  }

  static displayReasons = {
    complete: ['complete'],
    user_reset: ['reset', 'leave', 'account_link', 'interrupt_topic'],
    system_reset: ['reset_by_system', 'change_topic', 'phone_number_push'],
    undo: ['undo'],
    operator: ['call_operator', 'operator_pickup', 'operator_hangup'],
    recognition_failure: [
      'abort_classification',
      'classification_failure',
      'classifier_error',
      'recognition_failure',
    ],
    scenario_error: [
      'exceed_response_limit',
      'exceed_rerun_limit',
      'exceed_message_length_limit',
      'classifier_not_found',
      'stack_level_too_deep',
      'oauth_request_denied',
      'oauth_session_not_found',
      'not_permitted_plan',
      'not_permitted_platform',
      'operator_disable',
      'operator_not_permitted',
      'account_link_disable',
      'external_account_credentials_error',
      'unsupport_image_action',
      'unsupport_carousel_action',
      'exceed_carousel_message_limit',
      'variable_for_carousel_not_found',
      'carousel_format_exception',
      'exceed_scheduled_task_limit',
      'choose_scenario_without_choice',
      'sending_image_not_found',
    ],
    system_error: [
      'error',
      'template_message_not_found',
      'unexpected_error',
      'system_exception',
      'abort_thread',
      'message_sending_failure',
    ],
    integration_error: [
      'to_email_not_found',
      'exceed_email_length_limit',
      'invalid_email',
      'not_authenticated_email',
      'office365_integration_error',
      'salesforce_integration_error',
      'oauth_configuration_not_found',
      'integration_parameter_error',
      'integration_execute_error',
    ],
    timeout: ['timeout'],
  }

  static iconTypes = {
    client: ['client'],
    bot: ['bot'],
    pushApi: ['push_api'],
    operator: ['operator'],
    system: ['system'],
  }

  getDisplayReason = finishReason => {
    return lodash.findKey(ApplicationLogs.displayReasons, reasons => lodash.includes(reasons, finishReason))
  }

  getIconType = message => {
    let senderType = message.sender_type
    if (lodash.includes(['switch', 'notice', 'log', 'access_log'], message.type)) {
      senderType = 'system'
    }
    return lodash.findKey(ApplicationLogs.iconTypes, types => lodash.includes(types, senderType))
  }

  getSenderName = message => {
    const { t } = this.context
    const { users } = this.props

    if (message.type === 'switch') {
      return t('analytics.applicationLogs.senderName.externalEvent')
    } else if (message.type === 'notice') {
      return t('analytics.applicationLogs.senderName.externalEvent')
    } else if (message.type === 'log') {
      return t('analytics.applicationLogs.senderName.log')
    } else if (message.type === 'access_log') {
      return t('analytics.applicationLogs.senderName.accessLog')
    } else if (message.type === 'error') {
      return t('analytics.applicationLogs.senderName.system')
    } else {
      if (lodash.includes(['client', 'bot', 'push_api'], message.sender_type)) {
        return message.sender_name
      } else if (message.sender_type === 'operator') {
        const user = lodash.find(users, { id: parseInt(message.sender_uid, 10) })
        return user ? user.name : t('analytics.applicationLogs.senderName.deletedUser')
      }
    }
  }

  constructor() {
    super()
    this.state = { expanded: {} }
  }

  onRefresh = () => {
    this.clearExpanded()
    this.props.onRefresh(this.tableInstance)
  }

  onExpandedChange = newExpanded => {
    this.setState({ expanded: newExpanded })
  }

  clearExpanded = () => {
    this.setState({ expanded: {} })
  }

  toggleAllExpander = () => {
    if (lodash.some(this.state.expanded)) {
      this.setState({ expanded: {} })
    } else {
      const pageSize = this.tableInstance.state.pageSize
      const expanded = Object.assign({}, lodash.fill(new Array(pageSize), {}))

      this.setState({ expanded })
    }
  }

  filterTimestamp = (filter, row) => {
    const { timezone } = getCredentials(this.context)
    const datetimeFormats = ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss', 'YYYY/MM/DD HH:mm:ss']

    return lodash.some(datetimeFormats, format => {
      const timestamp = moment.tz(row[filter.id], timezone).format(format)
      return lodash.includes(timestamp, filter.value)
    })
  }

  getBody = () => {
    const { t } = this.context
    const { threads, topics } = this.props

    if (lodash.isEmpty(threads)) return []

    return lodash.compact(
      lodash.map(threads, thread => {
        const row = lodash.cloneDeep(thread)

        row.feedback = t(`analytics.applicationLogs.feedback.${thread.feedback || 'no_answer'}`)
        if (thread.finish_reason) {
          row.finish_reason = t(
            `analytics.applicationLogs.finishReason.${
              this.getDisplayReason(thread.finish_reason) || 'system_error'
            }`
          )
        }
        if (thread.short_room_id) {
          row.short_room_id = thread.short_room_id
        }
        if (thread.topic_id) {
          row.topic =
            (lodash.find(topics, { id: thread.topic_id }) || {}).name ||
            t('analytics.applicationLogs.alreadyDeleted')
        }

        row.messages = lodash.compact(
          lodash.map(thread.messages, originalMessage => {
            if (
              lodash.includes(
                ['join', 'reset_by_system', 'reset', 'leave', 'task', 'event', 'unknown'],
                originalMessage.type
              )
            )
              return

            // Remove unnecessary resuest feedback message
            if (originalMessage.type === 'request_feedback' && originalMessage.content.state !== 'initial')
              return
            // Remove unnecessary form message
            if (originalMessage.type === 'form' && originalMessage.content.phase !== 'initial') return

            const message = lodash.cloneDeep(originalMessage)

            if (message.type === 'text' || message.type === 'datepicker') {
              if (isCarouselResponse(message.content)) {
                message.text = convertCarouselResponse(message.content)
              } else {
                message.text = message.content.text || message.content.value
                if (message.content.is_select) {
                  message.text = t('analytics.applicationLogs.messageType.chooseChoice') + message.text
                }
              }
            }
            if (message.type === 'image') {
              message.text = t('analytics.applicationLogs.messageType.image')
              if (message.content.title) {
                message.text += message.content.title
              }
              if (message.content.text) {
                message.text += `(${message.content.text})`
              }
            }
            if (message.type === 'confirm') {
              message.text = message.content.text + t('analytics.applicationLogs.yesNo')
            }
            if (message.type === 'choose') {
              message.text =
                message.content.text + ' (' + lodash.map(message.content.actions, 'label').join(' / ') + ')'
            }
            if (message.type === 'item_list') {
              message.text =
                t('analytics.applicationLogs.messageType.item_list') +
                lodash.map(message.content.items, 'text').join(' / ')
            }
            if (message.type === 'carousel') {
              message.text =
                t('analytics.applicationLogs.messageType.carousel') +
                lodash.map(message.content.carousel_items, 'title').join(' / ')
            }
            if (message.type === 'form') {
              message.text = t('analytics.applicationLogs.messageType.form') + message.content.title
            }
            if (message.type === 'response_form') {
              const fieldValues = message.content['field_values'] || {}

              const parameters = []
              lodash.forIn(fieldValues, (value, key) => {
                if (key.startsWith('@')) return

                if (lodash.isObject(value)) {
                  parameters.push(`${key}: ${JSON.stringify(value)}`)
                } else {
                  parameters.push(`${key}: ${value}`)
                }
              })

              message.text = t('analytics.applicationLogs.messageType.responseForm') + fieldValues['@text']
              if (!lodash.isEmpty(parameters)) {
                message.text += ` (${parameters.join(', ')})`
              }
            }
            if (message.type === 'datepicker') {
              message.text = message.content.text
            }
            if (message.type === 'switch') {
              message.text = t('analytics.applicationLogs.eventType.switch')
            }
            if (message.type === 'notice') {
              message.text = t('analytics.applicationLogs.eventType.notice')
            }
            if (message.type === 'request_feedback') {
              message.text = t('analytics.applicationLogs.messageType.feedback') + message.content.text
            }
            if (message.type === 'log') {
              message.text = t('analytics.applicationLogs.messageType.log') + message.content.text
            }
            if (message.type === 'access_log') {
              message.text = message.content.text
            }
            if (message.type === 'error') {
              message.text = message.content.text
            }

            //  Clip exceeded text
            if (message.text && message.text.length > 280) {
              message.text = message.text.slice(0, 280) + t('common.ellipsis')
            }
            return message
          })
        )

        const userMessages = lodash.filter(row.messages, { sender_type: 'client' })

        row.first_message = (
          lodash.minBy(
            lodash.filter(userMessages, message => {
              if (
                !lodash.includes(
                  ['yes', 'no', 'はい', 'いいえ', '[次へ]', '[最初へ戻る]'],
                  message.content.text
                )
              )
                return message
            }),
            'timestamp'
          ) || { content: {} }
        ).content.text

        return row
      })
    )
  }

  renderImageLink = url => {
    const { t } = this.context

    return (
      <Link
        title={url}
        className="dm-link image-link"
        onClick={() => window.open(url, 'imageWindow', 'resizable=yes')}
      >
        {t('analytics.applicationLogs.image')}
        <i className="fas fa-external-link-alt" />
      </Link>
    )
  }

  render() {
    const { t } = this.context
    const { isFetching, onFetchData } = this.props
    const { timezone } = getCredentials(this.context)
    const datetimeFormat = 'YYYY/MM/DD HH:mm:ss'

    const columns = [
      {
        Header: t('analytics.applicationLogs.startedAt'),
        accessor: 'started_at',
        width: 175,
        Cell: props => moment.tz(props.value, timezone).format(datetimeFormat),
        filterMethod: this.filterTimestamp,
      },
      {
        Header: t('analytics.applicationLogs.finishedAt'),
        accessor: 'finished_at',
        width: 175,
        Cell: props => {
          if (!props.value) return null
          return moment.tz(props.value, timezone).format(datetimeFormat)
        },
        filterMethod: this.filterTimestamp,
      },
      {
        Header: t('analytics.applicationLogs.finishReason.name'),
        accessor: 'finish_reason',
        width: 100,
        Cell: PopupableText,
        filterMethod: (filter, row) => {
          if (filter.value === 'all') return true
          return row[filter.id] === filter.value
        },
        Filter: ({ filter, onChange }) => (
          <select
            className="filter"
            value={filter ? filter.value : 'all'}
            onChange={event => onChange(event.target.value)}
          >
            <option value="all">{t('analytics.applicationLogs.finishReason.all')}</option>
            <option>{t('analytics.applicationLogs.finishReason.complete')}</option>
            <option>{t('analytics.applicationLogs.finishReason.user_reset')}</option>
            <option>{t('analytics.applicationLogs.finishReason.system_reset')}</option>
            <option>{t('analytics.applicationLogs.finishReason.undo')}</option>
            <option>{t('analytics.applicationLogs.finishReason.operator')}</option>
            <option>{t('analytics.applicationLogs.finishReason.recognition_failure')}</option>
            <option>{t('analytics.applicationLogs.finishReason.scenario_error')}</option>
            <option>{t('analytics.applicationLogs.finishReason.system_error')}</option>
            <option>{t('analytics.applicationLogs.finishReason.integration_error')}</option>
            <option>{t('analytics.applicationLogs.finishReason.timeout')}</option>
          </select>
        ),
      },
      {
        Header: t('analytics.applicationLogs.channelUuid'),
        accessor: 'short_room_id',
        width: 75,
      },
      {
        Header: t('analytics.applicationLogs.scenarioName'),
        accessor: 'topic',
        width: 170,
        Cell: PopupableText,
      },
      {
        Header: t('analytics.applicationLogs.firstMessage'),
        accessor: 'first_message',
        sortable: false,
        filterable: false,
        Cell: PopupableText,
        headerClassName: 'first-message',
        width: 130,
      },
      {
        Header: t('analytics.applicationLogs.feedback.name'),
        accessor: 'feedback',
        width: 140,
        filterMethod: (filter, row) => {
          if (filter.value === 'all') return true
          return row[filter.id] === filter.value
        },
        Filter: ({ filter, onChange }) => (
          <select
            className="filter"
            value={filter ? filter.value : 'all'}
            onChange={event => onChange(event.target.value)}
          >
            <option value="all">{t('analytics.applicationLogs.feedback.all')}</option>
            <option>{t('analytics.applicationLogs.feedback.yes')}</option>
            <option>{t('analytics.applicationLogs.feedback.no')}</option>
            <option>{t('analytics.applicationLogs.feedback.no_answer')}</option>
          </select>
        ),
      },
      {
        Header: t('analytics.applicationLogs.feedbackReason.name'),
        accessor: 'feedback_reason',
        width: 300,
        Cell: PopupableText,
        filterMethod: (filter, row) => {
          if (filter.value === 'all') return true
          else if (filter.value === 'exist') return !lodash.isEmpty(row[filter.id])
          else if (filter.value === 'notExist') return lodash.isEmpty(row[filter.id])
        },
        Filter: ({ filter, onChange }) => (
          <select
            className="filter"
            value={filter ? filter.value : 'all'}
            onChange={event => onChange(event.target.value)}
          >
            <option value="all">{t('analytics.applicationLogs.feedbackReason.all')}</option>
            <option value="exist">{t('analytics.applicationLogs.feedbackReason.exist')}</option>
            <option value="notExist">{t('analytics.applicationLogs.feedbackReason.notExist')}</option>
          </select>
        ),
      },
    ]
    const items = this.getBody()
    const pageSize = 10

    const chatFlowColumns = [
      {
        Header: t('analytics.applicationLogs.timestamp'),
        accessor: 'timestamp',
        sortable: false,
        width: 175,
        // TODO: Remove `parseZone` after two weeks from version 1.3 has been released.
        // Handle as UTC timezone automatically if message.timestamp does not contain timezone.
        Cell: props => moment.tz(moment.parseZone(props.value), timezone).format(datetimeFormat),
      },
      {
        Header: t('analytics.applicationLogs.senderName.name'),
        sortable: false,
        Cell: props => {
          const iconType = this.getIconType(props.original)
          return (
            <span>
              <span className={`icon ${iconType}`} />
              <span>{this.getSenderName(props.original)}</span>
            </span>
          )
        },
        width: 175,
      },
      {
        Header: t('analytics.applicationLogs.text'),
        accessor: 'text',
        sortable: false,
        Cell: props => {
          return (
            <div className="message-text">
              <span className="text">{PopupableText(props)}</span>
              {props.original.type === 'image' && this.renderImageLink(props.original.content.image_url)}
            </div>
          )
        },
      },
    ]

    return (
      <div className="mb-3">
        <div className="dm-table">
          <div className="dm-caption">
            <span className="dm-title-mini">{t('analytics.applicationLogs.title')}</span>
            {<Tooltip name="analytics.tooltip.applicationLogs.title" />}
            <div className="pull-right">
              <button className="dm-btn btn btn-default btn-icon-refresh" onClick={this.onRefresh} />
            </div>
          </div>
          <ReactTable
            ref={instance => {
              this.tableInstance = instance
            }}
            columns={columns}
            data={items}
            expanded={this.state.expanded}
            onExpandedChange={this.onExpandedChange}
            onSortedChange={this.clearExpanded}
            onFilteredChange={this.clearExpanded}
            onPageChange={this.clearExpanded}
            onPageSizeChange={this.clearExpanded}
            onFetchData={onFetchData}
            SubComponent={row => {
              const messages = row.original.messages
              return (
                <ReactTable
                  columns={chatFlowColumns}
                  data={messages}
                  showPagination={false}
                  minRows={messages.length}
                  defaultPageSize={messages.length}
                  getTrProps={(state, rowInfo) => {
                    if (!rowInfo) return {}
                    return { className: rowInfo.original.type }
                  }}
                  noDataText={t('common.noRowsFound')}
                  className="table messages no-footer without-hover"
                />
              )
            }}
            ThComponent={this.renderThComponent}
            loading={isFetching}
            defaultPageSize={pageSize}
            minRows={items.length > pageSize ? pageSize : 1}
            showPagination={items.length > pageSize}
            showPageSizeOptions={true}
            pageSizeOptions={[10, 20, 50, 100, 200]}
            previousText={'<'}
            nextText={'>'}
            pageText={'Page'}
            ofText={'/'}
            rowsText={t('common.rows')}
            noDataText={t('common.noRowsFound')}
            filterable
            defaultSorted={[{ id: 'started_at', desc: true }]}
            defaultFilterMethod={(filter, row) => lodash.includes(row[filter.id], filter.value)}
            className="table no-footer with-filter mb-0"
          />
        </div>
        <div className="dm-note">{t('analytics.usage.tableNote')}</div>
      </div>
    )
  }

  renderThComponent = props => {
    const ThComponent = ReactTableDefaults.ThComponent
    const ExpanderComponent = ReactTableDefaults.ExpanderComponent
    if (props.children === null && props.className !== 'first-message') {
      const state = this.tableInstance && this.tableInstance.getResolvedState()
      const isExpanded = this.tableInstance && lodash.some(state.expanded)
      return (
        <ThComponent
          {...props}
          className="rt-expandable"
          onClick={this.toggleAllExpander}
          tabIndex={undefined}
        >
          <ExpanderComponent isExpanded={isExpanded} />
        </ThComponent>
      )
    }

    return <ThComponent {...props} />
  }
}

export default ApplicationLogs
