import { deleteCache } from './api'
import Schemas from '../middleware/schema'
import request from '../helpers/requestManagementAPI'

export const fetchApplications = (token, filter = {}) => dispatch => {
  return request.get('/applications/', {
    token,
    dispatch,
    body: filter,
    target: 'applications',
    schema: Schemas.applications,
    preserve: false,
  })
}

export const fetchApplication = (token, id, ignoreFetching = false, errorHandler) => dispatch => {
  return request.get(`/applications/${id}`, {
    token,
    dispatch,
    target: ignoreFetching ? null : 'applications',
    schema: Schemas.application,
    errorHandler,
  })
}

export const fetchSimulator = token => () => {
  return request.get('/applications/simulator', {
    token,
  })
}

export const createApplication = (token, application) => dispatch => {
  return request.post('/applications/', {
    token,
    dispatch,
    body: application,
    target: 'applications',
    schema: Schemas.application,
  })
}

export const updateApplication = (token, id, application) => dispatch => {
  return request.put(`/applications/${id}`, {
    token,
    dispatch,
    body: application,
    target: 'applications',
    schema: Schemas.application,
  })
}

export const abortApplication = (token, id) => dispatch => {
  return request.post(`/applications/${id}/abort`, {
    token,
    dispatch,
    target: 'applications',
    schema: Schemas.application,
  })
}

export const abortReservation = (token, id) => dispatch => {
  return request.post(`/applications/${id}/abort_reservation`, {
    token,
    dispatch,
    target: 'applications',
    schema: Schemas.application,
  })
}

export const deleteApplication = (token, id, ignoreNotice) => dispatch => {
  return request
    .delete(`/applications/${id}`, {
      token,
      dispatch,
      target: 'applications',
      ignoreNotice: ignoreNotice,
    })
    .then(() => dispatch(deleteCache('applications', id)))
}

export const reissueApplicationToken = (token, id) => dispatch => {
  return request.post(`/applications/${id}/reissue/token`, {
    token,
    dispatch,
    target: 'applications',
    schema: Schemas.application,
  })
}

export const reissueNotificationApiKey = (token, id) => dispatch => {
  return request.post(`/applications/${id}/reissue/api_key`, {
    token,
    dispatch,
    body: { type: 'notification' },
    target: 'applications',
    schema: Schemas.application,
  })
}

export const reissuePnpApiKey = (token, id) => dispatch => {
  return request.post(`/applications/${id}/reissue/api_key`, {
    token,
    dispatch,
    body: { type: 'pnp' },
    target: 'applications',
    schema: Schemas.application,
  })
}

export const reissueWebAccountLinkApiKey = (token, id) => dispatch => {
  return request.post(`/applications/${id}/reissue/api_key`, {
    token,
    dispatch,
    body: { type: 'account_link' },
    target: 'applications',
    schema: Schemas.application,
  })
}

export const updateEmbeddedTag = (token, id, embedded_tag) => dispatch => {
  return request.put(`/applications/${id}/embedded_tag`, {
    token,
    dispatch,
    body: embedded_tag,
    target: 'applications',
    schema: Schemas.application,
  })
}

export const publishLauncherImage = (token, id) => dispatch => {
  return request.post(`/applications/${id}/embedded_tag/publish_image`, {
    token,
    dispatch,
    target: 'applications',
    schema: Schemas.application,
  })
}

export const fetchThreads = (token, id, uuids = [], with_messages = false) => dispatch => {
  const body = {
    uuid: uuids,
    with_messages: with_messages,
  }
  return request.post(`/applications/${id}/threads`, {
    token,
    dispatch,
    body,
    target: 'threads',
    schema: Schemas.threads,
  })
}

export const downloadLogs = (token, application_id, target_month) => dispatch => {
  return request.get(`/applications/${application_id}/download_logs`, {
    token,
    dispatch,
    body: { target_month: target_month },
  })
}

export const fetchPredictionFailureLogs = (token, application_id, type, faq_id) => dispatch => {
  return request.get(`/applications/${application_id}/prediction_logs`, {
    token,
    body: {
      type: type,
      faq_id: faq_id,
    },
    dispatch,
    target: 'prediction_failure_logs',
    schema: Schemas.predictionFailureLogs,
    preserve: false,
  })
}

export const fetchSpMetadata = (token, application_id) => dispatch => {
  return request.get(`/applications/${application_id}/download_sp_metadata`, {
    token,
    dispatch,
  })
}
