import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactLoader from 'react-loader'

class Loader extends Component {
  static propTypes = {
    loaded: PropTypes.bool,
    type: PropTypes.oneOf(['overlay-fullscreen', 'overlay', 'hidden', 'show']),
    children: PropTypes.node,
    reactLoaderOptions: PropTypes.object,
  }

  static defaultProps = {
    loaded: false,
    type: 'hidden',
    children: null,
    reactLoaderOptions: {},
  }

  constructor() {
    super()
    this.state = { loaded: false }
  }

  componentDidMount() {
    // setTimeout(() => { this.setState({loaded: true}) }, 3000)
  }

  render() {
    if (this.props.loaded) {
      return (
        <div>
          <div>{this.props.children}</div>
        </div>
      )
    }
    const renderers = {
      'overlay-fullscreen': this.renderOverlayFullscreen,
      overlay: this.renderOverlay,
      hidden: this.renderHidden,
      show: this.renderShow,
    }

    return renderers[this.props.type]()
  }

  renderOverlayFullscreen = () => (
    <div>
      <div>{this.props.children}</div>
      <div className="overlay-fullscreen">
        <ReactLoader options={this.props.reactLoaderOptions} />
      </div>
    </div>
  )

  renderOverlay = () => (
    <div className="fit-parent">
      <div>{this.props.children}</div>
      <div className="overlay">
        <ReactLoader options={this.props.reactLoaderOptions} />
      </div>
    </div>
  )

  renderHidden = () => {
    return <ReactLoader options={this.props.reactLoaderOptions} />
  }

  renderShow = () => (
    <div className="fit-parent">
      <div>{this.props.children}</div>
      <ReactLoader options={this.props.reactLoaderOptions} />
    </div>
  )
}

export default Loader
