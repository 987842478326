import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import { InputField, SelectField, CheckboxField } from '../../components/common/fields/FormFields'
import Tooltip from '../../components/common/Tooltip'
import LabelRow from './form_table/LabelRow'
import TextRow from './form_table/TextRow'
import TextListRow from './form_table/TextListRow'
import SelectRow from './form_table/SelectRow'
import SelectListRow from './form_table/SelectListRow'
import MultipleSelectRow from './form_table/MultipleSelectRow'

export class FieldTable extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    formFields: PropTypes.array,
    fields: PropTypes.object,
  }

  componentWillUpdate(nextProps) {
    const { fields } = nextProps
    const prevLastItem = lodash.last(this.props.formFields)
    const nextLastItem = lodash.last(nextProps.formFields)

    // if only has is_required fields other than arguments and it is false then remove the form
    nextProps.formFields.forEach((field, index) => {
      if (lodash.has(field, 'is_required') && !field.is_required && lodash.keys(field).length === 2) {
        if (fields.length > 1) {
          fields.remove(index)
        }
      }
    })

    if (lodash.keys(prevLastItem).length === 1 && lodash.keys(nextLastItem).length > 1) {
      fields.push({ arguments: { default_value_type: 'constant', data_type: 'constant' } })
    }
  }

  getRowSpan = field => {
    let component
    if (field.type === 'label') {
      component = LabelRow
    } else if (field.type === 'text') {
      component = TextRow
    } else if (field.type === 'text_list') {
      component = TextListRow
    } else if (field.type === 'select') {
      component = SelectRow
    } else if (field.type === 'select_list') {
      component = SelectListRow
    } else if (field.type === 'multiple_select') {
      component = MultipleSelectRow
    }

    if (!component) return 1

    return component.rowSpan(field)
  }

  renderOptionRow(name, index) {
    const { formFields } = this.props

    const field = formFields[index] || {}

    const props = {
      name,
      index,
      field,
    }

    if (field.type === 'label') {
      return <LabelRow {...props} />
    } else if (field.type === 'text') {
      return <TextRow {...props} />
    } else if (field.type === 'text_list') {
      return <TextListRow {...props} />
    } else if (field.type === 'select') {
      return <SelectRow {...props} />
    } else if (field.type === 'select_list') {
      return <SelectListRow {...props} />
    } else if (field.type === 'multiple_select') {
      return <MultipleSelectRow {...props} />
    }
  }

  renderMainRow(name, index) {
    const { t } = this.context
    const { formFields, fields } = this.props

    const field = formFields[index] || {}

    const types = [
      { id: 'label', name: t('topic.actionList.form.fieldTable.typeGroup.label') },
      { id: 'text', name: t('topic.actionList.form.fieldTable.typeGroup.text') },
      { id: 'text_list', name: t('topic.actionList.form.fieldTable.typeGroup.textList') },
      { id: 'select', name: t('topic.actionList.form.fieldTable.typeGroup.select') },
      { id: 'select_list', name: t('topic.actionList.form.fieldTable.typeGroup.selectList') },
      { id: 'multiple_select', name: t('topic.actionList.form.fieldTable.typeGroup.multipleSelect') },
    ]

    return (
      <tr key={`${index}-1`}>
        <td className="number" rowSpan={this.getRowSpan(field)}>
          {index + 1}
        </td>
        <td>
          <Field
            name={`${name}.type`}
            className="form-control dm-form-control"
            empty={true}
            items={types}
            valueKey="id"
            displayKey="name"
            component={SelectField}
          />
        </td>
        <td>
          <Field
            name={`${name}.name`}
            className="form-control"
            component={InputField}
            maxLength="40"
            disabled={field.type === 'label'}
          />
        </td>
        <td className="is_required">
          <Field
            type="checkbox"
            name={`${name}.is_required`}
            className="form-control"
            component={CheckboxField}
            disabled={field.type === 'label'}
          />
        </td>
        <td className="delete" rowSpan={this.getRowSpan(field)}>
          {index < formFields.length - 1 && (
            <button
              type="button"
              className="dm-btn btn btn-danger btn-icon-delete"
              onClick={() => {
                fields.remove(index)
              }}
            />
          )}
        </td>
      </tr>
    )
  }

  render() {
    const { t } = this.context
    const { fields } = this.props
    return (
      <table className="table table-bordered dm-table">
        <thead>
          <tr>
            <th className="number">{t('topic.actionList.form.fieldTable.no')}</th>
            <th>
              {t('topic.actionList.form.fieldTable.type')}
              <Tooltip name="topic.tooltip.actionList.form.fieldTable.type" />
            </th>
            <th>
              {t('topic.actionList.form.fieldTable.name')}
              <Tooltip name="topic.tooltip.actionList.form.fieldTable.name" />
            </th>
            <th className="isRequired">
              {t('topic.actionList.form.fieldTable.isRequired')}
              <Tooltip name="topic.tooltip.actionList.form.fieldTable.isRequired" />
            </th>
            <th className="delete">{t('topic.actionList.form.fieldTable.delete')}</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((name, index) => (
            <React.Fragment key={`${index}-row`}>
              {this.renderMainRow(name, index)}
              {this.renderOptionRow(name, index)}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    )
  }
}

export default FieldTable
