const default_options = {
  source: 'dashboard',
  removable: true,
}

export const addNotice = (level, message, options = {}) => ({
  type: 'ADD_NOTICE',
  level,
  message,
  options: {
    ...default_options,
    ...options,
  },
})

export const updateNotice = (uuid, level, message, options = {}) => ({
  type: 'UPDATE_NOTICE',
  uuid,
  level,
  message,
  options: {
    ...default_options,
    ...options,
  },
})

export const removeNotice = id => ({
  type: 'REMOVE_NOTICE',
  id,
})

export const removeNotices = (filter = {}) => ({
  type: 'REMOVE_NOTICES',
  filter,
})

export const removeAllNotices = () => ({
  type: 'REMOVE_NOTICES',
  filter: {},
})
