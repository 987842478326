import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes } from 'redux-form'

import { addNotice } from '../../actions/notice'
import { fetchUser, changePassword } from '../../actions/user'
import { InputField } from '../../components/common/fields/FormFields'
import Loader from '../../components/common/Loader'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import PasswordPolicy from '../../components/user/PasswordPolicy'
import { isFetching } from '../../helpers/selector'
import cancelUrl from '../../helpers/cancelurl'

const validate = (data, props) => {
  const errors = {}
  if (!data.current_password) {
    errors.current_password = 'validate.required'
  }
  if (props.useStrictPasswordPolicy) {
    const availableSymbols = '!"#$%&\'()*+,-./:;<=>?@[\\\\\\]^_`{|}~'
    const pattern = new RegExp(
      `^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\\d)(?=.*?[${availableSymbols}])[\\x20-\\x7E]{10,255}$`
    )
    if (!pattern.test(data.new_password)) {
      if (!data.new_password || data.new_password.length < 10) {
        errors.new_password = { id: 'validate.insufficientMinLength', values: { length: 10 } }
      } else if (!/^[\x20-\x7E]*$/i.test(data.new_password)) {
        errors.new_password = 'validate.invalidPassword'
      } else {
        errors.new_password = 'validate.violatedStrictPasswordPolicy'
      }
    }
  } else {
    if (!/^(?=.*?[a-z])(?=.*?\d)[\x20-\x7E]{8,255}$/i.test(data.new_password)) {
      if (!data.new_password || data.new_password.length < 8) {
        errors.new_password = { id: 'validate.insufficientMinLength', values: { length: 8 } }
      } else if (!/^[\x20-\x7E]*$/i.test(data.new_password)) {
        errors.new_password = 'validate.invalidPassword'
      } else {
        errors.new_password = 'validate.violatedStandardPasswordPolicy'
      }
    }
  }

  if (data.new_password !== data.new_password_confirmation) {
    errors.new_password_confirmation = 'validate.mismatchedPassword'
  }
  return errors
}

export class ChangePassword extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    useStrictPasswordPolicy: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    dispatch(fetchUser(state.session.token, state.session.id))
    cancelUrl.setRouteLeaveHook(this)
  }

  handleSave = (data, dispatch) => {
    const { t } = this.context
    const token = this.context.store.getState().session.token
    return dispatch(changePassword(token, data.current_password, data.new_password))
      .then(() => this.props.reset())
      .then(() => dispatch(addNotice('info', t('user.changePasswordSuccess'))))
  }

  render() {
    const { t } = this.context
    const { isFetching, submitting, handleSubmit } = this.props
    return (
      <div>
        <Loader loaded={!isFetching && !submitting} type="show">
          <form className="text-left col-md-9" onSubmit={handleSubmit(this.handleSave)}>
            <div className="form-group">
              <LabelWithTooltip
                htmlFor="current_password"
                className="dm-title-mini"
                name="user.currentPassword"
              />
              <Field
                type="password"
                name="current_password"
                className="form-control dm-form-control"
                component={InputField}
              />
            </div>
            <div className="form-group">
              <LabelWithTooltip htmlFor="new_password" className="dm-title-mini" name="user.newPassword" />
              <Field
                type="password"
                name="new_password"
                className="form-control dm-form-control"
                component={InputField}
                autoComplete="new-password"
              />
            </div>
            <div className="form-group">
              <LabelWithTooltip
                htmlFor="new_password_confirmation"
                className="dm-title-mini"
                name="user.newPasswordConfirmation"
              />
              <Field
                type="password"
                name="new_password_confirmation"
                className="form-control dm-form-control"
                component={InputField}
                autoComplete="new-password"
              />
            </div>
            {this.props.useStrictPasswordPolicy && <PasswordPolicy />}
            <div className="form-group text-right">
              <button type="submit" className="btn btn-primary dm-btn" disabled={submitting}>
                {t('common.save')}
              </button>
            </div>
          </form>
        </Loader>
      </div>
    )
  }
}

const ChangePasswordForm = reduxForm({
  form: 'ChangePassword',
  enableReinitialize: true,
  validate,
})(ChangePassword)

export const mapStateToProps = (state, _props) => {
  const user = state.entities.users[state.session.id]
  return {
    isFetching: isFetching(state),
    initialValues: {},
    useStrictPasswordPolicy: user.use_strict_password_policy,
  }
}

export default connect(mapStateToProps)(ChangePasswordForm)
