import lodash from 'lodash'

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { HorizontalBar } from 'react-chartjs-2'
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes'
import { Tableau20 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau'
import Chart from 'chart.js'

import Loader from '../common/Loader'

export class FaqUsageGraph extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    faqs: PropTypes.array,
    faqItems: PropTypes.array.isRequired,
    faqUsages: PropTypes.object,
    faqId: PropTypes.string,
    maxFaqItem: PropTypes.number,
    height: PropTypes.number,
    isFetching: PropTypes.bool,
  }
  static defaultProps = {
    faqs: [],
    faqUsage: {},
    maxFaqItem: 10,
    height: 300,
  }

  //  Max width of y-axis label for scenario name
  static maxLabelWidth = 130

  restrictWidth(ctx, label) {
    //  Width of y-axis label's padding for scenario name
    const tickPadding = Chart.defaults.scale.gridLines.tickMarkLength
    const maxWidth = FaqUsageGraph.maxLabelWidth - tickPadding

    let width = ctx.measureText(label).width
    if (width <= maxWidth) {
      return label
    }

    //  Ellipse long scenario name with ellipsis mark
    let ommitedLabel = label
    let len = ommitedLabel.length
    while (width > maxWidth && len-- > 0) {
      ommitedLabel = `${ommitedLabel.substring(0, len)}…`
      width = ctx.measureText(ommitedLabel).width
    }
    return ommitedLabel
  }

  getUsages = () => {
    const { t } = this.context
    const { faqItems, faqUsages, faqId, maxFaqItem } = this.props
    if (!faqUsages) {
      return []
    }

    let targetFaqItems
    let targetFaqUsages
    if (faqId === 'all') {
      targetFaqItems = faqItems
      targetFaqUsages = faqUsages.faqs
    } else {
      targetFaqItems = lodash.filter(faqItems, { faq_id: parseInt(faqId, 10) })
      targetFaqUsages = lodash.filter(faqUsages.faqs, { id: parseInt(faqId, 10) })
    }

    const failedData = {
      label: t('analytics.graph.usageGraph.faqUsage.graph.classificationFailed'),
    }

    const faqAggregation = lodash.flatten(
      lodash.map(targetFaqUsages, faqUsage => {
        const result = []
        lodash.forEach(faqUsage.items, faqItemUsage => {
          if (faqItemUsage.id === null) {
            // Classification failed
            failedData[`faq${faqUsage.id}`] = faqItemUsage.count
          } else {
            const targetFaqItem = lodash.find(targetFaqItems, { id: faqItemUsage.id })
            if (targetFaqItem) {
              result.push({
                label: targetFaqItem.name,
                [`faq${faqUsage.id}`]: faqItemUsage.count,
                count: faqItemUsage.count,
              })
            }
          }
        })

        return result
      })
    )

    return lodash.chain(faqAggregation).orderBy('count', 'desc').take(maxFaqItem).concat(failedData).value()
  }

  generateData = canvas => {
    const { faqs } = this.props

    const usages = this.getUsages()
    if (lodash.isEmpty(usages)) {
      return {}
    }

    const ctx = canvas.getContext('2d')
    ctx.font = "normal 12px 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
    const restrictedLabels = usages.map(usage => this.restrictWidth(ctx, usage.label))

    const datasets = lodash.map(faqs, faq => {
      return {
        label: this.restrictWidth(ctx, faq.name),
        data: lodash.map(usages, usage => lodash.get(usage, `faq${faq.id}`, 0)),
        stack: 'count',
        xAxesID: 'x-axis',
      }
    })

    return {
      labels: restrictedLabels,
      datasets,
    }
  }

  render() {
    const { t } = this.context
    const { height, isFetching } = this.props

    const scaleLabelPadding = 20

    const options = {
      tooltips: {
        mode: 'label',
      },
      legend: {
        onClick: null,
      },
      scales: {
        xAxes: [
          {
            id: 'x-axis',
            gridLines: {
              display: false,
            },
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: t('analytics.graph.usageGraph.faqUsage.graph.callAnswers'),
            },
            ticks: {
              beginAtZero: true,
              callback: value => {
                if (Math.floor(value) === value) {
                  return value
                }
              },
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
            },
            afterFit: function (scaleInstance) {
              // Restrict width that tick labels will be drawn
              scaleInstance.width = FaqUsageGraph.maxLabelWidth + scaleLabelPadding
            },
            scaleLabel: {
              display: true,
              labelString: t('analytics.graph.usageGraph.faqUsage.graph.faqNames'),
            },
          },
        ],
      },
      plugins: {
        colorschemes: {
          scheme: Tableau20,
        },
      },
    }

    return (
      <div className="dm-graph">
        <Loader type="overlay" loaded={!isFetching}>
          <HorizontalBar data={this.generateData} options={options} height={height} />
        </Loader>
      </div>
    )
  }
}

export default FaqUsageGraph
