import lodash from 'lodash'

import PropTypes from 'prop-types'
import React from 'react'
import Collapsible from 'react-collapsible'
import classnames from 'classnames'
import { Field } from 'redux-form'

import AbstractMessage from './AbstractMessage'
import MessageIcon from './MessageIcon'
import { InputField, SelectField, MultipleSelectField } from '../common/fields/FormFields'

class FormMessage extends AbstractMessage {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    ...AbstractMessage.propTypes,
    phase: PropTypes.oneOf(['initial', 'finish']),
    showAction: PropTypes.oneOf(['show', 'readonly']).isRequired,
    handleSendRawMessage: PropTypes.func.isRequired,
    formValues: PropTypes.object,
  }

  static defaultProps = {
    formValues: {},
  }

  constructor() {
    super()
    this.state = { collapsibleStatuses: {} }
  }

  checkIfCanSubmit = () => {
    const { content, formValues } = this.props

    return lodash.every(content.fields, field => {
      if (field.is_required) {
        if (lodash.includes(['text_list', 'select_list'], field.type)) {
          if (lodash.some(formValues[field.name], value => lodash.isEmpty(value))) return false
        } else {
          if (lodash.isEmpty(formValues[field.name])) return false
        }
      }
      return true
    })
  }

  clickTrigger = name => {
    const prevStatuses = this.state.collapsibleStatuses || {}
    this.setState({ collapsibleStatuses: { ...prevStatuses, [name]: !prevStatuses[name] } })
  }

  render() {
    const { phase, sender_type, content, timestamp, iconUrl, fallbackIconUrl, isLoaded } = this.props
    const renderers = {
      initial: this.renderInitial,
      finish: this.renderFinish,
    }

    return (
      <li className={classnames(this.getMessageClasses({ 'is-confirm': true }))}>
        <div className="body">
          <MessageIcon
            sender_type={sender_type}
            url={iconUrl}
            fallbackUrl={fallbackIconUrl}
            isLoaded={isLoaded}
          />
          <div className="balloon">
            <div className="panel panel-default dm-panel form">
              <div className="panel-heading" title={content.title}>
                <small className="title">{content.title}</small>
              </div>
              {renderers[phase]()}
            </div>
            {this.renderTimestamp(timestamp)}
          </div>
        </div>
      </li>
    )
  }

  renderLabelField = label => {
    return lodash.map(label.split('\n'), (text, index) => (
      <React.Fragment key={`label-row-${index}`}>
        {index !== 0 && <br />}
        <label>{text}</label>
      </React.Fragment>
    ))
  }

  renderTextListFieldRow = (field, disabled, index) => {
    const { uuid } = this.props

    return (
      <Field
        key={`text-list-${index}`}
        type="text"
        name={`${uuid}.${field.name}[${index}]`}
        className="form-control dm-form-control"
        placeholder={field.rows[index].placeholder}
        component={InputField}
        disabled={disabled}
      />
    )
  }

  renderSelectListFieldRow = (field, disabled, index) => {
    const { uuid } = this.props

    return (
      <Field
        key={`select-list-${index}`}
        name={`${uuid}.${field.name}[${index}]`}
        items={field.rows[index].items}
        valueKey="value"
        displayKey="label"
        className="form-control dm-form-control"
        component={SelectField}
        empty={field.empty}
        disabled={disabled}
      />
    )
  }

  renderListField = (field, disabled) => {
    const { t } = this.context

    const renderMethodByType = {
      text_list: this.renderTextListFieldRow,
      select_list: this.renderSelectListFieldRow,
    }

    const renderMethod = renderMethodByType[field.type]

    if (field.collapsible_start == null || field.collapsible_start > field.rows.length) {
      return lodash.map(field.rows, (row, index) => renderMethod(field, disabled, index))
    } else {
      const CollapsibleStartIndex = field.collapsible_start - 1

      const triggerClassnames = classnames({
        'collapsible-trigger': true,
        'is-open': !!this.state.collapsibleStatuses[field.name],
      })

      return (
        <React.Fragment>
          {lodash.map(lodash.range(CollapsibleStartIndex), index => renderMethod(field, disabled, index))}
          <Collapsible
            open={!!this.state.collapsibleStatuses[field.name]}
            triggerDisabled={true}
            transitionTime={300}
            overflowWhenOpen="visible"
          >
            {lodash.map(lodash.range(CollapsibleStartIndex, field.rows.length), index =>
              renderMethod(field, disabled, index)
            )}
          </Collapsible>
          <label className={triggerClassnames} onClick={() => this.clickTrigger(field.name)}>
            {t('chatMessage.form.collapsible', { count: field.collapsible_start, ordinal: true })}
          </label>
        </React.Fragment>
      )
    }
  }

  renderFormField = (field, index) => {
    const { uuid, showAction, isLatest } = this.props

    const disabled = showAction !== 'show' || !isLatest

    let formContent
    if (field.type === 'label') {
      formContent = this.renderLabelField(field.text)
    } else if (field.type === 'text') {
      formContent = (
        <Field
          type="text"
          name={`${uuid}.${field.name}`}
          className="form-control dm-form-control"
          placeholder={field.placeholder}
          component={InputField}
          disabled={disabled}
        />
      )
    } else if (field.type === 'text_list') {
      formContent = this.renderListField(field, disabled)
    } else if (field.type === 'select') {
      formContent = (
        <Field
          name={`${uuid}.${field.name}`}
          items={field.items}
          valueKey="value"
          displayKey="label"
          className="form-control dm-form-control"
          component={SelectField}
          empty={field.empty}
          disabled={disabled}
        />
      )
    } else if (field.type === 'select_list') {
      formContent = this.renderListField(field, disabled)
    } else if (field.type === 'multiple_select') {
      formContent = (
        <Field
          name={`${uuid}.${field.name}`}
          items={field.items}
          displayKey="label"
          valueKey="value"
          component={MultipleSelectField}
          disabled={disabled}
        />
      )
    }

    return (
      <div className="form-row" key={`form-${index}`}>
        {field.type !== 'label' && (
          <React.Fragment>
            <label className={classnames({ required: field.is_required })}>{field.label}</label>
            <br />
          </React.Fragment>
        )}
        {formContent}
      </div>
    )
  }

  renderInitial = () => {
    const { t } = this.context
    const { content, showAction, isLatest } = this.props

    let buttons
    if (showAction === 'show' && isLatest) {
      buttons = lodash.map(content.buttons, (button, index) => {
        const disabled = button.is_submit ? !this.checkIfCanSubmit() : false
        return (
          <div className="btn-group" key={`button-${index}`}>
            <button
              type="button"
              className="btn"
              title={!disabled ? button.label : t('chatMessage.form.buttonDisabled')}
              disabled={disabled}
              onClick={() => this.sendMessage(button)}
            >
              {button.label}
            </button>
          </div>
        )
      })
    } else {
      buttons = lodash.map(content.buttons, (button, index) => (
        <span key={`button-${index}`} title={button.label} className="btn disable">
          {button.label}
        </span>
      ))
    }

    return (
      <React.Fragment>
        <div className="panel-body">
          {lodash.map(content.fields, (field, index) => this.renderFormField(field, index))}
        </div>
        <div key="border" className="panel-border" />
        <div key="options" className="btn-group btn-group-justified">
          {buttons}
        </div>
        {this.renderDefaultFooterButtons('panel')}
      </React.Fragment>
    )
  }

  renderFinish = () => {
    const { mode, content, isSimulator } = this.props
    const options = {
      enableMarkdown: true,
      enableImagePreview: true,
      enableAbbreviation: true,
      enableSwitchChatBot: mode === 'client' && !isSimulator,
    }
    return (
      <React.Fragment>
        <div className="panel-body">{this.parse(content.text, options)}</div>
        {this.renderDefaultFooterButtons('panel')}
      </React.Fragment>
    )
  }

  sendMessage = button => {
    const { uuid, content, handleSendRawMessage, formValues } = this.props

    if (button.is_submit && !this.checkIfCanSubmit()) return

    const translationTarget = lodash
      .chain(content.fields)
      .filter(field => lodash.includes(['text', 'text_list'], field.type))
      .map(field => field.name)
      .values()

    const messageContent = {
      '@type': button.is_submit ? 'submit' : 'cancel',
      '@text': button.label,
      '@value': button.value,
      '@replyToUUID': uuid,
      '@translationTarget': translationTarget,
    }

    if (button.is_submit) {
      lodash.forEach(content.fields, field => {
        const value = formValues[field.name]
        if (value !== undefined && value !== '') {
          if (Array.isArray(value)) {
            if (!lodash.isEmpty(value)) {
              messageContent[field.name] = lodash.map(value, v => (v !== '' ? v : null))
            }
          } else {
            messageContent[field.name] = value
          }
        }
      })
    }

    handleSendRawMessage('response_form', { field_values: messageContent })
  }
}

export default FormMessage
