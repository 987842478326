import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field, FieldArray } from 'redux-form'

import { TextAreaField } from '../../components/common/fields/FormFields'
import IncrementableTable from '../../components/common/IncrementableTable'
import Loader from '../../components/common/Loader'

export class AnswerList extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    isGenerating: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    generateFaqAnswer: PropTypes.func.isRequired,
  }

  renderAnswerField = (name, disabled = false) => (
    <Field
      type="text"
      name={name}
      disabled={disabled}
      className="from-control dm-form-control"
      maxLength={2000}
      minRows={2}
      maxRows={9}
      component={TextAreaField}
      style={{ maxWidth: '100%' }}
    />
  )

  renderAnswers = ({ fields }) => {
    const { t } = this.context
    const { isFetching, isGenerating, submitting, generateFaqAnswer } = this.props
    const columns = [
      {
        Header: t('faqItem.answerList.answer'),
        Cell: (name, index) => {
          return index === 0 ? (
            <>
              <Loader loaded={!isGenerating} type="overlay" reactLoaderOptions={{ radius: 8, lines: 10 }}>
                {this.renderAnswerField(name, isGenerating)}
              </Loader>
              <div className="generator-link">
                <button
                  type="button"
                  className="btn-link dm-btn btn-icon-lightbulb"
                  disabled={isFetching || isGenerating || submitting}
                  onClick={generateFaqAnswer}
                >
                  <span>{t('faqItem.answerGenerator.link')}</span>
                </button>
              </div>
            </>
          ) : (
            this.renderAnswerField(name)
          )
        },
      },
    ]
    return <IncrementableTable fields={fields} columns={columns} maxRows={5} />
  }

  render() {
    return <FieldArray name="answers" component={this.renderAnswers} />
  }
}

export default AnswerList
