import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Collapsible from 'react-collapsible'
import { Link } from 'react-router'
import Config from '../../helpers/config'
import { isPermitted } from '../../helpers/permission'
import { getCredentials } from '../../helpers/sessionHelper'

class Menu extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
    store: PropTypes.object,
  }

  static propTypes = {
    pathname: PropTypes.string.isRequired,
    hideDrawerMenu: PropTypes.func.isRequired,
    bots: PropTypes.array,
    applications: PropTypes.array,
  }

  isActive = menuName => {
    const { pathname } = this.props
    if (menuName === '/bots/create' && ['/bots/create', '/bots/new', '/bots/import'].includes(pathname)) {
      return true
    } else if (pathname.indexOf(menuName) === 0) {
      if (menuName === '/bots' && ['/bots/create', '/bots/new', '/bots/import'].includes(pathname)) {
        return false
      } else if (menuName === '/bots' && /^\/bots\/\d+(?:\/.*)?/.test(pathname)) {
        return false
      } else if (menuName === '/applications' && pathname === '/applications/new') {
        return false
      } else if (menuName === '/applications' && /^\/applications\/\d+(?:\/.*)?/.test(pathname)) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  renderMenuLink = (path, text) => {
    return (
      <li key={path} className={this.isActive(path) ? 'active' : undefined}>
        <Link to={path} onClick={this.props.hideDrawerMenu}>
          {text}
        </Link>
      </li>
    )
  }

  renderSubMenuLink = (path, text) => {
    return (
      <li key={path} className={this.isActive(path) ? 'active subitem' : 'subitem'}>
        <Link to={path} onClick={this.props.hideDrawerMenu}>
          {text}
        </Link>
      </li>
    )
  }

  render() {
    const { is_active, role } = getCredentials(this.context)

    if (!is_active) {
      return this.renderMenuForInactive()
    } else {
      const links = []
      if (lodash.includes(['owner', 'writer', 'readonly'], role)) {
        links.push(this.renderBots())
        links.push(this.renderApplications())
      }
      // if (lodash.includes(['owner', 'writer', 'operator', 'readonly'], role)) {
      //   links.push(this.renderOperator())
      // }
      if (lodash.includes(['owner', 'writer', 'readonly'], role)) {
        links.push(this.renderAnalytics())
      }
      if (lodash.includes(['owner'], role)) {
        links.push(this.renderConfiguration())
        links.push(this.renderAdministration())
      }
      return <ul className="nav nav-pills nav-stacked dm-sidebar">{links}</ul>
    }
  }

  renderMenuForInactive() {
    const { role } = getCredentials(this.context)
    if (role === 'owner') {
      return <ul className="nav nav-pills nav-stacked dm-sidebar">{this.renderConfiguration()}</ul>
    } else {
      return null
    }
  }

  renderBots() {
    const { t } = this.context
    const { bots, pathname } = this.props
    const match = /^\/bots\/(\d+)(\/.*)?/.exec(pathname)
    const botId = match ? parseInt(match[1], 10) : null
    const bot = botId ? lodash.find(bots, { id: botId }) : null
    return (
      <li key="submenu.bots">
        <Collapsible
          open={true}
          transitionTime={200}
          overflowWhenOpen="visible"
          triggerTagName="div"
          trigger={
            <a>
              {t('menu.submenu.bots')}
              <span className="caret" />
            </a>
          }
        >
          <ul id="bots" className="submenu nav nav-pills nav-stacked collapse in">
            {this.renderMenuLink('/bots/create', t('menu.createBot'))}
            {this.renderMenuLink('/bots', t('menu.listBots'))}
            {bot ? this.renderSubMenuLink(`/bots/${bot.id}`, bot.name) : undefined}
          </ul>
        </Collapsible>
      </li>
    )
  }

  renderApplications() {
    const { t } = this.context
    const { applications, pathname } = this.props
    const match = /^\/applications\/(\d+)(\/.*)?/.exec(pathname)
    const applicationId = match ? parseInt(match[1], 10) : null
    const application = applicationId ? lodash.find(applications, { id: applicationId }) : null
    return (
      <li key="submenu.applications">
        <Collapsible
          open={true}
          transitionTime={200}
          overflowWhenOpen="visible"
          triggerTagName="div"
          trigger={
            <a>
              {t('menu.submenu.applications')}
              <span className="caret" />
            </a>
          }
        >
          <ul id="applications" className="submenu nav nav-pills nav-stacked collapse in">
            {this.renderMenuLink('/applications/new', t('menu.createApplication'))}
            {this.renderMenuLink('/applications', t('menu.listApplications'))}
            {application
              ? this.renderSubMenuLink(`/applications/${application.id}`, application.name)
              : undefined}
          </ul>
        </Collapsible>
      </li>
    )
  }

  renderOperator() {
    const { t } = this.context
    return (
      <li key="submenu.operator">
        <Collapsible
          open={true}
          transitionTime={200}
          overflowWhenOpen="visible"
          triggerTagName="div"
          trigger={
            <a>
              {t('menu.submenu.operator')}
              <span className="caret" />
            </a>
          }
        >
          <ul id="operator" className="submenu nav nav-pills nav-stacked collapse in">
            {this.renderMenuLink('/operator', t('menu.operator'))}
          </ul>
        </Collapsible>
      </li>
    )
  }

  renderAnalytics() {
    const { t } = this.context
    if (isPermitted('feature_analytics', this.context)) {
      return (
        <li key="submenu.analytics">
          <Collapsible
            open={true}
            transitionTime={200}
            overflowWhenOpen="visible"
            triggerTagName="div"
            trigger={
              <a>
                {t('menu.submenu.analytics')}
                <span className="caret" />
              </a>
            }
          >
            <ul id="analytics" className="submenu nav nav-pills nav-stacked collapse in">
              {this.renderMenuLink('/analytics', t('menu.statistics'))}
              {isPermitted('feature_push_api', this.context) &&
                this.renderMenuLink('/delivery', t('menu.delivery'))}
            </ul>
          </Collapsible>
        </li>
      )
    } else {
      return null
    }
  }

  renderConfiguration() {
    const { t } = this.context
    return (
      <li key="submenu.management">
        <Collapsible
          open={true}
          transitionTime={200}
          overflowWhenOpen="visible"
          triggerTagName="div"
          trigger={
            <a>
              {t('menu.submenu.manageAccount')}
              <span className="caret" />
            </a>
          }
        >
          <ul id="management" className="submenu nav nav-pills nav-stacked collapse in">
            {this.renderMenuLink('/account', t('menu.account'))}
            {this.renderMenuLink('/users', t('menu.users'))}
            {isPermitted('integration_email', this.context) &&
              this.renderMenuLink('/email_addresses', t('menu.emailAddresses'))}
            {isPermitted('feature_oauth', this.context) &&
              this.renderMenuLink('/oauth_clients', t('menu.oauthClients'))}
            {isPermitted('feature_endpoint', this.context) &&
              Config.enableWatson &&
              this.renderMenuLink('/endpoints', t('menu.endpoints'))}
          </ul>
        </Collapsible>
      </li>
    )
  }

  renderAdministration() {
    const { t } = this.context

    if (!isPermitted('feature_operation_history', this.context)) return

    return (
      <li key="submenu.administration">
        <Collapsible
          open={true}
          transitionTime={200}
          overflowWhenOpen="visible"
          triggerTagName="div"
          trigger={
            <a>
              {t('menu.submenu.administration')}
              <span className="caret" />
            </a>
          }
        >
          <ul id="administration" className="submenu nav nav-pills nav-stacked collapse in">
            {this.renderMenuLink('/operation_histories', t('menu.operationHistories'))}
          </ul>
        </Collapsible>
      </li>
    )
  }
}

export default Menu
