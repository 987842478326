import lodash from 'lodash'
import moment from 'moment'

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2'

import Loader from '../common/Loader'

export class ActivityGraph extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    statistics: PropTypes.object,
    height: PropTypes.number,
  }
  static defaultProps = {
    statistics: {},
    height: 300,
  }

  render() {
    const { t } = this.context
    const { statistics } = this.props

    const formatDate = message => {
      return moment(message.period).locale('en').format(t('analytics.graph.activityGraph.dateFormat'))
    }

    const data = {
      labels: lodash.map(statistics.messages, formatDate),
      datasets: [
        {
          label: t('analytics.graph.activityGraph.activeUsers'),
          type: 'line',
          data: lodash.map(statistics.messages, 'active_users'),
          fill: false,
          pointRadius: 0,
          lineTension: 0,
          borderColor: '#819FF7',
          backgroundColor: '#819FF7',
          yAxisID: 'y-axis-1',
        },
        {
          type: 'bar',
          label: t('analytics.graph.activityGraph.userMessages'),
          data: lodash.map(statistics.messages, 'user'),
          backgroundColor: '#A9E2F3',
          borderColor: '#A9E2F3',
          yAxisID: 'y-axis-2',
        },
      ],
    }

    const options = {
      tooltips: {
        mode: 'label',
      },
      scales: {
        xAxes: [
          {
            ticks: {
              autoSkipPadding: 2,
            },
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            position: 'left',
            id: 'y-axis-1',
            gridLines: {
              display: false,
            },
            ticks: {
              beginAtZero: true,
              callback: value => {
                if (Math.floor(value) === value) {
                  return value
                }
              },
            },
            scaleLabel: {
              display: true,
              labelString: t('analytics.graph.activityGraph.activeUsers'),
            },
          },
          {
            position: 'right',
            id: 'y-axis-2',
            gridLines: {
              display: false,
            },
            ticks: {
              beginAtZero: true,
              callback: value => {
                if (Math.floor(value) === value) {
                  return value
                }
              },
            },
            scaleLabel: {
              display: true,
              labelString: t('analytics.graph.activityGraph.userMessages'),
            },
          },
        ],
      },
    }

    return (
      <div className="dm-graph">
        <Loader loaded={!lodash.isEmpty(statistics)} type="overlay">
          <Bar data={data} options={options} height={this.props.height} />
        </Loader>
      </div>
    )
  }
}

export default ActivityGraph
