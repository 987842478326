import PropTypes from 'prop-types'
import React, { Component } from 'react'

class LoginUser extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    onLogout: PropTypes.func.isRequired,
  }

  render() {
    const { t } = this.context
    const { onLogout } = this.props

    return (
      <li>
        <button type="button" onClick={onLogout}>
          {t('common.logout')}
        </button>
      </li>
    )
  }
}

export default LoginUser
