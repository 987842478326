import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes } from 'redux-form'

import Loader from '../../components/common/Loader'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import { InputField } from '../../components/common/fields/FormFields'
import TextField from '../../components/common/fields/TextField'

import { addNotice } from '../../actions/notice'
import { fetchApplication } from '../../actions/application'
import { fetchDeliveryJob, createDeliveryJob } from '../../actions/delivery'

import { isFetching } from '../../helpers/selector'
import cancelUrl from '../../helpers/cancelurl'
import { replaceSystemVariableWhenOnChange } from '../../helpers/replaceSystemVariable'

const validate = data => {
  const errors = {}
  if (!data.name) {
    errors.name = 'validate.required'
  } else if (data.name.length > 100) {
    errors.name = { id: 'validate.exceededMaxLength', values: { length: 100 } }
  }
  return errors
}

class DeliveryJobEdit extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    application: PropTypes.object,
    params: PropTypes.shape({
      application_id: PropTypes.string,
      id: PropTypes.string,
    }),
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    const { application_id, id } = this.props.params

    dispatch(fetchApplication(state.session.token, application_id))
    if (this.props.params.id) {
      dispatch(fetchDeliveryJob(state.session.token, application_id, id))
    }
    cancelUrl.setRouteLeaveHook(this)
  }

  handleSave = (data, dispatch) => {
    const state = this.context.store.getState()
    const { t, router } = this.context
    const {
      application,
      params: { application_id },
    } = this.props

    if (
      !window.confirm(
        t('delivery.jobs.sendConfirmationMessage', {
          count: application.room_count,
          cost: Math.floor(application.room_count * 0.5),
        })
      )
    )
      return

    const job = {
      name: data.name,
      message: data.message,
    }
    replaceSystemVariableWhenOnChange(job, 'message', true, false)
    return dispatch(createDeliveryJob(state.session.token, application_id, job))
      .then(json =>
        router.push({
          pathname: `/delivery/${application_id}/jobs/${json.result}`,
          state: { ignoreBlocking: true },
        })
      )
      .then(() => dispatch(addNotice('info', t('common.saveSuccessMessage'))))
  }

  render() {
    const { t } = this.context
    const { application, isFetching, submitting, handleSubmit } = this.props

    return (
      <div>
        <Loader loaded={!isFetching && !submitting} type="show">
          <div className="mb-3">
            <label className="dm-title-mini">{t('delivery.jobs.applicationName')}</label>
            <div className="dm-value ml-3">{application.name}</div>
            <div className="dm-value ml-3" />
          </div>
          <form className="text-left col-md-9" onSubmit={handleSubmit(this.handleSave)}>
            <div className="form-group">
              <LabelWithTooltip htmlFor="name" className="dm-title-mini" name="delivery.jobs.name" />
              <Field
                type="text"
                name="name"
                className="form-control dm-form-control"
                maxLength="100"
                component={InputField}
              />
            </div>
            <div className="form-group">
              <LabelWithTooltip htmlFor="message" className="dm-title-mini" name="delivery.jobs.message" />
              <TextField name="message" className="form-control dm-form-control" minRows={4} />
            </div>
            <div className="form-group">
              <LabelWithTooltip
                htmlFor="name"
                className="dm-title-mini"
                name="delivery.jobs.estimatedTargetCountLabel"
              />
              <div className="dm-value ml-3">
                {t('delivery.jobs.estimatedTargetCountValue', { count: application.room_count })}
              </div>
              <div className="dm-value ml-3 note">{t('delivery.jobs.estimatedTargetCountNote')}</div>
            </div>
            <div className="form-group text-right">
              <button type="submit" className="btn btn-primary dm-btn" disabled={submitting}>
                {t('delivery.jobs.send')}
              </button>
            </div>
          </form>
        </Loader>
      </div>
    )
  }
}

const DeliveryJobEditForm = reduxForm({
  form: 'DeliveryJob',
  enableReinitialize: true,
  validate,
  shouldError: () => true,
})(DeliveryJobEdit)

const mapStateToProps = (state, props) => {
  const application = state.entities.applications[props.params.application_id] || {}
  const initialValues = state.entities.delivery_jobs[props.params.id] || {}
  replaceSystemVariableWhenOnChange(initialValues, 'message', false, false)

  return {
    application,
    initialValues,
    isFetching: isFetching(state),
  }
}

export default connect(mapStateToProps)(DeliveryJobEditForm)
