import lodash from 'lodash'
import rawRequest from './request'
import { deleteSession } from '../actions/session'
import { clearTemporaryMessages } from '../actions/chat'

import Config from './config'

let context
export const setContext = c => {
  context = c
}

export class InterruptionError {
  constructor(message, response, json = {}) {
    this.name = 'InterruptionError'
    this.message = message
    this.response = response
    this.body = JSON.stringify(json)
  }
}

export class Redirect {
  constructor(location, accessToken, payload) {
    this.location = location
    this.accessToken = accessToken
    this.payload = payload
  }
}

const defaultErrorHandler = (dispatch, path) => response => {
  if (response.ok) {
    return Promise.resolve(response)
  }
  if (response.status === 300) {
    return response.json().then(json => {
      throw new Redirect(json.location, json.access_token, { accounts: json.accounts })
    })
  }
  if (path !== '/auth' && response.status === 401) {
    dispatch(deleteSession())
    return Promise.reject(new InterruptionError(context.t('error.expiredToken'), response))
  }
  if (lodash.includes([404], response.status)) {
    context.router.replace({ pathname: '/', state: { ignoreBlocking: true } })
    return Promise.reject(new InterruptionError(context.t('error.forbidden'), response))
  }
  if (lodash.includes([413], response.status)) {
    return Promise.reject(new InterruptionError(context.t('error.payloadTooLarge'), response))
  }
  const managedStatusCodes = [400, 401, 403, 405, 409]
  if (lodash.includes(managedStatusCodes, response.status)) {
    return response.json().then(json => {
      throw new InterruptionError(json.message, response, json)
    })
  }

  if (response.status === 503) {
    if (path.endsWith('/messages')) {
      // clear sending failed messages
      dispatch(clearTemporaryMessages())
    }
    return response.json().then(json => {
      throw new InterruptionError(json.message, response)
    })
  }

  return response
    .json()
    .catch(() => ({ message: '' }))
    .then(detail => {
      throw Error(detail.message)
    })
}

const request = (path, options) => {
  options.source = 'dashboard'
  options.errorHandler = options.errorHandler || defaultErrorHandler
  return rawRequest(Config.server, path, options).catch(e => {
    if (e.constructor === Redirect) {
      if (path !== '/auth') {
        context.router.replace({ pathname: e.location, state: { ignoreBlocking: true, ...e.payload } })
      }
    } else if (!lodash.includes(['InterruptionError', 'AbortError'], e.name)) {
      context.router.replace('/error')
    }
    throw e
  })
}

const getRequest = (path = '/', options) => request(path, { ...options, method: 'GET' })

const postRequest = (path = '/', options) => request(path, { ...options, method: 'POST' })

const putRequest = (path = '/', options) => request(path, { ...options, method: 'PUT' })

const deleteRequest = (path = '/', options) => request(path, { ...options, method: 'DELETE' })

export default {
  get: getRequest,
  post: postRequest,
  put: putRequest,
  delete: deleteRequest,
}
