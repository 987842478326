import lodash from 'lodash'
import React from 'react'
import { Field, FieldArray } from 'redux-form'

import AbstractAction from './AbstractAction'
import { SelectField, CheckboxField } from '../../components/common/fields/FormFields'
import IncrementableTable from '../../components/common/IncrementableTable'
import Tooltip from '../common/Tooltip'
import LabelWithTooltip from '../common/LabelWithTooltip'
import VariableField from '../common/fields/VariableField'
import ListVariableField from '../common/fields/ListVariableField'
import TitleField from '../common/fields/TitleField'
import CardTextField from '../common/fields/CardTextField'
import CarouselButtonLabelField from '../common/fields/CarouselButtonLabelField'
import CarouselButtonValueField from '../common/fields/CarouselButtonValueField'
import UrlField from '../common/fields/UrlField'
import { lengthWithoutVariable } from '../../helpers/validation'

export class CarouselListAction extends AbstractAction {
  getTitle() {
    return this.context.t('topic.actionList.carousel_list.title')
  }
  getType() {
    const { action } = this.props
    let isPostbackExist = false
    if (action.item && action.item.actions) {
      if (
        lodash.some(action.item.actions, action => {
          return action.type === 'postback'
        })
      ) {
        isPostbackExist = true
      } else if (action.item.default_action && action.item.default_action.type === 'postback') {
        isPostbackExist = true
      }
    }
    if (isPostbackExist) {
      return 'user'
    } else {
      return 'normal'
    }
  }

  static validate = action => {
    const errors = super.validate(action)

    if (!action.item) {
      errors.item = { carousel_id: 'validate.required', text: 'validate.required' }
    } else {
      const itemErrors = {}

      if (!action.item.image_url && !action.item.text) {
        itemErrors.text = 'validate.required'
      }

      if (lodash.reject(action.item.actions, lodash.isEmpty).length === 0) {
        if (!action.item.enable_default_action) {
          itemErrors.actions = [{ type: 'validate.required', label: 'validate.required' }]
        } else if (action.item.title || action.item.text) {
          itemErrors.actions = [{ type: 'validate.required', label: 'validate.required' }]
        }
      } else {
        itemErrors.actions = action.item.actions.map(button => {
          const buttonErrors = {}

          if (!button.type) {
            buttonErrors.type = 'validate.required'
          }

          return buttonErrors
        })
      }

      if (action.item.enable_default_action) {
        if (!action.item.default_action) {
          itemErrors.default_action = { type: 'validate.required', value: 'validate.required' }
        } else {
          itemErrors.default_action = {}
          if (!action.item.default_action.type) {
            itemErrors.default_action.type = 'validate.required'
          }
        }
      }
      errors.item = itemErrors
    }
    return errors
  }

  static validateForLine = action => {
    if (lengthWithoutVariable(action.item.text) > 60) {
      return false
    }
    return true
  }

  static getVariables = (action, _props, _context) => {
    return [{ variable: action.title, text: action.title }]
  }

  renderButtons = ({ fields }) => {
    const { t } = this.context
    const columns = [
      {
        Header: (
          <th key="type">
            {t('topic.actionList.carousel_list.action.type')}
            <Tooltip name="topic.tooltip.actionList.carousel_list.action.type" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            name={`${name}.type`}
            className="form-control dm-form-control"
            items={[
              { label: t('topic.actionList.carousel.action.postback'), value: 'postback' },
              { label: t('topic.actionList.carousel.action.uri'), value: 'uri' },
            ]}
            valueKey="value"
            displayKey="label"
            empty={true}
            component={SelectField}
          />
        ),
      },
      {
        Header: (
          <th key="label">
            {t('topic.actionList.carousel_list.action.label')}
            <Tooltip name="topic.tooltip.actionList.carousel_list.action.label" />
          </th>
        ),
        // eslint-disable-next-line react/display-name
        Cell: (name, _index) => (
          <CarouselButtonLabelField
            name={`${name}.label`}
            className="form-control dm-form-control"
            ignoreValidation={(value, allValues) => !lodash.get(allValues, name)}
          />
        ),
      },
      {
        Header: (
          <th key="value">
            {t('topic.actionList.carousel_list.action.value')}
            <Tooltip name="topic.tooltip.actionList.carousel_list.action.value" />
          </th>
        ),
        Cell: (name, index) => {
          let placeholder = null
          if ((fields.get(index) || {}).type === 'uri') {
            placeholder = t('topic.actionList.carousel.action.uriPlaceholder')
          }

          return (
            <CarouselButtonValueField
              name={`${name}.value`}
              className="form-control dm-form-control"
              nullable={true}
              placeholder={placeholder}
            />
          )
        },
      },
    ]

    // Render field table for buttons in carousel's column
    return <IncrementableTable fields={fields} columns={columns} maxRows={3} />
  }

  renderDefaultAction = name => {
    const { t } = this.context
    const { action } = this.props

    let placeholder = null
    if ((action.item.default_action || {}).type === 'uri') {
      placeholder = t('topic.actionList.carousel.action.uriPlaceholder')
    }

    return (
      <table className="table table-bordered dm-table default-action">
        <thead>
          <tr>
            <th key="type" className="type">
              {t('topic.actionList.carousel_list.action.type')}
              <Tooltip name="topic.tooltip.actionList.carousel_list.action.type" />
            </th>
            <th key="value">
              {t('topic.actionList.carousel_list.action.value')}
              <Tooltip name="topic.tooltip.actionList.carousel_list.action.value" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td key="type">
              <Field
                name={`${name}.item.default_action.type`}
                className="form-control dm-form-control"
                items={[
                  { label: t('topic.actionList.carousel_list.action.postback'), value: 'postback' },
                  { label: t('topic.actionList.carousel_list.action.uri'), value: 'uri' },
                ]}
                valueKey="value"
                displayKey="label"
                empty={true}
                component={SelectField}
              />
            </td>
            <td key="value">
              <CarouselButtonValueField
                name={`${name}.item.default_action.value`}
                className="form-control dm-form-control"
                placeholder={placeholder}
              />
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  renderBody() {
    const { t } = this.context
    const { action, name } = this.props
    const enableDefaultAction = action.item.enable_default_action

    return (
      <div className="carousel_list">
        <div className="form-group row dm-form-group">
          <LabelWithTooltip
            className="dm-title-mini col-md-3"
            name="topic.actionList.carousel_list.carouselTitle"
          />
          <div className="col-md-9">
            <VariableField name={`${name}.title`} className="form-control dm-form-control" />
          </div>
        </div>
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor="variable_for_list"
            className="dm-title-mini col-md-3"
            name="topic.actionList.carousel_list.variable"
          />
          <div className="col-md-9">
            <ListVariableField name={`${name}.variable`} className="form-control dm-form-control" />
          </div>
        </div>
        <LabelWithTooltip className="dm-title-mini" name="topic.actionList.carousel_list.carousels" />
        <div className="item">
          <div className="form-group dm-form-group row">
            <LabelWithTooltip className="dm-title-mini col-md-3" name="topic.actionList.carousel_list.id" />
            <div className="col-md-9">
              <TitleField name={`${name}.item.carousel_id`} className="form-control dm-form-control" />
            </div>
          </div>
          <div className="form-group dm-form-group row">
            <LabelWithTooltip
              className="dm-title-mini col-md-3"
              name="topic.actionList.carousel_list.carouselItemTitle"
            />
            <div className="col-md-9">
              <TitleField
                name={`${name}.item.title`}
                className="form-control dm-form-control"
                nullable={true}
              />
            </div>
          </div>
          <div className="form-group dm-form-group row">
            <LabelWithTooltip className="dm-title-mini col-md-3" name="topic.actionList.carousel_list.body" />
            <div className="col-md-9">
              <CardTextField
                name={`${name}.item.text`}
                className="form-control dm-form-control"
                nullable={true}
              />
            </div>
          </div>
          <div className="form-group dm-form-group row">
            <LabelWithTooltip
              className="dm-title-mini col-md-3"
              name="topic.actionList.carousel_list.image_url"
            />
            <div className="col-md-9">
              <UrlField
                name={`${name}.item.image_url`}
                className="form-control dm-form-control"
                nullable={true}
              />
              <ul className="notes">
                <li>{t('topic.actionList.carousel.notes.authorizedFileFormat')}</li>
                <li>{t('topic.actionList.carousel.notes.protocol')}</li>
              </ul>
            </div>
          </div>
          <div>
            <LabelWithTooltip className="dm-title-mini" name="topic.actionList.carousel_list.choices" />
            <FieldArray name={`${name}.item.actions`} component={this.renderButtons} />
          </div>
          <div className="form-group dm-form-group mt-1">
            <div className="form-inline">
              <Field
                type="checkbox"
                name={`${name}.item.enable_default_action`}
                component={CheckboxField}
                className="form-control"
              />
              <LabelWithTooltip
                className="dm-title-mini pl-1"
                name="topic.actionList.carousel_list.defaultAction"
                htmlFor={`${name}.item.enable_default_action`}
              />
            </div>
            {enableDefaultAction && this.renderDefaultAction(name)}
          </div>
        </div>
      </div>
    )
  }
}

export default CarouselListAction
