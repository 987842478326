export const mobileEmailDomains = [
  'docomo.ne.jp',
  'softbank.ne.jp',
  'i.softbank.jp',
  'disney.ne.jp',
  'd.vodafone.ne.jp',
  'h.vodafone.ne.jp',
  't.vodafone.ne.jp',
  'c.vodafone.ne.jp',
  'r.vodafone.ne.jp',
  'k.vodafone.ne.jp',
  'n.vodafone.ne.jp',
  's.vodafone.ne.jp',
  'q.vodafone.ne.jp',
  'jp-d.ne.jp',
  'jp-h.ne.jp',
  'jp-t.ne.jp',
  'jp-c.ne.jp',
  'jp-c.ne.jp',
  'jp-k.ne.jp',
  'jp-n.ne.jp',
  'jp-s.ne.jp',
  'jp-q.ne.jp',
  'ezweb.ne.jp',
  'au.com',
  'biz.ezweb.ne.jp',
  'ido.ne.jp',
  'sky.tkk.ne.jp',
  'sky.tkc.ne.jp',
  'sky.tu-ka.ne.jp',
  'pdx.ne.jp',
  'di.pdx.ne.jp',
  'dj.pdx.ne.jp',
  'dk.pdx.ne.jp',
  'wm.pdx.ne.jp',
  'willcom.com',
  'emnet.ne.jp',
]
