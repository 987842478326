import lodash from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form'
import { CheckboxField, SelectField } from '../../components/common/fields/FormFields'
import LabelWithTooltip from '../common/LabelWithTooltip'

import AbstractAction from './AbstractAction'

import { isPermitted } from '../../helpers/permission'

export class RunIntegrationAction extends AbstractAction {
  static propTypes = {
    ...AbstractAction.propTypes,
    integrations: PropTypes.array.isRequired,
  }

  static validate = action => {
    const errors = super.validate(action)
    if (!action.integration_id) {
      errors.integration_id = 'validate.required'
    }

    return errors
  }

  static getVariables = (action, props, _context) => {
    const { integrations } = props
    const integration = lodash.first(lodash.filter(integrations, { id: parseInt(action.integration_id, 10) }))
    if (!integration) return []
    if (integration.type === 'http') {
      const variables = integration.response_map.map(({ _from, to }) => {
        return { variable: to, text: to }
      })
      if (integration.response_body_variable) {
        variables.push({
          variable: integration.response_body_variable,
          text: integration.response_body_variable,
        })
      }
      if (integration.result_variable) {
        variables.push({ variable: integration.result_variable, text: integration.result_variable })
      }
      return variables
    } else if (integration.type === 'salesforce') {
      if (integration.variable_name) {
        return [{ variable: integration.variable_name, text: integration.variable_name }]
      } else {
        return integration.response_map.map(({ _from, to }) => {
          return { variable: to, text: to }
        })
      }
    } else if (integration.type === 'office365') {
      return integration.response_map.map(({ _from, to }) => {
        return { variable: to, text: to }
      })
    } else if (integration.type === 'excel') {
      if (integration.variable_name) {
        return [{ variable: integration.variable_name, text: integration.variable_name }]
      } else {
        return []
      }
    } else {
      return []
    }
  }

  getTitle() {
    return this.context.t('topic.actionList.integration.title')
  }

  findIntegration = id => {
    const integration = this.props.integrations.filter(integration => integration.id === parseInt(id, 10))[0]
    return integration || {}
  }

  renderBody() {
    const { t } = this.context
    const { name, integrations, action } = this.props

    const normalIntegrations = lodash.reject(integrations, { type: 'tool' })
    const integration = this.findIntegration(action.integration_id)
    const variables = lodash.map(RunIntegrationAction.getVariables(action, this.props), 'variable')

    return (
      <div className="run_integration">
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor="integration"
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.integration.target"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <Field
              name={`${name}.integration_id`}
              className="form-control"
              component={SelectField}
              items={normalIntegrations}
              valueKey="id"
              displayKey="name"
              empty={true}
              parse={value => value && parseInt(value, 10)}
              order="asc"
            />
          </div>
        </div>
        {isPermitted('feature_http_integration_status_code_handing', this.context) &&
          integration.type === 'http' && (
            <div className="row form-group">
              <div className="form-inline col-sm-12">
                <Field
                  type="checkbox"
                  name={`${name}.exit_scenario_if_failed`}
                  id={`${name}.exit_scenario_if_failed`}
                  component={CheckboxField}
                  className="form-control"
                />
                <LabelWithTooltip
                  htmlFor={`${name}.exit_scenario_if_failed`}
                  className="control-label"
                  name="topic.actionList.integration.exitTopicIfFailed"
                />
              </div>
            </div>
          )}
        {variables.length > 0 && (
          <div className="variables">
            <label>{t('topic.actionList.integration.availableVariables')}</label>
            <ul className="variable-list">
              {variables.map((variable, index) => (
                <li key={index} className="variable-item">
                  {variable}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    )
  }
}

export default RunIntegrationAction
