import lodash from 'lodash'
import { isEmail, isURL } from 'validator'

export const lengthWithoutVariable = text => {
  if (!text) return 0
  if (typeof text.replace !== 'function') return 0

  //  Ignore length of variables
  text = text.replace(/{?{{.*?}}}?/g, '')

  return text.length
}

export const lengthWithoutVariableAndUrl = text => {
  if (!text) return 0
  if (typeof text.replace !== 'function') return 0

  //  Ignore length of variables
  text = text.replace(/{?{{.*?}}}?/g, '')

  //  Count length of URL that converted to markup language
  text = text.replace(/\[(.+)\]\((?:https?|notes|file):\/\/[^\s]*\)/g, (m, $1) => $1)

  //  Count length of URL from ellipsed URL (Do not shorten the file scheme URL)
  text = text.replace(
    /((?:https?|notes):\/\/[^\s/]*)\/?([^\s]*)/g,
    (m, $1, $2) => `${$2 ? $1.concat('/...') : $1}`
  )

  return text.length
}

export const isValidLengthForNormalText = text => {
  if (!text) return true
  return lengthWithoutVariableAndUrl(text) <= 280 && text.length <= 1000
}

export const isContainVariable = text => {
  return text.length !== lengthWithoutVariable(text)
}

export const isValidUrlOrVariable = text => {
  if (isContainVariable(text)) {
    return true
  } else if (
    isURL(text, {
      protocols: ['http', 'https', 'ftp', 'notes', 'file'],
      require_tld: false,
      require_protocol: true,
    })
  ) {
    return true
  } else {
    return false
  }
}

export const splitCommaSeparatedAddresses = text => {
  return lodash.compact(text.split(',').map(text => text.trim()))
}

export const isValidEmailOrVariable = (text, allowArray = false) => {
  if (!text) {
    return false
  } else if (allowArray) {
    const addresses = splitCommaSeparatedAddresses(text)
    return lodash.every(addresses, address => isValidEmailOrVariable(address, false))
  } else {
    return isContainVariable(text) || isEmail(text)
  }
}

export const isIncludingInvalidSymbols = text => {
  if (!text) return false

  return !!text.match(/[\s!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~\u3000]/)
}

export const hasValidationError = object => {
  // Check leaf node
  if (!lodash.isArray(object) && !lodash.isObject(object)) return !!object

  return lodash.some(object, value => hasValidationError(value))
}
