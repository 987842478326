import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Config from '../../helpers/config'

class CarouselItems extends Component {
  constructor() {
    super()
    this.state = {
      activeItemIndex: 0,
      isTransit: false,
    }
  }
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    id: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    showImage: PropTypes.bool,
    showAction: PropTypes.oneOf(['show', 'readonly', 'hide']).isRequired,
    sendMessage: PropTypes.func,
    handleDefaultAction: PropTypes.func,
    parse: PropTypes.func.isRequired,
    enableSwitchChatBot: PropTypes.bool,
  }

  slide = (currentIndex, nextIndex, direction) => {
    if (this.state.isTransit) return
    this.setState({ activeItemIndex: nextIndex, isTransit: true })

    const items = Array.from(document.querySelectorAll(`.carousel-${this.props.id} .item`))

    const currentItem = items[currentIndex]
    const nextItem = items[nextIndex]

    const onTransitionEnd = () => {
      this.setState({ isTransit: false })
      // classList.remove get only a single class in IE
      currentItem.classList.remove('active')
      currentItem.classList.remove('right')
      currentItem.classList.remove('left')

      nextItem.classList.add('active')
      nextItem.classList.remove('next')
      nextItem.classList.remove('left')
      nextItem.classList.remove('prev')
      nextItem.classList.remove('right')

      nextItem.removeEventListener('transitionend', onTransitionEnd)
    }

    nextItem.addEventListener('transitionend', onTransitionEnd)

    nextItem.classList.add(direction === 'left' ? 'next' : 'prev')
    setTimeout(() => {
      currentItem.classList.add(direction)
      nextItem.classList.add(direction)
    }, 1)
  }

  slideLeft = () => {
    const items = Array.from(document.querySelectorAll(`.carousel-${this.props.id} .item`))
    const currentIndex = items.findIndex(x => x.classList.contains('active'))
    const nextIndex = (currentIndex + 1 + items.length) % items.length
    this.slide(currentIndex, nextIndex, 'left')
  }

  slideRight = () => {
    const items = Array.from(document.querySelectorAll(`.carousel-${this.props.id} .item`))
    const currentIndex = items.findIndex(x => x.classList.contains('active'))
    const nextIndex = (currentIndex - 1 + items.length) % items.length
    this.slide(currentIndex, nextIndex, 'right')
  }

  slideTarget = nextIndex => {
    const items = Array.from(document.querySelectorAll(`.carousel-${this.props.id} .item`))
    const currentIndex = items.findIndex(x => x.classList.contains('active'))
    if (nextIndex === currentIndex) return
    this.slide(currentIndex, nextIndex, currentIndex < nextIndex ? 'left' : 'right')
  }

  renderButtons = item => {
    const { sendMessage, showAction, enableSwitchChatBot } = this.props

    const buttons = item.actions.map((button, index) => {
      if (button.type === 'uri') {
        let linkTarget = '_blank'
        if (enableSwitchChatBot && button.value.startsWith(Config.embeddedChatURL)) {
          linkTarget = undefined
        }

        return (
          <div key={index}>
            <a className="btn" href={button.value} target={linkTarget} rel="noopener noreferrer">
              {button.label}
            </a>
          </div>
        )
      } else {
        if (showAction === 'show') {
          // postback
          const dataLabel = item.title ? `[${item.title}] ${button.label}` : button.label
          const title = button.tooltip || button.long_label || button.label || ''
          return (
            <div key={index}>
              <button
                type="button"
                className="btn"
                data-label={dataLabel}
                data-value={button.value}
                title={title}
                onClick={sendMessage}
              >
                {button.label}
              </button>
            </div>
          )
        } else {
          return (
            <div key={index}>
              <span className="btn disable" title={button.label}>
                {button.label}
              </span>
            </div>
          )
        }
      }
    })

    return (
      <React.Fragment>
        {!lodash.isEmpty(buttons) && (
          <React.Fragment>
            <div className="panel-border" />
            <div className="btn-group btn-group-justified">{buttons}</div>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  renderIndicators = () => {
    const { items, showImage } = this.props
    const { activeItemIndex } = this.state
    const indicatorStyle = {}

    if (!items) return null

    if (showImage) {
      indicatorStyle.top = '153px' // image height (173px) - indicator height (20px)
    } else {
      indicatorStyle.bottom = `${items[0].actions.length * 36}px`
    }

    const indicator = items.map((_item, index) => {
      return (
        <li
          key={index}
          data-target={`#${this.props.id}`}
          data-slide-to={`${index}`}
          className={index === activeItemIndex ? 'active' : ''}
          onClick={() => this.slideTarget(index)}
        />
      )
    })
    return (
      <ol className="carousel-indicators" style={indicatorStyle}>
        {indicator}
      </ol>
    )
  }

  render() {
    const { items, showAction, showImage, parse, handleDefaultAction, enableSwitchChatBot } = this.props
    const parseOptions = { enableMarkdown: true, enableSwitchChatBot }
    const columns = items.map((item, index) => {
      const columnClass = index === 0 ? 'item active' : 'item'
      const defaultAction = item.default_action || {}
      const showCaption = !!item.title || !!item.text
      if (defaultAction.type === 'postback' && showAction === 'show') {
        return (
          <div key={index} className={columnClass}>
            <div className="carousel-body" role="button" onClick={e => handleDefaultAction(e, defaultAction)}>
              {showImage && <img src={item.image_url} alt="" />}
              {showCaption && (
                <div className="caption">
                  <label>{item.title}</label>
                  <p>{parse(item.text, parseOptions)}</p>
                </div>
              )}
            </div>
            {this.renderButtons(item)}
          </div>
        )
      } else if (defaultAction.type === 'uri') {
        let linkTarget = '_blank'
        if (enableSwitchChatBot && defaultAction.value.startsWith(Config.embeddedChatURL)) {
          linkTarget = undefined
        }

        return (
          <div key={index} className={columnClass}>
            <div className="carousel-body">
              <a href={defaultAction.value} target={linkTarget} rel="noopener noreferrer">
                {showImage && <img src={item.image_url} alt="" />}
                {showCaption && (
                  <div className="caption">
                    <label>{item.title}</label>
                    <p>{parse(item.text, parseOptions)}</p>
                  </div>
                )}
              </a>
            </div>
            {this.renderButtons(item)}
          </div>
        )
      } else {
        return (
          <div key={index} className={columnClass}>
            <div className="carousel-body">
              {showImage && <img src={item.image_url} alt="" />}
              {showCaption && (
                <div className="caption">
                  <label>{item.title}</label>
                  <p>{parse(item.text, parseOptions)}</p>
                </div>
              )}
            </div>
            {this.renderButtons(item)}
          </div>
        )
      }
    })

    return (
      <div id={this.props.id} className="carousel slide" data-ride="carousel" data-interval="false">
        {columns.length >= 2 && this.renderIndicators()}
        <div className={`carousel-inner carousel-${this.props.id}`} role="listbox">
          {columns}
        </div>
        {columns.length >= 2 && (
          <div>
            <a className="left carousel-control" role="button" data-slide="prev" onClick={this.slideRight}>
              <span className="icon-arrows-left" aria-hidden="true" />
            </a>
            <a className="right carousel-control" role="button" data-slide="next" onClick={this.slideLeft}>
              <span className="icon-arrows-right" aria-hidden="true" />
            </a>
          </div>
        )}
      </div>
    )
  }
}

export default CarouselItems
