import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import MessageIcon from './MessageIcon'

import AbstractMessage from './AbstractMessage'

class UnknownMessage extends AbstractMessage {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  render() {
    const { sender_type, timestamp, icon_url, fallbackIconUrl, isLoaded } = this.props
    const { t } = this.context

    const textNode = <p className="text">{t('operator.messages.typeUnKnown')}</p>
    return (
      <li className={classnames(this.getMessageClasses())}>
        <div className="body">
          <MessageIcon
            sender_type={sender_type}
            url={icon_url}
            fallbackUrl={fallbackIconUrl}
            isLoaded={isLoaded}
          />
          <div className="balloon">
            <div className="content">{textNode}</div>
            {this.renderTimestamp(timestamp)}
          </div>
        </div>
      </li>
    )
  }
}

export default UnknownMessage
