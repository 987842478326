import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Collapsible from 'react-collapsible'
import { Field, Fields } from 'redux-form'
import Sticky from 'react-sticky-state'

import { InputField, SelectField, CheckboxField, TextAreaField } from '../common/fields/FormFields'
import Tooltip from '../common/Tooltip'
import LabelWithTooltip from '../common/LabelWithTooltip'
import ActionList from '../topic/ActionList'
import ActionSubList from '../topic/ActionSubList'

import { filterSystemVariables, filterUpperVariables } from '../../helpers/replaceSystemVariable'

export class TaskEditComponent extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    bot: PropTypes.object.isRequired,
    task_id: PropTypes.string,
    type: PropTypes.string,
    actions: PropTypes.array,
    integrations: PropTypes.array.isRequired,
    tasks: PropTypes.array.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    isLoadedImage: PropTypes.bool,
    onChangeImageFile: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    variables: PropTypes.array.isRequired,
    onChangeConditionType: PropTypes.func.isRequired,
    isExponential: PropTypes.bool,
    useTimeoutMessage: PropTypes.bool,
    condition_groups: PropTypes.array,
  }

  static defaultProps = {
    type: 'once',
    actions: [],
    examples: [],
    condition_groups: [],
  }

  onSubmit = _e => {
    document.activeElement.blur()
  }

  renderError = fields => {
    const { t } = this.context
    const error = lodash.get(lodash.find(fields, 'meta.error'), 'meta.error')
    const touched = lodash.some(fields, 'meta.touched')
    if (error && touched) {
      return <div className="error delay">{t(error)}</div>
    } else {
      return null
    }
  }

  render() {
    const { t } = this.context
    const {
      submitting,
      bot,
      task_id,
      type,
      actions,
      variables,
      integrations,
      tasks,
      handleSave,
      handleDelete,
      isLoadedImage,
      onChangeImageFile,
      onError,
      onChangeConditionType,
      condition_groups,
    } = this.props

    const restrictedActions = [
      'slot',
      'confirm',
      'choose',
      'carousel',
      'form',
      'operator',
      'exit_topic',
      'rerun_scenario',
      'change_scenario',
      'choose_scenario',
      'choose_list',
      'carousel_list',
      'faq',
      'generate_answer',
    ]

    const systemVariables = filterSystemVariables()
    const chatbotConstants = lodash.map(bot.constant_definitions, 'name')
    const userVariables = filterUpperVariables(lodash.map(bot.memory_definitions, 'name'), [
      ...systemVariables,
    ])
    const scenarioVariables = filterUpperVariables(lodash.map(variables, 'variable'), [
      ...systemVariables,
      ...userVariables,
    ])
    const conditionVariables = lodash.map(condition_groups, 'name')

    const types = [
      { value: 'once', text: t('task.types.once') },
      { value: 'repeat', text: t('task.types.repeat') },
    ]

    const units = [
      { value: 'minutes', display: t('task.delay.minutes') },
      { value: 'hours', display: t('task.delay.hours') },
      { value: 'days', display: t('task.delay.days') },
    ]

    return (
      <div className="task">
        <div className="row dm-topics">
          <form className="text-left col-md-9" onSubmit={handleSave}>
            <div className="form-group">
              <LabelWithTooltip htmlFor="name" className="dm-title" name="task.name" />
              <Field
                name="name"
                component={InputField}
                type="text"
                className="form-control dm-form-control"
                maxLength="255"
              />
            </div>
            <LabelWithTooltip htmlFor="timing" className="dm-title" name="task.timing" />
            <div className="timing">
              <div className="form-group dm-form-group row">
                <LabelWithTooltip htmlFor="type" className="dm-label col-sm-3" name="task.type" />
                <div className="col-sm-9">
                  <Field
                    name="type"
                    items={types}
                    valueKey="value"
                    displayKey="text"
                    className="form-control dm-form-control"
                    component={SelectField}
                  />
                </div>
              </div>
              <div key="start_delay" className="form-group dm-form-group row">
                <LabelWithTooltip
                  htmlFor="start_delay_value"
                  className="dm-label col-sm-3"
                  name="task.start"
                />
                <div className="form-inline col-sm-9">
                  <label className="dm-title-mini">{t('task.delay.beforeLabel')}</label>
                  <Field
                    name="start_delay_value"
                    className="form-control text-right"
                    component={InputField}
                    maxLength="10"
                    size="2"
                  />
                  <Field
                    name="start_delay_unit"
                    className="form-control"
                    component={SelectField}
                    items={units}
                    valueKey="value"
                    displayKey="display"
                    empty={true}
                  />
                  <label className="dm-title-mini">{t('task.delay.afterLabel')}</label>
                  <Fields
                    names={['start', 'start_delay_value', 'start_delay_unit']}
                    component={this.renderError}
                  />
                </div>
              </div>
              {type === 'repeat' && this.renderRepeatConfiguration()}
            </div>
            <div className="form-group">
              <label className="dm-title">
                {t('task.actions')}
                <Tooltip name="task.tooltip.actionsCenter" />
              </label>
              <ActionList
                actions={actions}
                integrations={integrations}
                tasks={tasks}
                isLoadedImage={isLoadedImage}
                onChangeImageFile={onChangeImageFile}
                onError={onError}
                userVariables={userVariables}
                chatbotConstants={chatbotConstants}
                variables={variables}
                onChangeConditionType={onChangeConditionType}
                restrictedActions={restrictedActions}
                isValidateCondition={false}
                condition_groups={condition_groups}
              />
            </div>
            <Sticky>
              <div className="sticky bottom">
                <div className="dm-sticky-buttons">
                  <button
                    type="submit"
                    className="btn btn-primary dm-btn"
                    disabled={submitting}
                    onClick={this.onSubmit}
                  >
                    {t('common.save')}
                  </button>
                  {task_id && (
                    <button
                      type="button"
                      className="btn btn-danger dm-btn"
                      onClick={handleDelete}
                      disabled={submitting}
                    >
                      {t('common.delete')}
                    </button>
                  )}
                </div>
              </div>
            </Sticky>
          </form>
          <div className="hidden-sm-down col-md-3">
            <div className="dm-topic-sidebar">
              <ActionSubList
                type="task"
                actions={actions}
                integrations={integrations}
                condition_groups={condition_groups}
              />
              <Collapsible
                trigger={<LabelWithTooltip name="topic.variables.title" direction="left" />}
                transitionTime={300}
                triggerTagName="h4"
                open={true}
                overflowWhenOpen="visible"
              >
                <ul className="nav nav-pills nav-stacked variables">
                  {scenarioVariables.length > 0 && [
                    <li key="header" className="header">
                      {t('topic.variables.scenario')}
                    </li>,
                    <li key="body" className="body">
                      <ul>
                        {scenarioVariables.map((variable, index) => (
                          <li key={index}>{variable}</li>
                        ))}
                      </ul>
                    </li>,
                  ]}
                  {userVariables.length > 0 && [
                    <li key="header" className="header">
                      {t('topic.variables.user')}
                    </li>,
                    <li key="body" className="body">
                      <ul>
                        {userVariables.map((variable, index) => (
                          <li key={index}>{variable}</li>
                        ))}
                      </ul>
                    </li>,
                  ]}
                  {systemVariables.length > 0 && [
                    <li key="header" className="header">
                      {t('topic.variables.system')}
                    </li>,
                    <li key="body" className="body">
                      <ul>
                        {systemVariables.map((variable, index) => (
                          <li key={index}>{t('systemVariables.' + variable)}</li>
                        ))}
                      </ul>
                    </li>,
                  ]}
                  {chatbotConstants.length > 0 && [
                    <li key="header" className="header">
                      {t('topic.variables.chatbotConstant')}
                    </li>,
                    <li key="body" className="body">
                      <ul>
                        {chatbotConstants.map((constant, index) => (
                          <li key={index}>{constant}</li>
                        ))}
                      </ul>
                    </li>,
                  ]}
                  {conditionVariables.length > 0 && [
                    <li key="header" className="header">
                      {t('condition_group.title')}
                    </li>,
                    <li key="body" className="body">
                      <ul>
                        {conditionVariables.map((variable, index) => (
                          <li key={index}>{variable}</li>
                        ))}
                      </ul>
                    </li>,
                  ]}
                </ul>
              </Collapsible>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderRepeatConfiguration = () => {
    const { t } = this.context
    const { isExponential, useTimeoutMessage } = this.props
    const units = [
      { value: 'minutes', display: t('task.delay.minutes') },
      { value: 'hours', display: t('task.delay.hours') },
      { value: 'days', display: t('task.delay.days') },
    ]

    return (
      <div>
        <div key="end_delay" className="form-group dm-form-group row">
          <LabelWithTooltip htmlFor="end_delay_value" className="dm-label col-sm-3" name="task.end" />
          <div className="form-inline col-sm-9">
            <label className="dm-title-mini">{t('task.delay.beforeLabel')}</label>
            <Field
              name="end_delay_value"
              className="form-control text-right"
              component={InputField}
              maxLength="10"
              size="2"
            />
            <Field
              name="end_delay_unit"
              className="form-control"
              component={SelectField}
              items={units}
              valueKey="value"
              displayKey="display"
              empty={true}
            />
            <label className="dm-title-mini">{t('task.delay.afterLabel')}</label>
            <Fields names={['end', 'end_delay_value', 'end_delay_unit']} component={this.renderError} />
          </div>
        </div>
        {!isExponential && (
          <div key="interval" className="form-group dm-form-group row">
            <LabelWithTooltip htmlFor="interval" className="dm-label col-sm-3" name="task.interval" />
            <div className="col-sm-9">
              <Field
                name="interval"
                component={InputField}
                type="text"
                className="form-control dm-form-control"
                maxLength="30"
              />
            </div>
          </div>
        )}
        <div key="use_timeout_message" className="form-group">
          <Field type="checkbox" name="use_timeout_message" component={CheckboxField} />
          <LabelWithTooltip
            htmlFor="use_timeout_message"
            className="dm-checkbox form-control-static"
            name="task.useTimeoutMessage"
          />
        </div>
        {useTimeoutMessage && (
          <div key="timeout_message" className="form-group dm-form-group row">
            <LabelWithTooltip
              htmlFor="timeout_message"
              className="dm-label col-sm-3"
              name="task.timeoutMessage"
            />
            <div className="col-sm-9">
              <Field
                name="timeout_message"
                component={TextAreaField}
                className="form-control dm-form-control"
                minRows={4}
                maxLength="1000"
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default TaskEditComponent
