import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import Config from '../../helpers/config'
import AbstractMessage from './AbstractMessage'
import MessageIcon from './MessageIcon'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

class ChooseMessage extends AbstractMessage {
  static propTypes = {
    ...AbstractMessage.propTypes,
    showAction: PropTypes.oneOf(['show', 'readonly', 'hide']).isRequired,
    handleSendMessage: PropTypes.func,
  }

  sendMessage = e => {
    e.preventDefault()
    this.props.handleSendMessage({
      text: e.target.dataset.label,
      value: e.target.dataset.value,
      is_select: true,
    })
  }

  render() {
    const {
      mode,
      sender_type,
      content,
      timestamp,
      showAction,
      iconUrl,
      fallbackIconUrl,
      isLoaded,
      isSimulator,
    } = this.props
    const parseOptions = { enableMarkdown: true, enableSwitchChatBot: mode === 'client' && !isSimulator }
    const buttons = content.actions.map((action, index) => {
      if (showAction === 'show') {
        const ellipsedLabel = this.ellipse(action.label, Config.maximumChoiceLabelLength)
        const title = action.tooltip || action.long_label || action.label || ''

        return action.type === 'uri' ? (
          <a key={index} className="btn" href={action.value} target="_blank" rel="noopener noreferrer">
            {action.label}
          </a>
        ) : (
          <button
            type="button"
            key={index}
            className="btn"
            title={title}
            data-value={action.value}
            data-label={action.label}
            onClick={this.sendMessage}
          >
            {ellipsedLabel}
          </button>
        )
      } else {
        return (
          <span key={index} className="btn disable">
            {action.label}
          </span>
        )
      }
    })
    return (
      <li className={classnames(this.getMessageClasses({ 'is-confirm': true }))}>
        <div className="body">
          {sender_type !== 'client' && (
            <MessageIcon
              sender_type={sender_type}
              url={iconUrl}
              fallbackUrl={fallbackIconUrl}
              isLoaded={isLoaded}
            />
          )}
          <div className="balloon">
            <div className="panel panel-default dm-panel choose">
              <div className="panel-heading" title={content.title}>
                <small className="title">{content.title}</small>
              </div>
              {content.image_url && (
                <div className="panel-image">
                  <img src={content.image_url} alt="" />
                </div>
              )}
              <div className="panel-body">{this.parse(content.text, parseOptions)}</div>
              {showAction !== 'hide' && (
                <React.Fragment>
                  <div className="panel-border" />
                  <div className="btn-group btn-group-vertical">{buttons}</div>
                  {this.renderFooterButtons(content.footerButtons)}
                  {this.renderDefaultFooterButtons('panel')}
                </React.Fragment>
              )}
            </div>
            {this.renderTimestamp(timestamp)}
          </div>
        </div>
      </li>
    )
  }

  renderFooterButtons = buttons => {
    if (!buttons) return null
    if (buttons.length === 0) return null

    const settings = {
      dots: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
    }

    const buttonNodes = buttons.map(button => (
      <div key={button}>
        <button
          type="button"
          className="dm-btn btn footer-btn"
          title={button}
          data-value={button}
          data-label={button}
          onClick={this.sendMessage}
        >
          <span>{button}</span>
        </button>
      </div>
    ))
    return (
      <React.Fragment>
        <div className="footer-border" />
        <div className="footer-btns-wrapper">
          <div className="slider-container">
            <Slider {...settings}>{buttonNodes}</Slider>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ChooseMessage
