import lodash from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { CheckboxField, InputField } from '../../components/common/fields/FormFields'
import LabelWithTooltip from '../common/LabelWithTooltip'
import { isPermitted } from '../../helpers/permission'

import AbstractAction from './AbstractAction'
import { TextAreaField, MultipleSelectField } from '../../components/common/fields/FormFields'

export class AiAssistantAction extends AbstractAction {
  static propTypes = {
    ...AbstractAction.propTypes,
    integrations: PropTypes.array.isRequired,
  }

  getTitle() {
    return this.context.t('topic.actionList.ai_assistant.title')
  }

  static validate = action => {
    const errors = super.validate(action)

    if (action.enable_set_variable) {
      if (!action.variable_name) {
        errors.variable_name = 'validate.required'
      } else if (action.variable_name.length > 40) {
        errors.variable_name = { id: 'validate.exceededMaxLength', values: { length: 40 } }
      }
    }
    return errors
  }

  static getVariables = (action, _props, _context) => {
    if (action.variable_name) {
      return [{ variable: action.variable_name, text: action.variable_name }]
    } else {
      return []
    }
  }

  renderBody() {
    const { t } = this.context
    const { action, name, integrations } = this.props

    const allowToolIntegration = isPermitted('integration_tool', this.context)
    const toolIntegrations = lodash.filter(integrations, { type: 'tool' })

    return (
      <div className="text_message">
        <div className="row">
          <LabelWithTooltip
            htmlFor="message"
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.ai_assistant.prompt"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <Field
              name={`${name}.prompt`}
              className="form-control"
              nullable="true"
              placeholder={t('topic.actionList.ai_assistant.promptPlaceholder')}
              component={TextAreaField}
            />
          </div>
        </div>
        {allowToolIntegration && (
          <div className="row form-group">
            <LabelWithTooltip
              htmlFor="integration"
              className="control-label col-lg-2 col-md-2 col-sm-12"
              name="topic.actionList.ai_assistant.integrations"
            />
            <div className="col-lg-10 col-md-10 col-sm-12">
              <Field
                name={`${name}.integration_ids`}
                component={MultipleSelectField}
                items={toolIntegrations}
                valueKey="id"
                displayKey="name"
                order="asc"
                placeholder={t('topic.actionList.ai_assistant.integrationsPlaceholder')}
                noOptionsMessage={() => t('topic.actionList.ai_assistant.noOptionsMessage')}
              />
            </div>
          </div>
        )}
        <div className="form-group form-inline mt-1">
          <Field
            name={`${name}.ignore_error`}
            id={`${name}.ignore_error`}
            type="checkbox"
            component={CheckboxField}
            className="form-control"
          />
          <LabelWithTooltip
            htmlFor={`${name}.ignore_error`}
            name="topic.actionList.ai_assistant.ignoreError"
          />
        </div>
        <div className="form-group form-inline mt-1">
          <Field
            name={`${name}.enable_set_variable`}
            id={`${name}.enable_set_variable`}
            type="checkbox"
            component={CheckboxField}
            className="form-control"
          />
          <LabelWithTooltip
            htmlFor={`${name}.enable_set_variable`}
            name="topic.actionList.ai_assistant.enableSetVariable.title"
          />
        </div>
        {action.enable_set_variable && (
          <div className="form-group with-indent">
            <LabelWithTooltip
              htmlFor={`${name}.variable_name`}
              className="col-sm-3"
              name="topic.actionList.ai_assistant.enableSetVariable.variableName"
            />
            <div className="col-sm-9">
              <Field
                name={`${name}.variable_name`}
                className="form-control"
                component={InputField}
                maxLength="40"
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default AiAssistantAction
