import { deleteCache } from './api'
import Schemas from '../middleware/schema'
import request from '../helpers/requestManagementAPI'

export const fetchFaqItems = (token, faq_id, filter = {}) => dispatch => {
  return request.get(`/faqs/${faq_id}/items`, {
    token,
    dispatch,
    body: filter,
    target: 'faq_items',
    schema: Schemas.faqItems,
  })
}

export const fetchFaqItem = (token, faq_id, id, errorHandler) => dispatch => {
  return request.get(`/faqs/${faq_id}/items/${id}`, {
    token,
    dispatch,
    target: 'faq_items',
    schema: Schemas.faqItem,
    errorHandler,
  })
}

export const createFaqItem = (token, faq, item) => dispatch => {
  return request.post(`/faqs/${faq.id}/items`, {
    token,
    dispatch,
    body: item,
    target: 'faq_items',
    schema: Schemas.faqItem,
  })
}

export const updateFaqItem = (token, faq, item) => dispatch => {
  return request.put(`/faqs/${faq.id}/items/${item.id}`, {
    token,
    dispatch,
    body: item,
    target: 'faq_items',
    schema: Schemas.faqItem,
  })
}

export const updateFaqItemWithoutRouting = (token, faqId, faqItemId, payload) => dispatch => {
  return request.put(`/faqs/${faqId}/items/${faqItemId}`, {
    token,
    dispatch,
    body: payload,
    target: 'faq_items',
    schema: Schemas.faqItem,
  })
}

export const deleteFaqItem = (token, id, faq_id) => dispatch => {
  return request
    .delete(`/faqs/${faq_id}/items/${id}`, {
      token,
      dispatch,
      target: 'faq_items',
    })
    .then(() => dispatch(deleteCache('faq_items', id)))
}

export const generateFaqAnswer = (
  token,
  faq_id,
  faq_item_id,
  faq_item_name,
  faq_item_question,
  fetchOptions
) => dispatch => {
  return request.post('/generator/faq/answer', {
    token,
    dispatch,
    body: {
      faq_id,
      faq_item_id,
      faq_item_name,
      faq_item_question,
    },
    target: 'generator',
    fetchOptions,
  })
}
