import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, propTypes, Field } from 'redux-form'

import { InputField } from '../common/fields/FormFields'
import LabelWithTooltip from '../common/LabelWithTooltip'
import { deleteCache } from '../../actions/api'
import { fetchPredictionFailureLogs } from '../../actions/application'
import { createLearningExclusionWord, updateLearningExclusionWord } from '../../actions/bot'

const validate = data => {
  const errors = {}
  if (!data.pattern) {
    errors.pattern = 'validate.required'
  } else if (data.pattern.length > 3000) {
    errors.pattern = { id: 'validate.exceededMaxLength', values: { length: 3000 } }
  }
  return errors
}

export class LearningExclusionWordEdit extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    ...propTypes,
    onClose: PropTypes.func,
    renderExclusionEdit: PropTypes.func,
    item: PropTypes.object,
  }

  constructor() {
    super()
    this.state = { errorMessage: '' }
  }

  handleSave = (data, dispatch) => {
    const state = this.context.store.getState()
    const { id, application, intentType, selectedFaqId } = this.props
    const botId = application.latest_bot.original_id || application.latest_bot.id

    const learningExclusionWord = {
      pattern: data.pattern,
    }

    if (id) {
      learningExclusionWord.id = id

      dispatch(updateLearningExclusionWord(state.session.token, botId, learningExclusionWord))
        .then(() => this.props.onClose())
        .then(() => dispatch(deleteCache('prediction_failure_logs')))
        .then(() => {
          if (intentType === 'topic') {
            dispatch(fetchPredictionFailureLogs(state.session.token, application.id, 'topic'))
          } else if (intentType === 'faq' && selectedFaqId) {
            dispatch(
              fetchPredictionFailureLogs(state.session.token, application.id, 'faq_item', selectedFaqId)
            )
          }
        })
        .catch(e => {
          this.setState({ errorMessage: e.message })
        })
    } else {
      dispatch(createLearningExclusionWord(state.session.token, botId, learningExclusionWord))
        .then(() => this.props.onClose())
        .then(() => dispatch(deleteCache('prediction_failure_logs')))
        .then(() => {
          if (intentType === 'topic') {
            dispatch(fetchPredictionFailureLogs(state.session.token, application.id, 'topic'))
          } else if (intentType === 'faq' && selectedFaqId) {
            dispatch(
              fetchPredictionFailureLogs(state.session.token, application.id, 'faq_item', selectedFaqId)
            )
          }
        })
        .catch(e => {
          this.setState({ errorMessage: e.message })
        })
    }
  }

  renderErrorMessage = () => {
    if (this.state.errorMessage === '') return
    return <div className="dm-error-message pb-4">{this.state.errorMessage}</div>
  }

  render() {
    const { t } = this.context
    const { handleSubmit, submitting } = this.props

    return (
      <div className="modal-background">
        <div className="modal-dialog" role="document" onClick={e => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.onClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title">{t('analytics.learningExclusionWord.title')}</h4>
            </div>
            <form className="text-left" onSubmit={handleSubmit(this.handleSave)}>
              <div className="modal-body">
                <div className="results">
                  {this.renderErrorMessage()}
                  <div className="form-group row dm-form-group" key="pattern">
                    <LabelWithTooltip
                      htmlFor="pattern"
                      className="col-sm-3"
                      name="analytics.learningExclusionWord.pattern"
                    />
                    <div className="col-sm-9">
                      <Field
                        name="pattern"
                        component={InputField}
                        type="text"
                        className="form-control dm-form-control"
                        placeholder={t('analytics.learningExclusionWord.patternPlaceholder')}
                        maxLength="3000"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary dm-btn" disabled={submitting}>
                  {t('common.save')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const LearningExclusionWordEditForm = reduxForm({
  form: 'LearningExclusionWordEdit',
  enableReinitialize: true,
  validate,
})(LearningExclusionWordEdit)

export const mapStateToProps = (state, props) => {
  let defaultValues = {
    id: undefined,
    pattern: '',
  }

  if (props.item.id) {
    const learning_exclusion_word = state.entities.learning_exclusion_words[props.item.id]
    defaultValues.id = learning_exclusion_word.id
    defaultValues.pattern = learning_exclusion_word.pattern
  }

  return {
    initialValues: defaultValues,
    id: props.item.id,
  }
}

export default connect(mapStateToProps)(LearningExclusionWordEditForm)
