import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import { InputField } from '../../components/common/fields/FormFields'

class SlotField extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
  }

  plainText = field => {
    return <span>{field.input.value}</span>
  }

  render() {
    const { name } = this.props
    return (
      <tr>
        <td>
          <Field name={`${name}.key`} component={this.plainText} />
        </td>
        <td>
          <Field
            type="text"
            className="form-control dm-form-control"
            name={`${name}.value`}
            component={InputField}
          />
        </td>
      </tr>
    )
  }
}

export default SlotField
