import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'

import DataBindingTable from '../../components/common/DataBindingTable'
import { fetchApplication } from '../../actions/application'
import { fetchDeliveryJobs } from '../../actions/delivery'
import { updateTableState } from '../../actions/table'
import { isFetching } from '../../helpers/selector'
import { getCredentials } from '../../helpers/sessionHelper'

class DeliveryJobIndex extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    application: PropTypes.object.isRequired,
    delivery_jobs: PropTypes.array.isRequired,
    isFetching: PropTypes.bool,
    tableState: PropTypes.object,
    params: PropTypes.shape({
      application_id: PropTypes.string,
    }),
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    dispatch(fetchApplication(state.session.token, this.props.params.application_id))
    dispatch(fetchDeliveryJobs(state.session.token, this.props.params.application_id))
  }

  handleRefreshClick = () => {
    const state = this.context.store.getState()
    this.props.dispatch(fetchDeliveryJobs(state.session.token, this.props.params.application_id))
  }

  updateTableState = (path, tableName, tableState) => {
    const { dispatch } = this.props
    dispatch(updateTableState(path, tableName, tableState))
  }

  render() {
    const { t } = this.context
    const {
      application,
      delivery_jobs,
      isFetching,
      tableState,
      params: { application_id },
    } = this.props
    const { timezone } = getCredentials(this.context)
    const columns = [
      {
        Header: t('delivery.jobs.name'),
        id: 'name',
        accessor: 'name',
        getFooterProps: () => ({ className: 'footer-all' }),
        Footer: (
          <Link
            className="dm-btn btn btn-primary btn-icon-plus mini"
            to={`/delivery/${application_id}/jobs/new`}
          >
            <span>{t('common.new')}</span>
          </Link>
        ),
      },
      {
        Header: t('delivery.jobs.status'),
        id: 'status',
        accessor: job => {
          const showPercentage = ['enqueued', 'error'].includes(job.status)
          if (showPercentage && job.scheduled_target_count > 0) {
            const percent = Math.min(
              ((job.success_count + job.failure_count) / job.scheduled_target_count) * 100,
              100
            ).toFixed(1)
            return t(`delivery.jobs.statuses.${job.status}`) + ` (${percent}%)`
          } else {
            return t(`delivery.jobs.statuses.${job.status}`)
          }
        },
        width: 160,
      },
      {
        Header: t('delivery.jobs.successCount'),
        id: 'success_count',
        style: {
          textAlign: 'right',
          paddingRight: '20px',
        },
        accessor: 'success_count',
        width: 100,
      },
      {
        Header: t('delivery.jobs.failureCount'),
        id: 'failure_count',
        style: {
          textAlign: 'right',
          paddingRight: '20px',
        },
        accessor: 'failure_count',
        width: 100,
      },
      {
        Header: t('common.updatedAt'),
        id: 'updatedAt',
        accessor: jobRequest =>
          jobRequest.updated_at ? moment.tz(jobRequest.updated_at, timezone).format('YYYY/MM/DD HH:mm') : '',
        width: 160,
      },
      {
        Header: t('delivery.jobs.detail'),
        id: 'detail',
        style: { textAlign: 'center' },
        accessor: 'id',
        width: 160,
        Cell: props => (
          <Link
            className="btn btn-primary dm-btn mini"
            to={`/delivery/${application_id}/jobs/${props.value}`}
          >
            {t('delivery.jobs.linkToDetail')}
          </Link>
        ),
        sortable: false,
      },
    ]

    return (
      <div>
        <div className="mb-3">
          <label className="dm-title-mini">{t('delivery.jobs.applicationName')}</label>
          <div className="dm-value ml-3">{application.name}</div>
        </div>
        <DataBindingTable
          title={t('delivery.jobs.title')}
          titleTooltip="delivery.tooltip.jobs.title"
          items={delivery_jobs}
          columns={columns}
          isFetching={isFetching}
          defaultSorted={[{ id: 'updated_at', desc: true }]}
          tableState={tableState}
          updateTableState={this.updateTableState}
          onRefresh={this.handleRefreshClick}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const application = state.entities.applications[props.params.application_id] || {}
  const ids = state.pagination.delivery_jobs.ids || []
  const delivery_jobs = ids.map(id => state.entities.delivery_jobs[id])
  return {
    application,
    delivery_jobs: delivery_jobs,
    isFetching: isFetching(state),
    tableState: state.table[window.location.pathname],
  }
}

export default connect(mapStateToProps)(DeliveryJobIndex)
