import { deleteCache } from './api'
import Schemas from '../middleware/schema'
import request from '../helpers/requestManagementAPI'

export const fetchIntegrations = (token, filter = {}) => dispatch => {
  return request.get('/integrations/', {
    token,
    dispatch,
    body: filter,
    target: 'integrations',
    schema: Schemas.integrations,
    preserve: false,
  })
}

export const fetchIntegration = (token, id, errorHandler) => dispatch => {
  return request.get(`/integrations/${id}`, {
    token,
    dispatch,
    target: 'integrations',
    schema: Schemas.integration,
    errorHandler,
  })
}

export const createIntegration = (token, integration) => dispatch => {
  return request.post('/integrations/', {
    token,
    dispatch,
    body: integration,
    target: 'integrations',
    schema: Schemas.integration,
  })
}

export const updateIntegration = (token, integration) => dispatch => {
  return request.put(`/integrations/${integration.id}`, {
    token,
    dispatch,
    body: integration,
    target: 'integrations',
    schema: Schemas.integration,
  })
}

export const deleteIntegration = (token, id) => dispatch => {
  return request
    .delete(`/integrations/${id}`, {
      token,
      dispatch,
      target: 'integrations',
    })
    .then(() => dispatch(deleteCache('integrations', id)))
}

export const requestIntegration = () => ({ type: 'REQUEST_INTEGRATION' })

export const responseIntegration = results => ({ type: 'RESPONSE_INTEGRATION', results })

export const setErrorOnIntegration = message => ({ type: 'SET_ERROR_ON_INTEGRATION', message })

export const clearIntegrationResult = isQuery => ({ type: 'CLEAR_INTEGRATION_RESULT', isQuery })

export const executeIntegration = (token, id, slots) => dispatch => {
  dispatch(requestIntegration())
  return request
    .post(`/integrations/${id}/execute`, {
      token,
      body: slots,
    })
    .then(json => dispatch(responseIntegration(json)))
    .catch(e => {
      dispatch(responseIntegration(JSON.parse(e.body).values))
      dispatch(setErrorOnIntegration(e.message))
    })
}
