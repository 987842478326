import lodash from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form'
import { InputField } from './FormFields'
import BaseField from './BaseField'

export class ListVariableField extends BaseField {
  static propTypes = {
    ...BaseField.propTypes,
    ignoreValidation: PropTypes.func,
  }

  constructor() {
    super()
    this.validate = this.validate.bind(this)
  }

  validate(value, allValues, props) {
    const { ignoreValidation } = this.props
    if (ignoreValidation && ignoreValidation(value, allValues, props)) return undefined

    const error = super.validate(value, allValues, props)
    if (error) return error

    if (value) {
      if (value.length > 100) {
        return { id: 'validate.exceededMaxLength', values: { length: 100 } }
      }
    }
  }

  render() {
    const omitKeys = ['ignoreValidation', 'maxLength', 'nullable']
    return (
      <Field
        {...lodash.omit(this.props, omitKeys)}
        maxLength="100"
        parse={value => (value === '' ? null : value)}
        component={InputField}
        validate={this.validate}
      />
    )
  }
}

export default ListVariableField
