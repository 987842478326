import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, reduxForm, propTypes } from 'redux-form'

// components
import Sidebar from '../../components/common/Sidebar'
import Loader from '../../components/common/Loader'
import TaskEditComponent from '../../components/task/TaskEdit'
import { Simulator } from '../simulator/Simulator'
import { TaskOption } from '../../components/task_option/TaskOption'

// actions
import { addNotice } from '../../actions/notice'
import { fetchBot } from '../../actions/bot'
import { fetchApplications } from '../../actions/application'
import { fetchTask, createTask, updateTask, deleteTask } from '../../actions/task'
import { fetchIntegrations } from '../../actions/integration'
import { fetchTasks } from '../../actions/task'
import { uploadImage } from '../../actions/topic'
import { fetchConditionGroups } from '../../actions/condition_group'
import { updateTableState } from '../../actions/table'

// helpers
import { isValidLengthForNormalText } from '../../helpers/validation'
import {
  validateAction,
  sanitizeActions,
  collectVariables,
  convertSystemVariablesOfAction,
} from '../../helpers/actionHelper'
import {
  getReplaceSystemVariableValue,
  replaceSystemVariableWhenOnChange,
} from '../../helpers/replaceSystemVariable'
import { isFetching } from '../../helpers/selector'
import cancelUrl, { convertToString } from '../../helpers/cancelurl'
import { isPermitted } from '../../helpers/permission'
import { checkApplications } from '../../helpers/checkApplications'

const validate = data => {
  const multiplier = {
    minutes: 1,
    hours: 60,
    days: 24 * 60,
  }

  const errors = {}
  if (!data.name) {
    errors.name = 'validate.required'
  } else if (data.name.length > 255) {
    errors.name = { id: 'validate.exceededMaxLength', values: { length: 255 } }
  }

  if (!data.start_delay_value && !lodash.isNumber(data.start_delay_value)) {
    errors.start = 'validate.required'
  } else if (!/^\d+$/.test(data.start_delay_value)) {
    errors.start = 'validate.invalid'
  }
  if (!data.start_delay_unit) {
    errors.start = 'validate.required'
  }

  const start = data.start_delay_value * multiplier[data.start_delay_unit]
  if (start > 30 * 24 * 60) {
    errors.start = 'task.invalidTaskLifetime'
  }

  if (data.type === 'repeat') {
    if (!data.is_exponential) {
      if (!data.interval && !lodash.isNumber(data.interval)) {
        errors.interval = 'validate.required'
      } else if (!/^\d+$/.test(data.interval)) {
        errors.interval = 'validate.invalid'
      } else if (parseInt(data.interval, 10) < 5) {
        errors.interval = { id: 'validate.minimumLimit', values: { minimum: 5 } }
      }
    }

    if (!data.end_delay_value && !lodash.isNumber(data.start_delay_value)) {
      errors.end = 'validate.required'
    } else if (!/^\d+$/.test(data.end_delay_value)) {
      errors.end = 'validate.invalid'
    }
    if (!data.end_delay_unit) {
      errors.end = 'validate.required'
    }

    const end = data.end_delay_value * multiplier[data.end_delay_unit]
    if (end > 30 * 24 * 60) {
      errors.end = 'task.invalidTaskLifetime'
    }

    if (start > end) {
      errors.end = 'task.taskPeriodInconsistency'
    }
  }

  if (data.use_timeout_message) {
    if (!data.timeout_message) {
      errors.timeout_message = 'validate.required'
    } else if (!isValidLengthForNormalText(data.timeout_message)) {
      errors.timeout_message = { id: 'validate.exceededMaxLength', values: { length: 280 } }
    }
  }

  if (data.actions.length === 0) {
    errors.actions = { _error: 'validate.actionRequired' }
  } else {
    const actionErrors = data.actions.map(validateAction)
    if (!lodash.every(actionErrors, lodash.isEmpty)) errors.actions = actionErrors
  }
  return errors
}

export class TaskEdit extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      bot_id: PropTypes.string,
      id: PropTypes.string,
    }),
    bot: PropTypes.object,
    task: PropTypes.object,
    actions: PropTypes.array,
    integrations: PropTypes.array,
    tasks: PropTypes.array,
    type: PropTypes.string,
    isExponential: PropTypes.bool,
    useTimeoutMessage: PropTypes.bool,
    tableStates: PropTypes.object,
    applications: PropTypes.array.isRequired,
  }

  constructor() {
    super()
    this.state = { isLoadedImage: true, sidebarIndex: 0 }
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    dispatch(fetchBot(state.session.token, this.props.params.bot_id))
    if (isPermitted('feature_integration', this.context)) {
      dispatch(fetchIntegrations(state.session.token, { bot_id: this.props.params.bot_id }))
    }
    Promise.resolve()
      .then(() => {
        if (isPermitted('feature_task', this.context)) {
          dispatch(fetchTasks(state.session.token, { bot_id: this.props.params.bot_id }))
        }
      })
      .then(() => {
        if (this.props.params.id) {
          dispatch(fetchTask(state.session.token, this.props.params.id))
          dispatch(fetchApplications(state.session.token, { original_bot_id: this.props.params.bot_id }))
        }
      })
    if (this.props.params.id) {
      dispatch(fetchConditionGroups(state.session.token, { task_id: this.props.params.id }))
    }
    cancelUrl.setRouteLeaveHook(this)
  }

  handleSave = (data, dispatch) => {
    const { bot } = this.props
    const { t } = this.context
    const session = this.context.store.getState().session
    const router = this.context.router

    const orderedActions = data.actions.map((data, index) => {
      return Object.assign({}, data, { order: index })
    })

    const task = {
      bot_id: bot.id,
      name: data.name,
      type: data.type,
      start_delay_value: data.start_delay_value,
      start_delay_unit: data.start_delay_unit,
      actions: sanitizeActions(this.context, this.props, orderedActions),
    }

    if (data.type === 'repeat') {
      task.end_delay_value = data.end_delay_value
      task.end_delay_unit = data.end_delay_unit
      task.interval_type = 'constant'
      task.interval = data.interval
      task.max_tries = data.max_tries
      task.use_timeout_message = data.use_timeout_message
      if (data.use_timeout_message) {
        task.timeout_message = getReplaceSystemVariableValue(data.timeout_message, true, true)
      } else {
        task.timeout_message = ''
      }
    }

    if (this.props.params.id) {
      task.id = this.props.params.id
      return dispatch(updateTask(session.token, task))
        .then(() => dispatch(addNotice('info', t('common.saveSuccessMessage'))))
        .then(() =>
          checkApplications(this.props.params.bot_id, this.props.applications, dispatch, this.context)
        )
    } else {
      return dispatch(createTask(session.token, task))
        .then(json =>
          router.push({ pathname: `/bots/${bot.id}/tasks/${json.result}`, state: { ignoreBlocking: true } })
        )
        .then(() => cancelUrl.setRouteLeaveHook(this))
        .then(() => dispatch(addNotice('info', t('common.saveSuccessMessage'))))
        .then(() =>
          checkApplications(this.props.params.bot_id, this.props.applications, dispatch, this.context)
        )
    }
  }

  handleDelete = () => {
    const { t } = this.context
    const { dispatch, bot, task } = this.props
    if (!window.confirm(t('common.deleteConfirmMessage', { type: t('task.title'), name: task.name }))) return

    const state = this.context.store.getState()
    const router = this.context.router
    const id = parseInt(this.props.params.id, 10)
    return dispatch(deleteTask(state.session.token, id))
      .then(() => router.push({ pathname: `/bots/${bot.id}/tasks`, state: { ignoreBlocking: true } }))
      .then(() => dispatch(addNotice('info', t('common.deleteSuccessMessage'))))
  }

  onChangeConditionType = e => {
    const fieldName = e.target.name.match(/actions\[\d+\]/)[0]
    const index = fieldName.match(/.+(\d+).+/)[1]
    if (this.props.actions[index].condition_type === 'between') {
      this.props.change(`${fieldName}.condition_value`, '')
    }
  }

  // check of file type and file size
  // maximum file size is 1MB(=1048576byte),
  // and height and width are 1024 * 1024.
  onChangeImageFile = (e, callback) => {
    var files = e.target.files
    const state = this.context.store.getState()
    const { t } = this.context
    const { dispatch } = this.props
    const image = files[0]
    const action_path = e.target.name.match(/(actions\[\d+\])/)[1]

    if (!image) {
      callback(null)
      window.alert(t('validate.invalidImage'))
      this.props.change(`${action_path}.upload_image_url`, null)
      this.props.change(`${action_path}.isInvalidImage`, true)
      return
    }
    const authorizedFileFormat = ['image/jpeg']
    const maxFileSize = 1048576
    if (authorizedFileFormat.indexOf(image.type) === -1 || image.size > maxFileSize) {
      callback(null)
      window.alert(t('validate.invalidImage'))
      this.props.change(`${action_path}.upload_image_url`, null)
      this.props.change(`${action_path}.isInvalidImage`, true)
      return
    }

    const fr = new FileReader()
    fr.onload = evt => {
      const dv = new DataView(evt.target.result, 0, 5)
      const nume1 = dv.getUint8(0, true)
      const nume2 = dv.getUint8(1, true)
      const hex = nume1.toString(16) + nume2.toString(16)

      // Jpeg has 'ffd8' in header
      if (hex === 'ffd8') {
        this.setState({ isLoadedImage: false })
        const formData = new FormData()
        formData.append('type', 'upload_image')
        formData.append('upload_file', image)
        dispatch(uploadImage(state.session.token, formData))
          .then(upload => {
            callback(upload.image_url)
            this.props.change(`${action_path}.upload_image_url`, upload.image_url)
            this.props.change(`${action_path}.upload_thumb_url`, upload.thumb_url)
            this.setState({ isLoadedImage: true })
          })
          .catch(() => this.setState({ isLoadedImage: true }))
      } else {
        callback(null)
        window.alert(t('validate.invalidImage'))
        this.props.change(`${action_path}.upload_image_url`, null)
        this.props.change(`${action_path}.isInvalidImage`, true)
      }
    }
    fr.readAsArrayBuffer(image)
  }

  onChangeSidebar = index => {
    this.setState({ sidebarIndex: index })
  }

  onRefreshTaskConditionGroup = () => {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    if (this.props.params.id) {
      dispatch(fetchConditionGroups(state.session.token, { task_id: this.props.params.id }))
    }
  }

  onError = (message, options = {}) => {
    const level = options.level || 'error'
    this.props.dispatch(addNotice(level, message))
  }

  updateTableState = (path, tableName, tableState) => {
    const { dispatch } = this.props
    dispatch(updateTableState(path, tableName, tableState))
  }

  render() {
    const { t } = this.context
    const { sidebarIndex } = this.state
    const {
      isFetching,
      submitting,
      handleSubmit,
      bot,
      type,
      isExponential,
      useTimeoutMessage,
      actions,
      integrations,
      tasks,
      params,
      condition_groups,
      tableStates,
    } = this.props

    const titles = []
    titles.push(t('simulator.title'))
    titles.push(t('condition_group.taskOption'))
    const variables = collectVariables(this.context, this.props, actions)
    return (
      <div>
        <Loader loaded={!isFetching && !submitting} type="show">
          <TaskEditComponent
            bot={bot}
            task_id={params.id}
            type={type}
            isExponential={isExponential}
            useTimeoutMessage={useTimeoutMessage}
            actions={actions}
            integrations={integrations}
            tasks={tasks}
            variables={variables}
            handleSave={handleSubmit(this.handleSave)}
            handleDelete={this.handleDelete}
            onChangeImageFile={this.onChangeImageFile}
            onError={this.onError}
            isLoadedImage={this.state.isLoadedImage}
            onChangeConditionType={this.onChangeConditionType}
            condition_groups={condition_groups}
          />
          <Sidebar titles={titles} onChangeSidebar={this.onChangeSidebar}>
            {bot.id && sidebarIndex === 0 && <Simulator tabs={['chat']} bot={bot} />}
            {sidebarIndex === 1 && (
              <TaskOption
                bot={bot}
                variables={variables}
                task_id={params.id}
                condition_groups={condition_groups}
                onRefresh={this.onRefreshTaskConditionGroup}
                tableState={tableStates['taskOption']}
                updateTableState={this.updateTableState}
              />
            )}
          </Sidebar>
        </Loader>
      </div>
    )
  }
}

const TaskEditForm = reduxForm({
  form: 'TaskEdit',
  enableReinitialize: true,
  validate,
})(TaskEdit)

const selector = formValueSelector('TaskEdit')

export const mapStateToProps = (state, props) => {
  const applications = lodash.filter(state.entities.applications)
  const bot = state.entities.bots[props.params.bot_id] || {}
  const task = {
    type: 'once',
    actions: [],
    ...state.entities.tasks[props.params.id],
  }

  //  Handle fields for number as string to use pristine flag in redux-form
  task.start_delay_value = convertToString(task.start_delay_value)
  task.end_delay_value = convertToString(task.end_delay_value)
  task.interval = convertToString(task.interval)

  let condition_groups = []
  if (props.params.id) {
    condition_groups = lodash.filter(state.entities.condition_groups, {
      task_id: parseInt(props.params.id, 10),
    })
  }

  //  Allow integrations which must not use user input
  const integrations = lodash.filter(state.entities.integrations, integration =>
    lodash.includes(['http', 'google_spreadsheet', 'email', 'excel'], integration.type)
  )

  //  Convert system variables to localized text
  if (task.actions) {
    task.actions.forEach(action => {
      convertSystemVariablesOfAction(action, false)
    })
    replaceSystemVariableWhenOnChange(task, 'timeout_message', false, true)
  }

  task.actions = task.actions.map(action => {
    switch (action.type) {
      case 'upload_image':
        return {
          ...action,
          upload_image_url: action.image_method === 'upload' ? action.image_url : undefined,
          upload_thumb_url: action.image_method === 'upload' ? action.thumb_url : undefined,
          input_url: action.image_method === 'url' ? action.image_url : undefined,
        }
      default:
        return action
    }
  })

  const tableStates = {
    taskOption: state.table[`${window.location.pathname}#TaskConditionGroupList`],
  }

  return {
    isFetching: isFetching(state, ['tasks', 'integrations']),
    bot: bot,
    task: task,
    condition_groups: condition_groups,
    initialValues: task,
    applications: applications,
    type: selector(state, 'type'),
    isExponential: selector(state, 'is_exponential'),
    useTimeoutMessage: selector(state, 'use_timeout_message'),
    actions: selector(state, 'actions'),
    integrations: integrations,
    tasks: lodash.filter(state.entities.tasks, { bot_id: bot.id }),
    tableStates: tableStates,
  }
}

export default connect(mapStateToProps)(TaskEditForm)
