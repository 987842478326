import lodash from 'lodash'
import moment from 'moment-timezone'
import 'rc-tabs/assets/index.css'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { Field, reduxForm, propTypes, formValueSelector } from 'redux-form'
import Tabs, { TabPane } from 'rc-tabs'
import TabContent from 'rc-tabs/lib/TabContent'
import SwipeableInkTabBar from 'rc-tabs/lib/SwipeableInkTabBar'

import { deleteCache } from '../../actions/api'
import { fetchApplication, downloadLogs } from '../../actions/application'
import { fetchDomains } from '../../actions/domain'
import { fetchTopics } from '../../actions/topic'
import { fetchUsers } from '../../actions/user'
import { isPermitted } from '../../helpers/permission'
import { isFetching } from '../../helpers/selector'
import LearningExclusionWordEdit from '../../components/analytics/LearningExclusionWordEdit'
import { SelectField } from '../../components/common/fields/FormFields'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import Loader from '../../components/common/Loader'
import ActivityGraph from './ActivityGraph'
import UsageGraph from './UsageGraph'
import PushMessage from './PushMessage'
import ApplicationLogs from './ApplicationLogs'
import PredictionFailureLogs from './PredictionFailureLogs'
import LearningExclusionWords from './LearningExclusionWords'

class ApplicationUsage extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    application: PropTypes.object.isRequired,
    topics: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    intentType: PropTypes.string,
    selectedFaqId: PropTypes.number,
  }

  constructor() {
    super()
    this.state = {
      isOpened: false,
      isExclusionsOpened: false,
      isExclusionEditOpened: false,
      exclusionItem: {
        id: undefined,
        keyword: '',
      },
      graph: 'activities',
    }
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props

    dispatch(fetchUsers(state.session.token))
    if (this.props.params.id) {
      dispatch(deleteCache('threads'))
      dispatch(fetchApplication(state.session.token, this.props.params.id)).then(response => {
        const bot_id = response.entities.applications[this.props.params.id].latest_bot.original_id
        dispatch(fetchDomains(state.session.token, { bot_id }))
        dispatch(fetchTopics(state.session.token, { bot_id }))
      })
    }
  }

  handleDialogOpen = () => {
    this.setState({ isOpened: true })
  }

  handleDialogClose = () => {
    this.setState({ isOpened: false })
  }

  handleDownloadLogs = data => {
    const state = this.context.store.getState()
    const { application, dispatch } = this.props

    return dispatch(downloadLogs(state.session.token, application.id, data.targetMonth)).then(blob => {
      this.handleDialogClose()
      let filename
      if (moment().format('YYYY-MM') !== data.targetMonth) {
        filename = `${application.name}-log-${lodash.camelCase(data.targetMonth)}.csv`
      } else {
        filename = `${application.name}-log-${moment().format('YYYYMMDDHHmmss')}.csv`
      }
      const a = document.createElement('a')
      a.download = filename
      a.target = '_blank'
      a.rel = 'noopener noreferrer'

      if (window.navigator.msSaveBlob) {
        // for IE
        window.navigator.msSaveBlob(blob, filename)
      } else if (window.URL && window.URL.createObjectURL) {
        // for Firefox
        a.href = window.URL.createObjectURL(blob)
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      } else if (window.webkitURL && window.webkitURL.createObject) {
        // for Chrome
        a.href = window.webkitURL.createObjectURL(blob)
        a.click()
      } else {
        // for Safari
        // TODO: notice not supported message
      }
    })
  }

  handleExclusionsOpen = () => {
    this.setState({
      isExclusionsOpened: true,
    })
  }

  handleExclusionsClose = () => {
    this.setState({ isExclusionsOpened: false })
  }

  handleExclusionEditOpen = item => {
    this.setState({
      isExclusionEditOpened: true,
      exclusionItem: item,
    })
  }

  handleExclusionEditClose = () => {
    this.setState({
      isExclusionEditOpened: false,
    })
  }

  onChangeGraph = e => {
    const graph = e.nativeEvent.target.value
    this.setState({ graph: graph })
  }

  render() {
    const { graph } = this.state
    const { t } = this.context
    const { application, topics, users, isFetching, intentType, selectedFaqId } = this.props

    if (!application.id) return null

    return (
      <div className="dm-analytics">
        <div className="mb-3">
          <label className="dm-title-mini">{t('analytics.predictionFailureLogs.name')}</label>
          <div className="dm-value ml-3">{application.name}</div>
        </div>
        <Tabs
          className="dm-tabs"
          defaultActiveKey="usage"
          renderTabBar={() => <SwipeableInkTabBar pageSize={3} speed={10} />}
          renderTabContent={() => <TabContent animatedWithMargin />}
        >
          <TabPane tab={t('analytics.usage.title')} key="usage">
            <div className="form-group radio-inline">
              <input
                key="activities"
                id="activities"
                name="activityGraph"
                type="radio"
                value="activities"
                defaultChecked
                className="form-control"
                onChange={this.onChangeGraph}
              />
              <LabelWithTooltip
                htmlFor="activities"
                className="dm-radio"
                name="analytics.usage.activity"
                direction="left"
              />
            </div>
            <div className="form-group radio-inline">
              <input
                key="scenarios"
                id="scenarios"
                name="activityGraph"
                type="radio"
                value="UsageGraph"
                className="form-control"
                onChange={this.onChangeGraph}
              />
              <LabelWithTooltip
                htmlFor="scenarios"
                className="dm-radio"
                name="analytics.usage.usage"
                direction="left"
              />
            </div>
            {isPermitted('feature_push_api', this.context) && (
              <div className="form-group radio-inline">
                <input
                  key="pushMessages"
                  id="pushMessages"
                  name="activityGraph"
                  type="radio"
                  value="pushMessages"
                  className="form-control"
                  onChange={this.onChangeGraph}
                />
                <LabelWithTooltip
                  htmlFor="pushMessages"
                  className="dm-radio"
                  name="analytics.usage.push"
                  direction="left"
                />
              </div>
            )}
            {graph === 'activities' && <ActivityGraph application={application} topics={topics} />}
            {graph === 'UsageGraph' && <UsageGraph application={application} topics={topics} />}
            {graph === 'pushMessages' && <PushMessage application={application} />}
          </TabPane>
          <TabPane tab={t('analytics.applicationLogs.title')} key="logs">
            {this.renderLogDownloadButton()}
            <ApplicationLogs
              application={application}
              topics={topics}
              users={users}
              isFetching={isFetching}
            />
          </TabPane>
          <TabPane tab={t('analytics.predictionFailureLogs.title')} key="failure">
            <PredictionFailureLogs
              application={application}
              isFetching={isFetching}
              handleExclusionsOpen={this.handleExclusionsOpen}
            />
          </TabPane>
        </Tabs>
        {this.state.isOpened && (
          <LogDownloadForm
            application={application}
            onExecute={this.handleDownloadLogs}
            onClose={this.handleDialogClose}
            initialValues={{ targetMonth: moment().format('YYYY-MM') }}
          />
        )}
        {this.state.isExclusionsOpened && (
          <LearningExclusionWords
            application={application}
            isFetching={isFetching}
            renderExclusionEdit={this.handleExclusionEditOpen}
            handleExclusionsClose={this.handleExclusionsClose}
            intentType={intentType}
            selectedFaqId={selectedFaqId}
          />
        )}
        {this.state.isExclusionEditOpened && (
          <LearningExclusionWordEdit
            application={this.props.application}
            onClose={this.handleExclusionEditClose}
            item={this.state.exclusionItem}
            intentType={intentType}
            selectedFaqId={selectedFaqId}
          />
        )}
      </div>
    )
  }

  renderLogDownloadButton() {
    const { t } = this.context
    const { application } = this.props

    return (
      <div>
        <div className="dm-note">{t('analytics.usage.logDownloadNote')}</div>
        <Link
          className="btn btn-primary dm-btn"
          to={`/analytics/${application.id}/usage/#`}
          onClick={this.handleDialogOpen}
        >
          {t('analytics.applicationLogs.download')}
        </Link>
      </div>
    )
  }
}

class LogDownLoad extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    ...propTypes,
    application: PropTypes.object.isRequired,
    onExecute: PropTypes.func,
    onClose: PropTypes.func,
    initialValues: PropTypes.object.isRequired,
  }

  render() {
    const { t } = this.context
    const { application, handleSubmit, submitting, onExecute } = this.props

    const from = moment.max(moment().subtract(1, 'years'), moment('2017-05'))
    const to = moment()

    let current = to.clone()
    const targetMonths = []
    while (current.isAfter(from)) {
      targetMonths.push({
        display: current.format(t('analytics.usage.messageLogs.targetMonthFormat')),
        value: current.format('YYYY-MM'),
      })
      current = current.subtract(1, 'months')
    }

    return (
      <div className="dm-modal modal-background" onClick={this.props.onClose}>
        <div className="modal-dialog" role="document" onClick={e => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.onClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title">{t('analytics.usage.messageLogs.dialogName')}</h4>
            </div>
            <Loader type="show" loaded={!submitting}>
              <form className="text-left" onSubmit={handleSubmit(onExecute)}>
                <div className="modal-body">
                  <div className="modal-row">
                    <label className="dm-title-mini">{t('analytics.usage.name')}</label>
                    <div className="modal-data">{application.name}</div>
                  </div>
                  <div className="modal-row">
                    <label className="dm-title-mini">{t('analytics.usage.messageLogs.targetMonth')}</label>
                    <div className="modal-data">
                      <Field
                        name="targetMonth"
                        className="form-control dm-form-control"
                        component={SelectField}
                        items={targetMonths}
                        displayKey="display"
                        valueKey="value"
                      />
                    </div>
                  </div>
                  <div className="modal-row">
                    <div className="dm-note">{t('analytics.usage.messageLogs.downloadNotes.thisMonth')}</div>
                    <div className="dm-note">{t('analytics.usage.messageLogs.downloadNotes.history')}</div>
                    <div className="dm-note">
                      {t('analytics.usage.messageLogs.downloadNotes.columns.prefix')}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('analytics.usage.messageLogs.downloadNotes.columns.link'),
                        }}
                      />
                      {t('analytics.usage.messageLogs.downloadNotes.columns.suffix')}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary" disabled={submitting}>
                    {t('common.download')}
                  </button>
                </div>
              </form>
            </Loader>
          </div>
        </div>
      </div>
    )
  }
}

const LogDownloadForm = reduxForm({
  form: 'LogDownLoad',
})(LogDownLoad)

const predictionFailureLogsSelector = formValueSelector('PredictionFailureLogs')

export const mapStateToProps = (state, props) => {
  const application = state.entities.applications[props.params.id] || {}
  const bot_id = Object.keys(application).length === 0 ? undefined : application.latest_bot.original_id
  const domains = lodash.filter(state.entities.domains, { bot_id: bot_id }) || {}
  const domainIds = domains.map(domain => domain.id)
  const topics =
    lodash.filter(
      state.entities.topics,
      topic => topic.type === 'normal' && lodash.includes(domainIds, topic.domain_id)
    ) || []
  const user_ids = state.pagination.users.ids || []
  const users = user_ids.map(id => state.entities.users[id])

  return {
    application: application,
    topics: topics,
    users: users,
    isFetching: isFetching(state),
    intentType: predictionFailureLogsSelector(state, 'intent_type') || 'topic',
    selectedFaqId: predictionFailureLogsSelector(state, 'faq_id')
      ? parseInt(predictionFailureLogsSelector(state, 'faq_id'), 10)
      : undefined,
  }
}

export default connect(mapStateToProps)(ApplicationUsage)
