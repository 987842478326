import lodash from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import AbstractMessage from './AbstractMessage'
import CarouselItems from './CarouselItems'
import MessageIcon from './MessageIcon'

class CarouselMessage extends AbstractMessage {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    ...AbstractMessage.propTypes,
    showAction: PropTypes.oneOf(['show', 'readonly', 'hide']).isRequired,
    handleSendMessage: PropTypes.func,
  }

  sendMessage = e => {
    e.preventDefault()
    this.props.handleSendMessage({
      text: e.target.dataset.label,
      value: e.target.dataset.value,
      is_select: true,
    })
  }

  handleDefaultAction = (e, defaultAction) => {
    e.preventDefault()
    this.props.handleSendMessage({ text: defaultAction.label, value: defaultAction.value, is_select: true })
  }

  render() {
    const {
      mode,
      uuid,
      sender_type,
      content,
      timestamp,
      showAction,
      iconUrl,
      fallbackIconUrl,
      isLoaded,
      isSimulator,
    } = this.props
    const showImage = lodash.some(content.carousel_items, item => item.image_url && item.image_url.trim())

    const enableSwitchChatBot = mode === 'client' && !isSimulator

    return (
      <li className={classnames(this.getMessageClasses({ 'is-confirm': true }))}>
        <div className="body">
          {sender_type !== 'client' && (
            <MessageIcon
              sender_type={sender_type}
              url={iconUrl}
              fallbackUrl={fallbackIconUrl}
              isLoaded={isLoaded}
            />
          )}
          <div className="balloon">
            <div className="panel panel-default dm-panel">
              <CarouselItems
                id={uuid}
                items={content.carousel_items}
                showImage={showImage}
                showAction={showAction}
                sendMessage={this.sendMessage}
                handleDefaultAction={this.handleDefaultAction}
                parse={this.parse}
                enableSwitchChatBot={enableSwitchChatBot}
              />
              {this.renderDefaultFooterButtons('panel')}
            </div>
            {this.renderTimestamp(timestamp)}
          </div>
        </div>
      </li>
    )
  }
}

export default CarouselMessage
