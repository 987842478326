import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import { CheckboxField, SelectField } from '../common/fields/FormFields'
import LabelWithTooltip from '../common/LabelWithTooltip'
import { isPermitted } from '../../helpers/permission'

export class OAuthEdit extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    enabledOffice365: PropTypes.bool.isRequired,
    enabledSalesforce: PropTypes.bool.isRequired,
    office365OAuthClients: PropTypes.array,
    salesforceOAuthClients: PropTypes.array,
  }

  static defaultProps = {
    enabledOffice365: false,
    enabledSalesforce: false,
  }

  render() {
    const { t } = this.context
    const { enabledOffice365, enabledSalesforce, office365OAuthClients, salesforceOAuthClients } = this.props

    const office365OAuthClientsItems = lodash.map(office365OAuthClients, client => {
      const disabled = client.use_preauthentication && !client.is_verified
      const name = client.name + (disabled ? t('bot.cooperation.office365.notApproval') : '')

      return { id: client.id, name: name, disabled: disabled }
    })
    const salesforceOAuthClientsItems = lodash.map(salesforceOAuthClients, client => {
      const disabled = client.use_preauthentication && !client.is_verified
      const name = client.name + (disabled ? t('bot.cooperation.salesforce.notApproval') : '')

      return { id: client.id, name: name, disabled: disabled }
    })

    return (
      <div className="cooperation">
        {isPermitted('integration_office365', this.context) && (
          <div>
            <div className="form-group form-inline checkbox">
              <Field
                type="checkbox"
                name="use_office365"
                className="form-control m-0"
                component={CheckboxField}
              />
              <LabelWithTooltip htmlFor="use_office365" name="bot.cooperation.office365.enabled" />
            </div>
            {enabledOffice365 && (
              <div className="form-group row dm-form-group menu-paragraph">
                <LabelWithTooltip
                  htmlFor="oauth_client_id_office365"
                  className="col-sm-3 mb-5"
                  name="bot.cooperation.office365.oauth_client_id"
                />
                <div className="col-sm-5">
                  <Field
                    name="oauth_client_id_office365"
                    items={office365OAuthClientsItems}
                    valueKey="id"
                    displayKey="name"
                    disabledKey="disabled"
                    className="form-control dm-form-control"
                    component={SelectField}
                    empty={true}
                    parse={value => value && parseInt(value, 10)}
                    order="asc"
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {isPermitted('integration_salesforce', this.context) && (
          <div>
            <div className="form-group form-inline checkbox">
              <Field
                type="checkbox"
                name="use_salesforce"
                className="form-control m-0"
                component={CheckboxField}
              />
              <LabelWithTooltip htmlFor="use_salesforce" name="bot.cooperation.salesforce.enabled" />
            </div>
            {enabledSalesforce && (
              <div className="form-group row dm-form-group menu-paragraph">
                <LabelWithTooltip
                  htmlFor="oauth_client_id_salesforce"
                  className="col-sm-3 mb-5"
                  name="bot.cooperation.salesforce.oauth_client_id"
                />
                <div className="col-sm-5">
                  <Field
                    name="oauth_client_id_salesforce"
                    items={salesforceOAuthClientsItems}
                    valueKey="id"
                    displayKey="name"
                    disabledKey="disabled"
                    className="form-control dm-form-control"
                    disabled={!enabledSalesforce}
                    component={SelectField}
                    empty={true}
                    parse={value => value && parseInt(value, 10)}
                    order="asc"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default OAuthEdit
