import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import { addNotice, updateNotice, removeNotice } from '../../actions/notice'

export class PublishNotice extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    application: PropTypes.object,
  }

  getMessage = application => {
    const { t } = this.context
    const messages = {
      enqueued: t('application.publishing.waitPublishing'),
      publishing: t('application.publishing.waitPublishing'),
      error: t('application.publishing.publishingError'),
    }

    return messages[application.status]
  }

  automationErrorMessage = application => {
    const { t } = this.context
    if (/AutomationError/.test(application.error_reason)) {
      if (/invalid bot data/.test(application.error_reason)) {
        return t('application.automation.displayNameError')
      } else {
        return t('application.automation.unexpectedError')
      }
    }
    return null
  }

  componentDidMount() {
    const { dispatch } = this.props
    const message = this.automationErrorMessage(this.props.application)
      ? this.automationErrorMessage(this.props.application)
      : this.getMessage(this.props.application)
    if (message) {
      Promise.resolve().then(() =>
        dispatch(addNotice('train', message, { uuid: '5b50e80f-43d6-4b9f-8787-8fe0ae2a6968' }))
      )
    }
  }

  componentDidUpdate() {
    const { dispatch } = this.props
    const message = this.automationErrorMessage(this.props.application)
      ? this.automationErrorMessage(this.props.application)
      : this.getMessage(this.props.application)
    if (message) {
      dispatch(updateNotice('5b50e80f-43d6-4b9f-8787-8fe0ae2a6968', 'train', message))
    } else {
      dispatch(removeNotice('5b50e80f-43d6-4b9f-8787-8fe0ae2a6968'))
    }
  }

  render() {
    return null
  }
}

export default connect()(PublishNotice)
