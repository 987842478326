import React from 'react'
import Collapsible from 'react-collapsible'
import classnames from 'classnames'

import AbstractMessage from './AbstractMessage'
import MessageIcon from './MessageIcon'

class ItemListMessage extends AbstractMessage {
  renderItems(items) {
    const { mode, isSimulator } = this.props
    const options = { enableSwitchChatBot: mode === 'client' && !isSimulator }
    return items.map((item, index) => {
      return (
        <li key={`item-${index}`} className="list-group-item">
          <p>{this.parse(item.text, options)}</p>
        </li>
      )
    })
  }

  renderItemList() {
    const { t } = this.context
    const { content } = this.props
    if (content.items.length <= 5) {
      const items = this.renderItems(content.items)
      return (
        <div className="panel panel-default dm-panel item-list">
          <div>
            <ul className="list-group item-list">{items}</ul>
          </div>
        </div>
      )
    } else {
      const items = this.renderItems(content.items.slice(0, 5))
      const additionalItems = this.renderItems(content.items.slice(5))
      return (
        <div className="panel panel-default dm-panel item-list">
          <Collapsible
            triggerWhenOpen={
              <React.Fragment>
                <div>
                  <ul className="list-group item-list">{items}</ul>
                </div>
                <div>
                  <ul className="list-group item-list">{additionalItems}</ul>
                </div>
              </React.Fragment>
            }
            trigger={
              <React.Fragment>
                <div>
                  <ul className="list-group item-list">{items}</ul>
                </div>
                <div className="collapse in expanded">{t('common.expand')}</div>
              </React.Fragment>
            }
          ></Collapsible>
          {this.renderDefaultFooterButtons('panel')}
        </div>
      )
    }
  }

  render() {
    const { sender_type, timestamp, iconUrl, fallbackIconUrl, isLoaded } = this.props
    const itemList = this.renderItemList()
    return (
      <li className={classnames(this.getMessageClasses({ 'is-confirm': true }))}>
        <div className="body">
          {sender_type !== 'client' && (
            <MessageIcon
              sender_type={sender_type}
              url={iconUrl}
              fallbackUrl={fallbackIconUrl}
              isLoaded={isLoaded}
            />
          )}
          <div className="balloon">
            {itemList}
            {this.renderTimestamp(timestamp)}
          </div>
        </div>
      </li>
    )
  }
}

export default ItemListMessage
