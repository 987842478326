import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Sticky from 'react-sticky-state'
import { Link } from 'react-router'

// components
import DataBindingTable from '../../../components/common/DataBindingTable'
import Loader from '../../../components/common/Loader'
import LabelWithTooltip from '../../../components/common/LabelWithTooltip'
import Simulator from '../../../containers/simulator/Simulator'
import Sidebar from '../../../components/common/Sidebar'

// actions
import { addNotice } from '../../../actions/notice'
import { fetchBot } from '../../../actions/bot'
import { fetchFaq } from '../../../actions/faq'
import { updateTableState } from '../../../actions/table'
import { clearGroupingResult } from '../../../actions/inquiry'

// helpers
import { isFetching } from '../../../helpers/selector'

export class InquiryGroups extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    bot: PropTypes.object,
    faq: PropTypes.object.isRequired,
    groups: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      bot_id: PropTypes.string.isRequired,
      faq_id: PropTypes.string.isRequired,
    }),
    isFetching: PropTypes.bool,
    tableState: PropTypes.object,
  }

  static FAQ_MATCHING_THRESHOLD = 7

  componentDidMount() {
    const state = this.context.store.getState()
    const {
      dispatch,
      params: { bot_id, faq_id },
    } = this.props
    dispatch(fetchBot(state.session.token, bot_id))
    dispatch(fetchFaq(state.session.token, faq_id))
  }

  clearGroupingResult = () => {
    const state = this.context.store.getState()
    const { t, router } = this.context
    const {
      dispatch,
      params: { bot_id, faq_id },
    } = this.props

    return dispatch(clearGroupingResult(state.session.token, faq_id))
      .then(() => router.push(`/bots/${bot_id}/faqs/${faq_id}/generator/import`))
      .then(() => dispatch(addNotice('info', t('inquiry.inquiryGroups.clearSuccessMessage'))))
  }

  updateTableState = (path, tableName, tableState) => {
    const { dispatch } = this.props
    dispatch(updateTableState(path, tableName, tableState))
  }

  render() {
    const { t } = this.context
    const {
      isFetching,
      bot,
      faq,
      groups,
      tableState,
      params: { bot_id, faq_id },
    } = this.props

    const filterInquiriesByCategoryDepth = (group, depth) => {
      const usedFilters = lodash.map(lodash.range(depth), depth =>
        lodash.matchesProperty(`categories[${depth}]`, getMostCategoryWithDepth(group, depth + 1))
      )
      return lodash.filter(group.inquiries, lodash.overEvery(usedFilters))
    }
    const getMostCategoryWithDepth = (group, depth) => {
      const inquiries = filterInquiriesByCategoryDepth(group, depth - 1)
      const categories = lodash.map(inquiries, `categories[${depth - 1}]`)
      return getMostCategory(categories)
    }
    const getMostCategory = categories => {
      const categoryCounts = lodash.countBy(
        lodash.filter(categories, category => category && category.trim())
      )
      const categoryCountList = lodash.map(categoryCounts, (count, category) => ({ category, count }))
      if (lodash.isEmpty(categoryCountList)) {
        return ''
      }
      return lodash.maxBy(categoryCountList, 'count').category
    }
    const columns = [
      {
        Header: t('inquiry.inquiryGroups.inquiryGroupsTable.title'),
        id: 'title',
        accessor: group => {
          if (group.others_group) {
            return t('inquiry.inquiryGroups.inquiryGroupsTable.noiseTitle')
          }

          const inquiries = filterInquiriesByCategoryDepth(group, 5)
          return inquiries[0]?.title || inquiries[0]?.question || ''
        },
        Cell: props => (
          <Link
            to={`/bots/${bot_id}/faqs/${faq_id}/generator/groups/${props.original.group_id}`}
            title={props.value}
          >
            {props.value}
          </Link>
        ),
      },
      {
        Header: t('inquiry.inquiryGroups.inquiryGroupsTable.count'),
        id: 'count',
        style: { textAlign: 'right' },
        accessor: group => {
          return group.inquiries.length
        },
        width: 80,
      },
      {
        Header: t('inquiry.inquiryGroups.inquiryGroupsTable.faqMatch'),
        id: 'faqMatch',
        style: { textAlign: 'center' },
        accessor: group => {
          const isFaqMatch = lodash.some(group.inquiries, inquiry => {
            return lodash.some(
              inquiry.candidates,
              candidate => candidate.confidence >= InquiryGroups.FAQ_MATCHING_THRESHOLD
            )
          })
          return isFaqMatch ? '○' : ''
        },
        width: 80,
      },
      {
        Header: t('inquiry.inquiryGroups.inquiryGroupsTable.category1'),
        id: 'category1',
        accessor: group => getMostCategoryWithDepth(group, 1),
        width: 120,
      },
      {
        Header: t('inquiry.inquiryGroups.inquiryGroupsTable.category2'),
        id: 'category2',
        accessor: group => getMostCategoryWithDepth(group, 2),
        width: 120,
      },
      {
        Header: t('inquiry.inquiryGroups.inquiryGroupsTable.category3'),
        id: 'category3',
        accessor: group => getMostCategoryWithDepth(group, 3),
        width: 120,
      },
      {
        Header: t('inquiry.inquiryGroups.inquiryGroupsTable.category4'),
        id: 'category4',
        accessor: group => getMostCategoryWithDepth(group, 4),
        width: 120,
      },
      {
        Header: t('inquiry.inquiryGroups.inquiryGroupsTable.category5'),
        id: 'category5',
        accessor: group => getMostCategoryWithDepth(group, 5),
        width: 120,
      },
      {
        id: 'group_id',
        accessor: 'group_id',
        show: false,
      },
    ]

    return (
      <div>
        <Loader loaded={!isFetching} type="show">
          <div>
            <LabelWithTooltip name="inquiry.inquiryGroups.title" className="dm-title" />
            <DataBindingTable
              items={groups}
              columns={columns}
              defaultSorted={[
                { id: 'count', desc: true },
                { id: 'group_id', desc: false },
              ]}
              tableState={tableState}
              updateTableState={this.updateTableState}
            />
          </div>

          <Sticky>
            <div className="sticky bottom">
              <div className="dm-sticky-buttons">
                <button type="button" className="btn btn-primary dm-btn" onClick={this.clearGroupingResult}>
                  {t('inquiry.inquiryGroups.clearGrouping')}
                </button>
              </div>
            </div>
          </Sticky>

          <Sidebar titles={[t('simulator.title')]}>
            <Simulator tabs={['faqClassifier', 'chat']} faq={faq} bot={bot} />
          </Sidebar>
        </Loader>
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const bot = state.entities.bots[props.params.bot_id] || {}
  const faq = state.entities.faqs[props.params.faq_id] || {}
  const groups = state.groups[props.params.faq_id] || []

  return {
    bot: bot,
    faq: faq,
    isFetching: isFetching(state),
    tableState: state.table[window.location.pathname],
    groups: groups,
  }
}

export default connect(mapStateToProps)(InquiryGroups)
