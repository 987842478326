import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'redux-form'

import { filterSystemVariables } from '../../helpers/replaceSystemVariable'

// components
import { InputField, SelectField, AutoCompleteField } from '../../components/common/fields/FormFields'

class ConditionTable extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    bot: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    maxRows: PropTypes.number,
    onChangeConditionType: PropTypes.func.isRequired,
    variables: PropTypes.array.isRequired,
    enableValidation: PropTypes.bool,
  }

  componentWillUpdate(nextProps) {
    const { fields } = nextProps
    lodash.range(fields.length).forEach(index => {
      if (lodash.isEmpty(fields.get(index))) {
        fields.remove(index)
      }
    })
  }

  needNewRow = () => {
    const { fields, maxRows } = this.props
    const row = !lodash.isEmpty(fields.get(fields.length - 1))
      ? lodash.cloneDeep(fields.get(fields.length - 1))
      : []
    if (row.hasOwnProperty('operator')) delete row.operator
    const needNewRow = fields.length === 0 || !lodash.isEmpty(row)
    if (!needNewRow) return false
    if (maxRows && fields.length >= maxRows) return false

    return true
  }

  validateVariable(rowIndex) {
    const { t } = this.context
    const { variables, fields, bot } = this.props
    const userVariables = lodash.map(bot.memory_definitions, 'name')
    const chatbotConstants = lodash.map(bot.constant_definitions, 'name')
    const adaptableVariables = variables
      .map(variable => variable.variable)
      .concat(
        filterSystemVariables().map(variable => t('systemVariables.' + variable)),
        userVariables,
        chatbotConstants
      )

    const row = !lodash.isEmpty(fields.get(rowIndex)) ? fields.get(rowIndex) : []
    const isInvalidVariable = !!(
      row.variable &&
      adaptableVariables.filter(
        variable => row.variable === variable || row.variable.match(new RegExp('^' + variable + '\\.'))
      ).length === 0
    )
    return isInvalidVariable
  }

  render() {
    const { t } = this.context
    const { fields } = this.props
    const length = fields.length + (this.needNewRow() ? 1 : 0)

    return (
      <table className="table table-bordered dm-table hover incrementable">
        <thead>
          <tr>
            <th key="no" className="index">
              {t('condition_group.conditionItems.no')}
            </th>
            <th key="variable">{t('condition_group.conditionItems.name')}</th>
            <th key="operator" className="condition">
              {t('condition_group.conditionItems.condition')}
            </th>
            <th key="value">{t('condition_group.conditionItems.value')}</th>
            <th key="delete" className="delete">
              {t('common.delete')}
            </th>
          </tr>
        </thead>
        <tbody>
          {lodash.range(length).map(index => this.renderBodyRow(`${fields.name}[${index}]`, index))}
        </tbody>
      </table>
    )
  }

  renderBodyRow = (name, rowIndex) => {
    const { t } = this.context
    const { fields, onChangeConditionType, variables, bot, enableValidation } = this.props
    const condition = [
      { id: '', name: '' },
      { id: '=', name: t('common.conditions.equal') },
      { id: '<>', name: t('common.conditions.notEqual') },
      { id: '<', name: t('common.conditions.lessThan') },
      { id: '<=', name: t('common.conditions.lessOrEqual') },
      { id: '>', name: t('common.conditions.greaterThan') },
      { id: '>=', name: t('common.conditions.greaterOrEqual') },
      { id: 'include', name: t('common.conditions.include') },
      { id: 'not_include', name: t('common.conditions.notInclude') },
      { id: 'between', name: t('common.conditions.between') },
      /* { id: 'undefined', name: t('common.conditions.undefined') } */
    ]
    const isInvalidVariable = enableValidation && this.validateVariable(rowIndex)
    const scenarioVariables = lodash.map(variables, 'variable')
    const userVariables = lodash.map(bot.memory_definitions, 'name')
    const chatbotConstants = lodash.map(bot.constant_definitions, 'name')
    const adaptableVariables = lodash
      .chain(scenarioVariables)
      .difference(userVariables)
      .union(
        userVariables,
        filterSystemVariables().map(variable => t('systemVariables.' + variable)),
        chatbotConstants
      )
      .map(value => {
        return { variable: value, text: value }
      })
      .value()
    const cells = []
    let candidates = undefined
    let valueField
    let row = {}

    if (!lodash.isEmpty(fields.get(rowIndex))) {
      row = lodash.cloneDeep(fields.get(rowIndex))
      if (row.hasOwnProperty('variable')) {
        candidates = (lodash.find(variables, { variable: row.variable }) || {}).candidates
      }
    }

    if (row.hasOwnProperty('operator') && row.operator === 'between') {
      valueField = (
        <td key={3} className="dm-row-without-gap form-horizontal">
          <div className="col-xs-6 col-sm-6 between-from">
            <Field
              name={`${name}.value.from`}
              className="form-control dm-form-control"
              component={InputField}
              placeholder={t('condition_group.conditionPlaceholder.from')}
              maxLength="80"
            />
          </div>
          <div className="col-xs-6 col-sm-6 between-to">
            <Field
              name={`${name}.value.to`}
              className="form-control dm-form-control"
              component={InputField}
              placeholder={t('condition_group.conditionPlaceholder.to')}
              maxLength="80"
            />
          </div>
        </td>
      )
    } else if (candidates) {
      valueField = (
        <td key={3}>
          <Field
            name={`${name}.value`}
            className="form-control dm-form-control"
            component={SelectField}
            items={candidates}
            valueKey="value"
            displayKey="value"
            empty={true}
          />
        </td>
      )
    } else {
      valueField = (
        <td key={3}>
          <Field
            type="text"
            name={`${name}.value`}
            className="form-control dm-form-control"
            component={InputField}
            maxLength="200"
            placeholder={row.operator === 'undefined' ? '' : t('condition_group.conditionValue')}
            disabled={row.hasOwnProperty('operator') && row.operator === 'undefined'}
          />
        </td>
      )
    }

    cells.push(
      <td key={0}>{rowIndex + 1}</td>,
      <td key={1}>
        <Field
          name={`${name}.variable`}
          className="form-control dm-form-control"
          placeholder={t('condition_group.conditionVariable')}
          component={AutoCompleteField}
          items={adaptableVariables}
          valueKey="variable"
          displayKey="text"
          maxLength={30}
        />
        {isInvalidVariable && <div className="error">{t('condition_group.conditionValidator')}</div>}
      </td>,
      <td key={2}>
        <Field
          name={`${name}.operator`}
          className="form-control dm-form-control"
          component={SelectField}
          items={condition}
          onChange={onChangeConditionType}
          valueKey="id"
          displayKey="name"
        />
      </td>
    )
    cells.push(valueField)

    //  Append delete button
    if (row.hasOwnProperty('operator')) delete row.operator
    if (!lodash.isEmpty(row) || rowIndex < fields.length - 1) {
      cells.push(
        <td key="delete" className="delete">
          <button
            type="button"
            className="dm-btn btn btn-danger btn-icon-delete"
            onClick={e => {
              e.preventDefault()
              fields.remove(rowIndex)
            }}
          />
        </td>
      )
    } else {
      cells.push(<td key="delete" className="delete" />)
    }

    return <tr key={rowIndex}>{cells}</tr>
  }
}

export default ConditionTable
