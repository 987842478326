import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import DataBindingTable from '../../components/common/DataBindingTable'

class FaqUsageTable extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    faqs: PropTypes.array.isRequired,
    faqItems: PropTypes.array.isRequired,
    faqUsages: PropTypes.object,
    faqId: PropTypes.string,
    maxFaqItem: PropTypes.number,
    isFetching: PropTypes.bool,
    tableState: PropTypes.object,
    updateTableState: PropTypes.func.isRequired,
    handleRefreshClick: PropTypes.func.isRequired,
  }
  static defaultProps = {
    tableState: {},
    maxFaqItem: 200,
  }

  aggregateFaqItem = () => {
    const { t } = this.context
    const { faqs, faqItems, faqUsages, faqId, maxFaqItem } = this.props
    if (!faqUsages) {
      return []
    }

    let targetFaqs
    let targetFaqItems
    let targetFaqUsages
    if (faqId === 'all') {
      targetFaqs = faqs
      targetFaqItems = faqItems
      targetFaqUsages = faqUsages.faqs
    } else {
      targetFaqs = lodash.filter(faqs, { id: parseInt(faqId, 10) })
      targetFaqItems = lodash.filter(faqItems, { faq_id: parseInt(faqId, 10) })
      targetFaqUsages = lodash.filter(faqUsages.faqs, { id: parseInt(faqId, 10) })
    }

    const classificationFailed = {
      name: t('analytics.graph.usageGraph.faqUsage.graph.classificationFailed'),
      count: 0,
      ratio: -1,
      order: 0,
    }

    const faqAggregation = lodash.flatten(
      lodash.map(targetFaqUsages, faqUsage => {
        const result = []
        lodash.forEach(faqUsage.items, faqItemUsage => {
          if (faqItemUsage.id === null) {
            // Classification failed
            classificationFailed.count += faqItemUsage.count
          } else {
            const targetFaqItem = lodash.find(targetFaqItems, { id: faqItemUsage.id })
            if (targetFaqItem) {
              result.push({
                name: targetFaqItem.name,
                faqName: lodash.find(targetFaqs, { id: faqUsage.id }).name,
                count: faqItemUsage.count,
                ratio: 0,
                order: 0,
              })
            }
          }
        })

        return result
      })
    )

    const totalFaqStart = lodash.sumBy(faqAggregation, 'count')
    if (totalFaqStart !== 0) {
      lodash.forEach(faqAggregation, row => {
        row.ratio = Math.round((row.count / totalFaqStart) * 100)
      })
    }

    const totalAggregation = {
      name: t('analytics.graph.usageGraph.faqUsage.graph.total'),
      ratio: -1,
      count: totalFaqStart + classificationFailed.count,
      order: 1,
    }

    return lodash
      .chain(faqAggregation)
      .orderBy('count', 'desc')
      .take(maxFaqItem)
      .concat(classificationFailed, totalAggregation)
      .value()
  }

  render() {
    const { t } = this.context
    const { faqId, isFetching, tableState, handleRefreshClick, updateTableState } = this.props

    const columns = [
      {
        Header: t('analytics.graph.usageGraph.faqUsage.table.item'),
        id: 'name',
        accessor: usage => {
          if (faqId === 'all' && usage.faqName) {
            return `${usage.name} (${usage.faqName})`
          } else {
            return usage.name
          }
        },
      },
      {
        Header: t('analytics.graph.usageGraph.faqUsage.table.count'),
        id: 'count',
        accessor: 'count',
        style: { textAlign: 'right' },
        width: 120,
        Cell: props => <div> {props.value.toLocaleString()} </div>,
      },
      {
        Header: t('analytics.graph.usageGraph.faqUsage.table.ratio'),
        id: 'ratio',
        accessor: 'ratio',
        Cell: props => (props.value === -1 ? <div>-</div> : <div> {props.value} </div>),
        style: { textAlign: 'right' },
        width: 120,
      },
      {
        id: 'order',
        accessor: 'order',
        show: false,
      },
    ]

    const tableData = this.aggregateFaqItem()

    return (
      <div className="ml-3 mb-3">
        <DataBindingTable
          title={t('analytics.graph.usageGraph.faqUsage.table.title')}
          items={tableData}
          columns={columns}
          tableName="faqTable"
          defaultSorted={[
            { id: 'order', desc: true },
            { id: 'count', desc: true },
          ]}
          isFetching={isFetching}
          tableState={tableState}
          updateTableState={updateTableState}
          onRefresh={handleRefreshClick}
        />
        <div className="dm-note">{t('analytics.graph.usageGraph.faqUsage.table.note')}</div>
      </div>
    )
  }
}

export default FaqUsageTable
