import { deleteCache } from './api'
import Schemas from '../middleware/schema'
import request from '../helpers/requestManagementAPI'

export const fetchInterruptions = (token, filter = {}) => dispatch => {
  return request.get('/interruptions/', {
    token,
    dispatch,
    body: filter,
    target: 'interruptions',
    schema: Schemas.interruptions,
    preserve: false,
  })
}

export const fetchInterruption = (token, id, errorHandler) => dispatch => {
  return request.get(`/interruptions/${id}`, {
    token,
    dispatch,
    target: 'interruptions',
    schema: Schemas.interruption,
    errorHandler,
  })
}

export const createInterruption = (token, interruption) => dispatch => {
  return request.post('/interruptions/', {
    token,
    dispatch,
    body: interruption,
    target: 'interruptions',
    schema: Schemas.interruption,
  })
}

export const updateInterruption = (token, interruption) => dispatch => {
  return request.put(`/interruptions/${interruption.id}`, {
    token,
    dispatch,
    body: interruption,
    target: 'interruptions',
    schema: Schemas.interruption,
  })
}

export const deleteInterruption = (token, id) => dispatch => {
  return request
    .delete(`/interruptions/${id}`, {
      token,
      dispatch,
      target: 'interruptions',
    })
    .then(() => dispatch(deleteCache('interruptions', id)))
}
