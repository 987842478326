import React from 'react'
import { Field } from 'redux-form'
import { CheckboxField } from '../../components/common/fields/FormFields'
import LabelWithTooltip from '../common/LabelWithTooltip'

import AbstractAction from './AbstractAction'
import VariableField from '../common/fields/VariableField'
import ConfirmTextField from '../common/fields/ConfirmTextField'

export class ConfirmAction extends AbstractAction {
  getTitle() {
    return this.context.t('topic.actionList.confirm.title')
  }
  getType() {
    return 'user'
  }

  static getVariables = (action, props, context) => {
    const { t } = context
    const candidates = [
      { value: 'yes', text: t('chatMessage.yes') },
      { value: 'no', text: t('chatMessage.no') },
    ]
    return [{ variable: action.title, text: action.title, candidates }]
  }

  renderBody() {
    const { topicType, name } = this.props

    return (
      <div className="confirm">
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor="title"
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.confirm.confirmTitle"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <VariableField name={`${name}.title`} className="form-control dm-form-control" />
          </div>
        </div>
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor="text"
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.confirm.text"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <ConfirmTextField name={`${name}.text`} className="form-control" />
          </div>
        </div>
        {topicType === 'normal' && (
          <div className="form-group form-inline">
            <Field
              name={`${name}.skip_known_memories`}
              id={`${name}.skip_known_memories`}
              type="checkbox"
              component={CheckboxField}
              className="form-control"
            />
            <LabelWithTooltip
              htmlFor={`${name}.skip_known_memories`}
              name="topic.actionList.confirm.skipKnownMemories"
            />
          </div>
        )}
      </div>
    )
  }
}

export default ConfirmAction
