import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import { Field } from 'redux-form'

import AbstractMessage from './AbstractMessage'
import MessageIcon from './MessageIcon'
import TextField from '../common/fields/TextField'

class RequestFeedbackMessage extends AbstractMessage {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    ...AbstractMessage.propTypes,
    state: PropTypes.oneOf(['initial', 'enquete', 'finish']),
    phase: PropTypes.oneOf(['initial', 'enquete', 'finish']),
    threadUUID: PropTypes.string,
    showAction: PropTypes.oneOf(['show', 'readonly']).isRequired,
    handleSendRawMessage: PropTypes.func.isRequired,
    formValues: PropTypes.object,
  }

  static defaultProps = {
    formValues: {},
  }

  render() {
    const { state, phase, sender_type, content, timestamp, iconUrl, fallbackIconUrl, isLoaded } = this.props
    const renderers = {
      initial: this.renderInitial,
      enquete: this.renderEnquete,
      finish: this.renderFinish,
    }

    return (
      <li className={classnames(this.getMessageClasses({ 'is-confirm': true }))}>
        <div className="body">
          <MessageIcon
            sender_type={sender_type}
            url={iconUrl}
            fallbackUrl={fallbackIconUrl}
            isLoaded={isLoaded}
          />
          <div className="balloon">
            <div className="panel panel-default dm-panel confirm">
              <div className="panel-heading" title={content.title}>
                <small className="title">{content.title}</small>
              </div>
              {renderers[state || phase]()}
            </div>
            {this.renderTimestamp(timestamp)}
          </div>
        </div>
      </li>
    )
  }

  renderInitial = () => {
    const { content } = this.props

    if (content.is_required_feedback_reason) {
      return this.renderInitialWithReason()
    } else {
      return this.renderInitialWithoutReason()
    }
  }

  renderInitialWithoutReason = () => {
    const { t } = this.context
    const { mode, content, showAction, isSimulator } = this.props

    let buttons
    if (showAction === 'show' && !content.disabled) {
      buttons = (
        <React.Fragment>
          <div className="btn-group">
            <button
              type="button"
              key="yes"
              className="btn"
              data-value={t('chatMessage.yes')}
              onClick={() => this.sendFeedback('yes')}
            >
              {t('chatMessage.yes')}
            </button>
          </div>
          <div className="btn-group">
            <button
              type="button"
              key="no"
              className="btn"
              data-value={t('chatMessage.no')}
              onClick={() => this.sendFeedback('no')}
            >
              {t('chatMessage.no')}
            </button>
          </div>
        </React.Fragment>
      )
    } else {
      buttons = (
        <React.Fragment>
          <span key="yes" className="btn disable">
            {t('chatMessage.yes')}
          </span>
          <span key="no" className="btn disable">
            {t('chatMessage.no')}
          </span>
        </React.Fragment>
      )
    }

    const options = {
      enableMarkdown: true,
      enableImagePreview: true,
      enableAbbreviation: true,
      enableSwitchChatBot: mode === 'client' && !isSimulator,
    }
    return (
      <React.Fragment>
        <div className="panel-body">{this.parse(content.text, options)}</div>
        <div key="border" className="panel-border" />
        <div key="options" className="btn-group btn-group-justified">
          {buttons}
        </div>
        {this.renderDefaultFooterButtons('panel')}
      </React.Fragment>
    )
  }

  renderInitialWithReason = () => {
    const { t } = this.context
    const { mode, uuid, content, showAction, isSimulator, formValues } = this.props

    const disabled = showAction !== 'show' || content.disabled

    const options = {
      enableMarkdown: true,
      enableImagePreview: true,
      enableAbbreviation: true,
      enableSwitchChatBot: mode === 'client' && !isSimulator,
    }
    return (
      <React.Fragment>
        <div className="panel-body">{this.parse(content.text, options)}</div>
        <div key="border" className="panel-border" />
        <div className="panel-feedback">
          <div className="radio-group-justified">
            <div className="radio-group">
              <div className="radio-inline">
                <Field
                  name={`${uuid}.feedbackAnswer`}
                  type="radio"
                  value="yes"
                  component="input"
                  className="form-control"
                  id={`${uuid}.yes`}
                  disabled={disabled}
                />
                <label htmlFor={`${uuid}.yes`}>{t('chatMessage.yes')}</label>
              </div>
            </div>
            <div className="radio-group">
              <div className="radio-inline">
                <Field
                  name={`${uuid}.feedbackAnswer`}
                  type="radio"
                  value="no"
                  component="input"
                  className="form-control"
                  id={`${uuid}.no`}
                  disabled={disabled}
                />
                <label htmlFor={`${uuid}.no`}>{t('chatMessage.no')}</label>
              </div>
            </div>
          </div>
          {formValues.feedbackAnswer === 'no' && (
            <TextField
              name={`${uuid}.feedbackReason`}
              placeholder={content.placeholder}
              ignoreValidation={() => true}
              isTextArea={true}
              disabled={disabled}
            />
          )}
          <button
            type="button"
            onClick={this.sendFeedbackWithReason}
            disabled={formValues.feedbackAnswer !== 'yes' && !formValues.feedbackReason}
          >
            {t('chatMessage.feedback.submit')}
          </button>
        </div>
        {this.renderDefaultFooterButtons('panel')}
      </React.Fragment>
    )
  }

  renderEnquete = () => {
    const { t } = this.context
    const { mode, uuid, content, formValues, showAction, isSimulator } = this.props

    const options = {
      enableMarkdown: true,
      enableImagePreview: true,
      enableAbbreviation: true,
      enableSwitchChatBot: mode === 'client' && !isSimulator,
    }
    return (
      <React.Fragment>
        <div className="panel-body">{this.parse(content.text, options)}</div>
        <div key="border" className="panel-border" />
        <div className="panel-feedback">
          <TextField
            name={`${uuid}.feedbackReason`}
            placeholder={content.placeholder}
            ignoreValidation={() => true}
            isTextArea={true}
            disabled={showAction === 'readonly' || content.disabled}
          />
          <button
            type="button"
            onClick={this.sendFeedbackReason}
            disabled={!formValues.feedbackReason || content.disabled}
          >
            {t('chatMessage.feedback.submit')}
          </button>
        </div>
        {this.renderDefaultFooterButtons('panel')}
      </React.Fragment>
    )
  }

  renderFinish = () => {
    const { mode, content, isSimulator } = this.props
    const options = {
      enableMarkdown: true,
      enableImagePreview: true,
      enableAbbreviation: true,
      enableSwitchChatBot: mode === 'client' && !isSimulator,
    }
    return (
      <React.Fragment>
        <div className="panel-body">{this.parse(content.text, options)}</div>
        {this.renderDefaultFooterButtons('panel')}
      </React.Fragment>
    )
  }

  sendFeedback = result => {
    const { uuid, threadUUID, handleSendRawMessage } = this.props
    const content = {
      phase: 'initial',
      threadUUID: threadUUID,
      replyToUUID: uuid,
      feedbackResult: result,
    }
    handleSendRawMessage('response_feedback', content)
  }

  sendFeedbackWithReason = () => {
    const { uuid, threadUUID, formValues, handleSendRawMessage } = this.props
    if (!formValues.feedbackAnswer) return
    if (formValues.feedbackAnswer === 'no' && !formValues.feedbackReason) return

    const content = {
      phase: 'initial',
      threadUUID: threadUUID,
      replyToUUID: uuid,
      feedbackResult: formValues.feedbackAnswer,
    }

    if (formValues.feedbackAnswer === 'no') {
      content.feedbackReason = formValues.feedbackReason
    }

    handleSendRawMessage('response_feedback', content)
  }

  sendFeedbackReason = () => {
    const { uuid, threadUUID, formValues, handleSendRawMessage } = this.props
    if (!formValues.feedbackReason) return

    const content = {
      phase: 'enquete',
      threadUUID: threadUUID,
      replyToUUID: uuid,
      feedbackReason: formValues.feedbackReason,
    }
    handleSendRawMessage('response_feedback', content)
  }
}

export default RequestFeedbackMessage
