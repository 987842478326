const initialState = {
  isQuery: true,
  salesforceObjects: [],
  salesforceObjectDetails: undefined,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_INTEGRATION':
      return {
        ...state,
        isExecuting: true,
        results: undefined,
        errorMessage: undefined,
      }
    case 'RESPONSE_INTEGRATION':
      return {
        ...state,
        isExecuting: false,
        results: action.results,
      }
    case 'SET_ERROR_ON_INTEGRATION':
      return {
        ...state,
        isExecuting: false,
        errorMessage: action.message,
      }
    case 'CLEAR_INTEGRATION_RESULT':
      return {
        ...state,
        isExecuting: false,
        isQuery: action.isQuery,
        results: undefined,
        errorMessage: undefined,
      }
    case 'REQUEST_OAUTH':
      return {
        ...state,
        oauthRequestURL: undefined,
        oauthRequestSession: undefined,
        oauthErrorMessage: undefined,
      }
    case 'RESPONSE_OAUTH':
      return {
        ...state,
        oauthRequestURL: action.oauth_request.url,
        oauthRequestSession: action.oauth_request.uuid,
      }
    case 'RESPONSE_SALESFORCE_OBJECT':
      return {
        ...state,
        salesforceObjects: action.salesforceObjects,
      }
    case 'RESPONSE_SALESFORCE_OBJECT_DETAIL':
      return {
        ...state,
        salesforceObjectDetails: action.salesforceObjectDetails,
      }
    case 'CLEAR_SALESFORCE_OBJECT':
      return {
        ...state,
        salesforceObjects: [],
        salesforceObjectDetails: undefined,
      }
    case 'CLEAR_SALESFORCE_OBJECT_DETAIL':
      return {
        ...state,
        salesforceObjectDetails: undefined,
      }
    case 'SET_ERROR_ON_OAUTH':
      return {
        ...state,
        oauthErrorMessage: action.message,
      }
    default:
      return state
  }
}

export default reducer
