import lodash from 'lodash'

import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import TextareaAutosize from 'react-textarea-autosize'

const Select = React.lazy(() => import('react-select'))
const ReactAutocomplete = React.lazy(() => import('react-autocomplete'))

const ErrorNode = props => {
  const { t } = useTranslation()

  const { error } = props
  if (!error) return null

  if (typeof error === 'object') {
    return <div className="error">{t(error.id, error.values)}</div>
  } else {
    return <div className="error">{t(error)}</div>
  }
}
ErrorNode.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export const InputField = field => {
  const {
    disabled,
    input,
    meta: { submitting, touched, error },
    ...otherProps
  } = field
  return (
    <div>
      <input id={input.name} disabled={disabled || submitting} {...input} {...otherProps} />
      {touched && <ErrorNode error={error} />}
    </div>
  )
}

export const FileField = field => {
  const ignoreCancelWrapper = func => e => {
    if (e.target.files.length > 0) {
      func(e)
    }
  }

  const {
    disabled,
    input,
    meta: { submitting, touched, error },
    ...otherProps
  } = field
  input.value = undefined
  return (
    <div>
      <input
        id={input.name}
        disabled={disabled || submitting}
        {...input}
        {...otherProps}
        onChange={ignoreCancelWrapper(input.onChange)}
        onBlur={ignoreCancelWrapper(input.onBlur)}
      />
      {touched && <ErrorNode error={error} />}
    </div>
  )
}

export const TextAreaField = field => {
  const {
    disabled,
    minRows,
    maxRows,
    input,
    meta: { submitting, touched, error },
    ...otherProps
  } = field
  return (
    <div>
      <TextareaAutosize
        id={input.name}
        disabled={disabled || submitting}
        minRows={minRows || 2}
        maxRows={maxRows || 12}
        {...input}
        {...otherProps}
      />
      {touched && <ErrorNode error={error} />}
    </div>
  )
}

export const SelectField = field => {
  const {
    items,
    groupKey,
    groupFn,
    valueKey,
    valueFn,
    displayKey,
    displayFn,
    disabledKey,
    disabledFn,
    empty,
    disabled,
    input,
    order,
    meta: { submitting, touched, error },
    ...otherProps
  } = field
  let displayItems = lodash.isFunction(items) ? items(field) : items

  const getGroup = item => {
    return typeof item === 'object' ? (groupFn ? groupFn(item) : item[groupKey]) : item
  }
  const getValue = item => {
    return typeof item === 'object' ? (valueFn ? valueFn(item) : item[valueKey]) : item
  }
  const getText = item => {
    return typeof item === 'object' ? (displayFn ? displayFn(item) : item[displayKey]) : item
  }
  const getDisabled = item => {
    return typeof item === 'object' ? (disabledFn ? disabledFn(item) : item[disabledKey]) : false
  }

  const component = (item, index) => (
    <option key={index} value={getValue(item)} disabled={getDisabled(item)}>
      {getText(item)}
    </option>
  )

  let options = []
  if (groupKey || groupFn) {
    options = lodash.map(lodash.groupBy(displayItems, getGroup), (groupedItems, group) => {
      if (lodash.includes(['asc', 'desc'], order)) {
        displayItems = lodash.orderBy(groupedItems, getText, order)
      }
      return (
        <optgroup key={group} label={group}>
          {displayItems.map(component)}
        </optgroup>
      )
    })
  } else {
    if (lodash.includes(['asc', 'desc'], order)) {
      displayItems = lodash.orderBy(displayItems, getText, order)
    }
    options = displayItems.map(component)
  }

  if (empty) options.unshift(<option key="empty" />)

  return (
    <div>
      <select
        id={input.name}
        disabled={disabled || submitting}
        {...input}
        {...otherProps}
        onBlur={() => input.onBlur()}
      >
        {options}
      </select>
      {touched && <ErrorNode error={error} />}
    </div>
  )
}

export const MultipleSelectField = field => {
  const { t } = useTranslation()
  const {
    items,
    valueKey,
    displayKey,
    displayFn,
    disabled,
    input,
    hasAllSelectButton,
    meta: { submitting, touched, error },
    ...otherProps
  } = field
  const options = items.map(item => {
    return {
      label: displayFn ? displayFn(item) : item[displayKey],
      value: item[valueKey],
    }
  })

  const value = lodash.filter(options, option => lodash.includes(input.value, option.value))

  return (
    <div>
      <Suspense fallback="">
        <div className="multiple-select-container">
          <Select
            {...input}
            id={input.name}
            value={value}
            isDisabled={disabled || submitting}
            onChange={selectedOptions => input.onChange(selectedOptions.map(option => option.value))}
            onBlur={() => input.onBlur()}
            options={options}
            isMulti
            {...otherProps}
          />
          {hasAllSelectButton && (
            <button
              type="button"
              className="btn btn-primary dm-btn all-select-btn"
              onClick={() => input.onChange(options.map(option => option.value))}
              disabled={lodash.isEqual(value, options)}
            >
              {t('common.forms.allSelectLabel')}
            </button>
          )}
        </div>
        {touched && <ErrorNode error={error} />}
      </Suspense>
    </div>
  )
}

export const CheckboxField = field => {
  const {
    disabled,
    input,
    meta: { submitting },
    ...otherProps
  } = field
  return (
    <input
      id={input.name}
      disabled={disabled || submitting}
      {...input}
      {...otherProps}
      onBlur={() => input.onBlur()}
    />
  )
}

export const TimeField = field => {
  const {
    disabled,
    input,
    meta: { submitting, touched, error },
    ...otherProps
  } = field
  return (
    <div>
      <div className="form">
        <input id={input.name} disabled={disabled || submitting} {...input} {...otherProps} />
      </div>
      {touched && <ErrorNode error={error} />}
    </div>
  )
}

export const RangeField = field => {
  const {
    list,
    items,
    valueKey,
    displayKey,
    disabled,
    input,
    meta: { submitting, touched, error },
    ...otherProps
  } = field

  const label = lodash.get(lodash.find(items, [valueKey, input.value]), displayKey)

  return (
    <div>
      <div className="range-container">
        <input id={input.name} list={list} disabled={disabled || submitting} {...input} {...otherProps} />
        <label className="range-indicator">{label != null ? label : input.value}</label>
        {list != null && (
          // Scale of range field does not be displayed if same id as other tag is specified.
          <datalist id={list}>
            {lodash.map(items, item => {
              if (typeof item === 'object') {
                return <option key={item[valueKey]} value={item[valueKey]} label={item[displayKey]} />
              } else {
                return <option key={item} value={item} />
              }
            })}
          </datalist>
        )}
      </div>
      {touched && <ErrorNode error={error} />}
    </div>
  )
}

export const AutoCompleteField = field => {
  const {
    items,
    input,
    disabled,
    displayFn,
    valueKey,
    displayKey,
    parse,
    meta: { submitting, touched, error },
    ...otherProps
  } = field
  const getItemValue = item => (lodash.isPlainObject(item) ? item[valueKey] : item)
  const getItemText = item => {
    return lodash.isPlainObject(item) ? (displayFn ? displayFn(item) : item[displayKey]) : item
  }

  return (
    <div className="autocomplete-container">
      <Suspense fallback="">
        <ReactAutocomplete
          items={items}
          shouldItemRender={(item, value) => {
            const itemValue = getItemValue(item)
            if (!itemValue) return false

            // Display items that partially match contents of input form
            return lodash.includes(itemValue.toLowerCase(), value.toLowerCase())
          }}
          getItemValue={getItemValue}
          renderItem={(item, highlighted) => (
            <div key={getItemValue(item)} className={highlighted ? 'highlighted' : ''}>
              {getItemText(item)}
            </div>
          )}
          inputProps={{
            id: input.name,
            disabled: disabled || submitting,
            ...otherProps,
            onFocus: input.onFocus,
            onBlur: input.onBlur,
          }}
          value={input.value}
          onChange={input.onChange}
          onSelect={value => input.onChange(parse ? parse(value) : value)}
          wrapperStyle={{}}
          menuStyle={{}}
          renderMenu={function renderMenu(items, value, style) {
            return (
              <div style={{ ...style, ...this.menuStyle }} className="menu">
                {items}
              </div>
            )
          }}
        />
        {touched && <ErrorNode error={error} />}
      </Suspense>
    </div>
  )
}
