import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import * as URI from 'uri-js'

import Config from '../../helpers/config'

class UrlPreview extends Component {
  static propTypes = {
    enableImagePreview: PropTypes.bool.isRequired,
    enableAbbreviation: PropTypes.bool.isRequired,
    title: PropTypes.string,
    url: PropTypes.string.isRequired,
    enableSwitchChatBot: PropTypes.bool,
  }

  constructor() {
    super()
    this.state = { type: 'checking' }
  }

  componentWillMount() {
    if (!lodash.includes(['http', 'https'], URI.parse(this.props.url).scheme)) {
      this.setState({ type: 'text' })
    }
    if (!this.props.enableImagePreview) {
      this.setState({ type: 'text' })
    }
  }

  displayImage = e => {
    e.preventDefault()
    window.open(`/popup.html?url=${encodeURIComponent(this.props.url)}`, 'imageWindow', 'resizable=yes')
  }

  isEmbeddedChatURL = url => {
    if (url.startsWith(Config.redirectURL)) {
      url = new URL(url).searchParams.get('url')
    }
    return url.startsWith(Config.embeddedChatURL)
  }

  render() {
    const { title, url, enableSwitchChatBot } = this.props

    let linkTarget = '_blank'
    if (enableSwitchChatBot && this.isEmbeddedChatURL(url)) {
      linkTarget = undefined
    }

    if (this.state.type === 'text') {
      //  Text link
      return (
        <a href={URI.normalize(url)} target={linkTarget} rel="noopener noreferrer">
          {title ? title : this.abbreviateUrl(url)}
        </a>
      )
    } else if (this.state.type === 'image') {
      //  Image
      return (
        <img
          key="image"
          src={this.ignoreLogging(URI.normalize(url))}
          alt={title ? title : this.abbreviateUrl(url)}
          onClick={this.displayImage}
        />
      )
    } else if (this.state.type === 'checking') {
      //  Checking
      return (
        <span>
          <a href={URI.normalize(url)} target={linkTarget} rel="noopener noreferrer">
            {title ? title : this.abbreviateUrl(url)}
          </a>
          <img
            key="image"
            src={this.ignoreLogging(URI.normalize(url))}
            alt={title ? title : this.abbreviateUrl(url)}
            hidden={true}
            onLoad={() => {
              this.setState({ type: 'image' })
            }}
            onError={() => {
              this.setState({ type: 'text' })
            }}
          />
        </span>
      )
    }
  }

  abbreviateUrl = url => {
    if (!this.props.enableAbbreviation) {
      return url
    }
    if (lodash.includes(['file'], URI.parse(url).scheme)) {
      return url
    }
    return url.replace(/((?:https?|notes):\/\/[^\s/]*\/?)([^\s]*)/gi, (m, $1, $2) =>
      $2 ? $1.concat('...') : $1
    )
  }

  ignoreLogging = urlText => {
    if (!urlText.startsWith(Config.redirectURL)) return urlText

    const url = new URL(urlText)
    url.searchParams.append('ignore', 'true')
    return url.toString()
  }
}

export default UrlPreview
