import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import { InputField, CheckboxField } from '../../common/fields/FormFields'
import Tooltip from '../../common/Tooltip'
import LabelWithTooltip from '../../common/LabelWithTooltip'
import VariableField from '../../common/fields/VariableField'

export class SelectListRow extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    name: PropTypes.string,
    index: PropTypes.number,
    field: PropTypes.object,
  }

  static defaultProps = {
    field: {},
  }

  static rowSpan = field => {
    if (field.arguments.data_type === 'constant') {
      return 8
    } else {
      return 9
    }
  }

  renderError = (field, targetValue) => {
    const { t } = this.context
    const {
      meta: { error, touched },
    } = field
    if (!error || (!touched && targetValue == null)) return null

    if (typeof error === 'object') {
      return <div className="error">{t(error.id, error.values)}</div>
    } else {
      return <div className="error">{t(error)}</div>
    }
  }

  renderDataFields() {
    const { name, index, field } = this.props

    const length = parseInt(field.arguments.length, 10)
    const fieldLength = length > 0 ? lodash.min([length, 20]) : 1

    return (
      <div className="data-list">
        {lodash.range(fieldLength).map(i => (
          <Field
            key={`${index}-dataList-${i}`}
            name={`${name}.arguments.data_list[${i}]`}
            component={InputField}
            className="form-control"
          />
        ))}
      </div>
    )
  }

  render() {
    const { t } = this.context
    const { name, index, field } = this.props

    return (
      <React.Fragment>
        <tr key={`${index}-dataType`}>
          <td colSpan="3">
            <div className="dm-table-form">
              <label>{t('topic.actionList.form.fieldTable.dataType')}</label>
              <div className="col-xs-4">
                <Field
                  key={`${name}-constant`}
                  name={`${name}.arguments.data_type`}
                  id={`${name}-constant`}
                  type="radio"
                  value="constant"
                  className="form-control"
                  component="input"
                />
                <LabelWithTooltip
                  htmlFor={`${name}-constant`}
                  name="topic.actionList.form.fieldTable.constant"
                />
              </div>
              <div className="col-xs-4">
                <Field
                  key={`${name}-variable`}
                  name={`${name}.arguments.data_type`}
                  id={`${name}-variable`}
                  type="radio"
                  value="variable"
                  className="form-control"
                  component="input"
                />
                <LabelWithTooltip
                  htmlFor={`${name}-variable`}
                  name="topic.actionList.form.fieldTable.variable"
                />
              </div>
            </div>
          </td>
        </tr>
        {field.arguments.data_type === 'constant' && (
          <React.Fragment>
            <tr key={`${index}-length`}>
              <td colSpan="3">
                <div className="dm-table-form">
                  <label>{t('topic.actionList.form.fieldTable.length')}</label>
                  <Field
                    type="number"
                    name={`${name}.arguments.length`}
                    className="form-control dm-form-control text-right"
                    component={InputField}
                    parse={value => value && parseInt(value, 10)}
                    min="1"
                    max="20"
                  />
                  <Tooltip name="topic.tooltip.actionList.form.fieldTable.length" />
                </div>
              </td>
            </tr>
            <tr key={`${index}-data`}>
              <td colSpan="3">
                <div className="dm-table-form">
                  <label>{t('topic.actionList.form.fieldTable.data')}</label>
                  {this.renderDataFields()}
                  <Tooltip name="topic.tooltip.actionList.form.fieldTable.data" />
                </div>
              </td>
            </tr>
          </React.Fragment>
        )}
        {field.arguments.data_type === 'variable' && (
          <React.Fragment>
            <tr key={`${index}-listVariable`}>
              <td colSpan="3">
                <div className="dm-table-form">
                  <label>{t('topic.actionList.form.fieldTable.listVariable')}</label>
                  <VariableField
                    name={`${name}.arguments.list_variable`}
                    component={InputField}
                    className="form-control"
                  />
                  <Tooltip name="topic.tooltip.actionList.form.fieldTable.listVariable" />
                </div>
              </td>
            </tr>
            <tr key={`${index}-itemLabel`}>
              <td colSpan="3">
                <div className="dm-table-form with-indent">
                  <label>{t('topic.actionList.form.fieldTable.itemLabel')}</label>
                  <Field
                    name={`${name}.arguments.item_label`}
                    component={InputField}
                    className="form-control"
                  />
                  <Tooltip name="topic.tooltip.actionList.form.fieldTable.itemLabel" />
                </div>
              </td>
            </tr>
            <tr key={`${index}-itemValue`}>
              <td colSpan="3">
                <div className="dm-table-form with-indent">
                  <label>{t('topic.actionList.form.fieldTable.itemValue')}</label>
                  <Field
                    name={`${name}.arguments.item_value`}
                    component={InputField}
                    className="form-control"
                  />
                  <Tooltip name="topic.tooltip.actionList.form.fieldTable.itemValue" />
                </div>
              </td>
            </tr>
          </React.Fragment>
        )}
        <tr key={`${index}-defaultValueType`}>
          <td colSpan="3">
            <div className="dm-table-form">
              <label>{t('topic.actionList.form.fieldTable.defaultValueType')}</label>
              <div className="col-xs-4">
                <Field
                  key="constant"
                  name={`${name}.arguments.default_value_type`}
                  id={`${name}-defaultValueType-constant`}
                  type="radio"
                  value="constant"
                  className="form-control"
                  component="input"
                />
                <LabelWithTooltip
                  htmlFor={`${name}-defaultValueType-constant`}
                  name="topic.actionList.form.fieldTable.constant"
                />
              </div>
              <div className="col-xs-4">
                <Field
                  key="variable"
                  name={`${name}.arguments.default_value_type`}
                  id={`${name}-defaultValueType-variable`}
                  type="radio"
                  value="variable"
                  className="form-control"
                  component="input"
                />
                <LabelWithTooltip
                  htmlFor={`${name}-defaultValueType-variable`}
                  name="topic.actionList.form.fieldTable.variable"
                />
              </div>
            </div>
          </td>
        </tr>
        {field.arguments.default_value_type === 'constant' && (
          <tr key={`${index}-defaultValue`}>
            <td colSpan="3">
              <div className="dm-table-form">
                <label>{t('topic.actionList.form.fieldTable.defaultValue')}</label>
                <Field
                  name={`${name}.arguments.default_value`}
                  component={InputField}
                  className="form-control"
                />
                <Tooltip name="topic.tooltip.actionList.form.fieldTable.defaultValue" />
              </div>
            </td>
          </tr>
        )}
        {field.arguments.default_value_type === 'variable' && (
          <tr key={`${index}-variableName`}>
            <td colSpan="3">
              <div className="dm-table-form">
                <label>{t('topic.actionList.form.fieldTable.variableName')}</label>
                <Field
                  name={`${name}.arguments.variable_name`}
                  component={InputField}
                  className="form-control"
                />
                <Tooltip name="topic.tooltip.actionList.form.fieldTable.variableName" />
              </div>
            </td>
          </tr>
        )}
        <tr key={`${index}-collapsible`}>
          <td colSpan="3">
            <div className="dm-table-form ml-5">
              <Field
                name={`${name}.arguments.use_collapsible`}
                type="checkbox"
                component={CheckboxField}
                className="form-control"
              />
              <Field
                type="number"
                name={`${name}.arguments.default_display_limit`}
                className="form-control dm-form-control text-right with-checkbox"
                component={InputField}
                parse={value => value && parseInt(value, 10)}
                min="0"
                max="19"
                disabled={!(field.arguments || {}).use_collapsible}
              />
              <label>{t('topic.actionList.form.fieldTable.collapsibleStart')}</label>
              <Tooltip name="topic.tooltip.actionList.form.fieldTable.collapsibleStart" />
            </div>
            <Field
              name={`${name}.arguments.collapsibleError`}
              component={_field => this.renderError(_field, field.arguments.default_display_limit)}
            />
          </td>
        </tr>
        <tr key={`${index}-addEmpty`}>
          <td colSpan="3">
            <div className="dm-table-form ml-5">
              <Field
                name={`${name}.arguments.add_empty`}
                type="checkbox"
                component={CheckboxField}
                className="form-control"
              />
              <LabelWithTooltip
                htmlFor={`${name}.arguments.add_empty`}
                name="topic.actionList.form.fieldTable.addEmpty"
              />
            </div>
          </td>
        </tr>
      </React.Fragment>
    )
  }
}

export default SelectListRow
