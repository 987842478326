import lodash from 'lodash'
import ipaddr from 'ipaddr.js'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { Field, reduxForm, propTypes } from 'redux-form'

import Loader from '../../components/common/Loader'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import { CheckboxField, InputField, SelectField } from '../../components/common/fields/FormFields'

import { addNotice } from '../../actions/notice'
import { fetchAccount, updateAccount } from '../../actions/account'

import { isFetching } from '../../helpers/selector'
import { getCredentials } from '../../helpers/sessionHelper'
import { isPermitted, isPermittedCredentials } from '../../helpers/permission'
import { timezone } from '../../helpers/timezone'
import cancelUrl from '../../helpers/cancelurl'

const isValidCIDR = address => {
  try {
    ipaddr.parseCIDR(address)
    return true
  } catch (e) {
    return false
  }
}

const validate = data => {
  const errors = {}
  if (!data.company_name) {
    errors.company_name = 'validate.required'
  } else if (data.company_name.length > 100) {
    errors.company_name = { id: 'validate.exceededMaxLength', values: { length: 100 } }
  }
  if (data.use_allowed_addresses) {
    if (!data.allowed_addresses) {
      errors.allowed_addresses = 'validate.required'
    } else if (data.allowed_addresses.length > 10000) {
      errors.allowed_addresses = { id: 'validate.exceededMaxLength', values: { length: 10000 } }
    } else {
      const addresses = data.allowed_addresses.trim().split(/\s*,\s*/)
      if (!lodash.every(addresses, address => ipaddr.isValid(address) || isValidCIDR(address))) {
        errors.allowed_addresses = 'validate.invalidAddress'
      }
    }
  }
  return errors
}

export class AccountEdit extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    account: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    accountEditForm: PropTypes.object,
  }

  static defaultProps = {
    accountEditForm: {},
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    dispatch(fetchAccount(state.session.token))
    cancelUrl.setRouteLeaveHook(this)
  }

  handleSave = (data, dispatch) => {
    const { t } = this.context
    const state = this.context.store.getState()

    if (!this.props.initialValues.is_experimental && data.is_experimental) {
      window.alert(t('account.isExperimental.message'))
    }
    if (!this.props.initialValues.use_strict_password_policy && data.use_strict_password_policy) {
      if (!window.confirm(t('account.useStrictPasswordPolicy.message'))) return
    }

    const account = {
      company_name: data.company_name,
      allowed_addresses: data.allowed_addresses,
      use_allowed_addresses: data.use_allowed_addresses,
      timezone: data.timezone,
      is_experimental: data.is_experimental,
      use_strict_password_policy: data.use_strict_password_policy,
    }
    dispatch(updateAccount(state.session.token, account))
      .then(() => this.props.reset())
      .then(() => dispatch(addNotice('info', t('common.saveSuccessMessage'))))
  }

  renderRemainingTime() {
    const { t } = this.context
    const { account } = this.props
    if (account.remaining_time == null) return

    if (account.remaining_time === 0) {
      return t('common.remainingTime.expired')
    }

    const days = Math.floor(account.remaining_time / 3600 / 24)
    if (days > 0) {
      return t('common.remainingTime.days', { days: days })
    } else {
      const hours = Math.floor(account.remaining_time / 3600)
      if (hours > 0) {
        return t('common.remainingTime.hours', { hours: hours })
      } else {
        return t('common.remainingTime.lessThanHour')
      }
    }
  }

  renderCancelStatus() {
    const { account } = this.props
    if (!account.is_active) return null
    if (!account.is_canceled) return null

    return <LabelWithTooltip className="dm-title" name={'account.reservedCancel'} />
  }

  renderPlanManager() {
    const { t } = this.context
    const { account } = this.props

    if (account.is_invalidated) return null

    // Even if account is inactive, get same result of isPermittedCredentials as if it were active
    const credentials = {
      ...getCredentials(this.context),
      is_active: true,
    }

    if (isPermittedCredentials('payment_change_plan', credentials)) {
      return (
        <Link className="upgrade btn btn-primary dm-btn" to="/account/upgrade">
          {t('account.upgradePlan')}
        </Link>
      )
    } else {
      return <label className="dm-note upgrade">{t('account.forbidChangePlan')}</label>
    }
  }

  render() {
    const { t } = this.context
    const { handleSubmit, isSubmitting, isFetching, account, accountEditForm } = this.props
    const useAllowedAddresses = !!accountEditForm.use_allowed_addresses

    if (!account) return null

    // Add warning when remaining time less than one week
    let remainingTimeClass = 'remaining-time'
    if (account.remaining_time < 7 * 24 * 60 * 60) {
      remainingTimeClass = 'remaining-time warning'
    }

    //  Hide some option plans
    const invisibleOptionPlans = ['fulltext-search-option']
    const visibleOptionPlans = lodash.difference(account.option_plans, invisibleOptionPlans)

    return (
      <div className="dm-account">
        <Loader loaded={!isFetching && !isSubmitting} type="show">
          <form onSubmit={handleSubmit(this.handleSave)}>
            {account.is_invalidated && <div className="invalidated">{t('common.invalidatedAccount')}</div>}
            <div className="form-group">
              <LabelWithTooltip htmlFor="company_name" className="dm-title" name="account.companyName" />
              <Field
                name="company_name"
                component={InputField}
                type="text"
                className="form-control dm-form-control"
                maxLength="100"
              />
            </div>
            <div className="form-group">
              <LabelWithTooltip htmlFor="timezone" className="dm-title" name="account.timezone" />
              <Field
                name="timezone"
                items={timezone(t)}
                valueKey="id"
                displayKey="name"
                className="form-control dm-form-control"
                component={SelectField}
              />
            </div>
            <div className="form-group">
              <LabelWithTooltip className="dm-title" name="account.allowedAddresses.title" />
              <div className="form-inline">
                <Field
                  type="checkbox"
                  name="use_allowed_addresses"
                  className="form-control"
                  component={CheckboxField}
                  disabled={!isPermitted('feature_restricted_access', this.context)}
                />
                <LabelWithTooltip
                  htmlFor="use_allowed_addresses"
                  className="dm-checkbox"
                  name="account.allowedAddresses.enabled"
                />
              </div>
              <Field
                name="allowed_addresses"
                component={InputField}
                maxLength="10000"
                type="text"
                className="form-control dm-form-control"
                disabled={!useAllowedAddresses}
              />
            </div>
            <div className="form-group">
              <LabelWithTooltip className="dm-title" name="account.isExperimental.title" />
              <div className="form-inline">
                <Field
                  type="checkbox"
                  name="is_experimental"
                  className="form-control"
                  component={CheckboxField}
                />
                <LabelWithTooltip
                  htmlFor="is_experimental"
                  className="dm-checkbox"
                  name="account.isExperimental.enabled"
                />
              </div>
            </div>
            <div className="form-group">
              <LabelWithTooltip className="dm-title" name="account.useStrictPasswordPolicy.title" />
              <div className="form-inline">
                <Field
                  type="checkbox"
                  name="use_strict_password_policy"
                  className="form-control"
                  component={CheckboxField}
                  disabled={account.freeze_password_policy}
                />
                <LabelWithTooltip
                  htmlFor="use_strict_password_policy"
                  className="dm-checkbox"
                  name="account.useStrictPasswordPolicy.enabled"
                />
              </div>
              {account.freeze_password_policy && (
                <div className="dm-note">{t('account.useStrictPasswordPolicy.freezeMessage')}</div>
              )}
            </div>
            <div className="form-group text-right">
              <button type="submit" className="btn btn-primary dm-btn" disabled={isSubmitting}>
                {t('common.save')}
              </button>
            </div>
          </form>
          <hr />
          <div className="form-group">
            <LabelWithTooltip htmlFor="plan" className="dm-title" name="account.plan" />
            <div className="contract">
              <LabelWithTooltip className="dm-title" name={`common.longPlanNames.${account.plan}`} />
              {this.renderCancelStatus()}
              {this.renderPlanManager()}
              {!lodash.isEmpty(visibleOptionPlans) && (
                <div className="option_plans">
                  <label>{t('account.optionPlans')}</label>
                  <ul>
                    {visibleOptionPlans.map((option, index) => (
                      <li key={index}>{t(`common.optionPlans.${option}`)}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className={remainingTimeClass}>{this.renderRemainingTime()}</div>
              <div className="remarks">
                {t('account.accountNumber')}
                {account.account_number}
              </div>
            </div>
          </div>
        </Loader>
      </div>
    )
  }
}

const AccountEditForm = reduxForm({
  form: 'AccountEdit',
  enableReinitialize: true,
  validate,
})(AccountEdit)

export const mapStateToProps = state => {
  const account = lodash.first(Object.values(state.entities.accounts))
  const accountEditForm = state.form.AccountEdit || {}

  return {
    account: account,
    isFetching: isFetching(state, 'accounts'),
    accountEditForm: accountEditForm.values || {},
    initialValues: {
      ...account,
    },
  }
}

export default connect(mapStateToProps)(AccountEditForm)
