import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import DataBindingTable from '../../components/common/DataBindingTable'

class ScenarioUsageTable extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    topics: PropTypes.array.isRequired,
    scenarioUsages: PropTypes.object,
    tableState: PropTypes.object,
    updateTableState: PropTypes.func.isRequired,
    handleRefreshClick: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
  }
  static defaultProps = {
    tableState: {},
  }

  aggregateFeedback = () => {
    const { t } = this.context
    const { scenarioUsages, topics } = this.props
    if (!scenarioUsages) {
      return {}
    }
    const nameObject = {}
    topics.forEach(topic => (nameObject[topic.id] = topic.name))
    const countTotal = lodash.sumBy(scenarioUsages.topics, topic => (topic.id ? topic.count : 0))
    const scenarioAggregation = scenarioUsages.topics
      .map(topic => {
        const aggregateData = {
          name: lodash.has(nameObject, topic.id)
            ? nameObject[topic.id]
            : t('analytics.graph.usageGraph.scenarioUsage.graph.classificationFailed'),
          count: topic.count,
          ratio: -1,
          feedbackCount: -1,
          yes: -1,
          no: -1,
          useful: -1,
          order: 0,
        }
        if (lodash.has(nameObject, topic.id)) {
          const feedbackCount = lodash.sum(['yes', 'no'].map(key => topic.feedback[key]))
          if (feedbackCount > 0) {
            return lodash.extend(aggregateData, {
              ratio: Math.round((topic.count / countTotal) * 100),
              feedbackCount: feedbackCount,
              yes: topic.feedback.yes,
              no: topic.feedback.no,
              useful: Math.round((topic.feedback.yes / feedbackCount) * 100),
            })
          } else {
            return lodash.extend(aggregateData, {
              ratio: topic.count ? Math.round((topic.count / countTotal) * 100) : 0,
            })
          }
        } else if (!topic.id) {
          return lodash.extend(aggregateData, {
            count: topic.error_count,
          })
        } else {
          return null
        }
      })
      .filter(scenario => scenario)

    const defaultTotal = {
      name: t('analytics.graph.usageGraph.scenarioUsage.graph.total'),
      ratio: -1,
      count: 0,
      feedbackCount: -1,
      yes: -1,
      no: -1,
      useful: -1,
      order: 1,
    }
    const total = { count: 0 }
    lodash.forEach(scenarioAggregation, row => {
      total.count += row.count
      if (row.feedbackCount > 0) {
        total.feedbackCount = lodash.get(total, 'feedbackCount', 0) + row.feedbackCount
        total.yes = lodash.get(total, 'yes', 0) + row.yes
        total.no = lodash.get(total, 'no', 0) + row.no
      }
    })

    if (total.feedbackCount) total.useful = Math.round((total.yes / total.feedbackCount) * 100)

    scenarioAggregation.push({ ...defaultTotal, ...total })
    return scenarioAggregation
  }

  render() {
    const { t } = this.context
    const { scenarioUsages, tableState, handleRefreshClick, updateTableState, isFetching } = this.props

    const scenarioAggregation = this.aggregateFeedback()
    const columns = [
      {
        Header: t('analytics.graph.usageGraph.scenarioUsage.table.scenario'),
        id: 'name',
        accessor: 'name',
        width: 170,
      },
      {
        Header: t('analytics.graph.usageGraph.scenarioUsage.table.count'),
        id: 'count',
        accessor: 'count',
        style: { textAlign: 'right' },
        Cell: props => <div> {props.value.toLocaleString()} </div>,
      },
      {
        Header: t('analytics.graph.usageGraph.scenarioUsage.table.ratio'),
        id: 'ratio',
        accessor: 'ratio',
        Cell: props => (props.value === -1 ? <div>-</div> : <div> {props.value} </div>),
        style: { textAlign: 'right' },
      },
      {
        Header: t('analytics.graph.usageGraph.scenarioUsage.table.feedback'),
        id: 'feedbackCount',
        accessor: 'feedbackCount',
        style: { textAlign: 'right' },
        Cell: props => (props.value === -1 ? <div>-</div> : <div> {props.value.toLocaleString()} </div>),
      },
      {
        Header: t('analytics.graph.usageGraph.scenarioUsage.table.yes'),
        id: 'yes',
        accessor: 'yes',
        style: { textAlign: 'right' },
        Cell: props => (props.value === -1 ? <div>-</div> : <div> {props.value.toLocaleString()} </div>),
      },
      {
        Header: t('analytics.graph.usageGraph.scenarioUsage.table.no'),
        id: 'no',
        accessor: 'no',
        style: { textAlign: 'right' },
        Cell: props => (props.value === -1 ? <div>-</div> : <div> {props.value.toLocaleString()} </div>),
      },
      {
        Header: t('analytics.graph.usageGraph.scenarioUsage.table.useful'),
        id: 'useful',
        accessor: 'useful',
        Cell: props => (props.value === -1 ? <div>-</div> : <div> {props.value} </div>),
        style: { textAlign: 'right' },
      },
      {
        id: 'order',
        accessor: 'order',
        show: false,
      },
    ]

    return (
      <div className="ml-3 mb-3">
        <DataBindingTable
          title={t('analytics.graph.usageGraph.scenarioUsage.table.title')}
          items={scenarioUsages ? scenarioAggregation : []}
          columns={columns}
          tableName="scenarioTable"
          defaultSorted={[
            { id: 'order', desc: true },
            { id: 'count', desc: true },
          ]}
          isFetching={isFetching}
          tableState={tableState}
          updateTableState={updateTableState}
          onRefresh={handleRefreshClick}
        />
      </div>
    )
  }
}

export default ScenarioUsageTable
