export const getCredentials = context => {
  const state = context.store.getState()
  const account = state.session.account || {}

  return {
    token: state.session.token,
    is_active: account.is_active,
    is_invalidated: account.is_invalidated,
    is_canceled: account.is_canceled,
    is_experimental: account.is_experimental,
    plan: account.plan,
    option_plans: account.option_plans,
    role: state.session.role,
    allow_fulltext_search: account.allow_fulltext_search,
    timezone: account.timezone ? account.timezone : 'Asia/Tokyo',
  }
}
