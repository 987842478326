import lodash from 'lodash'
import moment from 'moment'

export const hasSavedMessages = channelUUID => {
  if (!channelUUID) return false

  const key = `messages.${channelUUID}`
  return !!localStorage.getItem(key)
}

export const saveMessages = state => {
  if (!state.currentChannel) return
  if (lodash.isEmpty(state.messages)) return

  const key = `messages.${state.currentChannel}`
  const serializedMessages = JSON.stringify(state.messages.slice(-300))
  localStorage.setItem(key, serializedMessages)
}

export const restoreMessages = channelUUID => {
  if (!channelUUID) return []

  const key = `messages.${channelUUID}`
  const serializedMessages = localStorage.getItem(key) || '[]'
  const messages = JSON.parse(serializedMessages)
  messages.forEach(message => {
    if (!message.timestamp) return

    message.timestamp = moment(message.timestamp)
  })
  return messages
}

export const removeExpiredMessages = ({ days, ignoreChannels }) => {
  const expirationInDays = moment().subtract(days, 'days')
  const ignoreKeys = ignoreChannels.map(uuid => `messages.${uuid}`)

  Object.entries(localStorage).forEach(([key, serializedMessages]) => {
    if (ignoreKeys.includes(key)) return
    if (!key.startsWith('messages.')) return

    const messages = JSON.parse(serializedMessages)
    const lastMessageTimestamp = lodash.last(messages)?.timestamp
    if (lastMessageTimestamp && moment(lastMessageTimestamp).isAfter(expirationInDays)) return

    const [_, channel] = key.split('.')
    localStorage.removeItem(`messages.${channel}`)
    localStorage.removeItem(`joinParameters.${channel}`)
  })
}
