export const freeEmailDomains = [
  'msn.com',
  'gmail.com',
  'hotmail.co.jp',
  'hotmail.com',
  'yahoo.co.jp',
  'mail.goo.ne.jp',
  'freemail.ne.jp',
  'tokyo24.com',
  'excite.co.jp',
  'infoseek.co.jp',
  'infoseek.jp',
  'comeon.to',
  'comeon.cx',
  'cuib.or.jp',
  'ziplip.co.jp',
  'zdnetmail.ne.jp',
  'supermail.com',
  'lycos.ne.jp',
  'lycos.com',
  'clubaa.com',
  'prontomail.ne.jp',
  'itpmail.itp.ne.jp',
  'bizoffi.com',
  'xaque.com',
  'safe-mail.ne.jp',
  '100100.co.jp',
  'mailkun.com',
  'curio-city.com',
  'teamgear.net',
  '24h.co.jp',
  'gariya.net',
  'pub.ne.jp',
  'jmail.co.jp',
  'kigaru.zzn.com',
  'goomail.com',
  'iloveyou-jp.com',
  'wbs-club.ne.jp',
  'otegami.com',
  'piyomail.com',
  'iat.ne.jp',
  'aol.com',
  'tok2.com',
  'kobe-city.com',
  'xmail.to',
  'club.wonder.ne.jp',
  'pub.to',
  'csc.ne.jp',
  'club.ne.jp',
  'mcn.ne.jp',
  'postpet.co.jp',
  'manbow.com',
  'ijk.com',
  'drive.co.jp',
  'yagi.net',
  'pospe.jp.prg',
  'estyle.ne.jp',
  'eastmail.com',
  'shagami.com',
  'voo.to',
  'julex.to',
  'yi-web.com',
  'mailfriend.net',
  'koei.nu',
  'goo.jp',
  'kobej.zzn.com',
  'pc_run.zzn.com',
  'fact-mail.com',
  'walkerplus.com',
  'keyakiclub.net',
  'yesyes.jp',
  'fubako.com',
  'smoug.net',
  'meritmail.net',
  'vjp.jp',
  'melmel.tv',
  'ultrapostman.com',
  'uymail.com',
  'sailormoon.com',
  'astroboymail.com',
  'doramail.com',
  'dbzmail.com',
  'aamail.jp',
  'lunashine.net',
  'gooo.jp',
  '1kw.jp',
  'hsjp.net',
  'glaystyle.net',
  'saku2.com',
  'kyouin.com',
  'Netidol.jp',
  'csc.jp',
  'kanagawa.to',
  'mukae.com',
  'anet.ne.jp',
]
