import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Loader from '../../components/common/Loader'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'

import { fetchApplication } from '../../actions/application'
import { fetchDeliveryJob } from '../../actions/delivery'

import { convertLineBreak } from '../../helpers/lineBreakConverter'
import { isFetching } from '../../helpers/selector'
import { rejectReadonlyUser } from '../../helpers/permission'
import { getCredentials } from '../../helpers/sessionHelper'
import { replaceSystemVariableWhenOnChange } from '../../helpers/replaceSystemVariable'

export class DeliveryJobDetail extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    application: PropTypes.object,
    job: PropTypes.object,
    params: PropTypes.shape({
      application_id: PropTypes.string,
      id: PropTypes.string,
    }),
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    if (this.props.params.id) {
      const { application_id, id } = this.props.params
      dispatch(fetchApplication(state.session.token, application_id))
      dispatch(fetchDeliveryJob(state.session.token, application_id, id))
    }
  }

  copy = () => {
    const { router } = this.context
    const {
      dispatch,
      params: { application_id, id },
    } = this.props

    if (rejectReadonlyUser(dispatch, this.context)) return

    router.push(`/delivery/${application_id}/jobs/${id}/copy`)
  }

  render() {
    const { t } = this.context
    const { application, job, isFetching } = this.props
    const { timezone } = getCredentials(this.context)

    let statusText
    const showPercentage = ['enqueued', 'error'].includes(job.status)
    if (showPercentage && job.scheduled_target_count > 0) {
      const percent = Math.min(
        ((job.success_count + job.failure_count) / job.scheduled_target_count) * 100,
        100
      ).toFixed(1)
      statusText = t(`delivery.jobs.statuses.${job.status}`) + ` (${percent}%)`
    } else if (job.status) {
      statusText = t(`delivery.jobs.statuses.${job.status}`)
    }

    return (
      <div>
        <Loader loaded={!isFetching} type="show">
          <div className="mb-3">
            <label className="dm-title-mini">{t('delivery.jobs.applicationName')}</label>
            <div className="dm-value ml-3">{application.name}</div>
          </div>
          <div className="form-group">
            <LabelWithTooltip htmlFor="name" className="dm-title-mini" name="delivery.jobs.name" />
            <div className="dm-value ml-3">{job.name}</div>
          </div>
          <div className="form-group">
            <LabelWithTooltip htmlFor="message" className="dm-title-mini" name="delivery.jobs.message" />
            <div className="dm-value ml-3">{convertLineBreak(job.message)}</div>
          </div>
          <div className="form-group">
            <LabelWithTooltip htmlFor="status" className="dm-title-mini" name="delivery.jobs.status" />
            <div className="dm-value ml-3">{statusText}</div>
          </div>
          <div className="form-group">
            <LabelWithTooltip
              htmlFor="successCount"
              className="dm-title-mini"
              name="delivery.jobs.successCount"
            />
            <div className="dm-value ml-3">{job.success_count}</div>
          </div>
          <div className="form-group">
            <LabelWithTooltip
              htmlFor="failureCount"
              className="dm-title-mini"
              name="delivery.jobs.failureCount"
            />
            <div className="dm-value ml-3">{job.failure_count}</div>
          </div>
          <div className="form-group">
            <LabelWithTooltip htmlFor="updatedAt" className="dm-title-mini" name="delivery.jobs.updatedAt" />
            <div className="dm-value ml-3">
              {job.updated_at ? moment.tz(job.updated_at, timezone).format('YYYY/MM/DD HH:mm') : ''}
            </div>
          </div>
          <div className="form-group text-right">
            <button type="button" className="btn btn-primary dm-btn" onClick={this.copy}>
              {t('delivery.jobs.copy')}
            </button>
          </div>
        </Loader>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const application = state.entities.applications[props.params.application_id] || {}
  const job = state.entities.delivery_jobs[props.params.id] || {}
  replaceSystemVariableWhenOnChange(job, 'message', false, false)

  return {
    application,
    job,
    isFetching: isFetching(state),
  }
}

export default connect(mapStateToProps)(DeliveryJobDetail)
