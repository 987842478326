import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes } from 'redux-form'
import { isEmail } from 'validator'

import { forgotPassword } from '../../actions/user'
import { addNotice } from '../../actions/notice'
import { InputField } from '../../components/common/fields/FormFields'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'

const validate = data => {
  const errors = {}
  if (!data.email) {
    errors.email = 'validate.required'
  } else if (!isEmail(data.email)) {
    errors.email = 'validate.invalidEmail'
  }
  return errors
}

export class ForgotPassword extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
  }

  handleReset = (data, dispatch) => {
    const { t, router } = this.context
    return dispatch(forgotPassword(data.email))
      .then(() => router.replace('/login'))
      .then(() => dispatch(addNotice('info', t('passwordReset.sendMailMessage'))))
  }

  render() {
    const { t } = this.context
    const { handleSubmit, isSubmitting } = this.props

    return (
      <div className="dm-login is-forgot">
        <div className="row description">
          {t('passwordReset.forgot.description1')}
          <br />
          {t('passwordReset.forgot.description2')}
        </div>
        <div className="row">
          <div className="col-sm-2 col-md-2" />
          <form className="text-left col-sm-6 col-md-6" onSubmit={handleSubmit(this.handleReset)}>
            <div className="form-group">
              <LabelWithTooltip htmlFor="email" className="dm-title-mini" name="signup.email" />
              <Field
                type="email"
                name="email"
                className="form-control dm-form-control"
                component={InputField}
              />
            </div>
            <div className="form-group text-right">
              <button type="submit" disabled={isSubmitting} className="btn btn-primary dm-btn">
                {t('passwordReset.reset')}
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const ForgotPasswordForm = reduxForm({
  form: 'ForgotPassword',
  validate,
})(ForgotPassword)

export default connect()(ForgotPasswordForm)
