import lodash from 'lodash'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactTable from 'react-table'
import ReactTablePagination from 'react-table/lib/pagination'
import 'react-table/react-table.css'
import { Field } from 'redux-form'

import Tooltip from './Tooltip'

class FixedReactTablePagination extends ReactTablePagination {
  getPageJumpProperties() {
    const properties = super.getPageJumpProperties()
    properties.onChange = e => {
      const val = e.target.value
      const page = val - 1
      if (val === '') {
        this.setState({ page: val })
      } else {
        this.setState({ page: this.getSafePage(page) })
      }
      setTimeout(this.applyPage, 0)
    }
    return properties
  }
}

class DataBindingTable extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    title: PropTypes.string,
    titleTooltip: PropTypes.string,
    remarks: PropTypes.array,
    items: PropTypes.array,
    columns: PropTypes.array,
    isFetching: PropTypes.bool,
    defaultSorted: PropTypes.array,
    tableName: PropTypes.string,
    tableState: PropTypes.object,
    updateTableState: PropTypes.func,
    onRefresh: PropTypes.func,
    showPageSizeOptions: PropTypes.bool,
    filterable: PropTypes.bool,
    noDataText: PropTypes.string,
  }

  static defaultProps = {
    items: [],
    columns: [],
    isFetching: false,
    tableState: {},
    showPageSizeOptions: true,
    filterable: false,
  }

  componentWillUpdate(nextProps) {
    const { items, tableState } = nextProps
    if (lodash.isEmpty(items)) return
    if (this.props.items.length === items.length && this.props.tableState.pageSize === tableState.pageSize)
      return

    this.resetPageIfOutOfRange(items, tableState)
  }

  resetPageIfOutOfRange = (items, tableState) => {
    const { tableName, updateTableState } = this.props

    const pageSize = tableState.pageSize || 10
    const maxPage = Math.max(Math.ceil(items.length / pageSize) - 1, 0)

    if ((tableState.page || 0) > maxPage) {
      updateTableState(window.location.pathname, tableName, { page: 0 })
    }
  }

  renderRemarks = () => {
    const { remarks } = this.props
    return (
      <ul className="notes">
        {lodash.map(remarks, remark => {
          return <p key={remark}>{remark}</p>
        })}
      </ul>
    )
  }

  render() {
    const { t } = this.context
    const {
      title,
      titleTooltip,
      remarks,
      columns,
      items,
      tableName,
      tableState,
      defaultSorted,
      updateTableState,
      isFetching,
      onRefresh,
      showPageSizeOptions,
      filterable,
      noDataText,
    } = this.props

    const pageSize = tableState.pageSize || 10

    const maxPage = Math.max(Math.ceil(items.length / pageSize) - 1, 0)
    const page = Math.min(tableState.page || 0, maxPage)

    const sorted = tableState.sorted ||
      defaultSorted || [{ id: columns[0].id ? columns[0].id : columns[0].accessor, desc: false }]

    let withFooter = false
    if (
      lodash.find(columns, column => {
        return 'Footer' in column
      })
    ) {
      withFooter = true
    }

    const className = classnames({
      'dm-table': true,
      filterable: filterable,
    })
    return (
      <div className={className}>
        {title && (
          <div className="dm-caption">
            <span>{title}</span>
            {titleTooltip && <Tooltip name={titleTooltip} />}
            {remarks && <Field name="remarks" component={this.renderRemarks} />}
            {onRefresh && <button className="dm-btn btn btn-default btn-icon-refresh" onClick={onRefresh} />}
          </div>
        )}
        <ReactTable
          columns={columns}
          data={items}
          loading={isFetching}
          page={page}
          pageSize={pageSize}
          sortable={!!updateTableState}
          sorted={sorted}
          minRows={items.length > pageSize ? pageSize : Math.max(items.length, 1)}
          showPagination={items.length > 10}
          showPageSizeOptions={showPageSizeOptions}
          pageSizeOptions={[10, 20, 50, 100, 200, 1000, 2000]}
          previousText={'<'}
          nextText={'>'}
          pageText={'Page'}
          ofText={'/'}
          rowsText={t('common.rows')}
          noDataText={noDataText ?? t('common.noRowsFound')}
          className={withFooter ? 'table' : 'table no-footer'}
          filterable={filterable}
          defaultFilterMethod={(filter, row) => lodash.includes(row[filter.id], filter.value)}
          PaginationComponent={FixedReactTablePagination}
          // Callbacks
          onPageChange={currentPage => {
            updateTableState(window.location.pathname, tableName, { page: currentPage })
          }}
          onPageSizeChange={currentPageSize => {
            updateTableState(window.location.pathname, tableName, { pageSize: currentPageSize, page: 0 })
          }}
          onSortedChange={currentSorted => {
            updateTableState(window.location.pathname, tableName, { sorted: currentSorted })
          }}
          onFilteredChange={_currentFilter => {
            updateTableState(window.location.pathname, tableName, { page: 0 })
          }}
        />
      </div>
    )
  }
}

export default DataBindingTable
