import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import { InputField } from '../../common/fields/FormFields'
import Tooltip from '../../common/Tooltip'

export class TextRow extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    name: PropTypes.string,
    index: PropTypes.number,
  }

  static rowSpan = _field => {
    return 3
  }

  render() {
    const { t } = this.context
    const { name, index } = this.props

    return (
      <React.Fragment>
        <tr key={`${index}-defaultValue`}>
          <td colSpan="3">
            <div className="dm-table-form">
              <label>{t('topic.actionList.form.fieldTable.defaultValue')}</label>
              <Field
                name={`${name}.arguments.default_value`}
                component={InputField}
                className="form-control"
              />
              <Tooltip name="topic.tooltip.actionList.form.fieldTable.defaultValue" />
            </div>
          </td>
        </tr>
        <tr key={`${index}-placeholder`}>
          <td colSpan="3">
            <div className="dm-table-form">
              <label>{t('topic.actionList.form.fieldTable.placeholder')}</label>
              <Field name={`${name}.arguments.placeholder`} component={InputField} className="form-control" />
              <Tooltip name="topic.tooltip.actionList.form.fieldTable.placeholder" />
            </div>
          </td>
        </tr>
      </React.Fragment>
    )
  }
}

export default TextRow
