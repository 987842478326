import PropTypes from 'prop-types'
import React, { Component } from 'react'

class Tooltip extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    direction: PropTypes.string,
  }

  static defaultProps = {
    direction: 'right',
  }

  render() {
    const { t } = this.context
    const { name, direction } = this.props
    const text = t(name)
    if (text === name) return null

    return (
      <div className="dm-tooltip">
        <img src="/image/icon_tooltip.png" alt="" />
        <div className={`popup ${direction}`} dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    )
  }
}

export default Tooltip
