import lodash from 'lodash'

class Tool {
  constructor(id, name, json, language = 'en') {
    this.fetched = true
    this.language = language

    this.id = id
    this.name = name
    this.description = this.translate(json.description, this.language)
    this.endpoints = this.generateEndpoints(json.paths)
  }

  generateEndpoints(paths) {
    const endpoints = []

    lodash.each(paths, (detail, path) => {
      lodash.each(detail, (endpoint, method) => {
        endpoints.push({
          ...endpoint,
          endpoint: `${method.toUpperCase()} ${path}`,
          description: this.translate(endpoint.description, this.language),
        })
      })
    })

    return endpoints
  }

  getPreconfiguredParameters() {
    const parameters = []
    lodash.each(this.endpoints, endpoint => {
      lodash.each(endpoint.parameters, parameter => {
        if (parameter.runtime) return

        parameters.push({
          ...parameter,
          description: this.translate(parameter.description, this.language),
        })
      })
    })

    return lodash.uniqWith(parameters, (a, b) => a.in === b.in && a.name === b.name)
  }

  translate(description, language = 'en') {
    if (lodash.isString(description)) return description
    return description[language] || description.en
  }
}

export class ToolDirectory {
  constructor(server) {
    this.server = server
    this.tools = {}
  }

  async fetchTool(id, language = 'en') {
    const response = await fetch(this.server + `/integrations/${id}/integration.json`)
    const name = this.tools[id]
    return new Tool(id, name, await response.json(), language)
  }

  async fetchToolIndex() {
    const response = await fetch(this.server + '/index.json')
    const tools = (await response.json()).integrations
    this.tools = Object.assign({}, ...tools.map(tool => ({ [tool.id]: tool })))
    return this.tools
  }
}
