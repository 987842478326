import lodash from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { decode } from 'jwt-simple'

import { getCredentials } from '../helpers/sessionHelper'
import CallingWatcher from './utils/CallingWatcher'
import { AuthorizedComponent } from '../components/common/AuthorizedComponent'

import { fetchAccount } from '../actions/account'
import { fetchUser } from '../actions/user'
import { completeSessionLoading, deleteSession, updateSession } from '../actions/session'
import { addNotice } from '../actions/notice'

class RequireLogin extends AuthorizedComponent {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    session: PropTypes.object,
    children: PropTypes.node,
  }

  constructor(props, context) {
    super(props, context)

    this.handleUnauthorizedPlan = () => {
      context.router.replace('/')
    }
    this.handleUnauthorizedRole = () => {
      context.router.replace('/')
    }
  }

  componentWillMount() {
    const { session } = this.props

    if (!session.token) {
      this.context.router.replace('/login')
      return
    }
    if (this.checkSession(this.props)) {
      super.componentWillMount()
    }
  }

  componentDidMount() {
    const { session, dispatch } = this.props
    //  For Edge compatibility
    if (session.token) {
      const payload = decode(session.token, '', true)
      const sub = payload.sub || payload.identity
      dispatch(fetchUser(session.token, sub.id)).then(userResponse => {
        dispatch(fetchAccount(session.token)).then(accountResponse => {
          const user = lodash.first(Object.values(userResponse.entities.users))
          const account = lodash.first(Object.values(accountResponse.entities.accounts))
          const identity = {
            id: sub.id,
            account_uuid: sub.account_uuid,
            name: user.name,
            email: user.email,
            role: user.role,
            account: {
              is_active: account.is_active,
              is_experimental: account.is_experimental,
              plan: account.plan,
              option_plans: account.option_plans,
              timezone: account.timezone,
              allow_fulltext_search: account.allow_fulltext_search,
            },
          }
          dispatch(updateSession(identity))
          dispatch(completeSessionLoading())

          if (window.zE) {
            // Show Zendesk widget and prefill user name and email.
            window.zE('webWidget', 'show')
            window.zE('webWidget', 'prefill', {
              name: {
                value: user.name,
                readOnly: false,
              },
              email: {
                value: user.email,
                readOnly: false,
              },
            })
          }
        })
      })
    }
  }

  componentWillUpdate(nextProps) {
    const { dispatch, session } = this.props
    if (nextProps.session.token !== session.token) {
      dispatch(deleteSession())
      if (nextProps.session.token) {
        dispatch(updateSession(session.token, { identity: session }))
        dispatch(fetchAccount(session.token))
      }
    }

    if (this.checkSession(nextProps)) {
      super.componentWillUpdate(nextProps)
    }

    if (!nextProps.session.token) {
      if (window.zE) {
        // Hide Zendesk widget if already logged out.
        window.zE('webWidget', 'hide')
      }
    }
  }

  checkSession = props => {
    const { t } = this.context
    const { dispatch } = this.props

    if (!props.session.token) {
      Promise.resolve()
        .then(() => this.context.router.replace('/login'))
        .then(() => dispatch(addNotice('error', t('error.expiredToken'))))
      return false
    }
    const payload = decode(props.session.token, '', true)
    if (new Date().getTime() > payload.exp * 1000) {
      dispatch(deleteSession())
      return false
    }
    if (!props.session.loaded) {
      return false
    }
    return true
  }

  render() {
    const { plan, role } = getCredentials(this.context)
    if (!this.props.session.token) return null
    if (!plan || !role) return null

    const {
      session: { account_uuid },
    } = this.props

    return (
      <div>
        <div>{this.props.children}</div>
        <div>
          <CallingWatcher accountUUID={account_uuid} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const account = lodash.first(Object.values(state.entities.accounts))
  return {
    session: state.session,
    account,
  }
}

export default connect(mapStateToProps)(RequireLogin)
