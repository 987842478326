import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form'
import { SelectField, CheckboxField } from '../../components/common/fields/FormFields'
import LabelWithTooltip from '../common/LabelWithTooltip'

import AbstractAction from './AbstractAction'
import TextField from '../common/fields/TextField'
import CardTextField from '../common/fields/CardTextField'

export class ClassifyFaqAction extends AbstractAction {
  static propTypes = {
    ...AbstractAction.propTypes,
    faqs: PropTypes.array.isRequired,
  }

  static validate = action => {
    const errors = super.validate(action)

    if (!action.faq_id) {
      errors.faq_id = 'validate.required'
    }

    return errors
  }

  getTitle() {
    return this.context.t('topic.actionList.faq.title')
  }
  getType() {
    return 'user'
  }

  static getVariables = (action, _props, _context) => {
    const { t } = _context
    return [
      {
        variable: t('systemVariables.@latestClassifiedFaqQuestion'),
        text: t('systemVariables.@latestClassifiedFaqQuestion'),
      },
      {
        variable: t('systemVariables.@latestClassifiedFaqItemName'),
        text: t('systemVariables.@latestClassifiedFaqItemName'),
      },
    ]
  }

  renderBody() {
    const { t } = this.context
    const { action, name, faqs } = this.props
    const InputField = action.use_drilldown ? CardTextField : TextField
    const placeholderKey = action.use_drilldown ? 'drilldown' : 'normal'

    return (
      <div className="faq">
        {/* question text  */}
        <div className="row">
          <LabelWithTooltip
            htmlFor={`${name}.content`}
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.faq.question"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <InputField
              name={`${name}.content`}
              className="form-control"
              placeholder={t(`topic.actionList.faq.questionPlaceHolders.${placeholderKey}`)}
            />
          </div>
        </div>

        {/* faq select  */}
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor={`${name}.faq_id`}
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.faq.target"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <Field
              name={`${name}.faq_id`}
              className="form-control"
              component={SelectField}
              items={faqs}
              valueKey="id"
              displayKey="name"
              empty={true}
              parse={value => value && parseInt(value, 10)}
              order="asc"
            />
          </div>
        </div>

        {/* drilldown setting */}
        <div className="form-group form-inline">
          <Field
            type="checkbox"
            name={`${name}.use_drilldown`}
            component={CheckboxField}
            className="form-control"
          />
          <LabelWithTooltip htmlFor={`${name}.use_drilldown`} name="topic.actionList.faq.useDrilldown" />
        </div>

        {/* Use to recommendation setting */}
        <div className="form-group form-inline">
          <Field
            type="checkbox"
            name={`${name}.use_recommendations`}
            component={CheckboxField}
            className="form-control"
          />
          <LabelWithTooltip
            htmlFor={`${name}.use_recommendations`}
            name="topic.actionList.faq.useRecommendations"
          />
        </div>

        {/* Use to scenario classifier setting */}
        <div className="form-group form-inline">
          <Field
            type="checkbox"
            name={`${name}.use_questions_to_topic_classification`}
            component={CheckboxField}
            className="form-control"
          />
          <LabelWithTooltip
            htmlFor={`${name}.use_questions_to_topic_classification`}
            name="topic.actionList.faq.useQuestionsToTopicClassification"
          />
        </div>

        {/* Use to faq item name */}
        <div className="form-group form-inline">
          <Field
            type="checkbox"
            name={`${name}.use_item_name_message`}
            component={CheckboxField}
            className="form-control"
          />
          <LabelWithTooltip
            htmlFor={`${name}.use_item_name_message`}
            name="topic.actionList.faq.useItemNameMessage.title"
          />
        </div>
        {action.use_item_name_message && (
          <div className="form-group with-indent">
            <LabelWithTooltip
              htmlFor={`${name}.item_name_message_template`}
              className="col-sm-3"
              name="topic.actionList.faq.useItemNameMessage.template"
            />
            <div className="col-sm-9">
              <TextField
                name={`${name}.item_name_message_template`}
                className="form-control"
                placeholder={t('topic.actionList.faq.useItemNameMessage.placeholder')}
                nullable={true}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default ClassifyFaqAction
