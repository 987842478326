import lodash from 'lodash'
import fetch from 'isomorphic-fetch'
import Config from '../helpers/config'

const receiveNews = news => ({
  type: 'RECEIVE_NEWS',
  news,
})

export const fetchNews = () => dispatch => {
  if (!Config.newsURLs) return

  return Promise.all(Config.newsURLs.map(url => fetch(url).then(response => response.json()))).then(
    responses => {
      const articles = lodash.concat(...lodash.map(responses, 'articles'))
      const sortedArticles = lodash.orderBy(articles, 'created_at', 'desc')
      return dispatch(receiveNews(sortedArticles))
    }
  )
}

const receiveImportantMessages = messages => ({
  type: 'RECEIVE_IMPORTANT_MESSAGES',
  messages,
})

export const fetchImportantMessages = () => dispatch => {
  if (!Config.importantMessagesURLs) return

  const fetchJson = fetch(Config.importantMessagesURLs.json)
    .then(response => response.json())
    .then(json => json.messages)

  const fetchZendesk = fetch(Config.importantMessagesURLs.zendesk)
    .then(response => response.json())
    .then(json =>
      json.articles.map(article => ({
        text: `<a href="${article.html_url}" target="_blank">${article.title}</a>`,
      }))
    )

  return Promise.all([fetchJson, fetchZendesk]).then(messagesList => {
    const messages = lodash.concat(...messagesList)
    dispatch(receiveImportantMessages(messages))
  })
}
