import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'

import DataBindingTable from '../../components/common/DataBindingTable'
import { fetchEndpoints } from '../../actions/endpoint'
import { updateTableState } from '../../actions/table'
import Config from '../../helpers/config'
import { isFetching } from '../../helpers/selector'

class EndpointIndex extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    endpoints: PropTypes.array.isRequired,
    isFetching: PropTypes.bool,
    tableState: PropTypes.object,
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    dispatch(fetchEndpoints(state.session.token))
  }

  componentWillMount() {
    if (!Config.enableWatson) {
      this.context.router.replace('/')
    }
  }

  handleRefreshClick = () => {
    const state = this.context.store.getState()
    this.props.dispatch(fetchEndpoints(state.session.token))
  }

  handleAddClick = () => {
    this.context.router.push('/endpoints/new')
  }

  updateTableState = (path, tableName, tableState) => {
    const { dispatch } = this.props
    dispatch(updateTableState(path, tableName, tableState))
  }

  render() {
    const { t } = this.context
    const { endpoints, isFetching, tableState } = this.props
    const watsonEndpoints = endpoints.filter(endpoint => endpoint.type === 'watson')
    const columns = [
      {
        Header: t('endpoint.type'),
        accessor: 'type',
        Cell: row => <Link to={`/endpoints/${row.original.id}`}>{row.value}</Link>,
        getFooterProps: () => ({ className: 'footer-all' }),
        Footer: (
          <Link className="dm-btn btn btn-primary btn-icon-plus mini" onClick={this.handleAddClick}>
            <span>{t('common.new')}</span>
          </Link>
        ),
      },
      {
        Header: t('endpoint.watson.url'),
        accessor: 'url',
      },
      {
        Header: t('endpoint.watson.username'),
        accessor: 'username',
      },
      {
        Header: t('endpoint.watson.language'),
        accessor: 'language',
      },
    ]
    return (
      <div>
        <DataBindingTable
          title={t('endpoint.watson.title')}
          items={watsonEndpoints}
          columns={columns}
          isFetching={isFetching}
          tableState={tableState}
          updateTableState={this.updateTableState}
          onRefresh={this.handleRefreshClick}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const ids = state.pagination.endpoints.ids || []
  const endpoints = ids.map(id => state.entities.endpoints[id])
  return {
    endpoints: endpoints,
    isFetching: isFetching(state),
    tableState: state.table[window.location.pathname],
  }
}

export default connect(mapStateToProps)(EndpointIndex)
