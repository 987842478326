import request from '../helpers/requestManagementAPI'

export const startFetchingStatistics = () => ({
  type: 'START_FETCHING_STATISTICS',
})

export const receiveMonthlyStatistics = result => ({
  type: 'RECEIVE_MONTHLY_STATISTICS',
  result,
})

export const receiveWeeklyStatistics = result => ({
  type: 'RECEIVE_WEEKLY_STATISTICS',
  result,
})

export const receiveDailyStatistics = result => ({
  type: 'RECEIVE_DAILY_STATISTICS',
  result,
})

export const receiveScenarioUsage = result => ({
  type: 'RECEIVE_SCENARIO_USAGE',
  result,
})

export const receiveFaqUsage = result => ({
  type: 'RECEIVE_FAQ_USAGE',
  result,
})

export const fetchMonthlyActivities = (token, application_id, start, end) => dispatch => {
  const url = application_id ? `/applications/${application_id}/statistics` : '/account/statistics'
  dispatch(startFetchingStatistics())
  return request
    .get(url, {
      token,
      body: { start: start, end: end, period: 'month' },
    })
    .then(result => {
      dispatch(receiveMonthlyStatistics(result))
    })
}

export const fetchWeeklyActivities = (token, application_id, start, end) => dispatch => {
  const url = application_id ? `/applications/${application_id}/statistics` : '/account/statistics'
  dispatch(startFetchingStatistics())
  return request
    .get(url, {
      token,
      body: { start: start, end: end, period: 'week' },
    })
    .then(result => {
      dispatch(receiveWeeklyStatistics(result))
    })
}

export const fetchDailyActivities = (token, application_id, start, end) => dispatch => {
  const url = application_id ? `/applications/${application_id}/statistics` : '/account/statistics'
  dispatch(startFetchingStatistics())
  return request
    .get(url, {
      token,
      body: { start: start, end: end, period: 'day' },
    })
    .then(result => {
      dispatch(receiveDailyStatistics(result))
    })
}

export const fetchScenarioUsage = (token, application_id, start, end) => dispatch => {
  dispatch(startFetchingStatistics())
  return request
    .get(`/applications/${application_id}/usage/topic`, {
      token,
      body: { start: start, end: end },
    })
    .then(result => {
      dispatch(receiveScenarioUsage(result))
    })
}

export const downloadScenarioUsage = (token, application_id, start, end) => dispatch => {
  return request.get(`/applications/${application_id}/usage/topic/download`, {
    token,
    body: { start: start, end: end },
    dispatch,
  })
}

export const fetchFaqUsage = (token, application_id, start, end) => dispatch => {
  dispatch(startFetchingStatistics())
  return request
    .get(`/applications/${application_id}/usage/faq`, {
      token,
      body: { start: start, end: end },
    })
    .then(result => {
      dispatch(receiveFaqUsage(result))
    })
}

export const downloadFaqUsage = (token, application_id, start, end, faq_id = null) => dispatch => {
  const body = { start: start, end: end }
  if (faq_id) {
    body['faq_id'] = faq_id
  }

  return request.get(`/applications/${application_id}/usage/faq/download`, {
    token,
    body,
    dispatch,
  })
}
