import PropTypes from 'prop-types'
import React from 'react'
import { Field, FieldArray } from 'redux-form'
import { InputField, SelectField } from '../../components/common/fields/FormFields'
import IncrementableTable from '../../components/common/IncrementableTable'
import LabelWithTooltip from '../common/LabelWithTooltip'
import Tooltip from '../../components/common/Tooltip'

import AbstractAction from './AbstractAction'

export class RegisterTaskAction extends AbstractAction {
  static propTypes = {
    ...AbstractAction.propTypes,
    tasks: PropTypes.array.isRequired,
  }

  getTitle() {
    return this.context.t('topic.actionList.register_task.title')
  }
  getType() {
    return 'normal'
  }

  static validate = action => {
    const errors = super.validate(action)
    if (!action.target_task_id) {
      errors.target_task_id = 'validate.required'
    }
    errors.variables = action.variables.map(variable => {
      if (!variable.name && variable.value) {
        return { name: 'validate.required' }
      }
      return {}
    })
    return errors
  }

  renderVariables = ({ fields }) => {
    const { t } = this.context
    const columns = [
      {
        Header: (
          <th key="name">
            {t('topic.actionList.register_task.variables.name')}
            <Tooltip name="topic.tooltip.actionList.register_task.variables.name" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            type="text"
            name={`${name}.name`}
            className="form-control dm-form-control"
            maxLength="30"
            component={InputField}
          />
        ),
      },
      {
        Header: (
          <th key="value">
            {t('topic.actionList.register_task.variables.value')}
            <Tooltip name="topic.tooltip.actionList.register_task.variables.value" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            type="text"
            name={`${name}.value`}
            className="form-control dm-form-control"
            component={InputField}
          />
        ),
      },
    ]
    return <IncrementableTable fields={fields} columns={columns} />
  }

  renderBody() {
    const { name, tasks } = this.props

    return (
      <div className="register_task">
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor="target_task_id"
            className="control-label col-lg-3 col-md-3 col-sm-12"
            name="topic.actionList.register_task.targetTask"
          />
          <div className="col-lg-9 col-md-9 col-sm-12">
            <Field
              name={`${name}.target_task_id`}
              className="form-control"
              component={SelectField}
              items={tasks}
              valueKey="id"
              displayKey="name"
              empty={true}
              parse={value => value && parseInt(value, 10)}
              order="asc"
            />
          </div>
        </div>
        <div className="row">
          <LabelWithTooltip
            htmlFor="variables"
            className="control-label col-lg-12 col-md-12 col-sm-12"
            name="topic.actionList.register_task.variables.title"
          />
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <FieldArray name={`${name}.variables`} component={this.renderVariables} />
          </div>
        </div>
      </div>
    )
  }
}

export default RegisterTaskAction
