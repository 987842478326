const initialState = {
  open: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'START_TOUR':
      return { open: true }
    case 'END_TOUR':
      return { open: false }
    default:
      return state
  }
}

export default reducer
