const initialState = {
  results: undefined,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_CLASSIFY':
      return initialState
    case 'REQUEST_CLASSIFY':
      return {
        ...state,
        isFetching: true,
      }
    case 'RESPONSE_CLASSIFY':
      return {
        ...state,
        isFetching: false,
        results: action.results,
      }
    default:
      return state
  }
}

export default reducer
