import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { Field, reduxForm, propTypes } from 'redux-form'
import { isEmail } from 'validator'

import { Redirect } from '../helpers/requestManagementAPI'
import { clearState } from '../actions/api'
import { fetchRooms } from '../actions/chat'
import { createSession, requestLogin } from '../actions/session'
import { addNotice } from '../actions/notice'
import { InputField } from '../components/common/fields/FormFields'
import Loader from '../components/common/Loader'
import LabelWithTooltip from '../components/common/LabelWithTooltip'

const validate = data => {
  const errors = {}
  if (!data.email) {
    errors.email = 'validate.required'
  } else if (!isEmail(data.email)) {
    errors.email = 'validate.invalidEmail'
  }
  if (!data.password) {
    errors.password = 'validate.required'
  }

  return errors
}

export class Login extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
  }

  componentWillMount() {
    const state = this.context.store.getState()
    const router = this.context.router
    if (state.session.token) {
      router.replace('/')
    }
  }

  handleLogin = (data, dispatch) => {
    const router = this.context.router
    return dispatch(requestLogin(data.email, data.password, router))
      .then(() => router.push('/'))
      .then(() => {
        // Get the number of rooms which calling operator
        dispatch(fetchRooms(this.context.store.getState().session, { operator_state: 'calling' }))
      })
      .catch(e => {
        if (e.constructor === Redirect) {
          dispatch(clearState())
          dispatch(createSession(e.accessToken))
          router.replace({ pathname: e.location, state: e.payload })
        } else {
          dispatch(addNotice('error', e.message))
        }
      })
  }

  render() {
    const { t } = this.context
    const { submitting, handleSubmit } = this.props
    return (
      <div>
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-6">
            <div className="dm-login">
              <Loader type="show" loaded={!submitting}>
                <form className="text-left" onSubmit={handleSubmit(this.handleLogin)}>
                  <div className="form-group">
                    <LabelWithTooltip htmlFor="email" className="dm-title-mini" name="login.email" />
                    <Field
                      className="form-control dm-form-control"
                      name="email"
                      type="email"
                      component={InputField}
                      placeholder="user@example.com"
                    />
                  </div>
                  <div className="form-group">
                    <LabelWithTooltip htmlFor="password" className="dm-title-mini" name="login.password" />
                    <Field
                      className="form-control dm-form-control"
                      name="password"
                      type="password"
                      component={InputField}
                      placeholder="Your password"
                    />
                  </div>
                  <div className="form-group text-right">
                    <button type="submit" className="btn btn-primary btn-block dm-btn" disabled={submitting}>
                      {t('common.login')}
                    </button>
                  </div>
                  <div className="form-group text-center">
                    <Link to="/forgot_password" className="dm-link">
                      {t('login.forgotPassword')}
                    </Link>
                  </div>
                  <div className="form-group text-center">
                    <Link to="/signup" className="dm-link">
                      {t('login.newAccount')}
                    </Link>
                  </div>
                </form>
              </Loader>
            </div>
          </div>
          <div className="col-md-4" />
        </div>
      </div>
    )
  }
}

const LoginForm = reduxForm({
  form: 'Login',
  validate,
})(Login)

export default connect()(LoginForm)
