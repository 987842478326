import request from '../helpers/requestManagementAPI'

export const createOAuthSession = (token, id, oauth_request) => () => {
  return request.post(`/oauth_clients/${id}/sessions`, {
    token,
    body: oauth_request,
  })
}

export const deleteOAuthSession = (token, id) => () => {
  return request.delete(`/oauth_clients/${id}/sessions`, {
    token,
  })
}
