import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Loader from '../../components/common/Loader'
import { confirmEmailAddress } from '../../actions/email_address'
import { isFetching } from '../../helpers/selector'

class EmailAddressConfirm extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      confirmation_token: PropTypes.string.isRequired,
    }),
    isFetching: PropTypes.bool,
  }

  componentWillMount() {
    const {
      dispatch,
      params: { confirmation_token },
    } = this.props
    return dispatch(confirmEmailAddress(confirmation_token))
  }

  render() {
    const { t } = this.context
    const { isFetching } = this.props

    return (
      <Loader loaded={!isFetching} type="hidden">
        <div>{t('account.emailAddresses.completeConfirmationMessage')}</div>
      </Loader>
    )
  }
}

export const mapStateToProps = (state, _props) => {
  return {
    isFetching: isFetching(state),
  }
}

export default connect(mapStateToProps)(EmailAddressConfirm)
