import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Collapse from 'react-collapse'
import Tabs, { TabPane } from 'rc-tabs'
import TabContent from 'rc-tabs/lib/TabContent'
import SwipeableInkTabBar from 'rc-tabs/lib/SwipeableInkTabBar'

import Tooltip from '../common/Tooltip'
import { FaqPatternTable } from './FaqPatternTable'
import { FaqQuestionTable } from './FaqQuestionTable'

export class FaqMatchingTab extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
    store: PropTypes.object,
  }

  static propTypes = {
    questions: PropTypes.object,
    patterns: PropTypes.object,
    defaultOpened: PropTypes.bool,
  }

  static defaultProps = {
    defaultOpened: true,
  }

  constructor() {
    super()
    this.state = { isOpened: false, activeKey: 'questions' }
  }

  componentDidMount() {
    this.setState({ isOpened: this.props.defaultOpened })
  }

  toggleFaqMatching = () => {
    this.setState({ isOpened: !this.state.isOpened })
  }

  render() {
    const { t } = this.context
    const { isOpened, activeKey } = this.state
    const { questions, patterns } = this.props

    const currentTabError = activeKey === 'questions' ? !!questions.meta.error : !!patterns.meta.error

    return (
      <div className={isOpened ? 'is-open' : ''}>
        <span onClick={() => this.toggleFaqMatching(currentTabError)}>
          <label className="dm-title toggleFaqMatching">{t('faqItem.faqMatching')}</label>
          <Tooltip name="faqItem.tooltip.faqMatching" />
          <span type="button" className="btn btn-link glyphicon glyphicon-collapse-down" />
        </span>
        <Collapse className="faqMatching" isOpened={isOpened}>
          <Tabs
            className="dm-tabs"
            destroyInactiveTabPane
            activeKey={activeKey}
            onChange={key => {
              if (!currentTabError) {
                this.setState({ activeKey: key })
              }
            }}
            renderTabBar={() => <SwipeableInkTabBar pageSize={2} speed={10} />}
            renderTabContent={() => <TabContent animatedWithMargin />}
          >
            <TabPane
              tab={`${t('faqItem.questions.title')} ${lodash.compact(questions.input.value).length}`}
              key="questions"
            >
              <div className="instruction">{t('faqItem.questions.instruction')}</div>
              <FaqQuestionTable />
            </TabPane>
            <TabPane
              tab={`${t('faqItem.patterns.title')} ${lodash.compact(patterns.input.value).length}`}
              key="patterns"
            >
              <div className="instruction">
                {t('faqItem.patterns.instruction1')}
                <br />
                {t('faqItem.patterns.instruction2')}
              </div>
              <FaqPatternTable />
            </TabPane>
          </Tabs>
        </Collapse>
        {lodash.isString(questions.meta.error) && <div className="error">{t(questions.meta.error)}</div>}
        {lodash.isString(patterns.meta.error) && <div className="error">{t(patterns.meta.error)}</div>}
      </div>
    )
  }
}

export default FaqMatchingTab
