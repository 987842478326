import lodash from 'lodash'

const initialState = {}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_SESSION':
      return {
        ...state,
        token: action.token,
      }
    case 'UPDATE_SESSION':
      return {
        ...state,
        ...action.session,
      }
    case 'DELETE_SESSION':
      return {}
    case 'COMPLETE_SESSION_LOADING':
      return {
        ...state,
        loaded: true,
      }
    case 'CHANGE_APPLICATION':
      return {
        ...state,
        application_id: action.id,
      }
    case 'UPDATE_TOPICS_STYLE':
      return {
        ...state,
        topicsStyle: action.style,
      }
    case 'SET_STRICT_PASSWORD_POLICY':
      return {
        ...state,
        useStrictPasswordPolicy: action.useStrictPasswordPolicy,
      }
    case 'RESPONSE_API': {
      if (action.target !== 'accounts') return state

      const account = lodash.first(Object.values(action.response.entities.accounts))
      return {
        ...state,
        account: {
          is_active: account.is_active,
          is_canceled: account.is_canceled,
          is_invalidated: account.is_invalidated,
          is_experimental: account.is_experimental,
          plan: account.plan,
          option_plans: account.option_plans,
          allow_fulltext_search: account.allow_fulltext_search,
          timezone: account.timezone,
        },
      }
    }
    default:
      return state
  }
}

export default reducer
