import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes } from 'redux-form'
import { decode } from 'jwt-simple'

import { resetPassword } from '../../actions/user'
import { addNotice } from '../../actions/notice'
import { InputField } from '../../components/common/fields/FormFields'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import PasswordPolicy from '../../components/user/PasswordPolicy'

const validate = (data, props) => {
  const errors = {}
  if (props.useStrictPasswordPolicy) {
    const availableSymbols = '!"#$%&\'()*+,-./:;<=>?@[\\\\\\]^_`{|}~'
    const pattern = new RegExp(
      `^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\\d)(?=.*?[${availableSymbols}])[\\x20-\\x7E]{10,255}$`
    )
    if (!pattern.test(data.password)) {
      if (!data.password || data.password.length < 10) {
        errors.password = { id: 'validate.insufficientMinLength', values: { length: 10 } }
      } else if (!/^[\x20-\x7E]*$/i.test(data.password)) {
        errors.password = 'validate.invalidPassword'
      } else {
        errors.password = 'validate.violatedStrictPasswordPolicy'
      }
    }
  } else {
    if (!/^(?=.*?[a-z])(?=.*?\d)[\x20-\x7E]{8,255}$/i.test(data.password)) {
      if (!data.password || data.password.length < 8) {
        errors.password = { id: 'validate.insufficientMinLength', values: { length: 8 } }
      } else if (!/^[\x20-\x7E]*$/i.test(data.password)) {
        errors.password = 'validate.invalidPassword'
      } else {
        errors.password = 'validate.violatedStandardPasswordPolicy'
      }
    }
  }

  if (data.password !== data.password_confirmation) {
    errors.password_confirmation = 'validate.mismatchedPassword'
  }
  return errors
}

export class ResetPassword extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      reset_token: PropTypes.string.isRequired,
    }),
    useStrictPasswordPolicy: PropTypes.bool.isRequired,
  }

  componentWillMount() {
    const state = this.context.store.getState()
    const router = this.context.router

    if (state.session.token) {
      router.replace('/')
    }
  }

  componentDidMount() {
    const { t, router } = this.context
    const dispatch = this.props.dispatch
    const payload = decode(this.props.params.reset_token, '', true)
    if (new Date() > payload.exp * 1000) {
      Promise.resolve()
        .then(() => router.replace('/login'))
        .then(() => dispatch(addNotice('error', t('passwordReset.expiredTokenMessage'))))
    }
  }

  handleReset = (data, dispatch) => {
    const { t, router } = this.context
    return dispatch(resetPassword(this.props.params.reset_token, data.password))
      .then(() => router.replace('/login'))
      .then(() => dispatch(addNotice('info', t('passwordReset.resetPasswordMessage'))))
  }

  render() {
    const { t } = this.context
    const { handleSubmit, isSubmitting } = this.props

    return (
      <div className="dm-login is-reset">
        <div className="row description">
          {t('passwordReset.update.description1')}
          <br />
          {t('passwordReset.update.description2')}
        </div>
        <div className="row">
          <div className="col-sm-2 col-md-2" />
          <form className="text-left col-sm-6 col-md-6" onSubmit={handleSubmit(this.handleReset)}>
            <div className="form-group">
              <LabelWithTooltip
                htmlFor="password"
                className="dm-title-mini"
                name="passwordReset.newPassword"
              />
              <Field
                type="password"
                name="password"
                className="form-control dm-form-control"
                component={InputField}
                autoComplete="new-password"
              />
            </div>
            <div className="form-group">
              <LabelWithTooltip
                htmlFor="password_confirmation"
                className="dm-title-mini"
                name="passwordReset.newPasswordConfirmation"
              />
              <Field
                type="password"
                name="password_confirmation"
                className="form-control dm-form-control"
                component={InputField}
                autoComplete="new-password"
              />
            </div>
            {this.props.useStrictPasswordPolicy && <PasswordPolicy />}
            <div className="form-group text-right">
              <button type="submit" disabled={isSubmitting} className="btn btn-primary dm-btn">
                {t('passwordReset.reset')}
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const ResetPasswordForm = reduxForm({
  form: 'ResetPassword',
  validate,
})(ResetPassword)

export const mapStateToProps = (_state, props) => {
  const useStrictPasswordPolicy = decode(props.params.reset_token, '', true).sub.use_strict_password_policy
  return {
    useStrictPasswordPolicy,
  }
}

export default connect(mapStateToProps)(ResetPasswordForm)
