import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form'
import { CheckboxField } from '../../components/common/fields/FormFields'
import LabelWithTooltip from '../common/LabelWithTooltip'

import AbstractAction from './AbstractAction'
import TextField from '../common/fields/TextField'

export class ExitTopicAction extends AbstractAction {
  static propTypes = {
    enableFeedback: PropTypes.bool,
  }

  getTitle() {
    return this.context.t('topic.actionList.exit_topic.title')
  }
  getType() {
    return 'stop'
  }

  renderBody() {
    const { name, enableFeedback } = this.props

    return (
      <div className="exit_topic">
        <div className="row">
          <LabelWithTooltip
            htmlFor="message"
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.exit_topic.message"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <TextField name={`${name}.content`} className="form-control" nullable={true} />
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-lg-2 col-md-2 col-sm-12" />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <div className="form-group form-inline">
              <Field
                type="checkbox"
                name={`${name}.is_all_terminate`}
                id={`${name}.is_all_terminate`}
                component={CheckboxField}
                className="form-control"
              />
              <LabelWithTooltip
                htmlFor={`${name}.is_all_terminate`}
                className="control-label"
                name="topic.actionList.exit_topic.terminate"
              />
            </div>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-lg-2 col-md-2 col-sm-12" />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <div className="form-group form-inline">
              <Field
                type="checkbox"
                name={`${name}.enable_feedback`}
                id={`${name}.enable_feedback`}
                component={CheckboxField}
                disabled={!enableFeedback}
                className="form-control"
              />
              <LabelWithTooltip
                htmlFor={`${name}.enable_feedback`}
                className="control-label"
                name="topic.actionList.exit_topic.enableFeedback"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ExitTopicAction
