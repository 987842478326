import lodash from 'lodash'
import moment from 'moment'
import uuid from 'uuid'

const extractMessages = error => {
  if (typeof error.message === 'string') {
    return [error.message]
  }

  if (Array.isArray(error.message)) {
    return lodash.uniq(error.mesage)
  }

  if (typeof error.message === 'object') {
    return lodash.uniq(Object.values(error.message))
  }

  return []
}

const isDuplicateNotice = (notices, action) => {
  return lodash.find(notices, notice => {
    return (
      notice.options.source === action.options.source &&
      notice.level === action.level &&
      notice.message === action.message
    )
  })
}

export default (state = [], action) => {
  if (action.type === 'ERROR_API') {
    const messages = extractMessages(action.error)
    let notices = lodash.compact(messages).map(message => ({
      id: uuid.v4(),
      level: 'error',
      message,
      options: {
        source: action.error.source || 'dashboard',
        code: (action.error.response || {}).status,
        removable: true,
      },
      timestamp: moment.utc(),
    }))
    notices = lodash.reject(notices, notice => isDuplicateNotice(state, notice))

    return [...state, ...notices]
  }

  if (action.type === 'ADD_NOTICE') {
    if (isDuplicateNotice(state, action)) {
      return state
    }
    return [
      ...state,
      {
        id: action.options.uuid || uuid.v4(),
        level: action.level,
        message: action.message,
        options: lodash.omit(action.options, 'uuid'),
        timestamp: moment.utc(),
      },
    ]
  }

  if (action.type === 'UPDATE_NOTICE') {
    const nextState = lodash.cloneDeep(state)
    const notice = lodash.find(nextState, { id: action.uuid })
    if (notice) {
      notice.level = action.level
      notice.message = action.message
      notice.options = { ...action.options }
      notice.timestamp = moment.utc()
      return nextState
    } else {
      if (isDuplicateNotice(state, action)) {
        return state
      }
      return [
        ...state,
        {
          id: action.uuid || uuid.v4(),
          level: action.level,
          message: action.message,
          options: { ...action.options },
          timestamp: moment.utc(),
        },
      ]
    }
  }

  if (action.type === 'REMOVE_NOTICE') {
    return state.filter(notice => notice.id !== action.id)
  }

  if (action.type === 'REMOVE_NOTICES') {
    return lodash.reject(state, action.filter)
  }

  return state
}
