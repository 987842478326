import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import MessageIcon from './MessageIcon'

import AbstractMessage from './AbstractMessage'
import { isCarouselResponse, convertCarouselResponse } from '../../helpers/messageHelper'
import { convertLineBreak } from '../../helpers/lineBreakConverter'

class TextMessage extends AbstractMessage {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  render() {
    const {
      uuid,
      mode,
      sender_type,
      content,
      timestamp,
      iconUrl,
      fallbackIconUrl,
      isLoaded,
      isSimulator,
    } = this.props
    if (!content.text) {
      return null
    }
    let textNode
    if (sender_type !== 'client') {
      const options = {
        enableMarkdown: true,
        enableImagePreview: true,
        enableAbbreviation: true,
        enableSwitchChatBot: mode === 'client' && !isSimulator,
      }
      textNode = <p className="text">{this.parse(content.text, options)}</p>
    } else if (mode === 'server' && isCarouselResponse(content)) {
      textNode = <p className="text">{convertCarouselResponse(content)}</p>
    } else {
      textNode = <p className="text">{convertLineBreak(content.text, uuid)}</p>
    }
    return (
      <li className={classnames(this.getMessageClasses())}>
        <div className="body">
          {sender_type !== 'client' && (
            <MessageIcon
              sender_type={sender_type}
              url={iconUrl}
              fallbackUrl={fallbackIconUrl}
              isLoaded={isLoaded}
            />
          )}
          <div className="balloon">
            <div className="content">
              {textNode}
              {this.renderDefaultFooterButtons('content')}
            </div>
            {this.renderTimestamp(timestamp)}
          </div>
        </div>
      </li>
    )
  }
}

export default TextMessage
