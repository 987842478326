import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'

import DataBindingTable from '../../components/common/DataBindingTable'
import { fetchOAuthClients } from '../../actions/oauth_client'
import { updateTableState } from '../../actions/table'
import { isFetching } from '../../helpers/selector'

class OAuthClientIndex extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    oauthClients: PropTypes.array.isRequired,
    isFetching: PropTypes.bool,
    tableState: PropTypes.object,
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    dispatch(fetchOAuthClients(state.session.token))
  }

  handleRefreshClick = () => {
    const state = this.context.store.getState()
    this.props.dispatch(fetchOAuthClients(state.session.token))
  }

  handleAddClick = () => {
    this.context.router.push('/oauth_clients/new')
  }

  updateTableState = (path, tableName, tableState) => {
    const { dispatch } = this.props
    dispatch(updateTableState(path, tableName, tableState))
  }

  render() {
    const { t } = this.context
    const { oauthClients, isFetching, tableState } = this.props

    let columns = [
      {
        Header: t('oauthClients.name'),
        id: 'name',
        accessor: 'name',
        Cell: props => <Link to={`/oauth_clients/${props.original.id}`}>{props.value}</Link>,
        getFooterProps: () => ({ className: 'footer-all' }),
        Footer: (
          <Link className="dm-btn btn btn-primary btn-icon-plus mini" onClick={this.handleAddClick}>
            <span>{t('common.new')}</span>
          </Link>
        ),
      },
      {
        Header: t('oauthClients.type'),
        id: 'type',
        Cell: props => t(`oauthClients.providerTypes.${props.value}`),
        accessor: 'type',
      },
    ]
    return (
      <div>
        <DataBindingTable
          title={t('oauthClients.title')}
          titleTooltip="oauthClients.tooltip.title"
          items={oauthClients}
          columns={columns}
          isFetching={isFetching}
          tableState={tableState}
          updateTableState={this.updateTableState}
          onRefresh={this.handleRefreshClick}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const ids = state.pagination.oauth_clients.ids || []
  const oauthClients = ids.map(id => state.entities.oauth_clients[id])
  return {
    oauthClients: oauthClients,
    isFetching: isFetching(state),
    tableState: state.table[window.location.pathname],
  }
}

export default connect(mapStateToProps)(OAuthClientIndex)
