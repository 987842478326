import lodash from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { formValueSelector, reduxForm, propTypes, Field, FieldArray } from 'redux-form'

// components
import { InputField } from '../../components/common/fields/FormFields'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import ConditionTable from '../../components/common/ConditionTable'

import { isDirtyForm } from '../../helpers/cancelurl'
import { filterSystemVariables, replaceSystemVariableWhenOnChange } from '../../helpers/replaceSystemVariable'

import {
  fetchConditionGroup,
  createConditionGroup,
  updateConditionGroup,
  deleteConditionGroup,
} from '../../actions/condition_group'
import { addNotice } from '../../actions/notice'

const validate = data => {
  const errors = {}
  if (!data.name) {
    errors.name = 'validate.required'
  } else if (data.name.length > 255) {
    errors.name = { id: 'validate.exceededMaxLength', values: { length: 255 } }
  }

  if (data.conditions && data.conditions.length > 0) {
    errors.conditions = data.conditions.map(d => {
      const m = {}
      if (d.variable || d.value || d.operator) {
        if (d.variable && d.variable.length > 30) {
          m.variable = { id: 'validate.exceededMaxLength', values: { length: 30 } }
        }
        if (!d.operator) {
          m.operator = 'validate.required'
        } else if (d.operator === 'between') {
          if (d.value) {
            const m_value = {}
            if (d.value.from && d.value.from.length > 80) {
              m_value.from = { id: 'validate.exceededMaxLength', values: { length: 80 } }
            }
            if (d.value.to && d.value.to.length > 80) {
              m_value.to = { id: 'validate.exceededMaxLength', values: { length: 80 } }
            }

            if (!lodash.isEmpty(m_value)) m.value = m_value
          }
        } else {
          if (d.value && d.value.length > 200) {
            m.value = { id: 'validate.exceededMaxLength', values: { length: 200 } }
          }
        }
      }
      return m
    })
  } else {
    errors.conditions = [
      {
        variable: 'validate.required',
        value: 'validate.required',
      },
    ]
  }
  return errors
}

export const convertSystemVariablesOfConditionGroup = (condition_group, isSave) => {
  if (condition_group.conditions) {
    condition_group.conditions.forEach((conditions, index) => {
      replaceSystemVariableWhenOnChange(condition_group.conditions[index], 'variable', isSave, false)
      if (conditions.operator === 'between') {
        replaceSystemVariableWhenOnChange(condition_group.conditions[index], 'value["from"]', isSave, true)
        replaceSystemVariableWhenOnChange(condition_group.conditions[index], 'value["to"]', isSave, true)
      } else {
        replaceSystemVariableWhenOnChange(condition_group.conditions[index], 'value', isSave, true)
      }
    })
  }
}

export class ConditionGroupEdit extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    ...propTypes,
    container_type: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    bot: PropTypes.object.isRequired,
    variables: PropTypes.array.isRequired,
    topic_id: PropTypes.string,
    task_id: PropTypes.string,
    id: PropTypes.number,
    use_or_operator: PropTypes.string,
  }

  constructor() {
    super()
    this.state = { errorMessage: '' }
  }

  componentDidMount() {
    const { id } = this.props

    const state = this.context.store.getState()
    const { dispatch } = this.props
    if (id) {
      dispatch(fetchConditionGroup(state.session.token, id, true))
    }
  }

  componentWillUpdate(nextProps) {
    const { conditions } = nextProps
    const { variables } = this.props

    //  Remove condition which regard of deleted variable
    conditions.forEach((item, index) => {
      if (!item.variable) {
        delete conditions[index]
        return
      }

      if (item.operator) {
        if (item.operator !== 'between' && typeof item.value !== 'string') {
          this.props.change(`conditions[${index}].value`, '')
        }
      }

      const variable = lodash.find(variables, { variable: item.variable }) || {}

      if (!item.value) return
      if (!variable.candidates) return

      if (!lodash.some(variable.candidates, { value: item.value })) {
        if (item.operator === 'between') {
          this.props.change(`conditions[${index}].value`, { from: '', to: '' })
        } else {
          this.props.change(`conditions[${index}].value`, '')
        }
      }
    })
  }

  sanitizeItems(conditions) {
    const { t } = this.context
    const { bot, variables, container_type } = this.props
    const userVariables = lodash.map(bot.memory_definitions, 'name')
    const chatbotConstants = lodash.map(bot.constant_definitions, 'name')

    const adaptableVariables = variables
      .map(variable => variable.variable)
      .concat(
        filterSystemVariables().map(variable => t('systemVariables.' + variable)),
        userVariables,
        chatbotConstants
      )
    const clonedItems = lodash.cloneDeep(conditions)

    return lodash.compact(
      clonedItems.map(item => {
        if (container_type === 'topic') {
          const isInvalidVariable = !!(
            item.variable &&
            adaptableVariables.filter(
              variable =>
                item.variable === variable || item.variable.match(new RegExp('^' + variable + '\\.'))
            ).length === 0
          )
          if (isInvalidVariable) {
            lodash.assign(item, { variable: '', value: '' })
          }
          //  Clear invalid condition
          const candidates = (lodash.find(variables, { variable: item.variable }) || {}).candidates
          if (candidates && !lodash.some(candidates, { value: item.value })) {
            lodash.assign(item, { variable: '', value: '' })
          }

          //  Unify unspecified condition value to empty string
          if (item.value == null) item.value = ''

          if (item.variable === '') return null

          if (item.variable !== '' && item.operator === undefined) return null
        }

        return item
      })
    )
  }

  validateVariable() {
    const { t } = this.context
    const { variables, conditions, bot } = this.props
    const userVariables = lodash.map(bot.memory_definitions, 'name')
    const chatbotConstants = lodash.map(bot.constant_definitions, 'name')
    const adaptableVariables = variables
      .map(variable => variable.variable)
      .concat(
        filterSystemVariables().map(variable => t('systemVariables.' + variable)),
        userVariables,
        chatbotConstants
      )

    let flg = false
    if (!conditions) return flg

    conditions.forEach(item => {
      flg = !!(
        item.variable &&
        adaptableVariables.filter(
          variable => item.variable === variable || item.variable.match(new RegExp('^' + variable + '\\.'))
        ).length === 0
      )
      if (flg) return flg
    })
    return flg
  }

  handleSave = (data, dispatch) => {
    const { t } = this.context
    const { id, container_type } = this.props
    const state = this.context.store.getState()
    const items = this.sanitizeItems(data.conditions)
    if (items.length === 0) {
      this.setState({ errorMessage: t('condition_group.emptyItems') })
      return
    }
    const condition_group = {
      name: data.name,
      conditions: items,
    }
    condition_group.use_or_operator = data.use_or_operator === 'true' ? true : false
    if (container_type === 'topic') {
      condition_group.topic_id = this.props.topic_id
    } else if (container_type === 'task') {
      condition_group.task_id = this.props.task_id
    }
    convertSystemVariablesOfConditionGroup(condition_group, true)

    if (id) {
      condition_group.id = id
      dispatch(updateConditionGroup(state.session.token, condition_group, true))
        .then(() => this.props.onClose())
        .catch(e => {
          this.setState({ errorMessage: e.message })
        })
    } else {
      dispatch(createConditionGroup(state.session.token, condition_group, true))
        .then(() => this.props.onClose())
        .catch(e => {
          this.setState({ errorMessage: e.message })
        })
    }
  }

  handleDelete = () => {
    const state = this.context.store.getState()
    const { t } = this.context
    const { dispatch, id } = this.props

    const condition_group = this.props.initialValues
    if (
      !window.confirm(
        t('common.deleteConfirmMessage', { type: t('condition_group.name'), name: condition_group.name })
      )
    )
      return

    dispatch(deleteConditionGroup(state.session.token, id, true))
      .then(() => this.props.onClose())
      .then(() => dispatch(addNotice('info', t('common.deleteSuccessMessage'))))
      .catch(e => {
        this.setState({ errorMessage: e.message })
      })
  }

  handleClose = () => {
    const { t } = this.context
    if (isDirtyForm(this)) {
      if (!window.confirm(t('common.confirmMessage'))) return
    }
    this.props.onClose()
  }

  onChangeConditionType = e => {
    const fieldName = e.target.name.slice(0, e.target.name.indexOf('.'))
    if (e.target.value === 'between') {
      this.props.change(`${fieldName}.value`, { from: '', to: '' })
    }
  }

  renderErrorMessage = () => {
    if (this.state.errorMessage === '') return

    return <div className="dm-error-message pb-4">{this.state.errorMessage}</div>
  }

  renderConditionItems = ({ fields }) => {
    const { bot, variables, container_type } = this.props
    const enableValidation = container_type === 'topic'

    return (
      <ConditionTable
        bot={bot}
        fields={fields}
        maxRows={300}
        variables={variables}
        onChangeConditionType={this.onChangeConditionType}
        enableValidation={enableValidation}
      />
    )
  }

  render() {
    const { t } = this.context
    const { handleSubmit, conditions, id, container_type } = this.props

    return (
      <div className="modal-background">
        <div className="modal-dialog condition-dialog" role="document" onClick={e => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title">{t('condition_group.title')}</h4>
            </div>
            <form className="text-left" onSubmit={handleSubmit(this.handleSave)}>
              <div className="modal-body">
                <div className="results">
                  {this.renderErrorMessage()}
                  <div className="form-group dm-form-group" key="name">
                    <LabelWithTooltip htmlFor="name" name="condition_group.name" />
                    <Field name="name" className="form-control dm-form-control" component={InputField} />
                  </div>
                  <div className="form-group dm-form-group" key="use_or_operator">
                    <LabelWithTooltip htmlFor="use_or_operator" name="condition_group.logic" />
                    <br />
                    <div className="radio-inline">
                      <Field
                        name="use_or_operator"
                        type="radio"
                        value="false"
                        component="input"
                        className="form-control"
                        id="and"
                      />
                      <label htmlFor="and">{t('condition_group.compositeItems.and')}</label>
                    </div>
                    <div className="radio-inline">
                      <Field
                        name="use_or_operator"
                        type="radio"
                        value="true"
                        component="input"
                        className="form-control"
                        id="or"
                      />
                      <label htmlFor="or">{t('condition_group.compositeItems.or')}</label>
                    </div>
                  </div>
                  <div className="form-group dm-form-group" key="conditions">
                    <FieldArray
                      name="conditions"
                      response_map={conditions}
                      component={this.renderConditionItems}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary dm-btn"
                  disabled={this.validateVariable() && container_type === 'topic'}
                >
                  {t('common.save')}
                </button>
                {id && (
                  <button type="button" className="btn btn-danger dm-btn" onClick={this.handleDelete}>
                    {t('common.delete')}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const ConditionGroupEditForm = reduxForm({
  form: 'ConditionEdit',
  enableReinitialize: true,
  validate,
})(ConditionGroupEdit)

const selector = formValueSelector('ConditionEdit')

export const mapStateToProps = (state, props) => {
  const defaultValues = {
    conditions: [],
    use_or_operator: 'false',
  }

  let condition_group = {
    ...defaultValues,
  }
  if (props.id) {
    condition_group = {
      ...condition_group,
      ...state.entities.condition_groups[props.id],
    }
    if (typeof condition_group.use_or_operator === 'boolean') {
      if (condition_group.use_or_operator) {
        condition_group.use_or_operator = 'true'
      } else {
        condition_group.use_or_operator = 'false'
      }
    }
  }

  convertSystemVariablesOfConditionGroup(condition_group, false)

  return {
    initialValues: condition_group,
    conditions: selector(state, 'conditions'),
  }
}

export default connect(mapStateToProps)(ConditionGroupEditForm)
