import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field, FieldArray } from 'redux-form'
import { InputField } from '../../components/common/fields/FormFields'
import IncrementableTable from '../common/IncrementableTable'

export class ExampleTable extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  renderExamples = ({ fields }) => {
    const { t } = this.context
    const columns = [
      {
        Cell: (name, index) => (
          <Field
            name={name}
            component={InputField}
            type="text"
            className="form-control dm-form-control"
            maxLength="280"
            placeholder={index === 0 ? t('topic.examples.placeholder') : ''}
          />
        ),
      },
    ]

    return (
      <div className="exampleTable">
        <IncrementableTable withHeader={false} fields={fields} columns={columns} />
      </div>
    )
  }

  render() {
    return <FieldArray name="examples" component={this.renderExamples} />
  }
}

export default ExampleTable
