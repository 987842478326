import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classnames from 'classnames'

export const Candidate = ({ text, selected, selectCandidate, decideCandidate }) => {
  const components = []

  //  Chunk candidate to multiple parts that are highlighted(<em>) text and normal text by elasticsearch.
  const pattern = new RegExp('<em>(.+?)</em>|(.+?(?=<em>|$))', 'gim')
  let matched
  while ((matched = pattern.exec(text))) {
    if (matched[1]) {
      components.push(
        <span key={components.length} className="highlight">
          {matched[1]}
        </span>
      )
    }
    if (matched[2]) {
      components.push(matched[2])
    }
  }

  return (
    <li
      className={classnames({ candidate: true, selected })}
      onMouseEnter={selectCandidate}
      onClick={() => {
        selectCandidate()
        decideCandidate()
      }}
      title={text.replace(/<\/?em>/g, '')}
    >
      <div className="text">{components}</div>
    </li>
  )
}

Candidate.propTypes = {
  text: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  selectCandidate: PropTypes.func.isRequired,
  decideCandidate: PropTypes.func.isRequired,
}

class Suggestion extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    candidates: PropTypes.array.isRequired,
    selectedIndex: PropTypes.number,
    selectCandidate: PropTypes.func.isRequired,
    decideCandidate: PropTypes.func.isRequired,
  }

  render() {
    const { candidates, selectedIndex, selectCandidate, decideCandidate } = this.props
    if (candidates.length === 0) return null

    const candidateNodes = candidates.map((candidate, index) => {
      const selected = index === selectedIndex
      return (
        <Candidate
          key={index}
          text={candidate}
          selected={selected}
          selectCandidate={() => selectCandidate(index)}
          decideCandidate={decideCandidate}
        />
      )
    })
    return <ul className="suggestion">{candidateNodes}</ul>
  }
}

export default Suggestion
