import lodash from 'lodash'

import { uploadImage } from '../actions/topic'

export const uploadImageFileWithValidation = (dispatch, token, type, image, callback, restrictions = {}) => {
  if (!image) {
    callback(null)
    return
  }

  if (restrictions.allowedFileFormats && !lodash.includes(restrictions.allowedFileFormats, image.type)) {
    callback(null)
    return
  }
  if (restrictions.maxFileSize && image.size > restrictions.maxFileSize) {
    callback(null)
    return
  }

  const fr = new FileReader()
  fr.onload = evt => {
    if (restrictions.allowedFileFormats) {
      const dv = new DataView(evt.target.result, 0, 5)
      const nume1 = dv.getUint8(0, true)
      const nume2 = dv.getUint8(1, true)
      const hex = nume1.toString(16) + nume2.toString(16)

      // Jpeg has 'ffd8' in header or Png has '8950' in header
      if ((image.type === 'image/jpeg' && hex !== 'ffd8') || (image.type === 'image/png' && hex !== '8950')) {
        callback(null)
        return
      }
    }

    // check of image height and width.
    var img = new Image()
    img.onload = () => {
      if (restrictions.maxWidth && img.width > restrictions.maxWidth) {
        callback(null)
        return
      }
      if (restrictions.maxHeight && img.height > restrictions.maxHeight) {
        callback(null)
        return
      }

      const formData = new FormData()
      formData.append('type', type)
      formData.append('upload_file', image)
      dispatch(uploadImage(token, formData))
        .then(uploadedImage => {
          callback(uploadedImage)
        })
        .catch(() => {
          callback(null)
        })
    }
    img.src = window.URL.createObjectURL(image)
  }
  fr.readAsArrayBuffer(image)
}
