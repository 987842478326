import lodash from 'lodash'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { getCredentials } from '../../helpers/sessionHelper'
import { isPermitted } from '../../helpers/permission'

export class AuthorizedComponent extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    routes: PropTypes.array.isRequired,
  }

  componentWillMount() {
    this.componentWillUpdate(this.props)
  }

  componentWillUpdate(nextProps) {
    const { role, is_experimental } = getCredentials(this.context)
    const { routes } = nextProps
    const permissions = this.flattenProp(routes, 'permissions', [])
    const roles = this.flattenProp(routes, 'roles', ['owner', 'writer', 'operator', 'readonly'])
    const isExperimental = !!this.flattenProp(routes, 'isExperimental')[0]

    if (permissions.length > 0) {
      permissions.forEach(permission => {
        if (!isPermitted(permission, this.context)) {
          this.handleUnauthorizedPlan()
          return
        }
      })
    }
    if (roles.length > 0 && lodash.intersection(roles, [role]).length === 0) {
      this.handleUnauthorizedRole()
      return
    }
    if (isExperimental && !is_experimental) {
      this.handleUnauthorizedRole()
      return
    }
  }

  flattenProp(routes, key, allValues) {
    return lodash
      .chain(routes)
      .filter(item => item[key])
      .map(item => item[key])
      .flattenDeep()
      .thru(values => (lodash.includes(values, 'all') ? allValues : values))
      .union()
      .value()
  }
}
