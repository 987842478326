import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import ChatSimulator from '../../containers/chat/ChatSimulator.js'
import Classifier from './Classifier'

export class Simulator extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    tabs: PropTypes.array,
    bot: PropTypes.object,
    domain: PropTypes.object,
    faq: PropTypes.object,
    currentPageItemId: PropTypes.string,
    isCurrentPageFormDirty: PropTypes.bool,
  }

  constructor() {
    super()
    this.state = { currentTab: '' }
  }

  componentDidMount() {
    const { tabs } = this.props
    if (tabs && tabs.length > 0) {
      this.changeCurrentTab(tabs[0])
    }
  }

  changeCurrentTab = currentTab => {
    this.setState({ currentTab })

    if (currentTab === 'chat') {
      setTimeout(() => {
        const chat = document.querySelector('ul.chat')
        if (chat) {
          chat.scrollTop = chat.scrollHeight
        }
      }, 0)
    }
  }

  hasRequiredProps() {
    const { faq, domain } = this.props
    const { currentTab } = this.state
    if (!lodash.includes(['topicClassifier', 'faqClassifier'], currentTab)) {
      return false
    }
    if (currentTab === 'topicClassifier') {
      if (!domain || !domain.hasOwnProperty('bot_id')) {
        return false
      }
    }
    if (currentTab === 'faqClassifier') {
      if (!faq || !faq.hasOwnProperty('id')) {
        return false
      }
    }
    return true
  }

  render() {
    const { t } = this.context
    const { tabs, bot, domain, faq, currentPageItemId, isCurrentPageFormDirty } = this.props
    const { currentTab } = this.state
    const classifierType = { topicClassifier: 'topic', faqClassifier: 'faq' }

    return (
      <div className="dm-sidebar-content-with-selector">
        <ul className="selector clearfix">
          {tabs.map(tab => {
            return (
              <li
                key={tab}
                className={currentTab === tab ? 'selected' : ''}
                onClick={() => this.changeCurrentTab(tab)}
              >
                {t(`simulator.${tab}.title`)}
              </li>
            )
          })}
        </ul>
        <div
          className={`content ${
            lodash.includes(['topicClassifier', 'faqClassifier'], currentTab) ? 'show' : ''
          }`}
        >
          {this.hasRequiredProps() && (
            <Classifier
              type={classifierType[currentTab]}
              domain={domain}
              faq={faq}
              currentPageItemId={currentPageItemId}
              isCurrentPageFormDirty={isCurrentPageFormDirty}
            />
          )}
        </div>
        <div className={`content ${currentTab === 'chat' ? 'show' : ''}`}>
          {bot && bot.hasOwnProperty('application_token') && (
            <ChatSimulator applicationToken={bot.application_token} />
          )}
        </div>
      </div>
    )
  }
}

export default Simulator
