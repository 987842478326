import lodash from 'lodash'

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { HorizontalBar } from 'react-chartjs-2'
import Chart from 'chart.js'

import Loader from '../common/Loader'

export class ScenarioUsageGraph extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    topics: PropTypes.array,
    scenarioUsages: PropTypes.object,
    maxScenario: PropTypes.number,
    height: PropTypes.number,
    isFetching: PropTypes.bool.isRequired,
  }
  static defaultProps = {
    topics: [],
    scenarioUsages: {},
    maxScenario: 10,
    height: 300,
  }

  //  Max width of y-axis label for scenario name
  static maxLabelWidth = 130

  restrictWidth(ctx, label) {
    //  Width of y-axis label's padding for scenario name
    const tickPadding = Chart.defaults.scale.gridLines.tickMarkLength
    const maxWidth = ScenarioUsageGraph.maxLabelWidth - tickPadding

    let width = ctx.measureText(label).width
    if (width <= maxWidth) {
      return label
    }

    //  Ellipse long scenario name with ellipsis mark
    let ommitedLabel = label
    let len = ommitedLabel.length
    while (width > maxWidth && len-- > 0) {
      ommitedLabel = `${ommitedLabel.substring(0, len)}…`
      width = ctx.measureText(ommitedLabel).width
    }
    return ommitedLabel
  }

  getUsages = () => {
    const { t } = this.context
    const { scenarioUsages, topics, maxScenario } = this.props

    const activeScenarioUsages = lodash.compact(
      lodash.map(scenarioUsages.topics, usage => {
        const topic = lodash.find(topics, { id: usage.id })
        if (!topic) return
        return {
          ...usage,
          label: topic.name,
        }
      })
    )

    //  Sort by count of feedbacks
    const countFeedbacks = usage => {
      const counts = lodash.at(usage.feedback, ['yes', 'no', 'no_answer', 'disable'])
      return lodash.sum(counts)
    }
    const usages = lodash.take(lodash.orderBy(activeScenarioUsages, countFeedbacks, ['desc']), maxScenario)

    //  Add classification failed
    const failedUsage = lodash.find(scenarioUsages.topics, { id: null })
    usages.push({
      label: t('analytics.graph.usageGraph.scenarioUsage.graph.classificationFailed'),
      ...{
        feedback: {
          yes: 0,
          no: 0,
          no_answer: 0,
          disable: failedUsage ? failedUsage.error_count : 0,
        },
      },
    })

    return usages
  }

  generateData = canvas => {
    const { t } = this.context
    const usages = this.getUsages()

    const ctx = canvas.getContext('2d')
    ctx.font = "normal 12px 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
    const restrictedLabels = usages.map(usage => this.restrictWidth(ctx, usage.label))

    return {
      labels: restrictedLabels,
      datasets: [
        {
          label: t('analytics.graph.usageGraph.scenarioUsage.graph.yes'),
          data: lodash.map(usages, 'feedback.yes'),
          stack: 'feedback',
          backgroundColor: '#1d5c8b',
          borderColor: '#1d5c8b',
          xAxesID: 'x-axis',
        },
        {
          label: t('analytics.graph.usageGraph.scenarioUsage.graph.no'),
          data: lodash.map(usages, 'feedback.no'),
          stack: 'feedback',
          backgroundColor: '#df6363',
          borderColor: '#df6363',
          xAxesID: 'x-axis',
        },
        {
          label: t('analytics.graph.usageGraph.scenarioUsage.graph.noAnswer'),
          data: lodash.map(usages, 'feedback.no_answer'),
          stack: 'feedback',
          backgroundColor: '#F4FA58',
          borderColor: '#F4FA58',
          xAxesID: 'x-axis',
        },
        {
          label: t('analytics.graph.usageGraph.scenarioUsage.graph.disable'),
          data: lodash.map(usages, 'feedback.disable'),
          stack: 'feedback',
          backgroundColor: '#9e9e9e',
          borderColor: '#9e9e9e',
          xAxesID: 'x-axis',
        },
      ],
    }
  }

  render() {
    const { t } = this.context
    const { height, isFetching } = this.props

    const scaleLabelPadding = 20

    const options = {
      tooltips: {
        mode: 'label',
      },
      scales: {
        xAxes: [
          {
            id: 'x-axis',
            gridLines: {
              display: false,
            },
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: t('analytics.graph.usageGraph.scenarioUsage.graph.callScenarios'),
            },
            ticks: {
              beginAtZero: true,
              callback: value => {
                if (Math.floor(value) === value) {
                  return value
                }
              },
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
            },
            afterFit: function (scaleInstance) {
              // Restrict width that tick labels will be drawn
              scaleInstance.width = ScenarioUsageGraph.maxLabelWidth + scaleLabelPadding
            },
            scaleLabel: {
              display: true,
              labelString: t('analytics.graph.usageGraph.scenarioUsage.graph.scenarioNames'),
            },
          },
        ],
      },
    }

    return (
      <div className="dm-graph">
        <Loader type="overlay" loaded={!isFetching}>
          <HorizontalBar data={this.generateData} options={options} height={height} />
        </Loader>
      </div>
    )
  }
}

export default ScenarioUsageGraph
