import { deleteCache } from './api'
import Schemas from '../middleware/schema'
import request from '../helpers/requestManagementAPI'

export const fetchEntities = (token, filter = {}) => dispatch => {
  return request.get('/entities/', {
    token,
    dispatch,
    body: filter,
    target: 'entities',
    schema: Schemas.entities,
    preserve: false,
  })
}

export const fetchEntity = (token, id, errorHandler) => dispatch => {
  return request.get(`/entities/${id}`, {
    token,
    dispatch,
    target: 'entities',
    schema: Schemas.entity,
    errorHandler,
  })
}

export const createEntity = (token, body) => dispatch => {
  return request.post('/entities/', {
    token,
    dispatch,
    body,
    target: 'entities',
    schema: Schemas.entity,
  })
}

export const updateEntity = (token, id, body) => dispatch => {
  return request.put(`/entities/${id}`, {
    token,
    dispatch,
    body,
    target: 'entities',
    schema: Schemas.entity,
    preserve: false,
  })
}

export const deleteEntity = (token, id) => dispatch => {
  return request
    .delete(`/entities/${id}`, {
      token,
      dispatch,
      target: 'entities',
    })
    .then(() => dispatch(deleteCache('entities', id)))
}

export const exportEntity = (token, id) => dispatch => {
  return request.post(`/entities/${id}/export`, {
    token,
    dispatch,
    target: 'entities',
  })
}
