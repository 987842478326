import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Loader from '../../components/common/Loader'
import { fetchAccount } from '../../actions/account'
import { addNotice } from '../../actions/notice'
import { fetchAvailablePlans, upgradePlan } from '../../actions/plan'
import { isFetching } from '../../helpers/selector'

export class PlanUpgrade extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    account: PropTypes.object,
    plans: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
  }

  constructor() {
    super()
    this.state = { phase: 'initial', newPlan: null, errorMessages: [] }
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    dispatch(fetchAccount(state.session.token))
    dispatch(fetchAvailablePlans(state.session.token))
  }

  openModal = plan => {
    const { plans } = this.props

    if (!lodash.find(plans, { name: plan }).is_changeable) return

    this.setState({ phase: 'confirmation', newPlan: plan })
  }

  closeModal = () => {
    this.setState({ phase: 'initial', newPlan: null, errorMessages: [] })
  }

  handleSave = plan => {
    const state = this.context.store.getState()
    const { t, router } = this.context
    const { dispatch } = this.props

    return dispatch(upgradePlan(state.session.token, plan))
      .then(() => router.push({ pathname: '/account' }))
      .then(() => dispatch(addNotice('info', t('account.planTable.upgradeSuccessMessage'))))
      .catch(e => {
        this.setState({ phase: 'error', errorMessages: JSON.parse(e.body).messages })
      })
  }

  renderModalBody() {
    const { t } = this.context
    const { phase, errorMessages } = this.state

    let content
    if (phase === 'confirmation') {
      content = (
        <div
          dangerouslySetInnerHTML={{ __html: t(`account.planTable.planUpgrade.notes.${this.state.newPlan}`) }}
        />
      )
    } else if (phase === 'error') {
      const errorNodes = lodash.map(errorMessages, (error, index) => {
        return (
          <li key={`error${index}`} className="dm-error-message">
            {error}
          </li>
        )
      })

      content = (
        <div>
          <div
            className="dm-error-message"
            dangerouslySetInnerHTML={{
              __html: t(`account.planTable.planUpgrade.notPermitted.${this.state.newPlan}`),
            }}
          />
          <div className="errors">
            <ul>{errorNodes}</ul>
          </div>
        </div>
      )
    }

    return <div className="modal-body">{content}</div>
  }

  renderModalFooter() {
    const { t } = this.context
    const { isFetching } = this.props
    const { phase } = this.state

    let content
    if (phase === 'confirmation') {
      content = (
        <button
          key="apply"
          type="button"
          className="btn btn-primary dm-btn"
          onClick={() => this.handleSave(this.state.newPlan)}
          disabled={isFetching}
        >
          {t('account.planTable.planUpgrade.apply')}
        </button>
      )
    } else if (phase === 'error') {
      content = (
        <button
          key="close"
          type="button"
          className="btn btn-primary dm-btn"
          onClick={this.closeModal}
          disabled={isFetching}
        >
          {t('account.planTable.planUpgrade.close')}
        </button>
      )
    }

    return <div className="modal-footer">{content}</div>
  }

  renderModal() {
    const { t } = this.context
    const { isFetching } = this.props

    return (
      <div className="dm-modal modal-background" onClick={this.closeModal}>
        <div className="modal-dialog" role="document" onClick={e => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.closeModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title">
                {t('account.planTable.planUpgrade.title', { plan: t(`common.plans.${this.state.newPlan}`) })}
              </h4>
            </div>
            <Loader type="show" loaded={!isFetching}>
              {this.renderModalBody()}
              {this.renderModalFooter()}
            </Loader>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { t } = this.context
    const { isFetching, account, plans } = this.props
    if (!account || lodash.isEmpty(plans)) return null

    const userName = this.context.store.getState().session.name

    const planList = ['trial', 'free', 'lite', 'standard', 'advance']
    const buttonDisables = {}
    const cellFocuses = {}

    planList.forEach(function (name) {
      buttonDisables[name] = !lodash.find(plans, { name: name }).is_changeable
      if (account.plan === 'trial-beta') {
        cellFocuses[name] = name === 'trial' ? 'focus' : ''
      } else {
        cellFocuses[name] = name === account.plan ? 'focus' : ''
      }
    })

    const rowItems = [
      t('account.planTable.price'),
      t('account.planTable.freeMessage'),
      t('account.planTable.excessCharge'),
      t('account.planTable.createBot'),
      t('account.planTable.createScenario'),
      t('account.planTable.createfaq'),
      t('account.planTable.publishApplication'),
      t('account.planTable.application'),
      t('account.planTable.scenarioImport'),
      t('account.planTable.template'),
      t('account.planTable.hearingByEntity'),
      t('account.planTable.simulator'),
      t('account.planTable.customizeTheChatScreen'),
      t('account.planTable.integration'),
      t('account.planTable.mail'),
      t('account.planTable.operator'),
      t('account.planTable.customize'),
      t('account.planTable.usagelog'),
      t('account.planTable.downloadLog'),
      t('account.planTable.feedback'),
      t('account.planTable.failurelog'),
      t('account.planTable.office365'),
      t('account.planTable.salesforce'),
      t('account.planTable.advancedScenarioControl'),
      t('account.planTable.suggestionWhileQuestion'),
      t('account.planTable.accessLimit'),
      t('account.planTable.botAccessLimit'),
      t('account.planTable.operationHistory'),
    ]

    const MailTo = ({ desiredPlan }) => {
      const subject = t('account.planTable.mailSubject', { plan: desiredPlan })
      const body = t('account.planTable.mailBody', {
        accountNumber: account.account_number,
        companyName: account.company_name,
        userName: userName,
        plan: desiredPlan,
      })
      const link = `mailto:support@dialogplay.jp?&subject=${subject}&body=${body}`

      return (
        <a type="button" href={link} className="dm-btn btn btn-primary">
          {t('account.planTable.apply')}
        </a>
      )
    }

    return (
      <div className="dm-plan-upgrade">
        <Loader loaded={!isFetching} type="show">
          <div className="x-scroll">
            <table className="table table-striped table-bordered refreshable dm-table hover">
              <thead>
                <tr>
                  <th className="sticky" />
                  <th className={cellFocuses['trial']}>{t('common.plans.trial')}</th>
                  <th className={cellFocuses['free']}>{t('common.plans.free')}</th>
                  <th className={cellFocuses['lite']}>{t('common.plans.lite')}</th>
                  <th className={cellFocuses['standard']}>{t('common.plans.standard')}</th>
                  <th className={cellFocuses['advance']}>{t('common.plans.advance')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="sticky">{rowItems[0]}</td>
                  <td className={cellFocuses['trial']}>
                    {t('account.planTable.freeDuration', { days: '30' })}
                  </td>
                  <td className={cellFocuses['free']}>{t('account.planTable.free')}</td>
                  <td className={cellFocuses['lite']}>{t('account.planTable.money', { price: '50,000' })}</td>
                  <td className={cellFocuses['standard']}>
                    {t('account.planTable.money', { price: '80,000' })}
                  </td>
                  <td className={cellFocuses['advance']}>
                    {t('account.planTable.money', { price: '150,000' })}
                  </td>
                </tr>
                <tr>
                  <td className="sticky">
                    {rowItems[1]}
                    <span className="asterisk">{t('account.planTable.asterisk', { number: '1' })}</span>
                  </td>
                  <td className={cellFocuses['trial']}>
                    {t('account.planTable.count', { number: '1,000' })}
                  </td>
                  <td className={cellFocuses['free']}>{t('account.planTable.count', { number: '100' })}</td>
                  <td className={cellFocuses['lite']}>
                    {t('account.planTable.count', { number: '10,000' })}
                  </td>
                  <td className={cellFocuses['standard']}>
                    {t('account.planTable.count', { number: '50,000' })}
                  </td>
                  <td className={cellFocuses['advance']}>
                    {t('account.planTable.count', { number: '100,000' })}
                  </td>
                </tr>
                <tr>
                  <td className="sticky">
                    {rowItems[2]}
                    <span className="asterisk">{t('account.planTable.asterisk', { number: '1' })}</span>
                  </td>
                  <td className={'impossible ' + cellFocuses['trial']}>
                    <span className="asterisk">{t('account.planTable.asterisk', { number: '2' })}</span>
                  </td>
                  <td className={'impossible ' + cellFocuses['free']}>
                    <span className="asterisk">{t('account.planTable.asterisk', { number: '2' })}</span>
                  </td>
                  <td className={cellFocuses['lite']}>
                    {t('account.planTable.moneyPerCount', { price: '0.5' })}
                  </td>
                  <td className={cellFocuses['standard']}>
                    {t('account.planTable.moneyPerCount', { price: '0.5' })}
                  </td>
                  <td className={cellFocuses['advance']}>
                    {t('account.planTable.moneyPerCount', { price: '0.5' })}
                  </td>
                </tr>
                <tr>
                  <td className="sticky">{rowItems[3]}</td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'possible ' + cellFocuses['free']} />
                  <td className={'possible ' + cellFocuses['lite']} />
                  <td className={'possible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">{rowItems[4]}</td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'possible ' + cellFocuses['free']} />
                  <td className={'possible ' + cellFocuses['lite']} />
                  <td className={'possible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">{rowItems[5]}</td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'impossible ' + cellFocuses['free']} />
                  <td className={'possible ' + cellFocuses['lite']} />
                  <td className={'possible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">{rowItems[6]}</td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'possible ' + cellFocuses['free']} />
                  <td className={'possible ' + cellFocuses['lite']} />
                  <td className={'possible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">{rowItems[7]}</td>
                  <td className={cellFocuses['trial']}>
                    <ul className="platforms">
                      <li>{t('account.planTable.platforms.web')}</li>
                      <li>{t('account.planTable.platforms.line')}</li>
                      <li>{t('account.planTable.platforms.lineWorks')}</li>
                      <li>{t('account.planTable.platforms.slack')}</li>
                      <li>{t('account.planTable.platforms.azureBotService')}</li>
                      <li>{t('account.planTable.platforms.teams')}</li>
                      <li>{t('account.planTable.platforms.hangouts')}</li>
                      <li>
                        {t('account.planTable.platforms.directLineApi')}
                        <span className="asterisk">{t('account.planTable.asterisk', { number: '4' })}</span>
                      </li>
                    </ul>
                  </td>
                  <td className={cellFocuses['free']}>
                    <ul className="platforms">
                      <li>{t('account.planTable.platforms.web')}</li>
                    </ul>
                  </td>
                  <td className={cellFocuses['lite']}>
                    <ul className="platforms">
                      <li>{t('account.planTable.platforms.web')}</li>
                      <li>{t('account.planTable.platforms.line')}</li>
                      <li>{t('account.planTable.platforms.slack')}</li>
                    </ul>
                  </td>
                  <td className={cellFocuses['standard']}>
                    <ul className="platforms">
                      <li>{t('account.planTable.platforms.web')}</li>
                      <li>{t('account.planTable.platforms.line')}</li>
                      <li>{t('account.planTable.platforms.lineWorks')}</li>
                      <li>{t('account.planTable.platforms.slack')}</li>
                      <li>{t('account.planTable.platforms.azureBotService')}</li>
                      <li>{t('account.planTable.platforms.teams')}</li>
                      <li>{t('account.planTable.platforms.hangouts')}</li>
                      <li>
                        {t('account.planTable.platforms.directLineApi')}
                        <span className="asterisk">{t('account.planTable.asterisk', { number: '4' })}</span>
                      </li>
                    </ul>
                  </td>
                  <td className={cellFocuses['advance']}>
                    <ul className="platforms">
                      <li>{t('account.planTable.platforms.web')}</li>
                      <li>{t('account.planTable.platforms.line')}</li>
                      <li>{t('account.planTable.platforms.lineWorks')}</li>
                      <li>{t('account.planTable.platforms.slack')}</li>
                      <li>{t('account.planTable.platforms.azureBotService')}</li>
                      <li>{t('account.planTable.platforms.teams')}</li>
                      <li>{t('account.planTable.platforms.hangouts')}</li>
                      <li>
                        {t('account.planTable.platforms.directLineApi')}
                        <span className="asterisk">{t('account.planTable.asterisk', { number: '4' })}</span>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="sticky">{rowItems[8]}</td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'possible ' + cellFocuses['free']} />
                  <td className={'possible ' + cellFocuses['lite']} />
                  <td className={'possible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">{rowItems[9]}</td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'possible ' + cellFocuses['free']} />
                  <td className={'possible ' + cellFocuses['lite']} />
                  <td className={'possible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">{rowItems[10]}</td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'possible ' + cellFocuses['free']} />
                  <td className={'possible ' + cellFocuses['lite']} />
                  <td className={'possible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">{rowItems[11]}</td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'possible ' + cellFocuses['free']} />
                  <td className={'possible ' + cellFocuses['lite']} />
                  <td className={'possible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">{rowItems[12]}</td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'possible ' + cellFocuses['free']} />
                  <td className={'possible ' + cellFocuses['lite']} />
                  <td className={'possible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">{rowItems[13]}</td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'impossible ' + cellFocuses['free']} />
                  <td className={'impossible ' + cellFocuses['lite']} />
                  <td className={'possible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">{rowItems[14]}</td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'impossible ' + cellFocuses['free']} />
                  <td className={'impossible ' + cellFocuses['lite']} />
                  <td className={'possible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">
                    {rowItems[15]}
                    <span className="asterisk">{t('account.planTable.asterisk', { number: '3' })}</span>
                  </td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'impossible ' + cellFocuses['free']} />
                  <td className={'impossible ' + cellFocuses['lite']} />
                  <td className={'possible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">{rowItems[16]}</td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'impossible ' + cellFocuses['free']} />
                  <td className={'impossible ' + cellFocuses['lite']} />
                  <td className={'possible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">{rowItems[17]}</td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'impossible ' + cellFocuses['free']} />
                  <td className={'impossible ' + cellFocuses['lite']} />
                  <td className={'possible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">{rowItems[18]}</td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'impossible ' + cellFocuses['free']} />
                  <td className={'impossible ' + cellFocuses['lite']} />
                  <td className={'possible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">{rowItems[19]}</td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'impossible ' + cellFocuses['free']} />
                  <td className={'impossible ' + cellFocuses['lite']} />
                  <td className={'possible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">{rowItems[20]}</td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'impossible ' + cellFocuses['free']} />
                  <td className={'impossible ' + cellFocuses['lite']} />
                  <td className={'impossible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">{rowItems[21]}</td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'impossible ' + cellFocuses['free']} />
                  <td className={'impossible ' + cellFocuses['lite']} />
                  <td className={'impossible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">
                    {rowItems[22]}
                    <span className="asterisk">{t('account.planTable.asterisk', { number: '4' })}</span>
                  </td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'impossible ' + cellFocuses['free']} />
                  <td className={'impossible ' + cellFocuses['lite']} />
                  <td className={'impossible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">
                    {rowItems[23]}
                    <span className="asterisk">{t('account.planTable.asterisk', { number: '5' })}</span>
                  </td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'impossible ' + cellFocuses['free']} />
                  <td className={'impossible ' + cellFocuses['lite']} />
                  <td className={'impossible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">
                    {rowItems[24]}
                    <span className="asterisk">{t('account.planTable.asterisk', { number: '6' })}</span>
                  </td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'impossible ' + cellFocuses['free']} />
                  <td className={'impossible ' + cellFocuses['lite']} />
                  <td className={'impossible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">{rowItems[25]}</td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'impossible ' + cellFocuses['free']} />
                  <td className={'impossible ' + cellFocuses['lite']} />
                  <td className={'impossible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">{rowItems[26]}</td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'impossible ' + cellFocuses['free']} />
                  <td className={'impossible ' + cellFocuses['lite']} />
                  <td className={'impossible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
                <tr>
                  <td className="sticky">{rowItems[27]}</td>
                  <td className={'possible ' + cellFocuses['trial']} />
                  <td className={'impossible ' + cellFocuses['free']} />
                  <td className={'impossible ' + cellFocuses['lite']} />
                  <td className={'impossible ' + cellFocuses['standard']} />
                  <td className={'possible ' + cellFocuses['advance']} />
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td className="sticky" />
                  <td className={cellFocuses['trial']} />
                  <td className={cellFocuses['free']}>
                    {account.plan === 'trial' && (
                      <button
                        type="button"
                        className="dm-btn btn btn-primary"
                        onClick={() => this.openModal('free')}
                        disabled={account.is_invalidated}
                      >
                        {t('account.planTable.apply')}
                      </button>
                    )}
                  </td>
                  <td className={cellFocuses['lite']}>
                    <MailTo desiredPlan="Lite"></MailTo>
                  </td>
                  <td className={cellFocuses['standard']}>
                    <MailTo desiredPlan="Standard"></MailTo>
                  </td>
                  <td className={cellFocuses['advance']}>
                    <MailTo desiredPlan="Advance"></MailTo>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="supplement">
            <div>{t('account.planTable.supplement1')}</div>
            <div className="asterisk">
              <div>{t('account.planTable.supplement2')}</div>
              <div>{t('account.planTable.supplement3')}</div>
              <div>{t('account.planTable.supplement4')}</div>
              <div>{t('account.planTable.supplement5')}</div>
              <div>{t('account.planTable.supplement6')}</div>
              <div>{t('account.planTable.supplement7')}</div>
            </div>
          </div>
          {lodash.includes(['confirmation', 'error'], this.state.phase) && this.renderModal()}
        </Loader>
      </div>
    )
  }
}

export const mapStateToProps = state => {
  const account = lodash.first(Object.values(state.entities.accounts))
  const ids = state.pagination.plans.ids || []
  const plans = ids.map(id => state.entities.plans[id])
  return {
    account,
    plans,
    isFetching: isFetching(state, ['accounts', 'plans']),
  }
}

export default connect(mapStateToProps)(PlanUpgrade)
