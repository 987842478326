import PropTypes from 'prop-types'
import React, { Component } from 'react'

class Sidebar extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    children: PropTypes.node,
    titles: PropTypes.array.isRequired,
    style: PropTypes.object,
    onChangeSidebar: PropTypes.func,
  }

  static defaultProps = {
    children: null,
    onChangeSidebar: null,
    style: {},
  }

  constructor() {
    super()
    this.state = { show: false, sidebarIndex: 0 }
  }

  onClickList = value => {
    const { onChangeSidebar } = this.props
    const { sidebarIndex } = this.state

    if (sidebarIndex !== value) {
      if (onChangeSidebar) onChangeSidebar(value)
      this.setState({ sidebarIndex: value, show: true })
    } else {
      this.setState({ show: !this.state.show })
    }
  }

  render() {
    const { titles, style } = this.props
    const { sidebarIndex } = this.state

    return (
      <div>
        <div className={`sidebar-container ${this.state.show ? 'show' : ''}`} style={style}>
          <ul className="sidebar">
            {titles.map((title, index) => {
              return (
                <li
                  key={index}
                  className={`${sidebarIndex === index ? 'selected' : ''}`}
                  onClick={this.onClickList.bind(this, index)}
                >
                  {title}
                </li>
              )
            })}
          </ul>
          <div className="sidebar-content">{this.props.children}</div>
        </div>
      </div>
    )
  }
}

export default Sidebar
