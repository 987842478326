import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import classnames from 'classnames'

import {
  InputField,
  SelectField,
  AutoCompleteField,
  CheckboxField,
} from '../../components/common/fields/FormFields'
import AddNewAction from './AddNewAction'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import Tooltip from '../common/Tooltip'
import { filterSystemVariables } from '../../helpers/replaceSystemVariable'
import { isPermitted } from '../../helpers/permission'

export class AbstractAction extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    topicType: PropTypes.string,
    action: PropTypes.object,
    index: PropTypes.number,
    name: PropTypes.string,
    onMoveUp: PropTypes.func,
    onMoveDown: PropTypes.func,
    onRemove: PropTypes.func,
    onInsertAction: PropTypes.func,
    onChangeConditionType: PropTypes.func,
    onError: PropTypes.func,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    userVariables: PropTypes.array,
    chatbotConstants: PropTypes.array,
    variables: PropTypes.array.isRequired,
    condition_groups: PropTypes.array,
    restrictedActions: PropTypes.array,
    isValidateCondition: PropTypes.bool,
    disableAddNewAction: PropTypes.bool,
  }

  static defaultProps = {
    restrictedActions: [],
    isValidateCondition: true,
  }

  static validate = action => {
    const errors = {}
    if (action.condition_variable) {
      if (action.condition_variable.length > 30) {
        errors.condition_variable = { id: 'validate.exceededMaxLength', values: { length: 30 } }
      }
    }
    if (action.condition_variable) {
      if (!action.condition_type) {
        errors.condition_type = 'validate.required'
      } else if (action.condition_type === 'between') {
        if (action.condition_value) {
          const condition_value_errors = {}
          if (action.condition_value.from && action.condition_value.from.length > 80) {
            condition_value_errors.from = { id: 'validate.exceededMaxLength', values: { length: 80 } }
          }
          if (action.condition_value.to && action.condition_value.to.length > 80) {
            condition_value_errors.to = { id: 'validate.exceededMaxLength', values: { length: 80 } }
          }

          if (!lodash.isEmpty(condition_value_errors)) {
            errors.condition_value = condition_value_errors
          }
        }
      } else {
        if (action.condition_value && action.condition_value.length > 200) {
          errors.condition_value = { id: 'validate.exceededMaxLength', values: { length: 200 } }
        }
      }
    }

    return errors
  }

  static validateForLine = () => true

  static getVariables = (_action, _props, _context) => {
    return []
  }

  constructor() {
    super()
    this.state = { isAdvanced: false, isOpeningFinished: false }
  }

  componentWillMount() {
    const { action } = this.props
    if (action.condition_variable || action.use_condition_group) {
      this.setState({ isAdvanced: true, isOpeningFinished: true })
    }
  }

  onFocus = () => {
    this.setState({ isActive: true })
  }

  onBlur = () => {
    this.setState({ isActive: false })
  }

  render() {
    const { t } = this.context
    const { isAdvanced, isActive, isOpeningFinished } = this.state
    const {
      topicType,
      index,
      isFirst,
      isLast,
      action,
      onRemove,
      onMoveUp,
      onMoveDown,
      onInsertAction,
      restrictedActions,
      disableAddNewAction,
    } = this.props

    const type = topicType === 'normal' ? this.getType() : 'normal'
    const classNames = [type]
    if (action.condition_variable || action.use_condition_group) classNames.push('with-condition')
    if (isActive) classNames.push('is-active')
    if (isAdvanced) classNames.push('is-advanced')
    if (isOpeningFinished) classNames.push('is-opening-finished')
    if (action.type === 'operator' && isPermitted('platform_mobilus', this.context)) {
      classNames.push('operatoraction')
    } else {
      classNames.push('abstract')
    }

    return (
      <div>
        <div
          className={`panel panel-default dm-panel ${classNames.join(' ')}`}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        >
          <div id={`action${index}`} className="scroll-position" />
          <div className="panel-heading">
            <small className="title">
              <span className={`avator-${this.getIcon()}`} />
              {`${index + 1}: ${this.getTitle()}`}
              <Tooltip name={`topic.tooltip.actionList.${action.type}.title`} />
            </small>

            <div className="dm-panel-sorts">
              {!isFirst && (
                <button
                  type="button"
                  className="btn btn-primary dm-btn is-gray"
                  onClick={() => onMoveUp(index)}
                >
                  <i className="icon icon-arrows-up" />
                </button>
              )}
              {!isLast && (
                <button
                  type="button"
                  className="btn btn-primary dm-btn is-gray"
                  onClick={() => onMoveDown(index)}
                >
                  <i className="icon icon-arrows-down" />
                </button>
              )}
              <button type="button" className="btn btn-danger dm-btn" onClick={() => onRemove(index)}>
                <i className="icon icon-arrows-remove" />
              </button>
            </div>
          </div>
          <div className="panel-body">{this.renderBody()}</div>
          {topicType === 'normal' && this.renderCondition()}
        </div>

        <div className={`panel-connector dm-panel-connector arrow ${type}`}>
          <div className="user">
            <span className="avator-user" />
            {t('topic.actionList.waitUser')}
            <Tooltip name="topic.tooltip.actionList.waitUser" />
          </div>
        </div>

        {!isLast && (
          <AddNewAction
            onAdd={onInsertAction}
            restrictedActions={restrictedActions}
            disabled={disableAddNewAction}
          />
        )}
      </div>
    )
  }

  renderCondition = () => {
    const { action } = this.props

    const advancedBodyClass = classnames({
      'panel-advanced-body': true,
      'unused-variable': !this.validateVariable(),
      'invalid-variable': !lodash.isEmpty(AbstractAction.validate(action)),
    })

    return (
      <div>
        <div className={advancedBodyClass} onTransitionEnd={this.onTransitionEnd}>
          <div className="panel-body">{this.renderAdvancedBody()}</div>
        </div>
        <div className="panel-footer" onClick={this.toggleAdvancedBody}>
          <i className="icon icon-arrows-down-double" />
        </div>
      </div>
    )
  }

  toggleAdvancedBody = () => {
    this.setState({ isAdvanced: !this.state.isAdvanced, isOpeningFinished: false })
  }

  onTransitionEnd = () => {
    if (this.state.isAdvanced) {
      this.setState({ isOpeningFinished: true })
    }
  }

  getTitle() {
    return 'Unknown'
  }

  getType() {
    return 'normal'
  }

  getIcon() {
    return 'bot'
  }

  validateVariable() {
    const { t } = this.context
    const { isValidateCondition, variables, action, userVariables, chatbotConstants } = this.props

    //  Skip validation
    if (!isValidateCondition) return true

    if (!action.condition_variable) return true

    const adaptableVariables = variables
      .map(variable => variable.variable)
      .concat(
        filterSystemVariables().map(variable => t('systemVariables.' + variable)),
        userVariables,
        chatbotConstants
      )

    return adaptableVariables.some(variable => {
      return action.condition_variable.split('.')[0] === variable
    })
  }

  renderBody() {
    return null
  }

  renderCustomizedAdvancedBody() {
    return null
  }

  renderAdvancedBody() {
    const { t } = this.context
    const {
      name,
      action,
      variables,
      onChangeConditionType,
      condition_groups,
      userVariables,
      chatbotConstants,
    } = this.props

    const condition = [
      { id: '', name: '' },
      { id: '=', name: t('common.conditions.equal') },
      { id: '<>', name: t('common.conditions.notEqual') },
      { id: '<', name: t('common.conditions.lessThan') },
      { id: '<=', name: t('common.conditions.lessOrEqual') },
      { id: '>', name: t('common.conditions.greaterThan') },
      { id: '>=', name: t('common.conditions.greaterOrEqual') },
      { id: 'include', name: t('common.conditions.include') },
      { id: 'not_include', name: t('common.conditions.notInclude') },
      { id: 'between', name: t('common.conditions.between') },
      /* { id: 'undefined', name: t('common.conditions.undefined') } */
    ]
    const isValidVariable = this.validateVariable()
    const candidates = (lodash.find(variables, { variable: action.condition_variable }) || {}).candidates
    const scenarioVariables = lodash.map(variables, 'variable')
    const adaptableVariables = lodash
      .chain(scenarioVariables)
      .difference(userVariables)
      .union(
        userVariables,
        filterSystemVariables().map(variable => t('systemVariables.' + variable)),
        chatbotConstants
      )
      .map(value => {
        return { variable: value, text: value }
      })
      .value()
    const disabledConditionGroup = condition_groups && condition_groups.length ? false : true
    const isUndefined = action.condition_type === 'undefined'

    let valueField
    if (action.condition_type === 'between') {
      valueField = (
        <div className="dm-row-without-gap form-horizontal">
          <div className="col-xs-6 col-sm-6 between-from">
            <Field
              name={`${name}.condition_value.from`}
              className="form-control dm-form-control"
              component={InputField}
              placeholder={t('topic.actionList.conditionPlaceholder.from')}
              maxLength="80"
            />
          </div>
          <div className="col-xs-6 col-sm-6 between-to">
            <Field
              name={`${name}.condition_value.to`}
              className="form-control dm-form-control"
              component={InputField}
              placeholder={t('topic.actionList.conditionPlaceholder.to')}
              maxLength="80"
            />
          </div>
        </div>
      )
    } else if (candidates) {
      valueField = (
        <Field
          name={`${name}.condition_value`}
          className="form-control dm-form-control"
          component={SelectField}
          items={candidates}
          valueKey="value"
          displayKey="value"
          empty={true}
        />
      )
    } else {
      valueField = (
        <Field
          name={`${name}.condition_value`}
          className="form-control dm-form-control"
          component={InputField}
          placeholder={isUndefined ? '' : t('topic.actionList.conditionValue')}
          disabled={isUndefined}
          maxLength="200"
        />
      )
    }

    return (
      <div>
        {this.renderCustomizedAdvancedBody()}
        <div className="row">
          <label htmlFor="condition" className="control-label col-lg-2 col-md-2 col-sm-12">
            {t('topic.actionList.condition')}
            <Tooltip name="topic.tooltip.actionList.condition" />
          </label>

          <div className="col-lg-10 col-md-10 col-sm-12">
            <div className="form-group form-inline">
              <fieldset disabled={disabledConditionGroup}>
                <div className="checkbox">
                  <Field
                    type="checkbox"
                    name={`${name}.use_condition_group`}
                    id={`${name}.use_condition_group`}
                    component={CheckboxField}
                    className="form-control m-0"
                    disabled={disabledConditionGroup}
                  />
                  <LabelWithTooltip
                    htmlFor={`${name}.use_condition_group`}
                    name="topic.actionList.useConditionGroup"
                    disabled={disabledConditionGroup}
                  />
                </div>
              </fieldset>
            </div>
          </div>
          {action.use_condition_group && (
            <div className="col-lg-10 col-md-10 col-sm-12">
              <Field
                name={`${name}.condition_group_id`}
                className="form-control dm-form-control"
                component={SelectField}
                items={condition_groups}
                displayKey="name"
                valueKey="id"
                empty={true}
                order="asc"
              />
            </div>
          )}
          {!action.use_condition_group && (
            <div className="col-lg-10 col-md-10 col-sm-12">
              <div className="dm-row-without-gap">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 condition">
                  <Field
                    name={`${name}.condition_variable`}
                    className="form-control dm-form-control"
                    placeholder={t('topic.actionList.conditionVariable')}
                    component={AutoCompleteField}
                    items={adaptableVariables}
                    valueKey="variable"
                    displayKey="text"
                    maxLength="30"
                  />
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                  <Field
                    name={`${name}.condition_type`}
                    className="form-control dm-form-control"
                    component={SelectField}
                    items={condition}
                    onChange={onChangeConditionType}
                    valueKey="id"
                    displayKey="name"
                  />
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">{valueField}</div>
              </div>
              {!isValidVariable && (
                <div className="col-xs-12 info">{t('topic.actionList.conditionValidator')}</div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default AbstractAction
