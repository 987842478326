import React, { Component } from 'react'

class CookiebotWidget extends Component {
  constructor() {
    super()
    this.state = { __html: '' }
  }

  componentDidMount() {
    setTimeout(this.waitCookiebotWidget, 100)
  }

  waitCookiebotWidget = () => {
    if (document.getElementById('CookiebotWidget')) {
      this.setState({ __html: document.getElementById('CookiebotWidget').parentNode.outerHTML })
    } else {
      setTimeout(this.waitCookiebotWidget, 100)
    }
  }

  showCookiebotWidget = () => {
    window.Cookiebot.show()
  }

  render = () => {
    return <div onClick={this.showCookiebotWidget} dangerouslySetInnerHTML={this.state}></div>
  }
}

export default CookiebotWidget
