import objectFitImages from 'object-fit-images'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import classnames from 'classnames'

import { FileField } from '../../components/common/fields/FormFields'
import LabelWithTooltip from '../common/LabelWithTooltip'

export class IconFieldWithDialog extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    iconUrl: PropTypes.string,
    defaultUrl: PropTypes.string.isRequired,
    iconHeight: PropTypes.number.isRequired,
    iconWidth: PropTypes.number.isRequired,
    iconFileFormats: PropTypes.array,
    renderNotes: PropTypes.func,
    restoreDefault: PropTypes.func,
    handleSave: PropTypes.func.isRequired,
    handleDelete: PropTypes.func,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    defaultUrl: '/image/icon_no_image.png',
    renderNotes: () => {},
    disabled: false,
  }

  constructor() {
    super()
    this.state = { pendingIconFile: null, pendingIconUrl: null, isOpen: false, isInvalidImage: false }
  }

  onClose = () => {
    this.setState({ pendingIconFile: null, pendingIconUrl: null, isOpen: false, isInvalidImage: false })
  }

  setImageIfValid = image => {
    const { iconHeight, iconWidth, iconFileFormats } = this.props

    const maxFileSize = 3145728

    if (iconFileFormats.indexOf(image.type) === -1 || image.size > maxFileSize) {
      this.setState({ isInvalidImage: true })
      return
    }

    const image_src = window.URL.createObjectURL(image)

    const img = new Image()
    img.onload = () => {
      if (img.height < iconHeight || img.width < iconWidth) {
        this.setState({ isInvalidImage: true })
        return
      } else {
        this.setState({ isInvalidImage: false, pendingIconFile: image, pendingIconUrl: img.src })
      }
    }
    img.src = image_src
  }

  updateDisplayingImage = () => {
    // Call to change displaying image after change URL in IE
    this.loadTimer = setTimeout(() => {
      objectFitImages()
    }, 1000)
  }

  onChangeImage = e => {
    var files = e.target.files
    if (!files[0]) return

    const image = files[0]
    this.setImageIfValid(image)

    this.updateDisplayingImage()
  }

  restoreDefault = () => {
    const { restoreDefault } = this.props

    if (!restoreDefault()) return

    this.setState({ pendingIconFile: null, pendingIconUrl: null, isOpen: false, isInvalidImage: false })
    this.updateDisplayingImage()
  }

  handleSave = () => {
    const { handleSave } = this.props
    const { pendingIconFile } = this.state

    if (!handleSave(pendingIconFile)) return

    this.setState({ pendingIconFile: null, pendingIconUrl: null, isOpen: false, isInvalidImage: false })
    this.updateDisplayingImage()
  }

  handleDelete = () => {
    const { handleDelete } = this.props

    if (!handleDelete()) return

    this.setState({ pendingIconFile: null, pendingIconUrl: null, isOpen: false, isInvalidImage: false })
    this.updateDisplayingImage()
  }

  handleOpen = () => {
    const { disabled } = this.props

    if (disabled) return

    this.setState({ isOpen: true })
  }

  renderFileField = () => {
    const { t } = this.context
    const { pendingIconUrl } = this.state
    const { iconUrl, iconHeight, iconWidth, renderNotes, restoreDefault, handleDelete } = this.props

    const url = pendingIconUrl || iconUrl

    const buttons = [
      <button
        key="setting"
        type="button"
        className="btn dm-btn btn-primary"
        disabled={!this.state.pendingIconUrl}
        onClick={this.handleSave}
      >
        {t('iconField.iconSettingDialog.setting')}
      </button>,
    ]

    if (iconUrl) {
      if (restoreDefault) {
        buttons.unshift(
          <button
            key="restoreDefault"
            type="button"
            className="btn dm-btn btn-warning"
            onClick={this.restoreDefault}
          >
            {t('iconField.iconSettingDialog.restoreDefault')}
          </button>
        )
      }
      if (handleDelete) {
        buttons.push(
          <button key="delete" type="button" className="btn dm-btn btn-danger" onClick={this.handleDelete}>
            {t('common.delete')}
          </button>
        )
      }
    }

    return (
      <div className="modal-background" onClick={this.onClose}>
        <div className="modal-dialog" role="document" onClick={e => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title">{t('iconField.iconSettingDialog.title')}</h4>
            </div>
            <div className="modal-body">
              <div className="flex">
                <React.Fragment>
                  {url && (
                    <div className="chatbot-icon-preview">
                      <img src={url} width={iconWidth} height={iconHeight} alt="" />
                    </div>
                  )}
                  <div className="filefield">
                    <div>
                      <Field
                        type="file"
                        name="upload_file_with_dialog"
                        className="form-control dm-form-control"
                        component={FileField}
                        onChange={this.onChangeImage}
                      />
                      {this.state.isInvalidImage && <div className="error">{t('validate.invalidImage')}</div>}
                    </div>
                    {renderNotes()}
                  </div>
                </React.Fragment>
              </div>
            </div>
            <div className="modal-footer">{buttons}</div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { t } = this.context
    const { isOpen } = this.state
    const { name, iconUrl, defaultUrl, iconHeight, iconWidth, disabled } = this.props

    const url = iconUrl || defaultUrl

    const imageClass = classnames({
      disabled: disabled,
    })

    const buttonClass = classnames({
      'change-icon-link': true,
      disabled: disabled,
    })

    return (
      <div className="chatbot-icon-field">
        <div className="chatbot-icon-form">
          <div>
            <LabelWithTooltip className="dm-title-mini" name={name} />
          </div>
          <div className="chatbot-icon-preview">
            <div>
              <img
                className={imageClass}
                src={url}
                width={iconWidth}
                height={iconHeight}
                alt=""
                onClick={this.handleOpen}
              />
            </div>
            <button type="button" className={buttonClass} disabled={disabled} onClick={this.handleOpen}>
              {t('iconField.setting')}
            </button>
          </div>
        </div>
        {isOpen && this.renderFileField()}
      </div>
    )
  }
}

export default IconFieldWithDialog
