import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

class ImportantMessages extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    messages: PropTypes.array.isRequired,
  }

  static defaultProps = {
    messages: [],
  }

  render() {
    const { t } = this.context
    const { messages } = this.props
    if (lodash.isEmpty(messages)) return null

    const messageNodes = messages.map((message, index) => (
      <li key={index}>
        <span className="important">{t('common.importantNotice')}</span>
        <span dangerouslySetInnerHTML={{ __html: message }} />
      </li>
    ))
    return (
      <div className="dm-important-news">
        <ul className="container">{messageNodes}</ul>
      </div>
    )
  }
}

export default ImportantMessages
