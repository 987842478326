import rawRequest from './request'

import Config from './config'

class VoiceSynthAPIError {
  constructor(message, response) {
    this.message = message
    this.source = 'voiceSynth'
    this.response = response
  }
}

const defaultErrorHandler = (_dispatch, _path) => response => {
  if (response.ok) {
    return Promise.resolve(response)
  }

  return response
    .json()
    .catch(() => ({ messsage: response.statusText }))
    .then(detail => {
      throw new VoiceSynthAPIError(detail.message, response)
    })
}

const request = (path, options) => {
  const host = Config.voiceSynthServer

  options.headers = { Authorization: `Bearer ${Config.voiceSynthAccessToken}`, ...options.headers }
  options.errorHandler = options.errorHandler || defaultErrorHandler
  return rawRequest(host, path, options)
}

const getRequest = (path = '/', options) => request(path, { ...options, method: 'GET' })

const postRequest = (path = '/', options) => request(path, { ...options, method: 'POST' })

const putRequest = (path = '/', options) => request(path, { ...options, method: 'PUT' })

const deleteRequest = (path = '/', options) => request(path, { ...options, method: 'DELETE' })

export default {
  get: getRequest,
  post: postRequest,
  put: putRequest,
  delete: deleteRequest,
}
