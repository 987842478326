import request from '../helpers/requestManagementAPI'

export const inquiryImport = (token, faq_id, body) => dispatch => {
  return request.post(`/faqs/${faq_id}/inquiries/import`, {
    token,
    dispatch,
    body,
  })
}

export const fetchAnalysis = (token, faq_id) => dispatch => {
  return request.get(`/faqs/${faq_id}/inquiries/analysis`, {
    token,
    dispatch,
    errorHandler: () => ({}),
  })
}

export const receiveGroupingResult = (faq_id, groups) => ({
  type: 'RECEIVE_GROUPING_RESULT',
  faq_id,
  groups,
})

export const clearGroupingResult = (token, faq_id) => dispatch => {
  return request
    .delete(`/faqs/${faq_id}/inquiries/analysis`, {
      token,
      dispatch,
    })
    .catch(e => {
      // Don't fallback if response status is forbidden
      if (e.response.status === 403) throw e
      // Fallback to the import page even if it fails
    })
    .then(() => {
      dispatch({ type: 'CLEAR_GROUPING_RESULT', faq_id })
    })
}
