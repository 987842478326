export const convertReminingTime = (context, time) => {
  const { t } = context

  if (time == null) return ''

  if (time === 0) {
    return t('common.remainingTime.expired')
  }

  const days = Math.floor(time / 3600 / 24)
  if (days > 0) {
    return t('common.remainingTime.days', { days: days })
  }

  const hours = Math.floor(time / 3600)
  if (hours > 0) {
    return t('common.remainingTime.hours', { hours: hours })
  }

  return t('common.remainingTime.lessThanHour')
}
