import lodash from 'lodash'
import moment from 'moment'

const initialState = {
  monthlyActivities: {},
  weeklyActivities: {},
  dailyActivities: {},
  scenarioUsages: {},
  faqUsages: {},
  isFetching: false,
}

const reducer = (state = initialState, action) => {
  const getLastUpdated = (lastUpdated, applications) => {
    const lastUpdates = [...lodash.map(applications, 'last_updated'), lastUpdated]
    if (!lodash.some(lastUpdates)) return null

    const enabledDate = lodash.compact(lastUpdates)

    return moment.max(lodash.map(enabledDate, date => moment(date)))
  }

  const updateTargetState = (state, action) => {
    const applications = action.result.applications ? action.result.applications : [action.result]
    return {
      applications: {
        ...state.applications,
        ...lodash.keyBy(applications, 'id'),
      },
      last_updated: getLastUpdated(state.last_updated, applications),
    }
  }

  if (action.type === 'START_FETCHING_STATISTICS') {
    return {
      ...state,
      isFetching: true,
    }
  }

  if (action.type === 'RECEIVE_MONTHLY_STATISTICS') {
    return {
      ...state,
      isFetching: false,
      monthlyActivities: updateTargetState(state.monthlyActivities, action),
    }
  }

  if (action.type === 'RECEIVE_WEEKLY_STATISTICS') {
    return {
      ...state,
      isFetching: false,
      weeklyActivities: updateTargetState(state.weeklyActivities, action),
    }
  }

  if (action.type === 'RECEIVE_DAILY_STATISTICS') {
    return {
      ...state,
      isFetching: false,
      dailyActivities: updateTargetState(state.dailyActivities, action),
    }
  }

  if (action.type === 'RECEIVE_SCENARIO_USAGE') {
    return {
      ...state,
      isFetching: false,
      scenarioUsages: updateTargetState(state.scenarioUsages, action),
    }
  }

  if (action.type === 'RECEIVE_FAQ_USAGE') {
    return {
      ...state,
      isFetching: false,
      faqUsages: updateTargetState(state.faqUsages, action),
    }
  }

  return state
}

export default reducer
