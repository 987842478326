import moment from 'moment'

const initialState = { news: [], importantMessages: [] }

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RECEIVE_NEWS':
      return { ...state, news: [...action.news] }
    case 'RECEIVE_IMPORTANT_MESSAGES': {
      const isEnabled = message => {
        if (message.start && moment().isBefore(message.start)) return false
        if (message.end && moment().isAfter(message.end)) return false
        return true
      }
      const messages = action.messages.filter(isEnabled).map(message => message.text)
      return { ...state, importantMessages: [...messages] }
    }
    default:
      return state
  }
}

export default reducer
