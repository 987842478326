import lodash from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import classnames from 'classnames'

import { getCredentials } from '../../helpers/sessionHelper'
import DataBindingTable from '../../components/common/DataBindingTable'
import { fetchApplications } from '../../actions/application'
import { updateTableState } from '../../actions/table'
import { addNotice } from '../../actions/notice'
import { isFetching } from '../../helpers/selector'

class ApplicationIndex extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    applications: PropTypes.array.isRequired,
    isFetching: PropTypes.bool,
    tableState: PropTypes.object,
    maxApplications: PropTypes.number,
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    dispatch(fetchApplications(state.session.token))
  }

  handleRefreshClick = () => {
    const state = this.context.store.getState()
    this.props.dispatch(fetchApplications(state.session.token))
  }

  handleAddClick = () => {
    const { t, router } = this.context
    const { dispatch, applications, maxApplications } = this.props

    if (maxApplications && applications.length >= maxApplications) {
      dispatch(addNotice('warn', t('application.applicationsLimitation', { maximum: maxApplications })))
      return
    }

    router.push('/applications/new')
  }

  updateTableState = (path, tableName, tableState) => {
    const { dispatch } = this.props
    dispatch(updateTableState(path, tableName, tableState))
  }

  render() {
    const { t } = this.context
    const { applications, isFetching, tableState, maxApplications } = this.props
    const { timezone } = getCredentials(this.context)
    // TODO: support Tooltip on table header
    // headerTooptips = [undefined, undefined, 'application.tooltip.status', 'application.tooltip.logs_page', 'application.tooltip.download_logs']

    const isPossibleToAdd = maxApplications == null || maxApplications > applications.length
    const addButtonClassNames = classnames({
      'dm-btn': true,
      btn: true,
      'btn-icon-plus': true,
      mini: true,
      'btn-primary': isPossibleToAdd,
      'btn-alert': !isPossibleToAdd,
    })

    let columns = [
      {
        Header: t('application.name'),
        id: 'name',
        accessor: 'name',
        Cell: props => <Link to={`/applications/${props.original.id}`}>{props.value}</Link>,
        getFooterProps: () => ({ className: 'footer-all' }),
        Footer: (
          <div>
            <div className="pull-left">
              <Link className={addButtonClassNames} onClick={this.handleAddClick}>
                <span>{t('common.new')}</span>
              </Link>
            </div>
            {maxApplications && (
              <div className="pull-right">
                {t('application.remaining', { count: applications.length, total: maxApplications })}
              </div>
            )}
          </div>
        ),
        minWidth: 108,
      },
      {
        Header: t('application.bots'),
        id: 'botName',
        accessor: app => app.latest_bot.name,
      },
      {
        Header: t('application.platform'),
        id: 'platform',
        accessor: app => t(`common.platform.${app.platform}`),
      },
      {
        Header: t('application.status'),
        id: 'botStatus',
        accessor: app => {
          const statusText = {
            true: {
              enqueued: t('application.training.inRetraining'),
              publishing: t('application.training.inRetraining'),
              published: t('application.training.finished'),
              aborted: t('application.training.finished'),
              error: t('application.training.trainError'),
            },
            false: {
              enqueued: t('application.training.inTraining'),
              publishing: t('application.training.inTraining'),
              published: t('application.training.finished'),
              aborted: t('application.training.untrained'),
              error: t('application.training.trainError'),
            },
          }
          return statusText[app.is_published][app.status]
        },
        minWidth: 60,
      },
      {
        Header: t('application.lastPublishedBy'),
        id: 'lastPublishedBy',
        accessor: app => app.last_published_by.name || '',
        width: 160,
      },
      {
        Header: t('application.lastPublishedAt'),
        id: 'publishedAt',
        accessor: app =>
          app.published_at ? moment.tz(app.published_at, timezone).format('YYYY/MM/DD HH:mm') : '',
        width: 160,
      },
    ]

    return (
      <div>
        <DataBindingTable
          title={t('application.title')}
          titleTooltip="application.tooltip.title"
          items={applications}
          columns={columns}
          isFetching={isFetching}
          tableState={tableState}
          updateTableState={this.updateTableState}
          onRefresh={this.handleRefreshClick}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const account = lodash.first(Object.values(state.entities.accounts)) || {}

  const ids = state.pagination.applications.ids || []
  const applications = ids.map(id => state.entities.applications[id])

  return {
    applications: applications,
    isFetching: isFetching(state),
    tableState: state.table[window.location.pathname],
    maxApplications: account.max_applications,
  }
}

export default connect(mapStateToProps)(ApplicationIndex)
