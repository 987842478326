import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, propTypes, getFormValues } from 'redux-form'

import { fetchAccount } from '../../actions/account'
import { clearClassify, classifyTopics, classifyFaqItems } from '../../actions/classifier'
import { fetchTopic, updateTopic } from '../../actions/topic'
import { fetchFaqCategories } from '../../actions/faq_category'
import { fetchFaqItem, fetchFaqItems, updateFaqItemWithoutRouting } from '../../actions/faq_item'
import { addNotice } from '../../actions/notice'
import Loader from '../../components/common/Loader'
import ClassifierComponent from '../../components/simulator/Classifier'
import { rejectReadonlyUser } from '../../helpers/permission'

const validate = data => {
  const errors = {}
  if (!data.query) {
    errors.query = 'validate.required'
  } else if (data.query.length > 280) {
    errors.name = { id: 'validate.exceededMaxLength', values: { length: 280 } }
  }

  return errors
}

export class Classifier extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
    formValues: PropTypes.object,
    type: PropTypes.string.isRequired,
    domain: PropTypes.object,
    faq: PropTypes.object,
    topics: PropTypes.array,
    items: PropTypes.array,
    maxScenarios: PropTypes.number,
    maxFaqItems: PropTypes.number,
    results: PropTypes.array,
    currentPageItemId: PropTypes.string,
    isCurrentPageFormDirty: PropTypes.bool,
  }

  constructor() {
    super()
    this.state = { query: '' }
  }

  componentWillMount() {
    this.props.dispatch(clearClassify())
  }

  componentDidMount() {
    const { type, faq, dispatch } = this.props
    const state = this.context.store.getState()

    dispatch(fetchAccount(state.session.token))

    if (type === 'faq') {
      dispatch(fetchFaqCategories(state.session.token, faq.id))
      dispatch(fetchFaqItems(state.session.token, faq.id))
    }
  }

  handleClassify = (data, dispatch) => {
    const { type, domain, faq } = this.props
    const state = this.context.store.getState()
    this.setState({ query: data.query })
    this.props.reset()
    if (type === 'topic') {
      return dispatch(classifyTopics(state.session.token, domain.id, data.query))
    } else {
      return dispatch(classifyFaqItems(state.session.token, faq.id, data.query))
    }
  }

  /** Add topicClassifier.query to topic.examples */
  handleUpdateFaqItem = (faqItem_id, query, notice = true) => {
    const { faq } = this.props
    const { t } = this.context
    const state = this.context.store.getState()
    const { dispatch, reset, currentPageItemId, isCurrentPageFormDirty } = this.props

    // validate for faqItem_id not found
    if (typeof faqItem_id === 'undefined') {
      return notice
        ? this.props.dispatch(addNotice('error', t('simulator.faqClassifier.faqItemIdNotFound')))
        : false
    }

    if (isCurrentPageFormDirty && currentPageItemId === faqItem_id.toString()) {
      if (!window.confirm(t('simulator.faqClassifier.confirmMessage'))) return
    }

    dispatch(fetchFaqItem(state.session.token, faq.id, faqItem_id)).then(response => {
      const faqItem = response.entities.faq_items[faqItem_id]
      // Error handling for there exists same question as query.
      if (faqItem.questions.includes(query.trim())) {
        return notice ? dispatch(addNotice('error', t('simulator.faqClassifier.sameQuestionExists'))) : false
        // Update the topic
      } else {
        faqItem.questions.push(query.trim())
        return dispatch(
          updateFaqItemWithoutRouting(state.session.token, faq.id, faqItem.id, {
            questions: faqItem.questions,
          })
        )
          .then(() => {
            return notice ? dispatch(addNotice('info', t('simulator.faqClassifier.addedQuery'))) : false
          })
          .then(() => reset()) // select field reset
      }
    })
  }

  handleAddNewTopic = query => {
    const { t, router } = this.context
    const { dispatch, topics, domain, maxScenarios } = this.props

    if (rejectReadonlyUser(dispatch, this.context)) return

    if (topics.length >= maxScenarios) {
      dispatch(addNotice('warn', t('topic.topicsLimitation', { maximum: maxScenarios })))
      return
    }

    router.push({ pathname: `/bots/${domain.bot_id}/topics/new`, search: `?example=${query}` })
  }

  handleAddNewFaqItem = query => {
    const { t, router } = this.context
    const { dispatch, items, faq, maxFaqItems } = this.props

    if (rejectReadonlyUser(dispatch, this.context)) return

    if (items.length >= maxFaqItems) {
      dispatch(addNotice('warn', t('faqCategory.faqItemsLimitation', { maximum: maxFaqItems })))
      return
    }

    router.push({ pathname: `/bots/${faq.bot_id}/faqs/${faq.id}/items/new`, search: `?example=${query}` })
  }

  /** Add topicClassifier.query to topic.examples */
  handleUpdateTopic = (topic_id, query, notice = true) => {
    const { t } = this.context
    const state = this.context.store.getState()
    const { dispatch, reset, currentPageItemId, isCurrentPageFormDirty } = this.props

    // validate for topic_id not found
    if (typeof topic_id === 'undefined') {
      return notice
        ? this.props.dispatch(addNotice('error', t('simulator.topicClassifier.topicIdNotFound')))
        : false
    }

    if (isCurrentPageFormDirty && currentPageItemId === topic_id.toString()) {
      if (!window.confirm(t('simulator.topicClassifier.confirmMessage'))) return
    }

    dispatch(fetchTopic(state.session.token, topic_id)).then(response => {
      const topic = response.entities.topics[topic_id]
      // Error handling for there exists same example as query.
      if (topic.examples.includes(query.trim())) {
        return notice ? dispatch(addNotice('error', t('simulator.topicClassifier.sameExampleExists'))) : false
        // Update the topic
      } else {
        const payload = { id: topic.id, examples: lodash.concat(topic.examples, query.trim()) }
        return dispatch(updateTopic(state.session.token, payload))
          .then(() => {
            const key = topic.is_draft ? 'addedQueryToDraft' : 'addedQuery'
            return notice ? dispatch(addNotice('info', t(`simulator.topicClassifier.${key}`))) : false
          })
          .then(() => reset()) // select field reset
      }
    })
  }

  render() {
    const {
      type,
      submitting,
      handleSubmit,
      domain,
      topics,
      faq,
      results,
      formValues,
      items,
      maxScenarios,
      maxFaqItems,
    } = this.props
    if (!domain && !faq) return null
    return (
      <div className="classifier-scroll">
        <Loader loaded={!submitting} type="show">
          <form onSubmit={handleSubmit(this.handleClassify)}>
            <ClassifierComponent
              type={type}
              query={this.state.query}
              results={results}
              formValues={formValues}
              domain={domain}
              faq={faq}
              topics={topics}
              items={items}
              maxScenarios={maxScenarios}
              maxFaqItems={maxFaqItems}
              submitting={submitting}
              handleUpdateTopic={this.handleUpdateTopic}
              handleUpdateFaqItem={this.handleUpdateFaqItem}
              handleAddNewTopic={this.handleAddNewTopic}
              handleAddNewFaqItem={this.handleAddNewFaqItem}
            />
          </form>
        </Loader>
      </div>
    )
  }
}

const ClassifierForm = reduxForm({
  form: 'Classifier',
  validate,
})(Classifier)

export const mapStateToProps = (state, props) => {
  const account = lodash.first(Object.values(state.entities.accounts)) || {}
  const results = state.classifier.results
  const topics = lodash.filter(state.entities.topics, { type: 'normal' })
  const formValues = getFormValues('Classifier')(state) || {}

  const faq = props.faq || {}
  const items = lodash.filter(state.entities.faq_items, { faq_id: faq.id })

  return {
    results,
    formValues,
    topics,
    items,
    maxScenarios: account.max_scenarios,
    maxFaqItems: account.max_faq_items,
  }
}

export default connect(mapStateToProps)(ClassifierForm)
