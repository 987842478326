import lodash from 'lodash'
import { deleteCache } from './api'
import uuid from 'uuid'
import Schemas from '../middleware/schema'
import request from '../helpers/requestManagementAPI'

export const fetchTasks = (token, filter = {}) => dispatch => {
  return request.get('/tasks/', {
    token,
    dispatch,
    body: filter,
    target: 'tasks',
    schema: Schemas.tasks,
    preserve: false,
  })
}

export const fetchTask = (token, id, errorHandler) => dispatch => {
  return request.get(`/tasks/${id}`, {
    token,
    dispatch,
    target: 'tasks',
    schema: Schemas.task,
    formatter: task => {
      task.actions = lodash.map(task.actions, action => ({ ...action, uuid: uuid.v4() }))
      return task
    },
    errorHandler,
  })
}

export const createTask = (token, task) => dispatch => {
  return request.post('/tasks/', {
    token,
    dispatch,
    body: task,
    target: 'tasks',
    schema: Schemas.task,
    formatter: task => {
      task.actions = lodash.map(task.actions, action => ({ ...action, uuid: uuid.v4() }))
      return task
    },
  })
}

export const updateTask = (token, task) => dispatch => {
  return request.put(`/tasks/${task.id}`, {
    token,
    dispatch,
    body: task,
    target: 'tasks',
    schema: Schemas.task,
    formatter: task => {
      task.actions = lodash.map(task.actions, action => ({ ...action, uuid: uuid.v4() }))
      return task
    },
  })
}

export const deleteTask = (token, id) => dispatch => {
  return request
    .delete(`/tasks/${id}`, {
      token,
      dispatch,
      target: 'tasks',
    })
    .then(() => dispatch(deleteCache('tasks', id)))
}
