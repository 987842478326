import { deleteCache } from './api'
import Schemas from '../middleware/schema'
import request from '../helpers/requestManagementAPI'

export const fetchEndpoints = (token, filter = {}) => dispatch => {
  return request.get('/endpoints/', {
    token,
    dispatch,
    body: filter,
    target: 'endpoints',
    schema: Schemas.endpoints,
    preserve: false,
  })
}

export const fetchEndpoint = (token, id, errorHandler) => dispatch => {
  return request.get(`/endpoints/${id}`, {
    token,
    dispatch,
    target: 'endpoints',
    schema: Schemas.endpoint,
    errorHandler,
  })
}

export const createEndpoint = (token, endpoint) => dispatch => {
  return request.post('/endpoints/', {
    token,
    dispatch,
    body: endpoint,
    target: 'endpoints',
    schema: Schemas.endpoint,
  })
}

export const updateEndpoint = (token, endpoint) => dispatch => {
  return request.put(`/endpoints/${endpoint.id}`, {
    token,
    dispatch,
    body: endpoint,
    target: 'endpoints',
    schema: Schemas.endpoint,
  })
}

export const deleteEndpoint = (token, id) => dispatch => {
  return request
    .delete(`/endpoints/${id}`, {
      token,
      dispatch,
      target: 'endpoints',
    })
    .then(() => dispatch(deleteCache('endpoints', id)))
}
