import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes } from 'redux-form'

import { addNotice } from '../../actions/notice'
import { importTopicCSV } from '../../actions/bot'
import { CheckboxField, FileField } from '../../components/common/fields/FormFields'
import Sidebar from '../../components/common/Sidebar'
import Loader from '../../components/common/Loader'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import { Simulator } from '../simulator/Simulator'

import { checkApplications } from '../../helpers/checkApplications'
import { isDirtyForm } from '../../helpers/cancelurl'
import { fetchApplications } from '../../actions/application'
import { fetchBot } from '../../actions/bot'
import { fetchDomains } from '../../actions/domain'
import { downloadBlob } from '../../helpers/download'

export class DomainImport extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
    applications: PropTypes.array,
    bot: PropTypes.object,
    topics: PropTypes.array,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }

  componentDidMount() {
    const {
      dispatch,
      params: { id },
    } = this.props
    const state = this.context.store.getState()
    dispatch(fetchApplications(state.session.token, { original_bot_id: id }))
    dispatch(fetchBot(state.session.token, id))
    dispatch(fetchDomains(state.session.token, { bot_id: id }))
  }

  handleSave = (data, dispatch) => {
    const { t } = this.context
    if (!data.upload_file || !data.upload_file[0]) {
      dispatch(addNotice('error', t('bot.noFileSelectedError')))
      return
    }

    // Validate upload size
    const fileSize = data.upload_file[0].size / 1024 / 1024
    if (fileSize > 3) {
      dispatch(addNotice('error', t('validate.invalidFileSize', { size: 3 })))
      return
    }

    const state = this.context.store.getState()
    const router = this.context.router
    const {
      topics,
      params: { id },
      bot,
    } = this.props

    if (data.delete) {
      let message = t('bot.import.messages.confirm')
      const supplements = []
      if (bot.default_behavior !== 'none') {
        supplements.push(t('bot.import.messages.disableDefaultBehavior'))
      }
      if (bot.use_first_topic) {
        supplements.push(t('bot.import.messages.disableUseFirstTopic'))
      }
      if (!lodash.isEmpty(supplements)) {
        message = message + '\n\n' + supplements.join('\n')
      }
      if (!window.confirm(message)) return
    }

    const request_body = new FormData()
    request_body.append('file', data.upload_file[0])
    request_body.append('delete', !!data.delete)
    return dispatch(importTopicCSV(state.session.token, id, topics, request_body))
      .then(json => router.push(`/bots/${json.result}`))
      .then(() => dispatch(addNotice('info', t('common.importSuccessMessage'))))
      .then(() => checkApplications(bot.id, this.props.applications, dispatch, this.context))
      .catch(() => {
        document.getElementById('upload_file').value = ''
      })
  }

  downloadFile = (e, filename) => {
    e.preventDefault()

    const data = new XMLHttpRequest()
    data.open('GET', e.target.href)
    data.responseType = 'blob'
    data.send()
    data.onload = () => {
      downloadBlob(data.response, filename)
    }
  }

  render() {
    const { t } = this.context
    const { submitting, handleSubmit, bot, domain } = this.props

    return (
      <div>
        <Loader loaded={!submitting} type="show">
          <form
            className="text-left col-md-9"
            ref="upload_form"
            encType="multipart/form-data"
            onSubmit={handleSubmit(this.handleSave)}
          >
            <div className="form-group">
              <LabelWithTooltip htmlFor="file" className="dm-title" name="bot.import.data" />
              <Field
                type="file"
                name="upload_file"
                className="form-control dm-form-control"
                component={FileField}
              />
            </div>
            <div className="form-group form-inline">
              <Field type="checkbox" name="delete" id="delete" component={CheckboxField} />
              <LabelWithTooltip htmlFor="delete" className="dm-checkbox" name="bot.import.delete" />
            </div>
            <div>
              <a
                href="/files/scenario_sample_v3.csv"
                onClick={e => this.downloadFile(e, 'scenario_sample.csv')}
              >
                {t('bot.import.downloadSampleCSV')}
              </a>
              <span className="ml-3">
                (
                <a
                  href="/files/scenario_sample_v1.csv"
                  onClick={e => this.downloadFile(e, 'scenario_sample_old.csv')}
                >
                  {t('bot.import.downloadSampleCSVOld')}
                </a>
                )
              </span>
            </div>
            <div className="dm-example small">
              <table className="table table-sm table-bordered">
                <tbody>
                  <tr className="row">
                    <td className="col-sm-4">{t('bot.import.example.fieldName.categories')}</td>
                    <td className="col-sm-8">{t('bot.import.example.description.categories')}</td>
                  </tr>
                  <tr className="row">
                    <td className="col-sm-4">{t('bot.import.example.fieldName.scenarioName')}</td>
                    <td className="col-sm-8">{t('bot.import.example.description.scenarioName')}</td>
                  </tr>
                  <tr className="row">
                    <td className="col-sm-4">{t('bot.import.example.fieldName.examples')}</td>
                    <td className="col-sm-8">{t('bot.import.example.description.examples')}</td>
                  </tr>
                  <tr className="row">
                    <td className="col-sm-4">{t('bot.import.example.fieldName.answers')}</td>
                    <td className="col-sm-8">{t('bot.import.example.description.answers')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="form-group text-right">
              <button type="submit" className="btn btn-primary" disabled={submitting}>
                {t('common.import')}
              </button>
            </div>
          </form>
          <Sidebar titles={[t('simulator.title')]}>
            <Simulator
              tabs={['topicClassifier', 'chat']}
              bot={bot}
              domain={domain}
              currentPageItemId={this.props.params.id}
              isCurrentPageFormDirty={isDirtyForm(this)}
            />
          </Sidebar>
        </Loader>
      </div>
    )
  }
}

const DomainImportForm = reduxForm({
  form: 'DomainImport',
  enableReinitialize: true,
})(DomainImport)

const mapStateToProps = (state, props) => {
  const applications = lodash.filter(state.entities.applications)
  const bot = lodash.find(state.entities.bots, { id: parseInt(props.params.id, 10) })
  const domains = lodash.filter(state.entities.domains, { bot_id: parseInt(props.params.id, 10) }) || {}
  const domainIds = domains.map(domain => domain.id)
  const topics =
    lodash.filter(state.entities.topics, topic => lodash.includes(domainIds, topic.domain_id)) || []

  return {
    initialValues: { delete: false },
    applications,
    bot,
    topics,
  }
}

export default connect(mapStateToProps)(DomainImportForm)
