import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, propTypes, formValueSelector } from 'redux-form'

import { deleteCache } from '../../actions/api'
import { addNotice } from '../../actions/notice'
import { fetchAccount } from '../../actions/account'
import { fetchPredictionFailureLogs } from '../../actions/application'
import { createLearningExclusionWord } from '../../actions/bot'
import { fetchTopics, fetchTopic, updateTopic } from '../../actions/topic'
import { fetchFaqs } from '../../actions/faq'
import { fetchFaqItem, fetchFaqItems, updateFaqItemWithoutRouting } from '../../actions/faq_item'
import PredictionFailureLogsComponent from '../../components/analytics/PredictionFailureLogs'
import { rejectReadonlyUser } from '../../helpers/permission'

export class PredictionFailureLogs extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    application: PropTypes.object,
    botId: PropTypes.number,
    faqs: PropTypes.array,
    intentType: PropTypes.string,
    intents: PropTypes.array,
    selectedFaqId: PropTypes.number,
    predictionFailureLogs: PropTypes.array,
    maxScenarios: PropTypes.number,
    maxFaqItems: PropTypes.number,
    isFetching: PropTypes.bool,
    handleExclusionsOpen: PropTypes.func,
  }

  static defaultProps = {
    isFetching: false,
    predictionFailureLogs: [],
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch, application, intentType, selectedFaqId } = this.props

    dispatch(fetchAccount(state.session.token))
    if (application) {
      const botId = application.latest_bot.original_id || application.latest_bot.id
      dispatch(fetchTopics(state.session.token, { bot_id: botId }))
      dispatch(fetchFaqs(state.session.token, { bot_id: botId })).then(response => {
        return new Promise(resolve => {
          Promise.all(
            response.result.map(faq_id => {
              return Promise.resolve(dispatch(fetchFaqItems(state.session.token, faq_id)))
            })
          ).then(() => resolve(response.result))
        })
      })
      if (intentType === 'topic') {
        dispatch(fetchPredictionFailureLogs(state.session.token, application.id, 'topic'))
      } else if (intentType === 'faq' && selectedFaqId) {
        dispatch(fetchPredictionFailureLogs(state.session.token, application.id, 'faq_item', selectedFaqId))
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.intentType !== this.props.intentType ||
      prevProps.selectedFaqId !== this.props.selectedFaqId
    ) {
      this.onRefresh()
    }
  }

  /** Delete and re-fetch all state of prediction failure logs */
  onRefresh = () => {
    const state = this.context.store.getState()
    const { dispatch, isFetching, application, intentType, selectedFaqId } = this.props
    if (!isFetching) {
      if (intentType === 'topic') {
        Promise.resolve()
          .then(() => dispatch(deleteCache('prediction_failure_logs')))
          .then(() => dispatch(fetchPredictionFailureLogs(state.session.token, application.id, 'topic')))
      } else if (intentType === 'faq' && selectedFaqId) {
        Promise.resolve()
          .then(() => dispatch(deleteCache('prediction_failure_logs')))
          .then(() =>
            dispatch(
              fetchPredictionFailureLogs(state.session.token, application.id, 'faq_item', selectedFaqId)
            )
          )
      } else {
        Promise.resolve().then(() => dispatch(deleteCache('prediction_failure_logs')))
      }
    }
  }

  /** Add the query to the topic, and delete the log from state */
  insertExampleToTopic = (topicId, query, notice = true) => {
    const { t } = this.context
    const state = this.context.store.getState()
    const { dispatch, predictionFailureLogs } = this.props
    const clippedQuery = query.trim().substring(0, 280)

    dispatch(fetchTopic(state.session.token, topicId)).then(response => {
      const topic = response.entities.topics[topicId]
      // Error handling for there exists same example as query.
      if (topic.examples.includes(clippedQuery)) {
        return notice
          ? dispatch(addNotice('error', t('analytics.predictionFailureLogs.sameExampleExists')))
          : false
        // Update the topic
      } else {
        const payload = { id: topic.id, examples: lodash.concat(topic.examples, clippedQuery) }
        return dispatch(updateTopic(state.session.token, payload))
          .then(() => {
            return notice
              ? dispatch(addNotice('info', t('analytics.predictionFailureLogs.addedQuery')))
              : false
          })
          .then(() => {
            // delete the log from state
            const target = predictionFailureLogs
              .filter(log => log.query === query)
              .map(log => log.message_uuid)
            dispatch(deleteCache('prediction_failure_logs', target))
          })
      }
    })
  }

  insertQuestionToFaqItem = (faqItemId, query, notice = true) => {
    const { t } = this.context
    const state = this.context.store.getState()
    const { dispatch, selectedFaqId, predictionFailureLogs } = this.props
    const clippedQuery = query.trim().substring(0, 280)

    dispatch(fetchFaqItem(state.session.token, selectedFaqId, faqItemId)).then(response => {
      const faqItem = response.entities.faq_items[faqItemId]
      if (faqItem.questions.includes(clippedQuery)) {
        // Error handling for there exists same example as query.
        return notice
          ? dispatch(addNotice('error', t('analytics.predictionFailureLogs.sameExampleExists')))
          : false
      } else {
        const payload = { questions: lodash.concat(faqItem.questions, clippedQuery) }
        return dispatch(updateFaqItemWithoutRouting(state.session.token, selectedFaqId, faqItemId, payload))
          .then(() => {
            return notice
              ? dispatch(addNotice('info', t('analytics.predictionFailureLogs.addedQuery')))
              : false
          })
          .then(() => {
            // delete the log from state
            const target = predictionFailureLogs
              .filter(log => log.query === query)
              .map(log => log.message_uuid)
            dispatch(deleteCache('prediction_failure_logs', target))
          })
      }
    })
  }

  handleInsertQueryToLinkedIntent = (intentId, query, notice = true) => {
    const { t } = this.context
    const { dispatch, intentType } = this.props
    // validate intentId
    if (!intentId) {
      if (notice) {
        dispatch(addNotice('error', t('analytics.predictionFailureLogs.topicIdNotFound')))
      }
      return false
    }
    // insert query to intent
    if (intentType === 'topic') {
      this.insertExampleToTopic(intentId, query, notice)
    } else if (intentType === 'faq') {
      this.insertQuestionToFaqItem(intentId, query, notice)
    }
  }

  handleInsertQueryToSelectedIntent = (message_uuid, query, notice = true) => data => {
    const { intentType } = this.props
    const intentId = data.selectedIntent ? data.selectedIntent[message_uuid] : undefined
    // validate intentId
    if (!intentId) {
      return false
    }
    // insert query to intent
    if (intentType === 'topic') {
      this.insertExampleToTopic(intentId, query, notice)
    } else if (intentType === 'faq') {
      this.insertQuestionToFaqItem(intentId, query, notice)
    }
  }

  handleAddNewIntent = query => {
    const { dispatch, intentType } = this.props

    if (rejectReadonlyUser(dispatch, this.context)) return

    if (intentType === 'topic') {
      this.handleAddNewTopicIntent(query)
    } else {
      this.handleAddNewFaqItemIntent(query)
    }
  }

  handleAddNewTopicIntent = query => {
    const { t, router } = this.context
    const { dispatch, botId, intents, maxScenarios } = this.props
    const clippedQuery = query.trim().substring(0, 280)

    if (intents.length >= maxScenarios) {
      dispatch(addNotice('warn', t('topic.topicsLimitation', { maximum: maxScenarios })))
      return
    }

    router.push({ pathname: `/bots/${botId}/topics/new`, search: `?example=${clippedQuery}` })
  }

  handleAddNewFaqItemIntent = query => {
    const { t, router } = this.context
    const { dispatch, botId, intents, selectedFaqId, maxFaqItems } = this.props
    const clippedQuery = query.trim().substring(0, 280)

    if (intents.length >= maxFaqItems) {
      dispatch(addNotice('warn', t('faqCategory.faqItemsLimitation', { maximum: maxFaqItems })))
      return
    }

    router.push({
      pathname: `/bots/${botId}/faqs/${selectedFaqId}/items/new`,
      search: `?example=${clippedQuery}`,
    })
  }

  handleAddLearningExclusionWord = query => (data, dispatch) => {
    const state = this.context.store.getState()
    const { t } = this.context
    const { application, intentType, selectedFaqId } = this.props
    const botId = application.latest_bot.original_id || application.latest_bot.id
    const learningExclusionWord = {
      pattern: query,
    }
    return dispatch(createLearningExclusionWord(state.session.token, botId, learningExclusionWord, false))
      .then(() => dispatch(addNotice('info', t('analytics.learningExclusionWord.addedExclusionWord'))))
      .then(() => dispatch(deleteCache('prediction_failure_logs')))
      .then(() => {
        if (intentType === 'topic') {
          dispatch(fetchPredictionFailureLogs(state.session.token, application.id, 'topic'))
        } else if (intentType === 'faq' && selectedFaqId) {
          dispatch(fetchPredictionFailureLogs(state.session.token, application.id, 'faq_item', selectedFaqId))
        }
      })
  }

  render() {
    const {
      application,
      botId,
      faqs,
      intentType,
      intents,
      selectedFaqId,
      predictionFailureLogs,
      maxScenarios,
      maxFaqItems,
      isFetching,
      submitting,
      handleSubmit,
      handleExclusionsOpen,
    } = this.props

    const maxIntents = intentType === 'topic' ? maxScenarios : maxFaqItems
    return (
      <PredictionFailureLogsComponent
        application={application}
        botId={botId}
        faqs={faqs}
        intentType={intentType}
        intents={intents}
        selectedFaqId={selectedFaqId}
        predictionFailureLogs={predictionFailureLogs}
        maxIntents={maxIntents}
        isFetching={isFetching}
        isSubmitting={submitting}
        onRefresh={this.onRefresh}
        handleSubmit={handleSubmit}
        handleAddNewIntent={this.handleAddNewIntent}
        handleInsertQueryToLinkedIntent={this.handleInsertQueryToLinkedIntent}
        handleInsertQueryToSelectedIntent={this.handleInsertQueryToSelectedIntent}
        handleAddLearningExclusionWord={this.handleAddLearningExclusionWord}
        handleExclusionsOpen={handleExclusionsOpen}
      />
    )
  }
}

const PredictionFailureLogsForm = reduxForm({
  form: 'PredictionFailureLogs',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(PredictionFailureLogs)

const selector = formValueSelector('PredictionFailureLogs')

export const mapStateToProps = (state, props) => {
  const account = lodash.first(Object.values(state.entities.accounts)) || {}
  const application = props.application
  const botId = application ? application.latest_bot.original_id || application.latest_bot.id : undefined
  const domains = botId ? lodash.filter(state.entities.domains, { bot_id: botId }) : undefined
  const faqs = botId ? lodash.filter(state.entities.faqs, { bot_id: botId }) : []
  const intentType = selector(state, 'intent_type') || 'topic'
  const selectedFaqId = selector(state, 'faq_id') ? parseInt(selector(state, 'faq_id'), 10) : undefined

  let intents = []
  if (intentType === 'topic' && domains) {
    intents = lodash.filter(state.entities.topics, topic => {
      return lodash.find(domains, { id: topic.domain_id }) && topic.type === 'normal'
    })
  } else if (intentType === 'faq' && selectedFaqId) {
    intents = lodash.filter(state.entities.faq_items, { faq_id: selectedFaqId })
  }

  let predictionFailureLogs = []
  if (!props.isFetching) {
    const intentTypeOnLog = intentType === 'faq' ? 'faq_item' : 'topic'
    predictionFailureLogs =
      lodash.filter(state.entities.prediction_failure_logs, {
        application_uuid: application.uuid,
        type: intentTypeOnLog,
      }) || []
    predictionFailureLogs = lodash.orderBy(predictionFailureLogs, 'timestamp', 'desc')
  }

  return {
    ...props,
    application: application,
    botId: botId,
    faqs: faqs,
    intentType: intentType,
    intents: intents,
    selectedFaqId: selectedFaqId,
    predictionFailureLogs: predictionFailureLogs,
    maxScenarios: account.max_scenarios,
    maxFaqItems: account.max_faq_items,
  }
}

export default connect(mapStateToProps)(PredictionFailureLogsForm)
