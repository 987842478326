import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes } from 'redux-form'
import { InputField } from '../common/fields/FormFields'
import LabelWithTooltip from '../common/LabelWithTooltip'
import Loader from '../common/Loader'
import { isFetching } from '../../helpers/selector'

const validate = data => {
  const errors = {}
  if (!data.target_name) {
    errors.target_name = 'validate.required'
  } else if (data.target_name !== data.dummy_target_name) {
    if (data.target_type === 'bot') {
      errors.target_name = 'validate.mismatchedBotName'
    } else if (data.target_type === 'application') {
      errors.target_name = 'validate.mismatchedApplicationName'
    }
  }
  return errors
}

export class DeleteConfirm extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    ...propTypes,
    targetName: PropTypes.string,
    targetType: PropTypes.string,
    errorMessage: PropTypes.string,
    onDeleteConfirmSubmit: PropTypes.func,
    onDeleteConfirmClose: PropTypes.func,
  }

  renderErrorMessage = () => {
    if (this.props.errorMessage === '') return
    return <div className="dm-error-message pb-4">{this.props.errorMessage}</div>
  }

  render() {
    const { t } = this.context
    const { handleSubmit, isFetching, targetType, targetName } = this.props

    return (
      <div className="modal-background" onClick={this.props.onDeleteConfirmClose}>
        <div className="modal-dialog" role="document" onClick={e => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.onDeleteConfirmClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title">{t('deleteConfirm.title', { name: targetName })}</h4>
            </div>
            <Loader loaded={!isFetching} type="show">
              <form className="text-left" onSubmit={handleSubmit(this.props.onDeleteConfirmSubmit)}>
                <div className="modal-body">
                  <div className="results">
                    {this.renderErrorMessage()}
                    <div className="pb-2">
                      {t(`deleteConfirm.${targetType}.body`)}
                      {targetType === 'application' && <p>{t('deleteConfirm.application.remark')}</p>}
                    </div>
                    <Field type="hidden" name="target_type" component={InputField} />
                    <Field type="hidden" name="dummy_target_name" component={InputField} />
                    <div className="form-group dm-form-group" key="name">
                      <LabelWithTooltip
                        htmlFor="target_name"
                        name={`deleteConfirm.${targetType}.targetName`}
                      />
                      <Field
                        type="text"
                        name="target_name"
                        className="form-control dm-form-control"
                        component={InputField}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-danger dm-btn" disabled={isFetching}>
                    {t('common.delete')}
                  </button>
                  <button
                    type="button"
                    className="btn btn-alert dm-btn"
                    onClick={this.props.onDeleteConfirmClose}
                    disabled={isFetching}
                  >
                    {t('deleteConfirm.cancel')}
                  </button>
                </div>
              </form>
            </Loader>
          </div>
        </div>
      </div>
    )
  }
}

const DeleteConfirmForm = reduxForm({
  form: 'DeleteConfirm',
  enableReinitialize: true,
  validate,
})(DeleteConfirm)

export const mapStateToProps = (state, props) => {
  let defaultValues = {
    dummy_target_name: props.targetName,
    target_type: props.targetType,
    target_name: '',
  }
  return {
    isFetching: isFetching(state),
    initialValues: defaultValues,
  }
}

export default connect(mapStateToProps)(DeleteConfirmForm)
