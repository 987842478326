import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'

import { addNotice } from '../../actions/notice'
import DataBindingTable from '../../components/common/DataBindingTable'
import { updateTableState } from '../../actions/table'
import { fetchUsers, sendMail } from '../../actions/user'
import { convertReminingTime } from '../../helpers/remainingTime'
import { isFetching } from '../../helpers/selector'

class UserIndex extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
    isFetching: PropTypes.bool,
    tableState: PropTypes.object,
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    dispatch(fetchUsers(state.session.token))
  }

  handleRefreshClick = () => {
    const state = this.context.store.getState()
    this.props.dispatch(fetchUsers(state.session.token))
  }

  handleAddClick = () => {
    this.context.router.push('/users/new')
  }

  resendConfirmationMail = id => {
    const { t } = this.context
    const session = this.context.store.getState().session
    const { dispatch } = this.props

    dispatch(sendMail(session.token, id)).then(() =>
      dispatch(addNotice('info', t('account.emailAddresses.sendConfirmationMessage')))
    )
  }

  updateTableState = (path, tableName, tableState) => {
    const { dispatch } = this.props
    dispatch(updateTableState(path, tableName, tableState))
  }

  render() {
    const { t } = this.context
    const session = this.context.store.getState().session
    const { users, isFetching, tableState } = this.props
    let columns = [
      {
        Header: t('user.email'),
        id: 'email',
        accessor: 'email',
        Cell: props => (
          <Link to={`/users/${props.original.id}`}>{props.value || props.original.pending_email}</Link>
        ),
        getFooterProps: () => ({ className: 'footer-all' }),
      },
      {
        Header: t('user.name'),
        id: 'name',
        accessor: 'name',
      },
      {
        Header: t('user.role'),
        id: 'role',
        accessor: user => t(`common.roles.${user.role}`),
      },
      {
        Header: t('user.emailConfirmation'),
        id: 'emailConfirmation',
        accessor: 'confirmed_at',
        Cell: props => {
          let text = props.value ? t('user.confirmStatus.confirmed') : t('user.confirmStatus.inConfirmation')

          const remainingTime = props.original.confirmation_token_remaining_time
          if (remainingTime !== null) {
            text = `${text} (${convertReminingTime(this.context, remainingTime)})`
          }

          return text
        },
      },
      {
        id: 'emailResend',
        width: 90,
        style: { textAlign: 'center' },
        sortable: false,
        resizable: false,
        show: lodash.some(users, ['confirmed_at', null]),
        Cell: props => {
          if (props.original.confirmed_at) {
            return <span />
          } else {
            return (
              <button
                type="button"
                className="dm-btn btn btn-primary mini"
                onClick={() => {
                  this.resendConfirmationMail(props.original.id)
                }}
              >
                {t('common.resend')}
              </button>
            )
          }
        },
      },
    ]
    if (session.role === 'owner') {
      columns[0].Footer = (
        <Link className="dm-btn btn btn-primary btn-icon-plus mini" onClick={this.handleAddClick}>
          <span>{t('common.new')}</span>
        </Link>
      )
    }
    return (
      <div>
        <DataBindingTable
          title={t('user.title')}
          titleTooltip="user.tooltip.title"
          items={users}
          columns={columns}
          isFetching={isFetching}
          tableState={tableState}
          updateTableState={this.updateTableState}
          onRefresh={this.handleRefreshClick}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const ids = state.pagination.users.ids || []
  const users = ids.map(id => state.entities.users[id])
  return {
    users: users,
    isFetching: isFetching(state),
    tableState: state.table[window.location.pathname],
  }
}

export default connect(mapStateToProps)(UserIndex)
