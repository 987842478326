import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import { TextAreaField } from '../../common/fields/FormFields'
import Tooltip from '../../common/Tooltip'

export class LabelRow extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    name: PropTypes.string,
    index: PropTypes.number,
  }

  static rowSpan = _field => {
    return 2
  }

  render() {
    const { t } = this.context
    const { name, index } = this.props

    return (
      <tr key={`${index}-label`}>
        <td colSpan="3">
          <div className="dm-table-form">
            <label>{t('topic.actionList.form.fieldTable.label')}</label>
            <Field
              name={`${name}.arguments.text`}
              minRows={2}
              className="form-control dm-form-control"
              component={TextAreaField}
            />
            <Tooltip name="topic.tooltip.actionList.form.fieldTable.label" />
          </div>
        </td>
      </tr>
    )
  }
}

export default LabelRow
