import lodash from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { fetchMonthlyActivities } from '../actions/statistics'
import { fetchApplications } from '../actions/application'
import { fetchNews } from '../actions/news'
import { getCredentials } from '../helpers/sessionHelper'
import { isFetching } from '../helpers/selector'
import LabelWithTooltip from '../components/common/LabelWithTooltip'

export class Dashboard extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    news: PropTypes.array,
    isFetching: PropTypes.bool,
    account: PropTypes.object,
    applications: PropTypes.array,
    monthlyActivities: PropTypes.object,
  }
  static defaultProps = {
    news: [],
    account: {},
  }

  constructor() {
    super()
    this.state = { showAll: false }
  }

  componentDidMount() {
    const { dispatch } = this.props
    const state = this.context.store.getState()
    const { role } = getCredentials(this.context)

    const start = moment().subtract(2, 'months').date(1).format('YYYY-MM-DD')
    const end = moment().add(1, 'months').date(0).format('YYYY-MM-DD')

    dispatch(fetchNews())
    if (lodash.includes(['owner', 'writer', 'readonly'], role)) {
      dispatch(fetchApplications(state.session.token)).then(() =>
        dispatch(fetchMonthlyActivities(state.session.token, null, start, end))
      )
    }
  }

  showAll = e => {
    e.preventDefault()
    this.setState({ showAll: true })
  }

  showLess = e => {
    e.preventDefault()
    this.setState({ showAll: false })
  }

  renderNews() {
    const { t } = this.context

    let news = this.props.news.map(this.renderNewsItem)

    const showAll = this.state.showAll || news.length <= 5
    if (!showAll) news = news.slice(0, 5)

    if (news.length === 0) {
      news = <span className="without-news">{t('dashboard.newsDoesNotExists')}</span>
    }

    return (
      <div>
        <LabelWithTooltip className="dm-title" name="dashboard.news" />
        <div className="panel-group">{news}</div>
        {!showAll && (
          <button type="button" className="show-all" onClick={this.showAll}>
            {t('dashboard.showAll')}
          </button>
        )}

        {showAll && (
          <button type="button" className="show-all" onClick={this.showLess}>
            {t('dashboard.showLess')}
          </button>
        )}
      </div>
    )
  }

  renderNewsItem = (item, index) => {
    const { timezone } = getCredentials(this.context)
    return (
      <div className="panel panel-default" key={index}>
        <a href={item.html_url} target="_blank" rel="noopener noreferrer">
          <div className="panel-heading">
            <span className="title">{item.title}</span>
            <span className="timestamp">
              {moment.tz(item.edited_at, timezone).format('YYYY/MM/DD HH:mm')}
            </span>
          </div>
        </a>
      </div>
    )
  }

  renderStatistics() {
    const { t } = this.context
    const { applications, monthlyActivities, isFetching } = this.props

    if (isFetching || lodash.isEmpty(monthlyActivities.applications)) return

    let lastUpdated = t('dashboard.statistics.doesNotUpdated')
    if (monthlyActivities.last_updated) {
      lastUpdated = moment(monthlyActivities.last_updated).format(t('dashboard.statistics.lastUpdatedFormat'))
    }
    const applicationRows = applications.map(this.renderApplicationRow)

    return (
      <div className="statistics">
        <div className="table-title">
          <LabelWithTooltip className="dm-title" name="dashboard.statistics.title" />
          <span className="lastUpdated">{lastUpdated}</span>
        </div>
        <table className="table table-striped table-bordered refreshable dm-table hover">
          {this.renderHeader()}
          <tbody>
            {applicationRows}
            {this.renderDeletedRow()}
          </tbody>
          {this.renderTotalRow()}
        </table>
      </div>
    )
  }

  renderHeader() {
    const { t } = this.context
    const now = moment()

    const periods = [
      now.clone().subtract(2, 'months'),
      now.clone().subtract(1, 'months'),
      now.clone().subtract(0, 'months'),
    ]

    const periodColumns = periods.map(period => (
      <th key={period}>{period.format(t('dashboard.statistics.periodFormat'))}</th>
    ))

    return (
      <thead>
        <tr>
          <th rowSpan="2">{t('dashboard.statistics.application')}</th>
          <th colSpan="3">{t('dashboard.statistics.messageCount')}</th>
        </tr>
        <tr>{periodColumns}</tr>
      </thead>
    )
  }

  renderApplicationRow = application => {
    const { monthlyActivities } = this.props

    const statistic = lodash.find(monthlyActivities.applications, { id: application.id })
    const cells = statistic.messages.map(message => <td key={message.period}>{message.user}</td>)

    return (
      <tr key={application.id}>
        <td>{application.name}</td>
        {cells}
      </tr>
    )
  }

  renderDeletedRow() {
    const { t } = this.context
    const { monthlyActivities, applications } = this.props

    const deletedApps = lodash.values(monthlyActivities.applications)
    lodash.pullAllBy(deletedApps, applications, 'id')
    const messageCounts = this.sumMessagesEachMonth(deletedApps)

    if (messageCounts.reduce((a, b) => a + b) === 0) return
    return (
      <tr>
        <td>{t('dashboard.statistics.alreadyDeleted')}</td>
        {messageCounts.map((count, index) => (
          <td key={index}>{count}</td>
        ))}
      </tr>
    )
  }

  renderTotalRow() {
    const { t } = this.context
    const { monthlyActivities } = this.props

    const messageCounts = this.sumMessagesEachMonth(monthlyActivities.applications)

    return (
      <tfoot>
        <tr>
          <td>{t('dashboard.statistics.total')}</td>
          {messageCounts.map((count, index) => (
            <td key={index}>{count}</td>
          ))}
        </tr>
      </tfoot>
    )
  }

  sumMessagesEachMonth(applications) {
    if (lodash.isEmpty(applications)) return [0, 0, 0]

    const appsCounts = lodash.values(applications).map(application => {
      return lodash.map(application.messages, 'user')
    })

    return lodash.unzip(appsCounts).map(lodash.sum)
  }

  render() {
    const { t } = this.context
    const { account } = this.props

    return (
      <div className="dm-dashboard">
        {account.is_invalidated && <div className="warning">{t('common.invalidatedAccount')}</div>}
        {this.renderNews()}
        {this.renderStatistics()}
      </div>
    )
  }
}

export const mapStateToProps = state => {
  const account = lodash.first(Object.values(state.entities.accounts))
  const ids = state.pagination.applications.ids || []
  const applications = ids.map(id => state.entities.applications[id])
  return {
    isFetching: isFetching(state),
    news: state.news.news,
    account: account,
    applications: applications,
    monthlyActivities: state.statistics.monthlyActivities,
  }
}

export default connect(mapStateToProps)(Dashboard)
