export default {
  server: 'https://management-api.dialogplay.jp',
  messagingServer: 'https://api.dialogplay.jp',
  voiceSynthServer: 'https://voicevox.dialogplay.jp',
  voiceSynthAccessToken: '8F9dJnGMYpc4RjZ7',
  toolDirectoryServer: 'https://tool-directory.dialogplay.jp',
  awsIotEndpoint: 'au2ojz1cdid79-ats.iot.ap-northeast-1.amazonaws.com',
  importantMessagesURLs: {
    json: 'https://upload.dialogplay.jp/notices/important.json'
  },
  embeddedChatURL: 'https://dialogplay.jp/embedded',
  redirectURL: 'https://redirect.dialogplay.jp',
  paymentStoreId: '113914',
  IdentityPoolId: 'ap-northeast-1:54c0eb99-6801-4ce7-abdd-63a20b725538'
}
