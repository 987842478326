import lodash from 'lodash'
import i18next from '../middleware/i18n'

export const systemVariables = [
  '@now',
  '@today',
  '@currentTime',
  '@latestUserInput',
  '@latestClassifiedScenarioQuestion',
  '@latestClassifiedFaqQuestion',
  '@latestClassifiedFaqItemName',
  '@latestAction',
  '@faqItemName',
  '@userId',
  '@userName',
  '@userDisplayName',
  '@platform',
  '@platformAttributes',
  '@linkedExternalServiceUserId',
  '@channelUUID',
]

export const filterSystemVariables = () => {
  const disableKeys = [
    '@latestUserInput',
    '@latestClassifiedScenarioQuestion',
    '@latestClassifiedFaqQuestion',
    '@latestClassifiedFaqItemName',
    '@latestAction',
    '@faqItemName',
    '@userId',
    '@userDisplayName',
    '@platform',
    '@linkedExternalServiceUserId',
    '@channelUUID',
  ]
  return systemVariables.filter(variable => !disableKeys.includes(variable))
}

export const filterUpperVariables = (variables, upperVariables) => {
  const allLocaleUpperVariables = []
  upperVariables.forEach(name => {
    if (name.startsWith('@')) {
      allLocaleUpperVariables.push(name)
      allLocaleUpperVariables.push(i18next.t(`systemVariables.${name}`, { lng: 'ja' }))
      allLocaleUpperVariables.push(i18next.t(`systemVariables.${name}`, { lng: 'zh' }))
    } else {
      allLocaleUpperVariables.push(name)
    }
  })

  return lodash.difference(variables, allLocaleUpperVariables)
}

export const getReplaceSystemVariableValue = (value, isSave, isAddBrackets) => {
  if (!value || typeof value !== 'string') return value

  let translationPairs = []
  if (isSave) {
    translationPairs = systemVariables.map(name => ({
      from: i18next.t(`systemVariables.${name}`),
      to: name,
    }))
  } else {
    translationPairs = systemVariables.map(name => ({
      from: name,
      to: i18next.t(`systemVariables.${name}`),
    }))
  }

  lodash
    .orderBy(translationPairs, pair => pair.from.length, 'desc')
    .forEach(({ from, to }) => {
      if (isAddBrackets) {
        from = '{{' + from + '(\\.[^}]+)?}}'
        to = '{{' + to + '$1}}'
      }
      value = value.replace(new RegExp(from, 'g'), to)
    })
  return value
}

export const replaceSystemVariableWhenOnChange = (object, key, isSave, isAddBrackets) => {
  const afterValue = getReplaceSystemVariableValue(object[key], isSave, isAddBrackets)
  if (object[key] !== afterValue) {
    object[key] = afterValue
  }
}
