import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import { InputField, CheckboxField } from '../../components/common/fields/FormFields'
import Tooltip from '../../components/common/Tooltip'
import TextField from '../common/fields/TextField'
import VariableField from '../common/fields/VariableField'

class EntitySelectField extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    entities: PropTypes.array.isRequired,
    empty: PropTypes.bool,
    className: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
  }

  errorNode = error => {
    const { t } = this.context
    if (!error) return null
    if (typeof error === 'object') {
      return <div className="error">{t(error.id, error.values)}</div>
    } else {
      return <div className="error">{t(error)}</div>
    }
  }

  render() {
    const {
      entities,
      empty,
      className,
      input,
      meta: { submitting, touched, error },
    } = this.props
    const { t } = this.context

    const groupKeys = [
      'datetime',
      'contact',
      'noun',
      'file',
      'without-summary-question',
      'user_entity.dict',
      'user_entity.regex',
    ]

    const optGroups = lodash.map(groupKeys, key => {
      const items = lodash.sortBy(lodash.filter(entities, { option_group: key }), ['order', 'name'])
      const options = lodash.map(items, ({ id, name }) => {
        return (
          <option key={`key.${id}`} value={id}>
            {name}
          </option>
        )
      })
      return (
        <optgroup key={key} label={t(`topic.actionList.slot.slotTable.entityGroup.${key}`)}>
          {options}
        </optgroup>
      )
    })
    if (empty) optGroups.unshift(<option key="empty" />)

    return (
      <div>
        <select id={input.name} className={className} disabled={submitting} {...input}>
          {optGroups}
        </select>
        {touched && this.errorNode(error)}
      </div>
    )
  }
}

export class SlotTable extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    slots: PropTypes.array,
    entities: PropTypes.array,
    fields: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    skipQuestion: PropTypes.bool,
  }

  componentWillUpdate(nextProps) {
    const { fields } = nextProps
    const prevLastItem = lodash.last(this.props.slots)
    const nextLastItem = lodash.last(nextProps.slots)

    // if only has is_optional fields and it is false then remove the slot
    nextProps.slots.forEach((slot, index) => {
      if (lodash.has(slot, 'is_optional') && !slot.is_optional && lodash.keys(slot).length === 1) {
        if (fields.length > 1) {
          fields.remove(index)
        }
      }
    })

    if (prevLastItem === undefined && nextLastItem !== undefined) {
      fields.push(undefined)
    }
  }

  canAllowedOptional(entity_id) {
    const { entities } = this.props

    const entity_name = (lodash.find(entities, { id: parseInt(entity_id, 10) }) || {}).name
    const notOptionalType = ['@text', '@number', '@alphanumeric-symbol']
    return !lodash.includes(notOptionalType, entity_name)
  }

  renderRow1(name, index) {
    const { t } = this.context
    const { slots, fields, entities, skipQuestion } = this.props

    const slot = slots[index] || {}

    const checkboxDisabled = !this.canAllowedOptional(slot.entity_id)

    return (
      <tr key={`${index}-1`}>
        <td rowSpan={slot.is_optional ? 2 : 1}>{index + 1}</td>
        <td>
          <VariableField
            name={`${name}.name`}
            className="form-control"
            ignoreValidation={(value, allValues) => !lodash.get(allValues, name)}
          />
        </td>
        <td>
          <Field
            name={`${name}.entity_id`}
            component={EntitySelectField}
            className="form-control"
            empty={true}
            entities={entities}
            onChange={this.props.onChange}
            parse={value => value && parseInt(value, 10)}
          />
          <Field name={`${name}.entity_name`} component="input" type="hidden" />
        </td>
        <td>
          <TextField
            name={`${name}.template`}
            className="form-control"
            placeholder={t('topic.actionList.slot.slotTable.questionPlaceholder', {
              interpolation: { suffix: '###', prefix: '###' },
            })}
            isTextArea={false}
            nullable={true}
            disabled={skipQuestion}
          />
        </td>
        <td>
          <Field
            name={`${name}.is_optional`}
            type="checkbox"
            component={CheckboxField}
            className="form-control"
            onChange={this.props.onChange}
            disabled={checkboxDisabled}
          />
        </td>
        <td rowSpan={slot.is_optional ? 2 : 1}>
          {index < slots.length - 1 && (
            <button
              type="button"
              className="dm-btn btn btn-danger btn-icon-delete"
              onClick={() => {
                fields.remove(index)
              }}
            />
          )}
        </td>
      </tr>
    )
  }

  renderRow2(name, index) {
    const { t } = this.context
    const { slots } = this.props
    if (!slots[index] || !slots[index].is_optional) return

    return (
      <tr key={`${index}-2`}>
        <td colSpan="4">
          <div className="form-group form-inline">
            <label>{t('topic.actionList.slot.slotTable.defaultValue')}</label>
            <Field name={`${name}.default`} component={InputField} className="form-control" />
            <Tooltip name="topic.tooltip.actionList.slot.slotTable.defaultValue" />
          </div>
        </td>
      </tr>
    )
  }

  render() {
    const { t } = this.context
    const { fields } = this.props
    return (
      <table className="table table-bordered dm-table">
        <thead>
          <tr>
            <th>{t('topic.actionList.slot.slotTable.no')}</th>
            <th>
              {t('topic.actionList.slot.slotTable.name')}
              <Tooltip name="topic.tooltip.actionList.slot.slotTable.name" />
            </th>
            <th>
              {t('topic.actionList.slot.slotTable.entity')}
              <Tooltip name="topic.tooltip.actionList.slot.slotTable.entity" />
            </th>
            <th>
              {t('topic.actionList.slot.slotTable.question')}
              <Tooltip name="topic.tooltip.actionList.slot.slotTable.question" />
            </th>
            <th>
              {t('topic.actionList.slot.slotTable.optional')}
              <Tooltip name="topic.tooltip.actionList.slot.slotTable.optional" />
            </th>
            <th>{t('topic.actionList.slot.slotTable.delete')}</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((name, index) => [this.renderRow1(name, index), this.renderRow2(name, index)])}
        </tbody>
      </table>
    )
  }
}

export default SlotTable
