import lodash from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form'
import {
  InputField,
  SelectField,
  AutoCompleteField,
  TextAreaField,
} from '../../components/common/fields/FormFields'
import LabelWithTooltip from '../common/LabelWithTooltip'
import VariableField from '../common/fields/VariableField'
import { lengthWithoutVariable } from '../../helpers/validation'

import AbstractAction from './AbstractAction'

export class VariableAction extends AbstractAction {
  getTitle() {
    return this.context.t('topic.actionList.variable.title')
  }

  static propTypes = {
    ...AbstractAction.propTypes,
    faqs: PropTypes.array.isRequired,
  }

  static validate = action => {
    const errors = super.validate(action)

    if (!action.operation_type) {
      errors.operation_type = 'validate.required'
    }

    if (!action.variable_name) {
      errors.variable_name = 'validate.required'
    } else {
      if (action.variable_name && action.variable_name.startsWith('@')) {
        if (action.variable_name !== '@userName') {
          errors.variable_name = 'validate.invalidVariableName'
        }
      }
    }

    if (action.operation_type === 'set_variable_random') {
      if (!action.arguments || !action.arguments.value) {
        errors.arguments = { value: 'validate.required' }
      } else if (lengthWithoutVariable(action.arguments.value) > 10000) {
        errors.arguments = { value: { id: 'validate.exceededMaxLength', values: { length: 10000 } } }
      } else {
        const inputArguments = action.arguments.value.split('\n')
        const threshold = 280
        const overRowNumbers = lodash.compact(
          inputArguments.map((text, id) => (lengthWithoutVariable(text) > threshold ? id + 1 : undefined))
        )

        if (!lodash.isEmpty(overRowNumbers)) {
          errors.arguments = {
            value: {
              id: 'validate.exceededMaxLengthWithRowNumber',
              values: {
                row: overRowNumbers.join(', '),
                length: threshold,
              },
            },
          }
        }
      }
    }

    if (action.operation_type === 'set_classified_faq') {
      if (!action.arguments || !action.arguments.text) {
        errors.arguments = { text: 'validate.required' }
      } else {
        if (lengthWithoutVariable(action.arguments.text) > 300 || action.arguments.text.length > 1000) {
          errors.arguments = { text: { id: 'validate.exceededMaxLength', values: { length: 300 } } }
        }
      }

      if (!action.faq_id) {
        errors.faq_id = 'validate.required'
      }
    }

    if (action.operation_type === 'set_variable_calculated') {
      const matchVariable = /^{{[^{}]+}}$|^{{{[^{}]+}}}$/
      errors.arguments = {}

      if (!action.arguments || !action.arguments.value1) {
        errors.arguments.value1 = 'validate.required'
      } else if (
        !action.arguments.value1.match(matchVariable) &&
        !lodash.isFinite(lodash.toNumber(action.arguments.value1))
      ) {
        errors.arguments.value1 = 'validate.invalidNumber'
      } else if (lengthWithoutVariable(action.arguments.value1) > 280) {
        errors.arguments.value1 = { id: 'validate.exceededMaxLength', values: { length: 280 } }
      }

      if (!action.arguments || !action.arguments.value2) {
        errors.arguments.value2 = 'validate.required'
      } else if (
        !action.arguments.value2.match(matchVariable) &&
        !lodash.isFinite(lodash.toNumber(action.arguments.value2))
      ) {
        errors.arguments.value2 = 'validate.invalidNumber'
      } else if (lengthWithoutVariable(action.arguments.value2) > 280) {
        errors.arguments.value2 = { id: 'validate.exceededMaxLength', values: { length: 280 } }
      }

      if (!action.arguments || !action.arguments.operator) {
        errors.arguments.operator = 'validate.required'
      }
    }

    if (action.operation_type === 'set_variable_history') {
      if (!action.arguments || action.arguments.limit === null || action.arguments.limit === undefined) {
        errors.arguments = { value: 'validate.required' }
      } else if (Number(action.arguments.limit) > 20) {
        errors.arguments = { value: { id: 'validate.maximumLimit', values: { maximum: 20 } } }
      } else if (Number(action.arguments.limit) < 1) {
        errors.arguments = { value: { id: 'validate.minimumLimit', values: { minimum: 1 } } }
      }
    }

    return errors
  }

  static getVariables = (action, _props, _context) => {
    if (
      lodash.includes(
        [
          'set_variable',
          'set_variable_random',
          'set_classified_faq',
          'set_variable_calculated',
          'set_variable_history',
        ],
        action.operation_type
      )
    ) {
      return [{ variable: action.variable_name, text: action.variable_name }]
    } else {
      return []
    }
  }

  renderSetVariable = () => {
    const { name, variables, userVariables } = this.props
    const adaptableVariables = lodash.uniq(lodash.concat(lodash.map(variables, 'variable'), userVariables))

    return (
      <React.Fragment>
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor={`${name}.variable_name`}
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.variable.setVariable.variableName"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <Field
              name={`${name}.variable_name`}
              className="form-control dm-form-control"
              component={AutoCompleteField}
              items={adaptableVariables}
            />
          </div>
        </div>
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor={`${name}.arguments.value`}
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.variable.setVariable.arguments.value"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <Field name={`${name}.arguments.value`} className="form-control" component={InputField} />
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderSetVariableRandom = () => {
    const { name, variables, userVariables } = this.props
    const adaptableVariables = lodash.uniq(lodash.concat(lodash.map(variables, 'variable'), userVariables))

    return (
      <React.Fragment>
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor={`${name}.variable_name`}
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.variable.setVariableRandom.variableName"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <Field
              name={`${name}.variable_name`}
              className="form-control dm-form-control"
              component={AutoCompleteField}
              items={adaptableVariables}
            />
          </div>
        </div>
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor={`${name}.arguments.value`}
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.variable.setVariableRandom.arguments.value"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <Field
              name={`${name}.arguments.value`}
              className="form-control"
              maxRows={8.3}
              minRows={4}
              component={TextAreaField}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderSetVariableCalculated = () => {
    const { name, variables, userVariables } = this.props
    const { t } = this.context
    const adaptableVariables = lodash.uniq(lodash.concat(lodash.map(variables, 'variable'), userVariables))
    const operators = [
      { value: 'add', label: t('topic.actionList.variable.setVariableCalculated.arguments.operators.add') },
      { value: 'sub', label: t('topic.actionList.variable.setVariableCalculated.arguments.operators.sub') },
      { value: 'mul', label: t('topic.actionList.variable.setVariableCalculated.arguments.operators.mul') },
      { value: 'div', label: t('topic.actionList.variable.setVariableCalculated.arguments.operators.div') },
      { value: 'mod', label: t('topic.actionList.variable.setVariableCalculated.arguments.operators.mod') },
    ]

    const addCurlyBraces = value => {
      if (adaptableVariables.includes(value)) {
        return '{{' + value + '}}'
      } else {
        return value
      }
    }

    return (
      <React.Fragment>
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor={`${name}.variable_name`}
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.variable.setVariable.variableName"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <Field
              name={`${name}.variable_name`}
              className="form-control dm-form-control"
              component={AutoCompleteField}
              items={adaptableVariables}
            />
          </div>
        </div>
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor={`${name}.arguments.value`}
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.variable.setVariableCalculated.arguments.formula"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <div className="dm-row-without-gap">
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 condition">
                <Field
                  name={`${name}.arguments.value1`}
                  className="form-control dm-form-control"
                  placeholder={t(
                    'topic.actionList.variable.setVariableCalculated.arguments.value1Placeholder'
                  )}
                  component={AutoCompleteField}
                  items={adaptableVariables}
                  parse={value => addCurlyBraces(value)}
                  valueKey="variable"
                  displayKey="text"
                  maxLength="280"
                />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <Field
                  name={`${name}.arguments.operator`}
                  className="form-control dm-form-control"
                  component={SelectField}
                  items={operators}
                  valueKey="value"
                  displayKey="label"
                  empty={true}
                />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 condition">
                <Field
                  name={`${name}.arguments.value2`}
                  className="form-control dm-form-control"
                  placeholder={t(
                    'topic.actionList.variable.setVariableCalculated.arguments.value2Placeholder'
                  )}
                  component={AutoCompleteField}
                  items={adaptableVariables}
                  parse={value => addCurlyBraces(value)}
                  valueKey="variable"
                  displayKey="text"
                  maxLength="280"
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderAppendValue = () => {
    const { name, variables, userVariables } = this.props

    const adaptableVariables = lodash.uniq(lodash.concat(lodash.map(variables, 'variable'), userVariables))

    return (
      <React.Fragment>
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor={`${name}.variable_name`}
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.variable.appendValue.variableName"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <Field
              name={`${name}.variable_name`}
              className="form-control dm-form-control"
              component={AutoCompleteField}
              items={adaptableVariables}
            />
          </div>
        </div>
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor={`${name}.arguments.value`}
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.variable.appendValue.arguments.value"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <Field name={`${name}.arguments.value`} className="form-control" component={InputField} />
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderSetClassifiedFaq = () => {
    const { name, faqs } = this.props

    return (
      <React.Fragment>
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor={`${name}.variable_name`}
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.variable.setClassifiedFaq.variableName"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <VariableField name={`${name}.variable_name`} className="form-control dm-form-control" />
          </div>
        </div>
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor={`${name}.arguments.text`}
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.variable.setClassifiedFaq.arguments.text"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <Field
              name={`${name}.arguments.text`}
              className="form-control"
              component={InputField}
              maxLength="1000"
            />
          </div>
        </div>
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor={`${name}.faq_id`}
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.variable.setClassifiedFaq.faq"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <Field
              name={`${name}.faq_id`}
              className="form-control"
              component={SelectField}
              items={faqs}
              valueKey="id"
              displayKey="name"
              empty={true}
              parse={value => value && parseInt(value, 10)}
              order="asc"
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderSetVariableHistory = () => {
    const { name, variables, userVariables } = this.props
    const adaptableVariables = lodash.uniq(lodash.concat(lodash.map(variables, 'variable'), userVariables))

    return (
      <React.Fragment>
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor={`${name}.variable_name`}
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.variable.setVariableHistory.variableName"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <Field
              name={`${name}.variable_name`}
              className="form-control dm-form-control"
              component={AutoCompleteField}
              items={adaptableVariables}
            />
          </div>
        </div>
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor={`${name}.arguments.limit`}
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.variable.setVariableHistory.arguments.limit"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <Field
              type="number"
              name={`${name}.arguments.limit`}
              className="form-control"
              component={InputField}
              max="20"
              min="1"
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderBody() {
    const { t } = this.context
    const { action, name } = this.props

    const operationTypes = [
      { id: 'set_variable', name: t('topic.actionList.variable.operationTypes.setVariable') },
      { id: 'set_variable_random', name: t('topic.actionList.variable.operationTypes.setVariableRandom') },
      {
        id: 'set_variable_calculated',
        name: t('topic.actionList.variable.operationTypes.setVariableCalculated'),
      },
      { id: 'append_value', name: t('topic.actionList.variable.operationTypes.appendValue') },
      { id: 'set_classified_faq', name: t('topic.actionList.variable.operationTypes.setClassifiedFaq') },
      { id: 'set_variable_history', name: t('topic.actionList.variable.operationTypes.setVariableHistory') },
    ]

    return (
      <div className="variable">
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor={`${name}.operation_type`}
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.variable.operationType"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <Field
              name={`${name}.operation_type`}
              className="form-control"
              component={SelectField}
              items={operationTypes}
              valueKey="id"
              displayKey="name"
              empty={true}
            />
          </div>
        </div>
        {action.operation_type === 'set_variable' && this.renderSetVariable()}
        {action.operation_type === 'set_variable_random' && this.renderSetVariableRandom()}
        {action.operation_type === 'set_variable_calculated' && this.renderSetVariableCalculated()}
        {action.operation_type === 'append_value' && this.renderAppendValue()}
        {action.operation_type === 'set_classified_faq' && this.renderSetClassifiedFaq()}
        {action.operation_type === 'set_variable_history' && this.renderSetVariableHistory()}
      </div>
    )
  }
}

export default VariableAction
