import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'

import DataBindingTable from '../../components/common/DataBindingTable'
import { getCredentials } from '../../helpers/sessionHelper'

class PnpTopicIndex extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    bot: PropTypes.object.isRequired,
    topics: PropTypes.array.isRequired,
    tableState: PropTypes.object,
    updateTableState: PropTypes.func.isRequired,
  }

  render() {
    const { t } = this.context
    const { bot, topics, tableState, updateTableState } = this.props
    const { timezone } = getCredentials(this.context)
    const columns = [
      {
        Header: t('pnpTopic.name'),
        id: 'name',
        accessor: 'name',
        Cell: props => <Link to={`/bots/${bot.id}/pnp_topics/${props.original.id}`}>{props.value}</Link>,
        getFooterProps: () => ({ className: 'footer-all' }),
        Footer: (
          <Link className="dm-btn btn btn-primary btn-icon-plus mini" to={`/bots/${bot.id}/pnp_topics/new`}>
            <span>{t('common.new')}</span>
          </Link>
        ),
      },
      {
        Header: t('common.lastUpdatedBy'),
        id: 'lastUpdatedBy',
        accessor: topic => topic.last_updated_by.name || '',
        width: 160,
      },
      {
        Header: t('common.updatedAt'),
        id: 'updatedAt',
        accessor: topic =>
          topic.updated_at ? moment.tz(topic.updated_at, timezone).format('YYYY/MM/DD HH:mm') : '',
        width: 160,
      },
    ]
    return (
      <div>
        <DataBindingTable
          items={topics}
          columns={columns}
          tableState={tableState}
          updateTableState={updateTableState}
          tableName="PnpTopicList"
        />
      </div>
    )
  }
}

export default PnpTopicIndex
