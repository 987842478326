import PropTypes from 'prop-types'
import React, { Component } from 'react'

class Footer extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  render() {
    const { t } = this.context

    return (
      <footer className="dm-footer">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <a href="/about.html">{t('terms.usage')}</a>
              <a href="/terms.html">{t('terms.termsOfUse')}</a>
              <a href="http://www.tis.co.jp/tis_securitypolicy/index.html">{t('terms.securityPolicy')}</a>
              <a href="/privacypolicy.html">{t('terms.privacyPolicy')}</a>
              <a href="http://www.tis.co.jp/privacy/index.html">{t('terms.privacy')}</a>
              <a href="/tokusho.html">{t('terms.tokusho')}</a>
            </div>
          </div>
          <span>{t('terms.copyright')}</span>
        </div>
      </footer>
    )
  }
}

export default Footer
