import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import TextareaAutosize from 'react-textarea-autosize'
import classnames from 'classnames'
import { UAParser } from 'ua-parser-js'

import UnknownMessage from '../../components/chat/UnknownMessage'
import TextMessage from '../../components/chat/TextMessage'
import TypingMessage from '../../components/chat/TypingMessage'
import OperatorTypingMessage from '../../components/chat/OperatorTypingMessage'
import ImageMessage from '../../components/chat/ImageMessage'
import CarouselMessage from '../../components/chat/CarouselMessage'
import ConfirmMessage from '../../components/chat/ConfirmMessage'
import ChooseMessage from '../../components/chat/ChooseMessage'
import ItemListMessage from '../../components/chat/ItemListMessage'
import RequestFeedbackMessage from '../../components/chat/RequestFeedbackMessage'
import FormMessage from '../../components/chat/FormMessage'
import { getBrowserLanguageCode } from '../../helpers/language'
import { isPermitted } from '../../helpers/permission'
import ChatMenu from './ChatMenu'
import Suggestion from './Suggestion'
import ImageFileConfirmation from './ImageFileConfirmation'

class ChatRoom extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    mode: PropTypes.string.isRequired,
    timezone: PropTypes.string,
    isError: PropTypes.bool.isRequired,
    handleSendMessage: PropTypes.func,
    handleSendRawMessage: PropTypes.func,
    onChangeText: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    messages: PropTypes.array,
    formValues: PropTypes.object,
    features: PropTypes.object,
    isSimulator: PropTypes.bool,

    updateUnreadStatus: PropTypes.func.isRequired,
    setAutoScroll: PropTypes.func.isRequired,
    isAutoScroll: PropTypes.bool.isRequired,
    showBotTypingMessage: PropTypes.bool.isRequired,
    isOperatorTyping: PropTypes.bool.isRequired,

    suggestion: PropTypes.object,
    imageUploaderDisabled: PropTypes.bool,

    noticeMode: PropTypes.string,
    changeNoticeMode: PropTypes.func,
    changeSpeaker: PropTypes.func,
    stopReadingOutByVoiceSynth: PropTypes.func,
    onSpeechRecognition: PropTypes.func.isRequired,
    speechRecognitionDisabled: PropTypes.bool,
    speechRecognitionContinuously: PropTypes.bool,
    speechRecognitionSendWait: PropTypes.number,
    speechRecognitionTimeBarDelay: PropTypes.number,

    speechSynthesisDisabled: PropTypes.bool,
    unlockAudio: PropTypes.func.isRequired,

    inputTextPlaceholder: PropTypes.string,
    inputTextDisabled: PropTypes.bool,
  }

  static defaultProps = {
    isError: false,
    messages: [],
    onChangeText: () => {},
    handleSendMessage: () => {},
    handleSendRawMessage: () => {},
    features: {},
    isSimulator: false,
    isAutoScroll: true,
    showBotTypingMessage: false,
    isOperatorTyping: false,
    formValues: {},
    suggestion: {
      candidates: [],
      queryCandidates: () => {},
      clearCandidates: () => {},
      selectPrevCandidate: () => {},
      selectNextCandidate: () => {},
      selectCandidate: () => {},
      decideCandidate: () => {},
    },
    imageUploaderDisabled: false,
    stopReadingOutByVoiceSynth: () => {},
    speechRecognitionDisabled: false,
    speechRecognitionContinuously: false,
    speechRecognitionSendWait: 4000,
    speechRecognitionTimeBarDelay: 500,
    speechSynthesisDisabled: false,
  }

  constructor() {
    super()
    this.state = {
      isShowMenu: false,
      isShowImageFileConfirmation: false,
      iconUrl: null,
      isLoaded: false,
      imageFile: null,
      recordingStatus: 'disabled',
      timeBarStatus: 'stopped',
    }
  }

  componentDidMount() {
    if (!this.props.imageUploaderDisabled) {
      const chatRoom = document.getElementsByClassName('dm-container')[0]
      chatRoom.addEventListener('paste', this.imagePasteEvent)
      chatRoom.addEventListener('dragover', this.imageDragoverEvent)
      chatRoom.addEventListener('drop', this.imageDropEvent)
    }

    if (window.webkitSpeechRecognition || window.SpeechRecognition) {
      this.initializeSpeechRecognition()
    } else {
      this.setState({ recordingStatus: 'disabled' })
    }
  }

  initializeSpeechRecognition = () => {
    const {
      speechRecognitionContinuously,
      speechRecognitionSendWait,
      speechRecognitionTimeBarDelay,
    } = this.props
    const timeBarDuration = speechRecognitionSendWait - speechRecognitionTimeBarDelay
    this.refs.time_bar.style.setProperty('--time-bar-duration', timeBarDuration + 'ms')
    this.refs.time_bar.style.setProperty('--time-bar-delay', speechRecognitionTimeBarDelay + 'ms')

    this.recognition = new (window.webkitSpeechRecognition || window.SpeechRecognition)()

    this.recognition.lang = getBrowserLanguageCode()

    this.recognition.onresult = event => {
      const { formValues, onSpeechRecognition, handleSendMessage } = this.props
      const message = event.results[0][0].transcript
      const newMessage = formValues.text ? formValues.text + message : message
      onSpeechRecognition(newMessage)

      if (this.state.recordingStatus === 'recording') {
        this.setState({ timeBarStatus: 'full' })
        setTimeout(() => {
          this.setState({ timeBarStatus: 'running' })
        }, 0)
      }

      clearTimeout(this.sendMessageTimer)
      this.sendMessageTimer = setTimeout(() => {
        if (this.state.recordingStatus !== 'recording') return

        if (!this.hasPersonalInfo(newMessage)) {
          handleSendMessage({ text: newMessage })
        }

        if (!speechRecognitionContinuously) {
          this.setState({ recordingStatus: 'stopped' })
          this.recognition.stop()
        }
        this.setState({ timeBarStatus: 'stopped' })
      }, speechRecognitionSendWait)
    }

    this.recognition.onstart = () => {
      this.setState({ recordingStatus: 'recording' })
    }

    this.recognition.onend = () => {
      // keep voice recognition running while recording
      if (this.state.recordingStatus === 'recording') {
        this.recognition.start()
      }
    }

    this.recognition.onerror = event => {
      if (event.error === 'not-allowed') {
        this.setState({ recordingStatus: 'not-allowed' })
      } else if (event.error === 'service-not-allowed') {
        this.setState({ recordingStatus: 'disabled' })
        alert(this.context.t('embeddedChat.recordingNotSupported'))
      } else if (event.error === 'language-not-supported') {
        this.setState({ recordingStatus: 'not-supported' })
        alert(this.context.t('embeddedChat.recordingNotSupported'))
      } else if (event.error === 'network') {
        // Not supported language in Edge
        if (this.recognition.lang !== 'en-us') {
          this.recognition.lang = 'en-us'
        } else {
          this.setState({ recordingStatus: 'stopped' })
          alert(this.context.t('error.message.unexpectedError.message'))
        }
      } else if (event.error === 'no-speech') {
        //  Ignore error and keep voice recognition
      } else if (event.error === 'aborted') {
        this.setState({ recordingStatus: 'stopped' })
      } else {
        this.setState({ recordingStatus: 'stopped' })
        alert(this.context.t('error.message.unexpectedError.message'))
      }
    }

    if (window.navigator.permissions) {
      window.navigator.permissions
        .query({ name: 'microphone' })
        .then(permissionStatus => {
          if (permissionStatus.state === 'denied') {
            this.setState({ recordingStatus: 'not-allowed' })
          } else {
            this.setState({ recordingStatus: 'stopped' })
          }
          permissionStatus.onchange = event => {
            if (event.target.state === 'denied') {
              this.setState({ recordingStatus: 'not-allowed' })
            } else {
              this.setState({ recordingStatus: 'stopped' })
            }
          }
        })
        .catch(() => {
          this.setState({ recordingStatus: 'disabled' })
        })
    } else {
      this.setState({ recordingStatus: 'stopped' })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { iconUrl } = nextProps.features
    if (!this.state.iconUrl) {
      if (iconUrl) {
        this.checkImageUrl(iconUrl)
      }
    } else {
      if (this.state.iconUrl !== iconUrl) {
        this.checkImageUrl(iconUrl)
      }
    }
    // Set loading completion of icon settings when use default icon
    if (iconUrl === null) {
      this.setState({ isLoaded: true })
    }
    if (this.props.imageUploaderDisabled !== nextProps.imageUploaderDisabled) {
      const chatRoom = document.getElementsByClassName('dm-container')[0]
      if (nextProps.imageUploaderDisabled) {
        // Disable image uploader
        chatRoom.removeEventListener('paste', this.imagePasteEvent)
        chatRoom.removeEventListener('dragover', this.imageDragoverEvent)
        chatRoom.removeEventListener('drop', this.imageDropEvent)
      } else {
        // Enable image uploader
        chatRoom.addEventListener('paste', this.imagePasteEvent)
        chatRoom.addEventListener('dragover', this.imageDragoverEvent)
        chatRoom.addEventListener('drop', this.imageDropEvent)
      }
    }
  }

  checkImageUrl = iconUrl => {
    var image = new Image()
    image.onload = () => {
      this.setState({ iconUrl: iconUrl, isLoaded: true })
    }
    image.onerror = () => {
      this.setState({ iconUrl: null, isLoaded: true })
    }
    image.src = iconUrl
  }

  componentDidUpdate() {
    const { scrollHeight, clientHeight } = this.refs.chat_body
    const { isAutoScroll, setAutoScroll } = this.props

    if (!isAutoScroll) return

    //  <ul> element has not initialized
    if (clientHeight === 0) return

    const unreadMessages = document.querySelectorAll('ul.chat > li.unread')
    const unreadHeight = lodash.sum(lodash.map(unreadMessages, node => node.clientHeight + 10))
    if (unreadHeight < clientHeight) {
      //  Scroll to bottom automatically
      this.preventScrollEvent = true
      this.refs.chat_body.scrollTop = scrollHeight - clientHeight - 1
    } else {
      //  Disable auto scrolling
      this.refs.chat_body.scrollTop = scrollHeight - unreadHeight - 10
      setAutoScroll(false)
    }
  }

  hasPersonalInfo(text) {
    const { features } = this.props

    if (!features.digitMaskingLength) return false
    if (!text) return false

    // Mask numbers with more than specified number of digits, such as credit card numbers and My Numbers
    // Extract patterns from xregexp to reduce bundle size
    const spaceSeparator = ' \xA0\u1680\u2000-\u200A\u202F\u205F\u3000'
    const dashPunctuation =
      '\\-\u058A\u05BE\u1400\u1806\u2010-\u2015\u2E17\u2E1A\u2E3A\u2E3B\u2E40\u2E5D\u301C\u3030\u30A0\uFE31\uFE32\uFE58\uFE63\uFF0D'
    const number =
      '0-9\xB2\xB3\xB9\xBC-\xBE\u0660-\u0669\u06F0-\u06F9\u07C0-\u07C9\u0966-\u096F\u09E6-\u09EF\u09F4-\u09F9\u0A66-\u0A6F\u0AE6-\u0AEF\u0B66-\u0B6F\u0B72-\u0B77\u0BE6-\u0BF2\u0C66-\u0C6F\u0C78-\u0C7E\u0CE6-\u0CEF\u0D58-\u0D5E\u0D66-\u0D78\u0DE6-\u0DEF\u0E50-\u0E59\u0ED0-\u0ED9\u0F20-\u0F33\u1040-\u1049\u1090-\u1099\u1369-\u137C\u16EE-\u16F0\u17E0-\u17E9\u17F0-\u17F9\u1810-\u1819\u1946-\u194F\u19D0-\u19DA\u1A80-\u1A89\u1A90-\u1A99\u1B50-\u1B59\u1BB0-\u1BB9\u1C40-\u1C49\u1C50-\u1C59\u2070\u2074-\u2079\u2080-\u2089\u2150-\u2182\u2185-\u2189\u2460-\u249B\u24EA-\u24FF\u2776-\u2793\u2CFD\u3007\u3021-\u3029\u3038-\u303A\u3192-\u3195\u3220-\u3229\u3248-\u324F\u3251-\u325F\u3280-\u3289\u32B1-\u32BF\uA620-\uA629\uA6E6-\uA6EF\uA830-\uA835\uA8D0-\uA8D9\uA900-\uA909\uA9D0-\uA9D9\uA9F0-\uA9F9\uAA50-\uAA59\uABF0-\uABF9\uFF10-\uFF19'
    const pattern = new RegExp(`[${spaceSeparator}${dashPunctuation}]+`, 'g')
    const textWithoutSeparator = text.replaceAll(pattern, '')

    const matches = Array.from(textWithoutSeparator.matchAll(new RegExp(`[${number}]+`, 'g')))
    return lodash.some(matches, match => match[0].length >= features.digitMaskingLength)
  }

  isValidImageFile = (file, hex) => {
    if (file.type === 'image/jpeg' && hex === 'ffd8') return true
    if (file.type === 'image/png' && hex === '8950') return true
    if (file.type === 'image/gif' && hex === '4749') return true

    return false
  }

  setUploadingImageFile = file => {
    const { t } = this.context

    const authorizedFileFormat = ['image/png', 'image/jpeg', 'image/gif']
    const maxFileSize = 3145728

    if (authorizedFileFormat.indexOf(file.type) === -1) {
      window.alert(t('validate.invalidFileFormat'))
      return
    }

    if (file.size > maxFileSize) {
      window.alert(t('validate.invalidFileSize', { size: 3 }))
      return
    }

    const fr = new FileReader()
    fr.onload = evt => {
      const dv = new DataView(evt.target.result, 0, 5)
      const nume1 = dv.getUint8(0, true)
      const nume2 = dv.getUint8(1, true)
      const hex = nume1.toString(16) + nume2.toString(16)

      if (this.isValidImageFile(file, hex)) {
        this.setState({ imageFile: file })
        this.openImageFileConfirmation()
      } else {
        window.alert(t('validate.invalidFileExtension'))
      }
    }
    fr.readAsArrayBuffer(file)
  }

  onChangeImageFile = e => {
    const file = e.target.files[0]
    if (!file) return

    this.setUploadingImageFile(file)
  }

  imagePasteEvent = e => {
    const image_file_item = lodash.find(e.clipboardData.items, item => {
      return item.kind === 'file' && lodash.startsWith(item.type, 'image/')
    })
    if (image_file_item) {
      e.preventDefault()
      this.setUploadingImageFile(image_file_item.getAsFile())
    }
  }

  imageDragoverEvent = e => {
    // Stop default drop event.
    e.preventDefault()
  }

  imageDropEvent = e => {
    e.preventDefault()
    if (e.dataTransfer.files.length > 0) {
      this.setUploadingImageFile(e.dataTransfer.files[0])
    }
  }

  onCompositionStart = _e => {
    this.enableComposition = true
  }

  onCompositionEnd = e => {
    this.enableComposition = false
    return this.props.suggestion.queryCandidates(e.target.value)
  }

  onChangeText = e => {
    this.props.onChangeText(e)

    if (this.enableComposition) return

    return this.props.suggestion.queryCandidates(e.target.value)
  }

  onKeyDown = e => {
    const { formValues, handleSendMessage } = this.props
    const { selectedIndex, candidates } = this.props.suggestion
    if (this.enableComposition) return

    const VK_RETURN = 13
    const VK_UP = 38
    const VK_DOWN = 40

    if (e.keyCode === VK_UP && candidates.length > 0) {
      this.props.suggestion.selectPrevCandidate()
      e.preventDefault()
    }
    if (e.keyCode === VK_DOWN && candidates.length > 0) {
      this.props.suggestion.selectNextCandidate()
      e.preventDefault()
    }
    if (e.keyCode === VK_RETURN && !e.shiftKey) {
      if (this.hasPersonalInfo(formValues.text)) {
        e.preventDefault()
        return
      }

      if (selectedIndex == null) {
        this.props.suggestion.clearCandidates()
        handleSendMessage(formValues)
        e.preventDefault()
      } else {
        this.props.suggestion.decideCandidate()
        e.preventDefault()
      }
    }
  }

  scrollToBottom = () => {
    const { scrollHeight } = this.refs.chat_body
    if (typeof this.refs.chat_body.scrollTo === 'function') {
      if (new UAParser().getBrowser().name === 'Firefox') {
        this.refs.chat_body.scrollTo({ top: scrollHeight, behavior: 'smooth' })
      } else {
        this.refs.chat_body.scrollTo({ top: scrollHeight, behavior: 'instant' })
      }
    } else {
      this.refs.chat_body.scrollTop = scrollHeight
    }
  }

  handleScroll = e => {
    if (this.preventScrollEvent) {
      this.preventScrollEvent = false
      return
    }

    const { messages, updateUnreadStatus, setAutoScroll } = this.props
    const current = e.target.scrollTop
    const max = e.target.scrollHeight - e.target.clientHeight
    const isScrollBottom = max - current < 2
    const hasUnread = (lodash.last(messages) || {}).unread
    if (isScrollBottom && hasUnread) {
      updateUnreadStatus()
    }

    clearTimeout(this.scrollTimer)
    this.scrollTimer = setTimeout(() => {
      setAutoScroll(isScrollBottom)
    }, 100)
    e.target.scrollTop = Math.max(1, Math.min(e.target.scrollHeight - e.target.clientHeight - 1, current))
  }

  toggleMenu = () => {
    if (this.props.isError) return

    this.props.unlockAudio()
    this.setState({ isShowMenu: !this.state.isShowMenu })
  }

  closeMenu = () => {
    this.setState({ isShowMenu: false })
  }

  openImageFileConfirmation = () => {
    this.setState({ isShowImageFileConfirmation: true })
  }

  closeImageFileConfirmation = () => {
    this.setState({ imageFile: null, isShowImageFileConfirmation: false })
  }

  onClickTextField = e => {
    e.stopPropagation()
    this.props.suggestion.queryCandidates(e.target.value)
  }

  renderMessageField = ({ input, meta: _meta, ...otherProps }) => {
    return <TextareaAutosize id={input.name} {...input} {...otherProps} />
  }

  generateMessageProps = (message, key, isLatest) => {
    const { isLoaded } = this.state
    const { mode, timezone, isError, handleSendMessage, isSimulator, features } = this.props
    const { iconUrl } = features

    const props = {
      ...message,
      content:
        mode === 'server' && message.app_language_content ? message.app_language_content : message.content,
      key,
      mode,
      timezone,
      isLoaded: isError || isLoaded,
      iconUrl,
      isSimulator,
      isLatest,
      handleSendMessage,
    }

    if (lodash.get(message, 'content.sender.icon_url')) {
      if (props.isLoaded) {
        props.fallbackIconUrl = iconUrl
      }
      props.iconUrl = message.content.sender.icon_url
      props.isLoaded = true
    } else if (message.sender_type === 'operator') {
      // Use default operator icon
      props.iconUrl = ''
    }

    return props
  }

  onClickMicrophone = () => {
    if (!this.recognition) return

    if (this.state.recordingStatus === 'not-allowed') {
      alert(this.context.t('embeddedChat.recordingAlert'))
      return
    }
    if (this.state.recordingStatus === 'not-supported') {
      alert(this.context.t('embeddedChat.recordingNotSupported'))
      return
    }

    if (this.state.recordingStatus !== 'recording') {
      this.recognition.start()
    } else {
      this.setState({ recordingStatus: 'stopped' })
      this.recognition.stop()
      clearTimeout(this.sendMessageTimer)
      this.setState({ timeBarStatus: 'stopped' })
    }
  }

  onSubmit = e => {
    if (this.recognition && this.state.recordingStatus === 'recording') {
      this.setState({ recordingStatus: 'stopped' })
      this.recognition.stop()
      clearTimeout(this.sendMessageTimer)
      this.setState({ timeBarStatus: 'stopped' })
    }

    return this.props.onSubmit(e)
  }

  render() {
    const { t } = this.context
    const { isShowMenu, isShowImageFileConfirmation, imageFile, recordingStatus, timeBarStatus } = this.state
    const {
      mode,
      isSimulator,
      isError,
      submitting,
      messages,
      handleSendMessage,
      handleSendRawMessage,
      formValues,
      features,
      isAutoScroll,
      showBotTypingMessage,
      isOperatorTyping,
      suggestion,
      imageUploaderDisabled,
      noticeMode,
      changeNoticeMode,
      changeSpeaker,
      stopReadingOutByVoiceSynth,
      speechRecognitionDisabled,
      speechSynthesisDisabled,
      inputTextPlaceholder,
      inputTextDisabled,
    } = this.props

    const mySenderType = mode === 'client' ? 'client' : 'operator'
    const ignoreTypes = ['typing', 'done', 'operator_typing', 'operator_stop_typing']
    const unreadIndex = lodash.findIndex(
      messages,
      message =>
        message.unread && message.sender_type !== mySenderType && !lodash.includes(ignoreTypes, message.type)
    )
    const latestVisibleMessage = lodash.findLast(
      messages,
      message => !lodash.includes(ignoreTypes, message.type)
    )
    const aitalkEnabled = isSimulator ? isPermitted('feature_aitalk', this.context) : features.enableAITalk

    const children = []
    messages.forEach((message, idx) => {
      const key = message.uuid
      const isLatest = message.uuid === latestVisibleMessage?.uuid
      const props = this.generateMessageProps(message, key, isLatest)

      //  Insert unread indicator
      if (unreadIndex > 0 && unreadIndex === idx) {
        children.push(
          <li key="unread-indicator" className="unread unread-indicator">
            <hr />
            <span>{t('embeddedChat.newMessages')}</span>
            <hr />
          </li>
        )
      }

      let showAction = 'hide'
      if (mode === 'client' && isLatest) showAction = 'show'
      if (mode === 'server') showAction = 'readonly'
      if (message.type === 'unknown') {
        children.push(<UnknownMessage {...props} />)
      }
      if (message.type === 'text') {
        children.push(<TextMessage {...props} />)
      }
      if (message.type === 'datepicker') {
        // Treat datepicker message as text message
        children.push(<TextMessage {...props} type="text" />)
      }
      if (message.type === 'image') {
        children.push(<ImageMessage {...props} />)
      }
      if (message.type === 'confirm') {
        children.push(<ConfirmMessage {...props} showAction={showAction} />)
      }
      if (message.type === 'choose') {
        children.push(<ChooseMessage {...props} showAction={showAction} />)
      }
      if (message.type === 'item_list') {
        children.push(<ItemListMessage {...props} />)
      }
      if (message.type === 'carousel') {
        children.push(<CarouselMessage {...props} showAction={showAction} />)
      }
      if (message.type === 'request_feedback') {
        children.push(
          <RequestFeedbackMessage
            {...props}
            {...message.content}
            showAction={mode === 'server' ? 'readonly' : 'show'}
            handleSendRawMessage={handleSendRawMessage}
            formValues={formValues[message.uuid]}
          />
        )
      }
      if (message.type === 'form') {
        children.push(
          <FormMessage
            {...props}
            {...message.content}
            showAction={mode === 'server' ? 'readonly' : 'show'}
            handleSendRawMessage={handleSendRawMessage}
            formValues={formValues[message.uuid]}
          />
        )
      }
    })

    //  Add bot typing message to last
    if (showBotTypingMessage) {
      const latestBotTypingMessage = lodash.findLast(messages, { type: 'typing' })
      if (latestBotTypingMessage) {
        const props = this.generateMessageProps(latestBotTypingMessage, 'typing', true)
        children.push(<TypingMessage {...props} />)
      }
    }

    //  Add operator typing message to last
    if (isOperatorTyping) {
      const lastNotClientMessage = lodash.findLast(messages, message => message.sender_type !== 'client')
      if (lastNotClientMessage.type === 'operator_typing') {
        const props = this.generateMessageProps(lastNotClientMessage, 'operator_typing', true)
        children.push(<OperatorTypingMessage {...props} text={features.operatorTypingMessage} />)
      }
    }

    children.push(
      <li
        className={`scroll-to-bottom ${isAutoScroll ? 'hide' : 'display'}`}
        key="scroll-to-bottom"
        onClick={this.scrollToBottom}
      />
    )

    let menuButton = null
    if (mode === 'client') {
      menuButton = (
        <span className="input-group-btn">
          <button
            type="button"
            className={`btn btn-default dm-btn toggle-menu ${isShowMenu ? 'show-menu' : ''}`}
            onClick={this.toggleMenu}
          >
            <img src="/image/icon_plus_black.png" alt="" />
          </button>
        </span>
      )
    }

    const hasPersonalInfo = this.hasPersonalInfo(formValues.text)

    const microphoneClasses = {
      fas: true,
      'fa-microphone': true,
      icon: true,
      'microphone-on': recordingStatus === 'recording',
      'microphone-off': recordingStatus === 'stopped',
      'microphone-not-allowed': ['not-allowed', 'not-supported'].includes(recordingStatus),
    }

    return (
      <div className="fit dm-container">
        {features.showAdvertisement && (
          <div className="advertisement-bar">
            Powered by&nbsp;
            <a target="_blank" rel="noopener noreferrer" href="https://www.dialogplay.jp">
              DialogPlay
            </a>
          </div>
        )}
        <div className="chat-wrapper">
          <ul
            className={`chat ${mode}`}
            ref="chat_body"
            onScroll={this.handleScroll}
            onClick={this.closeMenu}
          >
            {children}
          </ul>
          <Suggestion {...suggestion} />
        </div>
        {hasPersonalInfo && (
          <div className="note">
            <span className="icon" />
            <span className="message">
              {t('embeddedChat.errors.personalInformation', { length: features.digitMaskingLength })}
            </span>
          </div>
        )}
        <form onSubmit={this.onSubmit}>
          <div className="dm-chat-footer">
            <div className="input-group">
              {menuButton}
              <Field
                name="text"
                type="text"
                className="form-control dm-form-control"
                autoComplete="off"
                placeholder={
                  recordingStatus === 'recording'
                    ? t('embeddedChat.recording')
                    : inputTextPlaceholder || t('embeddedChat.textField')
                }
                minRows={1}
                component={this.renderMessageField}
                disabled={isError || recordingStatus === 'recording' || inputTextDisabled}
                onChange={this.onChangeText}
                onCompositionStart={this.onCompositionStart}
                onCompositionEnd={this.onCompositionEnd}
                onKeyDown={this.onKeyDown}
                onClick={this.onClickTextField}
              />
              {!speechRecognitionDisabled && recordingStatus !== 'disabled' && (
                <span className="microphone">
                  <i onClick={this.onClickMicrophone} className={classnames(microphoneClasses)}></i>
                </span>
              )}
              <span className="input-group-btn">
                <button
                  type="submit"
                  className="btn btn-primary dm-btn"
                  disabled={submitting || isError || hasPersonalInfo}
                >
                  {t('embeddedChat.send')}
                </button>
              </span>
            </div>
            <div className={`auto-send-time-bar ${timeBarStatus}`} ref="time_bar"></div>
          </div>
        </form>
        {mode === 'client' && (
          <ChatMenu
            isSimulator={isSimulator}
            language={features.language}
            show={isShowMenu}
            enableTranslation={features.enableTranslation}
            operatorFunction={features.operator}
            resetFunction={features.reset}
            closeMenu={this.closeMenu}
            handleSendMessage={handleSendMessage}
            imageUploaderDisabled={imageUploaderDisabled}
            onChangeImageFile={this.onChangeImageFile}
            callingOperatorMessage={features.callingOperatorMessage}
            callingOperatorButton={features.callingOperatorButton}
            noticeMode={noticeMode}
            speakerNames={features.speakerNames}
            changeNoticeMode={changeNoticeMode}
            changeSpeaker={changeSpeaker}
            stopReadingOutByVoiceSynth={stopReadingOutByVoiceSynth}
            skipCookieConsent={features.skipCookieConsent}
            aitalkEnabled={aitalkEnabled}
            speechSynthesisDisabled={speechSynthesisDisabled}
          />
        )}
        {isShowImageFileConfirmation && (
          <ImageFileConfirmation
            imageFile={imageFile}
            handleSendMessage={handleSendMessage}
            onClose={this.closeImageFileConfirmation}
          />
        )}
      </div>
    )
  }
}

export default ChatRoom
