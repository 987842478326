import React from 'react'
import lodash from 'lodash'

export const convertLineBreak = (text, keyPrefix) => {
  if (!text) return text

  const components = []

  lodash.forEach(lodash.split(text, /\r?\n/), (str, index) => {
    if (index !== 0) {
      components.push(<br key={`${keyPrefix}-${index}`} />)
    }
    if (str) {
      components.push(str)
    }
  })

  return components
}
