import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes } from 'redux-form'

import { addNotice } from '../../actions/notice'
import { deleteSession } from '../../actions/session'
import { confirmUser, isAlreadyConfirmedUser } from '../../actions/user'
import { InputField } from '../../components/common/fields/FormFields'
import Loader from '../../components/common/Loader'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import PasswordPolicy from '../../components/user/PasswordPolicy'

import { setStrictPasswordPolicy } from '../../actions/session'

const validate = (data, props) => {
  const errors = {}
  if (props.useStrictPasswordPolicy) {
    const availableSymbols = '!"#$%&\'()*+,-./:;<=>?@[\\\\\\]^_`{|}~'
    const pattern = new RegExp(
      `^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\\d)(?=.*?[${availableSymbols}])[\\x20-\\x7E]{10,255}$`
    )
    if (!pattern.test(data.password)) {
      if (!data.password || data.password.length < 10) {
        errors.password = { id: 'validate.insufficientMinLength', values: { length: 10 } }
      } else if (!/^[\x20-\x7E]*$/i.test(data.password)) {
        errors.password = 'validate.invalidPassword'
      } else {
        errors.password = 'validate.violatedStrongPasswordPolicy'
      }
    }
  } else {
    if (!/^(?=.*?[a-z])(?=.*?\d)[\x20-\x7E]{8,255}$/i.test(data.password)) {
      if (!data.password || data.password.length < 8) {
        errors.password = { id: 'validate.insufficientMinLength', values: { length: 8 } }
      } else if (!/^[\x20-\x7E]*$/i.test(data.password)) {
        errors.password = 'validate.invalidPassword'
      } else {
        errors.password = 'validate.violatedStandardPasswordPolicy'
      }
    }
  }

  if (data.password !== data.password_confirmation) {
    errors.password_confirmation = 'validate.mismatchedPassword'
  }
  if (!data.name) {
    errors.name = 'validate.required'
  }
  return errors
}

export class UserConfirm extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      confirmation_token: PropTypes.string.isRequired,
    }),
    useStrictPasswordPolicy: PropTypes.bool.isRequired,
  }

  constructor() {
    super()
    this.state = { loaded: false }
  }

  componentDidMount() {
    const { t, router } = this.context
    const {
      dispatch,
      params: { confirmation_token },
    } = this.props

    dispatch(isAlreadyConfirmedUser(confirmation_token)).then(json => {
      if (json.is_confirmed) {
        return Promise.resolve(dispatch(confirmUser(confirmation_token)))
          .then(() => dispatch(deleteSession()))
          .then(() => router.replace('/login'))
          .then(() => dispatch(addNotice('info', t('signup.confirmedMessage'))))
      } else {
        this.setState({ loaded: true })
        if (json.use_strict_password_policy) {
          dispatch(setStrictPasswordPolicy(json.use_strict_password_policy))
        }
      }
    })
  }

  handleSave = (data, dispatch) => {
    const { t, router } = this.context
    const {
      params: { confirmation_token },
    } = this.props
    return dispatch(confirmUser(confirmation_token, data.password, data.name))
      .then(() => dispatch(deleteSession()))
      .then(() => router.replace('/login'))
      .then(() => dispatch(addNotice('info', t('signup.confirmedMessage'))))
  }

  render() {
    const { t } = this.context
    const { handleSubmit, isSubmitting } = this.props

    return (
      <div className="dm-login is-confirm">
        <Loader loaded={this.state.loaded}>
          <div className="row">
            <div className="col-sm-2 col-md-2" />
            <form className="text-left col-sm-6 col-md-6" onSubmit={handleSubmit(this.handleSave)}>
              <div className="form-group">
                <LabelWithTooltip htmlFor="password" className="dm-title-mini" name="signup.password" />
                <Field
                  type="password"
                  name="password"
                  className="form-control dm-form-control"
                  component={InputField}
                  autoComplete="new-password"
                />
              </div>
              <div className="form-group">
                <LabelWithTooltip
                  htmlFor="password_confirmation"
                  className="dm-title-mini"
                  name="signup.passwordConfirmation"
                />
                <Field
                  type="password"
                  name="password_confirmation"
                  className="form-control dm-form-control"
                  component={InputField}
                  autoComplete="new-password"
                />
              </div>
              {this.props.useStrictPasswordPolicy && <PasswordPolicy />}
              <div className="form-group">
                <LabelWithTooltip htmlFor="name" className="dm-title-mini" name="signup.name" />
                <Field
                  type="name"
                  name="name"
                  className="form-control dm-form-control"
                  component={InputField}
                />
              </div>
              <div className="form-group text-right">
                <button type="submit" disabled={isSubmitting} className="btn btn-primary dm-btn">
                  {t('common.register')}
                </button>
              </div>
            </form>
          </div>
        </Loader>
      </div>
    )
  }
}

const UserConfirmForm = reduxForm({
  form: 'UserConfirm',
  validate,
  shouldError: ({ props, nextProps }) => {
    return props?.useStrictPasswordPolicy !== nextProps?.useStrictPasswordPolicy
  },
})(UserConfirm)

export const mapStateToProps = state => {
  return {
    useStrictPasswordPolicy: state.session.useStrictPasswordPolicy || false,
  }
}

export default connect(mapStateToProps)(UserConfirmForm)
