const hexToHsl = hex => {
  // hex to rgb
  hex = hex.slice(1)
  if (hex.length === 3) {
    hex = hex.slice(0, 1).repeat(2) + hex.slice(1, 2).repeat(2) + hex.slice(2, 3).repeat(2)
  }
  let [r, g, b] = [hex.slice(0, 2), hex.slice(2, 4), hex.slice(4, 6)].map(str => {
    return parseInt(str, 16)
  })
  // rgb to hsl
  r /= 255
  g /= 255
  b /= 255
  var max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  var h,
    s,
    l = (max + min) / 2
  if (max === min) {
    h = s = 0 // achromatic
  } else {
    var d = max - min
    s = l >= 0.5 ? d / (2 - (max + min)) : d / (max + min)
    switch (max) {
      case r:
        h = ((g - b) / d + 0) * 60
        break
      case g:
        h = ((b - r) / d + 2) * 60
        break
      case b:
        h = ((r - g) / d + 4) * 60
        break
      default:
    }
  }
  return [h, s, l]
}

const hslToHex = (h, s, l) => {
  var r, g, b

  if (s === 0) {
    r = g = b = l // achromatic
  } else {
    var hue2rgb = function hue2rgb(p, q, t) {
      if (t < 0) t += 1
      if (t > 1) t -= 1
      if (t < 1 / 6) return p + (q - p) * 6 * t
      if (t < 1 / 2) return q
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
      return p
    }

    var q = l < 0.5 ? l * (1 + s) : l + s - l * s
    var p = 2 * l - q
    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }

  ;[r, g, b] = [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
  const toHex = c => {
    var hex = c.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }
  return '#' + toHex(r) + toHex(g) + toHex(b)
}

const darken = (color, percent) => {
  let hsl = hexToHsl(color)
  hsl[2] = Math.max(hsl[2] - percent / 100, 0) // darken for given percent
  return hslToHex(hsl[0] / 360, hsl[1], hsl[2])
}

export default darken
