import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'

import DataBindingTable from '../../components/common/DataBindingTable'
import ConditionGroupEdit from '../../components/common/ConditionGroupEdit'

export class TaskOption extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    bot: PropTypes.object.isRequired,
    variables: PropTypes.array.isRequired,
    task_id: PropTypes.string,
    condition_groups: PropTypes.array,
    onRefresh: PropTypes.func,
    updateTableState: PropTypes.func,
    tableState: PropTypes.object,
  }

  constructor() {
    super()
    this.state = { isOpened: false, condition_id: undefined, type: 'task_condition' }
  }

  changeType = type => {
    this.setState({ type })
  }

  handleOpen = value => {
    this.setState({ isOpened: true, condition_id: value })
  }

  handleClose = () => {
    this.setState({ isOpened: false })
  }

  renderEditForm() {
    const { bot, variables, task_id } = this.props
    return (
      <ConditionGroupEdit
        container_type="task"
        onClose={this.handleClose}
        bot={bot}
        variables={variables}
        task_id={task_id}
        id={this.state.condition_id}
      />
    )
  }

  render() {
    const { t } = this.context
    const { condition_groups, onRefresh, task_id, tableState, updateTableState } = this.props
    let columns = [
      {
        Header: t('condition_group.name'),
        id: 'name',
        accessor: 'name',
        Cell: props => (
          <Link className="intent-rows" onClick={this.handleOpen.bind(this, props.original.id)}>
            {props.value}
          </Link>
        ),
      },
    ]

    const classDisable = task_id ? '' : ' disabled'
    columns[0].Footer = (
      <Link
        className={`dm-btn btn btn-primary btn-icon-plus mini${classDisable}`}
        onClick={this.handleOpen.bind(this, undefined)}
      >
        <span>{t('common.new')}</span>
      </Link>
    )

    return (
      <div className="dm-sidebar-content-with-selector">
        <ul className="selector clearfix">
          <li
            className={this.state.type === 'task_condition' ? 'selected' : ''}
            onClick={() => this.changeType('task_condition')}
          >
            {t('condition_group.title')}
          </li>
        </ul>
        <div className={`content ${this.state.type === 'task_condition' ? 'show' : ''}`}>
          <div className="dm-condition">
            <DataBindingTable
              title={t('condition_group.list')}
              items={condition_groups}
              columns={columns}
              onRefresh={onRefresh}
              tableState={tableState}
              updateTableState={updateTableState}
              showPageSizeOptions={false}
              tableName="TaskConditionGroupList"
            />
            {!task_id && <strong className="text-danger">{t('condition_group.taskDisableMessage')}</strong>}
            {this.state.isOpened && this.renderEditForm()}
          </div>
        </div>
      </div>
    )
  }
}

export default TaskOption
