import lodash from 'lodash'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import { updateNotice, removeNotice } from '../../actions/notice'
import Config from '../../helpers/config'

export class TrainNotice extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    bot: PropTypes.object,
    topics: PropTypes.array,
  }

  static defaultProps = {
    topics: [],
  }

  static UUID_FOR_TRAINING_NOTICE = '347281f6-b782-4607-aa5a-6e7bccc60313'

  getMessage = (bot, topics) => {
    const { t } = this.context
    const messages = {
      Enqueued: t('bot.training.waitTraining'),
      Error: t('bot.training.trainingError'),
      UnTrained: t('bot.training.needTrain'),
      NotFound: t('bot.training.notFound'),
      Training: t('bot.training.nowTraining'),
      LongTraining: t('bot.training.nowLongTraining'),
    }

    let status = bot.status
    if (!bot.is_training && bot.dirty && bot.status === 'Active') {
      status = 'UnTrained'
    }

    const topicsWithExamples = lodash.filter(topics, topic => !lodash.isEmpty(topic.examples))
    const isTakingTime = topicsWithExamples.length > Config.defaultMaxScenarios

    if (bot.status === 'Training' && isTakingTime) {
      status = 'LongTraining'
    }

    return messages[status]
  }

  componentDidMount() {
    const { dispatch } = this.props
    const message = this.getMessage(this.props.bot, this.props.topics)
    if (message) {
      dispatch(updateNotice(TrainNotice.UUID_FOR_TRAINING_NOTICE, 'train', message, { removable: false }))
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch } = this.props
    const prevMessage = this.getMessage(prevProps.bot, prevProps.topics)
    const nextMessage = this.getMessage(this.props.bot, this.props.topics)
    if (prevMessage === nextMessage) return
    if (nextMessage) {
      dispatch(updateNotice(TrainNotice.UUID_FOR_TRAINING_NOTICE, 'train', nextMessage, { removable: false }))
    } else {
      dispatch(removeNotice(TrainNotice.UUID_FOR_TRAINING_NOTICE))
    }
  }

  render() {
    return null
  }
}

export default connect()(TrainNotice)
