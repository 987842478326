import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field, FieldArray } from 'redux-form'
import { InputField, SelectField, CheckboxField } from '../../components/common/fields/FormFields'
import IncrementableTable from '../common/IncrementableTable'

export class TopicPatternTable extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  renderPatterns = ({ fields }) => {
    const { t } = this.context

    const typeItems = [
      { value: 'partial_match', display: t('topic.patterns.types.partial_match') },
      { value: 'exact_match', display: t('topic.patterns.types.exact_match') },
      { value: 'forward_match', display: t('topic.patterns.types.forward_match') },
      { value: 'backward_match', display: t('topic.patterns.types.backward_match') },
      { value: 'regex', display: t('topic.patterns.types.regex') },
    ]

    const columns = [
      {
        Cell: (name, index) => (
          <div>
            <div>
              <Field
                name={`${name}.pattern`}
                component={InputField}
                type="text"
                className="form-control dm-form-control"
                placeholder={index === 0 ? t('topic.patterns.placeholder') : ''}
                maxLength="280"
              />
            </div>
            <div>
              <Field
                name={`${name}.interrupt_execution`}
                type="checkbox"
                className="form-control dm-form-control"
                component={CheckboxField}
              />
              <label htmlFor={`${name}.interrupt_execution`} className="ignorecase">
                {t('topic.patterns.interruptExecution')}
              </label>
            </div>
          </div>
        ),
      },
      {
        Cell: (name, _index) => (
          <div>
            <div>
              <Field
                name={`${name}.type`}
                className="form-control dm-form-control"
                component={SelectField}
                items={typeItems}
                valueKey="value"
                displayKey="display"
              />
            </div>
            <div>
              <Field
                name={`${name}.case_sensitive`}
                type="checkbox"
                className="form-control dm-form-control"
                component={CheckboxField}
              />
              <label htmlFor={`${name}.case_sensitive`} className="ignorecase">
                {t('topic.patterns.ignoreCase')}
              </label>
            </div>
          </div>
        ),
      },
    ]

    return (
      <div>
        <IncrementableTable withHeader={false} fields={fields} columns={columns} maxRows={10} />
      </div>
    )
  }

  render() {
    return <FieldArray name="patterns" component={this.renderPatterns} />
  }
}

export default TopicPatternTable
