import lodash from 'lodash'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import classnames from 'classnames'

import {
  fetchRunningApplication,
  fetchChannels,
  setApplicationToken,
  setCurrentChannel,
  setChatStatus,
} from '../../actions/embedded'
import EmbeddedChat from './EmbeddedChat'
import ReactLoader from 'react-loader'

const generateCookieKey = (application_token, referrer) => {
  const pattern = /https?:\/\/([^/]+)/
  let url
  if (pattern.test(referrer)) {
    url = referrer
  } else {
    url = window.location.href
  }

  const domain = url.match(pattern)[1]
  return `embeddedChat.${domain}.${application_token}.channel_uuid`
}

const parseCookie = () => {
  return document.cookie.split(/;\s?/).reduce((hash, e) => {
    const [k, v] = e.split('=')
    hash[k] = v
    return hash
  }, {})
}

export class EmbeddedChatWrapper extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
  }
  static childContextTypes = {
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    t: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    status: PropTypes.string,
    messages: PropTypes.array,
    application_token: PropTypes.string.isRequired,
    link_token: PropTypes.string,
    channel_uuid: PropTypes.string,
    handleRefresh: PropTypes.func,
    notices: PropTypes.array,
    isSimulator: PropTypes.bool,
    referrer: PropTypes.string,
    formValues: PropTypes.object,

    position: PropTypes.oneOf(['position-left', 'position-right', 'frame']),
    isImmediate: PropTypes.bool,
    isAutoScroll: PropTypes.bool,
    showBotTypingMessage: PropTypes.bool,
    isOperatorTyping: PropTypes.bool,

    theme: PropTypes.string,
    autoHideScrollbar: PropTypes.bool,
    fontSize: PropTypes.string,
    colors: PropTypes.object,
    title: PropTypes.string,
    features: PropTypes.object,
    params: PropTypes.string,
    isHiddenToken: PropTypes.bool,
    messageCallbackEnabled: PropTypes.bool,
    speechRecognitionDisabled: PropTypes.bool,
    speechRecognitionContinuously: PropTypes.bool,
    speechRecognitionSendWait: PropTypes.number,
    speechRecognitionTimeBarDelay: PropTypes.number,
    speechSynthesisDisabled: PropTypes.bool,
    speechVolumeCallbackEnabled: PropTypes.bool,

    inputTextPlaceholder: PropTypes.string,
    inputTextDisabled: PropTypes.bool,
  }

  static defaultProps = {
    messages: [],
    handleRefresh: () => {},
    isSimulator: false,
    status: 'loading',
  }

  constructor() {
    super()
    this.state = { initialized: false }
  }

  getChildContext() {
    return { t: this.props.t }
  }

  componentDidMount() {
    const { dispatch, isSimulator, isHiddenToken } = this.props

    if (!isSimulator) {
      document.body.classList.add('embedded')

      if (isHiddenToken) {
        const location = window.location

        const hash = queryString.parse(location.search)
        dispatch(setApplicationToken(hash.token))
        delete hash['isHiddenToken']
        delete hash['token']

        const newQueryString = queryString.stringify(hash)
        const url = `${location.protocol}//${location.host}${location.pathname}?${newQueryString}`
        window.location.replace(url)
        return
      }
    }

    this.refresh()
    this.setState({ initialized: true })

    window.addEventListener('message', this.onFinishAuthenticationFlow)
    window.addEventListener('beforeunload', this.onBeforeUnload)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.application_token !== this.props.application_token) {
      this.refresh()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onBeforeUnload)
    window.removeEventListener('message', this.onFinishAuthenticationFlow)
    this.props.dispatch(setChatStatus('loading'))
  }

  refresh = () => {
    const { dispatch, application_token, referrer } = this.props

    dispatch(setChatStatus('loading'))
    dispatch(fetchRunningApplication(application_token))
      .then(features => {
        if (!features.skipCookieConsent) {
          document.body.classList.add('show-cookiebot')
        } else if (window.Cookiebot) {
          window.Cookiebot.submitCustomConsent(true, true, true)
        }

        if (features.authenticationType !== 'none') {
          this.signIn(features)
        } else {
          dispatch(fetchChannels(application_token, referrer))
            .then(response => {
              if (response.channel_uuid) {
                dispatch(setCurrentChannel(response.channel_uuid, response.client_uid))
              }
              dispatch(setChatStatus('initializing'))
            })
            .catch(() => {
              dispatch(setChatStatus('error'))
            })
        }
      })
      .catch(() => {
        dispatch(setChatStatus('error'))
      })
  }

  signIn = features => {
    const { dispatch, application_token, referrer } = this.props

    import('@aws-amplify/auth').then(({ Auth }) => {
      Auth.configure(features.amplifyConfiguration)
      Auth.currentAuthenticatedUser()
        .then(() => {
          dispatch(fetchChannels(application_token, referrer))
            .then(response => {
              if (response.channel_uuid) {
                dispatch(setCurrentChannel(response.channel_uuid, response.client_uid))
              }
              dispatch(setChatStatus('initializing'))
            })
            .catch(() => {
              dispatch(setChatStatus('error'))
            })
        })
        .catch(() => {
          dispatch(setChatStatus('preauthenticating'))
        })
    })
  }

  startAuthentication = () => {
    const { dispatch, application_token } = this.props
    if (this.authenticationPopup) {
      this.authenticationPopup.close()
    }

    this.authenticationPopup = window.open(
      `/embedded/authentication/${application_token}`,
      '_blank',
      'popup,width=640,height=640'
    )
    dispatch(setChatStatus('authenticating'))
  }

  onFinishAuthenticationFlow = ({ origin, data }) => {
    const { dispatch, features } = this.props

    if (origin !== location.origin) return
    if (data.type !== 'AuthenticationFlowFinished') return

    if (data.status === 'success') {
      lodash.each(data.cognitoSession, (v, k) => {
        localStorage[k] = v
      })
      this.signIn(features)
    } else {
      dispatch(setChatStatus('authentication_failed'))
    }
  }

  onBeforeUnload = () => {
    if (this.authenticationPopup) {
      this.authenticationPopup.close()
    }
  }

  render() {
    const { status, t } = this.props
    const { initialized } = this.state

    if (!initialized) {
      return <ReactLoader />
    }

    if (status === 'loading') {
      return <ReactLoader />
    } else if (status === 'preauthenticating') {
      return this.renderAuthentication(t('embeddedChat.signInRequired'), t('embeddedChat.signIn'))
    } else if (status === 'authenticating') {
      return this.renderAuthentication(t('embeddedChat.signInProcessing'), t('embeddedChat.retrySignIn'))
    } else if (status === 'authentication_failed') {
      return this.renderAuthentication(t('embeddedChat.signInFailed'), t('embeddedChat.retrySignIn'))
    }

    return <EmbeddedChat {...this.props} />
  }

  renderAuthentication(message, buttonLabel) {
    const { theme, autoHideScrollbar, fontSize, title, colors } = this.props

    const containerClass = classnames({
      fit: true,
      embedded: true,
      'dm-chat': true,
      'force-normal': true,
      [theme]: theme,
      autoHideScrollbar: autoHideScrollbar,
      [fontSize]: fontSize,
      paused: true,
    })

    return (
      <div className={containerClass}>
        <div className="foreground">
          <div className="content">
            <div className="title">{title}</div>
            <p className="message" dangerouslySetInnerHTML={{ __html: message }} />
            <div>
              <input
                className="dm-btn btn btn-primary start"
                style={{ backgroundColor: colors.sendButton, color: colors.sendButtonFont }}
                type="button"
                onClick={this.startAuthentication}
                value={buttonLabel}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const hash = queryString.parse(window.location.search)
  const application_token = props.application_token || hash.token || state.chat.applicationToken
  const link_token = hash.linkToken
  const cookie = parseCookie()
  const isSimulator = props.isSimulator
  const referrer = isSimulator ? null : hash.overrideReferrer || document.referrer
  const cookieKey = generateCookieKey(application_token, referrer)
  const theme = hash.theme
  const autoHideScrollbar = hash.autoHideScrollbar === 'true'
  const notices = lodash.filter(state.notice, { options: { source: 'messaging' } })
  const formValues = getFormValues('EmbeddedChat')(state) || {}
  const params = hash.params

  const validateColorCode = code => {
    return code ? (code.match(/^#[0-9a-f]{3}$|^#[0-9a-f]{6}$/) ? code : '') : ''
  }
  const colors =
    theme === 'custom'
      ? {
          background: validateColorCode(hash.background_bg),
          backgroundFont: validateColorCode(hash.background_font),
          botBalloon: validateColorCode(hash.botBalloon_bg),
          botBalloonFont: validateColorCode(hash.botBalloon_font),
          userBalloon: validateColorCode(hash.userBalloon_bg),
          userBalloonFont: validateColorCode(hash.userBalloon_font),
          sendButton: validateColorCode(hash.sendButton_bg),
          sendButtonFont: validateColorCode(hash.sendButton_font),
        }
      : {}

  const fontSizes = {
    [-3]: 'x2-small',
    [-2]: 'x-small',
    [-1]: 'small',
    0: '',
    1: 'large',
    2: 'x-large',
    3: 'x2-large',
  }

  const fontSize = fontSizes[hash.fontSize]

  return {
    status: state.chat.status,
    application_token,
    link_token,
    channel_uuid: state.chat.currentChannel || props.channel_uuid || cookie[cookieKey],
    messages: state.chat.messages,
    suggestion: state.chat.suggestion,
    features: state.chat.features || {},
    notices,
    referrer,
    formValues,
    isAutoScroll: state.chat.isAutoScroll,
    showBotTypingMessage: state.chat.isBotTyping && !state.chat.preventBotTypingMessage,
    isOperatorTyping: state.chat.isOperatorTyping,

    position: hash.position,
    isImmediate: hash.isImmediate === 'true',
    theme,
    autoHideScrollbar,
    fontSize,
    colors,
    title: hash.title,
    params,
    isHiddenToken: hash.isHiddenToken === 'true',
    messageCallbackEnabled: hash.messageCallbackEnabled === 'true',
    speechRecognitionDisabled: hash.speechRecognitionDisabled === 'true',
    speechRecognitionContinuously: hash.speechRecognitionContinuously === 'true',
    speechRecognitionSendWait: isNaN(parseInt(hash.speechRecognitionSendWait))
      ? undefined
      : parseInt(hash.speechRecognitionSendWait),
    speechRecognitionTimeBarDelay: isNaN(parseInt(hash.speechRecognitionTimeBarDelay))
      ? undefined
      : parseInt(hash.speechRecognitionTimeBarDelay),
    speechSynthesisDisabled: hash.speechSynthesisDisabled === 'true',
    speechVolumeCallbackEnabled: hash.speechVolumeCallbackEnabled === 'true',
    inputTextPlaceholder: hash.inputTextPlaceholder,
    inputTextDisabled: hash.inputTextDisabled === 'true',
  }
}

const LocalizedEmbeddedChat = withTranslation()(EmbeddedChatWrapper)

export default connect(mapStateToProps)(LocalizedEmbeddedChat)
