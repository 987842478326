import classnames from 'classnames'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form'
import { CheckboxField, FileField } from '../../components/common/fields/FormFields'
import LabelWithTooltip from '../common/LabelWithTooltip'
import Loader from '../../components/common/Loader'
import AbstractAction from './AbstractAction'
import VariableField from '../common/fields/VariableField'
import UrlField from '../common/fields/UrlField'
import { isValidUrlOrVariable } from '../../helpers/validation'
import { hasVariable } from '../../helpers/actionHelper'

export class UploadImageAction extends AbstractAction {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    onChangeImageMethod: PropTypes.func.isRequired,
    onChangeImageFile: PropTypes.func.isRequired,
  }

  constructor() {
    super()
    this.state = { ...this.state, previewImageUrl: undefined }
  }

  componentDidMount() {
    const { action } = this.props

    if (super.componentDidMount) {
      super.componentDidMount()
    }
    if (!hasVariable(action.image_url)) {
      this.setState({ previewImageUrl: action.image_url })
    }
  }

  static validate = action => {
    const errors = super.validate(action)

    if (action.image_method === 'upload') {
      if (action.isInvalidImage) {
        errors.upload_file = 'validate.invalidImage'
      } else if (!action.upload_image_url) {
        errors.upload_file = 'validate.required'
      }
    }

    return errors
  }

  getTitle() {
    return this.context.t('topic.actionList.upload_image.title')
  }

  onChangeImageFile = e => {
    const { t } = this.context

    this.props.onChangeImageFile(e, url => {
      this.setState({ previewImageUrl: url })
      if (url == null) window.alert(t('validate.invalidImage'))
    })
  }

  onBlurImageUrl = e => {
    const image_url = e.nativeEvent.target.value
    if (isValidUrlOrVariable(image_url) && !hasVariable(image_url)) {
      this.setState({ previewImageUrl: image_url })
    } else {
      this.setState({ previewImageUrl: null })
    }
  }

  onChangeImageMethod = e => {
    const { action } = this.props

    let url
    if (e.nativeEvent.target.value === 'upload') {
      url = action.upload_image_url
    } else if (e.nativeEvent.target.value === 'url') {
      url = action.input_url
    }
    if (!hasVariable(url)) {
      this.setState({ previewImageUrl: url })
    } else {
      this.setState({ previewImageUrl: null })
    }

    this.props.onChangeImageMethod(e)
  }

  onErrorImageLoading = _e => {
    const { t } = this.context
    const { action } = this.props
    if (action.image_method === 'upload') {
      this.props.onError(t('topic.actionList.upload_image.errors.failedToLoadUploadedImage'), {
        level: 'warn',
      })
    } else {
      this.props.onError(t('topic.actionList.upload_image.errors.failedToLoadExternalImage'), {
        level: 'warn',
      })
    }
  }

  renderBody() {
    const { name, isLoadedImage, action } = this.props
    const { t } = this.context

    const useActionClass = classnames({
      'dm-title-mini': true,
      'pl-1': true,
      disabled: action.image_method !== 'upload',
    })

    return (
      <div className="upload_image">
        <div className="form-group row dm-form-group">
          <div className="control-label col-lg-4 col-md-4 col-sm-12">
            <LabelWithTooltip htmlFor="title" name="topic.actionList.upload_image.uploadImageTitle" />
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <VariableField name={`${name}.title`} className="form-control dm-form-control" nullable={true} />
          </div>
        </div>
        <div className="form-group row dm-form-group">
          <LabelWithTooltip
            className="dm-title-mini col-sm-3 col-md-4"
            name="topic.actionList.upload_image.image"
          />
          <div className="form-inline col-sm-4 col-md-4">
            <Field
              key={`${name}-upload`}
              name={`${name}.image_method`}
              id={`${name}-upload`}
              type="radio"
              value="upload"
              className="form-control"
              component="input"
              onChange={this.onChangeImageMethod}
            />
            <LabelWithTooltip
              htmlFor={`${name}-upload`}
              className="dm-checkbox"
              name="topic.actionList.carousel.uploadImage"
              direction="left"
            />
          </div>
          <div className="form-inline col-sm-5 col-md-4">
            <Field
              key={`${name}-url`}
              name={`${name}.image_method`}
              id={`${name}-url`}
              type="radio"
              value="url"
              className="form-control"
              component="input"
              onChange={this.onChangeImageMethod}
            />
            <LabelWithTooltip
              htmlFor={`${name}-url`}
              className="dm-checkbox"
              name="topic.actionList.carousel.pasteUrl"
              direction="left"
            />
          </div>
        </div>
        <div className="form-group row dm-form-group">
          <div className="col-sm-5 col-md-4">
            <Loader loaded={isLoadedImage} type="overlay">
              <div className="chatbot-icon-preview">
                <img
                  className="upload-image-preview"
                  src={this.state.previewImageUrl}
                  alt=""
                  onError={this.onErrorImageLoading}
                />
              </div>
            </Loader>
          </div>
          <div className="col-sm-7 col-md-8">
            <div className={action.image_method === 'upload' ? '' : 'hidden'}>
              <Field
                type="file"
                name={`${name}.upload_file`}
                accept="image/jpeg"
                className="form-control dm-form-control"
                component={FileField}
                onChange={this.onChangeImageFile}
              />
              <ul className="notes">
                <li>{t('topic.actionList.upload_image.authorizedFileFormat')}</li>
                <li>{t('topic.actionList.upload_image.maximumImageSize')}</li>
                <li>{t('topic.actionList.upload_image.maxFileSize')}</li>
              </ul>
            </div>
            <div className={action.image_method === 'url' ? '' : 'hidden'}>
              <UrlField
                name={`${name}.input_url`}
                className="form-control dm-form-control"
                onBlur={e => this.onBlurImageUrl(e)}
                ignoreValidation={(value, allValues) =>
                  lodash.get(allValues, `${name}.image_method`) !== 'url'
                }
              />
              <ul className="notes">
                <li>{t('topic.actionList.upload_image.notes.authorizedFileFormat')}</li>
                <li>{t('topic.actionList.upload_image.notes.protocol')}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="form-group dm-form-group">
          <Field
            type="checkbox"
            name={`${name}.use_action`}
            component={CheckboxField}
            disabled={action.image_method !== 'upload'}
            title={
              action.image_method !== 'upload'
                ? t('topic.actionList.upload_image.action.disabledTooltip')
                : ''
            }
          />
          <LabelWithTooltip
            className={useActionClass}
            name="topic.actionList.upload_image.action.title"
            htmlFor={`${name}.use_action`}
            direction="left top"
            title={
              action.image_method !== 'upload'
                ? t('topic.actionList.upload_image.action.disabledTooltip')
                : ''
            }
          />
          {action.use_action && (
            <div className="form-group row dm-form-group">
              <div className="control-label col-lg-4 col-md-4 col-sm-12">
                <LabelWithTooltip htmlFor="title" name="topic.actionList.upload_image.action.uri" />
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12">
                <UrlField
                  name={`${name}.open_url`}
                  className="form-control dm-form-control"
                  nullable={false}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default UploadImageAction
