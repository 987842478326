import lodash from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form'
import { TextAreaField } from './FormFields'
import BaseField from './BaseField'

import { lengthWithoutVariableAndUrl } from '../../../helpers/validation'

export class CardTextField extends BaseField {
  static propTypes = {
    ...BaseField.propTypes,
    ignoreValidation: PropTypes.func,
  }

  constructor() {
    super()
    this.validate = this.validate.bind(this)
  }

  validate(value, allValues, props) {
    const { ignoreValidation } = this.props
    if (ignoreValidation && ignoreValidation(value, allValues, props)) return undefined

    const error = super.validate(value, allValues, props)
    if (error) return error

    if (value) {
      if (lengthWithoutVariableAndUrl(value) > 280 || value.length > 1000) {
        return { id: 'validate.exceededMaxLength', values: { length: 280 } }
      }
    }
  }

  render() {
    const omitKeys = ['ignoreValidation', 'maxLength', 'nullable']
    return (
      <Field
        {...lodash.omit(this.props, omitKeys)}
        maxLength="1000"
        parse={value => (value === '' ? null : value)}
        component={TextAreaField}
        validate={this.validate}
      />
    )
  }
}

export default CardTextField
