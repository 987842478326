import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes } from 'redux-form'
import classnames from 'classnames'
import SortableTree, { getVisibleNodeCount } from 'react-sortable-tree'
import { Link } from 'react-router'

import Config from '../../../helpers/config'

import { addNotice } from '../../../actions/notice'
import {
  buildTree,
  clearTree,
  updateTree,
  startRenaming,
  cancelEditing,
  finishEditing,
  addScenario,
  addDomain,
} from '../../../actions/scenarioTree'
import { fetchBot } from '../../../actions/bot'
import { createTopic, deleteTopic, renameTopic, moveTopic, fetchTopics } from '../../../actions/topic'
import { createDomain, deleteDomain, renameDomain, moveDomain, fetchDomains } from '../../../actions/domain'

import theme from './theme'
import styles from './styles.module.scss'

export class ScenarioTree extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    bot: PropTypes.object.isRequired,
    domains: PropTypes.array.isRequired,
    topics: PropTypes.array.isRequired,
    treeData: PropTypes.array.isRequired,
    buildTree: PropTypes.func.isRequired,
    clearTree: PropTypes.func.isRequired,
    updateTree: PropTypes.func.isRequired,
    startRenaming: PropTypes.func.isRequired,
    finishEditing: PropTypes.func.isRequired,
    cancelEditing: PropTypes.func.isRequired,
    addScenario: PropTypes.func.isRequired,
    addDomain: PropTypes.func.isRequired,
    fetchBot: PropTypes.func.isRequired,
    fetchTopics: PropTypes.func.isRequired,
    createTopic: PropTypes.func.isRequired,
    deleteTopic: PropTypes.func.isRequired,
    moveTopic: PropTypes.func.isRequired,
    renameTopic: PropTypes.func.isRequired,
    fetchDomains: PropTypes.func.isRequired,
    createDomain: PropTypes.func.isRequired,
    deleteDomain: PropTypes.func.isRequired,
    moveDomain: PropTypes.func.isRequired,
    renameDomain: PropTypes.func.isRequired,
    addNotice: PropTypes.func.isRequired,
    topicsStyle: PropTypes.string.isRequired,
    maxScenarios: PropTypes.number.isRequired,
    updateTopicsStyle: PropTypes.func.isRequired,
  }

  static defaultProps = {
    treeData: [],
  }

  componentDidMount() {
    const { buildTree, domains, topics } = this.props
    buildTree(domains, topics)
  }

  componentWillUnmount() {
    const { clearTree } = this.props
    clearTree()
  }

  componentDidUpdate(prevProps) {
    const { buildTree } = this.props
    if (
      prevProps.domains.length !== this.props.domains.length ||
      prevProps.topics.length !== this.props.topics.length
    ) {
      buildTree(this.props.domains, this.props.topics)
    }
  }

  onChange = treeData => {
    this.setState({ treeData })
  }

  canDrag = ({ node }) => {
    return !node.renaming && !node.isSystem
  }

  canDrop = ({ node, nextParent, nextPath }) => {
    //  Prevent exceeding max depth only domains
    const calculateDepth = (node, depth = 0) => {
      if (node.type !== 'domain') return depth
      if (lodash.isEmpty(node.children)) return depth + 1
      return lodash.max(lodash.map(node.children || [], child => calculateDepth(child, depth + 1)))
    }
    const depth = calculateDepth(node)
    if (nextPath.length - 1 + depth >= 6) return false

    //  Prevent domain which has duplicate name on same level
    const matcher = el => {
      return el.type === 'domain' && node.type === 'domain' && el.title === node.title && el.id !== node.id
    }
    const children = nextParent ? nextParent.children : this.props.treeData
    if (lodash.filter(children, matcher).length > 0) return false

    return true
  }

  onKeyDownInTextbox = (event, { node, path, parentNode }) => {
    const { cancelEditing } = this.props
    const VK_RETURN = 0x0d
    const VK_ESCAPE = 0x1b
    if (event.keyCode === VK_RETURN) {
      this.onFinishEditing(event, { node, path, parentNode })
    }
    if (event.keyCode === VK_ESCAPE) {
      cancelEditing()
    }
  }

  onFinishEditing = (event, { node, path, parentNode }) => {
    const state = this.context.store.getState()
    const {
      bot,
      domains,
      handleSubmit,
      cancelEditing,
      finishEditing,
      fetchBot,
      fetchTopics,
      createTopic,
      createDomain,
      renameTopic,
      renameDomain,
    } = this.props
    event.stopPropagation()

    //  Handle tree root as root domain
    const rootDomain = lodash.find(domains, { parent_id: null })
    const parentId = parentNode ? parentNode.id : rootDomain.id

    handleSubmit(data => {
      //  Prevent request when name does not changed
      if (node.title === data.newName) {
        cancelEditing()
        return
      }
      //  Prevent request with empty name both of creat and edit
      if (data.newName.trim() === '') {
        cancelEditing()
        return
      }

      if (node.creating) {
        if (node.type === 'domain') {
          const domain = {
            bot_id: bot.id,
            parent_id: parentId,
            name: data.newName,
          }
          createDomain(state.session.token, domain).then(() => {
            fetchBot(state.session.token, bot.id)
          })
        } else {
          const topic = {
            bot_id: bot.id,
            domain_id: parentId,
            name: data.newName,
            is_draft: true,
          }
          createTopic(state.session.token, topic).then(() => {
            fetchBot(state.session.token, bot.id)
          })
        }
      } else {
        if (node.type === 'domain') {
          renameDomain(state.session.token, node.id, data.newName)
            .then(() => fetchTopics(state.session.token, { bot_id: bot.id }))
            .then(() => finishEditing(node, path, data.newName))
            .then(() => fetchBot(state.session.token, bot.id))
        } else {
          renameTopic(state.session.token, node.id, data.newName)
            .then(() => finishEditing(node, path, data.newName))
            .then(() => fetchBot(state.session.token, bot.id))
        }
      }
    })(event)
  }

  onAddScenario = (event, { node, path }) => {
    const { t } = this.context
    const { topics, initialize, cancelEditing, addScenario, addNotice, maxScenarios } = this.props
    event.stopPropagation()
    cancelEditing()

    const remaining = maxScenarios - topics.length
    if (remaining <= 0) {
      addNotice('warn', t('topic.topicsLimitation', { maximum: maxScenarios }))
      return
    }

    initialize({ newName: '' })
    addScenario(node, path)

    //  Scroll to input element automatically
    if (!node) {
      setTimeout(() => {
        const container = document.querySelector('.rst__virtualScrollOverride')
        if (typeof container.scrollTo === 'function') {
          container.scrollTo(0, container.scrollHeight)
        } else {
          container.scrollLeft = 0
          container.scrollTop = container.scrollHeight
        }
      })
    }
  }

  onAddDomain = (event, { node, path }) => {
    const { t } = this.context
    const { domains, initialize, cancelEditing, addDomain, addNotice } = this.props
    event.stopPropagation()
    cancelEditing()

    const remaining = Config.maximumDomainsNum - lodash.filter(domains, 'parent_id').length
    if (remaining <= 0) {
      addNotice('warn', t('topic.domainsLimitation', { maximum: Config.maximumDomainsNum }))
      return
    }

    initialize({ newName: '' })
    addDomain(node, path)

    //  Scroll to input element automatically
    if (!node) {
      setTimeout(() => {
        const container = document.querySelector('.rst__virtualScrollOverride')
        if (typeof container.scrollTo === 'function') {
          container.scrollTo(0, container.scrollHeight)
        } else {
          container.scrollLeft = 0
          container.scrollTop = container.scrollHeight
        }
      })
    }
  }

  onMoveNode = ({ treeData, prevPath, nextPath, prevTreeIndex, nextTreeIndex, node, nextParentNode }) => {
    if (lodash.isEqual(prevPath, nextPath) && prevTreeIndex === nextTreeIndex) return

    const state = this.context.store.getState()
    const { bot, domains, topics, buildTree, fetchBot, moveDomain, moveTopic } = this.props

    //  Handle tree root as root domain
    const rootDomain = lodash.find(domains, { parent_id: null })
    const nextDomainId = nextParentNode ? nextParentNode.id : rootDomain.id

    const children = nextParentNode ? nextParentNode.children : treeData
    const nextOrder = lodash.findIndex(children, node) + 1

    let movePromise
    if (node.type === 'domain') {
      movePromise = moveDomain(state.session.token, node.id, nextDomainId, nextOrder, bot.updated_at)
    } else {
      movePromise = moveTopic(state.session.token, node.id, nextDomainId, nextOrder, bot.updated_at)
    }

    movePromise
      .then(() => fetchBot(state.session.token, bot.id))
      .catch(() => {
        buildTree(domains, topics)
      })
  }

  getTextboxWidth(text) {
    const canvas =
      this.getTextboxWidth.canvas || (this.getTextboxWidth.canvas = document.createElement('canvas'))
    const context = canvas.getContext('2d')
    context.font = '16px Meiryo'
    const metrics = context.measureText(text)
    return Math.max(120, metrics.width + 10)
  }

  generateNodeProps = rowInfo => {
    const className = classnames({
      [styles.row]: true,
      [styles.renaming]: rowInfo.node.renaming,
    })
    return {
      className: className,
      title: this.generateTitle(rowInfo),
      icons: this.generateIcons(rowInfo),
      buttons: this.generateButtons(rowInfo),
      draggable: !rowInfo.node.renaming,
    }
  }

  generateIcons = ({ node }) => {
    const { t } = this.context
    const iconStyles = {
      scenario: styles.iconScenario,
      draftScenario: styles.iconDraftScenario,
      scenarioWithoutAction: styles.iconScenarioWithoutAction,
      domain: styles.iconDomain,
    }

    if (node.type === 'scenario' && node.is_draft) {
      return [<i key="icon" className={iconStyles['draftScenario']} title={t('bot.tree.draftScenario')} />]
    }

    if (node.type === 'scenario' && !node.with_action) {
      return [
        <i
          key="icon"
          className={iconStyles['scenarioWithoutAction']}
          title={t('bot.tree.scenarioWithoutAction')}
        />,
      ]
    }
    return [<i key="icon" className={iconStyles[node.type]} />]
  }

  generateButtons = ({ node, path, parentNode }) => {
    const { t } = this.context
    const { initialize, startRenaming, cancelEditing } = this.props
    if (node.renaming || node.creating) {
      return [
        <i
          key="button"
          className={styles.iconComplete}
          title={t('common.save')}
          onMouseDown={e => e.stopPropagation()}
          onClick={event => this.onFinishEditing(event, { node, path, parentNode })}
        />,
      ]
    }

    const buttons = []
    if (node.type === 'domain') {
      buttons.push(
        <i
          className={styles.iconAddScenario}
          title={t('bot.tree.addScenario')}
          onMouseDown={e => e.stopPropagation()}
          onClick={event => this.onAddScenario(event, { node, path })}
        />
      )

      if (path.length === 5) {
        buttons.push(<i className={styles.iconDisabledAddDomain} title={t('bot.tree.disabledAddDomain')} />)
      } else {
        buttons.push(
          <i
            className={styles.iconAddDomain}
            title={t('bot.tree.addDomain')}
            onMouseDown={e => e.stopPropagation()}
            onClick={event => this.onAddDomain(event, { node, path })}
          />
        )
      }
    }

    buttons.push(
      <i
        className={styles.iconEdit}
        title={t('bot.tree.rename')}
        onMouseDown={e => e.stopPropagation()}
        onClick={e => {
          e.stopPropagation()
          cancelEditing()
          initialize({ newName: node.title })
          startRenaming(node, path)
        }}
      />
    )
    buttons.push(
      <i
        className={styles.iconRemove}
        title={t('common.delete')}
        onMouseDown={e => e.stopPropagation()}
        onClick={() => this.onDeleteRow({ node, path })}
      />
    )

    return buttons
  }

  generateTitle = ({ node, path, parentNode }) => {
    const { t } = this.context
    const { bot } = this.props

    if (node.renaming || node.creating) {
      return (
        <Field
          className={styles.renameTextBox}
          style={{ width: `${this.getTextboxWidth(node.title)}px` }}
          name="newName"
          type="text"
          value={node.title}
          draggable={false}
          onClick={e => e.stopPropagation()}
          onMouseDown={e => e.stopPropagation()}
          onKeyDown={e => this.onKeyDownInTextbox(e, { node, path, parentNode })}
          onFocus={e => e.target.select()}
          component="input"
          autoFocus={true}
        />
      )
    }

    if (node.type === 'scenario') {
      return (
        <span>
          <Link className={styles.rowLabel} to={`/bots/${bot.id}/topics/${node.id}`}>
            {node.title}
          </Link>
          {node.is_draft && <span className="label label-draft in-tree">{t('common.draft')}</span>}
        </span>
      )
    } else {
      return <span className={styles.rowLabel}>{node.title}</span>
    }
  }

  onDeleteRow = ({ node }) => {
    const { t } = this.context
    const state = this.context.store.getState()
    const { bot, fetchBot, fetchDomains, fetchTopics, deleteDomain, deleteTopic, buildTree } = this.props
    if (node.type === 'domain') {
      const message = t('bot.tree.messages.deleteDomain', { name: node.title })
      if (!window.confirm(message)) return
      deleteDomain(state.session.token, node.id).then(() => {
        fetchDomains(state.session.token, { bot_id: bot.id }).then(result => {
          const domains = result.entities.domains
          fetchTopics(state.session.token, { bot_id: bot.id }).then(result => {
            const normalTopics = lodash.filter(result.entities.topics, { type: 'normal' })
            buildTree(domains, normalTopics)
          })
        })
      })
    } else {
      const message = t('bot.tree.messages.deleteScenario', { name: node.title })
      if (!window.confirm(message)) return
      deleteTopic(state.session.token, node.id).then(() => {
        //  Reload dirty flag
        fetchBot(state.session.token, bot.id)
      })
    }
  }

  render() {
    const { t } = this.context
    const {
      treeData,
      bot,
      domains,
      topics,
      topicsStyle,
      updateTopicsStyle,
      updateTree,
      cancelEditing,
      maxScenarios,
    } = this.props
    const itemCount = getVisibleNodeCount({ treeData })
    const height = Math.min(itemCount * 25, 400)

    const topicsRemaining = maxScenarios - topics.length
    const addTopicButtonClass = topicsRemaining <= 0 ? 'btn-alert' : 'btn-primary'

    const domainsRemaining = Config.maximumDomainsNum - lodash.filter(domains, 'parent_id').length
    const addDomainButtonClass = domainsRemaining <= 0 ? 'btn-alert' : 'btn-primary'

    const component = !lodash.isEmpty(treeData) ? (
      <SortableTree
        treeData={treeData}
        getNodeKey={({ node }) => `${node.type}:${node.id}`}
        onChange={updateTree}
        onMoveNode={this.onMoveNode}
        onDragStateChanged={this.onDragStateChanged}
        generateNodeProps={this.generateNodeProps}
        canDrag={this.canDrag}
        canDrop={this.canDrop}
        canNodeHaveChildren={node => node.type !== 'scenario'}
        maxDepth={7}
        theme={theme}
      />
    ) : (
      <div className={styles.noData}>{t('common.noRowsFound')}</div>
    )

    return (
      <div className={styles.scenarioTreeContainer}>
        <div
          className={classnames(styles.scenarioTree)}
          style={{ height: height }}
          onMouseDown={cancelEditing}
        >
          {component}
        </div>
        <div className={classnames('clearfix', styles.footer)}>
          <div className="pull-left">
            <Link
              className={`dm-btn btn ${addTopicButtonClass} btn-icon-plus mini`}
              onClick={e => this.onAddScenario(e, {})}
            >
              <span>{t('bot.tree.addScenario')}</span>
            </Link>
            <Link
              className={`dm-btn btn ${addDomainButtonClass} btn-icon-plus mini`}
              onClick={e => this.onAddDomain(e, {})}
            >
              <span>{t('bot.tree.addDomain')}</span>
            </Link>
            <Link className="dm-btn btn btn-primary btn-icon-upload mini" to={`/bots/${bot.id}/import`}>
              <span>{t('common.import')}</span>
            </Link>
            <label>{t('bot.tree.displayStyle')}</label>
            <select value={topicsStyle} onChange={e => updateTopicsStyle(e.target.value)}>
              <option value="table">{t('bot.tree.displayStyles.table')}</option>
              <option value="tree">{t('bot.tree.displayStyles.tree')}</option>
            </select>
          </div>
          <div className="pull-right">
            {t('topic.remaining', { count: topics.length, total: maxScenarios })}
          </div>
        </div>
      </div>
    )
  }
}

const ScenarioTreeForm = reduxForm({
  form: 'ScenarioTree',
})(ScenarioTree)

const mapStateToProps = state => {
  return {
    treeData: state.scenarioTree.treeData,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      buildTree,
      clearTree,
      updateTree,
      startRenaming,
      finishEditing,
      cancelEditing,
      addScenario,
      addDomain,
      fetchBot,
      fetchTopics,
      createTopic,
      deleteTopic,
      moveTopic,
      renameTopic,
      fetchDomains,
      createDomain,
      deleteDomain,
      moveDomain,
      renameDomain,
      addNotice,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioTreeForm)
