import React from 'react'

import AbstractAction from './AbstractAction'

export class CompleteTaskAction extends AbstractAction {
  getTitle() {
    return this.context.t('topic.actionList.complete_task.title')
  }
  getType() {
    return 'stop'
  }

  renderBody() {
    const { t } = this.context

    return <div className="complete_task">{t('topic.actionList.rerun_scenario.noParameter')}</div>
  }
}

export default CompleteTaskAction
