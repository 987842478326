import lodash from 'lodash'
import { normalize } from 'normalizr'
import fetch from 'isomorphic-fetch'
import queryString from 'query-string'
import i18n from 'i18next'

import { requestAPI, responseAPI, errorAPI, closeAPI } from '../actions/api'

const parseJSON = response => {
  if (response.status === 204) return {}

  if (response.headers.get('Content-Type') === 'application/json') {
    return response.json()
  } else {
    return response.blob()
  }
}

const defaultOptions = {
  preserve: true,
  headers: {},
  dispatch: () => {},
}

const controller = new window.AbortController()

export default (host, path, options) => {
  const {
    preserve,
    headers,
    method,
    body,
    token,
    schema,
    formatter,
    dispatch,
    target,
    source,
    fetchOptions,
  } = lodash.merge({}, defaultOptions, options)
  const identifier = `${method}: ${path}`
  let url = `${host}${path}`

  headers['Accept-Language'] = i18n.language
  headers['Accept'] = 'application/json'
  if (token) {
    headers['Authorization'] = `JWT ${token}`
  }
  let request_body
  if (body) {
    if (method === 'GET' || method === 'HEAD') {
      url += '?' + queryString.stringify(body)
    } else {
      if (body instanceof FormData) {
        request_body = body
      } else {
        headers['Content-Type'] = 'application/json'
        request_body = JSON.stringify(body)
      }
    }
  }

  if (target) {
    dispatch(requestAPI(target, identifier))
  }

  let promise = fetch(url, {
    signal: controller.signal,
    headers,
    method,
    body: request_body,
    ...fetchOptions,
  })
    .then(options.errorHandler(dispatch, path))
    .then(parseJSON)

  if (formatter) {
    promise = promise.then(formatter)
  }

  if (schema) {
    promise = promise.then(json => normalize(json, schema))
  }

  return promise
    .then(json => {
      dispatch(closeAPI(target, identifier))
      dispatch(responseAPI(target, json, preserve))
      return json
    })
    .catch(e => {
      dispatch(closeAPI(target, identifier))

      //  Ignore canceled request by reload browser while requesting
      if (e.name === 'AbortError') throw e

      if (!options.ignoreNotice) {
        if (e.message === 'Failed to fetch') {
          // Chrome
          const message = i18n.translator.translate('error.networkError')
          dispatch(errorAPI(target, source, new e.constructor(message)))
        } else if (e.message === 'NetworkError when attempting to fetch resource.') {
          // Firefox
          const message = i18n.translator.translate('error.networkError')
          dispatch(errorAPI(target, source, new e.constructor(message)))
        } else {
          dispatch(errorAPI(target, source, e))
        }
      }
      throw e
    })
}

export const abortAllRequests = () => {
  controller.abort()
}

// Convert to FormData from object
export const convertToFormData = data => {
  const formData = new FormData()
  lodash.forEach(data, (value, key) => {
    if (value === undefined) return

    if (value instanceof File) {
      formData.append(key, value)
    } else if (value instanceof Object) {
      formData.append(key, JSON.stringify(value))
    } else {
      formData.append(key, value)
    }
  })
  return formData
}
