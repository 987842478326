import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import { InputField, CheckboxField } from '../../common/fields/FormFields'
import Tooltip from '../../common/Tooltip'
import LabelWithTooltip from '../../common/LabelWithTooltip'
import VariableField from '../../common/fields/VariableField'

export class SelectRow extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    name: PropTypes.string,
    index: PropTypes.number,
    field: PropTypes.object,
  }

  static defaultProps = {
    field: {},
  }

  static rowSpan = field => {
    if (field.arguments.data_type === 'constant') {
      return 5
    } else {
      return 7
    }
  }

  render() {
    const { t } = this.context
    const { name, index, field } = this.props

    return (
      <React.Fragment>
        <tr key={`${index}-dataType`}>
          <td colSpan="3">
            <div className="dm-table-form">
              <label>{t('topic.actionList.form.fieldTable.dataType')}</label>
              <div className="col-xs-4">
                <Field
                  key="constant"
                  name={`${name}.arguments.data_type`}
                  id={`${name}-dataType-constant`}
                  type="radio"
                  value="constant"
                  className="form-control"
                  component="input"
                />
                <LabelWithTooltip
                  htmlFor={`${name}-dataType-constant`}
                  name="topic.actionList.form.fieldTable.constant"
                />
              </div>
              <div className="col-xs-4">
                <Field
                  key="variable"
                  name={`${name}.arguments.data_type`}
                  id={`${name}-dataType-variable`}
                  type="radio"
                  value="variable"
                  className="form-control"
                  component="input"
                />
                <LabelWithTooltip
                  htmlFor={`${name}-dataType-variable`}
                  name="topic.actionList.form.fieldTable.variable"
                />
              </div>
            </div>
          </td>
        </tr>
        {field.arguments.data_type === 'constant' && (
          <tr key={`${index}-data`}>
            <td colSpan="3">
              <div className="dm-table-form">
                <label>{t('topic.actionList.form.fieldTable.data')}</label>
                <Field name={`${name}.arguments.data`} component={InputField} className="form-control" />
                <Tooltip name="topic.tooltip.actionList.form.fieldTable.data" />
              </div>
            </td>
          </tr>
        )}
        {field.arguments.data_type === 'variable' && (
          <React.Fragment>
            <tr key={`${index}-listVariable`}>
              <td colSpan="3">
                <div className="dm-table-form">
                  <label>{t('topic.actionList.form.fieldTable.listVariable')}</label>
                  <VariableField
                    name={`${name}.arguments.list_variable`}
                    component={InputField}
                    className="form-control"
                  />
                  <Tooltip name="topic.tooltip.actionList.form.fieldTable.listVariable" />
                </div>
              </td>
            </tr>
            <tr key={`${index}-itemLabel`}>
              <td colSpan="3">
                <div className="dm-table-form with-indent">
                  <label>{t('topic.actionList.form.fieldTable.itemLabel')}</label>
                  <Field
                    name={`${name}.arguments.item_label`}
                    component={InputField}
                    className="form-control"
                  />
                  <Tooltip name="topic.tooltip.actionList.form.fieldTable.itemLabel" />
                </div>
              </td>
            </tr>
            <tr key={`${index}-itemValue`}>
              <td colSpan="3">
                <div className="dm-table-form with-indent">
                  <label>{t('topic.actionList.form.fieldTable.itemValue')}</label>
                  <Field
                    name={`${name}.arguments.item_value`}
                    component={InputField}
                    className="form-control"
                  />
                  <Tooltip name="topic.tooltip.actionList.form.fieldTable.itemValue" />
                </div>
              </td>
            </tr>
          </React.Fragment>
        )}
        <tr key={`${index}-defaultValue`}>
          <td colSpan="3">
            <div className="dm-table-form">
              <label>{t('topic.actionList.form.fieldTable.defaultValue')}</label>
              <Field
                name={`${name}.arguments.default_value`}
                component={InputField}
                className="form-control"
              />
              <Tooltip name="topic.tooltip.actionList.form.fieldTable.defaultValue" />
            </div>
          </td>
        </tr>
        <tr key={`${index}-addEmpty`}>
          <td colSpan="3">
            <div className="dm-table-form ml-5">
              <Field
                name={`${name}.arguments.add_empty`}
                type="checkbox"
                component={CheckboxField}
                className="form-control"
              />
              <LabelWithTooltip
                htmlFor={`${name}.arguments.add_empty`}
                name="topic.actionList.form.fieldTable.addEmpty"
              />
            </div>
          </td>
        </tr>
      </React.Fragment>
    )
  }
}

export default SelectRow
