import lodash from 'lodash'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactTable from 'react-table'
import { connect } from 'react-redux'
import { FieldArray, reduxForm, propTypes } from 'redux-form'
import SlotField from '../../components/integration/SlotField'

export class IntegrationExecute extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    ...propTypes,
    type: PropTypes.string.isRequired,
    title: PropTypes.string,
    response_map: PropTypes.array,
    response_body_variable: PropTypes.string,
    result_variable: PropTypes.string,
    results: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    errorMessage: PropTypes.string,
    slots: PropTypes.array,
    isExecuting: PropTypes.bool,
    onExecute: PropTypes.func,
    onClose: PropTypes.func,
    onOAuthRequest: PropTypes.func,
    oauthClient: PropTypes.object,
    oauthStatus: PropTypes.string,
    oauthStatusMessage: PropTypes.string,
  }

  static defaultProps = {
    oauthClient: {},
    oauthStatus: 'initialized',
  }

  componentDidMount() {
    const { slots } = this.props
    this.props.array.removeAll('slots')
    slots.forEach(name => this.props.array.push('slots', { key: name, value: '' }))
  }

  renderSlotFields = ({ fields }) => {
    return (
      <tbody>
        {fields.map((name, index) => (
          <SlotField key={index} name={name} />
        ))}
      </tbody>
    )
  }

  renderAuthenticationButton = () => {
    const { t } = this.context
    const { type, salesforce_api_subtype, oauthClient } = this.props

    if (!lodash.includes(['office365', 'salesforce'], type)) return
    if (type === 'salesforce' && salesforce_api_subtype !== 'custom') return

    if (oauthClient && oauthClient.use_preauthentication) return

    return (
      <button
        type="button"
        className="btn btn-warning"
        onClick={this.props.onOAuthRequest}
        disabled={lodash.isEmpty(oauthClient)}
      >
        {oauthClient && oauthClient.is_verified
          ? t(`integration.execute.${type}.signinButtons.signinWithOtherUser`)
          : t(`integration.execute.${type}.signinButtons.signin`)}
      </button>
    )
  }

  renderSigninMessage = () => {
    const { t } = this.context
    const { oauthClient, type } = this.props

    if (lodash.isEmpty(oauthClient)) return
    if (oauthClient.use_preauthentication) return
    if (oauthClient.is_verified) return

    return <h5>{t(`integration.execute.${type}.needToAuthenticateMessage`)}</h5>
  }

  renderOAuthStatusMessage = () => {
    const { oauthStatus, oauthStatusMessage } = this.props

    if (!oauthStatusMessage) return

    const className = classnames({
      'dm-error-message': oauthStatus === 'failed' || oauthStatus === 'canceled',
    })

    return <div className={className}>{oauthStatusMessage}</div>
  }

  renderQueries = () => {
    const { t } = this.context
    const { isQuery, type, slots } = this.props

    if (!isQuery && type !== 'office365' && type !== 'salesforce') {
      return
    }

    if (type === 'okbiz') {
      return (
        <div>
          <table className="table table-striped table-bordered">
            <FieldArray name="slots" component={this.renderSlotFields} />
          </table>
        </div>
      )
    } else {
      return (
        <div>
          {slots.length > 0 && (
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>{t('integration.execute.slotName')}</th>
                  <th>{t('integration.execute.value')}</th>
                </tr>
              </thead>
              <FieldArray name="slots" component={this.renderSlotFields} />
            </table>
          )}
        </div>
      )
    }
  }

  renderResults = () => {
    const { type, isExecuting, results, errorMessage } = this.props
    if (isExecuting) {
      return <div>Loading...</div>
    }
    if (type !== 'http') {
      if (errorMessage) {
        return <div className="dm-error-message">{errorMessage}</div>
      }
      if (!results) {
        return
      }
    }

    const renderers = {
      http: this.renderResultTable,
      google_spreadsheet: this.renderResultTable,
      email: this.renderSendMessage,
      office365: this.renderResultTable,
      excel: this.renderResultExcelDataTable,
      salesforce: this.renderResultSalesforceDataTable,
      okbiz: this.renderOkbizResponse,
    }

    return renderers[type]()
  }

  renderResultSalesforceDataTable = () => {
    const { salesforce_api_subtype, response_map } = this.props

    if (
      lodash.includes(['search', 'keyword', 'picklist'], salesforce_api_subtype) &&
      response_map.length === 1 &&
      response_map[0].from === '.'
    ) {
      return this.renderResultDataTable()
    } else {
      return this.renderResultTable()
    }
  }

  renderResultExcelDataTable = () => {
    const { t } = this.context
    const { excel_api_subtype } = this.props
    let render_item = []

    if (excel_api_subtype !== 'select') {
      const message = {
        insert: 'integration.execute.excelInsertSuccessfully',
        update: 'integration.execute.excelUpdateSuccessfully',
        delete: 'integration.execute.excelDeleteSuccessfully',
      }
      render_item.push(<div key="type">{t(message[excel_api_subtype])}</div>)
    }

    if (excel_api_subtype !== 'insert') {
      render_item.push(this.renderResultDataTable())
    }
    return render_item
  }

  renderResultDataTable = () => {
    const { results } = this.props
    let columns = []
    let rows = []
    let keys = []

    const propertyNames = Object.getOwnPropertyNames(results)
    propertyNames.forEach(propertyName => {
      if (Array.isArray(results[propertyName])) {
        results[propertyName].forEach(values => {
          let value_key = Object.getOwnPropertyNames(values)
          let value = {}
          value_key.forEach(key => {
            value[key] = typeof values[key] === 'string' ? values[key] : JSON.stringify(values[key])
          })
          rows.push(value)
        })
      }
    })
    if (rows.length > 0) keys = Object.getOwnPropertyNames(rows[0])
    keys.forEach(key => columns.push({ Header: key, accessor: key }))

    let length = rows.length
    if (length < 1) {
      length = 1
      columns.push({ Header: '', accessor: '' })
    }

    return (
      <ReactTable
        key="result_data_table"
        data={rows}
        columns={columns}
        showPagination={false}
        defaultPageSize={length}
      />
    )
  }

  renderResultTable = () => {
    const { t } = this.context
    const { results, response_map, response_body_variable, result_variable, errorMessage } = this.props
    return (
      <React.Fragment>
        {results && (
          <table name="table_test" className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>{t('integration.execute.resultKey')}</th>
                <th>{t('integration.execute.resultValue')}</th>
              </tr>
            </thead>
            <tbody>
              {lodash.compact(
                response_map.map((response, idx) => {
                  if (!response.from || !response.to) return null
                  return (
                    <tr key={idx}>
                      <td>{response.to}</td>
                      <td>{JSON.stringify(results[response.to])}</td>
                    </tr>
                  )
                })
              )}
              {response_body_variable && (
                <tr key="response_body_variable">
                  <td>{response_body_variable}</td>
                  <td>{JSON.stringify(results[response_body_variable])}</td>
                </tr>
              )}
              {result_variable && (
                <tr key="result_variable">
                  <td>{result_variable}</td>
                  <td>{JSON.stringify(results[result_variable])}</td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        <div className="dm-error-message">{errorMessage}</div>
      </React.Fragment>
    )
  }

  renderOkbizResponse = () => {
    const { t } = this.context
    const { results } = this.props

    let columns = [
      {
        Header: t('integration.execute.okbizFaqTitle'),
        accessor: 'title',
        Cell: row => (
          <div>
            <span title={row.value}>{row.value}</span>
          </div>
        ),
      },
      {
        Header: t('integration.execute.okbizFaqAnswer'),
        accessor: 'answer',
        Cell: row => (
          <div>
            <span title={row.value}>{row.value}</span>
          </div>
        ),
      },
    ]
    if (results.length === 0) {
      columns = [{ Header: '', accessor: '' }]
    }

    return (
      <div>
        <ReactTable
          key="result_data_table"
          data={results}
          columns={columns}
          showPagination={false}
          defaultPageSize={Math.min(results.length, 15)}
        />
      </div>
    )
  }

  renderSendMessage = () => {
    const { t } = this.context

    return <div>{t('integration.execute.sendEmailSuccessfully')}</div>
  }

  render() {
    const { t } = this.context
    const {
      title,
      handleSubmit,
      type,
      oauthClient,
      results,
      excel_api_subtype,
      salesforce_api_subtype,
    } = this.props

    let modalClass = 'modal-dialog'
    if (type === 'excel' && excel_api_subtype !== 'insert' && results) modalClass += ' modal-data-table'
    if (
      type === 'salesforce' &&
      lodash.includes(['search', 'keyword', 'picklist'], salesforce_api_subtype) &&
      results
    )
      modalClass += ' modal-data-table'

    return (
      <div className="modal-background" onClick={this.props.onClose}>
        <div className={modalClass} role="document" onClick={e => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.onClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title">{title}</h4>
            </div>
            <form className="text-left" onSubmit={handleSubmit(this.props.onExecute)}>
              <div className="modal-body">
                <div className="results">
                  {this.renderSigninMessage()}
                  {this.renderOAuthStatusMessage()}
                  {this.renderQueries()}
                  {this.renderResults()}
                </div>
              </div>
              <div className="modal-footer">
                {this.renderAuthenticationButton()}
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={
                    (type === 'office365' || type === 'salesforce') &&
                    (!oauthClient || !oauthClient.is_verified)
                  }
                >
                  {t('integration.execute.execute')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const IntegrationExecuteForm = reduxForm({
  form: 'IntegrationExecute',
  enableReinitialize: true,
})(IntegrationExecute)

export default connect()(IntegrationExecuteForm)
