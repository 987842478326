import { deleteCache } from './api'
import request from '../helpers/requestManagementAPI'
import Schemas from '../middleware/schema'

export const fetchEmailDomains = (token, filter = {}) => dispatch => {
  return request.get('/email_domains/', {
    token,
    dispatch,
    body: filter,
    target: 'email_domains',
    schema: Schemas.emailDomains,
    preserve: false,
  })
}

export const addEmailDomain = (token, emailDomain) => dispatch => {
  return request.post('/email_domains/', {
    token,
    dispatch,
    body: emailDomain,
    target: 'email_domains',
    schema: Schemas.emailDomain,
  })
}

export const deleteEmailDomain = (token, id) => dispatch => {
  return request
    .delete(`/email_domains/${id}`, {
      token,
      dispatch,
      target: 'email_domains',
    })
    .then(() => dispatch(deleteCache('email_domains', id)))
}
