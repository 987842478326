import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

class MultipleSelectList extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    items: PropTypes.array,
    valueKey: PropTypes.string,
    displayKey: PropTypes.string,
  }

  constructor() {
    super()
    this.state = { allow_columns: [], deny_columns: [] }
  }

  resetColumn = () => {
    this.setState({ allow_columns: [], deny_columns: [] })
  }

  popColumn = () => {
    const { allow_columns } = this.state
    if (allow_columns.length < 1) return
    const {
      input: { value, onChange },
      items,
      valueKey,
    } = this.props
    onChange(
      lodash.compact(
        items.map(item => {
          let flgOrigin = lodash.includes(value, item[valueKey])
          let flgChange = lodash.includes(allow_columns, item[valueKey])
          return (flgOrigin || flgChange) && !(flgOrigin && flgChange) ? item[valueKey] : null
        })
      )
    )
    this.resetColumn()
  }

  popColumns = () => {
    const {
      input: { onChange },
    } = this.props
    onChange([])
    this.resetColumn()
  }

  pushColumn = () => {
    const { deny_columns } = this.state
    if (deny_columns.length < 1) return
    const {
      input: { value, onChange },
      items,
      valueKey,
    } = this.props
    onChange(
      lodash.compact(
        items.map(item => {
          return lodash.includes(value, item[valueKey]) || lodash.includes(deny_columns, item[valueKey])
            ? item[valueKey]
            : null
        })
      )
    )
    this.resetColumn()
  }

  pushColumns = () => {
    const {
      input: { onChange },
      items,
      valueKey,
    } = this.props
    onChange(
      items.map(item => {
        return item[valueKey]
      })
    )
    this.resetColumn()
  }

  getDenyItem() {
    const {
      items,
      valueKey,
      input: { value },
    } = this.props
    return lodash.compact(
      items.map(item => {
        return lodash.includes(value, item[valueKey]) ? null : item
      })
    )
  }

  getAllowItem() {
    const {
      items,
      valueKey,
      input: { value },
    } = this.props
    return lodash.compact(
      items.map(item => {
        return lodash.includes(value, item[valueKey]) ? item : null
      })
    )
  }

  errorNode = () => {
    const { t } = this.context
    const {
      meta: { error },
    } = this.props
    if (!error) return null
    if (typeof error === 'object') {
      return <div className="error">{t(error.id, error.values)}</div>
    } else {
      return <div className="error">{t(error)}</div>
    }
  }

  onChangeColumn = evt => {
    let columns = lodash.filter(evt.target.options, { selected: true }).map(item => {
      return item.value
    })
    this.setState({ [evt.target.name]: columns })
  }

  renderMultipleSelect = (name, items) => {
    const { displayKey, valueKey } = this.props
    const options = items.map(item => {
      return (
        <option key={item[valueKey]} value={item[valueKey]} title={item[displayKey]}>
          {item[displayKey]}
        </option>
      )
    })
    return (
      <select
        id={name}
        name={name}
        className="form-control dm-form-control"
        multiple
        size={10}
        value={this.state[name]}
        onChange={evt => this.onChangeColumn(evt)}
      >
        {options}
      </select>
    )
  }

  render() {
    const { t } = this.context
    const { deny_columns, allow_columns } = this.state
    return (
      <div>
        <div className="row dm-multiple-select-list">
          <div className="col-sm-5">
            <div>{t('multipleSelectList.hideColumn')}</div>
            {this.renderMultipleSelect('deny_columns', this.getDenyItem())}
          </div>
          <div className="col-sm-2">
            <div className="btn-table">
              <div className="btn-cell">
                <button
                  type="button"
                  className="btn btn-primary dm-btn btn-control is-gray"
                  disabled={deny_columns.length < 1}
                  onClick={this.pushColumn}
                >
                  {t('multipleSelectList.columnControls.push')}
                </button>
                <button
                  type="button"
                  className="btn btn-primary dm-btn btn-control is-gray ml-0"
                  onClick={this.pushColumns}
                >
                  {t('multipleSelectList.columnControls.allPush')}
                </button>
                <button
                  type="button"
                  className="btn btn-primary dm-btn btn-control is-gray ml-0 mt-3"
                  disabled={allow_columns.length < 1}
                  onClick={this.popColumn}
                >
                  {t('multipleSelectList.columnControls.pop')}
                </button>
                <button
                  type="button"
                  className="btn btn-primary dm-btn btn-control is-gray ml-0"
                  onClick={this.popColumns}
                >
                  {t('multipleSelectList.columnControls.allPop')}
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-5">
            <div>{t('multipleSelectList.viewColumn')}</div>
            {this.renderMultipleSelect('allow_columns', this.getAllowItem())}
          </div>
        </div>
        {this.errorNode()}
      </div>
    )
  }
}

export default MultipleSelectList
