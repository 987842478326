import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { fetchRunningApplication } from '../../actions/embedded'

export class AuthenticationPopup extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }),
  }

  componentDidMount() {
    const { dispatch } = this.props

    import('aws-amplify').then(({ Auth }) => {
      dispatch(fetchRunningApplication(this.props.params.token)).then(features => {
        Auth.configure(features.amplifyConfiguration)

        //  Overwrite window.open to keep window.opener while authenticating with aws-amplify in Firefox, Safari
        //  Because window.open(url, '_self') to open IdP in aws-amplify will change window.opener in these browsers.
        window.open = url => {
          location.href = url
          return window
        }
        Auth.federatedSignIn({ provider: 'SSOIdP' })
      })
    })
  }

  render() {
    return null
  }
}

export const mapStateToProps = (_state, _props) => {
  return {}
}

export default connect(mapStateToProps)(AuthenticationPopup)
