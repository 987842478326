import lodash from 'lodash'
import { deleteCache } from './api'
import uuid from 'uuid'
import Schemas from '../middleware/schema'
import request from '../helpers/requestManagementAPI'

export const fetchTopics = (token, filter = {}) => dispatch => {
  return request.get('/topics/', {
    token,
    dispatch,
    body: filter,
    target: 'topics',
    schema: Schemas.topics,
    preserve: false,
  })
}

export const fetchTopic = (token, id, errorHandler) => dispatch => {
  return request.get(`/topics/${id}`, {
    token,
    dispatch,
    target: 'topics',
    schema: Schemas.topic,
    formatter: topic => {
      topic.actions = lodash.map(topic.actions, action => ({ ...action, uuid: uuid.v4() }))
      return topic
    },
    errorHandler,
  })
}

export const createTopic = (token, topic) => dispatch => {
  if (topic.condition_variable === '') {
    topic.condition_variable = undefined
  }
  return request.post('/topics/', {
    token,
    dispatch,
    body: topic,
    target: 'topics',
    schema: Schemas.topic,
    formatter: topic => {
      topic.actions = lodash.map(topic.actions, action => ({ ...action, uuid: uuid.v4() }))
      return topic
    },
  })
}

export const updateTopic = (token, topic) => dispatch => {
  if (topic.condition_variable === '') {
    topic.condition_variable = undefined
  }
  return request.put(`/topics/${topic.id}`, {
    token,
    dispatch,
    body: topic,
    target: 'topics',
    schema: Schemas.topic,
    formatter: topic => {
      topic.actions = lodash.map(topic.actions, action => ({ ...action, uuid: uuid.v4() }))
      return topic
    },
  })
}

export const moveTopic = (token, id, nextParentId, nextOrder, botUpdatedAt) => dispatch => {
  return request.put(`/topics/${id}/order`, {
    token,
    dispatch,
    body: { domain_id: nextParentId, order: nextOrder, bot_updated_at: botUpdatedAt },
    target: 'topics',
    schema: Schemas.topic,
    formatter: topic => {
      topic.actions = lodash.map(topic.actions, action => ({ ...action, uuid: uuid.v4() }))
      return topic
    },
  })
}

export const renameTopic = (token, id, newName) => dispatch => {
  return request.put(`/topics/${id}`, {
    token,
    dispatch,
    body: { name: newName },
    target: 'topics',
    schema: Schemas.topic,
    formatter: topic => {
      topic.actions = lodash.map(topic.actions, action => ({ ...action, uuid: uuid.v4() }))
      return topic
    },
  })
}

export const uploadImage = (token, form) => dispatch => {
  return request.post('/topics/upload', {
    token,
    dispatch,
    body: form,
  })
}

export const deleteTopic = (token, id) => dispatch => {
  return request
    .delete(`/topics/${id}`, {
      token,
      dispatch,
      target: 'topics',
    })
    .then(() => dispatch(deleteCache('topics', id)))
}
