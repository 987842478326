import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes } from 'redux-form'
import ReactLoader from 'react-loader'

import { addNotice } from '../../../actions/notice'
import { inquiryImport, fetchAnalysis, receiveGroupingResult } from '../../../actions/inquiry'
import { fetchBot } from '../../../actions/bot'
import { fetchFaq } from '../../../actions/faq'
import { CheckboxField, FileField } from '../../../components/common/fields/FormFields'
import Sidebar from '../../../components/common/Sidebar'
import Loader from '../../../components/common/Loader'
import LabelWithTooltip from '../../../components/common/LabelWithTooltip'
import { Simulator } from '../../simulator/Simulator'
import { downloadBlob } from '../../../helpers/download'

export class InquiryImport extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    bot: PropTypes.object.isRequired,
    faq: PropTypes.object.isRequired,
    groups: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      bot_id: PropTypes.string,
      faq_id: PropTypes.string,
    }),
  }

  constructor() {
    super()
    this.state = { isReady: undefined }
  }

  componentDidMount() {
    const { t } = this.context
    const {
      dispatch,
      params: { faq_id },
    } = this.props
    const state = this.context.store.getState()

    this.fetchAnalysis().then(response => {
      if (response.status !== 'completed') {
        this.setState({ isReady: true })
        dispatch(fetchFaq(state.session.token, faq_id))
      }
      if (response.status === 'enqueued') {
        dispatch(addNotice('info', t('inquiry.analyzingMessage')))
      }
    })
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  handleAnalyze = (data, dispatch) => {
    const state = this.context.store.getState()
    const { t } = this.context
    const {
      params: { bot_id, faq_id },
    } = this.props

    if (!data.upload_file || !data.upload_file[0]) {
      dispatch(addNotice('error', t('inquiry.inquiryGroups.noFileSelectedError')))
      return
    }

    // Validate upload size
    const fileSize = data.upload_file[0].size / 1024 / 1024
    if (fileSize > 10) {
      dispatch(addNotice('error', t('validate.invalidFileSize', { size: 10 })))
      return
    }

    const body = new FormData()
    body.append('file', data.upload_file[0])
    body.append('prefer_categories', data.prefer_categories)
    return dispatch(inquiryImport(state.session.token, faq_id, body))
      .then(() => dispatch(addNotice('info', t('inquiry.analyzingMessage'))))
      .then(() => this.fetchAnalysis())
      .then(() => dispatch(fetchBot(state.session.token, bot_id)))
      .catch(() => {
        document.getElementById('upload_file').value = ''
      })
  }

  fetchAnalysis = () => {
    const state = this.context.store.getState()
    const { t, router } = this.context
    const {
      dispatch,
      groups,
      params: { bot_id, faq_id },
    } = this.props
    return dispatch(fetchAnalysis(state.session.token, faq_id)).then(response => {
      if (response.status === 'enqueued') {
        this.timer = setTimeout(this.fetchAnalysis, 10000)
      } else if (response.status === 'completed') {
        dispatch(receiveGroupingResult(faq_id, response.result))
        router.replace(`/bots/${bot_id}/faqs/${faq_id}/generator/groups`)
        if (lodash.isEmpty(groups)) {
          dispatch(addNotice('info', t('inquiry.analyzeSuccessMessage')))
        }
      } else if (response.status === 'error') {
        dispatch(addNotice('error', t('inquiry.analyzeFaildMessage')))
      }
      return response
    })
  }

  downloadFile = e => {
    e.preventDefault()

    const data = new XMLHttpRequest()
    data.open('GET', e.target.href)
    data.responseType = 'blob'
    data.onload = () => {
      // Rename the file to the specified name and download it
      // Cannot use download attribute in IE
      downloadBlob(data.response, 'inquiry_sample.csv')
    }

    data.send()
  }

  render() {
    const { t } = this.context
    const { submitting, handleSubmit, bot, faq } = this.props

    if (!this.state.isReady) return <ReactLoader />

    return (
      <div>
        <Loader loaded={!submitting} type="show">
          <form
            className="text-left col-md-9"
            ref="upload_form"
            encType="multipart/form-data"
            onSubmit={handleSubmit(this.handleAnalyze)}
          >
            {/* upload file */}
            <div className="form-group">
              <LabelWithTooltip htmlFor="file" className="dm-title" name="inquiry.formCaption" />
              <div>
                <a
                  className="url"
                  href="https://guide.dialogplay.jp/hc/ja/articles/4407481955481"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('inquiry.guide')}
                  <i className="fas fa-external-link-alt" />
                </a>
              </div>
              <Field
                type="file"
                name="upload_file"
                className="form-control dm-form-control"
                accept=".csv"
                component={FileField}
                disabled={bot.is_analyzing || submitting}
              />
              <div className="form-inline checkbox mb-4">
                <Field
                  type="checkbox"
                  name="prefer_categories"
                  id="prefer_categories"
                  className="form-control m-0"
                  component={CheckboxField}
                  disabled={bot.is_analyzing || submitting}
                />
                <LabelWithTooltip htmlFor="prefer_categories" name="inquiry.preferCategories" />
              </div>
              <div>
                <a href="/files/inquiry_sample_v2.csv" onClick={this.downloadFile}>
                  {t('inquiry.downloadSampleCSV')}
                </a>
              </div>
              <div className="dm-example small">
                <table className="table table-sm table-bordered">
                  <tbody>
                    <tr className="row">
                      <td className="col-sm-3">{t('inquiry.example.category1.name')}</td>
                      <td className="col-sm-2">{t('inquiry.example.category1.type')}</td>
                      <td className="col-sm-7">{t('inquiry.example.category1.description')}</td>
                    </tr>
                    <tr className="row">
                      <td className="col-sm-3">{t('inquiry.example.category2.name')}</td>
                      <td className="col-sm-2">{t('inquiry.example.category2.type')}</td>
                      <td className="col-sm-7">{t('inquiry.example.category2.description')}</td>
                    </tr>
                    <tr className="row">
                      <td className="col-sm-3">{t('inquiry.example.category3.name')}</td>
                      <td className="col-sm-2">{t('inquiry.example.category3.type')}</td>
                      <td className="col-sm-7">{t('inquiry.example.category3.description')}</td>
                    </tr>
                    <tr className="row">
                      <td className="col-sm-3">{t('inquiry.example.category4.name')}</td>
                      <td className="col-sm-2">{t('inquiry.example.category4.type')}</td>
                      <td className="col-sm-7">{t('inquiry.example.category4.description')}</td>
                    </tr>
                    <tr className="row">
                      <td className="col-sm-3">{t('inquiry.example.category5.name')}</td>
                      <td className="col-sm-2">{t('inquiry.example.category5.type')}</td>
                      <td className="col-sm-7">{t('inquiry.example.category5.description')}</td>
                    </tr>
                    <tr className="row">
                      <td className="col-sm-3">{t('inquiry.example.title.name')}</td>
                      <td className="col-sm-2">{t('inquiry.example.title.type')}</td>
                      <td className="col-sm-7">{t('inquiry.example.title.description')}</td>
                    </tr>
                    <tr className="row">
                      <td className="col-sm-3">{t('inquiry.example.question.name')}</td>
                      <td className="col-sm-2">{t('inquiry.example.question.type')}</td>
                      <td className="col-sm-7">{t('inquiry.example.question.description')}</td>
                    </tr>
                    <tr className="row">
                      <td className="col-sm-3">{t('inquiry.example.answer.name')}</td>
                      <td className="col-sm-2">{t('inquiry.example.answer.type')}</td>
                      <td className="col-sm-7">{t('inquiry.example.answer.description')}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* import button */}
              <div className="form-group text-right">
                <button type="submit" className="btn btn-primary" disabled={bot.is_analyzing || submitting}>
                  {t('common.import')}
                </button>
              </div>
            </div>
          </form>
          <Sidebar titles={[t('simulator.title')]}>
            <Simulator tabs={['faqClassifier', 'chat']} faq={faq} bot={bot} />
          </Sidebar>
        </Loader>
      </div>
    )
  }
}

const InquiryImportForm = reduxForm({
  form: 'InquiryImport',
  enableReinitialize: true,
  initialValues: { prefer_categories: false },
})(InquiryImport)

const mapStateToProps = (state, props) => {
  const bot = state.entities.bots[props.params.bot_id] || {}
  const faq = state.entities.faqs[props.params.faq_id] || {}
  const groups = state.groups[props.params.faq_id] || []

  return {
    bot: bot,
    faq: faq,
    groups: groups,
  }
}

export default connect(mapStateToProps)(InquiryImportForm)
