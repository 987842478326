import Schemas from '../middleware/schema'
import request from '../helpers/requestManagementAPI'

export const fetchSampleTemplates = (token, filter = {}) => dispatch => {
  return request.get('/sample_templates/', {
    token,
    dispatch,
    body: filter,
    target: 'sample_templates',
    schema: Schemas.sampleTemplates,
  })
}

export const importSampleTemplate = (token, id) => dispatch => {
  return request.post(`/sample_templates/${id}/import`, {
    token,
    dispatch,
    target: 'bots',
    schema: Schemas.bot,
  })
}
