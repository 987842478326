import lodash from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'

import DataBindingTable from '../common/DataBindingTable'
import LabelWithTooltip from '../common/LabelWithTooltip'
import { getCredentials } from '../../helpers/sessionHelper'
import { isPermitted } from '../../helpers/permission'

class FaqItemTable extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    bot_id: PropTypes.string.isRequired,
    faq: PropTypes.object,
    items: PropTypes.array,
    handleExport: PropTypes.func,
    isSubmitting: PropTypes.func,
    tableState: PropTypes.object,
    maxFaqItems: PropTypes.number.isRequired,
    updateTableState: PropTypes.func.isRequired,
    handleAdd: PropTypes.func.isRequired,
  }

  static defaultProps = {
    faq: {},
    items: [],
  }

  render() {
    const { t } = this.context
    const {
      bot_id,
      faq,
      items,
      handleAdd,
      handleExport,
      isSubmitting,
      tableState,
      maxFaqItems,
      updateTableState,
    } = this.props
    const { timezone } = getCredentials(this.context)

    const remaining = maxFaqItems - items.length
    const buttonType = remaining <= 0 ? 'btn-alert' : 'btn-primary'
    const columns = [
      {
        Header: t('faqCategory.faqItemTable.name'),
        id: 'name',
        accessor: 'name',
        Cell: props => (
          <Link className="" to={`/bots/${bot_id}/faqs/${faq.id}/items/${props.original.id}`}>
            {props.value}
          </Link>
        ),
        getFooterProps: () => ({ className: 'footer-all' }),
        Footer: (
          <div className="pull-left">
            <Link className={`dm-btn btn ${buttonType} btn-icon-plus mini`} onClick={handleAdd}>
              <span>{t('common.new')}</span>
            </Link>
            <Link
              className="dm-btn btn btn-primary btn-icon-upload mini"
              to={`/bots/${bot_id}/faqs/${faq.id}/import`}
            >
              <span>{t('common.import')}</span>
            </Link>
            <button
              className="dm-btn btn btn-primary btn-icon-download mini"
              onClick={handleExport}
              disabled={isSubmitting}
            >
              <span>{t('common.export')}</span>
            </button>
            {isPermitted('feature_faq_generator', this.context) && (
              <Link
                className="dm-btn btn btn-primary btn-icon-analyze mini"
                to={`/bots/${bot_id}/faqs/${faq.id}/generator/import`}
              >
                <span>{t('faqCategory.faqItemTable.inquiryImport')}</span>
              </Link>
            )}
          </div>
        ),
      },
      {
        Header: t('faqCategory.faqItemTable.faqMatching'),
        id: 'question',
        accessor: faqItem => {
          const question = lodash.first(faqItem.questions)
          const pattern = lodash.first(faqItem.patterns)
          if (question) {
            return question
          } else if (pattern) {
            const type = t('faqItem.patterns.types.partial_match')
            return `[${type}] ${pattern}`
          } else {
            return ''
          }
        },
      },
      {
        Header: t('faqCategory.faqItemTable.answer'),
        id: 'answer',
        accessor: faqItem => faqItem.answers[0],
      },
      {
        Header: t('faqCategory.faqItemTable.categories'),
        id: 'categories',
        accessor: faqItem => faqItem.categories.join(' > '),
        getProps: (_state, rowInfo, _column, _instance) => ({ title: rowInfo && rowInfo.row.categories }),
        minWidth: 80,
        Footer: (
          <div className="pull-right">
            {t('faqCategory.remaining', {
              count: items.length.toLocaleString(),
              total: maxFaqItems.toLocaleString(),
            })}
          </div>
        ),
      },
      {
        Header: t('common.lastUpdatedBy'),
        id: 'lastUpdatedBy',
        accessor: faqItem => faqItem.last_updated_by.name || '',
        width: 160,
      },
      {
        Header: t('common.updatedAt'),
        id: 'updatedAt',
        accessor: faqItem =>
          faqItem.updated_at ? moment.tz(faqItem.updated_at, timezone).format('YYYY/MM/DD HH:mm') : '',
        width: 160,
        filterable: false,
      },
    ]

    return (
      <div>
        <LabelWithTooltip name="faqCategory.faqItemTable.title" className="dm-title" />
        <DataBindingTable
          items={items}
          columns={columns}
          filterable={true}
          tableState={tableState}
          updateTableState={updateTableState}
        />
      </div>
    )
  }
}

export default FaqItemTable
