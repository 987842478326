import lodash from 'lodash'
import React from 'react'
import { Field, FieldArray } from 'redux-form'
import { CheckboxField } from '../../components/common/fields/FormFields'
import IncrementableTable from '../common/IncrementableTable'
import Tooltip from '../common/Tooltip'
import LabelWithTooltip from '../common/LabelWithTooltip'

import AbstractAction from './AbstractAction'
import VariableField from '../common/fields/VariableField'
import CardTextField from '../common/fields/CardTextField'
import LabelField from '../common/fields/LabelField'
import ChooseButtonValueField from '../common/fields/ChooseButtonValueField'
import { lengthWithoutVariable } from '../../helpers/validation'

export class ChooseAction extends AbstractAction {
  getTitle() {
    return this.context.t('topic.actionList.choose.title')
  }
  getType() {
    return 'user'
  }

  static validate = action => {
    const errors = super.validate(action)

    errors.choices = []
    if (!action.choices || action.choices.length === 0) {
      errors.choices[0] = { label: 'validate.required' }
    }
    return errors
  }

  static validateForLine = action => {
    if (lengthWithoutVariable(action.text) > 60) {
      return false
    }
    return true
  }

  static getVariables = (action, _props, _context) => {
    const candidates = lodash.map(action.choices, choice => ({
      value: choice.value || choice.label,
      text: choice.label,
    }))
    return [{ variable: action.title, text: action.title, candidates: candidates }]
  }

  renderChoices = ({ fields }) => {
    const { t } = this.context
    const textColumn = name => (
      <LabelField
        name={`${name}.label`}
        className="form-control dm-form-control"
        ignoreValidation={(value, allValues) => !lodash.get(allValues, name)}
      />
    )
    const valueColumn = name => (
      <ChooseButtonValueField
        name={`${name}.value`}
        className="form-control dm-form-control"
        nullable={true}
      />
    )
    const columns = [
      {
        Header: (
          <th key="label">
            {t('topic.actionList.choose.label')}
            <Tooltip name="topic.tooltip.actionList.choose.label" />
          </th>
        ),
        Cell: textColumn,
      },
      {
        Header: (
          <th key="value">
            {t('topic.actionList.choose.value')}
            <Tooltip name="topic.tooltip.actionList.choose.value" />
          </th>
        ),
        Cell: valueColumn,
      },
    ]
    return <IncrementableTable fields={fields} columns={columns} maxRows={15} />
  }

  renderBody() {
    const { t } = this.context
    const { topicType, name } = this.props
    return (
      <div className="choose">
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor="title"
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.choose.chooseTitle"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <VariableField name={`${name}.title`} className="form-control dm-form-control" />
          </div>
        </div>
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor="text"
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.choose.text"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <CardTextField name={`${name}.text`} className="form-control" />
          </div>
        </div>
        <label>{t('topic.actionList.choose.choices')}</label>
        <FieldArray name={`${name}.choices`} component={this.renderChoices} />
        {topicType === 'normal' && (
          <div className="form-group form-inline mt-1">
            <Field
              name={`${name}.skip_known_memories`}
              id={`${name}.skip_known_memories`}
              type="checkbox"
              component={CheckboxField}
              className="form-control"
            />
            <LabelWithTooltip
              htmlFor={`${name}.skip_known_memories`}
              name="topic.actionList.choose.skipKnownMemories"
            />
          </div>
        )}
        <div className="form-group form-inline mt-1">
          <Field
            name={`${name}.allow_others`}
            id={`${name}.allow_others`}
            type="checkbox"
            component={CheckboxField}
            className="form-control"
          />
          <LabelWithTooltip htmlFor={`${name}.allow_others`} name="topic.actionList.choose.allowOthers" />
        </div>
      </div>
    )
  }
}

export default ChooseAction
