import lodash from 'lodash'
import {
  CognitoIdentityClient,
  GetIdCommand,
  GetCredentialsForIdentityCommand,
} from '@aws-sdk/client-cognito-identity'
import awsIoTDevice from 'aws-iot-device-sdk/device'
import uuid from 'uuid'

import Config from '../helpers/config'

export default class WebSocketDevice {
  constructor(region, context) {
    this.region = region
    this.context = context
    this.canceled = false
    this.status = 'initialized'

    this.credentialsExpiration = null
  }

  connect(identityPoolId, handlers = {}) {
    const defaultHandlers = {
      connect: this.onConnect,
      error: this.onError,
      message: null,
      timeout: null,
      offline: this.onOffline,
      close: null,
      reconnect: this.onReconnect,
    }

    this.identityPoolId = identityPoolId

    this.handlers = lodash.mapValues(defaultHandlers, (defaultHandler, key) => {
      const handler = handlers[key] || (() => {})
      if (defaultHandler) {
        return lodash.wrap(handler, defaultHandler)
      } else {
        return (...args) => {
          handler.apply(this, args)
        }
      }
    })

    this.status = 'connecting'
    return this.getCredentials(this._connectIoT)
  }

  getCredentials = async (next, options = {}) => {
    try {
      const client = new CognitoIdentityClient({ region: this.region })
      const identityId = await client.send(new GetIdCommand({ IdentityPoolId: this.identityPoolId }))
      const data = await client.send(new GetCredentialsForIdentityCommand(identityId))
      this.credentialsExpiration = data.Credentials.Expiration
      return next(data.Credentials)
    } catch (error) {
      if (options.ignoreError) return
      if (this.canceled) return
      this.handlers.error(error)
      throw error
    }
  }

  close() {
    this.canceled = true
    if (this.device) {
      this.device.end()
      this.device = null
    }
    this.status = 'closed'
  }

  onConnect = (handler, ...args) => {
    this.status = 'connected'
    return handler.apply(this.context, args)
  }

  onError = (handler, ...args) => {
    this.status = 'error'
    if (this.device) {
      //  only update credentials because aws-iot-device-sdk will reconnect automatically
      this.getCredentials(
        data => {
          this.device.updateWebSocketCredentials(
            data.Credentials.AccessKeyId,
            data.Credentials.SecretKey,
            data.Credentials.SessionToken
          )
        },
        { ignoreError: true }
      )
    }
    return handler.apply(this.context, args)
  }

  onOffline = (handler, ...args) => {
    this.status = 'offline'
    return handler.apply(this.context, args)
  }

  onReconnect = (handler, ...args) => {
    this.status = 'connecting'
    if (this.device && (this.credentialsExpiration == null || new Date() > this.credentialsExpiration)) {
      //  only update credentials because aws-iot-device-sdk will reconnect automatically
      this.getCredentials(
        credentials => {
          this.device.updateWebSocketCredentials(
            credentials.AccessKeyId,
            credentials.SecretKey,
            credentials.SessionToken
          )
        },
        { ignoreError: true }
      )
    }
    return handler.apply(this.context, args)
  }

  _connectIoT = credentials => {
    return new Promise((resolve, reject) => {
      if (this.canceled) reject(new Error('canceled'))

      this.device = awsIoTDevice({
        host: Config.awsIotEndpoint,
        protocol: 'wss',
        accessKeyId: credentials.AccessKeyId,
        secretKey: credentials.SecretKey,
        sessionToken: credentials.SessionToken,
        clientId: uuid.v4(),
        keepalive: 30,
        clean: false,
        maximumReconnectTimeMs: 60000,
      })

      this.device.on('connect', (...args) => {
        this.handlers.connect(...args)
        resolve()
      })
      this.device.on('error', (...args) => {
        this.handlers.error(...args)
        reject(new Error('connection error'))
      })
      this.device.on('message', this.handlers.message)
      this.device.on('timeout', this.handlers.timeout)
      this.device.on('offline', this.handlers.offline)
      this.device.on('close', this.handlers.close)
      this.device.on('reconnect', this.handlers.reconnect)
    })
  }
}
