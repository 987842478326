import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, reduxForm, propTypes } from 'redux-form'

// components
import Loader from '../../components/common/Loader'
import PnpTopicEditComponent from '../../components/pnp_topic/PnpTopicEdit'

// actions
import { addNotice } from '../../actions/notice'
import { fetchApplications } from '../../actions/application'
import { fetchTopic, createTopic, updateTopic, deleteTopic, uploadImage } from '../../actions/topic'

// helpers
import {
  validateAction,
  validateActionForLine,
  sanitizeActions,
  convertSystemVariablesOfAction,
} from '../../helpers/actionHelper'
import { isFetching } from '../../helpers/selector'
import cancelUrl from '../../helpers/cancelurl'
import { checkApplications } from '../../helpers/checkApplications'

const validate = data => {
  const errors = {}
  if (!data.name) {
    errors.name = 'validate.required'
  } else if (data.name.length > 255) {
    errors.name = { id: 'validate.exceededMaxLength', values: { length: 255 } }
  }

  const actionErrors = data.actions.map(validateAction)
  if (data.actions.length === 0) {
    actionErrors._error = 'validate.actionRequired'
  } else if (data.actions.length > 5) {
    actionErrors._error = 'validate.exceedActions'
  }
  if (!lodash.every(actionErrors, lodash.isEmpty)) errors.actions = actionErrors

  return errors
}

export class PnpTopicEdit extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      bot_id: PropTypes.string,
      id: PropTypes.string,
    }),
    bot: PropTypes.object,
    topic: PropTypes.object,
    actions: PropTypes.array,
    type: PropTypes.string,
    isExponential: PropTypes.bool,
    useTimeoutMessage: PropTypes.bool,
    tableStates: PropTypes.object,
    applications: PropTypes.array.isRequired,
  }

  constructor() {
    super()
    this.state = { isLoadedImage: true }
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    if (this.props.params.id) {
      dispatch(fetchTopic(state.session.token, this.props.params.id))
    }
    dispatch(fetchApplications(state.session.token, { original_bot_id: this.props.params.bot_id }))
    cancelUrl.setRouteLeaveHook(this)
  }

  handleSave = (data, dispatch) => {
    const {
      params: { bot_id },
      applications,
    } = this.props
    const { t } = this.context
    const session = this.context.store.getState().session
    const router = this.context.router

    const orderedActions = data.actions.map((data, index) => {
      return Object.assign({}, data, { order: index })
    })

    const topic = {
      bot_id: bot_id,
      name: data.name,
      type: 'phone_number_push',
      actions: sanitizeActions(this.context, this.props, orderedActions),
    }

    if (this.props.params.id) {
      topic.id = this.props.params.id
      return dispatch(updateTopic(session.token, topic))
        .then(() => dispatch(addNotice('info', t('common.saveSuccessMessage'))))
        .then(() => {
          if (!lodash.every(topic.actions, action => validateActionForLine(action))) {
            dispatch(addNotice('warn', t('pnpTopic.exceededBodyOrTextForLine')))
          }
        })
        .then(() => checkApplications(bot_id, applications, dispatch, this.context))
    } else {
      return dispatch(createTopic(session.token, topic))
        .then(json =>
          router.push({
            pathname: `/bots/${bot_id}/pnp_topics/${json.result}`,
            state: { ignoreBlocking: true },
          })
        )
        .then(() => cancelUrl.setRouteLeaveHook(this))
        .then(() => dispatch(addNotice('info', t('common.saveSuccessMessage'))))
        .then(() => {
          if (!lodash.every(topic.actions, action => validateActionForLine(action))) {
            dispatch(addNotice('warn', t('pnpTopic.exceededBodyOrTextForLine')))
          }
        })
        .then(() => checkApplications(bot_id, applications, dispatch, this.context))
    }
  }

  handleDelete = () => {
    const { t } = this.context
    const {
      dispatch,
      params: { bot_id },
      topic,
    } = this.props
    if (!window.confirm(t('common.deleteConfirmMessage', { type: t('pnpTopic.title'), name: topic.name })))
      return

    const state = this.context.store.getState()
    const router = this.context.router
    const id = parseInt(this.props.params.id, 10)
    return dispatch(deleteTopic(state.session.token, id))
      .then(() => router.push({ pathname: `/bots/${bot_id}/pnp_topics`, state: { ignoreBlocking: true } }))
      .then(() => dispatch(addNotice('info', t('common.deleteSuccessMessage'))))
  }

  // check of file type and file size
  // maximum file size is 1MB(=1048576byte),
  // and height and width are 1024 * 1024.
  onChangeImageFile = (e, callback) => {
    var files = e.target.files
    const state = this.context.store.getState()
    const { t } = this.context
    const { dispatch } = this.props
    const image = files[0]
    const action_path = e.target.name.match(/(actions\[\d+\])/)[1]

    if (!image) {
      callback(null)
      window.alert(t('validate.invalidImage'))
      this.props.change(`${action_path}.upload_image_url`, null)
      this.props.change(`${action_path}.isInvalidImage`, true)
      return
    }
    const authorizedFileFormat = ['image/jpeg']
    const maxFileSize = 1048576
    if (authorizedFileFormat.indexOf(image.type) === -1 || image.size > maxFileSize) {
      callback(null)
      window.alert(t('validate.invalidImage'))
      this.props.change(`${action_path}.upload_image_url`, null)
      this.props.change(`${action_path}.isInvalidImage`, true)
      return
    }

    const fr = new FileReader()
    fr.onload = evt => {
      const dv = new DataView(evt.target.result, 0, 5)
      const nume1 = dv.getUint8(0, true)
      const nume2 = dv.getUint8(1, true)
      const hex = nume1.toString(16) + nume2.toString(16)

      // Jpeg has 'ffd8' in header
      if (hex === 'ffd8') {
        this.setState({ isLoadedImage: false })
        const formData = new FormData()
        formData.append('type', 'upload_image')
        formData.append('upload_file', image)
        dispatch(uploadImage(state.session.token, formData))
          .then(upload => {
            callback(upload.image_url)
            this.props.change(`${action_path}.upload_image_url`, upload.image_url)
            this.props.change(`${action_path}.upload_thumb_url`, upload.thumb_url)
            this.setState({ isLoadedImage: true })
          })
          .catch(() => this.setState({ isLoadedImage: true }))
      } else {
        callback(null)
        window.alert(t('validate.invalidImage'))
        this.props.change(`${action_path}.upload_image_url`, null)
        this.props.change(`${action_path}.isInvalidImage`, true)
      }
    }
    fr.readAsArrayBuffer(image)
  }

  // check of file type and file size
  // maximum file size is 1MB(=1048576byte).
  onChangeCarouselImageFile = (e, callback) => {
    const state = this.context.store.getState()
    const { t } = this.context
    const { dispatch } = this.props
    const image = e.target.files[0]
    const item_path = e.target.name.match(/(actions\[\d+\]\.items\[\d+\])/)[1]

    if (!image) return

    const authorizedFileFormat = ['image/png', 'image/jpeg']
    const maxFileSize = 1048576
    if (authorizedFileFormat.indexOf(image.type) === -1 || image.size > maxFileSize) {
      callback(null)
      window.alert(t('validate.invalidImage'))
      this.props.change(`${item_path}.upload_image_url`, null)
      return
    }

    const fr = new FileReader()
    fr.onload = evt => {
      const dv = new DataView(evt.target.result, 0, 5)
      const nume1 = dv.getUint8(0, true)
      const nume2 = dv.getUint8(1, true)
      const hex = nume1.toString(16) + nume2.toString(16)

      // Jpeg has 'ffd8' in header or png has '8950' in header
      if ((image.type === 'image/jpeg' && hex !== 'ffd8') || (image.type === 'image/png' && hex !== '8950')) {
        callback(null)
        window.alert(t('validate.invalidImage'))
        this.props.change(`${item_path}.upload_image_url`, null)
        return
      }

      // check of image height and width.
      const maxHeight = 1024
      const maxWidth = 1024
      var img = new Image()
      img.onload = () => {
        if (img.width > maxWidth || img.height > maxHeight) {
          callback(null)
          window.alert(t('validate.invalidImage'))
          this.props.change(`${item_path}.upload_image_url`, null)
          return
        }

        this.props.change(`${item_path}.upload_image_url`, window.URL.createObjectURL(image))
        const formData = new FormData()
        formData.append('type', 'carousel')
        formData.append('upload_file', image)
        dispatch(uploadImage(state.session.token, formData)).then(upload => {
          callback(upload.image_url)
          this.props.change(`${item_path}.upload_image_url`, upload.image_url)
        })
      }
      img.src = window.URL.createObjectURL(image)
    }
    fr.readAsArrayBuffer(image)
  }

  onClickImageReset = item_path => {
    this.props.change(`${item_path}.upload_image_url`, null)
  }

  onError = (message, options = {}) => {
    const level = options.level || 'error'
    this.props.dispatch(addNotice(level, message))
  }

  render() {
    const { isFetching, submitting, handleSubmit, actions, params } = this.props

    return (
      <div>
        <Loader loaded={!isFetching && !submitting} type="show">
          <PnpTopicEditComponent
            topic_id={params.id}
            actions={actions}
            handleSave={handleSubmit(this.handleSave)}
            handleDelete={this.handleDelete}
            onChangeImageFile={this.onChangeImageFile}
            onClickImageReset={this.onClickImageReset}
            onChangeCarouselImageFile={this.onChangeCarouselImageFile}
            onError={this.onError}
            isLoadedImage={this.state.isLoadedImage}
          />
        </Loader>
      </div>
    )
  }
}

const PnpTopicEditForm = reduxForm({
  form: 'PnpTopicEdit',
  enableReinitialize: true,
  validate,
})(PnpTopicEdit)

const selector = formValueSelector('PnpTopicEdit')

export const mapStateToProps = (state, props) => {
  const applications = lodash.filter(state.entities.applications)
  const topic = {
    actions: [],
    ...state.entities.topics[props.params.id],
  }
  topic.actions = topic.actions.map(action => {
    switch (action.type) {
      case 'upload_image':
        return {
          ...action,
          upload_image_url: action.image_method === 'upload' ? action.image_url : undefined,
          upload_thumb_url: action.image_method === 'upload' ? action.thumb_url : undefined,
          input_url: action.image_method === 'url' ? action.image_url : undefined,
        }
      case 'carousel':
        return {
          ...action,
          items: action.items.map(item => {
            return {
              ...item,
              upload_image_url: item.image_method === 'upload' ? item.image_url : undefined,
              input_image_url: item.image_method === 'url' ? item.image_url : undefined,
            }
          }),
        }
      default:
        return action
    }
  })

  if (topic.actions) {
    topic.actions.forEach(action => {
      convertSystemVariablesOfAction(action, false)
    })
  }

  return {
    isFetching: isFetching(state, ['topics']),
    applications,
    topic,
    initialValues: topic,
    actions: selector(state, 'actions'),
  }
}

export default connect(mapStateToProps)(PnpTopicEditForm)
