import { clearState } from './api'
import request from '../helpers/requestManagementAPI'

export const createSession = access_token => ({
  type: 'CREATE_SESSION',
  token: access_token,
})

export const updateSession = (session = {}) => ({
  type: 'UPDATE_SESSION',
  session,
})

export const deleteSession = () => ({
  type: 'DELETE_SESSION',
})

export const completeSessionLoading = () => ({
  type: 'COMPLETE_SESSION_LOADING',
})

export const requestLogin = (email, password) => dispatch => {
  return request
    .post('/auth', {
      body: { email, password },
    })
    .then(json => {
      dispatch(clearState())
      dispatch(createSession(json.access_token))
    })
}

export const changeApplication = id => ({
  type: 'CHANGE_APPLICATION',
  id,
})

export const updateTopicsStyle = style => ({
  type: 'UPDATE_TOPICS_STYLE',
  style,
})

export const setStrictPasswordPolicy = useStrictPasswordPolicy => ({
  type: 'SET_STRICT_PASSWORD_POLICY',
  useStrictPasswordPolicy,
})
