import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes } from 'redux-form'

import { FileField } from '../../components/common/fields/FormFields'
import Loader from '../../components/common/Loader'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import { addNotice } from '../../actions/notice'
import { importBot } from '../../actions/bot'
import { fetchAccount } from '../../actions/account'
import { fetchSampleTemplates, importSampleTemplate } from '../../actions/sample_template'
import { isFetching } from '../../helpers/selector'
import { isPermitted, isEnabled } from '../../helpers/permission'

export class BotImport extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
    templates: PropTypes.array,
    isFetching: PropTypes.bool,
    useExportImport: PropTypes.bool,
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    dispatch(fetchSampleTemplates(state.session.token))
    dispatch(fetchAccount(state.session.token))
  }

  importFromSample = e => {
    const { t, router } = this.context
    const state = this.context.store.getState()
    const { dispatch } = this.props

    return dispatch(importSampleTemplate(state.session.token, e.target.dataset.value))
      .then(result => router.push(`/bots/${result.result}`))
      .then(() => dispatch(addNotice('info', t('common.importSuccessMessage'))))
  }

  importFromFile = (data, dispatch) => {
    const { t, router } = this.context
    if (!data.upload_file || !data.upload_file[0]) {
      dispatch(addNotice('error', t('bot.noFileSelectedError')))
      return
    }

    const state = this.context.store.getState()

    const request_body = new FormData()
    request_body.append('file', data.upload_file[0])
    return dispatch(importBot(state.session.token, request_body))
      .then(result => router.push(`/bots/${result.result}`))
      .then(() => dispatch(addNotice('info', t('common.importSuccessMessage'))))
      .catch(() => {
        document.getElementById('upload_file').value = ''
      })
  }

  format = text => {
    if (!text) return text
    text = text.replace(/</g, '&lt;')
    text = text.replace(/>/g, '&gt;')
    text = text.replace(
      /https?:\/\/[^\s]*/g,
      m => `<a href="${encodeURI(m)}" target="_blank" rel="noopener noreferrer">${m}</a>`
    )
    text = text.replace(/\n/g, '<br>')
    return text
  }

  render() {
    const { t } = this.context
    const { templates, isFetching, submitting, handleSubmit, useExportImport } = this.props

    const children = lodash
      .chain(templates)
      .filter(template => {
        // hide beta template if experimental config is disabled.
        return isEnabled(`template_${template.group_name}`, this.context)
      })
      .sortBy('order')
      .map(template => {
        const permitted = isPermitted(`template_${template.group_name}`, this.context)
        const className = permitted ? 'btn btn-primary dm-btn import' : 'btn btn-danger dm-btn import'
        const value = permitted ? t('common.import') : t(`bot.templateRestrictions.${template.group_name}`)
        return (
          <li key={template.id}>
            <div className="info">
              <div className="name">{template.name}</div>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: this.format(template.description) }}
              />
            </div>
            <div className="button">
              <button
                className={className}
                data-value={template.id}
                onClick={this.importFromSample}
                disabled={!permitted}
              >
                {value}
              </button>
            </div>
          </li>
        )
      })
      .value()

    let fileImport = null

    if (useExportImport && isPermitted('feature_import_export', this.context)) {
      fileImport = (
        <div className="row file-template">
          <form
            className="text-left col-md-12"
            ref="upload_form"
            encType="multipart/form-data"
            onSubmit={handleSubmit(this.importFromFile)}
          >
            <div className="form-group">
              <LabelWithTooltip htmlFor="file" className="dm-title" name="bot.customImport.title" />
              <div className="ml-3">
                <div className="dm-note strong">{t('bot.customImport.note')}</div>
                <Field
                  type="file"
                  name="upload_file"
                  className="form-control dm-form-control"
                  component={FileField}
                />
              </div>
            </div>
            <div className="form-group text-right">
              <button type="submit" className="btn btn-primary dm-btn" disabled={submitting}>
                {t('common.import')}
              </button>
            </div>
          </form>
        </div>
      )
    }

    return (
      <div className="dm-bot">
        <Loader loaded={!isFetching && !submitting} type="show">
          <div className="row">
            <div className="col-md-12">
              <LabelWithTooltip className="dm-title" name="bot.templates" />
              <ul className="sample-template">{children}</ul>
            </div>
          </div>
          {fileImport}
        </Loader>
      </div>
    )
  }
}

const BotImportForm = reduxForm({
  form: 'BotImport',
  enableReinitialize: true,
})(BotImport)

const mapStateToProps = state => {
  const account = lodash.first(Object.values(state.entities.accounts)) || {}
  const ids = state.pagination.sample_templates.ids || []
  const templates = ids.map(id => state.entities.sample_templates[id])

  return {
    templates: templates,
    isFetching: isFetching(state),
    useExportImport: account.use_export_import,
  }
}

export default connect(mapStateToProps)(BotImportForm)
