import React from 'react'
import LabelWithTooltip from '../common/LabelWithTooltip'

import AbstractAction from './AbstractAction'
import TextField from '../common/fields/TextField'

export class TextMessageAction extends AbstractAction {
  getTitle() {
    return this.context.t('topic.actionList.speak.title')
  }

  renderBody() {
    const { name } = this.props

    return (
      <div className="text_message">
        <div className="row">
          <LabelWithTooltip
            htmlFor="message"
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.speak.message"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <TextField name={`${name}.content`} className="form-control" />
          </div>
        </div>
      </div>
    )
  }
}

export default TextMessageAction
