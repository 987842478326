import React from 'react'

export default () => {
  return (
    <div className="loading">
      <div className="loader"></div>
      <span className="loading-message">Loading...</span>
      <div className="script-load-error">
        ページの読み込みに失敗しました。しばらく待ってからリロードしてください。
        <br />
        Failed to load the page. Please wait a few minutes and reload.
      </div>
    </div>
  )
}
