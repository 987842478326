import lodash from 'lodash'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import introJs from 'intro.js'
import { getTour } from './TourScenario'
import { startTour, endTour } from '../../actions/tour'
import { isFetching } from '../../helpers/selector'
import { getCredentials } from '../../helpers/sessionHelper'

class TourManager extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
    store: PropTypes.object,
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    pathname: PropTypes.string.isRequired,
    isFetching: PropTypes.bool.isRequired,
  }

  constructor(props, context) {
    const { t } = context
    super(props)

    this.initIntro()
    this.defaultConfig = {
      overlayOpacity: 0.4,
      keyboardNavigation: false,
      showProgress: true,
      showBullets: false,
      showButtons: true,
      showStepNumbers: true,
      skipLabel: t('tour.skip'),
      nextLabel: t('tour.next'),
      prevLabel: t('tour.prev'),
      doneLabel: t('tour.done'),
      highlightClass: 'introjs-highlight',
    }
  }

  initIntro() {
    this.intro = introJs(document.querySelector('body'))
    this.intro.onexit(this.onIntroExit)
    this.intro.oncomplete(this.onIntroExit)
    this.intro.onbeforechange(this.onIntroChange)
    this.observer = new MutationObserver(this.refreshHighlight)
  }

  onIntroExit = () => {
    this.stopObservation(this.prevElement)
    this.props.dispatch(endTour())
  }

  onIntroChange = () => {
    const step = this.intro._introItems[this.intro._currentStep]
    if (step.onEnter) step.onEnter()

    const currentElement = step.element
    this.stopObservation(this.prevElement)
    this.startObservation(currentElement)
    this.prevElement = currentElement
  }

  startObservation = element => {
    if (!element) return
    this.observer.observe(element, { attributes: true, subtree: true, childList: true })
    element.addEventListener('transitionend', this.refreshHighlight)
    this.observeTimer = setInterval(this.refreshHighlight, 100)
  }

  stopObservation = element => {
    if (!element) return
    if (this.prevElement) this.prevElement.removeEventListener('transitionend', this.refreshHighlight)
    this.observer.disconnect()
    clearInterval(this.observeTimer)
  }

  refreshHighlight = () => {
    const element = this.intro._introItems[this.intro._currentStep].element
    if (!element) return

    const helperLayer = document.querySelector('.introjs-helperLayer')
    helperLayer.classList.remove('introjs-fixedTooltip')

    const referenceLayer = document.querySelector('.introjs-tooltipReferenceLayer')
    referenceLayer.classList.remove('introjs-fixedTooltip')

    this.intro.refresh()
    if (!element.classList.contains('introjs-showElement')) {
      element.classList.add('introjs-showElement')
    }
    if (!element.classList.contains('introjs-relativePosition')) {
      element.classList.add('introjs-relativePosition')
    }
    this.intro.refresh()
  }

  componentDidMount() {
    this.startTourIfNeeded()
  }

  componentDidUpdate(prevProps) {
    const { role } = getCredentials(this.context)
    const tour = getTour(this.props.pathname)
    //  start tour
    if (!prevProps.open && this.props.open) {
      let steps = tour.steps || []
      steps = lodash.filter(steps, step => {
        return !step.roles || lodash.includes(step.roles, role)
      })
      if (steps.length > 0) {
        this.intro.setOptions({ ...this.defaultConfig, steps: steps })
        this.intro.start()
      } else {
        this.props.dispatch(endTour())
      }
    }

    //  end tour
    if (prevProps.open && !this.props.open) {
      this.intro.exit()
    }

    if (prevProps.pathname !== this.props.pathname) {
      if (!tour.isIgnoreTransition || !tour.isIgnoreTransition(prevProps.pathname, this.props.pathname)) {
        this.props.dispatch(endTour())
        this.startTourIfNeeded()
      }
    }
  }

  startTourIfNeeded = () => {
    const { dispatch, pathname } = this.props
    const tour = getTour(pathname)
    clearInterval(this.timer)

    if (!tour.autoStart) return

    const visitedTours = JSON.parse(localStorage.getItem('visitedTours') || '{}')
    if (visitedTours[tour.name]) return

    this.timer = setInterval(() => {
      if (this.props.isFetching) return

      clearInterval(this.timer)
      dispatch(startTour())

      visitedTours[tour.name] = true
      localStorage.setItem('visitedTours', JSON.stringify(visitedTours))
    }, 100)
  }

  render() {
    return null
  }
}

export const mapStateToProps = (state, props) => {
  return {
    ...props,
    isFetching: isFetching(state),
    open: state.tour.open,
  }
}

export default connect(mapStateToProps)(TourManager)
