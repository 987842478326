import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import MessageIcon from './MessageIcon'

import Config from '../../helpers/config'
import AbstractMessage from './AbstractMessage'

class ImageMessage extends AbstractMessage {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  displayImage = e => {
    e.preventDefault()
    const { image_url, title } = this.props.content
    window.open(
      `/popup.html?url=${encodeURIComponent(image_url)}&title=${encodeURIComponent(title)}`,
      'imageWindow',
      'resizable=yes'
    )
  }

  render() {
    const { content } = this.props
    const imageUrl = content.thumb_url || content.image_url
    if (!imageUrl) return null

    if (content.open_url) {
      return this.renderLinkedImage()
    } else {
      return this.renderNormalImage()
    }
  }

  renderNormalImage() {
    const { sender_type, content, timestamp, iconUrl, fallbackIconUrl, isLoaded } = this.props
    const url = content.thumb_url || content.image_url
    if (!url) return null

    return (
      <li className={classnames(this.getMessageClasses())}>
        <div className="body">
          <MessageIcon
            sender_type={sender_type}
            url={iconUrl}
            fallbackUrl={fallbackIconUrl}
            isLoaded={isLoaded}
          />
          <div className="balloon">
            <div className="content content-image">
              <img className="image-aspect" src={url} alt={content.title} onClick={this.displayImage} />
              {this.renderDefaultFooterButtons('content')}
            </div>
            {this.renderTimestamp(timestamp)}
          </div>
        </div>
      </li>
    )
  }

  renderLinkedImage() {
    const {
      mode,
      sender_type,
      content,
      timestamp,
      iconUrl,
      fallbackIconUrl,
      isLoaded,
      isSimulator,
    } = this.props
    const url = content.thumb_url || content.image_url

    const enableSwitchChatBot = mode === 'client' && !isSimulator

    let linkTarget = '_blank'
    if (enableSwitchChatBot && content.open_url.startsWith(Config.embeddedChatURL)) {
      linkTarget = undefined
    }

    return (
      <li className={classnames(this.getMessageClasses())}>
        <div className="body">
          <MessageIcon
            sender_type={sender_type}
            url={iconUrl}
            fallbackUrl={fallbackIconUrl}
            isLoaded={isLoaded}
          />
          <div className="balloon">
            <div className="content content-image">
              <a href={content.open_url} target={linkTarget} rel="noopener noreferrer">
                <img className="image-aspect" src={url} alt={content.title} />
              </a>
              {this.renderDefaultFooterButtons('content')}
            </div>
            {this.renderTimestamp(timestamp)}
          </div>
        </div>
      </li>
    )
  }
}

export default ImageMessage
