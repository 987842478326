import PropTypes from 'prop-types'
import { Component } from 'react'

export class BaseField extends Component {
  static propTypes = {
    nullable: PropTypes.bool,
  }

  static defaultProps = {
    nullable: false,
  }

  validate(value, _allValues, _props) {
    const { nullable } = this.props
    if (!value && !nullable) {
      return 'validate.required'
    }
  }
}

export default BaseField
