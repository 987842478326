export const buildTree = (domains, topics) => ({
  type: 'BUILD_TREE',
  domains,
  topics,
})

export const clearTree = () => ({
  type: 'CLEAR_TREE',
})

export const updateTree = treeData => ({
  type: 'UPDATE_TREE',
  treeData,
})

export const startRenaming = (node, path) => ({
  type: 'START_RENAMING',
  node,
  path,
})

export const cancelEditing = () => ({
  type: 'CANCEL_EDITING',
})

export const finishEditing = (node, path, newName) => ({
  type: 'FINISH_EDITING',
  node,
  path,
  newName,
})

export const addScenario = (node, path) => ({
  type: 'ADD_SCENARIO',
  node,
  path,
})

export const addDomain = (node, path) => ({
  type: 'ADD_DOMAIN',
  node,
  path,
})
