import { deleteCache } from './api'
import request from '../helpers/requestManagementAPI'
import Schemas from '../middleware/schema'

export const fetchEmailAddresses = (token, filter = {}) => dispatch => {
  return request.get('/email_addresses/', {
    token,
    dispatch,
    body: filter,
    target: 'email_addresses',
    schema: Schemas.emailAddresses,
    preserve: false,
  })
}

export const addEmailAddress = (token, emailAddress) => dispatch => {
  return request.post('/email_addresses/', {
    token,
    dispatch,
    body: emailAddress,
    target: 'email_addresses',
    schema: Schemas.emailAddress,
  })
}

export const deleteEmailAddress = (token, id) => dispatch => {
  return request
    .delete(`/email_addresses/${id}`, {
      token,
      dispatch,
      target: 'email_addresses',
    })
    .then(() => dispatch(deleteCache('email_addresses', id)))
}

export const resendConfirmationMail = (token, id) => dispatch => {
  return request.post(`/email_addresses/${id}/resend`, {
    token,
    dispatch,
    target: 'email_addresses',
  })
}

export const confirmEmailAddress = confirmation_token => dispatch => {
  return request.post(`/email_addresses/confirm/${confirmation_token}`, {
    dispatch,
    target: 'email_addresses',
  })
}
