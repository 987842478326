import { deleteCache } from './api'
import Schemas from '../middleware/schema'
import request from '../helpers/requestManagementAPI'

export const fetchDomains = (token, filter = {}) => dispatch => {
  return request.get('/domains/', {
    token,
    dispatch,
    body: filter,
    target: 'domains',
    schema: Schemas.domains,
    preserve: false,
  })
}

export const fetchDomain = (token, id) => dispatch => {
  return request.get(`/domains/${id}`, {
    token,
    dispatch,
    target: 'domains',
    schema: Schemas.domain,
  })
}

export const createDomain = (token, domain) => dispatch => {
  return request.post('/domains/', {
    token,
    dispatch,
    body: domain,
    target: 'domains',
    schema: Schemas.domain,
  })
}

export const moveDomain = (token, id, nextParentId, nextOrder, botUpdatedAt) => dispatch => {
  return request.put(`/domains/${id}/order`, {
    token,
    dispatch,
    body: { parent_id: nextParentId, order: nextOrder, bot_updated_at: botUpdatedAt },
    target: 'domains',
    schema: Schemas.domain,
  })
}

export const renameDomain = (token, id, newName) => dispatch => {
  return request.put(`/domains/${id}`, {
    token,
    dispatch,
    body: { name: newName },
    target: 'domains',
    schema: Schemas.domain,
  })
}

export const deleteDomain = (token, domain_id) => dispatch => {
  return request
    .delete(`/domains/${domain_id}`, {
      token,
      dispatch,
      target: 'domains',
    })
    .then(() => dispatch(deleteCache('domains', domain_id)))
}
