import lodash from 'lodash'
import persistState from 'redux-localstorage'
import reducer from '../reducers'

export const persistPartialState = (path, config) => {
  const [parentKey, key] = path.split('.')
  return persistState(parentKey, {
    key: config.key,
    serialize: data => {
      const isAllowed =
        !window.Cookiebot ||
        lodash.every(config.permissions, permission => window.Cookiebot.consent[permission])
      if (!isAllowed) return

      let targetData
      if (key && data[parentKey]) {
        targetData = data[parentKey][key]
      } else {
        targetData = data[parentKey]
      }

      if (config.filter) {
        return JSON.stringify(lodash.pick(targetData, config.filter))
      } else {
        return JSON.stringify(targetData)
      }
    },
    deserialize: serializedData => {
      if (!serializedData || serializedData === 'undefined') return

      const state = reducer(undefined, {})
      if (key) {
        return { [parentKey]: { ...state[parentKey], [key]: JSON.parse(serializedData) } }
      } else {
        return { [parentKey]: { ...state[parentKey], ...JSON.parse(serializedData) } }
      }
    },
  })
}
