import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, Field, reduxForm, propTypes } from 'redux-form'

import { addNotice } from '../../actions/notice'
import { fetchEndpoint, updateEndpoint, createEndpoint, deleteEndpoint } from '../../actions/endpoint'
import { InputField, SelectField } from '../../components/common/fields/FormFields'
import Loader from '../../components/common/Loader'
import Config from '../../helpers/config'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import { isFetching } from '../../helpers/selector'

const validate = data => {
  const errors = {}
  if (data.type === 'watson') {
    if (!data.url) {
      errors.url = 'validate.required'
    }
    if (!data.username) {
      errors.username = 'validate.required'
    }
    if (!data.id && !data.password) {
      errors.password = 'validate.required'
    }
    if (!data.language) {
      errors.language = 'validate.required'
    }
  }
  return errors
}

export class EndpointEdit extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    type: PropTypes.string,
    isFetching: PropTypes.bool,
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    if (this.props.params.id) {
      dispatch(fetchEndpoint(state.session.token, this.props.params.id))
    }
  }

  componentWillMount() {
    if (!Config.enableWatson) {
      this.context.router.replace('/')
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.endpoint) {
      this.setState({ type: nextProps.endpoint.type })
    }
  }

  handleSave = (data, dispatch) => {
    const { t } = this.context
    const state = this.context.store.getState()
    const router = this.context.router

    const endpoint = {
      type: data.type,
    }

    if (endpoint.type === 'watson') {
      endpoint.url = data.url
      endpoint.username = data.username
      endpoint.language = data.language
      if (data.password !== '') {
        endpoint.password = data.password
      }
    }

    if (this.props.params.id) {
      endpoint.id = data.id
      return dispatch(updateEndpoint(state.session.token, endpoint)).then(() =>
        dispatch(addNotice('info', t('common.saveSuccessMessage')))
      )
    } else {
      return dispatch(createEndpoint(state.session.token, endpoint, router))
        .then(json => router.push(`/endpoints/${json.result}`))
        .then(() => dispatch(addNotice('info', t('common.saveSuccessMessage'))))
    }
  }

  handleDelete = () => {
    const { t } = this.context
    const endpoint = this.props.initialValues
    if (!window.confirm(t('common.deleteConfirmMessage', { type: t('endpoint.title'), name: endpoint.id })))
      return
    const state = this.context.store.getState()
    const router = this.context.router
    const { dispatch } = this.props
    const id = parseInt(this.props.params.id, 10)
    dispatch(deleteEndpoint(state.session.token, id)).then(() => router.push('/endpoints'))
  }

  render() {
    const isUpdate = !!this.props.params.id
    const { t } = this.context
    const { isFetching, submitting, type, isSystem, handleSubmit } = this.props
    const types = [{ id: 'watson', name: 'Watson' }]

    return (
      <div>
        <Loader loaded={!isFetching && !submitting} type="show">
          <form className="text-left col-md-9" onSubmit={handleSubmit(this.handleSave)}>
            <div className="form-group">
              <LabelWithTooltip htmlFor="type" className="dm-title-mini" name="endpoint.type" />
              <Field
                name="type"
                items={types}
                valueKey="id"
                displayKey="name"
                className="form-control dm-form-control"
                disabled={isUpdate}
                component={SelectField}
              />
            </div>
            {type === 'watson' && this.watsonForm()}
            {!isSystem && (
              <div className="form-group text-right">
                <button type="submit" className="btn btn-primary dm-btn" disabled={submitting}>
                  {t('common.save')}
                </button>
                {isUpdate && (
                  <button
                    type="button"
                    className="btn btn-danger dm-btn"
                    onClick={this.handleDelete}
                    disabled={submitting}
                  >
                    {t('common.delete')}
                  </button>
                )}
              </div>
            )}
          </form>
        </Loader>
      </div>
    )
  }

  watsonForm() {
    const { isSystem } = this.props
    return [
      <div className="form-group" key="url">
        <LabelWithTooltip htmlFor="url" className="dm-title-mini" name="endpoint.watson.url" />
        <Field
          type="text"
          name="url"
          className="form-control dm-form-control"
          disabled={isSystem}
          component={InputField}
        />
      </div>,
      <div className="form-group" key="username">
        <LabelWithTooltip htmlFor="username" className="dm-title-mini" name="endpoint.watson.username" />
        <Field
          type="text"
          name="username"
          className="form-control dm-form-control"
          disabled={isSystem}
          component={InputField}
        />
      </div>,
      <div className="form-group" key="password">
        <LabelWithTooltip htmlFor="password" className="dm-title-mini" name="endpoint.watson.password" />
        <Field
          type="password"
          name="password"
          className="form-control dm-form-control"
          disabled={isSystem}
          component={InputField}
          autoComplete="new-password"
        />
      </div>,
      <div className="form-group" key="language">
        <LabelWithTooltip htmlFor="language" className="dm-title-mini" name="endpoint.watson.language" />
        <Field
          type="text"
          name="language"
          className="form-control dm-form-control"
          disabled={isSystem}
          component={InputField}
        />
      </div>,
    ]
  }
}

const EndpointEditForm = reduxForm({
  form: 'EndpointEdit',
  enableReinitialize: true,
  validate,
})(EndpointEdit)

const selector = formValueSelector('EndpointEdit')

export const mapStateToProps = (state, props) => {
  return {
    type: selector(state, 'type'),
    isSystem: (state.entities.endpoints[props.params.id] || {}).is_system,
    isFetching: isFetching(state),
    initialValues: state.entities.endpoints[props.params.id] || { type: 'watson' },
  }
}

export default connect(mapStateToProps)(EndpointEditForm)
