import React from 'react'
import LabelWithTooltip from '../common/LabelWithTooltip'

import AbstractAction from './AbstractAction'
import ListVariableField from '../common/fields/ListVariableField'
import ItemTextField from '../common/fields/ItemTextField'

export class ItemListAction extends AbstractAction {
  getTitle() {
    return this.context.t('topic.actionList.item_list.title')
  }

  renderBody() {
    const { name } = this.props
    return (
      <div className="item-list">
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor="variable"
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.item_list.variable"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <ListVariableField name={`${name}.variable`} className="form-control" />
          </div>
        </div>
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor="text"
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.item_list.text"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <ItemTextField name={`${name}.text`} className="form-control" />
          </div>
        </div>
      </div>
    )
  }
}

export default ItemListAction
