import lodash from 'lodash'

export const isCarouselResponse = content => {
  if (!content.value) return false
  try {
    const value = JSON.parse(content.value)
    if (typeof value !== 'object') return false

    const needKeys = ['index', 'id', 'label', 'action']
    return lodash.every(needKeys, key => key in value)
  } catch (e) {
    return false
  }
}

export const convertCarouselResponse = content => {
  const supplements = []
  const value = JSON.parse(content.value)
  supplements.push(`index: ${value.index}`)
  if (value.id) {
    supplements.push(`id: ${value.id}`)
  }

  return `${content.text} (${supplements.join(', ')})`
}
