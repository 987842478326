import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Collapse from 'react-collapse'
import Tabs, { TabPane } from 'rc-tabs'
import TabContent from 'rc-tabs/lib/TabContent'
import SwipeableInkTabBar from 'rc-tabs/lib/SwipeableInkTabBar'

import Tooltip from '../common/Tooltip'
import ExampleTable from './ExampleTable'
import TopicPatternTable from './TopicPatternTable'

export class ScenarioMatchingTab extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
    store: PropTypes.object,
  }

  static propTypes = {
    examples: PropTypes.object,
    patterns: PropTypes.object,
    defaultOpened: PropTypes.bool,
  }

  constructor() {
    super()
    this.state = { isOpened: false, activeKey: 'examples' }
  }

  componentDidMount() {
    this.setState({ isOpened: this.props.defaultOpened })
  }

  toggleScenarioMatching = () => {
    this.setState({ isOpened: !this.state.isOpened })
  }

  render() {
    const { t } = this.context
    const { isOpened, activeKey } = this.state
    const { examples, patterns } = this.props

    const currentTabError = activeKey === 'examples' ? !!examples.meta.error : !!patterns.meta.error

    return (
      <div className={isOpened ? 'is-open' : ''}>
        <span onClick={() => this.toggleScenarioMatching(currentTabError)}>
          <label className="dm-title toggleScenarioMatching">{t('topic.scenarioMatching')}</label>
          <Tooltip name="topic.tooltip.scenarioMatching.beta" />
          <span type="button" className="btn btn-link glyphicon glyphicon-collapse-down" />
        </span>
        <Collapse className="scenarioMatching" isOpened={isOpened}>
          <Tabs
            className="dm-tabs"
            destroyInactiveTabPane
            activeKey={activeKey}
            onChange={key => {
              if (!currentTabError) {
                this.setState({ activeKey: key })
              }
            }}
            renderTabBar={() => <SwipeableInkTabBar pageSize={2} speed={10} />}
            renderTabContent={() => <TabContent animatedWithMargin />}
          >
            <TabPane
              tab={`${t('topic.examples.title')} ${lodash.compact(examples.input.value).length}`}
              key="examples"
            >
              <div className="instruction">{t('topic.examples.instruction')}</div>
              <ExampleTable />
            </TabPane>
            <TabPane
              tab={`${t('topic.patterns.title')} ${lodash.filter(patterns.input.value, 'pattern').length}`}
              key="patterns"
            >
              <div className="instruction">
                {t('topic.patterns.instruction1')}
                <br />
                {t('topic.patterns.instruction2')}
              </div>
              <TopicPatternTable />
            </TabPane>
          </Tabs>
        </Collapse>
        {lodash.isString(examples.meta.error) && <div className="error">{t(examples.meta.error)}</div>}
        {lodash.isString(patterns.meta.error) && <div className="error">{t(patterns.meta.error)}</div>}
      </div>
    )
  }
}

export default ScenarioMatchingTab
