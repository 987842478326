import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'
import { Field } from 'redux-form'
import classnames from 'classnames'

import { SelectField } from '../../components/common/fields/FormFields'

export class ClassifierComponent extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    type: PropTypes.string.isRequired,
    submitting: PropTypes.bool,
    query: PropTypes.string,
    items: PropTypes.array,
    topics: PropTypes.array,
    formValues: PropTypes.object,
    handleUpdateFaqItem: PropTypes.func,
    handleUpdateTopic: PropTypes.func,
    handleAddNewTopic: PropTypes.func,
    handleAddNewFaqItem: PropTypes.func,
    domain: PropTypes.object,
    faq: PropTypes.object,
    results: PropTypes.array,
    maxScenarios: PropTypes.number,
    maxFaqItems: PropTypes.number,
  }

  restrictLength = (text, limit) => {
    if (!text) return text
    if (text.length <= limit) return text

    return text.substring(0, limit - 1) + '…'
  }

  render() {
    const { t } = this.context
    const { type, submitting } = this.props
    return (
      <div className="dm-classifier">
        <div className="input-group">
          <Field
            name="query"
            type="text"
            className="form-control dm-form-control"
            placeholder={t(`simulator.${type}Classifier.query`)}
            autoComplete="off"
            maxLength="280"
            component="input"
          />
          <span className="input-group-btn">
            <button type="submit" className="btn btn-primary dm-btn" disabled={submitting}>
              {t(`simulator.${type}Classifier.send`)}
            </button>
          </span>
        </div>
        {this.renderClassifyTable()}
      </div>
    )
  }

  renderClassifyTable = () => {
    const { t } = this.context
    const {
      type,
      items,
      query,
      results,
      domain,
      faq,
      topics,
      maxScenarios,
      maxFaqItems,
      handleUpdateTopic,
      handleUpdateFaqItem,
      handleAddNewTopic,
      handleAddNewFaqItem,
      formValues,
    } = this.props
    const faq_id = faq ? faq.id : undefined
    const bot_id = domain ? domain.bot_id : faq.bot_id
    const key = type === 'topic' ? 'topic' : 'faq_item'
    const handleUpdate = { topic: handleUpdateTopic, faq_item: handleUpdateFaqItem }
    const handleAddNew = { topic: handleAddNewTopic, faq_item: handleAddNewFaqItem }
    const classesOfAddNew = ['dm-btn', 'btn', 'mini']
    if (type === 'topic') {
      if (maxScenarios != null && topics.length >= maxScenarios) {
        classesOfAddNew.push('btn-alert')
      } else {
        classesOfAddNew.push('btn-primary')
      }
    } else {
      if (items.length >= maxFaqItems) {
        classesOfAddNew.push('btn-alert')
      } else {
        classesOfAddNew.push('btn-primary')
      }
    }
    const urlForItem = {
      topic: `/bots/${bot_id}/topics/`,
      faq_item: `/bots/${bot_id}/faqs/${faq_id}/items/`,
    }

    if (Array.isArray(results)) {
      if (!lodash.some(results, key)) {
        return (
          <div>
            <label className="dm-title-mini">{t(`simulator.${type}Classifier.results`, { query })}</label>
            <p className="dm-title-mini">{t(`simulator.${type}Classifier.empty_result`)}</p>
          </div>
        )
      }
    } else {
      return null
    }
    const rows = () => {
      const select_row = (
        <tr key="selectItem">
          <td colSpan="3">
            <Field
              name="select_item"
              items={type === 'topic' ? topics : items}
              valueKey="id"
              displayKey="name"
              className="form-control dm-form-control"
              empty={true}
              component={SelectField}
              order="asc"
            />
          </td>
          <td>
            <Link
              className="intent-rows"
              disabled={!formValues.hasOwnProperty('select_item')}
              onClick={() => handleUpdate[key](formValues.select_item, query)}
            >
              {t(`simulator.${type}Classifier.addItem`)}
            </Link>
          </td>
        </tr>
      )
      const new_row = (
        <tr key="newItem">
          <td colSpan="4">
            <Link className={classnames(classesOfAddNew)} onClick={() => handleAddNew[key](query)}>
              {t(`simulator.${type}Classifier.addNew`)}
            </Link>
          </td>
        </tr>
      )
      const intent_rows = results.map((result, idx) => (
        <tr key={idx}>
          <td className="text-right">{idx + 1}</td>
          <td>
            {result[key] ? (
              <Link className="" to={urlForItem[key] + `${result[key].id}`} title={result[key].name}>
                {this.restrictLength(result[key].name, 35)}
              </Link>
            ) : (
              t(`simulator.${type}Classifier.deleted`)
            )}
          </td>
          <td>{result.confidence.toFixed(3)}</td>
          <td>
            {result[key] && (
              <Link className="intent-rows" onClick={() => handleUpdate[key](result[key].id, query)}>
                {t(`simulator.${type}Classifier.addItem`)}
              </Link>
            )}
          </td>
        </tr>
      ))

      return lodash.flatten([intent_rows, select_row, new_row])
    }

    return (
      <div className="results" style={{ marginTop: '24px' }}>
        <label className="dm-title-mini">{t(`simulator.${type}Classifier.results`, { query })}</label>
        <table className="table table-striped table-bordered refreshable dm-table hover">
          <thead>
            <tr>
              <th>{t(`simulator.${type}Classifier.rank`)}</th>
              <th>{t(`simulator.${type}Classifier.name`)}</th>
              <th>{t(`simulator.${type}Classifier.confidence`)}</th>
              <th>{t(`simulator.${type}Classifier.column`)}</th>
            </tr>
          </thead>
          <tbody>{rows()}</tbody>
        </table>
      </div>
    )
  }
}

export default ClassifierComponent
