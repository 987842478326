import lodash from 'lodash'
import { addNotice } from '../actions/notice'
import { experimentalFeatures, permissions } from '../config/permission'
import { getCredentials } from './sessionHelper'

export const isPermitted = (feature, context) => {
  if (!context || !context.store) {
    return false
  }
  return isPermittedCredentials(feature, getCredentials(context))
}

export const isPermittedCredentials = (feature, credentials) => {
  if (lodash.isEmpty(credentials) || !isEnabledByCredentials(feature, credentials)) {
    return false
  }

  const { plan, option_plans } = credentials
  const planPermission = plan in permissions ? permissions[plan] : {}
  const optionPlanPermissions = lodash.map(option_plans, option_plan => {
    return option_plan in permissions ? permissions[option_plan] : {}
  })

  if (lodash.some(optionPlanPermissions, optionPermission => optionPermission[feature] === false)) {
    return false
  } else if (lodash.some(optionPlanPermissions, optionPermission => optionPermission[feature] === true)) {
    return true
  } else {
    return planPermission[feature] || false
  }
}

export const isPermittedSpeaker = (speaker, state, props) => {
  const aitalkSpeakerNames = ['miyabi', 'yamato', 'nozomi', 'seiji', 'chihiro', 'yuuto']
  if (!lodash.includes(aitalkSpeakerNames, speaker)) return true

  if (props.isSimulator) {
    const credentials = {
      ...(state.session.account || {}),
      token: state.session.token,
      role: state.session.role,
    }
    return isPermittedCredentials('feature_aitalk', credentials)
  } else {
    return props.features.enableAITalk
  }
}

export const isEnabled = (feature, context) => {
  if (!context || !context.store) {
    return false
  }
  return isEnabledByCredentials(feature, getCredentials(context))
}

export const isEnabledByCredentials = (feature, credentials) => {
  const { is_active, is_experimental } = credentials

  if (!is_active) {
    return false
  }
  if (experimentalFeatures.includes(feature) && !is_experimental) {
    return false
  }
  return true
}

export const rejectReadonlyUser = (dispatch, context) => {
  const { t } = context
  const { role } = getCredentials(context)

  if (role === 'readonly') {
    dispatch(addNotice('error', t('error.rejectReadonlyUser')))
    return true
  } else {
    return false
  }
}
