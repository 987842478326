import lodash from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes, getFormValues } from 'redux-form'
import { UAParser } from 'ua-parser-js'

import { fetchAccount } from '../../actions/account'
import { exportOperationHistory } from '../../actions/operation_history'
import { InputField } from '../../components/common/fields/FormFields'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import { downloadBlob } from '../../helpers/download'

const validate = data => {
  const errors = {}

  const start = moment(data.start)
  const end = moment(data.end)
  if (start.isValid() && end.isValid()) {
    if (start.isAfter(end)) {
      errors.periods = 'validate.invalidDuration'
    }
  }

  return errors
}

class OperationHistory extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    account: PropTypes.object,
    formValues: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
  }

  constructor() {
    super()
    this.state = { max: null, min: null }
    this.validateDate = this.validateDate.bind(this)
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props

    dispatch(fetchAccount(state.session.token))

    const now = moment()
    const max = now.clone().format('YYYY-MM-DD')
    const min = now.clone().subtract(1, 'years').format('YYYY-MM-DD')
    this.setState({ max, min })

    const start = now.clone().set('date', 1)
    const end = now.clone()

    if (new UAParser().getBrowser().name === 'IE') {
      this.props.change('start', start.format('YYYY/MM/DD'))
      this.props.change('end', end.format('YYYY/MM/DD'))
    } else {
      this.props.change('start', start.format('YYYY-MM-DD'))
      this.props.change('end', end.format('YYYY-MM-DD'))
    }
  }

  handleExport = data => {
    const state = this.context.store.getState()
    const { account, dispatch } = this.props

    const start = moment(data.start).format('YYYY-MM-DD')
    const end = moment(data.end).format('YYYY-MM-DD')

    const body = { start_date: start, end_date: end }
    return dispatch(exportOperationHistory(state.session.token, body)).then(blob => {
      const timestamp = moment().format('YYYYMMDDhhmmss')
      const filename = `${account.company_name}-access-log-${timestamp}.csv`
      downloadBlob(blob, filename)
    })
  }

  validateDate(value) {
    if (!value) return 'validate.required'

    const date = moment(value, 'YYYY-MM-DD', true).isValid()
      ? moment(value, 'YYYY-MM-DD', true)
      : moment(value, 'YYYY/MM/DD', true)
    if (!date.isValid()) return 'validate.invalid'

    if (date.isAfter(this.state.max)) {
      return 'validate.futureDate'
    }
    if (date.isBefore(this.state.min)) {
      return 'validate.tooOldDate'
    }
  }

  renderError = field => {
    const { t } = this.context
    const {
      meta: { error, touched },
    } = field
    if (!error || !touched) return null

    return <div className="error">{t(error)}</div>
  }

  render() {
    const { t } = this.context
    const { submitting, handleSubmit, formValues } = this.props

    // Don't set date before minimum date as maximum date
    const maxStart = lodash.max([formValues.end || this.state.max, this.state.min])

    // Don't set date after maximum date as minimum date
    const minEnd = lodash.min([formValues.start || this.state.min, this.state.max])

    return (
      <div className="dm-operation-history">
        <form onSubmit={handleSubmit(this.handleExport)}>
          <LabelWithTooltip className="dm-title" name="operationHistory.title" />
          <div className="form-group">
            <div className="periods">
              <div>
                <LabelWithTooltip htmlFor="start" className="dm-title-mini" name="operationHistory.start" />
                <Field
                  name="start"
                  type="date"
                  component={InputField}
                  max={maxStart}
                  min={this.state.min}
                  required={true}
                  placeholder="YYYY/MM/DD"
                  validate={this.validateDate}
                />
              </div>
              <div>
                <LabelWithTooltip htmlFor="end" className="dm-title-mini" name="operationHistory.end" />
                <Field
                  name="end"
                  type="date"
                  component={InputField}
                  max={this.state.max}
                  min={minEnd}
                  required={true}
                  placeholder="YYYY/MM/DD"
                  validate={this.validateDate}
                />
              </div>
            </div>
            <Field name="periods" component={this.renderError} />
          </div>
          <div className="form-group">
            <div className="dm-note">{t('operationHistory.note1')}</div>
            <div className="dm-note">{t('operationHistory.note2')}</div>
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary dm-btn" disabled={submitting}>
              {t('common.download')}
            </button>
          </div>
        </form>
      </div>
    )
  }
}

const OperationHistoryForm = reduxForm({
  form: 'OperationHistory',
  validate,
})(OperationHistory)

export const mapStateToProps = state => {
  const account = lodash.first(Object.values(state.entities.accounts))

  return {
    account,
    formValues: getFormValues('OperationHistory')(state) || {},
  }
}

export default connect(mapStateToProps)(OperationHistoryForm)
