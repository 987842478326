import lodash from 'lodash'
import React from 'react'
import { FieldArray } from 'redux-form'

import AbstractAction from './AbstractAction'
import CarouselTabs from './CarouselTabs'
import LabelWithTooltip from '../common/LabelWithTooltip'
import VariableField from '../common/fields/VariableField'
import { lengthWithoutVariable } from '../../helpers/validation'

export class CarouselAction extends AbstractAction {
  getTitle() {
    return this.context.t('topic.actionList.carousel.title')
  }
  getType() {
    const { action } = this.props
    const isPostbackExist = lodash.some(action.items, item => {
      if (item.actions) {
        if (
          lodash.some(item.actions, action => {
            return action.type === 'postback'
          })
        ) {
          return true
        } else if (item.default_action && item.default_action.type === 'postback') {
          return true
        } else {
          return false
        }
      }
    })
    if (isPostbackExist) {
      return 'user'
    } else {
      return 'normal'
    }
  }

  static validate = action => {
    const errors = super.validate(action)

    const hasImageUrl = item => {
      if (item.image_method === 'upload') return !!item.upload_image_url
      else if (item.image_method === 'url') return !!item.input_image_url
      else return false
    }
    // Unify existence of titles over all columns
    const hasTitleError = lodash.some(action.items, 'title') && !lodash.every(action.items, 'title')

    const hasImageInSomeItem = lodash.some(action.items, hasImageUrl)

    // Unify existence of images over all columns
    const hasImageError = lodash.some(action.items, hasImageUrl) && !lodash.every(action.items, hasImageUrl)

    const minItemActionLength = lodash.min(
      action.items.map(item => {
        return item.actions ? item.actions.length : 0
      })
    )
    // const useItemTitle = lodash.
    errors.items = action.items.map(item => {
      const errors = {}

      if (hasTitleError) {
        errors.title = 'validate.invalidIntegrityTitles'
      }

      if (!hasImageInSomeItem && !item.text) {
        errors.text = 'validate.required'
      }

      if (hasImageError) {
        errors.image = 'validate.invalidIntegrityImages'
        errors.input_image_url = 'validate.invalidIntegrityImages'
      }

      if (lodash.reject(item.actions, lodash.isEmpty).length === 0) {
        if (!item.enable_default_action) {
          errors.actions = [{ type: 'validate.required', label: 'validate.required' }]
        } else if (item.title || item.text) {
          errors.actions = [{ type: 'validate.required', label: 'validate.required' }]
        }
      } else {
        errors.actions = item.actions.map((button, index) => {
          const error = {}
          if (index + 1 > minItemActionLength) {
            error.type = 'validate.differentActionLength'
          } else {
            if (!button.type) {
              error.type = 'validate.required'
            }
          }
          return error
        })
      }

      if (item.enable_default_action) {
        if (!item.default_action) {
          errors.default_action = { type: 'validate.required', value: 'validate.required' }
        } else {
          errors.default_action = {}
          if (!item.default_action.type) {
            errors.default_action.type = 'validate.required'
          }
        }
      }
      return errors
    })
    return errors
  }

  static validateForLine = action => {
    if (lodash.some(action.items, item => lengthWithoutVariable(item.text) > 60)) {
      return false
    }
    return true
  }

  static getVariables = (action, _props, _context) => {
    return [{ variable: action.title, text: action.title }]
  }

  renderBody() {
    const { topicType, name, action, onClickImageReset, onChangeCarouselImageFile, onError } = this.props

    return (
      <div className="carousel">
        <div className="form-group row dm-form-group">
          <LabelWithTooltip
            className="dm-title-mini col-md-2"
            name="topic.actionList.carousel.carouselTitle"
          />
          <div className="col-md-10">
            <VariableField name={`${name}.title`} className="form-control dm-form-control" />
          </div>
        </div>
        <LabelWithTooltip className="dm-title-mini" name="topic.actionList.carousel.carousels" />
        <FieldArray
          topicType={topicType}
          name={`${name}.items`}
          items={action.items}
          component={CarouselTabs}
          onClickImageReset={onClickImageReset}
          onChangeCarouselImageFile={onChangeCarouselImageFile}
          onError={onError}
        />
      </div>
    )
  }
}

export default CarouselAction
