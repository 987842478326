import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form'
import { SelectField } from '../../components/common/fields/FormFields'
import LabelWithTooltip from '../common/LabelWithTooltip'

import AbstractAction from './AbstractAction'
import TextField from '../common/fields/TextField'

export class ChangeScenarioAction extends AbstractAction {
  static propTypes = {
    ...AbstractAction.propTypes,
    topics: PropTypes.array.isRequired,
  }

  getTitle() {
    return this.context.t('topic.actionList.change_scenario.title')
  }
  getType() {
    return 'normal'
  }

  static validate = action => {
    const errors = super.validate(action)

    if (!action.target_topic_id) {
      errors.target_topic_id = 'validate.required'
    }

    return errors
  }

  renderBody() {
    const { name, topics } = this.props

    return (
      <div className="choose">
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor="title"
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.change_scenario.target"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <Field
              name={`${name}.target_topic_id`}
              className="form-control"
              component={SelectField}
              items={topics}
              valueKey="id"
              displayKey="name"
              empty={true}
              parse={value => value && parseInt(value, 10)}
              order="asc"
            />
          </div>
        </div>
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor="message"
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.change_scenario.message"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <TextField name={`${name}.content`} className="form-control" nullable={true} />
          </div>
        </div>
      </div>
    )
  }
}

export default ChangeScenarioAction
