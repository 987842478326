import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import Sticky from 'react-sticky-state'

import { InputField } from '../common/fields/FormFields'
import Tooltip from '../common/Tooltip'
import LabelWithTooltip from '../common/LabelWithTooltip'
import ActionList from '../topic/ActionList'
import ActionSubList from '../topic/ActionSubList'

export class PnpTopicEditComponent extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    topic_id: PropTypes.string,
    actions: PropTypes.array,
    handleSave: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    isLoadedImage: PropTypes.bool,
    onChangeImageFile: PropTypes.func.isRequired,
    onClickImageReset: PropTypes.func.isRequired,
    onChangeCarouselImageFile: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  }

  static defaultProps = {
    actions: [],
    examples: [],
  }

  onSubmit = _e => {
    document.activeElement.blur()
  }

  renderError = fields => {
    const { t } = this.context
    const error = lodash.get(lodash.find(fields, 'meta.error'), 'meta.error')
    const touched = lodash.some(fields, 'meta.touched')
    if (error && touched) {
      return <div className="error delay">{t(error)}</div>
    } else {
      return null
    }
  }

  render() {
    const { t } = this.context
    const {
      submitting,
      topic_id,
      actions,
      handleSave,
      handleDelete,
      isLoadedImage,
      onChangeImageFile,
      onClickImageReset,
      onChangeCarouselImageFile,
      onError,
    } = this.props

    const restrictedActions = [
      'slot',
      'form',
      'operator',
      'exit_topic',
      'rerun_scenario',
      'change_scenario',
      'choose_scenario',
      'integration',
      'item_list',
      'choose_list',
      'carousel_list',
      'faq',
      'generate_answer',
      'register_task',
      'abort_task',
      'complete_task',
    ]

    return (
      <div className="topic">
        <div className="row dm-topics">
          <form className="text-left col-md-9" onSubmit={handleSave}>
            <div className="form-group">
              <LabelWithTooltip htmlFor="name" className="dm-title" name="pnpTopic.name" />
              <Field
                name="name"
                component={InputField}
                type="text"
                className="form-control dm-form-control"
                maxLength="255"
              />
            </div>
            <div className="form-group">
              <label className="dm-title">
                {t('pnpTopic.actions')}
                <Tooltip name="pnpTopic.tooltip.actionsCenter" />
              </label>
              <ActionList
                topicType="phone_number_push"
                actions={actions}
                maxLength={5}
                isLoadedImage={isLoadedImage}
                onChangeImageFile={onChangeImageFile}
                onClickImageReset={onClickImageReset}
                onChangeCarouselImageFile={onChangeCarouselImageFile}
                onError={onError}
                restrictedActions={restrictedActions}
              />
            </div>
            <Sticky>
              <div className="sticky bottom">
                <div className="dm-sticky-buttons">
                  <button
                    type="submit"
                    className="btn btn-primary dm-btn"
                    disabled={submitting}
                    onClick={this.onSubmit}
                  >
                    {t('common.save')}
                  </button>
                  {topic_id && (
                    <button
                      type="button"
                      className="btn btn-danger dm-btn"
                      onClick={handleDelete}
                      disabled={submitting}
                    >
                      {t('common.delete')}
                    </button>
                  )}
                </div>
              </div>
            </Sticky>
          </form>
          <div className="hidden-sm-down col-md-3">
            <div className="dm-topic-sidebar">
              <ActionSubList type="topic" actions={actions} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PnpTopicEditComponent
