import lodash from 'lodash'

const reducer = (state = {}, action) => {
  if (action.type === 'UPDATE_TABLE_STATE') {
    return {
      ...state,
      [action.payload.path]: lodash.merge({}, state[action.payload.path], action.payload.tableState),
    }
  }

  return state
}

export default reducer
