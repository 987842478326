import Schemas from '../middleware/schema'
import request from '../helpers/requestManagementAPI'

export const fetchDeliveryJobs = (token, application_id) => dispatch => {
  return request.get(`/applications/${application_id}/delivery_jobs`, {
    token,
    dispatch,
    target: 'delivery_jobs',
    schema: Schemas.deliveryJobs,
    preserve: false,
  })
}

export const fetchDeliveryJob = (token, application_id, id) => dispatch => {
  return request.get(`/applications/${application_id}/delivery_jobs/${id}`, {
    token,
    dispatch,
    target: 'delivery_jobs',
    schema: Schemas.deliveryJob,
    preserve: false,
  })
}

export const createDeliveryJob = (token, application_id, job) => dispatch => {
  return request.post(`/applications/${application_id}/delivery_jobs`, {
    token,
    dispatch,
    body: job,
    target: 'delivery_jobs',
    schema: Schemas.deliveryJob,
  })
}
