import Schemas from '../middleware/schema'
import request from '../helpers/requestManagementAPI'
import { deleteCache } from './api'

export const fetchConditionGroups = (token, filter = {}) => dispatch => {
  return request.get('/condition_groups/', {
    token,
    dispatch,
    body: filter,
    target: 'condition_groups',
    schema: Schemas.conditionGroups,
    preserve: false,
  })
}

export const fetchConditionGroup = (token, id, ignoreNotice) => dispatch => {
  return request.get(`/condition_groups/${id}`, {
    token,
    dispatch,
    target: 'condition_groups',
    schema: Schemas.conditionGroup,
    ignoreNotice: ignoreNotice,
  })
}

export const createConditionGroup = (token, condition_group, ignoreNotice) => dispatch => {
  return request.post('/condition_groups/', {
    token,
    dispatch,
    body: condition_group,
    target: 'condition_groups',
    schema: Schemas.conditionGroup,
    ignoreNotice: ignoreNotice,
  })
}

export const updateConditionGroup = (token, condition_group, ignoreNotice) => dispatch => {
  return request.put(`/condition_groups/${condition_group.id}`, {
    token,
    dispatch,
    body: condition_group,
    target: 'condition_groups',
    schema: Schemas.conditionGroup,
    ignoreNotice: ignoreNotice,
  })
}

export const deleteConditionGroup = (token, id, ignoreNotice) => dispatch => {
  return request
    .delete(`/condition_groups/${id}`, {
      token,
      dispatch,
      target: 'condition_groups',
      ignoreNotice: ignoreNotice,
    })
    .then(() => dispatch(deleteCache('condition_groups', id)))
}
