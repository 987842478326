import React, { Component, Children, cloneElement } from 'react'
import PropTypes from 'prop-types'
import styles from './tree-node-renderer.module.scss'
import classnames from 'classnames'

class FileThemeTreeNodeRenderer extends Component {
  render() {
    const {
      children,
      listIndex,
      swapFrom,
      swapLength,
      swapDepth,
      scaffoldBlockPxWidth, // eslint-disable-line no-unused-vars
      lowerSiblingCounts,
      connectDropTarget,
      isOver,
      draggedNode,
      canDrop,
      treeIndex, // eslint-disable-line no-unused-vars
      treeId, // eslint-disable-line no-unused-vars
      getPrevRow, // eslint-disable-line no-unused-vars
      node, // eslint-disable-line no-unused-vars
      path, // eslint-disable-line no-unused-vars
      rowDirection, // eslint-disable-line no-unused-vars
      ...otherProps
    } = this.props

    // Construct the scaffold representing the structure of the tree
    const scaffoldBlockCount = lowerSiblingCounts.length
    const scaffold = []
    lowerSiblingCounts.forEach((lowerSiblingCount, i) => {
      //  Skip tree-node in root
      if (i === 0) {
        return
      }

      let lineClass = ''
      if (lowerSiblingCount > 0) {
        // At this level in the tree, the nodes had sibling nodes further down
        if (i === scaffoldBlockCount - 1) {
          // Last scaffold block in the row, right before the row content
          // +--+--+
          // |  |  |
          // |  +--+
          // |  |  |
          // +--+--+
          lineClass = classnames(styles.lineHalfHorizontalRight, styles.lineFullVertical)
        } else {
          // Simply connecting the line extending down to the next sibling on this level
          // +--+--+
          // |  |  |
          // |  |  |
          // |  |  |
          // +--+--+
          lineClass = classnames(styles.lineFullVertical)
        }
      } else if (i === scaffoldBlockCount - 1) {
        // The last or only node in this level of the tree
        // +--+--+
        // |  |  |
        // |  +--+
        // |     |
        // +-----+
        lineClass = classnames(styles.lineHalfVerticalTop, styles.lineHalfHorizontalRight)
      }

      scaffold.push(
        <div
          key={`pre_${1 + i}`}
          style={{ width: scaffoldBlockPxWidth }}
          className={classnames(styles.lineBlock, lineClass)}
        />
      )
    })

    const style = { left: scaffoldBlockPxWidth * (scaffoldBlockCount - 1) }

    return connectDropTarget(
      <div {...otherProps} className={classnames(styles.node)}>
        {scaffold}

        <div className={styles.nodeContent} style={style}>
          {Children.map(children, child =>
            cloneElement(child, {
              isOver,
              canDrop,
              draggedNode,
              lowerSiblingCounts,
              listIndex,
              swapFrom,
              swapLength,
              swapDepth,
            })
          )}
        </div>
      </div>
    )
  }
}

FileThemeTreeNodeRenderer.defaultProps = {
  swapFrom: null,
  swapDepth: null,
  swapLength: null,
  canDrop: false,
  draggedNode: null,
}

FileThemeTreeNodeRenderer.propTypes = {
  treeIndex: PropTypes.number.isRequired,
  treeId: PropTypes.string.isRequired,
  swapFrom: PropTypes.number,
  swapDepth: PropTypes.number,
  swapLength: PropTypes.number,
  scaffoldBlockPxWidth: PropTypes.number.isRequired,
  lowerSiblingCounts: PropTypes.arrayOf(PropTypes.number).isRequired,

  listIndex: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,

  // Drop target
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool,
  draggedNode: PropTypes.shape({}),

  // used in dndManager
  getPrevRow: PropTypes.func.isRequired,
  node: PropTypes.shape({}).isRequired,
  path: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  rowDirection: PropTypes.string.isRequired,
}

export default FileThemeTreeNodeRenderer
