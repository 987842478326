import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class PasswordPolicy extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  render() {
    const { t } = this.context

    return (
      <div className="dm-form-group">
        <ul className="password_policy">
          <li>
            {t('user.passwordPolicy.description1-1')}
            <br />
            {t('user.passwordPolicy.description1-2')}
            <b>{t('user.passwordPolicy.description1-3')}</b>
          </li>
          <li>{t('user.passwordPolicy.description2')}</li>
          <li>{t('user.passwordPolicy.description3')}</li>
        </ul>
      </div>
    )
  }
}

export default PasswordPolicy
