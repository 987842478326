import { deleteCache } from './api'
import Schemas from '../middleware/schema'
import request from '../helpers/requestManagementAPI'

export const fetchBots = (token, filter = {}, preserve = false) => dispatch => {
  return request.get('/bots/', {
    token,
    dispatch,
    body: filter,
    target: 'bots',
    schema: Schemas.bots,
    preserve,
  })
}

export const fetchBot = (token, id, ignoreFetching = false, errorHandler) => dispatch => {
  return request.get(`/bots/${id}`, {
    token,
    dispatch,
    target: ignoreFetching ? null : 'bots',
    schema: Schemas.bot,
    errorHandler,
  })
}

export const createBot = (token, bot) => dispatch => {
  return request.post('/bots/', {
    token,
    dispatch,
    body: bot,
    target: 'bots',
    schema: Schemas.bot,
  })
}

export const updateBot = (token, bot) => dispatch => {
  return request.put(`/bots/${bot.id}`, {
    token,
    dispatch,
    body: bot,
    target: 'bots',
    schema: Schemas.bot,
  })
}

export const copyBot = (token, id, newName, ignoreNotice = false) => dispatch => {
  return request.post(`/bots/${id}/copy`, {
    token,
    dispatch,
    body: { new_name: newName },
    target: 'bots',
    schema: Schemas.bot,
    ignoreNotice,
  })
}

export const deleteBot = (token, id, ignoreNotice) => dispatch => {
  return request
    .delete(`/bots/${id}`, {
      token,
      dispatch,
      target: 'bots',
      ignoreNotice: ignoreNotice,
    })
    .then(() => dispatch(deleteCache('bots', id)))
}

export const trainBot = (token, bot_id) => dispatch => {
  setTimeout(() => {
    dispatch(fetchBot(token, bot_id))
  }, 1500)
  return request.post(`/bots/${bot_id}/train`, {
    token,
    dispatch,
    target: 'bots',
  })
}

export const abortBot = (token, bot_id) => dispatch => {
  return request.post(`/bots/${bot_id}/abort`, {
    token,
    dispatch,
    target: 'bots',
  })
}

export const exportBot = (token, bot_id) => dispatch => {
  return request.post(`/bots/${bot_id}/export`, {
    token,
    dispatch,
  })
}

export const importBot = (token, body) => dispatch => {
  return request.post('/bots/import', {
    token,
    dispatch,
    body,
    target: 'bots',
    schema: Schemas.bot,
  })
}

export const fetchLearningExclusionWords = (token, bot_id, filter = {}) => dispatch => {
  return request.get(`/bots/${bot_id}/exclusion_words`, {
    token,
    dispatch,
    body: filter,
    target: 'learning_exclusion_words',
    schema: Schemas.learningExclusionWords,
    preserve: false,
  })
}

export const fetchLearningExclusionWord = (token, bot_id, id) => dispatch => {
  return request.get(`/bots/${bot_id}/exclusion_words/${id}`, {
    token,
    dispatch,
    target: 'learning_exclusion_words',
    schema: Schemas.learningExclusionWord,
    ignoreNotice: true,
  })
}

export const createLearningExclusionWord = (
  token,
  bot_id,
  learningExclusionWord,
  ignoreNotice = true
) => dispatch => {
  return request.post(`/bots/${bot_id}/exclusion_words`, {
    token,
    dispatch,
    body: learningExclusionWord,
    target: 'learning_exclusion_words',
    schema: Schemas.learningExclusionWord,
    ignoreNotice,
  })
}

export const updateLearningExclusionWord = (token, bot_id, learningExclusionWord) => dispatch => {
  return request.put(`/bots/${bot_id}/exclusion_words/${learningExclusionWord.id}`, {
    token,
    dispatch,
    body: learningExclusionWord,
    target: 'learning_exclusion_words',
    schema: Schemas.learningExclusionWord,
    ignoreNotice: true,
  })
}

export const deleteLearningExclusionWord = (token, bot_id, id) => dispatch => {
  return request
    .delete(`/bots/${bot_id}/exclusion_words/${id}`, {
      token,
      dispatch,
      target: 'learning_exclusion_words',
      ignoreNotice: true,
    })
    .then(() => dispatch(deleteCache('learning_exclusion_words', id)))
}

export const importTopicCSV = (token, id, topics, body) => dispatch => {
  topics.forEach(topic => dispatch(deleteCache('topics', topic.id)))
  return request.post(`/bots/${id}/import`, {
    token,
    dispatch,
    body,
    target: 'bots',
    schema: Schemas.bot,
  })
}
