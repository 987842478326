import PropTypes from 'prop-types'
import React from 'react'
import MessageIcon from './MessageIcon'

import AbstractMessage from './AbstractMessage'

class OperatorTypingMessage extends AbstractMessage {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    ...AbstractMessage.propTypes,
    text: PropTypes.string,
  }

  render() {
    const { t } = this.context
    const { mode, sender_type, iconUrl, fallbackIconUrl, isLoaded, text } = this.props

    if (mode !== 'client') return null

    return (
      <li className="server">
        <div className="body">
          <MessageIcon
            sender_type={sender_type}
            url={iconUrl}
            fallbackUrl={fallbackIconUrl}
            isLoaded={isLoaded}
          />
          <div className="balloon">
            <div className="content">
              <p className="text">
                <span className="with-dot">{text || t('chatMessage.operatorTyping')}</span>
                <span className="dot fadein-out1" />
                <span className="dot fadein-out2" />
                <span className="dot fadein-out3" />
              </p>
            </div>
          </div>
        </div>
      </li>
    )
  }
}

export default OperatorTypingMessage
