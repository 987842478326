import lodash from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import classnames from 'classnames'

import DataBindingTable from '../../components/common/DataBindingTable'
import { fetchBots } from '../../actions/bot'
import { addNotice } from '../../actions/notice'
import { updateTableState } from '../../actions/table'
import { isFetching } from '../../helpers/selector'
import { getCredentials } from '../../helpers/sessionHelper'

class BotIndex extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    bots: PropTypes.array.isRequired,
    isFetching: PropTypes.bool,
    tableState: PropTypes.object,
    maxBots: PropTypes.number,
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    dispatch(fetchBots(state.session.token))
  }

  handleRefreshClick = () => {
    const state = this.context.store.getState()
    this.props.dispatch(fetchBots(state.session.token))
  }

  handleAddClick = () => {
    const { t, router } = this.context
    const { dispatch, bots, maxBots } = this.props

    if (maxBots && bots.length >= maxBots) {
      dispatch(addNotice('warn', t('bot.botsLimitation', { maximum: maxBots })))
      return
    }

    router.push('/bots/new')
  }

  handleImportClick = () => {
    const { t, router } = this.context
    const { dispatch, bots, maxBots } = this.props

    if (maxBots && bots.length >= maxBots) {
      dispatch(addNotice('warn', t('bot.botsLimitation', { maximum: maxBots })))
      return
    }

    router.push('/bots/import')
  }

  updateTableState = (path, tableName, tableState) => {
    const { dispatch } = this.props
    dispatch(updateTableState(path, tableName, tableState))
  }

  render() {
    const { t } = this.context
    const { bots, isFetching, tableState, maxBots } = this.props
    const { timezone } = getCredentials(this.context)

    const isPossibleToAdd = maxBots == null || maxBots > bots.length
    const addButtonClassNames = classnames({
      'dm-btn': true,
      btn: true,
      'btn-icon-plus': true,
      mini: true,
      'btn-primary': isPossibleToAdd,
      'btn-alert': !isPossibleToAdd,
    })
    const importButtonClassNames = classnames({
      'dm-btn': true,
      btn: true,
      'btn-primary': isPossibleToAdd,
      'btn-alert': !isPossibleToAdd,
    })

    const columns = [
      {
        Header: t('bot.name'),
        id: 'name',
        accessor: 'name',
        Cell: props => <Link to={`/bots/${props.original.id}`}>{props.value}</Link>,
        getFooterProps: () => ({ className: 'footer-all' }),
        Footer: (
          <div>
            <div className="pull-left">
              <Link className={addButtonClassNames} onClick={this.handleAddClick}>
                <span>{t('common.new')}</span>
              </Link>
            </div>
            {maxBots && (
              <div className="pull-right">{t('bot.remaining', { count: bots.length, total: maxBots })}</div>
            )}
          </div>
        ),
      },
      {
        Header: t('common.lastUpdatedBy'),
        id: 'lastUpdatedBy',
        accessor: bot => bot.last_updated_by.name || '',
        width: 160,
      },
      {
        Header: t('common.updatedAt'),
        id: 'updatedAt',
        accessor: bot =>
          bot.updated_at ? moment.tz(bot.updated_at, timezone).format('YYYY/MM/DD HH:mm') : '',
        width: 160,
      },
    ]
    return (
      <div>
        <DataBindingTable
          title={t('bot.title')}
          titleTooltip="bot.tooltip.title"
          items={bots}
          columns={columns}
          isFetching={isFetching}
          tableState={tableState}
          updateTableState={this.updateTableState}
          onRefresh={this.handleRefreshClick}
        />
        <div className="text-right">
          <Link className={importButtonClassNames} onClick={this.handleImportClick} disabled={isFetching}>
            {t('bot.importTemplate')}
          </Link>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const account = lodash.first(Object.values(state.entities.accounts)) || {}

  const ids = state.pagination.bots.ids || []
  const bots = ids.map(id => state.entities.bots[id])

  return {
    bots: bots,
    isFetching: isFetching(state),
    tableState: state.table[window.location.pathname],
    maxBots: account.max_bots,
  }
}

export default connect(mapStateToProps)(BotIndex)
