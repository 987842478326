import lodash from 'lodash'
import React from 'react'
import { Field, FieldArray } from 'redux-form'
import LabelWithTooltip from '../common/LabelWithTooltip'
import Tooltip from '../common/Tooltip'
import IncrementableTable from '../common/IncrementableTable'
import { SelectField } from '../common/fields/FormFields'
import AbstractAction from './AbstractAction'
import FieldTable from './FieldTable'
import VariableField from '../common/fields/VariableField'
import LabelField from '../common/fields/LabelField'
import ChooseButtonValueField from '../common/fields/ChooseButtonValueField'

export class FormAction extends AbstractAction {
  getTitle() {
    return this.context.t('topic.actionList.form.title')
  }
  getType() {
    return 'user'
  }

  static validate = action => {
    const errors = super.validate(action)

    if (action.fields.length === 1 && lodash.keys(action.fields[0]).length === 1) {
      errors.fields = [{ type: 'validate.required', name: 'validate.required' }]
    } else {
      const formNames = []
      const formErrors = action.fields.map((field, index) => {
        const errors = {}
        if (!field) return {}
        if (action.fields.length === index + 1 && lodash.keys(field).length === 1) return {}

        if (!field.type) {
          errors.type = 'validate.required'
        } else {
          const argumentErrors = {}

          const args = field.arguments || {}
          if (field.type === 'label') {
            if (!args.text) argumentErrors.text = 'validate.required'
          }

          if (field.type === 'select_list') {
            if (args.data_type === 'constant') {
              const length = parseInt(args.length, 10)
              const fieldLength = length > 0 ? lodash.min([length, 20]) : 1

              const dataList = args.data_list || []
              argumentErrors.data_list = lodash
                .range(fieldLength)
                .map(index => (dataList[index] ? undefined : 'validate.required'))
            }
          }

          if (lodash.includes(['select', 'select_list', 'multiple_select'], field.type)) {
            if (args.data_type === 'constant') {
              if (!args.data) {
                argumentErrors.data = 'validate.required'
              }
            } else if (args.data_type === 'variable') {
              if (!args.item_label) {
                argumentErrors.item_label = 'validate.required'
              }
            }
          }

          if (lodash.includes(['text_list', 'select_list'], field.type)) {
            if (field.type === 'text_list' || args.data_type === 'constant') {
              if (!args.length) {
                argumentErrors.length = 'validate.required'
              } else if (args.length < 1) {
                argumentErrors.length = { id: 'validate.minimumLimit', values: { minimum: '1' } }
              } else if (args.length > 20) {
                argumentErrors.length = { id: 'validate.maximumLimit', values: { maximum: '20' } }
              }
            }

            if (args.use_collapsible) {
              if (args.default_display_limit == null) {
                argumentErrors.collapsibleError = 'validate.required'
              } else if (args.default_display_limit < 0) {
                argumentErrors.collapsibleError = {
                  id: 'validate.minimumLimit',
                  values: { minimum: '0' },
                }
              } else {
                let maxLength = 19

                if (field.type === 'text_list') {
                  maxLength = lodash.min([args.length - 1, 19])
                } else {
                  // field.type is select_list
                  if (args.data_type === 'constant') {
                    maxLength = lodash.min([args.length - 1, 19])
                  }
                }

                if (parseInt(args.default_display_limit, 10) > maxLength) {
                  argumentErrors.collapsibleError = {
                    id: 'validate.maximumLimit',
                    values: { maximum: maxLength },
                  }
                }
              }
            }
          }

          if (!lodash.isEmpty(argumentErrors)) errors.arguments = argumentErrors
        }

        if (field.name) {
          if (field.name.startsWith('@')) {
            errors.name = 'validate.invalidFormName'
          } else if (field.name.length > 40) {
            errors.name = { id: 'validate.exceededMaxLength', values: { length: 40 } }
          } else if (lodash.includes(formNames, field.name)) {
            errors.name = 'validate.itemNameDuplicated'
          } else {
            formNames.push(field.name)
          }
        } else {
          if (field.type !== 'label') {
            errors.name = 'validate.required'
          }
        }

        return errors
      })
      if (!lodash.every(formErrors, lodash.isEmpty)) errors.fields = formErrors
    }

    errors.buttons = []
    if (!action.buttons || action.buttons.length === 0) {
      errors.buttons[0] = { type: 'validate.required' }
    } else if (!lodash.some(action.buttons, { type: 'submit' })) {
      errors.buttons[0] = { type: 'validate.submitButtonRequired' }
    } else {
      errors.buttons = action.buttons.map(button => {
        if (!button.type) {
          return { type: 'validate.required' }
        } else {
          return {}
        }
      })
    }

    return errors
  }

  static getVariables = (action, _props, _context) => {
    return [{ variable: action.title, text: action.title }]
  }

  renderButtons = ({ fields }) => {
    const { t } = this.context

    const items = [
      { id: 'submit', name: t('topic.actionList.form.button.submit') },
      { id: 'cancel', name: t('topic.actionList.form.button.cancel') },
    ]

    const columns = [
      {
        Header: (
          <th key="type">
            {t('topic.actionList.form.button.type')}
            <Tooltip name="topic.tooltip.actionList.form.button.type" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            name={`${name}.type`}
            className="form-control dm-form-control"
            items={items}
            valueKey="id"
            displayKey="name"
            empty={true}
            component={SelectField}
          />
        ),
      },
      {
        Header: (
          <th key="label">
            {t('topic.actionList.form.button.label')}
            <Tooltip name="topic.tooltip.actionList.form.button.label" />
          </th>
        ),
        Cell: (name, index) => {
          let placeholder = null
          if ((fields.get(index) || {}).type === 'submit') {
            placeholder = t('topic.actionList.form.button.submitPlaceholder')
          } else if ((fields.get(index) || {}).type === 'cancel') {
            placeholder = t('topic.actionList.form.button.cancelPlaceholder')
          }

          return (
            <LabelField
              name={`${name}.label`}
              className="form-control dm-form-control"
              nullable={true}
              placeholder={placeholder}
            />
          )
        },
      },
      {
        Header: (
          <th key="value">
            {t('topic.actionList.form.button.value')}
            <Tooltip direction="left" name="topic.tooltip.actionList.form.button.value" />
          </th>
        ),
        Cell: (name, _index) => {
          return (
            <ChooseButtonValueField
              name={`${name}.value`}
              className="form-control dm-form-control"
              nullable={true}
            />
          )
        },
      },
    ]
    // Render field table for buttons in carousel's column
    return <IncrementableTable fields={fields} columns={columns} maxRows={5} />
  }

  renderBody() {
    const { t } = this.context
    const { name, action } = this.props

    return (
      <div className="form-action">
        <div className="form-group">
          <LabelWithTooltip htmlFor="title" name="topic.actionList.form.formTitle" />
          <VariableField name={`${name}.title`} className="form-control dm-form-control" />
        </div>
        <div className="form-group">
          <LabelWithTooltip htmlFor="fields" name="topic.actionList.form.fields" />
          &nbsp; (
          <a
            href="https://guide.dialogplay.jp/hc/ja/articles/8137865676313"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('topic.actionList.form.fieldsGuide')}
            <i className="fas fa-external-link-alt" />
          </a>
          )
          <FieldArray name={`${name}.fields`} formFields={action.fields} component={FieldTable} />
        </div>
        <div className="form-group dm-form-group">
          <LabelWithTooltip className="dm-title-mini" name="topic.actionList.form.button.title" />
          <FieldArray name={`${name}.buttons`} component={this.renderButtons} />
        </div>
      </div>
    )
  }
}

export default FormAction
