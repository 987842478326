import lodash from 'lodash'
import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { fetchRunningApplication } from '../../actions/embedded'

export class EmbeddedChatCallback extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }

  componentDidMount() {
    const {
      dispatch,
      params: { token },
    } = this.props

    import('aws-amplify').then(({ Auth, Hub }) => {
      Hub.listen('auth', this.onAuthEvent)

      dispatch(fetchRunningApplication(token)).then(features => {
        if (features.authenticationType !== 'none') {
          Auth.configure(features.amplifyConfiguration)
        } else {
          this.context.router.replace('/error')
        }
      })
    })
  }

  onAuthEvent = ({ payload: { event } }) => {
    switch (event) {
      case 'signIn':
        this.onSignIn()
        break
      case 'signIn_failure':
        this.onSignInFailure()
        break
      default:
    }
  }

  onSignIn = () => {
    if (window.opener) {
      window.opener.postMessage(
        {
          type: 'AuthenticationFlowFinished',
          status: 'success',
          cognitoSession: this.getCognitoSession(),
        },
        '*'
      )
      window.close()
    } else {
      this.context.router.replace('/error')
    }
  }

  onSignInFailure = () => {
    if (window.opener) {
      window.opener.postMessage(
        {
          type: 'AuthenticationFlowFinished',
          status: 'failed',
        },
        '*'
      )
    }
    this.context.router.replace('/error')
  }

  getCognitoSession = () => {
    return lodash.pickBy(localStorage, (v, k) => k.startsWith('CognitoIdentityServiceProvider'))
  }

  render() {
    return null
  }
}

export const mapStateToProps = (_state, _props) => {
  return {}
}

export default connect(mapStateToProps)(EmbeddedChatCallback)
