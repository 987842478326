import { deleteCache } from './api'
import Schemas from '../middleware/schema'
import request from '../helpers/requestManagementAPI'

export const fetchFaqCategories = (token, faq_id, filter = {}) => dispatch => {
  return request.get(`/faqs/${faq_id}/categories`, {
    token,
    dispatch,
    body: filter,
    target: 'faq_categories',
    schema: Schemas.faqCategories,
  })
}

export const fetchFaqCategory = (token, faq_id, id) => dispatch => {
  return request.get(`/faqs/${faq_id}/categories/${id}`, {
    token,
    dispatch,
    target: 'faq_categories',
    schema: Schemas.faqCategory,
  })
}

export const createFaqCategory = (token, faq_id, category) => dispatch => {
  return request.post(`/faqs/${faq_id}/categories`, {
    token,
    dispatch,
    body: category,
    target: 'faq_categories',
    schema: Schemas.faqCategory,
  })
}

export const updateFaqCategory = (token, faq_id, category) => dispatch => {
  return request.put(`/faqs/${faq_id}/categories/${category.id}`, {
    token,
    dispatch,
    body: category,
    target: 'faq_categories',
    schema: Schemas.faqCategory,
  })
}

export const deleteFaqCategory = (token, faq_id, id) => dispatch => {
  return request
    .delete(`/faqs/${faq_id}/categories/${id}`, {
      token,
      dispatch,
      target: 'faq_categories',
    })
    .then(() => dispatch(deleteCache('faq_categories', id)))
}

export const importFaqCategory = (token, faq_id, id, items, body) => dispatch => {
  items.forEach(item => dispatch(deleteCache('faq_items', item.id)))
  return request.post(`/faqs/${faq_id}/categories/${id}/import`, {
    token,
    dispatch,
    body,
    target: 'faq_categories',
    schema: Schemas.faqCategory,
  })
}

export const exportFaqCategory = (token, faq_id, id) => dispatch => {
  return request.get(`/faqs/${faq_id}/categories/${id}/export`, {
    token,
    dispatch,
  })
}
