import objectFitImages from 'object-fit-images'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

class ImageFileConfirmation extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    imageFile: PropTypes.object.isRequired,
    handleSendMessage: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  constructor() {
    super()
    this.state = { imageUrl: null }
  }

  componentDidMount() {
    const { imageFile } = this.props
    this.changePreviewImage(imageFile)
  }

  componentDidUpdate(prevProps) {
    const { imageFile } = this.props
    if (imageFile !== prevProps.imageFile) {
      this.changePreviewImage(imageFile)
    }
  }

  changePreviewImage = imageFile => {
    const image_src = window.URL.createObjectURL(imageFile)
    this.setState({ imageUrl: image_src })

    this.loadTimer = setTimeout(() => {
      objectFitImages()
    }, 1000)
  }

  onClickYesButton = () => {
    const { imageFile, handleSendMessage, onClose } = this.props

    handleSendMessage({ type: 'image', image_file: imageFile })
    onClose()
  }

  render() {
    const { t } = this.context
    const { onClose } = this.props

    return (
      <div className="modal-background" onClick={onClose}>
        <div className="modal-dialog" role="document" onClick={e => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-title">{t('embeddedChat.imageFileConfirmation.title')}</div>
            </div>
            <div className="modal-body">
              <img src={this.state.imageUrl} alt="" />
              <div className="pt-3">{t('embeddedChat.imageFileConfirmation.confirmationMessage')}</div>
            </div>
            <div className="modal-footer">
              <div className="flex-buttons">
                <button key="yes" type="button" className="btn" onClick={this.onClickYesButton}>
                  {t('embeddedChat.imageFileConfirmation.yes')}
                </button>
                <button key="no" type="button" className="btn" onClick={onClose}>
                  {t('embeddedChat.imageFileConfirmation.no')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ImageFileConfirmation
