import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import lodash from 'lodash'
import uuid from 'uuid'
import moment from 'moment'

import Config from '../../helpers/config'
import { fetchRooms } from '../../actions/chat'
import { storeMessage, sendMessage } from '../../actions/embedded'
import EmbeddedChatWrapper from './EmbeddedChatWrapper'

export class ChatSimulator extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    applicationToken: PropTypes.string.isRequired,
    currentChannel: PropTypes.string,
    room: PropTypes.object,
  }

  constructor() {
    super()
    this.state = { isPulldown: false }
  }

  componentWillUnmount() {
    clearTimeout(this.retryTimer)
  }

  componentWillUpdate(nextProps) {
    if (this.props.currentChannel !== nextProps.currentChannel) {
      this.refreshRoom(nextProps.currentChannel, true)
    }
  }

  refreshRoom = (currentChannel, autoRetry = false) => {
    const session = this.context.store.getState().session
    const { dispatch } = this.props

    if (!currentChannel) return Promise.resolve()

    if (autoRetry) {
      return new Promise((resolve, _reject) => {
        clearTimeout(this.retryTimer)
        this.retryTimer = setInterval(() => {
          dispatch(fetchRooms(session, { uuid: currentChannel })).then(result => {
            if (result.rooms.length > 0) {
              clearInterval(this.retryTimer)
              resolve(result)
            }
          })
        }, 1000)
      })
    } else {
      return dispatch(fetchRooms(session, { uuid: currentChannel }))
    }
  }

  createTemporaryMessage = (channel_uuid, text) => {
    return {
      uuid: uuid.v4(),
      timestamp: moment.utc(),
      channel_uuid: channel_uuid,
      sender_uid: '',
      sender_name: 'Guest',
      sender_type: 'client',
      type: 'text',
      content: { text: text },
      temporary: true,
    }
  }

  handleCallOperator = () => {
    const { dispatch, currentChannel } = this.props
    const message = this.createTemporaryMessage(currentChannel, Config.callOperatorMessage)
    dispatch(storeMessage(message))
    return dispatch(sendMessage(currentChannel, message)).then(() => this.refreshRoom(currentChannel))
  }

  handleResetChannel = () => {
    const { dispatch, currentChannel } = this.props
    const message = this.createTemporaryMessage(currentChannel, Config.resetMessage)
    dispatch(storeMessage(message))
    return dispatch(sendMessage(currentChannel, message)).then(() => this.refreshRoom(currentChannel))
  }

  togglePopup = e => {
    e.preventDefault()
    this.setState({ isOpened: !this.state.isOpened })
  }

  toggleSlotPane = e => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ isSlotOpened: !this.state.isSlotOpened })
  }

  togglePulldown = () => {
    this.setState({ isPulldown: !this.state.isPulldown })
  }

  render() {
    const { applicationToken } = this.props
    return (
      <div className={`dm-chat ${this.state.isPulldown ? 'with-pulldown' : ''}`}>
        <div className="pulldown" onClick={this.togglePulldown}>
          <i className="icon icon-arrows-down-double" />
        </div>
        <div className="main">
          <div className="pulldown-menu">{this.renderState()}</div>
          <EmbeddedChatWrapper
            application_token={applicationToken}
            handleRefresh={this.refreshRoom}
            isSimulator={true}
          />
        </div>
      </div>
    )
  }

  renderState = () => {
    const { t } = this.context
    const { room } = this.props

    if (!room) {
      return <div className="dm-chat-slot" />
    }

    let slotNodes

    if (room.slots.length > 0) {
      slotNodes = room.slots.map(slot => (
        <li key={slot.key} className="list-unstyled">
          <label>{`${slot.key}:`}</label>
          {slot.value || '----'}
        </li>
      ))
    } else {
      slotNodes = <li className="list-unstyled">{'----'}</li>
    }

    return (
      <div className="dm-chat-slot">
        <label>{t('simulator.chat.currentTopic')}: </label>
        <span>{room.topic.name}</span>

        <div className="field">
          <label>{t('simulator.chat.slots')}:</label>
          <ul>{slotNodes}</ul>
        </div>
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  return {
    ...props,
    currentChannel: state.chat.currentChannel,
    room: lodash.find(state.chat.rooms, { uuid: state.chat.currentChannel }),
  }
}

export default connect(mapStateToProps)(ChatSimulator)
