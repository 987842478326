import lodash from 'lodash'
import React from 'react'
import { Field, FieldArray } from 'redux-form'
import { InputField } from '../common/fields/FormFields'
import LabelWithTooltip from '../common/LabelWithTooltip'
import { isPermitted } from '../../helpers/permission'

import AbstractAction from './AbstractAction'
import TextField from '../common/fields/TextField'

export class CallOperatorAction extends AbstractAction {
  getTitle() {
    return this.context.t('topic.actionList.operator.title')
  }
  getType() {
    return 'stop'
  }
  getIcon() {
    return 'operator'
  }

  static validate = action => {
    const errors = super.validate(action)

    const tagErrors = action.tags.map(tag => {
      const errors = {}
      if (!tag) return {}
      if (!tag.k && !tag.v) {
        return {}
      }
      if (tag.k && tag.k.length > 30) {
        errors.k = { id: 'validate.exceededMaxLength', values: { length: 30 } }
      }
      if (tag.v && tag.v.length > 10000) {
        errors.k = { id: 'validate.exceededMaxLength', values: { length: 10000 } }
      }
      if (!tag.k && tag.v !== '') {
        errors.k = 'validate.required'
      }
      return errors
    })

    if (!lodash.every(tagErrors, lodash.isEmpty)) errors.tags = tagErrors
    return errors
  }

  isTagKeyFilled(index) {
    const { action } = this.props
    if (!action.tags[index]) return false
    if (!action.tags[index].k) return false
    return true
  }

  renderBody() {
    const { name } = this.props

    return (
      <div className="call_operator">
        <div className="row">
          <LabelWithTooltip
            htmlFor="message"
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.operator.message"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <TextField name={`${name}.content`} className="form-control" nullable={true} />
          </div>
        </div>
      </div>
    )
  }

  renderCustomizedAdvancedBody() {
    const { name } = this.props

    if (isPermitted('platform_mobilus', this.context)) {
      return (
        <div className="form-group row dm-form-group">
          <LabelWithTooltip
            htmlFor="operatorTags"
            className="col-sm-2"
            name="topic.actionList.operator.operatorTags"
          />
          <div className="col-sm-10">
            <FieldArray name={`${name}.tags`} component={this.renderTagMap} />
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  // eslint-disable-next-line react/display-name
  renderTagMap = ({ fields }) => {
    const { t } = this.context
    let items = []
    let canInputTagValue = false

    for (let index = 0; index < 3; index++) {
      canInputTagValue = this.isTagKeyFilled(index)

      // mobilus requests key,value items like
      // "[{'k': 'tag31', 'v': 'value1'}, {'k': 'tag32', 'v': 'value2'}] "
      items.push(
        <tr key={index}>
          <td>{index + 1}</td>
          <td>
            <Field
              type="text"
              name={`${fields.name}[${index}].k`}
              className="form-control dm-form-control"
              component={InputField}
            />
          </td>
          <td>
            <Field
              type="text"
              name={`${fields.name}[${index}].v`}
              className="form-control dm-form-control"
              disabled={!canInputTagValue}
              component={InputField}
            />
          </td>
        </tr>
      )
    }

    return (
      <table className="table table-bordered dm-table">
        <thead>
          <tr>
            <th>{t('topic.actionList.operator.tagTable.no')}</th>
            <th>{t('topic.actionList.operator.tagTable.roomTag')}</th>
            <th>{t('topic.actionList.operator.tagTable.content')}</th>
          </tr>
        </thead>
        <tbody>{items}</tbody>
      </table>
    )
  }
}

export default CallOperatorAction
