import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

class OAuthCallback extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    location: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const { t } = this.context
    const { location } = this.props

    const status = location.query.status
    if (window.opener) {
      window.opener.postMessage(
        {
          type: 'OAuthFlowFinished',
          status: status,
          message: t(`oauth_callback.${status}`),
        },
        '*'
      )
    }

    if (status === 'success') {
      window.close()
    }
  }

  render() {
    const { t } = this.context
    const { location } = this.props
    const status = location.query.status
    const error_message = location.query.error_message

    return (
      <React.Fragment>
        <h3>{t(`oauth_callback.${status}`)}</h3>
        {status === 'success' ? (
          <div className="note">{t('oauth_callback.successNote')}</div>
        ) : (
          <div className="error">{t(`${error_message}`)}</div>
        )}
      </React.Fragment>
    )
  }
}

export const mapStateToProps = (_state, _props) => {
  return {}
}

export default connect(mapStateToProps)(OAuthCallback)
