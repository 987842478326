export const requestAPI = (target, identifier) => ({
  type: 'REQUEST_API',
  target,
  identifier,
})

export const responseAPI = (target, response, preserve) => {
  if (response.result && !Array.isArray(response.result)) {
    response = {
      ...response,
      result: [response.result],
    }
  }
  return {
    type: 'RESPONSE_API',
    target,
    response,
    preserve,
  }
}

export const clearState = () => ({
  type: 'CLEAR_STATE',
})

export const deleteCache = (target, id) => ({
  type: 'DELETE_CACHE',
  target,
  id,
})

export const errorAPI = (target, source, error) => {
  error.source = source
  return {
    type: 'ERROR_API',
    target,
    error,
  }
}

export const closeAPI = (target, identifier) => ({
  type: 'CLOSE_API',
  target,
  identifier,
})
