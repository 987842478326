import lodash from 'lodash'

const topPage = {
  autoStart: true,
  name: 'topPage',
  steps: [
    {
      position: 'bottom',
      intro:
        'DialogPlayへようこそ！<br>' +
        'チュートリアルでは、DialogPlayの基本操作をご案内します。<br>' +
        'チュートリアルは右上のHelpアイコンを選択することでいつでも再表示できます。',
    },
    {
      element: '.dm-dashboard',
      position: 'bottom',
      intro: 'トップページでは、運営からのお知らせや、公開中のチャットボットの発言数などを確認できます。',
    },
    {
      element: 'ul.navbar-right',
      position: 'bottom',
      intro:
        '上部メニューからは各種補助機能などを利用できます。<br>' +
        '[Calls]: 有人オペレータの呼び出しに対する応対画面を開く<br>' +
        '[Guide]: DialogPlayのドキュメントサイトを開く<br>' +
        '[Help]: 各ページに設定されたチュートリアルを再度表示<br>' +
        '[Profile]: プロフィール更新、パスワードの変更、ログアウト等',
      onEnter: () => {
        window.scrollTo(0, 0)
      },
    },
    {
      element: 'ul.dm-sidebar',
      position: 'bottom',
      roles: ['owner', 'writer'],
      intro:
        'サイドメニューからは、DialogPlayの主要機能を利用できます。<br>' +
        'DialogPlayでは、以下の手順でチャットボットを公開できます。<br>' +
        '<br>' +
        '1. チャットボットを作成し、対応させたいシナリオを設定<br>' +
        '2. チャットボットをWeb, LINEなどのプラットフォームへ公開<br>' +
        '<br>' +
        '最初は、「チャットボット作成」の中の「新規作成」を選択してみましょう。',
      onEnter: () => {
        window.scrollTo(0, 0)
      },
    },
  ],
}
const operator = {
  autoStart: false,
  name: 'operator',
  steps: [
    {
      position: 'bottom',
      intro:
        'この画面はオペレーター用のチャット画面です。<br>オペレーターがユーザーとチャットをする場合に利用します。',
    },
    {
      element: 'div.dm-operator-sessions',
      position: 'right',
      intro: 'この画面には、ユーザーからのオペレーター呼び出し状況が表示されます。',
    },
    {
      element: 'div.dm-chat',
      position: 'bottom',
      intro:
        'この画面には、これまで、ユーザーとチャットボットが会話した履歴や、オペレーターと会話した履歴が表示されます。',
    },
    {
      element: 'div.slotState',
      position: 'left',
      intro:
        'この画面には、ユーザーとチャットボットの会話の概要が表示されます。<br>会話をしているチャットボットの名前や、ヒアリングの結果などが表示されます。',
    },
  ],
}
const applicationIndex = {
  autoStart: true,
  name: 'applicationIndex',
  steps: [
    {
      element: '.col-sm-9',
      position: 'bottom',
      intro: 'この画面では、公開済みのチャットボットを確認することができます。',
    },
    {
      element: 'a.btn',
      position: 'bottom',
      intro: 'まずは、チャットボットを公開してみましょう。<br>「新規作成」ボタンをクリックしてください。',
    },
  ],
}
const applicationNew = {
  autoStart: true,
  name: 'applicationNew',
  steps: [
    {
      position: 'bottom',
      intro: 'この画面では、チャットボットを公開するアプリケーションの新規作成を行うことができます。',
    },
    {
      element: '.col-sm-9',
      position: 'bottom',
      intro:
        'アプリケーション名やチャットボット公開先など各項目を入力後、「公開」ボタンをクリックし、アプリケーションを完成させましょう。<br>詳細については、<a href="https://guide.dialogplay.jp/hc/ja/categories/360001988711" target="_blank" rel="noopener noreferrer">ドキュメント</a>をご覧下さい。',
    },
  ],
}
const applicationEdit = {
  autoStart: true,
  name: 'applicationEdit',
  steps: [
    {
      position: 'top',
      intro: 'この画面では、アプリケーションの編集、公開（更新）、削除を行うことができます。',
    },
    {
      element: 'div.col-sm-9',
      position: 'bottom',
      intro:
        '公開するチャットボットの再選択やチャットボットのカラー、表示位置の変更など、一部を除き公開に必要な各種情報の更新ができます。',
    },
    {
      element: 'button[type="submit"]',
      position: 'top',
      intro:
        '「公開」ボタンをクリックすることで、アプリケーションの設定変更に加え、更新したチャットボットの内容がアプリケーションに反映されます。<br>クリックされなかった場合は<b>チャットボットの変更内容が</b>反映されませんのでご注意下さい。',
    },
    {
      element: 'button[type="submit"]',
      position: 'top',
      intro:
        '「学習して公開」ボタンが表示された場合は、「公開するチャットボット」で選択したチャットボットを学習した後、アプリケーションを更新します。',
    },
  ],
}
const applicationLogs = {
  autoStart: false,
  name: 'applicationLogs',
  steps: [
    {
      position: 'bottom',
      intro:
        'この画面では、公開したアプリケーション上で行われたユーザーとの会話の中から、シナリオ判定に失敗したユーザー発言に関する情報の確認と、シナリオの発言例への追加を行うことができます。',
    },
    {
      element: '.col-sm-9',
      position: 'bottom',
      intro:
        'シナリオ判定に失敗したユーザー発言を発言例に追加していくことで、これまでチャットボットが応答できなかった発言に対しても応答できるようになります。<br>定期的にこの画面を確認し、チャットボットを賢くしていきましょう。',
    },
  ],
}
const botCreate = {
  autoStart: true,
  name: 'botCreate',
  steps: [
    {
      position: 'bottom',
      intro:
        'チャットボットを新規作成する際には、サンプルテンプレートを選択してインポートするか、一からチャットボットを作成するかを選ぶことができます。',
    },
    {
      position: 'bottom',
      intro:
        'DialogPlayには、様々な用途に向けたチャットボットのサンプルテンプレートが用意されています。<br>' +
        '最初はやりたいことに近いサンプルテンプレートを試してみると、' +
        'どのような事をチャットボットに行わせることができるのかイメージしやすくなるでしょう。',
    },
  ],
}
const botIndex = {
  autoStart: true,
  name: 'botIndex',
  steps: [
    {
      position: 'bottom',
      intro:
        'この画面では作成済みのチャットボットを確認し、編集することができます。<br>' +
        'また、この画面からもチャットボットの新規作成やサンプルテンプレートのインポートを行うことができます。',
    },
  ],
}
const botNew = {
  autoStart: true,
  name: 'botNew',
  steps: [
    {
      element: '.col-sm-9',
      position: 'bottom',
      intro:
        'チャットボット名（必須項目）、初期発言を入力し、「保存」ボタンをクリックしましょう。<br>チャットボットが最初に発言する言葉を「初期発言」で設定できます。',
    },
  ],
}
const botEdit = {
  autoStart: true,
  name: 'botEdit',
  isIgnoreTransition: (prevPathname, pathname) => {
    const ignoreTransitionLastPaths = [
      undefined,
      '/topics',
      '/faqs',
      '/integrations',
      '/entities',
      '/tasks',
      '/pnp_topics',
    ]
    const result = prevPathname.match(/^\/bots\/(\d+)(\/\w+)?$/)
    if (!result) return false
    if (!lodash.includes(ignoreTransitionLastPaths, result[2])) return false

    return new RegExp(`^/bots/${result[1]}(/\\w+)?$`).test(pathname)
  },
  steps: [
    {
      position: 'bottom',
      intro: 'この画面では、チャットボットの編集、削除を行うことができます。',
      onEnter: () => {
        // Close simulator tab if opened.
        if (document.querySelector('.sidebar-container').classList.contains('show')) {
          document.querySelector('.sidebar-container li').click()
        }
        if (document.querySelector('.dm-bot-resources .rc-tabs-tab-active>div').id !== 'topics') {
          document.querySelector('.dm-bot-resources .rc-tabs-tab>div#topics').click()
        }
      },
    },
    {
      element: 'div[data-tab=topics].rc-tabs-tabpane .table',
      position: 'bottom',
      intro:
        'チャットボットの要となるのが「シナリオ」です。<br>' +
        '「シナリオ」とは、ユーザーの発言に対してチャットボットにどのような応答を行わせたいかを記述したものです。<br>' +
        'チャットボットに対応させたい目的毎にシナリオを作成することで、ユーザーの発言に対して様々な応答を返すことができるようになります。',
      onEnter: () => {
        // Close simulator tab if opened.
        if (document.querySelector('.sidebar-container').classList.contains('show')) {
          document.querySelector('.sidebar-container li').click()
        }
        if (document.querySelector('.dm-bot-resources .rc-tabs-tab-active>div').id !== 'topics') {
          document.querySelector('.dm-bot-resources .rc-tabs-tab>div#topics').click()
        }
      },
    },
    {
      element: '.rc-tabs-bar[role=tablist]',
      position: 'top',
      intro:
        'シナリオの他にも、チャットボットに様々なことを行わせるための機能が用意されています。<br>各機能については<a href="https://guide.dialogplay.jp/hc/ja/categories/360003488911" target="_blank" rel="noopener noreferrer">ドキュメント</a>をご覧ください。',
      onEnter: () => {
        // Close simulator tab if opened.
        if (document.querySelector('.sidebar-container').classList.contains('show')) {
          document.querySelector('.sidebar-container li').click()
        }
        if (document.querySelector('.dm-bot-resources .rc-tabs-tab-active>div').id !== 'topics') {
          document.querySelector('.dm-bot-resources .rc-tabs-tab>div#topics').click()
        }
      },
    },
    {
      element: '.trainer button',
      position: 'top',
      intro:
        'シナリオの作成が完了した後は「学習」を行うことで、チャットボットがユーザーの発言を適切なシナリオへ振り分けることができるようになります。<br>' +
        'シナリオ内の発言例を編集した場合は、その都度学習を行う必要があります。',
      onEnter: () => {
        // Close simulator tab if opened.
        if (document.querySelector('.sidebar-container').classList.contains('show')) {
          document.querySelector('.sidebar-container li').click()
        }
      },
    },
    {
      element: '.sidebar-container li',
      position: 'left',
      intro:
        'シナリオを作成し、学習が完了したチャットボットは、シミュレーターでその動作を確認することができます。',
      onEnter: () => {
        window.scrollTo(0, 0)
      },
    },
    {
      element: '.sidebar-container',
      position: 'left',
      intro:
        '「シナリオ判定」では、ユーザーが指定した発言をした時に、どのシナリオがユーザーに提案されるかを確認することができます。<br>' +
        '「チャット」では、実際にチャットボットに話しかけて、どのような返答が返ってくるかを確認することができます。',
      onEnter: () => {
        window.scrollTo(0, 0)
        // Open simulator tab if closed.
        if (!document.querySelector('.sidebar-container').classList.contains('show')) {
          document.querySelector('.sidebar-container li').click()
        }
      },
    },
    {
      element: 'div[data-tab=topics].rc-tabs-tabpane .btn-icon-plus',
      position: 'bottom',
      intro: '最初は、「シナリオを追加」をクリックして、シナリオを1つ作成してみましょう。',
      onEnter: () => {
        // Close simulator tab if opened.
        if (document.querySelector('.sidebar-container').classList.contains('show')) {
          document.querySelector('.sidebar-container li').click()
        }
        if (document.querySelector('.dm-bot-resources .rc-tabs-tab-active>div').id !== 'topics') {
          document.querySelector('.dm-bot-resources .rc-tabs-tab>div#topics').click()
        }
      },
    },
  ],
}
const botImport = {
  autoStart: true,
  name: 'botImport',
  steps: [
    {
      position: 'bottom',
      intro:
        'この画面では、テンプレートを利用してチャットボットを作成します。<br>テンプレートを利用することで、ある程度完成した状態から作り始めることができます。',
    },
    {
      element: '.import',
      position: 'left',
      intro: '「インポート」ボタンをクリックすると選択したテンプレートがチャットボット一覧に追加されます。',
    },
  ],
}
const topicNew = {
  autoStart: true,
  name: 'topicNew',
  steps: [
    {
      position: 'bottom',
      intro: 'この画面では、シナリオの新規作成を行うことができます。',
      onEnter: () => {
        if (document.querySelector('.sidebar-container').classList.contains('show')) {
          document.querySelector('.sidebar-container li').click()
        }
      },
    },
    {
      position: 'bottom',
      intro:
        'シナリオ名、ユーザーの発言例を入力し、シナリオの流れにアクションを追加してみましょう。<br>使用できるアクションについては、<a href="https://guide.dialogplay.jp/hc/ja/articles/360028781692#bot-action" target="_blank" rel="noopener noreferrer">ドキュメント</a>をご覧下さい。',
    },
    {
      element: '.sticky',
      position: 'bottom',
      intro: 'アクションの作成が完了したら、「保存」または「保存して学習」ボタンをクリックします。',
      onEnter: () => {
        if (document.querySelector('.sidebar-container').classList.contains('show')) {
          document.querySelector('.sidebar-container li').click()
        }
      },
    },
    {
      element: '.sidebar-container',
      position: 'left',
      intro:
        'シミュレーターでは、作成したチャットボットとすぐに会話ができ、シナリオの流れを確認することができます。<br>「シナリオ判定」では、ユーザーの発言に対しどのシナリオが適合するかを調べます。<br>「チャット」では、作成したチャットボットと実際に会話することができます。',
      onEnter: () => {
        if (!document.querySelector('.sidebar-container').classList.contains('show')) {
          document.querySelector('.sidebar-container li').click()
        }
      },
    },
    {
      element: '.dm-sidebar',
      position: 'right',
      intro:
        '次に、作成したチャットボットを公開しましょう。<br>サイドメニューの「新規公開」をクリックしてください。',
      onEnter: () => {
        if (document.querySelector('.sidebar-container').classList.contains('show')) {
          document.querySelector('.sidebar-container li').click()
        }
      },
    },
  ],
}
const topicEdit = {
  autoStart: false,
  name: 'topicEdit',
  steps: [
    {
      position: 'bottom',
      intro: 'この画面では、シナリオの編集、削除を行うことができます。',
      onEnter: () => {
        if (document.querySelector('.sidebar-container').classList.contains('show')) {
          document.querySelector('.sidebar-container li').click()
        }
      },
    },
    {
      position: 'bottom',
      intro:
        'シナリオ名、ユーザーの発言例を入力し、シナリオの流れを作成することで、シナリオを完成させましょう。<br>詳細については、<a href="https://guide.dialogplay.jp/hc/ja/articles/360028781692#bot-action" target="_blank" rel="noopener noreferrer">ドキュメント</a>に詳しい説明が記載されています。',
    },
    {
      element: '.sticky',
      position: 'top',
      intro: 'アクションの作成が完了したら、「保存」または「保存して学習」ボタンをクリックします。',
      onEnter: () => {
        if (document.querySelector('.sidebar-container').classList.contains('show')) {
          document.querySelector('.sidebar-container li').click()
        }
      },
    },
    {
      element: '.sidebar-container li',
      position: 'left',
      intro:
        'シナリオを作成し、学習が完了したチャットボットは、シミュレーターでその動作を確認することができます。',
      onEnter: () => {
        window.scrollTo(0, 0)
      },
    },
    {
      element: '.sidebar-container',
      position: 'left',
      intro:
        '「シナリオ判定」では、ユーザーが指定した発言をした時に、どのシナリオがユーザーに提案されるかを確認することができます。<br>' +
        '「チャット」では、実際にチャットボットに話しかけて、どのような返答が返ってくるかを確認することができます。',
      onEnter: () => {
        window.scrollTo(0, 0)
        // Open simulator tab if closed.
        if (!document.querySelector('.sidebar-container').classList.contains('show')) {
          document.querySelector('.sidebar-container li').click()
        }
      },
    },
    {
      element: '.dm-sidebar',
      position: 'right',
      intro:
        '編集したチャットボットを公開する場合は、サイドメニューの「新規公開」をクリックし、チャットボットの公開を行いましょう。',
      onEnter: () => {
        window.scrollTo(0, 0)
        if (document.querySelector('.sidebar-container').classList.contains('show')) {
          document.querySelector('.sidebar-container li').click()
        }
      },
    },
  ],
}
const domainImport = {
  autoStart: false,
  name: 'domainImport',
  steps: [
    {
      element: '.col-sm-9',
      position: 'bottom',
      intro:
        'この画面では、シナリオを記述したCSVファイルのインポートを行うことができます。<br>詳細については、<a href="https://guide.dialogplay.jp/hc/ja/articles/360029613531" target="_blank" rel="noopener noreferrer">ドキュメント</a>をご覧下さい。',
    },
  ],
}
const integrationEdit = {
  autoStart: false,
  name: 'integrationEdit',
  steps: [
    {
      position: 'bottom',
      intro: 'この画面では、システム連携の編集、削除、テストを行うことができます。',
    },
  ],
}
const integrationNew = {
  autoStart: false,
  name: 'integrationNew',
  steps: [
    {
      position: 'bottom',
      intro:
        'この画面では、システム連携の新規作成や連携テストを行うことができます。<br>シナリオ内の「外部システム連携アクション」で必要となる項目を設定し、「保存」することで利用できます。<br>また、シナリオ内の「ユーザー入力に対するAIアシスタント (Powered by OpenAI)アクション」で利用することも可能です。<br>詳細については、<a href="https://guide.dialogplay.jp/hc/ja/articles/360029511031" target="_blank" rel="noopener noreferrer">ドキュメント</a>をご覧下さい。',
    },
  ],
}
const entityShow = {
  autoStart: false,
  name: 'entityShow',
  steps: [
    {
      element: '.col-sm-9',
      position: 'bottom',
      intro: 'この画面では、ユーザー辞書の確認と編集を行うことができます。',
    },
  ],
}
const entityEdit = {
  autoStart: false,
  name: 'entityEdit',
  steps: [
    {
      element: '.col-sm-9',
      position: 'bottom',
      intro: 'この画面では、ユーザー辞書の編集と、削除を行うことができます。',
    },
  ],
}
const entityNew = {
  autoStart: false,
  name: 'entityNew',
  steps: [
    {
      element: '.col-sm-9',
      position: 'bottom',
      intro:
        'この画面では、ユーザー辞書の新規作成を行います。<br>CSVデータファイルをアップロードすることで辞書作成することができます。<br>詳細については、<a href="https://guide.dialogplay.jp/hc/ja/articles/360029249752" target="_blank" rel="noopener noreferrer">ドキュメント</a>をご覧下さい。',
    },
  ],
}
const userIndex = {
  autoStart: false,
  name: 'userIndex',
  steps: [
    {
      position: 'bottom',
      intro:
        'この画面では、ユーザーアカウントの管理を行うことができます。アカウント管理には管理者の権限が必要になります。',
    },
  ],
}
const userEdit = {
  autoStart: false,
  name: 'userEdit',
  steps: [
    {
      element: 'div.col-sm-9',
      position: 'bottom',
      intro: 'この画面では、ユーザー名と権限の編集を行うことができます。',
    },
    {
      element: 'div.form-group:nth-of-type(3)',
      position: 'bottom',
      intro:
        'ユーザーに付与された利用権限です。<br>詳細については、<a href="https://guide.dialogplay.jp/hc/ja/articles/360029291592" target="_blank" rel="noopener noreferrer">ドキュメント</a>をご覧下さい。',
    },
    {
      element: 'button[type="submit"]',
      position: 'bottom',
      intro: '「保存」ボタンをクリックすると変更内容を保存します。',
    },
  ],
}
const userNew = {
  autoStart: false,
  name: 'userNew',
  steps: [
    {
      position: 'bottom',
      intro: 'この画面では、ユーザーアカウントの新規作成を行うことができます。',
    },
    {
      element: 'div.form-group:nth-of-type(1)',
      position: 'bottom',
      intro: '追加するユーザーのメールアドレスを入力してください。',
    },
    {
      element: 'div.form-group:nth-of-type(2)',
      position: 'bottom',
      intro:
        'ユーザーの権限をリストより選択してください。<br>詳細については、<a href="https://guide.dialogplay.jp/hc/ja/articles/360029291592" target="_blank" rel="noopener noreferrer">ドキュメント</a>をご覧下さい。',
    },
    {
      element: 'button[type="submit"]',
      position: 'bottom',
      intro:
        '「招待メールを送信」ボタンをクリックすると、登録されたメールアドレスに招待メールが送信されます。',
    },
  ],
}

const accountIndex = {
  autoStart: false,
  name: 'accountIndex',
  steps: [
    {
      position: 'bottom',
      intro:
        'この画面では、会社名、タイムゾーン、DialogPlay管理画面へのアクセス制限の設定を行うことができます。<br>また、ご利用プランの申込やアップグレード、契約情報の変更もこちらの画面から行って下さい。<br>※接続許可IPアドレス機能は、Advanceプラン以外ではご利用頂けません。',
    },
  ],
}

const emailAddressIndex = {
  autoStart: false,
  name: 'emailAddressIndex',
  steps: [
    {
      position: 'bottom',
      intro:
        'この画面では、チャットボットからメールを送信する際のメールアドレスの追加・削除と認証済メールアドレスの管理を行うことができます。',
    },
  ],
}

const oauthClientIndex = {
  autoStart: false,
  name: 'oauthClientIndex',
  steps: [
    {
      position: 'bottom',
      intro: 'この画面では、Office 365やSalesforceなどの外部サービスと連携するための設定を行います。',
    },
  ],
}

const oauthClientEdit = {
  autoStart: false,
  name: 'oauthClientEdit',
  steps: [
    {
      position: 'bottom',
      intro:
        'この画面では、Office 365やSalesforceなどの外部サービスと連携するための設定の編集、削除を行うことができます。',
    },
  ],
}

const oauthClientNew = {
  autoStart: false,
  name: 'oauthClientNew',
  steps: [
    {
      position: 'bottom',
      intro: 'この画面では、Office 365やSalesforceなどの外部サービスと連携するための設定を作成できます。',
    },
  ],
}

const tours = {
  '/$': topPage,
  '/operator': operator,
  '/applications$': applicationIndex,
  '/bots$': botIndex,
  '/users$': userIndex,
  '/applications/(\\d+)$': applicationEdit,
  '/applications/new': applicationNew,
  '/applications/(\\d+)/logs': applicationLogs,
  '/bots/(\\d+)/import': domainImport,
  '/bots/(\\d+)$': botEdit,
  '/bots/(\\d+)/topics$': botEdit,
  '/bots/(\\d+)/faqs$': botEdit,
  '/bots/(\\d+)/integrations$': botEdit,
  '/bots/(\\d+)/entities$': botEdit,
  '/bots/(\\d+)/tasks$': botEdit,
  '/bots/(\\d+)/pnp_topics$': botEdit,
  '/bots/create': botCreate,
  '/bots/new': botNew,
  '/bots/import': botImport,
  '/topics/(\\d+)$': topicEdit,
  '/topics/new': topicNew,
  '/integrations/(\\d+)': integrationEdit,
  '/integrations/new': integrationNew,
  '/entities/(\\d+)/edit': entityEdit,
  '/entities/(\\d+)': entityShow,
  '/entities/new': entityNew,
  '/users/(\\d+)': userEdit,
  '/users/new': userNew,
  '/account$': accountIndex,
  '/email_addresses': emailAddressIndex,
  '/oauth_clients$': oauthClientIndex,
  '/oauth_clients/(\\d+)': oauthClientEdit,
  '/oauth_clients/new': oauthClientNew,
}

export const getTour = pathname => {
  for (let i = 0; i < Object.keys(tours).length; i++) {
    const pattern = Object.keys(tours)[i]
    if (pathname.match(pattern)) return tours[pattern]
  }
  return {}
}
