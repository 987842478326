import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'

import DataBindingTable from '../common/DataBindingTable'
import Tooltip from '../../components/common/Tooltip'

export class LearningExclusionWords extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    application: PropTypes.object,
    learningExclusionWords: PropTypes.array,
    isFetching: PropTypes.bool,
    tableState: PropTypes.object,
    updateTableState: PropTypes.func.isRequired,
    renderExclusionEdit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    onRefresh: PropTypes.func.isRequired,
    onClose: PropTypes.func,
  }

  static defaultProps = {
    learningExclusionWords: [],
  }

  constructor() {
    super()
    this.state = { errorMessage: '' }
  }

  renderErrorMessage = () => {
    if (this.state.errorMessage === '') return

    return <div className="dm-error-message pb-4">{this.state.errorMessage}</div>
  }

  handleDelete = id => {
    this.props.handleDelete(id).catch(e => {
      this.setState({ errorMessage: e.message })
    })
  }

  render = () => {
    const { t } = this.context
    const {
      isFetching,
      learningExclusionWords,
      onRefresh,
      renderExclusionEdit,
      tableState,
      updateTableState,
    } = this.props
    const columns = [
      {
        Header: t('analytics.learningExclusionWord.pattern'),
        id: 'pattern',
        accessor: 'pattern',
        Cell: props => (
          <Link
            className="learning-exclusion-word-item"
            onClick={() =>
              renderExclusionEdit({
                id: props.original.id,
                pattern: props.original.pattern,
              })
            }
            role="button"
          >
            {props.value}
          </Link>
        ),
        getFooterProps: () => ({ className: 'footer-all' }),
        Footer: (
          <div>
            <div className="pull-left">
              <Link
                className="dm-btn btn btn-primary btn-icon-plus mini"
                onClick={() => renderExclusionEdit({})}
              >
                <span>{t('common.new')}</span>
              </Link>
            </div>
          </div>
        ),
      },
      {
        Header: t('analytics.learningExclusionWord.delete'),
        id: 'delete',
        accessor: 'id',
        Cell: props => (
          <button
            type="button"
            className="dm-btn btn btn-danger btn-icon-delete mini"
            onClick={() => {
              this.handleDelete(props.original.id)
            }}
          />
        ),
        sortable: false,
        maxWidth: 80,
        className: 'text-center',
      },
    ]

    return (
      <div className="modal-background" onClick={this.props.onClose}>
        <div className="modal-dialog" role="document" onClick={e => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.onClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title">
                {t('analytics.learningExclusionWord.title')}
                {<Tooltip name="analytics.tooltip.learningExclusionWord.title" />}
              </h4>
            </div>
            <div className="modal-body modal-body-large">
              <div className="results">
                {this.renderErrorMessage()}
                <DataBindingTable
                  items={learningExclusionWords}
                  columns={columns}
                  isFetching={isFetching}
                  tableName="LearningExclusionWordList"
                  tableState={tableState}
                  updateTableState={updateTableState}
                  onRefresh={onRefresh}
                  showPageSizeOptions={false}
                  defaultSorted={[{ id: 'pattern', desc: false }]}
                />
              </div>
            </div>
            <div className="modal-footer" />
          </div>
        </div>
      </div>
    )
  }
}

export default LearningExclusionWords
