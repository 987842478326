import request from '../helpers/requestManagementAPI'

export const clearClassify = () => ({
  type: 'CLEAR_CLASSIFY',
})

const requestClassify = () => ({
  type: 'REQUEST_CLASSIFY',
})

const responseClassify = results => ({
  type: 'RESPONSE_CLASSIFY',
  results,
})

export const classifyTopics = (token, id, content) => dispatch => {
  dispatch(clearClassify())
  dispatch(requestClassify())
  return request
    .post(`/domains/${id}/classify`, {
      token,
      dispatch,
      body: { content },
    })
    .then(json => dispatch(responseClassify(json)))
}

export const classifyFaqItems = (token, id, content) => dispatch => {
  dispatch(clearClassify())
  dispatch(requestClassify())
  return request
    .post(`/faqs/${id}/classify`, {
      token,
      dispatch,
      body: { content },
    })
    .then(json => dispatch(responseClassify(json)))
}
