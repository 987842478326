import lodash from 'lodash'
import { isEmail } from 'validator'

import { freeEmailDomains } from './freeEmailDomains'
import { mobileEmailDomains } from './mobileEmailDomains'

export const unacceptableEmailDomains = () => {
  return freeEmailDomains.concat(mobileEmailDomains)
}

export const isUnacceptableEmailDomain = domain => {
  return lodash.includes(unacceptableEmailDomains(), domain)
}

export const unregisteredEmailDomains = (emailAddresses, registeredEmailDomains) => {
  const domainsOfConfirmedEmailAddress = lodash.uniq(
    lodash.map(lodash.filter(emailAddresses, 'is_confirmed'), emailAddress => {
      if (isEmail(emailAddress.email)) {
        return emailAddress.email.split('@').pop()
      }
    })
  )
  return lodash.compact(
    lodash.map(domainsOfConfirmedEmailAddress, domain => {
      if (
        !lodash.includes(lodash.map(registeredEmailDomains, 'email_domain'), domain) &&
        !isUnacceptableEmailDomain(domain)
      )
        return domain
    })
  )
}
