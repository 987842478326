import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { addNotice } from '../../actions/notice'
import { fetchBot, trainBot, abortBot } from '../../actions/bot'

import TrainNotice from './TrainNotice'

export class Trainer extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    bot: PropTypes.object,
    topics: PropTypes.array,
    isSubmitting: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const prevTraining = prevProps.bot && prevProps.bot.is_training
    const nextTraining = this.props.bot && this.props.bot.is_training
    if (!prevTraining && nextTraining) {
      //  Observe training status if training has been started
      this.observeTraining(this.props.bot.id)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  observeTraining = id => {
    const { t } = this.context
    const state = this.context.store.getState()
    const { dispatch } = this.props

    Promise.resolve()
      .then(() => dispatch(fetchBot(state.session.token, id, true)))
      .then(response => {
        const fetchedBot = response.entities.bots[response.result]
        if (fetchedBot.status === 'Active') {
          dispatch(addNotice('info', t('common.trainingFinishedMessage')))
        } else if (fetchedBot.is_training) {
          this.timer = setTimeout(() => this.observeTraining(id), 10000)
        }
      })
  }

  handleTrain = (data, dispatch) => {
    const state = this.context.store.getState()
    const { bot, handleSave } = this.props
    return handleSave(data, dispatch)
      .then(() => dispatch(trainBot(state.session.token, bot.id)))
      .then(() => this.observeTraining(bot.id))
  }

  handleAbort = (data, dispatch) => {
    const { t } = this.context
    const state = this.context.store.getState()
    const { bot } = this.props
    return Promise.resolve()
      .then(() => dispatch(abortBot(state.session.token, bot.id)))
      .then(() => dispatch(addNotice('warn', t('common.trainingAbortedMessage'))))
      .catch(() => {})
      .then(() => dispatch(fetchBot(state.session.token, bot.id)))
      .then(({ entities, result }) => {
        //  Keep observation for training status if not finished yet
        if (!entities.bots[result].is_training) {
          clearTimeout(this.timer)
        }
      })
  }

  render() {
    const { t } = this.context
    const { bot, topics, handleSubmit, isSubmitting } = this.props

    const buttonAppearances = {
      Enqueued: { abort: true, train: { disabled: true, caption: t('bot.training.inTraining') } },
      Error: { abort: false, train: { disabled: false, caption: t('bot.training.train') } },
      UnTrained: { abort: false, train: { disabled: false, caption: t('bot.training.train') } },
      NotFound: { abort: false, train: { disabled: false, caption: t('bot.training.train') } },
      Training: { abort: false, train: { disabled: true, caption: t('bot.training.inTraining') } },
      Active: { abort: false, train: { disabled: true, caption: t('bot.training.alreadyTrained') } },
    }

    if (!bot) return null

    let status = !bot.is_training && bot.dirty && bot.status === 'Active' ? 'UnTrained' : bot.status

    const appearance = buttonAppearances[status]
    if (!appearance) return null

    const components = []
    components.push(
      <button
        key="train"
        type="button"
        className="btn btn-primary dm-btn"
        onClick={handleSubmit(this.handleTrain)}
        disabled={isSubmitting || appearance.train.disabled}
      >
        {appearance.train.caption}
      </button>
    )
    if (appearance.abort) {
      components.push(
        <button
          key="abort"
          type="button"
          className="btn btn-danger dm-btn"
          onClick={handleSubmit(this.handleAbort)}
          disabled={isSubmitting}
        >
          {t('bot.training.abort')}
        </button>
      )
    }
    return (
      <span className="trainer">
        <TrainNotice bot={bot} topics={topics} />
        {components}
      </span>
    )
  }
}

export default connect()(Trainer)
