import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import i18n from '../../middleware/i18n'
import Config from '../../helpers/config'
import { convertLineBreak } from '../../helpers/lineBreakConverter'
import UrlPreview from './UrlPreview'

class AbstractMessage extends PureComponent {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    uuid: PropTypes.string,
    sender_uid: PropTypes.string.isRequired,
    sender_type: PropTypes.string.isRequired,
    type: PropTypes.string,
    content: PropTypes.object.isRequired,
    timestamp: PropTypes.object.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    iconUrl: PropTypes.string,
    fallbackIconUrl: PropTypes.string,
    mode: PropTypes.string.isRequired,
    timezone: PropTypes.string,
    unread: PropTypes.bool,
    isSimulator: PropTypes.bool,
    isLatest: PropTypes.bool.isRequired,
    handleSendMessage: PropTypes.func.isRequired,
  }

  parse = (text, options = {}) => {
    if (!text) return text
    let components = []

    const linkPattern = [
      String.raw`https?://[^)\s]+`,
      String.raw`notes://[^)\s]+`,
      String.raw`file://[^)\s]+`,
      String.raw`tel:[^)\s]+`,
      String.raw`mailto:[^)\s]+`,
    ].join('|')
    const markdownPattern = String.raw`(?:\[[^\]]+\]\((?:${linkPattern})\))`
    const linkOnlyPattern = String.raw`(?:(?:${linkPattern}))`

    let pattern
    if (options.enableMarkdown) {
      //  Match following texts repeatedly
      //    beforeText http://www.example.com/
      //    beforeText [title](http://www.example.com/)
      //  Also match plain text without URL
      //    otherText
      pattern = new RegExp(String.raw`([\s\S]*?)(${markdownPattern}|${linkOnlyPattern})|([\s\S]+)`, 'gim')
    } else {
      //  Match following texts repeatedly
      //    beforeText http://www.example.com/
      //  Also match plain text without URL
      //    otherText
      pattern = new RegExp(String.raw`([\s\S]*?)(${linkOnlyPattern})|([\s\S]+)`, 'gim')
    }

    let matched
    while ((matched = pattern.exec(text))) {
      const [beforeText, linkText, otherText] = matched.slice(1, 4)

      components = lodash.concat(
        components,
        this.replaceUrl(matched.index, beforeText, linkText, otherText, options)
      )
    }

    return components
  }

  replaceUrl = (index, beforeText, linkText, otherText, options = {}) => {
    let components = []
    if (beforeText) {
      components = lodash.concat(components, convertLineBreak(beforeText, index))
    }

    //  URL with/without title is rendered as image with <img> or link with <a>
    if (linkText) {
      const submatches = linkText.match(/^\[([^\]]+)\]\((.+)\)|(.+)$/)
      const title = submatches[1]
      const url = submatches[2] || submatches[3]
      const type = this.getLinkType(title, url)
      const oauthURL = Config.server + '/oauth/auth_request'
      const enableImagePreview = options.enableImagePreview && !url.startsWith(oauthURL) && type === 'plain'
      const enableAbbreviation = options.enableAbbreviation
      const enableSwitchChatBot = options.enableSwitchChatBot

      components.push(
        <UrlPreview
          enableImagePreview={!!enableImagePreview}
          enableAbbreviation={!!enableAbbreviation}
          key={index}
          title={title}
          url={url}
          enableSwitchChatBot={enableSwitchChatBot}
        />
      )
    }

    //  Other text is rendered as plain text
    if (otherText) {
      components = lodash.concat(components, convertLineBreak(otherText, index))
    }
    return components
  }

  getLinkType = (title, url) => {
    if (!url.startsWith(Config.redirectURL)) {
      return title ? 'markdown' : 'plain'
    }
    return new URL(url).searchParams.get('type')
  }

  ellipse = (text, limit) => {
    if (!text) return text
    if (text.length <= limit) return text

    return text.substring(0, limit - 1) + '…'
  }

  formatAbsoluteTimestamp = timestamp => {
    const { timezone } = this.props

    // Cannot use moment-timezone in embedded chat
    if (timestamp.tz && !lodash.isEmpty(timezone)) {
      return timestamp.clone().tz(timezone).format('MM/DD HH:mm')
    } else {
      return timestamp.format('MM/DD HH:mm')
    }
  }

  getMessageClasses = (classes = {}) => {
    const { sender_type, unread } = this.props
    return {
      client: sender_type === 'client',
      server: sender_type !== 'client',
      unread: unread,
      ...classes,
    }
  }

  sendResetMessage = async e => {
    const { content } = this.props
    e.preventDefault()

    await i18n.loadLanguages(content.language)
    this.props.handleSendMessage({
      text: this.context.t('triggers.reset', { lng: content.language }),
      skip_translation: true,
    })
  }

  sendUndoMessage = async e => {
    const { content } = this.props
    e.preventDefault()

    await i18n.loadLanguages(content.language)
    this.props.handleSendMessage({
      text: this.context.t('triggers.undo', { lng: content.language }),
      skip_translation: true,
    })
  }

  renderDefaultFooterButtons = type => {
    const { mode, content, isLatest } = this.props

    if (mode !== 'client') return
    if (!isLatest) return
    if (!content.reset_label && !content.undo_label) return

    return (
      <React.Fragment>
        <div className="footer-border" />
        <div className={`${type}-footer`}>
          <div className="footer-btns-wrapper">
            {content.reset_label && (
              <button className="dm-btn btn footer-btn" onClick={this.sendResetMessage}>
                {content.reset_label}
              </button>
            )}
            {content.undo_label && (
              <button className="dm-btn btn footer-btn" onClick={this.sendUndoMessage}>
                {content.undo_label}
              </button>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderTimestamp = timestamp => {
    const { mode, unread, sender_type } = this.props
    const formatTime = this.formatAbsoluteTimestamp(timestamp)
    const mySenderType = mode === 'client' ? 'client' : 'operator'
    return (
      <span className="message-footer">
        {unread && sender_type !== mySenderType && <small className="unread">●</small>}
        <small className="timestamp">{formatTime}</small>
      </span>
    )
  }

  render() {
    return null
  }
}

export default AbstractMessage
