import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

class IncrementableTable extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    withHeader: PropTypes.bool,
    withDelete: PropTypes.bool,
    withFooter: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    columns: PropTypes.array,
    maxRows: PropTypes.number,
    deletable: PropTypes.func,
  }

  static defaultProps = {
    withHeader: true,
    withDelete: true,
    withFooter: false,
    columns: [],
    deletable: _field => true,
  }

  needNewRow = () => {
    const { fields, maxRows } = this.props
    const needNewRow = fields.length === 0 || !lodash.isEmpty(fields.get(fields.length - 1))
    if (!needNewRow) return false
    if (maxRows && fields.length >= maxRows) return false

    return true
  }

  render() {
    const { fields, withHeader, withFooter } = this.props
    const length = fields.length + (this.needNewRow() ? 1 : 0)

    return (
      <table className="table table-bordered dm-table hover incrementable">
        {withHeader && <thead>{this.renderHeader()}</thead>}
        <tbody>
          {lodash.range(length).map(index => this.renderBodyRow(`${fields.name}[${index}]`, index))}
        </tbody>
        {withFooter && <tfoot>{this.renderFooter()}</tfoot>}
      </table>
    )
  }

  renderHeader = () => {
    const { t } = this.context
    const { columns, withDelete } = this.props
    const cells = columns.map((column, index) => {
      const cell = typeof column.Header === 'function' ? column.Header(index) : column.Header

      if (typeof cell === 'string') {
        return (
          <th key={index} className={column.HeaderClass}>
            {cell}
          </th>
        )
      }
      if (cell.type === 'th') {
        return cell
      }
      return <th key={index}>{cell}</th>
    })

    if (withDelete) {
      cells.push(
        <th key="delete" className="delete">
          {t('common.delete')}
        </th>
      )
    }
    return <tr>{cells}</tr>
  }

  renderBodyRow = (name, rowIndex) => {
    const { columns, fields, withDelete, deletable } = this.props
    const cells = columns.map((column, columnIndex) => {
      const cell = typeof column.Cell === 'function' ? column.Cell(name, rowIndex, columnIndex) : column.Cell
      if (typeof cell === 'string') {
        return <td key={columnIndex}>{cell}</td>
      } else if (cell.type === 'td') {
        return cell
      } else {
        return <td key={columnIndex}>{cell}</td>
      }
    })

    //  Append delete button
    if (withDelete) {
      const field = fields.get(rowIndex) || {}
      if ((!lodash.isEmpty(field) || rowIndex < fields.length - 1) && deletable(field)) {
        cells.push(
          <td key="delete" className="delete">
            <button
              type="button"
              className="dm-btn btn btn-danger btn-icon-delete"
              onClick={e => {
                e.preventDefault()
                fields.remove(rowIndex)
              }}
            />
          </td>
        )
      } else {
        cells.push(<td key="delete" className="delete" />)
      }
    }
    return <tr key={rowIndex}>{cells}</tr>
  }

  renderFooter = () => {
    const { fields, columns, withDelete } = this.props

    const rowIndex = fields.length + (this.needNewRow() ? 1 : 0)

    const cells = columns.map((column, columnIndex) => {
      const cell = typeof column.Footer === 'function' ? column.Footer(rowIndex, columnIndex) : column.Footer
      if (typeof cell === 'string') {
        return <td key={columnIndex}>{cell}</td>
      } else if (cell.type === 'td') {
        return cell
      } else {
        return <td key={columnIndex}>{cell}</td>
      }
    })

    if (withDelete) {
      cells.push(<td key="delete" className="delete" />)
    }

    return <tr>{cells}</tr>
  }
}

export default IncrementableTable
