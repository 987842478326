import request from '../helpers/requestManagementAPI'
import Schemas from '../middleware/schema'

export const fetchAccount = (token, filter = {}) => dispatch => {
  return request.get('/account/', {
    token,
    dispatch,
    body: filter,
    target: 'accounts',
    schema: Schemas.account,
  })
}

export const updateAccount = (token, account) => dispatch => {
  return request.put('/account/', {
    token,
    dispatch,
    body: account,
    target: 'accounts',
    schema: Schemas.account,
  })
}
