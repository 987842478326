import lodash from 'lodash'
import moment from 'moment'
import uuid from 'uuid'
import request from '../helpers/requestManagementAPI'

export const storeMessage = message => ({
  type: 'STORE_MESSAGE',
  message,
})

export const setCurrentRoom = room => ({
  type: 'SET_CURRENT_ROOM',
  room,
})

export const clearRoom = () => ({
  type: 'UNSET_CURRENT_ROOM',
})

const receiveRooms = rooms => ({
  type: 'RECEIVE_ROOMS',
  rooms,
})

export const receiveRoom = room => ({
  type: 'RECEIVE_ROOM',
  room,
})

const replaceRooms = rooms => ({
  type: 'REPLACE_ROOMS',
  rooms,
})

export const incrementUnreadMessage = room => ({
  type: 'INCREMENT_UNREAD_MESSAGE',
  room: room,
})

export const clearUnreadMessage = room => ({
  type: 'CLEAR_UNREAD_MESSAGE',
  room: room,
})

const receiveMessages = (room, messages) => ({
  type: 'RECEIVE_MESSAGES',
  channel_uuid: room.uuid,
  messages: messages.reverse(),
})

export const fetchRooms = (session, filter = {}) => dispatch => {
  return request
    .get('/rooms/', {
      token: session.token,
      body: filter,
    })
    .then(rooms => dispatch(receiveRooms(rooms)))
}

export const fetchRoom = (session, room_id) => dispatch => {
  return request
    .get(`/rooms/${room_id}`, {
      token: session.token,
    })
    .then(room => {
      dispatch(receiveRoom(room))
      return room
    })
}

export const fetchOperatorRooms = session => dispatch => {
  let fetchedRooms = []

  return request
    .get('/rooms/', {
      token: session.token,
      body: { operator_state: 'assigned' },
    })
    .then(rooms => {
      fetchedRooms = lodash.concat(fetchedRooms, rooms)
      request
        .get('/rooms/', {
          token: session.token,
          body: { operator_state: 'calling' },
        })
        .then(rooms => {
          fetchedRooms = lodash.concat(fetchedRooms, rooms)
          dispatch(replaceRooms(fetchedRooms))
        })
    })
}

export const fetchMessages = (session, room, since = null) => dispatch => {
  const query = since ? `?since=${since}` : ''
  return request
    .get(`/rooms/${room.id}/messages${query}`, {
      token: session.token,
    })
    .then(messages => dispatch(receiveMessages(room, messages)))
}

export const sendOperatorMessage = (session, room, sender_uid, type, text, image_file = null) => dispatch => {
  var message = {
    uuid: uuid.v4(),
    timestamp: moment(),
    channel_uuid: room.uuid,
    sender_uid: sender_uid,
    sender_type: 'operator',
    type: type,
    temporary: true,
  }

  let body
  if (type === 'image' && image_file) {
    message.content = { image_url: window.URL.createObjectURL(image_file) }
    dispatch(storeMessage(message))

    body = new FormData()
    body.append('uuid', message.uuid)
    body.append('type', message.type)
    body.append('content', '{}')
    body.append('image_file', image_file)
  } else {
    message.content = { text: text }
    dispatch(storeMessage(message))

    body = {
      uuid: message.uuid,
      type: message.type,
      content: message.content,
    }
  }

  return request.post(`/rooms/${room.id}/messages`, {
    token: session.token,
    dispatch: dispatch,
    body,
  })
}

export const clearTemporaryMessages = () => ({
  type: 'CLEAR_TEMPORARY_MESSAGES',
})

export const pickUp = (session, room_id) => dispatch => {
  return request
    .post(`/rooms/${room_id}/pick_up`, {
      token: session.token,
      dispatch: dispatch,
      target: 'operator',
    })
    .then(room => dispatch(receiveRoom(room)))
}

export const hungUp = (session, room_id) => dispatch => {
  dispatch(clearRoom())
  return request
    .post(`/rooms/${room_id}/hung_up`, {
      token: session.token,
      dispatch: dispatch,
      target: 'operator',
    })
    .then(room => dispatch(receiveRoom(room)))
}

export const updateUnreadStatus = () => ({
  type: 'UPDATE_UNREAD_STATUS',
})

export const setAutoScroll = isAutoScroll => ({
  type: 'SET_AUTO_SCROLL',
  isAutoScroll,
})
