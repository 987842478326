export const downloadBlob = (blob, filename) => {
  const linkElement = document.createElement('a')
  linkElement.download = filename
  linkElement.target = '_blank'
  linkElement.rel = 'noopener norefferer'

  if (window.navigator.msSaveBlob) {
    // for IE
    window.navigator.msSaveBlob(blob, filename)
  } else if (window.URL && window.URL.createObjectURL) {
    // for Firefox
    linkElement.href = window.URL.createObjectURL(blob)
    document.body.appendChild(linkElement)
    linkElement.click()
    document.body.removeChild(linkElement)
  } else if (window.webkitURL && window.webkitURL.createObject) {
    // for Chrome
    linkElement.href = window.webkitURL.createObjectURL(blob)
    linkElement.click()
  } else {
    // for Safari
    // TODO: notice not supported message
  }
}
