import React from 'react'
import { Field } from 'redux-form'
import { CheckboxField } from '../../components/common/fields/FormFields'
import LabelWithTooltip from '../common/LabelWithTooltip'

import AbstractAction from './AbstractAction'
import VariableField from '../common/fields/VariableField'
import CardTextField from '../common/fields/CardTextField'
import LabelField from '../common/fields/LabelField'
import ChooseButtonValueField from '../common/fields/ChooseButtonValueField'
import ListVariableField from '../common/fields/ListVariableField'
import { lengthWithoutVariable } from '../../helpers/validation'

export class ChooseListAction extends AbstractAction {
  getTitle() {
    return this.context.t('topic.actionList.choose_list.title')
  }
  getType() {
    return 'user'
  }

  static validateForLine = action => {
    if (lengthWithoutVariable(action.text) > 60) {
      return false
    }
    return true
  }

  static getVariables = (action, _props, _context) => {
    return [{ variable: action.title, text: action.title }]
  }

  renderBody() {
    const { t } = this.context
    const { name } = this.props
    return (
      <div className="choose">
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor="title"
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.choose_list.chooseTitle"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <VariableField name={`${name}.title`} className="form-control dm-form-control" />
          </div>
        </div>
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor="text"
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.choose_list.text"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <CardTextField name={`${name}.text`} className="form-control" />
          </div>
        </div>
        <label>{t('topic.actionList.choose_list.choices')}</label>
        <div className="ml-4 mt-2">
          <div className="row form-group">
            <LabelWithTooltip
              htmlFor="variable"
              className="control-label col-lg-3 col-md-3 col-sm-12"
              name="topic.actionList.choose_list.variable"
            />
            <div className="col-lg-9 col-md-9 col-sm-12 pl-0">
              <ListVariableField name={`${name}.variable`} className="form-control" />
            </div>
          </div>
          <div className="row form-group">
            <LabelWithTooltip
              htmlFor="label"
              className="control-label col-lg-3 col-md-3 col-sm-12"
              name="topic.actionList.choose_list.label"
            />
            <div className="col-lg-9 col-md-9 col-sm-12 pl-0">
              <LabelField name={`${name}.label`} className="form-control" />
            </div>
          </div>
          <div className="row form-group">
            <LabelWithTooltip
              htmlFor="value"
              className="control-label col-lg-3 col-md-3 col-sm-12"
              name="topic.actionList.choose_list.value"
            />
            <div className="col-lg-9 col-md-9 col-sm-12 pl-0">
              <ChooseButtonValueField name={`${name}.value`} className="form-control" nullable={true} />
            </div>
          </div>
          <div className="form-group form-inline mb-4">
            <Field
              name={`${name}.use_none_of_them`}
              id={`${name}.use_none_of_them`}
              type="checkbox"
              component={CheckboxField}
              className="form-control"
            />
            <LabelWithTooltip
              htmlFor={`${name}.use_none_of_them`}
              name="topic.actionList.choose_list.useNoneOfThem"
            />
          </div>
        </div>
        <div className="form-group form-inline">
          <Field
            name={`${name}.skip_known_memories`}
            id={`${name}.skip_known_memories`}
            type="checkbox"
            component={CheckboxField}
            className="form-control"
          />
          <LabelWithTooltip
            htmlFor={`${name}.skip_known_memories`}
            name="topic.actionList.choose.skipKnownMemories"
          />
        </div>
      </div>
    )
  }
}

export default ChooseListAction
