import { deleteCache } from './api'
import Schemas from '../middleware/schema'
import request from '../helpers/requestManagementAPI'

export const fetchFaqs = (token, filter = {}, ignoreFetching = false) => dispatch => {
  return request.get('/faqs/', {
    token,
    dispatch,
    body: filter,
    target: ignoreFetching ? null : 'faqs',
    schema: Schemas.faqs,
  })
}

export const fetchFaq = (token, id, ignoreFetching = false, errorHandler) => dispatch => {
  return request.get(`/faqs/${id}`, {
    token,
    dispatch,
    target: ignoreFetching ? null : 'faqs',
    schema: Schemas.faq,
    errorHandler,
  })
}

export const createFaq = (token, faq) => dispatch => {
  return request.post('/faqs/', {
    token,
    dispatch,
    body: faq,
    target: 'faqs',
    schema: Schemas.faq,
  })
}

export const updateFaq = (token, faq) => dispatch => {
  return request.put(`/faqs/${faq.id}`, {
    token,
    dispatch,
    body: faq,
    target: 'faqs',
    schema: Schemas.faq,
  })
}

export const deleteFaq = (token, id) => dispatch => {
  return request
    .delete(`/faqs/${id}`, {
      token,
      dispatch,
      target: 'faqs',
    })
    .then(() => dispatch(deleteCache('faqs', id)))
}

export const trainFaq = (token, faq_id) => dispatch => {
  setTimeout(() => {
    dispatch(fetchFaq(token, faq_id))
  }, 1500)
  return request.post(`/faqs/${faq_id}/train`, {
    token,
    dispatch,
    target: 'faqs',
  })
}

export const abortFaq = (token, faq_id) => dispatch => {
  return request.post(`/faqs/${faq_id}/abort`, {
    token,
    dispatch,
    target: 'faqs',
  })
}

export const importFaq = (token, body, faq_id) => dispatch => {
  const url = faq_id ? `/faqs/${faq_id}/import` : '/faqs/import'
  return request.post(url, {
    token,
    dispatch,
    body,
    target: 'faqs',
    schema: Schemas.faq,
  })
}

export const exportFaq = (token, faq_id) => dispatch => {
  return request.post(`/faqs/${faq_id}/export`, {
    token,
    dispatch,
  })
}
