import PropTypes from 'prop-types'
import React from 'react'
import MessageIcon from './MessageIcon'

import AbstractMessage from './AbstractMessage'

class TypingMessage extends AbstractMessage {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  render() {
    const { sender_type, iconUrl, fallbackIconUrl, isLoaded } = this.props

    return (
      <li className="server">
        <div className="body">
          <MessageIcon
            sender_type={sender_type}
            url={iconUrl}
            fallbackUrl={fallbackIconUrl}
            isLoaded={isLoaded}
          />
          <div className="balloon">
            <div className="content">
              <div>
                <span className="dot fadein-out1" />
                <span className="dot fadein-out2" />
                <span className="dot fadein-out3" />
              </div>
            </div>
          </div>
        </div>
      </li>
    )
  }
}

export default TypingMessage
