import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes } from 'redux-form'
import { isEmail } from 'validator'

import DataBindingTable from '../../components/common/DataBindingTable'
import { SelectField } from '../../components/common/fields/FormFields'
import { addNotice } from '../../actions/notice'
import {
  fetchEmailAddresses,
  addEmailAddress,
  deleteEmailAddress,
  resendConfirmationMail,
} from '../../actions/email_address'
import { fetchEmailDomains, addEmailDomain, deleteEmailDomain } from '../../actions/email_domain'
import { updateTableState } from '../../actions/table'
import { isUnacceptableEmailDomain, unregisteredEmailDomains } from '../../helpers/emailDomain'
import { isFetching } from '../../helpers/selector'

const validate = data => {
  const errors = {}
  if (data.email && !isEmail(data.email)) {
    errors.email = 'validate.invalidEmail'
  }
  return errors
}

export class EmailAddressIndex extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
    emailAddresses: PropTypes.array.isRequired,
    emailDomains: PropTypes.array.isRequired,
    isFetchingEmailAddress: PropTypes.bool,
    isFetchingEmailDomains: PropTypes.bool,
    tableStates: PropTypes.object,
    isInvalidEmailAddress: PropTypes.bool,
    isInvalidEmailDomain: PropTypes.bool,
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    dispatch(fetchEmailAddresses(state.session.token))
    dispatch(fetchEmailDomains(state.session.token))
  }

  handleEmailAddressRefreshClick = () => {
    const state = this.context.store.getState()
    this.props.dispatch(fetchEmailAddresses(state.session.token))
  }

  handleEmailDomainRefreshClick = () => {
    const state = this.context.store.getState()
    this.props.dispatch(fetchEmailDomains(state.session.token))
  }

  addEmailAddress = (data, dispatch) => {
    const { t } = this.context
    const session = this.context.store.getState().session

    const emailAddress = {
      email: data.email,
    }

    this.props.reset()
    return dispatch(addEmailAddress(session.token, emailAddress)).then(() =>
      dispatch(addNotice('info', t('account.emailAddresses.sendConfirmationMessage')))
    )
  }

  deleteEmailAddress = id => {
    const { t } = this.context
    const { dispatch } = this.props

    const emailAddress = lodash.filter(this.props.emailAddresses, { id: id })
    if (
      !window.confirm(
        t('common.deleteConfirmMessage', {
          type: t('account.emailAddresses.email'),
          name: emailAddress[0].email,
        })
      )
    )
      return

    const session = this.context.store.getState().session
    dispatch(deleteEmailAddress(session.token, id)).then(() =>
      dispatch(addNotice('info', t('common.deleteSuccessMessage')))
    )
  }

  resendConfirmationMail = id => {
    const { t } = this.context
    const session = this.context.store.getState().session
    const { dispatch } = this.props

    dispatch(resendConfirmationMail(session.token, id)).then(() =>
      dispatch(addNotice('info', t('account.emailAddresses.sendConfirmationMessage')))
    )
  }

  addEmailDomain = (data, dispatch) => {
    const session = this.context.store.getState().session

    const emailDomain = {
      email_domain: data.emailDomain,
    }

    this.props.reset()
    return dispatch(addEmailDomain(session.token, emailDomain))
  }

  deleteEmailDomain = id => {
    const { t } = this.context
    const { dispatch } = this.props
    const emailDomain = lodash.filter(this.props.emailDomains, { id: id })
    if (
      !window.confirm(
        t('common.deleteConfirmMessage', {
          type: t('account.emailDomains.domain'),
          name: emailDomain[0].email_domain,
        })
      )
    )
      return

    const session = this.context.store.getState().session
    dispatch(deleteEmailDomain(session.token, id)).then(() =>
      dispatch(addNotice('info', t('common.deleteSuccessMessage')))
    )
  }

  renderError = field => {
    const { t } = this.context
    const {
      meta: { error },
    } = field
    if (!error) return null

    return <div className="error">{t(error)}</div>
  }

  updateTableState = (path, tableName, tableState) => {
    const { dispatch } = this.props
    dispatch(updateTableState(path, tableName, tableState))
  }

  render() {
    const { t } = this.context
    const {
      handleSubmit,
      submitting,
      emailAddresses,
      emailDomains,
      isFetchingEmailAddress,
      isFetchingEmailDomains,
      isInvalidEmailAddress,
      isInvalidEmailDomain,
      tableStates,
    } = this.props
    const selectableDomains = unregisteredEmailDomains(emailAddresses, emailDomains)

    const emailColumns = [
      {
        Header: t('account.emailAddresses.email'),
        id: 'email',
        accessor: 'email',
      },
      {
        Header: t('account.emailAddresses.status'),
        id: 'is_confirmed',
        accessor: 'is_confirmed',
        Cell: props => (
          <div>
            <span>
              {props.value ? t('account.emailAddresses.confirmed') : t('account.emailAddresses.unconfirmed')}
            </span>
            {!props.value && (
              <button
                type="button"
                className="dm-btn btn btn-primary mini"
                onClick={() => {
                  this.resendConfirmationMail(props.original.id)
                }}
              >
                {t('account.emailAddresses.resend')}
              </button>
            )}
          </div>
        ),
      },
      {
        Header: t('account.emailAddresses.delete'),
        id: 'delete',
        accessor: 'id',
        Cell: props => (
          <button
            type="button"
            className="dm-btn btn btn-danger btn-icon-delete mini"
            onClick={() => {
              this.deleteEmailAddress(props.value)
            }}
          />
        ),
        sortable: false,
        style: { textAlign: 'center' },
        maxWidth: 70,
      },
    ]

    const emailRemarks = [
      t('account.emailAddresses.remarks.overview'),
      t('account.emailAddresses.remarks.flow'),
    ]

    const domainColumns = [
      {
        Header: t('account.emailDomains.domain'),
        id: 'email_domain',
        accessor: 'email_domain',
      },
      {
        Header: t('account.emailAddresses.delete'),
        id: 'delete',
        accessor: 'id',
        Cell: props => (
          <button
            type="button"
            className="dm-btn btn btn-danger btn-icon-delete mini"
            onClick={() => {
              this.deleteEmailDomain(props.value)
            }}
          />
        ),
        sortable: false,
        style: { textAlign: 'center' },
        maxWidth: 70,
      },
    ]

    const domainRemarks = [
      t('account.emailDomains.remarks.overview'),
      t('account.emailDomains.remarks.conditions'),
    ]

    return (
      <div className="dm-email">
        <DataBindingTable
          tableName="AddressList"
          title={t('account.emailAddresses.title')}
          items={emailAddresses}
          titleTooltip="account.tooltip.confirmedEmail"
          remarks={emailRemarks}
          columns={emailColumns}
          isFetching={isFetchingEmailAddress}
          tableState={tableStates['address']}
          updateTableState={this.updateTableState}
          onRefresh={this.handleEmailAddressRefreshClick}
        />
        <form onSubmit={handleSubmit(this.addEmailAddress)}>
          <div className="row">
            <div className="col-xs-3">
              <label htmlFor="name" className="dm-title-mini">
                {t('account.emailAddresses.addEmailAddress')}
              </label>
            </div>
            <div className="col-xs-4">
              <Field
                type="email"
                name="email"
                className="form-control dm-form-control"
                maxLength="255"
                component="input"
              />
            </div>
            <div className="col-xs-2">
              <button
                type="submit"
                className="btn btn-primary dm-btn"
                disabled={isInvalidEmailAddress || submitting}
              >
                {t('common.add')}
              </button>
            </div>
          </div>
          <Field name="email" component={this.renderError} />
        </form>
        <DataBindingTable
          tableName="DomainList"
          title={t('account.emailDomains.title')}
          titleTooltip="account.tooltip.notPermitted"
          items={emailDomains}
          remarks={domainRemarks}
          columns={domainColumns}
          isFetching={isFetchingEmailDomains}
          tableState={tableStates['domain']}
          updateTableState={this.updateTableState}
          onRefresh={this.handleEmailDomainRefreshClick}
        />
        <form onSubmit={handleSubmit(this.addEmailDomain)}>
          <div className="row">
            <div className="col-xs-3">
              <label htmlFor="name" className="dm-title-mini">
                {t('account.emailDomains.addEmailDomain')}
              </label>
            </div>
            <div className="col-xs-4">
              <Field
                name="emailDomain"
                className="form-control dm-form-control"
                items={selectableDomains}
                component={SelectField}
                empty="true"
                valueKey="value"
              />
            </div>
            <div className="col-xs-2">
              <button
                type="submit"
                className="btn btn-primary dm-btn"
                disabled={isInvalidEmailDomain || submitting}
              >
                {t('common.add')}
              </button>
            </div>
          </div>
          <Field name="emailDomain" component={this.renderError} />
        </form>
      </div>
    )
  }
}

const EmailAddressIndexForm = reduxForm({
  form: 'EmailAddressIndex',
  validate,
})(EmailAddressIndex)

const mapStateToProps = state => {
  const emailAddressIds = state.pagination.email_addresses.ids || []
  const emailDomainIds = state.pagination.email_domains.ids || []
  const emailAddresses = emailAddressIds.map(id => state.entities.email_addresses[id])
  const emailDomains = emailDomainIds.map(id => state.entities.email_domains[id])
  const isInvalidEmailAddress =
    !lodash.has(state.form.EmailAddressIndex, 'values.email') ||
    lodash.has(state.form.EmailAddressIndex, 'syncErrors.email')
  const isInvalidEmailDomain =
    !lodash.has(state.form.EmailAddressIndex, 'values.emailDomain') ||
    isUnacceptableEmailDomain(state.form.EmailAddressIndex.values.emailDomain)

  return {
    emailAddresses: emailAddresses,
    emailDomains: emailDomains,
    isFetchingEmailAddress: isFetching(state, 'email_addresses'),
    isFetchingEmailDomains: isFetching(state, 'email_domains'),
    tableStates: {
      address: state.table[`${window.location.pathname}#AddressList`],
      domain: state.table[`${window.location.pathname}#DomainList`],
    },
    isInvalidEmailAddress: isInvalidEmailAddress,
    isInvalidEmailDomain: isInvalidEmailDomain,
  }
}

export default connect(mapStateToProps)(EmailAddressIndexForm)
