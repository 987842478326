import lodash from 'lodash'

import { addNotice } from '../actions/notice'

/**
 * Notice republish applications.
 *
 */
export const checkApplications = (bot_id, applications, dispatch, context) => {
  const { t } = context
  const relatedApplications = lodash.filter(applications, {
    latest_bot: { original_id: parseInt(bot_id, 10) },
  })
  let application_names = []
  if (relatedApplications.length > 0) {
    relatedApplications.forEach(application => {
      application_names.push(application.name)
    })
    return dispatch(addNotice('info', t('bot.updateApplication', { name: application_names.join(', ') })))
  }
}
