import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field, FieldArray } from 'redux-form'
import { InputField } from '../../components/common/fields/FormFields'
import IncrementableTable from '../common/IncrementableTable'

export class FaqPatternTable extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  renderPatterns = ({ fields }) => {
    const columns = [
      {
        Cell: (name, _index) => (
          <Field
            name={name}
            component={InputField}
            type="text"
            className="form-control dm-form-control"
            maxLength="280"
          />
        ),
      },
    ]

    return (
      <div>
        <IncrementableTable withHeader={false} fields={fields} columns={columns} maxRows={20} />
      </div>
    )
  }

  render() {
    return <FieldArray name="patterns" component={this.renderPatterns} />
  }
}

export default FaqPatternTable
