import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Collapsible from 'react-collapsible'
import LabelWithTooltip from '../common/LabelWithTooltip'

export class ActionSubList extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    actions: PropTypes.array,
    integrations: PropTypes.array,
    topics: PropTypes.array,
    condition_groups: PropTypes.array,
    type: PropTypes.oneOf(['topic', 'task']).isRequired,
  }

  static defaultProps = {
    actions: [],
    type: 'topic',
  }

  discoverIndex = node => {
    if (!node) return undefined
    if (node.dataset.index) {
      return parseInt(node.dataset.index, 10)
    }
    return this.discoverIndex(node.parentElement)
  }

  getIcon(action) {
    const icons = {
      speak: 'basic-message',
      upload_image: 'basic-picture',
      integration: 'basic-link',
      operator: 'basic-headset',
      confirm: 'basic-signs',
      choose: 'basic-cards-diamonds',
      exit_topic: 'arrows-keyboard-delete',
      slot: 'basic-calendar',
      change_scenario: 'arrows-keyboard-right',
      choose_scenario: 'basic-sheet-multiple',
      rerun_scenario: 'arrows-clockwise',
      faq: 'basic-question',
      carousel: 'basic-postcard',
      item_list: 'basic-todo-txt',
      choose_list: 'basic-cards-hearts',
      carousel_list: 'basic-postcard-multiple',
      form: 'basic-todolist-pencil',
      register_task: 'basic-watch',
      abort_task: 'arrows-keyboard-delete',
      complete_task: 'arrows-move-bottom',
      variable: 'basic-calculator',
      log: 'basic-book-pencil',
    }
    return icons[action.type]
  }

  getSummary(action) {
    const { t } = this.context
    const { integrations, topics } = this.props

    if (action.type === 'speak') {
      if (action.content) return action.content
    }
    if (action.type === 'upload_image') {
      if (action.title) return action.title
    }
    if (action.type === 'integration') {
      const integration = lodash.filter(integrations, { id: parseInt(action.integration_id, 10) })[0]
      if (integration) return integration.name
    }
    if (action.type === 'confirm') {
      if (action.title) return action.title
    }
    if (action.type === 'choose') {
      if (action.title) return action.title
    }
    if (action.type === 'slot') {
      const slots = lodash.compact(action.slots).map(slot => slot.name)
      if (slots.length > 0) {
        return slots.join(', ')
      }
    }
    if (action.type === 'change_scenario') {
      const topic = lodash.filter(topics, { id: parseInt(action.target_topic_id, 10) })[0]
      if (topic) return topic.name
    }
    if (action.type === 'item_list') {
      if (action.text) return action.text
    }
    if (action.type === 'choose_list') {
      if (action.title) return action.title
    }
    if (action.type === 'carousel') {
      if (action.title) return action.title
    }
    if (action.type === 'carousel_list') {
      if (action.title) return action.title
    }
    if (action.type === 'form') {
      if (action.title) return action.title
    }

    return t(`topic.actionList.${action.type}.title`)
  }

  getConditionText(action) {
    const { t } = this.context
    const { condition_groups } = this.props
    const nodes = []

    if (action.use_condition_group) {
      if (!action.condition_group_id) return ''

      const item = lodash.filter(condition_groups, { id: parseInt(action.condition_group_id, 10) })
      if (item.length > 0) {
        nodes.push(t('condition_group.nodeTitle'))
        nodes.push(':')
        nodes.push(item[0].name || '')
      }
    } else {
      if (!action.condition_variable) return ''
      if (action.condition_type === 'between' && action.condition_value) {
        nodes.push(action.condition_value['from'] || '')
        nodes.push('<=')
        nodes.push(action.condition_variable || '')
        nodes.push('<=')
        nodes.push(action.condition_value['to'] || '')
      } else if (action.condition_type === 'include') {
        nodes.push(
          t('topic.conditions.include', {
            variable: action.condition_variable,
            value: action.condition_value || '',
          })
        )
      } else if (action.condition_type === 'not_include') {
        nodes.push(
          t('topic.conditions.notInclude', {
            variable: action.condition_variable,
            value: action.condition_value || '',
          })
        )
      } else if (action.condition_type === 'undefined') {
        nodes.push(t('topic.conditions.undefined', { variable: action.condition_variable }))
      } else {
        nodes.push(action.condition_variable)
        nodes.push(action.condition_type || '')
        nodes.push(action.condition_value || '')
      }
    }
    return nodes.join(' ')
  }

  render() {
    const { actions, type } = this.props

    const actionNodes = actions.map((action, index) => {
      const condition_text = this.getConditionText(action)
      return (
        <li key={index} className={condition_text ? 'with-condition' : ''}>
          <a href={`#action${index}`}>
            {index + 1}: <span className={`icon icon-${this.getIcon(action)}`} />
            {this.getSummary(action)}
            <br />
            <span className="condition">{condition_text}</span>
          </a>
        </li>
      )
    })

    return (
      <Collapsible
        trigger={<LabelWithTooltip name={`${type}.actions`} direction="left" />}
        transitionTime={300}
        triggerTagName="h4"
        open={true}
        overflowWhenOpen="visible"
      >
        <ul className="nav nav-pills nav-stacked flows">{actionNodes}</ul>
      </Collapsible>
    )
  }
}

export default ActionSubList
