import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import AbstractMessage from './AbstractMessage'
import MessageIcon from './MessageIcon'

class ConfirmMessage extends AbstractMessage {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    ...AbstractMessage.propTypes,
    showAction: PropTypes.oneOf(['show', 'readonly', 'hide']).isRequired,
    handleSendMessage: PropTypes.func,
  }

  sendMessage = e => {
    e.preventDefault()
    this.props.handleSendMessage({
      text: e.target.dataset.value,
      is_select: true,
      skip_translation: true,
    })
  }

  render() {
    const { t } = this.context
    const {
      mode,
      sender_type,
      content,
      timestamp,
      showAction,
      iconUrl,
      fallbackIconUrl,
      isLoaded,
      isSimulator,
    } = this.props

    const parseOptions = { enableMarkdown: true, enableSwitchChatBot: mode === 'client' && !isSimulator }
    let buttons
    if (showAction === 'show') {
      buttons = (
        <div key="options" className="btn-group btn-group-justified">
          <div className="btn-group">
            <button
              type="button"
              key="yes"
              className="btn"
              data-value={t('chatMessage.yes')}
              onClick={this.sendMessage}
            >
              {t('chatMessage.yes')}
            </button>
          </div>
          <div className="btn-group">
            <button
              type="button"
              key="no"
              className="btn"
              data-value={t('chatMessage.no')}
              onClick={this.sendMessage}
            >
              {t('chatMessage.no')}
            </button>
          </div>
        </div>
      )
    } else {
      buttons = (
        <div key="options" className="btn-group btn-group-justified">
          <span key="yes" className="btn disable">
            {t('chatMessage.yes')}
          </span>
          <span key="no" className="btn disable">
            {t('chatMessage.no')}
          </span>
        </div>
      )
    }

    return (
      <li className={classnames(this.getMessageClasses({ 'is-confirm': true }))}>
        <div className="body">
          {sender_type !== 'client' && (
            <MessageIcon
              sender_type={sender_type}
              url={iconUrl}
              fallbackUrl={fallbackIconUrl}
              isLoaded={isLoaded}
            />
          )}
          <div className="balloon">
            <div className="panel panel-default dm-panel confirm">
              <div className="panel-heading" title={content.title}>
                <small className="title">{content.title}</small>
              </div>
              {content.image_url && (
                <div className="panel-image">
                  <img src={content.image_url} alt="confirmation" />
                </div>
              )}
              <div className="panel-body">{this.parse(content.text, parseOptions)}</div>
              {showAction !== 'hide' && (
                <React.Fragment>
                  <div className="panel-border" />
                  {buttons}
                  {this.renderDefaultFooterButtons('panel')}
                </React.Fragment>
              )}
            </div>
            {this.renderTimestamp(timestamp)}
          </div>
        </div>
      </li>
    )
  }
}

export default ConfirmMessage
