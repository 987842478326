import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'

import LabelWithTooltip from '../../components/common/LabelWithTooltip'

class BotCreate extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  }

  constructor() {
    super()
    this.state = { creationMethod: 'import' }
  }

  handleChangeMethod = e => {
    this.setState({ creationMethod: e.target.value })
  }

  render() {
    const { t } = this.context
    const { creationMethod } = this.state
    const nextUrl = creationMethod === 'create' ? '/bots/new' : '/bots/import'
    return (
      <div>
        <form>
          <div className="form-group">
            <LabelWithTooltip className="dm-title" name="bot.selectMethod.title" />
            <div className="radio">
              <input
                id="import"
                name="creationMethod"
                type="radio"
                value="import"
                defaultChecked
                component="input"
                className="form-control"
                onChange={this.handleChangeMethod}
              />
              <label htmlFor="import">{t('bot.selectMethod.import')}</label>
            </div>
            <div className="radio">
              <input
                id="create"
                name="creationMethod"
                type="radio"
                value="create"
                component="input"
                className="form-control"
                onChange={this.handleChangeMethod}
              />
              <label htmlFor="create">{t('bot.selectMethod.create')}</label>
            </div>
          </div>
          <div className="text-right">
            <Link className="btn btn-primary dm-btn" to={nextUrl}>
              {t('bot.selectMethod.next')}
            </Link>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return state
}

export default connect(mapStateToProps)(BotCreate)
