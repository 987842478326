import lodash from 'lodash'
import moment from 'moment'
import objectFitImages from 'object-fit-images'
import thunk from 'redux-thunk'
import { createStore as reduxCreateStore, compose, applyMiddleware } from 'redux'
import reducer from './reducers'
import { persistPartialState } from './helpers/persistState'
import { removeAllNotices } from './actions/notice'

export const initialize = () => {
  //  Disable global onerror handler after load script
  window.removeEventListener('error', window.onScriptLoadError)

  // polyfill
  objectFitImages()

  moment.locale(navigator.language)
}

export const createStore = () => {
  const middleware = [thunk]

  const store = reduxCreateStore(
    reducer,
    compose(
      persistPartialState('session.token', { key: 'token' }),
      persistPartialState('chat', {
        filter: ['noticeMode', 'speaker', 'applicationToken'],
        key: 'chatPreferences',
      }),
      persistPartialState('table', { key: 'tables' }),
      persistPartialState('groups', { key: 'groups' }),
      applyMiddleware(...middleware)
    )
  )

  return store
}

//  Reset notices when transition
export const resetNoticesIfTransition = dispatch => (prevState, nextState) => {
  if (nextState.location.state && nextState.location.state.keepNotices) return

  const prevPathname = prevState.location.pathname
  const nextPathname = nextState.location.pathname

  // Don't remove notices when change tab for resource table in bot edit
  const ignorePatterns = [/\/bots\/\d+(\/\w+)?$/]
  if (!lodash.some(ignorePatterns, pattern => pattern.test(prevPathname) && pattern.test(nextPathname))) {
    dispatch(removeAllNotices())
    window.scrollTo(0, 0)
  }
}
