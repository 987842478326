// functon copied from node_modules/redux-form/es/structure/plain/deepEqual
import lodash from 'lodash'
import { getFormValues } from 'redux-form'

var customizer = function customizer(obj, other) {
  if (obj === other) return true
  if ((obj == null || obj === '' || obj === false) && (other == null || other === '' || other === false))
    return true

  if (obj && other && obj._error !== other._error) return false
  if (obj && other && obj._warning !== other._warning) return false
}

var deepEqual = function deepEqual(a, b) {
  return lodash.isEqualWith(a, b, customizer)
}

export const isDirtyForm = (form, ignoreFields = [], additionalFields = []) => {
  const isPristineFields = (fields, formValues, initialValues) => {
    return fields.every(field => deepEqual(lodash.at(formValues, field), lodash.at(initialValues, field)))
  }
  if (form.props.pristine) return false

  //  Check dirty status from registered fields only
  const state = form.context.store.getState()
  const formValues = getFormValues(form.props.form)(state) || {}
  const registeredFields = lodash.keys(state.form[form.props.form].registeredFields) || []
  const fields = lodash.difference(registeredFields, ignoreFields).concat(additionalFields)
  return !isPristineFields(fields, formValues, form.props.initialValues)
}

export const convertToString = value => {
  if (lodash.isNil(value)) return value
  return value.toString()
}

const cancelUrl = {
  setRouteLeaveHook: (form, ignoreFields = [], additionalFields = []) => {
    const { t, router } = form.context
    const { route } = form.props

    router.setRouteLeaveHook(route, nextLocation => {
      if (nextLocation.state && nextLocation.state.ignoreBlocking) return true
      if (nextLocation.pathname === '/error') return true

      if (!isDirtyForm(form, ignoreFields, additionalFields)) return true

      return window.confirm(t('common.confirmMessage'))
    })
  },
}
export default cancelUrl
