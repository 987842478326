import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { propTypes, reduxForm } from 'redux-form'

import { deleteCache } from '../../actions/api'
import { fetchPredictionFailureLogs } from '../../actions/application'
import { deleteLearningExclusionWord, fetchLearningExclusionWords } from '../../actions/bot'
import { addNotice } from '../../actions/notice'
import { updateTableState } from '../../actions/table'
import LearningExclusionWordsComponent from '../../components/analytics/LearningExclusionWords'
import { isFetching } from '../../helpers/selector'

export class LearningExclusionWords extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    renderExclusionEdit: PropTypes.func,
    handleExclusionsClose: PropTypes.func,
  }

  static defaultProps = {
    isFetching: false,
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch, application } = this.props
    const botId = application.latest_bot.original_id || application.latest_bot.id
    dispatch(fetchLearningExclusionWords(state.session.token, botId))
  }

  handleRefreshClick = () => {
    const state = this.context.store.getState()
    const { dispatch, application } = this.props
    const botId = application.latest_bot.original_id || application.latest_bot.id
    dispatch(fetchLearningExclusionWords(state.session.token, botId))
  }

  handleDelete = id => {
    const state = this.context.store.getState()
    const { t } = this.context
    const { dispatch, application, intentType, selectedFaqId } = this.props
    const botId = application.latest_bot.original_id || application.latest_bot.id

    return dispatch(deleteLearningExclusionWord(state.session.token, botId, id))
      .then(() => dispatch(addNotice('info', t('common.deleteSuccessMessage'))))
      .then(() => dispatch(deleteCache('prediction_failure_logs')))
      .then(() => {
        if (intentType === 'topic') {
          dispatch(fetchPredictionFailureLogs(state.session.token, application.id, 'topic'))
        } else if (intentType === 'faq' && selectedFaqId) {
          dispatch(fetchPredictionFailureLogs(state.session.token, application.id, 'faq_item', selectedFaqId))
        }
      })
  }

  updateTableState = (path, tableName, tableState) => {
    const { dispatch } = this.props
    dispatch(updateTableState(path, tableName, tableState))
  }

  render() {
    const {
      application,
      learningExclusionWords,
      isFetching,
      submitting,
      handleSubmit,
      tableState,
      renderExclusionEdit,
      handleExclusionsClose,
    } = this.props
    return (
      <LearningExclusionWordsComponent
        application={application}
        learningExclusionWords={learningExclusionWords}
        isFetching={isFetching}
        isSubmitting={submitting}
        onRefresh={this.handleRefreshClick}
        handleSubmit={handleSubmit}
        handleDelete={this.handleDelete}
        renderExclusionEdit={renderExclusionEdit}
        tableState={tableState}
        updateTableState={this.updateTableState}
        onClose={handleExclusionsClose}
      />
    )
  }
}

const LearningExclusionWordsForm = reduxForm({
  form: 'LearningExclusionWords',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(LearningExclusionWords)

export const mapStateToProps = (state, props) => {
  const ids = state.pagination.learning_exclusion_words.ids || []
  const learningExclusionWords = ids.map(id => state.entities.learning_exclusion_words[id])

  return {
    ...props,
    learningExclusionWords: learningExclusionWords,
    isFetching: isFetching(state, 'learning_exclusion_words'),
    tableState: state.table[[`${window.location.pathname}#LearningExclusionWordList`]],
  }
}

export default connect(mapStateToProps)(LearningExclusionWordsForm)
