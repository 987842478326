import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Collapsible from 'react-collapsible'
import { Field } from 'redux-form'
import { CheckboxField } from '../common/fields/FormFields'
import LabelWithTooltip from '../common/LabelWithTooltip'
import TextField from '../common/fields/TextField'
import ConfirmTextField from '../common/fields/ConfirmTextField'
import Tooltip from '../common/Tooltip'

export class FeedbackEdit extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    enabled: PropTypes.bool.isRequired,
    defaultOpened: PropTypes.bool.isRequired,
    isOptional: PropTypes.bool.isRequired,
    isRequiredReason: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    enabled: false,
    defaultOpened: false,
    isOptional: true,
    isRequiredReason: false,
  }

  render() {
    const { t } = this.context
    const { enabled, defaultOpened, isOptional, isRequiredReason } = this.props

    return (
      <div className="feedback">
        <Collapsible
          open={defaultOpened}
          transitionTime={300}
          overflowWhenOpen="visible"
          trigger={
            <span>
              {t('bot.feedback.title')}
              <Tooltip name="bot.tooltip.feedback.title" />
            </span>
          }
        >
          <div className="with-indent">
            <div className="form-group form-inline checkbox">
              <Field
                type="checkbox"
                name="use_feedback"
                className="form-control m-0"
                component={CheckboxField}
              />
              <LabelWithTooltip htmlFor="use_feedback" name="bot.feedback.enabled" />
            </div>
            {enabled && (
              <React.Fragment>
                <div>
                  <LabelWithTooltip
                    className="dm-subtitle form-group -dm-form-group"
                    name="bot.feedback.chatbotMessage"
                  />
                  <div className="form-group row dm-form-group menu-paragraph">
                    <div>
                      <LabelWithTooltip
                        htmlFor="feedback_question"
                        className="col-sm-3"
                        name="bot.feedback.question"
                      />
                    </div>
                    <div className="col-sm-5">
                      <ConfirmTextField
                        name="feedback_question"
                        className="form-control dm-form-control"
                        minRows={3}
                        placeholder={t('bot.feedback.defaultQuestion')}
                        ignoreValidation={(value, allValues) => !allValues.use_feedback}
                        nullable={true}
                      />
                    </div>
                  </div>
                  <div className="form-group row dm-form-group menu-paragraph">
                    <LabelWithTooltip
                      htmlFor="feedback_reply_yes"
                      className="col-sm-3"
                      name="bot.feedback.replyYes"
                    />
                    <div className="col-sm-5">
                      <TextField
                        name="feedback_reply_yes"
                        className="form-control dm-form-control"
                        minRows={3}
                        placeholder={t('bot.feedback.defaultReplyYes')}
                        ignoreValidation={(value, allValues) => !allValues.use_feedback}
                        nullable={true}
                      />
                    </div>
                  </div>
                  <div className="form-group row dm-form-group menu-paragraph">
                    <LabelWithTooltip
                      htmlFor="feedback_reply_no"
                      className="col-sm-3"
                      name="bot.feedback.replyNo"
                    />
                    <div className="col-sm-5">
                      <TextField
                        name="feedback_reply_no"
                        className="form-control dm-form-control"
                        minRows={3}
                        placeholder={t('bot.feedback.defaultReplyNo')}
                        ignoreValidation={(value, allValues) => !allValues.use_feedback}
                        nullable={true}
                        disabled={isRequiredReason}
                      />
                    </div>
                  </div>
                  <div className="row menu-paragraph">
                    <div className="form-group dm-form-group form-inline col-sm-12">
                      <Field
                        type="checkbox"
                        name="is_optional_feedback"
                        className="form-control m-0"
                        component={CheckboxField}
                      />
                      <LabelWithTooltip htmlFor="is_optional_feedback" name="bot.feedback.isOptional" />
                    </div>
                    <div className="form-group dm-form-group form-inline col-sm-12">
                      <Field
                        type="checkbox"
                        name="is_required_feedback_reason"
                        className="form-control m-0"
                        component={CheckboxField}
                        disabled={isOptional}
                      />
                      <LabelWithTooltip
                        htmlFor="is_required_feedback_reason"
                        name="bot.feedback.required"
                        direction="left"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <LabelWithTooltip className="dm-subtitle" name="bot.feedback.feedbackReason.title" />
                  <ul className="dm-note strong">
                    <li>{t('bot.feedback.feedbackReason.note')}</li>
                  </ul>
                </div>
              </React.Fragment>
            )}
          </div>
        </Collapsible>
      </div>
    )
  }
}

export default FeedbackEdit
