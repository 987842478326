import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes } from 'redux-form'
import { InputField } from '../common/fields/FormFields'
import LabelWithTooltip from '../common/LabelWithTooltip'
import Loader from '../common/Loader'
import { isFetching } from '../../helpers/selector'

const validate = (data, props) => {
  const errors = {}
  if (!data.new_name) {
    errors.new_name = 'validate.required'
  } else if (data.new_name.length > 100) {
    errors.new_name = { id: 'validate.exceededMaxLength', values: { length: 100 } }
  } else if (lodash.includes(props.notAllowedNames, data.new_name)) {
    errors.new_name = 'validate.nameDuplicated'
  }
  return errors
}

export class CopyDialog extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    ...propTypes,
    name: PropTypes.string.isRequired,
    notAllowedNames: PropTypes.array,
    onCopyDialogSubmit: PropTypes.func.isRequired,
    onCopyDialogClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    notAllowedNames: [],
  }

  constructor() {
    super()
    this.state = {
      errorMessage: undefined,
    }
  }

  componentDidMount() {
    const { name, notAllowedNames } = this.props

    const key = name.replace(/\(\d+\)$/, '')
    let count = 1
    while (lodash.includes(notAllowedNames, `${key}(${count})`)) count++

    this.props.change('new_name', `${key}(${count})`)
  }

  onCopyDialogSubmit = data => {
    return this.props.onCopyDialogSubmit(data).catch(e => {
      this.setState({ errorMessage: e.message })
    })
  }

  renderErrorMessage = () => {
    if (!this.state.errorMessage) return
    return <div className="dm-error-message pb-4">{this.state.errorMessage}</div>
  }

  render() {
    const { t } = this.context
    const { onCopyDialogClose, handleSubmit, isFetching } = this.props

    return (
      <div className="modal-background" onClick={onCopyDialogClose}>
        <div className="modal-dialog" role="document" onClick={e => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onCopyDialogClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title">{t('bot.copy.title')}</h4>
            </div>
            <Loader loaded={!isFetching} type="show">
              <form className="text-left" onSubmit={handleSubmit(this.onCopyDialogSubmit)}>
                <div className="modal-body">
                  <div className="results">
                    {this.renderErrorMessage()}
                    <div className="form-group dm-form-group" key="new_name">
                      <LabelWithTooltip htmlFor="new_name" name={'bot.copy.new_name'} />
                      <Field
                        type="text"
                        name="new_name"
                        className="form-control dm-form-control"
                        component={InputField}
                        maxLength="100"
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary dm-btn" disabled={isFetching}>
                    {t('common.copy')}
                  </button>
                  <button
                    type="button"
                    className="btn btn-alert dm-btn"
                    onClick={onCopyDialogClose}
                    disabled={isFetching}
                  >
                    {t('bot.copy.cancel')}
                  </button>
                </div>
              </form>
            </Loader>
          </div>
        </div>
      </div>
    )
  }
}

const CopyDialogForm = reduxForm({
  form: 'CopyDialog',
  validate,
})(CopyDialog)

export const mapStateToProps = state => {
  return {
    isFetching: isFetching(state),
  }
}

export default connect(mapStateToProps)(CopyDialogForm)
