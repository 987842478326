import { clearState, deleteCache } from './api'
import { createSession, updateSession, deleteSession } from './session'
import request from '../helpers/requestManagementAPI'
import Schemas from '../middleware/schema'

export const fetchUsers = (token, filter = {}) => dispatch => {
  return request.get('/users/', {
    token,
    dispatch,
    body: filter,
    target: 'users',
    schema: Schemas.users,
    preserve: false,
  })
}

export const fetchUser = (token, id) => dispatch => {
  return request.get(`/users/${id}`, {
    token,
    dispatch,
    target: 'users',
    schema: Schemas.user,
  })
}

export const selectAccount = (token, account_id) => dispatch => {
  return request
    .post('/users/select_account', {
      token,
      dispatch,
      body: {
        account_id: account_id,
      },
    })
    .then(json => {
      dispatch(clearState())
      dispatch(createSession(json.access_token))
    })
}

export const createUser = (token, user) => dispatch => {
  return request.post('/users/', {
    token,
    dispatch,
    body: user,
    target: 'users',
    schema: Schemas.user,
  })
}

const updateSessionIfSelfModified = (session, user, dispatch) => {
  if (session.id !== user.id) return
  return dispatch(updateSession({ email: user.email, name: user.name }))
}

const deleteSessionIfSelfModified = (session, id, dispatch) => {
  if (session.id !== id) return

  dispatch(deleteSession())
}

export const updateUser = (token, user, session) => dispatch => {
  return request
    .put(`/users/${user.id}`, {
      token,
      dispatch,
      body: user,
      target: 'users',
      schema: Schemas.user,
    })
    .then(() => updateSessionIfSelfModified(session, user, dispatch))
}

export const sendMail = (token, user_id) => dispatch => {
  return request.post(`/users/${user_id}/resend_confirmation`, {
    token,
    dispatch,
  })
}

export const cancelMail = (token, user_id) => dispatch => {
  return request.post(`/users/${user_id}/cancel_confirmation`, {
    token,
    dispatch,
    target: 'users',
    schema: Schemas.user,
  })
}

export const deleteUser = (token, id, session) => dispatch => {
  return request
    .delete(`/users/${id}`, {
      token,
      dispatch,
      target: 'users',
    })
    .then(() => dispatch(deleteCache('users', id)))
    .then(() => deleteSessionIfSelfModified(session, id, dispatch))
}

export const signUp = user => dispatch => {
  return request.post('/account/signup', {
    dispatch,
    body: user,
    //  Send tracking cookie over cross domain
    fetchOptions: { credentials: 'include' },
  })
}

export const isAlreadyConfirmedUser = confirmation_token => dispatch => {
  return request.get(`/users/confirm/${confirmation_token}`, {
    dispatch,
  })
}

export const confirmUser = (confirmation_token, password, name) => dispatch => {
  return request.post(`/users/confirm/${confirmation_token}`, {
    dispatch,
    body: {
      password,
      name,
    },
    target: 'users',
  })
}

export const changePassword = (token, current_password, new_password) => dispatch => {
  return request.post('/users/change_password', {
    token,
    dispatch,
    body: {
      current_password,
      new_password,
    },
  })
}

export const forgotPassword = email => dispatch => {
  return request.post('/users/forgot_password', {
    dispatch,
    body: {
      email,
    },
    target: 'users',
  })
}

export const resetPassword = (resetToken, password) => dispatch => {
  return request.post('/users/reset_password', {
    dispatch,
    body: {
      reset_token: resetToken,
      password,
    },
    target: 'users',
  })
}
