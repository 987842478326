import lodash from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, propTypes, formValueSelector, Field } from 'redux-form'

import { fetchFaqs } from '../../actions/faq'
import { fetchFaqItems } from '../../actions/faq_item'
import {
  downloadFaqUsage,
  downloadScenarioUsage,
  fetchFaqUsage,
  fetchScenarioUsage,
} from '../../actions/statistics'
import { updateTableState } from '../../actions/table'
import FaqUsageTable from '../../components/analytics/FaqUsageTable'
import ScenarioUsageTable from '../../components/analytics/ScenarioUsageTable'
import { SelectField } from '../../components/common/fields/FormFields'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import FaqUsageGraph from '../../components/graph/FaqUsageGraph'
import ScenarioUsageGraph from '../../components/graph/ScenarioUsageGraph'
import { isFetching } from '../../helpers/selector'

export class UsageGraph extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
    application: PropTypes.object.isRequired,
    topics: PropTypes.array.isRequired,
    faqs: PropTypes.array,
    faqItems: PropTypes.array,
    usageType: PropTypes.string,
    faqId: PropTypes.string,
    scenarioUsages: PropTypes.object,
    faqUsages: PropTypes.object,
    scenarioTableState: PropTypes.object,
    faqTableState: PropTypes.object,
    isScenarioFetching: PropTypes.bool,
    isFaqFetching: PropTypes.bool,
  }

  constructor() {
    super()
    this.state = {
      duration: {
        type: null,
        start: null,
        end: null,
      },
    }
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch, application } = this.props

    this.changeDuration('type', 'past30days')

    const botId = application.latest_bot.original_id || application.latest_bot.id

    dispatch(fetchFaqs(state.session.token, { bot_id: botId })).then(response => {
      return new Promise(resolve => {
        Promise.all(
          response.result.map(faq_id => {
            return Promise.resolve(dispatch(fetchFaqItems(state.session.token, faq_id)))
          })
        ).then(() => resolve(response.result))
      })
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.usageType !== this.props.usageType) {
      this.handleRefresh()
    }
  }

  changeDuration = (key, value) => {
    const duration = { ...this.state.duration, [key]: value }

    if (key === 'type') {
      const now = moment()
      if (value === 'past30days') {
        duration['start'] = now.clone().subtract(30, 'days').format('YYYY-MM-DD')
        duration['end'] = now.clone().subtract(1, 'days').format('YYYY-MM-DD')
      } else if (value === 'past7days') {
        duration['start'] = now.clone().subtract(7, 'days').format('YYYY-MM-DD')
        duration['end'] = now.clone().subtract(1, 'days').format('YYYY-MM-DD')
      } else if (value === 'previousMonth') {
        duration['start'] = now.clone().startOf('month').subtract(1, 'months').format('YYYY-MM-DD')
        duration['end'] = now.clone().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
      } else if (value === 'currentMonth') {
        duration['start'] = now.clone().startOf('month').subtract(0, 'months').format('YYYY-MM-DD')
        duration['end'] = now.clone().subtract(1, 'days').format('YYYY-MM-DD')
      } else if (value === 'previousWeek') {
        duration['start'] = now.clone().startOf('week').subtract(1, 'weeks').format('YYYY-MM-DD')
        duration['end'] = now.clone().endOf('week').subtract(1, 'weeks').format('YYYY-MM-DD')
      } else {
        duration['start'] = null
        duration['end'] = null
      }
    }

    this.setState({ duration: duration })

    if (duration.start && duration.end) {
      this.fetchUsageData(duration.start, duration.end)
    }
  }

  updateTableState = (path, tableName, tableState) => {
    const { dispatch } = this.props

    const fixedTableState = { ...tableState }

    const sorted = tableState.sorted
    if (sorted) {
      fixedTableState['sorted'] = [{ id: 'order', desc: true }, ...sorted]
    }

    dispatch(updateTableState('usageTable', tableName, fixedTableState))
  }

  handleRefresh = () => {
    const { duration } = this.state
    // Check for customPeriod. If either of fields is empty, ignore
    if (duration.start && duration.end) {
      this.fetchUsageData(duration.start, duration.end)
    }
  }

  downloadUsageData = () => {
    const state = this.context.store.getState()
    const {
      duration: { start, end },
    } = this.state
    const { t } = this.context
    const { dispatch, application, usageType } = this.props

    if (start && end) {
      if (!window.confirm(t('analytics.graph.usageGraph.downloadConfirmMessage'))) return

      if (usageType === 'scenario') {
        dispatch(downloadScenarioUsage(state.session.token, application.id, start, end)).then(blob => {
          this.generateCsvFile(blob)
        })
      } else if (usageType === 'faq') {
        const faqId = this.props.faqId !== 'all' ? this.props.faqId : null
        dispatch(downloadFaqUsage(state.session.token, application.id, start, end, faqId)).then(blob => {
          this.generateCsvFile(blob)
        })
      }
    }
  }

  generateCsvFile = blob => {
    const { faqs, faqId, usageType } = this.props

    let filename
    if (usageType === 'scenario') {
      filename = 'scenario_statistics.csv'
    } else if (usageType === 'faq') {
      if (faqId === 'all') {
        filename = 'faq_statistics.csv'
      } else {
        const faq = lodash.find(faqs, { id: parseInt(faqId, 10) })
        filename = `faq_${faq.name}_statistics.csv`
      }
    }

    const link = document.createElement('a')
    link.download = filename
    link.target = '_blank'
    link.rel = 'noopener noreferrer'

    if (window.navigator.msSaveBlob) {
      // for IE
      window.navigator.msSaveBlob(blob, filename)
    } else if (window.URL && window.URL.createObjectURL) {
      // for Firefox
      link.href = window.URL.createObjectURL(blob)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else if (window.webkitURL && window.webkitURL.createObjectURL) {
      // for Chrome
      link.href = window.webkitURL.createObjectURL(blob)
      link.click()
    }
  }

  fetchUsageData = (start, end) => {
    const state = this.context.store.getState()
    const { dispatch, application, usageType } = this.props

    if (usageType === 'scenario') {
      dispatch(fetchScenarioUsage(state.session.token, application.id, start, end))
    } else if (usageType === 'faq') {
      dispatch(fetchFaqUsage(state.session.token, application.id, start, end))
    }
  }

  renderFaqNamesSelectBox = () => {
    const { t } = this.context
    const { faqs } = this.props

    const selectItems = [{ id: 'all', name: t('analytics.graph.usageGraph.allFaqs') }, ...faqs]

    return (
      <div className="col-sm-6" key="faqIdSelectBox">
        <div className="form-group">
          <LabelWithTooltip
            className="dm-title-mini"
            htmlFor="faqId"
            name="analytics.graph.usageGraph.targetFaq"
          />
          <Field
            name="faqId"
            className="form-control dm-form-control"
            items={selectItems}
            valueKey="id"
            displayKey="name"
            component={SelectField}
          />
        </div>
      </div>
    )
  }

  renderSetDurationFields = () => {
    const { t } = this.context
    const { duration } = this.state

    const items = [
      { value: 'past30days', label: t('analytics.graph.usageGraph.periodPicker.past', { days: 30 }) },
      { value: 'past7days', label: t('analytics.graph.usageGraph.periodPicker.past', { days: 7 }) },
      { value: 'previousMonth', label: t('analytics.graph.usageGraph.periodPicker.previousMonth') },
      { value: 'currentMonth', label: t('analytics.graph.usageGraph.periodPicker.currentMonth') },
      { value: 'previousWeek', label: t('analytics.graph.usageGraph.periodPicker.previousWeek') },
      { value: 'customPeriod', label: t('analytics.graph.usageGraph.periodPicker.customPeriod') },
    ]

    return (
      <div className="form-group ml-3">
        <LabelWithTooltip
          className="dm-title-mini"
          htmlFor="periodType"
          name="analytics.graph.usageGraph.periodType"
        />
        <div className="period-picker">
          <div className="period-select">
            <Field
              name="periodType"
              className="form-control dm-form-control"
              items={items}
              displayKey="label"
              valueKey="value"
              component={SelectField}
              onChange={e => this.changeDuration('type', e.target.value)}
            />
          </div>
          <div className="period-custom-start">
            <label className="period-custom-label">
              {t('analytics.graph.usageGraph.periodPicker.start')}
            </label>
            {duration.type === 'customPeriod' ? (
              <input
                placeholder="YYYY/MM/DD"
                type="date"
                id="start"
                value={duration.start || ''}
                onChange={e => this.changeDuration('start', e.target.value)}
              />
            ) : (
              <label>{moment(duration.start).format(t('analytics.table.periodFormat'))}</label>
            )}
          </div>
          <div className="period-custom-end">
            <label className="period-custom-label">{t('analytics.graph.usageGraph.periodPicker.end')}</label>
            {duration.type === 'customPeriod' ? (
              <input
                placeholder="YYYY/MM/DD"
                type="date"
                id="end"
                value={duration.end || ''}
                onChange={e => this.changeDuration('end', e.target.value)}
              />
            ) : (
              <label>{moment(duration.end).format(t('analytics.table.periodFormat'))}</label>
            )}
          </div>
        </div>
      </div>
    )
  }

  renderGraphComponent = () => {
    const {
      topics,
      faqs,
      faqItems,
      usageType,
      faqId,
      scenarioUsages,
      faqUsages,
      isScenarioFetching,
      isFaqFetching,
    } = this.props

    if (usageType === 'scenario') {
      return (
        <ScenarioUsageGraph
          topics={topics}
          scenarioUsages={scenarioUsages}
          height={200}
          isFetching={isScenarioFetching}
        />
      )
    } else {
      return (
        <FaqUsageGraph
          faqs={faqs}
          faqItems={faqItems}
          faqId={faqId}
          faqUsages={faqUsages}
          height={200}
          isFetching={isFaqFetching}
        />
      )
    }
  }

  renderTable = () => {
    const {
      topics,
      faqs,
      faqItems,
      usageType,
      faqId,
      scenarioUsages,
      faqUsages,
      scenarioTableState,
      faqTableState,
      isScenarioFetching,
      isFaqFetching,
    } = this.props

    if (usageType === 'scenario') {
      return (
        <ScenarioUsageTable
          topics={topics}
          scenarioUsages={scenarioUsages}
          tableState={scenarioTableState}
          updateTableState={this.updateTableState}
          handleRefreshClick={this.handleRefresh}
          isFetching={isScenarioFetching}
        />
      )
    } else {
      return (
        <FaqUsageTable
          faqs={faqs}
          faqItems={faqItems}
          faqUsages={faqUsages}
          faqId={faqId}
          isFetching={isFaqFetching}
          tableState={faqTableState}
          updateTableState={this.updateTableState}
          handleRefreshClick={this.handleRefresh}
        />
      )
    }
  }

  render() {
    const { t } = this.context
    const { duration } = this.state
    const { usageType } = this.props

    const note = {
      past30days: t('analytics.graph.usageGraph.note.pastdays', { days: 30 }),
      past7days: t('analytics.graph.usageGraph.note.pastdays', { days: 7 }),
      previousMonth: t('analytics.graph.usageGraph.note.previousMonth'),
      currentMonth: t('analytics.graph.usageGraph.note.currentMonth'),
      previousWeek: t('analytics.graph.usageGraph.note.previousWeek'),
      customPeriod: t('analytics.graph.usageGraph.note.customPeriod'),
    }

    return (
      <div>
        <div className="form-group">
          <div className="col-md-12">
            <div className="form-group">
              <div className="col-sm-6">
                <div className="form-group">
                  <LabelWithTooltip
                    className="dm-title-mini"
                    htmlFor="usageType"
                    name="analytics.graph.usageGraph.usageType"
                  />
                  <Field
                    name="usageType"
                    className="form-control dm-form-control"
                    items={[
                      { label: t('analytics.graph.usageGraph.scenario'), value: 'scenario' },
                      { label: t('analytics.graph.usageGraph.faq'), value: 'faq' },
                    ]}
                    valueKey="value"
                    displayKey="label"
                    component={SelectField}
                  />
                </div>
              </div>
              {usageType === 'faq' && this.renderFaqNamesSelectBox()}
            </div>
          </div>
          <div className="col-md-12">
            {this.renderSetDurationFields()}
            <div className="ml-3 mb-3">
              <button type="button" className="btn dm-btn btn-primary" onClick={this.downloadUsageData}>
                {t('common.download')}
              </button>
            </div>
            <hr />
            <LabelWithTooltip
              className="dm-title-mini graph-title"
              name={`analytics.graph.usageGraph.${usageType}Usage.graph.title`}
            />
            <div className="ml-3 mb-3">
              {this.renderGraphComponent()}
              <div className="dm-note">{note[duration.type]}</div>
            </div>
            {this.renderTable()}
          </div>
        </div>
      </div>
    )
  }
}

const UsageGraphForm = reduxForm({
  form: 'UsageGraph',
})(UsageGraph)

const selector = formValueSelector('UsageGraph')

export const mapStateToProps = (state, props) => {
  const application = props.application
  const botId = application ? application.latest_bot.original_id || application.latest_bot.id : undefined

  const faqs = botId ? lodash.filter(state.entities.faqs, { bot_id: botId }) : []
  const faqItems = lodash.filter(state.entities.faq_items, item => lodash.some(faqs, ['id', item.faq_id]))

  const usageType = selector(state, 'usageType') || 'scenario'
  const faqId = selector(state, 'faqId') || 'all'

  const scenarioUsages = lodash.find(state.statistics.scenarioUsages.applications, {
    id: props.application.id,
  })

  const faqUsages = lodash.find(state.statistics.faqUsages.applications, {
    id: props.application.id,
  })

  const scenarioTableState = state.table['usageTable#scenarioTable'] || {}
  let scenarioSorted = scenarioTableState.sorted
  if (!lodash.isEmpty(scenarioSorted) && !lodash.some(scenarioSorted, { id: 'order' })) {
    scenarioSorted = lodash.concat({ id: 'order', desc: true }, scenarioTableState.sorted)
  }

  const faqTableState = state.table['usageTable#faqTable'] || {}
  let faqSorted = faqTableState.sorted
  if (!lodash.isEmpty(faqSorted) && !lodash.some(faqSorted, { id: 'order' })) {
    faqSorted = lodash.concat({ id: 'order', desc: true }, faqTableState.sorted)
  }

  return {
    ...props,
    faqs,
    faqItems,
    usageType,
    faqId,
    scenarioUsages,
    faqUsages,
    scenarioTableState: { ...scenarioTableState, sorted: scenarioSorted },
    faqTableState: { ...faqTableState, sorted: faqSorted },
    isScenarioFetching: isFetching(state, 'topics') || state.statistics.isFetching,
    isFaqFetching: isFetching(state, ['faqs', 'faq_items']) || state.statistics.isFetching,
  }
}

export default connect(mapStateToProps)(UsageGraphForm)
