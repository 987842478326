export const timezone = t => {
  const components = [
    { id: 'Etc/GMT+12', name: t('timezone.Etc/GMT+12') },
    { id: 'Etc/GMT+11', name: t('timezone.Etc/GMT+11') },
    { id: 'Pacific/Honolulu', name: t('timezone.Pacific/Honolulu') },
    { id: 'America/Anchorage', name: t('timezone.America/Anchorage') },
    { id: 'America/Santa_Isabel', name: t('timezone.America/Santa_Isabel') },
    { id: 'America/Los_Angeles', name: t('timezone.America/Los_Angeles') },
    { id: 'America/Chihuahua', name: t('timezone.America/Chihuahua') },
    { id: 'America/Phoenix', name: t('timezone.America/Phoenix') },
    { id: 'America/Denver', name: t('timezone.America/Denver') },
    { id: 'America/Guatemala', name: t('timezone.America/Guatemala') },
    { id: 'America/Chicago', name: t('timezone.America/Chicago') },
    { id: 'America/Regina', name: t('timezone.America/Regina') },
    { id: 'America/Mexico_City', name: t('timezone.America/Mexico_City') },
    { id: 'America/Bogota', name: t('timezone.America/Bogota') },
    { id: 'America/Indiana/Indianapolis', name: t('timezone.America/Indiana/Indianapolis') },
    { id: 'America/New_York', name: t('timezone.America/New_York') },
    { id: 'America/Caracas', name: t('timezone.America/Caracas') },
    { id: 'America/Halifax', name: t('timezone.America/Halifax') },
    { id: 'America/Asuncion', name: t('timezone.America/Asuncion') },
    { id: 'America/La_Paz', name: t('timezone.America/La_Paz') },
    { id: 'America/Cuiaba', name: t('timezone.America/Cuiaba') },
    { id: 'America/Santiago', name: t('timezone.America/Santiago') },
    { id: 'America/St_Johns', name: t('timezone.America/St_Johns') },
    { id: 'America/Sao_Paulo', name: t('timezone.America/Sao_Paulo') },
    { id: 'America/Godthab', name: t('timezone.America/Godthab') },
    { id: 'America/Cayenne', name: t('timezone.America/Cayenne') },
    { id: 'America/Argentina/Buenos_Aires', name: t('timezone.America/Argentina/Buenos_Aires') },
    { id: 'America/Montevideo', name: t('timezone.America/Montevideo') },
    { id: 'Etc/GMT+2', name: t('timezone.Etc/GMT+2') },
    { id: 'Atlantic/Cape_Verde', name: t('timezone.Atlantic/Cape_Verde') },
    { id: 'Atlantic/Azores', name: t('timezone.Atlantic/Azores') },
    { id: 'Africa/Casablanca', name: t('timezone.Africa/Casablanca') },
    { id: 'Atlantic/Reykjavik', name: t('timezone.Atlantic/Reykjavik') },
    { id: 'Europe/London', name: t('timezone.Europe/London') },
    { id: 'Etc/GMT', name: t('timezone.Etc/GMT') },
    { id: 'Europe/Berlin', name: t('timezone.Europe/Berlin') },
    { id: 'Europe/Paris', name: t('timezone.Europe/Paris') },
    { id: 'Africa/Lagos', name: t('timezone.Africa/Lagos') },
    { id: 'Europe/Budapest', name: t('timezone.Europe/Budapest') },
    { id: 'Europe/Warsaw', name: t('timezone.Europe/Warsaw') },
    { id: 'Africa/Windhoek', name: t('timezone.Africa/Windhoek') },
    { id: 'Europe/Istanbul', name: t('timezone.Europe/Istanbul') },
    { id: 'Europe/Kiev', name: t('timezone.Europe/Kiev') },
    { id: 'Africa/Cairo', name: t('timezone.Africa/Cairo') },
    { id: 'Asia/Damascus', name: t('timezone.Asia/Damascus') },
    { id: 'Asia/Amman', name: t('timezone.Asia/Amman') },
    { id: 'Africa/Johannesburg', name: t('timezone.Africa/Johannesburg') },
    { id: 'Asia/Jerusalem', name: t('timezone.Asia/Jerusalem') },
    { id: 'Asia/Beirut', name: t('timezone.Asia/Beirut') },
    { id: 'Asia/Baghdad', name: t('timezone.Asia/Baghdad') },
    { id: 'Europe/Minsk', name: t('timezone.Europe/Minsk') },
    { id: 'Asia/Riyadh', name: t('timezone.Asia/Riyadh') },
    { id: 'Africa/Nairobi', name: t('timezone.Africa/Nairobi') },
    { id: 'Asia/Tehran', name: t('timezone.Asia/Tehran') },
    { id: 'Europe/Moscow', name: t('timezone.Europe/Moscow') },
    { id: 'Asia/Tbilisi', name: t('timezone.Asia/Tbilisi') },
    { id: 'Asia/Yerevan', name: t('timezone.Asia/Yerevan') },
    { id: 'Asia/Dubai', name: t('timezone.Asia/Dubai') },
    { id: 'Asia/Baku', name: t('timezone.Asia/Baku') },
    { id: 'Indian/Mauritius', name: t('timezone.Indian/Mauritius') },
    { id: 'Asia/Kabul', name: t('timezone.Asia/Kabul') },
    { id: 'Asia/Tashkent', name: t('timezone.Asia/Tashkent') },
    { id: 'Asia/Karachi', name: t('timezone.Asia/Karachi') },
    { id: 'Asia/Colombo', name: t('timezone.Asia/Colombo') },
    { id: 'Asia/Kolkata', name: t('timezone.Asia/Kolkata') },
    { id: 'Asia/Kathmandu', name: t('timezone.Asia/Kathmandu') },
    { id: 'Asia/Almaty', name: t('timezone.Asia/Almaty') },
    { id: 'Asia/Dhaka', name: t('timezone.Asia/Dhaka') },
    { id: 'Asia/Yekaterinburg', name: t('timezone.Asia/Yekaterinburg') },
    { id: 'Asia/Rangoon', name: t('timezone.Asia/Rangoon') },
    { id: 'Asia/Bangkok', name: t('timezone.Asia/Bangkok') },
    { id: 'Asia/Novosibirsk', name: t('timezone.Asia/Novosibirsk') },
    { id: 'Asia/Krasnoyarsk', name: t('timezone.Asia/Krasnoyarsk') },
    { id: 'Asia/Ulaanbaatar', name: t('timezone.Asia/Ulaanbaatar') },
    { id: 'Asia/Shanghai', name: t('timezone.Asia/Shanghai') },
    { id: 'Australia/Perth', name: t('timezone.Australia/Perth') },
    { id: 'Asia/Singapore', name: t('timezone.Asia/Singapore') },
    { id: 'Asia/Taipei', name: t('timezone.Asia/Taipei') },
    { id: 'Asia/Irkutsk', name: t('timezone.Asia/Irkutsk') },
    { id: 'Asia/Seoul', name: t('timezone.Asia/Seoul') },
    { id: 'Asia/Tokyo', name: t('timezone.Asia/Tokyo') },
    { id: 'Australia/Darwin', name: t('timezone.Australia/Darwin') },
    { id: 'Australia/Adelaide', name: t('timezone.Australia/Adelaide') },
    { id: 'Australia/Hobart', name: t('timezone.Australia/Hobart') },
    { id: 'Asia/Yakutsk', name: t('timezone.Asia/Yakutsk') },
    { id: 'Australia/Brisbane', name: t('timezone.Australia/Brisbane') },
    { id: 'Pacific/Port_Moresby', name: t('timezone.Pacific/Port_Moresby') },
    { id: 'Australia/Sydney', name: t('timezone.Australia/Sydney') },
    { id: 'Asia/Vladivostok', name: t('timezone.Asia/Vladivostok') },
    { id: 'Pacific/Guadalcanal', name: t('timezone.Pacific/Guadalcanal') },
    { id: 'Etc/GMT-12', name: t('timezone.Etc/GMT-12') },
    { id: 'Pacific/Fiji', name: t('timezone.Pacific/Fiji') },
    { id: 'Asia/Magadan', name: t('timezone.Asia/Magadan') },
    { id: 'Pacific/Auckland', name: t('timezone.Pacific/Auckland') },
    { id: 'Pacific/Tongatapu', name: t('timezone.Pacific/Tongatapu') },
    { id: 'Pacific/Apia', name: t('timezone.Pacific/Apia') },
  ]
  return components
}
