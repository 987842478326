import { deleteCache } from './api'
import request from '../helpers/requestManagementAPI'
import Schemas from '../middleware/schema'

export const fetchAvailablePlans = (token, filter = {}) => dispatch => {
  dispatch(deleteCache('plans'))
  return request.get('/plans/', {
    token,
    dispatch,
    body: filter,
    target: 'plans',
    schema: Schemas.plans,
  })
}

export const upgradePlan = (token, plan) => dispatch => {
  return request.post('/account/plan', {
    token,
    dispatch,
    body: { plan },
    target: 'accounts',
    schema: Schemas.account,
  })
}
