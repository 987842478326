import queryString from 'query-string'

import request from '../helpers/requestVoiceSynthAPI'

export const fetchVoiceSynthFile = (text, speaker_name) => dispatch => {
  const query = queryString.stringify({ text, speaker_name })

  return request.get(`/voice/${query ? '?' + query : ''}`, {
    dispatch: dispatch,
  })
}
