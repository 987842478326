import lodash from 'lodash'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import { fetchRooms, receiveRoom, incrementUnreadMessage } from '../../actions/chat'
import Config from '../../helpers/config'
import WebSocketDevice from '../../helpers/awsIoT'

class CallingWatcher extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    accountUUID: PropTypes.string.isRequired,
  }

  componentWillMount() {
    this.initNotifier()
    this.initWatcher()
  }

  componentDidMount() {
    const { store } = this.context
    const { dispatch } = this.props
    const state = store.getState()

    this.prevCallingRooms = lodash.filter(state.chat.rooms, { operator_state: 'calling' })
    dispatch(fetchRooms(state.session, { operator_state: 'assigned' }))
  }

  initNotifier = () => {
    if (!('Notification' in window)) return
    if (Notification.permission === 'default') {
      Notification.requestPermission()
    }
  }

  initWatcher = () => {
    const handlers = {
      connect: this.connect,
      message: this.message,
      error: this.error,
      offline: this.offline,
    }
    this.websocket = new WebSocketDevice('ap-northeast-1', this)
    this.websocket.connect(Config.IdentityPoolId, handlers).catch(() => {
      this.startPolling()
    })
  }

  connect = () => {
    const { accountUUID } = this.props
    if (this.websocket.device) {
      //  Stop polling and use websocket event when connect websocket
      clearTimeout(this.pollingRoomsTimer)
      this.pollingRoomsTimer = null

      this.websocket.device.subscribe(`operator:${accountUUID}`, { qos: 1 })
    }
  }

  offline = () => {
    this.startPolling()
  }

  message = (topic, payload) => {
    const parsedPayload = JSON.parse(payload)

    const handlers = {
      none: this.updateOperatorStatus,
      calling: this.updateOperatorStatus,
      assigned: this.updateOperatorStatus,
      text: this.receiveMessage,
      image: this.receiveMessage,
    }
    const type = parsedPayload.type || parsedPayload.operator_state
    const handler = handlers[type] || (() => {})

    //  Prefer tab which already opened the operator page when click notification
    if (this.context.router.isActive('/operator')) {
      setTimeout(() => handler(parsedPayload), 100)
    } else {
      handler(parsedPayload)
    }
  }

  error = _error => {}

  componentWillUnmount() {
    clearTimeout(this.pollingRoomsTimer)
    this.websocket.close()
  }

  updateOperatorStatus = room => {
    const { t, router } = this.context
    const { dispatch } = this.props
    dispatch(receiveRoom(room))

    if ('Notification' in window) {
      if (room.operator_state === 'calling') {
        const options = { icon: '/image/notification.png', tag: 'operator' }
        const notification = new Notification(t('operator.notificationMessage', { id: room.id }), options)
        notification.addEventListener('click', () => {
          router.push(`/operator/${room.id}`)
          notification.close()
        })

        setTimeout(() => notification.close(), 5000)
      }
    }
  }

  receiveMessage = message => {
    const { store, router } = this.context
    const { dispatch } = this.props

    const state = store.getState()
    const userId = state.session.id
    const room = lodash.find(state.chat.rooms, { uuid: message.channel_uuid })
    //  Ignore message for others
    if (!lodash.some(room.operators, { id: userId })) return

    const contentText = message.content.text || ''
    const options = { icon: '/image/notification.png', tag: 'operator', image: message.content.image_url }
    if ('Notification' in window) {
      const notification = new Notification(contentText, options)
      notification.addEventListener('click', () => {
        router.push(`/operator/${room.id}`)
        notification.close()
      })

      setTimeout(() => notification.close(), 5000)
    }
    dispatch(incrementUnreadMessage(room))
  }

  startPolling = () => {
    if (!this.pollingRoomsTimer) {
      this.pollingRoomsTimer = setTimeout(this.pollingRooms, 0)
    }
  }

  pollingRooms = () => {
    //  Fetch rooms via HTTP request
    const state = this.context.store.getState()
    const { dispatch } = this.props

    if (!this.websocket || this.websocket.status === 'connected') return

    dispatch(fetchRooms(state.session, { operator_state: 'calling' })).then(response => {
      const noticableRooms = lodash.differenceBy(response.rooms, this.prevCallingRooms, 'uuid')
      noticableRooms.forEach(this.updateOperatorStatus)
      this.prevCallingRooms = response.rooms

      clearTimeout(this.pollingRoomsTimer)
      this.pollingRoomsTimer = setTimeout(this.pollingRooms, 60000)
    })
  }

  render() {
    return null
  }
}

export default connect()(CallingWatcher)
