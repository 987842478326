import { deleteCache } from './api'
import Schemas from '../middleware/schema'
import request from '../helpers/requestManagementAPI'

export const fetchOAuthClients = (token, filter = {}) => dispatch => {
  return request.get('/oauth_clients/', {
    token,
    dispatch,
    body: filter,
    target: 'oauth_clients',
    schema: Schemas.oauthClients,
    preserve: false,
  })
}

export const fetchOAuthClient = (token, id, errorHandler) => dispatch => {
  return request.get(`/oauth_clients/${id}`, {
    token,
    dispatch,
    target: 'oauth_clients',
    schema: Schemas.oauthClient,
    errorHandler,
  })
}

export const createOAuthClient = (token, oauthClient) => dispatch => {
  return request.post('/oauth_clients/', {
    token,
    dispatch,
    body: oauthClient,
    target: 'oauth_clients',
    schema: Schemas.oauthClient,
  })
}

export const updateOAuthClient = (token, oauthClient) => dispatch => {
  return request.put(`/oauth_clients/${oauthClient.id}`, {
    token,
    dispatch,
    body: oauthClient,
    target: 'oauth_clients',
    schema: Schemas.oauthClient,
  })
}

export const deleteOAuthClient = (token, id) => dispatch => {
  return request
    .delete(`/oauth_clients/${id}`, {
      token,
      dispatch,
      target: 'oauth_clients',
    })
    .then(() => dispatch(deleteCache('oauth_clients', id)))
}

export const adminConsent = (token, id) => dispatch => {
  return request.get(`/oauth_clients/${id}/admin_consent`, {
    token,
    dispatch,
  })
}

export const fetchUsedApplications = (token, id) => dispatch => {
  return request.get(`/oauth_clients/${id}/applications`, {
    token,
    dispatch,
  })
}

export const getSalesforceObject = (token, id, salesforce_api_subtype) => dispatch => {
  dispatch(clearSalesforceRequestObject())
  return request
    .get(`/oauth_clients/${id}/salesforce_objects`, {
      token,
      dispatch,
      body: { type: salesforce_api_subtype },
    })
    .then(json => dispatch(responseSalesforceObject(json)))
}

export const getSalesforceObjectDetail = (token, id, name, salesforce_api_subtype) => dispatch => {
  dispatch(clearSalesforceRequestObjectDetail())
  return request
    .get(`/oauth_clients/${id}/salesforce_objects/${name}`, {
      token,
      dispatch,
      body: { type: salesforce_api_subtype },
    })
    .then(json => dispatch(responseSalesforceObjectDetail(json)))
}

export const requestOAuth = () => ({ type: 'REQUEST_OAUTH' })

export const responseOAuth = oauth_request => ({ type: 'RESPONSE_OAUTH', oauth_request })

export const responseSalesforceObject = salesforceObjects => ({
  type: 'RESPONSE_SALESFORCE_OBJECT',
  salesforceObjects,
})
export const responseSalesforceObjectDetail = salesforceObjectDetails => ({
  type: 'RESPONSE_SALESFORCE_OBJECT_DETAIL',
  salesforceObjectDetails,
})
export const clearSalesforceRequestObject = () => ({ type: 'CLEAR_SALESFORCE_OBJECT' })
export const clearSalesforceRequestObjectDetail = () => ({ type: 'CLEAR_SALESFORCE_OBJECT_DETAIL' })
