import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, reduxForm, propTypes, Field } from 'redux-form'
import Sticky from 'react-sticky-state'

// components
import DataBindingTable from '../../../components/common/DataBindingTable'
import Loader from '../../../components/common/Loader'
import LabelWithTooltip from '../../../components/common/LabelWithTooltip'
import Simulator from '../../../containers/simulator/Simulator'
import Sidebar from '../../../components/common/Sidebar'

// actions
import { fetchBot } from '../../../actions/bot'
import { fetchFaq } from '../../../actions/faq'
import { fetchFaqItems } from '../../../actions/faq_item'
import { updateTableState } from '../../../actions/table'

// helpers
import { isFetching } from '../../../helpers/selector'
import { rejectReadonlyUser } from '../../../helpers/permission'

const TextWithTitle = props => {
  return <span title={props.value}>{props.value}</span>
}
TextWithTitle.propTypes = { value: PropTypes.string }

export class GroupDetail extends Component {
  static MAX_FAQ_ITEMS = 20
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired,
    faqItemId: PropTypes.string,
    bot: PropTypes.object,
    faq: PropTypes.object.isRequired,
    faqItems: PropTypes.array.isRequired,
    inquiries: PropTypes.array.isRequired,
    isFetching: PropTypes.bool,
    faqTableState: PropTypes.object,
    inquiryTableState: PropTypes.object,
    params: PropTypes.shape({
      bot_id: PropTypes.string.isRequired,
      faq_id: PropTypes.string,
    }),
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const {
      dispatch,
      params: { bot_id, faq_id },
    } = this.props
    dispatch(fetchBot(state.session.token, bot_id))
    dispatch(fetchFaq(state.session.token, faq_id))
    dispatch(fetchFaqItems(state.session.token, faq_id))
  }

  updateTableState = (path, tableName, tableState) => {
    const { dispatch } = this.props
    dispatch(updateTableState(path, tableName, tableState))
  }

  createFaq = data => {
    const { router } = this.context
    const {
      dispatch,
      inquiries,
      params: { bot_id, faq_id },
    } = this.props

    if (rejectReadonlyUser(dispatch, this.context)) return

    const state = {
      questions: lodash.map(lodash.at(inquiries, lodash.keys(data.questions)), 'question'),
    }
    if (data.answer != null) {
      state.answer = inquiries[data.answer].answer
    }
    router.push({ pathname: `/bots/${bot_id}/faqs/${faq_id}/items/new`, state })
  }

  updateFaq = data => {
    const { router } = this.context
    const {
      dispatch,
      inquiries,
      params: { bot_id, faq_id },
    } = this.props

    if (rejectReadonlyUser(dispatch, this.context)) return

    const state = {
      questions: lodash.map(lodash.at(inquiries, lodash.keys(data.questions)), 'question'),
    }
    router.push({ pathname: `/bots/${bot_id}/faqs/${faq_id}/items/${data.faqItemId}`, state })
  }

  render() {
    const { t } = this.context
    const { isFetching, mode, faqItemId, bot, faq, handleSubmit } = this.props

    return (
      <Loader loaded={!isFetching} type="show">
        <form>
          <div className="mb-3">
            <div className="form-group radio-inline">
              <Field
                name="mode"
                type="radio"
                value="insert"
                component="input"
                className="form-control"
                id="insert"
              />
              <LabelWithTooltip
                htmlFor="insert"
                className="dm-radio"
                name="inquiry.groupDetail.modes.insert"
                direction="left"
              />
            </div>
            <div className="form-group radio-inline">
              <Field
                name="mode"
                type="radio"
                value="update"
                component="input"
                className="form-control"
                id="update"
              />
              <LabelWithTooltip
                htmlFor="update"
                className="dm-radio"
                name="inquiry.groupDetail.modes.update"
                direction="left"
              />
            </div>
          </div>
          {this.renderFaqTable()}
          {this.renderInquiryTable()}

          <Sticky>
            <div className="sticky bottom">
              <div className="dm-sticky-buttons">
                {mode === 'insert' && (
                  <button
                    type="button"
                    className="btn btn-primary dm-btn"
                    onClick={handleSubmit(this.createFaq)}
                  >
                    {t('inquiry.groupDetail.createFaq')}
                  </button>
                )}
                {mode === 'update' && (
                  <button
                    type="button"
                    className="btn btn-primary dm-btn"
                    onClick={handleSubmit(this.updateFaq)}
                    disabled={!faqItemId}
                  >
                    {t('inquiry.groupDetail.updateFaq')}
                  </button>
                )}
              </div>
            </div>
          </Sticky>
        </form>

        <Sidebar titles={[t('simulator.title')]}>
          <Simulator tabs={['faqClassifier', 'chat']} faq={faq} bot={bot} />
        </Sidebar>
      </Loader>
    )
  }

  renderFaqNameCell = props => {
    const { mode } = this.props
    if (mode === 'insert') {
      return props.value
    } else {
      return (
        <>
          <Field
            key={props.viewIndex}
            name="faqItemId"
            id={`faqItemId_${props.index}`}
            type="radio"
            value={props.original.id.toString()}
            className="form-control"
            component="input"
          />
          <label htmlFor={`faqItemId_${props.index}`} title={props.value}>
            {props.value}
          </label>
        </>
      )
    }
  }

  renderFaqTable() {
    const { t } = this.context
    const { faqItems, faqTableState } = this.props
    const columns = [
      {
        Header: t('inquiry.groupDetail.faqTable.name'),
        id: 'name',
        accessor: 'name',
        Cell: this.renderFaqNameCell,
      },
      {
        Header: t('inquiry.groupDetail.faqTable.question'),
        id: 'questions',
        accessor: 'questions[0]',
        Cell: TextWithTitle,
      },
      {
        Header: t('inquiry.groupDetail.faqTable.answer'),
        id: 'answer',
        accessor: 'answers[0]',
        Cell: TextWithTitle,
      },
      {
        id: 'confidence',
        accessor: 'confidence',
        show: false,
      },
    ]

    return (
      <div>
        <LabelWithTooltip name="inquiry.groupDetail.faqTable.caption" className="dm-title" />
        <DataBindingTable
          tableName="FaqList"
          items={faqItems}
          columns={columns}
          defaultSorted={[{ id: 'confidence', desc: true }]}
          tableState={faqTableState}
          updateTableState={this.updateTableState}
        />
      </div>
    )
  }

  renderInquiryQuestionCell = props => {
    return (
      <>
        <Field
          key={props.viewIndex}
          name="questions"
          id={`question_${props.index}`}
          type="checkbox"
          className="form-control"
          component="input"
          format={value => {
            return value && value[props.index]
          }}
          normalize={(value, _previousValue, _allValues, previousAllValues) => {
            if (value) {
              return { ...previousAllValues.questions, [props.index]: true }
            } else {
              return lodash.omit(previousAllValues.questions, props.index)
            }
          }}
        />
        <label htmlFor={`question_${props.index}`} title={props.value}>
          {props.value}
        </label>
      </>
    )
  }

  renderInquiryAnswerCell = props => {
    const { mode } = this.props
    if (mode === 'insert') {
      return (
        <>
          <Field
            key={props.viewIndex}
            name="answer"
            id={`answer_${props.index}`}
            type="radio"
            value={props.index}
            className="form-control"
            component="input"
            parse={value => value && parseInt(value, 10)}
          />
          <label htmlFor={`answer_${props.index}`} title={props.value}>
            {props.value}
          </label>
        </>
      )
    } else {
      return <TextWithTitle {...props} />
    }
  }

  renderInquiryTable() {
    const { t } = this.context
    const { inquiries, inquiryTableState } = this.props
    const columns = [
      {
        Header: t('inquiry.groupDetail.inquiryTable.category1'),
        id: 'category1',
        accessor: 'categories[0]',
        width: 120,
      },
      {
        Header: t('inquiry.groupDetail.inquiryTable.category2'),
        id: 'category2',
        accessor: 'categories[1]',
        width: 120,
      },
      {
        Header: t('inquiry.groupDetail.inquiryTable.category3'),
        id: 'category3',
        accessor: 'categories[2]',
        width: 120,
      },
      {
        Header: t('inquiry.groupDetail.inquiryTable.category4'),
        id: 'category4',
        accessor: 'categories[3]',
        width: 120,
      },
      {
        Header: t('inquiry.groupDetail.inquiryTable.category5'),
        id: 'category5',
        accessor: 'categories[4]',
        width: 120,
      },
      {
        Header: t('inquiry.groupDetail.inquiryTable.question'),
        id: 'question',
        accessor: 'question',
        Cell: this.renderInquiryQuestionCell,
      },
      {
        Header: t('inquiry.groupDetail.inquiryTable.answer'),
        id: 'answer',
        accessor: 'answer',
        Cell: this.renderInquiryAnswerCell,
      },
    ]

    return (
      <div>
        <LabelWithTooltip name="inquiry.groupDetail.inquiryTable.caption" className="dm-title" />
        <DataBindingTable
          tableName="InquiryList"
          items={inquiries}
          columns={columns}
          tableState={inquiryTableState}
          updateTableState={this.updateTableState}
        />
      </div>
    )
  }
}

export const GroupDetailForm = reduxForm({
  form: 'GroupDetail',
})(GroupDetail)

const selector = formValueSelector('GroupDetail')

export const mapStateToProps = (state, props) => {
  const mode = selector(state, 'mode') || 'insert'
  const faqItemId = selector(state, 'faqItemId') || null
  const bot = state.entities.bots[props.params.bot_id] || {}
  const faq = state.entities.faqs[props.params.faq_id] || {}

  const group_id = parseInt(props.params.group_id, 10)
  const groups = state.groups[props.params.faq_id] || []
  const group = lodash.find(groups, group => group.group_id === group_id) || {}
  const inquiries = group.inquiries || []

  const candidates = lodash.concat(...lodash.map(inquiries, x => x.candidates))
  const uniqCandidates = lodash.map(lodash.groupBy(candidates, 'id'), (value, key) => ({
    id: key,
    confidence: lodash.maxBy(value, 'confidence').confidence,
  }))
  const faqItems = lodash.map(uniqCandidates, candidate => ({
    ...state.entities.faq_items[candidate.id],
    confidence: candidate.confidence,
  }))
  const sortedFaqItems = lodash.orderBy(faqItems, 'confidence', 'desc').slice(-GroupDetail.MAX_FAQ_ITEMS)

  return {
    mode: mode,
    faqItemId: faqItemId,
    bot: bot,
    faq: faq,
    faqItems: lodash.filter(sortedFaqItems, 'id'),
    inquiries,
    isFetching: isFetching(state),
    faqTableState: state.table[`${window.location.pathname}#FaqList`],
    inquiryTableState: state.table[`${window.location.pathname}#InquiryList`],
    initialValues: { mode: 'insert', questions: {} },
  }
}

export default connect(mapStateToProps)(GroupDetailForm)
