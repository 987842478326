import flatten from 'lodash/flatten'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Tooltip from './Tooltip'

class LabelWithTooltip extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    direction: PropTypes.string,
  }

  getTooltipKey = name => {
    const fields = name.split('.')
    const tooltipFields = [fields.slice(0, 1), 'tooltip', fields.slice(1)]
    return flatten(tooltipFields).join('.')
  }

  render() {
    const { t } = this.context
    const { name, direction, ...otherProps } = this.props
    const tooltipKey = this.getTooltipKey(name)

    return (
      <label {...otherProps}>
        {t(name)}
        <Tooltip name={tooltipKey} direction={direction} />
      </label>
    )
  }
}

export default LabelWithTooltip
