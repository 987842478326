import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { deleteCache } from '../../actions/api'
import { fetchThreads } from '../../actions/application'
import ApplicationLogsComponent from '../../components/analytics/ApplicationLogs'

const isDisplayThread = thread => {
  // Show thread which is already start dialogue
  if (thread.message_count > 0) return true

  return false
}

class ApplicationLogs extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    application: PropTypes.object.isRequired,
    topics: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    threads: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
  }

  constructor() {
    super()
    this.previousFilter = []
  }

  onRefresh = tableInstance => {
    if (this.props.isFetching) return

    Promise.resolve()
      .then(() => this.props.dispatch(deleteCache('threads')))
      .then(() => tableInstance.fireFetchData())
  }

  onFetchData = (tableState, instance) => {
    const state = this.context.store.getState()
    const { dispatch, application } = this.props

    if (lodash.isEmpty(tableState.data)) {
      //  Initialize: fetch entire threads and fetch detail on thread in first page
      dispatch(fetchThreads(state.session.token, application.id)).then(() => {
        const threads = lodash.map(instance.getResolvedState().sortedData, '_original')
        const filteredThreads = lodash.filter(threads, thread => isDisplayThread(thread))

        const uuids = lodash.map(filteredThreads, 'uuid').slice(0, instance.state.pageSize)
        dispatch(fetchThreads(state.session.token, application.id, uuids, true))
      })
    } else {
      //  Delay fetching to throttle consecutive request from changing filter
      const currentFilter = instance.getResolvedState().filtered
      const delay = lodash.isEqual(this.previousFilter, currentFilter) ? 0 : 500
      this.previousFilter = lodash.cloneDeep(currentFilter)
      clearTimeout(this.fetchThreadDetailTimer)

      this.fetchThreadDetailTimer = setTimeout(() => {
        //  Add details for threads in current page
        const pagedData = lodash.chunk(tableState.sortedData, tableState.pageSize)
        const uuids = lodash.map(pagedData[tableState.page], '_original.uuid')
        if (lodash.isEmpty(uuids)) return
        dispatch(fetchThreads(state.session.token, application.id, uuids, true))
      }, delay)
    }
  }

  render() {
    const { application, topics, users, threads, isFetching } = this.props

    return (
      <div>
        <ApplicationLogsComponent
          application={application}
          topics={topics}
          users={users}
          threads={threads}
          isFetching={isFetching}
          onRefresh={this.onRefresh}
          onFetchData={this.onFetchData}
        />
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const threads = lodash.values(state.entities.threads) || []
  const filteredThreads = lodash.filter(threads, thread => isDisplayThread(thread))

  return {
    ...props,
    threads: filteredThreads,
  }
}

export default connect(mapStateToProps)(ApplicationLogs)
