import lodash from 'lodash'

import PropTypes from 'prop-types'
import React, { Component } from 'react'

class MessageIcon extends Component {
  static propTypes = {
    sender_type: PropTypes.string.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    url: PropTypes.string,
    fallbackUrl: PropTypes.string,
  }

  constructor() {
    super()
    this.state = { isError: false }
  }

  isCustomIconEnable = () => {
    const { sender_type, url, fallbackUrl } = this.props

    if (!lodash.includes(['bot', 'push_api', 'operator'], sender_type)) return false
    if (!url) return false
    if (this.state.isError && !fallbackUrl) return false

    return true
  }

  render() {
    const { sender_type, url, fallbackUrl, isLoaded } = this.props

    if (this.isCustomIconEnable()) {
      if (isLoaded) {
        // show custom bot icon
        if (!this.state.isError) {
          return (
            <img
              id="icon"
              className="avator-bot-changed-icon"
              src={url}
              onError={() => {
                this.setState({ isError: true })
              }}
              alt=""
            />
          )
        } else {
          return <img id="icon" className="avator-bot-changed-icon" src={fallbackUrl} alt="" />
        }
      } else {
        // show empty icon circle until icon image loaded
        return (
          <img
            id="icon"
            className="avator-bot-changed-icon"
            src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs="
            alt=""
          />
        )
      }
    } else if (sender_type === 'push_api') {
      // show default bot icon
      return <span className={'avator-bot'} />
    } else {
      // show default bot icon, operator icon, or nothing (for user message)
      return <span className={`avator-${sender_type}`} />
    }
  }
}

export default MessageIcon
