import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import { addNotice } from '../../actions/notice'
import { deleteSession } from '../../actions/session'
import { confirmUser } from '../../actions/user'

export class EmailConfirm extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      confirmation_token: PropTypes.string.isRequired,
    }),
  }

  componentDidMount() {
    const { t, router } = this.context
    const {
      dispatch,
      params: { confirmation_token },
    } = this.props
    return dispatch(confirmUser(confirmation_token))
      .then(() => dispatch(deleteSession()))
      .then(() => router.replace('/login'))
      .then(() => dispatch(addNotice('info', t('user.changeEmailSuccess'))))
  }

  render() {
    return null
  }
}

export default connect()(EmailConfirm)
