import lodash from 'lodash'

import TextMessageAction from '../components/topic/TextMessageAction'
import UploadImageAction from '../components/topic/UploadImageAction'
import AiAssistantAction from '../components/topic/AiAssistantAction'
import RunIntegrationAction from '../components/topic/RunIntegrationAction'
import CallOperatorAction from '../components/topic/CallOperatorAction'
import ConfirmAction from '../components/topic/ConfirmAction'
import ChooseAction from '../components/topic/ChooseAction'
import ExitTopicAction from '../components/topic/ExitTopicAction'
import SlotFillingAction from '../components/topic/SlotFillingAction'
import ChangeScenarioAction from '../components/topic/ChangeScenarioAction'
import ClassifyFaqAction from '../components/topic/ClassifyFaqAction'
import GenerateAnswerAction from '../components/topic/GenerateAnswerAction'
import ChooseScenarioAction from '../components/topic/ChooseScenarioAction'
import ItemListAction from '../components/topic/ItemListAction'
import ChooseListAction from '../components/topic/ChooseListAction'
import CarouselAction from '../components/topic/CarouselAction'
import CarouselListAction from '../components/topic/CarouselListAction'
import FormAction from '../components/topic/FormAction'
import RegisterTaskAction from '../components/topic/RegisterTaskAction'
import AbortTaskAction from '../components/topic/AbortTaskAction'
import CompleteTaskAction from '../components/topic/CompleteTaskAction'
import VariableAction from '../components/topic/VariableAction'
import LogAction from '../components/topic/LogAction'

import { replaceSystemVariableWhenOnChange } from './replaceSystemVariable'

export const getActionComponent = type => {
  const components = {
    speak: TextMessageAction,
    upload_image: UploadImageAction,
    ai_assistant: AiAssistantAction,
    confirm: ConfirmAction,
    choose: ChooseAction,
    slot: SlotFillingAction,
    operator: CallOperatorAction,
    exit_topic: ExitTopicAction,
    integration: RunIntegrationAction,
    change_scenario: ChangeScenarioAction,
    faq: ClassifyFaqAction,
    generate_answer: GenerateAnswerAction,
    choose_scenario: ChooseScenarioAction,
    item_list: ItemListAction,
    choose_list: ChooseListAction,
    carousel: CarouselAction,
    carousel_list: CarouselListAction,
    form: FormAction,
    register_task: RegisterTaskAction,
    abort_task: AbortTaskAction,
    complete_task: CompleteTaskAction,
    variable: VariableAction,
    log: LogAction,
  }
  return components[type]
}

export const validateAction = action => {
  const component = getActionComponent(action.type)
  if (!component) return {}
  return component.validate(action)
}

export const validateActionForLine = action => {
  const component = getActionComponent(action.type)
  if (!component) return true
  return component.validateForLine(action)
}

export const convertSystemVariablesOfAction = (action, isSave) => {
  // common
  replaceSystemVariableWhenOnChange(action, 'condition_variable', isSave, false)

  if (action.condition_type === 'between') {
    replaceSystemVariableWhenOnChange(action, 'condition_value["from"]', isSave, true)
    replaceSystemVariableWhenOnChange(action, 'action.condition_value["to"]', isSave, true)
  } else {
    replaceSystemVariableWhenOnChange(action, 'condition_value', isSave, true)
  }

  if (action.type === 'speak') {
    replaceSystemVariableWhenOnChange(action, 'content', isSave, true)
  }
  if (action.type === 'ai_assistant') {
    replaceSystemVariableWhenOnChange(action, 'prompt', isSave, true)
  }
  if (action.type === 'operator') {
    replaceSystemVariableWhenOnChange(action, 'content', isSave, true)
  }
  if (action.type === 'confirm') {
    ;['title', 'text'].forEach(replaceKey => {
      replaceSystemVariableWhenOnChange(action, replaceKey, isSave, true)
    })
  }
  if (action.type === 'choose') {
    ;['title', 'text'].forEach(replaceKey => {
      replaceSystemVariableWhenOnChange(action, replaceKey, isSave, true)
    })
    action.choices.forEach(choice => {
      replaceSystemVariableWhenOnChange(choice, 'label', isSave, true)
      replaceSystemVariableWhenOnChange(choice, 'value', isSave, true)
    })
  }
  if (action.type === 'exit_topic') {
    replaceSystemVariableWhenOnChange(action, 'content', isSave, true)
  }
  if (action.type === 'choose_scenario') {
    ;['title', 'text'].forEach(replaceKey => {
      replaceSystemVariableWhenOnChange(action, replaceKey, isSave, true)
    })
  }
  if (action.type === 'change_scenario') {
    replaceSystemVariableWhenOnChange(action, 'content', isSave, true)
  }
  if (action.type === 'faq') {
    replaceSystemVariableWhenOnChange(action, 'content', isSave, true)
    replaceSystemVariableWhenOnChange(action, 'item_name_message_template', isSave, true)
  }
  if (action.type === 'generate_answer') {
    replaceSystemVariableWhenOnChange(action, 'content', isSave, true)
    replaceSystemVariableWhenOnChange(action, 'prompt', isSave, true)
  }
  if (action.type === 'carousel') {
    action.items.forEach(item => {
      ;['carousel_id', 'title', 'text'].forEach(replaceKey => {
        replaceSystemVariableWhenOnChange(item, replaceKey, isSave, true)
      })
      if (item.actions) {
        item.actions.forEach(button => {
          replaceSystemVariableWhenOnChange(button, 'label', isSave, true)
          replaceSystemVariableWhenOnChange(button, 'value', isSave, true)
        })
      }
      if (item.default_action) {
        replaceSystemVariableWhenOnChange(item.default_action, 'label', isSave, true)
        replaceSystemVariableWhenOnChange(item.default_action, 'value', isSave, true)
      }
    })
  }
  if (action.type === 'carousel_list') {
    ;['carousel_id', 'title', 'text'].forEach(replaceKey => {
      replaceSystemVariableWhenOnChange(action.item, replaceKey, isSave, true)
    })
    if (action.item.actions) {
      action.item.actions.forEach(button => {
        replaceSystemVariableWhenOnChange(button, 'label', isSave, true)
        replaceSystemVariableWhenOnChange(button, 'value', isSave, true)
      })
    }
    if (action.item.default_action) {
      replaceSystemVariableWhenOnChange(action.item.default_action, 'label', isSave, true)
      replaceSystemVariableWhenOnChange(action.item.default_action, 'value', isSave, true)
    }
  }
  if (action.type === 'form') {
    action.fields.forEach(field => {
      replaceSystemVariableWhenOnChange(field.arguments, 'data', isSave, true)
      replaceSystemVariableWhenOnChange(field.arguments, 'list_variable', isSave, false)
      replaceSystemVariableWhenOnChange(field.arguments, 'default_value', isSave, true)
      replaceSystemVariableWhenOnChange(field.arguments, 'variable_name', isSave, false)
      replaceSystemVariableWhenOnChange(field.arguments, 'label', isSave, true)
      replaceSystemVariableWhenOnChange(field.arguments, 'value', isSave, true)

      if (field.arguments.data_list) {
        lodash
          .range(field.arguments.length)
          .map(index => replaceSystemVariableWhenOnChange(field.arguments.data_list, index, isSave, true))
      }
    })
    action.buttons.forEach(button => {
      replaceSystemVariableWhenOnChange(button, 'label', isSave, true)
      replaceSystemVariableWhenOnChange(button, 'value', isSave, true)
    })
  }
  if (action.type === 'register_task') {
    action.variables.forEach(variable => {
      replaceSystemVariableWhenOnChange(variable, 'value', isSave, true)
    })
  }
  if (action.type === 'abort_task') {
    replaceSystemVariableWhenOnChange(action, 'content', isSave, true)
  }
  if (action.type === 'slot') {
    replaceSystemVariableWhenOnChange(action, 'question', isSave, true)
    action.slots.forEach(slot => {
      if (slot) replaceSystemVariableWhenOnChange(slot, 'default', isSave, true)
    })
  }
  if (action.type === 'variable') {
    if (action.arguments) {
      replaceSystemVariableWhenOnChange(action.arguments, 'value', isSave, true)
    }
  }
  if (action.type === 'log') {
    replaceSystemVariableWhenOnChange(action, 'content', isSave, true)
  }
}

export const collectVariables = (context, props, actions) => {
  const variables = [
    {
      variable: context.t('systemVariables.@latestClassifiedScenarioQuestion'),
      text: context.t('systemVariables.@latestClassifiedScenarioQuestion'),
    },
  ]

  if (!actions) return variables

  actions.forEach((action, index) => {
    const component = getActionComponent(action.type)
    if (!component) return
    component.getVariables(action, props, context).forEach(variable => {
      variables.push({ index, ...variable })
    })
  })
  return lodash.uniqBy(variables, 'variable')
}

export const sanitizeActions = (context, props, actions) => {
  const clonedActions = lodash.cloneDeep(actions)

  return clonedActions.map(action => {
    delete action.uuid
    //  Unify unspecified condition value to empty string
    if (action.condition_value == null) action.condition_value = ''

    //  Clear default value if optional isn't set
    if (action.type === 'slot') {
      action.slots.forEach(slot => {
        if (slot) {
          if (!slot.is_optional) {
            slot.default = undefined
          }
          slot.entity_name = undefined
        }
      })
      if (action.skip_question) {
        delete action.question
        action.slots.forEach(slot => delete slot?.template)
      }
      if (!action.use_reminder) {
        delete action.reminder_delay
        delete action.reminder_unit
        delete action.reminder_topic_id
      }
    }

    if (action.type === 'choose_scenario') {
      action.image_url = action.image_method === 'upload' ? action.upload_image_url : action.input_image_url
      delete action.image
      delete action.upload_image_url
      delete action.input_image_url

      action.choices = lodash.map(lodash.filter(action.choices), (choice, index) => {
        return { ...choice, order: index }
      })
    }

    if (action.type === 'integration') {
      const integration = lodash.find(props.integrations, { id: parseInt(action.integration_id, 10) })
      if (integration.type !== 'http') {
        action.exit_scenario_if_failed = true
      }
    }

    if (action.type === 'operator') {
      action.tags.forEach((tag, index) => {
        if (!tag || (tag && !tag.k)) action.tags.splice(index, 1)
      })
    }

    if (action.type === 'upload_image') {
      if (action.image_method === 'upload') {
        action.image_url = action.upload_image_url
        action.thumb_url = action.upload_thumb_url
      } else {
        action.image_url = action.thumb_url = action.input_url
      }
      if (!action.use_action) {
        delete action.open_url
      }
    }
    delete action.upload_file
    delete action.upload_image_url
    delete action.upload_thumb_url
    delete action.isInvalidImage
    delete action.input_url
    delete action.input_thumb_url

    if (action.type === 'carousel') {
      action.items.forEach(item => {
        if (item.image_method === 'upload') {
          item.image_url = item.upload_image_url
        } else {
          item.image_url = item.input_image_url
        }
        if (item.image_url === '') delete item.image_url
        delete item.image
        delete item.upload_image_url
        delete item.input_image_url

        if (!item.enable_default_action) {
          delete item.default_action
        }
        if (item.actions) {
          item.actions = lodash.compact(
            item.actions.map(button => {
              if (lodash.includes(['postback', 'uri'], button.type)) {
                return button
              } else {
                return null
              }
            })
          )
        }
      })
    } else {
      delete action.items
    }

    if (action.type === 'carousel_list') {
      if (action.item.image_url === '') {
        delete action.item.image_url
      }
      if (!action.item.enable_default_action) {
        delete action.item.default_action
      }
      if (action.item.actions) {
        action.item.actions = lodash.compact(
          action.item.actions.map(button => {
            if (lodash.includes(['postback', 'uri'], button.type)) {
              return button
            } else {
              return null
            }
          })
        )
      }
    } else {
      delete action.item
    }

    if (action.type === 'form') {
      action.fields = lodash.compact(
        action.fields.map(field => {
          if (!field.type) return null

          const fieldArguments = {}
          if (field.type === 'label') {
            fieldArguments.text = field.arguments.text
          } else if (field.type === 'text') {
            fieldArguments.default_value = field.arguments.default_value
            fieldArguments.placeholder = field.arguments.placeholder
          } else if (field.type === 'text_list') {
            fieldArguments.length = field.arguments.length
            fieldArguments.placeholder = field.arguments.placeholder

            fieldArguments.default_value_type = field.arguments.default_value_type
            if (field.arguments.default_value_type === 'constant') {
              fieldArguments.default_value = field.arguments.default_value
            } else {
              fieldArguments.variable_name = field.arguments.variable_name
            }

            fieldArguments.use_collapsible = field.arguments.use_collapsible
            if (field.arguments.use_collapsible) {
              fieldArguments.default_display_limit = field.arguments.default_display_limit
            }
          } else if (field.type === 'select') {
            fieldArguments.default_value = field.arguments.default_value
            fieldArguments.add_empty = field.arguments.add_empty

            fieldArguments.data_type = field.arguments.data_type
            if (field.arguments.data_type === 'constant') {
              fieldArguments.data = field.arguments.data
            } else {
              fieldArguments.list_variable = field.arguments.list_variable
              fieldArguments.item_label = field.arguments.item_label
              fieldArguments.item_value = field.arguments.item_value
            }
          } else if (field.type === 'select_list') {
            fieldArguments.data_type = field.arguments.data_type
            if (field.arguments.data_type === 'constant') {
              fieldArguments.length = field.arguments.length
              fieldArguments.data_list = field.arguments.data_list
            } else {
              fieldArguments.list_variable = field.arguments.list_variable
              fieldArguments.item_label = field.arguments.item_label
              fieldArguments.item_value = field.arguments.item_value
            }

            fieldArguments.default_value_type = field.arguments.default_value_type
            if (field.arguments.default_value_type === 'constant') {
              fieldArguments.default_value = field.arguments.default_value
            } else {
              fieldArguments.variable_name = field.arguments.variable_name
            }

            fieldArguments.add_empty = field.arguments.add_empty

            fieldArguments.use_collapsible = field.arguments.use_collapsible
            if (field.arguments.use_collapsible) {
              fieldArguments.default_display_limit = field.arguments.default_display_limit
            }
          } else if (field.type === 'multiple_select') {
            fieldArguments.data_type = field.arguments.data_type
            if (field.arguments.data_type === 'constant') {
              fieldArguments.data = field.arguments.data
            } else {
              fieldArguments.list_variable = field.arguments.list_variable
              fieldArguments.item_label = field.arguments.item_label
              fieldArguments.item_value = field.arguments.item_value
            }

            fieldArguments.default_value_type = field.arguments.default_value_type
            if (field.arguments.default_value_type === 'constant') {
              fieldArguments.default_value = field.arguments.default_value
            } else {
              fieldArguments.variable_name = field.arguments.variable_name
            }
          }

          return {
            type: field.type,
            name: field.type !== 'label' ? field.name : null,
            is_required: field.type !== 'label' ? field.is_required : null,
            arguments: fieldArguments,
          }
        })
      )
    }

    if (action.type === 'register_task') {
      action.variables = lodash.filter(action.variables, 'name')
    }

    if (action.type === 'variable') {
      if (action.arguments) {
        if (action.operation_type === 'set_classified_faq') {
          delete action.arguments.value
        } else {
          delete action.arguments.text
        }

        if (action.operation_type === 'set_variable_random') {
          action.arguments.value = action.arguments.value.replace(/\n[\n\s]*$/g, '')
        }
      } else {
        action.arguments = { value: '' }
      }
    }

    if (action.type === 'generate_answer') {
      delete action.tags
      if (!action.use_customized_prompt) {
        delete action.prompt
      }
    }

    if (action.type === 'ai_assistant') {
      if (!action.enable_set_variable) {
        delete action.variable_name
      }
    }

    if (
      action.condition_group_id === '' ||
      action.condition_group_id === 0 ||
      action.condition_group_id === null
    ) {
      action.condition_group_id = null
      action.use_condition_group = false
    }
    if (!action.use_condition_group) {
      action.condition_group_id = null
    }

    convertSystemVariablesOfAction(action, true)
    return action
  })
}

export const hasVariable = text => {
  if (!text) return false

  return /\{\{.*\}\}/.test(text)
}

export const getEntityName = (entities, entityId) => {
  const entity = lodash.find(entities, { id: parseInt(entityId, 10) })
  if (!entity) return null
  return entity.name
}
