import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, Field, reduxForm, propTypes, getFormValues } from 'redux-form'

import { addNotice } from '../../actions/notice'
import {
  fetchOAuthClient,
  fetchUsedApplications,
  updateOAuthClient,
  createOAuthClient,
  deleteOAuthClient,
  adminConsent,
} from '../../actions/oauth_client'
import { createOAuthSession, deleteOAuthSession } from '../../actions/oauth_session'
import { InputField, SelectField, CheckboxField } from '../../components/common/fields/FormFields'
import Loader from '../../components/common/Loader'
import Config from '../../helpers/config'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import { isFetching } from '../../helpers/selector'
import cancelUrl from '../../helpers/cancelurl'
import { isPermitted } from '../../helpers/permission'
import { isFQDN, isUUID } from 'validator'

const validate = data => {
  const tenant_validate = ['common', 'organizations', 'consumers']
  const errors = {}

  if (!data.type) {
    errors.type = 'validate.required'
  }

  if (!data.name) {
    errors.name = 'validate.required'
  } else if (data.name.length > 100) {
    errors.name = { id: 'validate.exceededMaxLength', values: { length: 100 } }
  }

  if (!data.client_id) {
    errors.client_id = 'validate.required'
  } else {
    if (data.type === 'office365') {
      if (data.client_id.length > 36) {
        errors.client_id = { id: 'validate.exceededMaxLength', values: { length: 36 } }
      } else if (!isUUID(data.client_id)) {
        errors.client_id = 'validate.invalid'
      }
    } else if (data.type === 'salesforce') {
      if (data.client_id.length > 255) {
        errors.client_id = { id: 'validate.exceededMaxLength', values: { length: 255 } }
      } else if (!/^[0-9a-zA-Z!-/:-@¥[-`{-~]*$/.test(data.client_id)) {
        errors.client_id = 'validate.invalid'
      }
    }
  }

  if (!data.id && !data.client_secret) {
    errors.client_secret = 'validate.required'
  }
  if (data.client_secret) {
    if (data.client_secret.length > 255) {
      errors.client_secret = { id: 'validate.exceededMaxLength', values: { length: 255 } }
    } else if (!/^[0-9a-zA-Z!-/:-@¥[-`{-~]*$/.test(data.client_secret)) {
      errors.client_secret = 'validate.invalid'
    }
  }

  if (data.type === 'office365') {
    if (!data.tenant_id) {
      errors.tenant_id = 'validate.required'
    } else if (data.tenant_id.length > 255) {
      errors.tenant_id = { id: 'validate.exceededMaxLength', values: { length: 255 } }
    } else if (
      !(isFQDN(data.tenant_id) || isUUID(data.tenant_id) || tenant_validate.indexOf(data.tenant_id) >= 0)
    ) {
      errors.tenant_id = 'validate.invalid'
    }
  }

  if (data.type === 'salesforce') {
    if (data.endpoint_type === 'custom') {
      if (!data.custom_domain) {
        errors.custom_domain = 'validate.required'
      } else if (data.custom_domain.length > 255) {
        errors.custom_domain = {
          id: 'validate.exceededMaxLength',
          values: { length: 255 },
        }
      }
    }
  }

  return errors
}

export class OAuthClientEdit extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    formValues: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    isFetching: PropTypes.bool,
    oauth_client: PropTypes.object,
    type: PropTypes.string,
    is_admin_consent: PropTypes.bool,
    tenant_id: PropTypes.string,
    client_id: PropTypes.string,
    endpoint_type: PropTypes.string,
  }

  constructor() {
    super()
    this.state = { oauthStatus: 'initialized' }
  }

  componentDidMount = () => {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    if (this.props.params.id) {
      dispatch(fetchOAuthClient(state.session.token, this.props.params.id))
    }
    cancelUrl.setRouteLeaveHook(this)

    //  Refresh OAuthClient when finish authentication in another window
    window.addEventListener('message', this.onFinishOAuthFlow)
  }

  componentWillUnmount = () => {
    window.removeEventListener('message', this.onFinishOAuthFlow)
  }

  reinitializeForm = () => {
    const { formValues } = this.props
    this.props.initialize(formValues)
  }

  handleSave = (data, dispatch) => {
    const { t } = this.context
    const session = this.context.store.getState().session

    if (this.props.params.id) {
      dispatch(fetchUsedApplications(session.token, this.props.params.id)).then(applications => {
        if (applications.length > 0) {
          const messages = lodash.concat(
            t('oauthClients.confirmUsedApplication'),
            lodash.map(applications, application => `- ${application.name}`)
          )
          if (!window.confirm(messages.join('\n'))) return
        }

        this.saveOAuthClient(data, dispatch)
      })
    } else {
      this.saveOAuthClient(data, dispatch)
    }
  }

  saveOAuthClient = (data, dispatch) => {
    const { t } = this.context
    const session = this.context.store.getState().session
    const router = this.context.router

    let oauth_client = {
      name: data.name,
      client_id: data.client_id,
      tenant_id: data.tenant_id,
    }

    if (data.type === 'office365') {
      oauth_client.is_admin_consent =
        data.office365_authentication_type === 'preauthentication' ? true : data.is_admin_consent
      oauth_client.authentication_type = data.office365_authentication_type
    } else if (data.type === 'salesforce') {
      oauth_client.endpoint_type = data.endpoint_type
      oauth_client.custom_domain = data.custom_domain
      oauth_client.authentication_type = data.salesforce_authentication_type
    }

    if (data.client_secret !== '') {
      oauth_client.client_secret = data.client_secret
    }

    if (this.props.params.id) {
      oauth_client.id = this.props.params.id
      return dispatch(updateOAuthClient(session.token, oauth_client))
        .then(() => router.push({ pathname: `/oauth_clients/${oauth_client.id}` }))
        .then(() => dispatch(addNotice('info', t('common.saveSuccessMessage'))))
        .then(() => this.reinitializeForm())
    } else {
      oauth_client.type = data.type
      return dispatch(createOAuthClient(session.token, oauth_client))
        .then(json =>
          router.push({ pathname: `/oauth_clients/${json.result}`, state: { ignoreBlocking: true } })
        )
        .then(() => dispatch(addNotice('info', t('common.saveSuccessMessage'))))
    }
  }

  handleDelete = () => {
    const { t } = this.context
    const { oauth_client } = this.props
    if (
      !window.confirm(
        t('common.deleteConfirmMessage', {
          type: t('oauthClients.name'),
          name: oauth_client.name,
        })
      )
    )
      return
    const session = this.context.store.getState().session
    const router = this.context.router
    const { dispatch } = this.props
    const id = parseInt(this.props.params.id, 10)
    dispatch(deleteOAuthClient(session.token, id))
      .then(() => router.push({ pathname: '/oauth_clients', state: { ignoreBlocking: true } }))
      .then(() => dispatch(addNotice('info', t('common.deleteSuccessMessage'))))
  }

  handleCopy = fieldName => {
    document.getElementById(fieldName).select()
    document.execCommand('Copy')
  }

  handleDeleteOAuthSession = () => {
    const state = this.context.store.getState()
    const { t } = this.context
    const { dispatch } = this.props

    dispatch(fetchUsedApplications(state.session.token, this.props.params.id)).then(applications => {
      if (applications.length > 0) {
        const messages = lodash.concat(
          t('oauthClients.confirmToRevokePreauthentication'),
          lodash.map(applications, application => `- ${application.name}`)
        )
        if (!window.confirm(messages.join('\n'))) return
      }
      dispatch(deleteOAuthSession(state.session.token, this.props.params.id)).then(() => {
        dispatch(fetchOAuthClient(state.session.token, this.props.params.id))
      })
    })
  }

  handleAdminConsent = () => {
    const state = this.context.store.getState()
    const { dispatch, type } = this.props

    const id = parseInt(this.props.params.id, 10)
    if (type === 'office365') {
      dispatch(adminConsent(state.session.token, id)).then(json => {
        const oauthWindow = window.open(json.url)
        this.setState({ oauthStatus: 'processing' })
        this.observeOAuthWindow(oauthWindow)
      })
    } else {
      dispatch(createOAuthSession(state.session.token, id, {})).then(json => {
        const oauthWindow = window.open(json.url)
        this.setState({ oauthStatus: 'processing' })
        this.observeOAuthWindow(oauthWindow)
      })
    }
  }

  observeOAuthWindow = oauthWindow => {
    const { t } = this.context
    const state = this.context.store.getState()
    const { dispatch } = this.props
    if (this.state.oauthStatus !== 'processing') return

    if (oauthWindow && oauthWindow.closed) {
      this.setState({ oauthStatus: 'canceled' })
      dispatch(addNotice('error', t('oauth_callback.canceled')))
      dispatch(fetchOAuthClient(state.session.token, this.props.params.id))
      return
    }

    setTimeout(() => {
      this.observeOAuthWindow(oauthWindow)
    }, 1000)
  }

  onFinishOAuthFlow = message => {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    const id = parseInt(this.props.params.id, 10)
    if (message.data.type === 'OAuthFlowFinished') {
      if (message.data.status === 'success') {
        this.setState({ oauthStatus: 'succeeded' })
        dispatch(fetchOAuthClient(state.session.token, id))
        dispatch(addNotice('info', message.data.message))
      } else {
        this.setState({ oauthStatus: 'failed' })
        dispatch(addNotice('error', message.data.message))
      }
    }
  }

  render() {
    const { t } = this.context
    const { isFetching, submitting, handleSubmit } = this.props
    const provider_types = []
    if (isPermitted('integration_office365', this.context)) {
      provider_types.push({ id: 'office365', name: t('oauthClients.providerTypes.office365') })
    }
    if (isPermitted('integration_salesforce', this.context)) {
      provider_types.push({ id: 'salesforce', name: t('oauthClients.providerTypes.salesforce') })
    }
    const isUpdate = !!this.props.params.id

    return (
      <div>
        <Loader loaded={!isFetching && !submitting} type="show">
          <form className="text-left col-md-9" onSubmit={handleSubmit(this.handleSave)}>
            <div className="form-group">
              <LabelWithTooltip htmlFor="type" className="dm-title-mini" name="oauthClients.type" />
              <Field
                name="type"
                items={provider_types}
                valueKey="id"
                displayKey="name"
                className="form-control dm-form-control"
                component={SelectField}
                disabled={isUpdate}
                empty={true}
              />
            </div>
            <div className="form-group">
              <LabelWithTooltip htmlFor="name" className="dm-title-mini" name="oauthClients.name" />
              <Field
                type="text"
                name="name"
                className="form-control dm-form-control"
                maxLength="100"
                component={InputField}
              />
            </div>
            {this.props.type === 'office365' && (
              <div className="form-group">
                <LabelWithTooltip
                  htmlFor="tenant_id"
                  className="dm-title-mini"
                  name="oauthClients.tenant_id"
                />
                <Field
                  type="text"
                  name="tenant_id"
                  className="form-control dm-form-control"
                  maxLength="255"
                  component={InputField}
                />
              </div>
            )}
            {this.props.type === 'salesforce' && this.renderSalesforceEndpoints()}
            <div className="form-group">
              <LabelWithTooltip htmlFor="client_id" className="dm-title-mini" name="oauthClients.client_id" />
              <Field
                type="text"
                name="client_id"
                className="form-control dm-form-control"
                maxLength="255"
                component={InputField}
              />
            </div>
            <div className="form-group">
              <LabelWithTooltip
                htmlFor="client_secret"
                className="dm-title-mini"
                name="oauthClients.client_secret"
              />
              <Field
                type="password"
                name="client_secret"
                className="form-control dm-form-control"
                autoComplete="new-password"
                maxLength="255"
                component={InputField}
                placeholder={this.props.params.id ? '********' : ''}
              />
            </div>
            {this.renderAuthenticationType()}
            {this.renderPreauthenticationButton()}
            {this.props.params.id && (
              <div className="form-group" key="callback_url">
                <LabelWithTooltip
                  htmlFor="callback_url"
                  className="dm-title-mini"
                  name="oauthClients.callbackUrl"
                />
                <div className="input-group">
                  <Field
                    type="text"
                    name="callback_url"
                    className="form-control dm-form-control"
                    readOnly={true}
                    component={InputField}
                  />
                  <label className="input-group-addon btn btn-primary dm-btn is-gray">
                    {t('oauthClients.copy')}
                    <button type="button" onClick={() => this.handleCopy('callback_url')} />
                  </label>
                </div>
              </div>
            )}
            <div className="form-group text-right">
              <button type="submit" className="btn btn-primary dm-btn" disabled={submitting}>
                {t('common.save')}
              </button>
              {this.props.params.id && (
                <button
                  type="button"
                  className="btn btn-danger dm-btn"
                  onClick={this.handleDelete}
                  disabled={submitting}
                >
                  {t('common.delete')}
                </button>
              )}
            </div>
          </form>
        </Loader>
      </div>
    )
  }

  renderSalesforceEndpoints() {
    const { t } = this.context
    const { endpoint_type } = this.props

    return (
      <div className="form-group dm-form-group">
        <LabelWithTooltip className="dm-title-mini" name="oauthClients.endpoint.title" />
        <div className="radio-with-label">
          <Field
            name="endpoint_type"
            id="salesforce_endpoint_type_original"
            className="form-control dm-form-control"
            component="input"
            type="radio"
            value="original"
          />
          <LabelWithTooltip
            htmlFor="salesforce_endpoint_type_original"
            name="oauthClients.endpoint.options.original"
          />
        </div>
        <div className="radio-with-label">
          <Field
            name="endpoint_type"
            id="salesforce_endpoint_type_sandbox"
            className="form-control dm-form-control"
            component="input"
            type="radio"
            value="sandbox"
          />
          <LabelWithTooltip
            htmlFor="salesforce_endpoint_type_sandbox"
            name="oauthClients.endpoint.options.sandbox"
          />
        </div>
        <div className="radio-with-label">
          <Field
            name="endpoint_type"
            id="salesforce_endpoint_type_custom"
            className="form-control dm-form-control"
            component="input"
            type="radio"
            value="custom"
          />
          <LabelWithTooltip
            htmlFor="salesforce_endpoint_type_custom"
            name="oauthClients.endpoint.options.custom"
          />
        </div>
        {endpoint_type === 'custom' && (
          <div className="with-radio">
            <LabelWithTooltip htmlFor="custom_domain" name="oauthClients.endpoint.domain" />
            <Field
              name="custom_domain"
              id="custom_domain"
              className="form-control dm-form-control"
              component={InputField}
              type="text"
              size="30"
              maxLength="255"
              placeholder={t('oauthClients.endpoint.domainPlaceholder')}
            />
          </div>
        )}
      </div>
    )
  }

  renderAuthenticationType() {
    const { t } = this.context
    const { type, formValues } = this.props

    const isUpdate = !!this.props.params.id

    if (type === 'office365') {
      return (
        <div className="form-group dm-form-group">
          <LabelWithTooltip
            htmlFor="office365_authentication_type"
            className="dm-title-mini"
            name="oauthClients.office365AuthenticationTypes.title"
          />
          <div className="radio-with-label" key="normal">
            <Field
              name="office365_authentication_type"
              id="office365_authentication_type_normal"
              className="form-control dm-form-control"
              component="input"
              type="radio"
              value="normal"
              disabled={isUpdate}
            />
            <LabelWithTooltip
              htmlFor="office365_authentication_type_normal"
              className={isUpdate ? ' disabled' : ''}
              name="oauthClients.office365AuthenticationTypes.authentication"
            />
          </div>
          {formValues.office365_authentication_type === 'normal' && (
            <div className="checkbox-with-label with-radio" key="is_admin_consent">
              <Field type="checkbox" name="is_admin_consent" component={CheckboxField} />
              <LabelWithTooltip htmlFor="is_admin_consent" name="oauthClients.is_admin_consent" />
            </div>
          )}
          <div className="radio-with-label" key="preauthentication">
            <Field
              name="office365_authentication_type"
              id="office365_authentication_type_preauthentication"
              className="form-control dm-form-control"
              component="input"
              type="radio"
              value="preauthentication"
              disabled={isUpdate}
            />
            <LabelWithTooltip
              htmlFor="office365_authentication_type_preauthentication"
              className={isUpdate ? 'disabled' : ''}
              name="oauthClients.office365AuthenticationTypes.preauthentication"
            />
          </div>
          <div className="dm-note">{t('oauthClients.office365AuthenticationTypes.note')}</div>
        </div>
      )
    } else if (type === 'salesforce') {
      return (
        <div className="form-group dm-form-group">
          <LabelWithTooltip
            htmlFor="salesforce_authentication_type"
            className="dm-title-mini"
            name="oauthClients.salesforceAuthenticationTypes.title"
          />
          <div className="radio-with-label" key="normal">
            <Field
              name="salesforce_authentication_type"
              id="salesforce_authentication_type_normal"
              className="form-control dm-form-control"
              component="input"
              type="radio"
              value="normal"
              disabled={isUpdate}
            />
            <LabelWithTooltip
              htmlFor="salesforce_authentication_type_normal"
              className={`${isUpdate ? 'disabled' : ''}`}
              name="oauthClients.salesforceAuthenticationTypes.authentication"
            />
          </div>
          <div className="radio-with-label" key="preauthentication">
            <Field
              name="salesforce_authentication_type"
              id="salesforce_authentication_type_preauthentication"
              className="form-control dm-form-control"
              component="input"
              type="radio"
              value="preauthentication"
              disabled={isUpdate}
            />
            <LabelWithTooltip
              htmlFor="salesforce_authentication_type_preauthentication"
              className={isUpdate ? ' disabled' : ''}
              name="oauthClients.salesforceAuthenticationTypes.preauthentication"
            />
          </div>
          <div className="dm-note">{t('oauthClients.salesforceAuthenticationTypes.note')}</div>
        </div>
      )
    }
  }

  renderPreauthenticationButton() {
    const { t } = this.context
    const { formValues, type, oauth_client, submitting } = this.props

    if (!this.props.params.id) return
    if (this.props.invalid) return

    if (type === 'office365') {
      if (!formValues.is_admin_consent) return
    } else if (type === 'salesforce') {
      if (formValues.salesforce_authentication_type !== 'preauthentication') return
    }

    if (type === 'office365') {
      const alreadyPreauthenticated = oauth_client.is_verified_admin_consent
      return (
        <div className="form-group dm-form-group">
          <LabelWithTooltip className="dm-title-mini" name="oauthClients.preauthentication.office365.title" />
          <div className="with-indent">
            <LabelWithTooltip name="oauthClients.preauthentication.office365.text" />
            <button
              type="button"
              className="btn btn-warning dm-btn"
              onClick={this.handleAdminConsent}
              disabled={submitting}
            >
              {alreadyPreauthenticated
                ? t('oauthClients.preauthentication.office365.buttons.alreadyAuthenticated')
                : t('oauthClients.preauthentication.office365.buttons.authentication')}
            </button>
          </div>
        </div>
      )
    } else if (type === 'salesforce') {
      const alreadyPreauthenticated = oauth_client.is_verified
      return (
        <div className="form-group dm-form-group">
          <LabelWithTooltip
            className="dm-title-mini"
            name="oauthClients.preauthentication.salesforce.title"
          />
          <div className="with-indent">
            <LabelWithTooltip name="oauthClients.preauthentication.salesforce.text" />
            <button
              type="button"
              className="btn btn-warning dm-btn"
              onClick={this.handleAdminConsent}
              disabled={alreadyPreauthenticated || submitting}
            >
              {alreadyPreauthenticated
                ? t('oauthClients.preauthentication.salesforce.buttons.alreadyAuthenticated')
                : t('oauthClients.preauthentication.salesforce.buttons.authentication')}
            </button>
            {alreadyPreauthenticated && (
              <button type="button" className="btn btn-danger dm-btn" onClick={this.handleDeleteOAuthSession}>
                {t('oauthClients.preauthentication.salesforce.buttons.revoke')}
              </button>
            )}
          </div>
        </div>
      )
    }
  }
}

const OAuthClientEditForm = reduxForm({
  form: 'OAuthClientEdit',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(OAuthClientEdit)

const selector = formValueSelector('OAuthClientEdit')

export const mapStateToProps = (state, props) => {
  const oauth_client = state.entities.oauth_clients[props.params.id] || {}
  const formValues = getFormValues('OAuthClientEdit')(state) || {}

  const defaultValues = {
    type: '',
    endpoint_type: 'original',
  }
  const initialValues = {
    ...defaultValues,
    ...oauth_client,
  }

  if (props.params.id) {
    initialValues.office365_authentication_type = oauth_client.authentication_type
    initialValues.salesforce_authentication_type = oauth_client.authentication_type
  } else {
    initialValues.office365_authentication_type = 'normal'
    initialValues.salesforce_authentication_type = 'normal'
  }

  if (props.params.id) {
    initialValues.callback_url = Config.server + '/oauth/callback'
  }
  return {
    formValues,
    isFetching: isFetching(state),
    oauth_client,
    type: selector(state, 'type'),
    is_admin_consent: selector(state, 'is_admin_consent'),
    tenant_id: selector(state, 'tenant_id'),
    client_id: selector(state, 'client_id'),
    endpoint_type: selector(state, 'endpoint_type'),
    initialValues,
  }
}

export default connect(mapStateToProps)(OAuthClientEditForm)
