import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'

import DataBindingTable from '../../components/common/DataBindingTable'
import { fetchApplications } from '../../actions/application'
import { updateTableState } from '../../actions/table'
import { isFetching } from '../../helpers/selector'

class DeliveryIndex extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    applications: PropTypes.array.isRequired,
    isFetching: PropTypes.bool,
    tableState: PropTypes.object,
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    dispatch(fetchApplications(state.session.token))
  }

  handleRefreshClick = () => {
    const state = this.context.store.getState()
    this.props.dispatch(fetchApplications(state.session.token))
  }

  updateTableState = (path, tableName, tableState) => {
    const { dispatch } = this.props
    dispatch(updateTableState(path, tableName, tableState))
  }

  render() {
    const { t } = this.context
    const { applications, isFetching, tableState } = this.props

    const columns = [
      {
        Header: t('delivery.index.name'),
        id: 'name',
        accessor: 'name',
        minWidth: 108,
      },
      {
        Header: t('delivery.index.bots'),
        id: 'botName',
        accessor: app => app.latest_bot.name,
      },
      {
        Header: t('delivery.index.platform'),
        id: 'platform',
        accessor: app => t(`common.platform.${app.platform}`),
      },
      {
        Header: t('delivery.index.notify'),
        id: 'logsPage',
        style: { textAlign: 'center' },
        accessor: 'id',
        width: 160,
        Cell: props => (
          <Link className="btn btn-primary dm-btn mini" to={`/delivery/${props.value}/jobs`}>
            {t('delivery.index.linkToNotify')}
          </Link>
        ),
        sortable: false,
      },
    ]

    return (
      <div>
        <DataBindingTable
          title={t('delivery.index.title')}
          titleTooltip="delivery.tooltip.index.title"
          items={applications}
          columns={columns}
          isFetching={isFetching}
          tableState={tableState}
          updateTableState={this.updateTableState}
          onRefresh={this.handleRefreshClick}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const ids = state.pagination.applications.ids || []
  const applications = lodash.filter(
    ids.map(id => state.entities.applications[id]),
    'is_published'
  )
  return {
    applications: applications,
    isFetching: isFetching(state),
    tableState: state.table[window.location.pathname],
  }
}

export default connect(mapStateToProps)(DeliveryIndex)
