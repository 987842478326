import lodash from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Field, FieldArray, touch } from 'redux-form'
import { CheckboxField, InputField, SelectField } from '../../components/common/fields/FormFields'
import LabelWithTooltip from '../common/LabelWithTooltip'

import AbstractAction from './AbstractAction'
import SlotTable from './SlotTable'
import TextField from '../common/fields/TextField'

export class SlotFillingAction extends AbstractAction {
  static propTypes = {
    ...AbstractAction.propTypes,
    entities: PropTypes.array.isRequired,
    topics: PropTypes.array.isRequired,
    onChangeSlotDefinition: PropTypes.func.isRequired,
  }

  getTitle() {
    return this.context.t('topic.actionList.slot.title')
  }

  getType() {
    return 'user'
  }

  static validate = action => {
    const errors = super.validate(action)

    if (lodash.compact(action.slots).length === 0) {
      errors.slots = [{ name: 'validate.required', entity_id: 'validate.required' }]
    }

    const slotErrors = action.slots.map(slot => {
      const errors = {}
      if (!slot) return {}

      if (!slot.entity_id) {
        errors.entity_id = 'validate.required'
      }

      if (slot.entity_name === '@persons') {
        try {
          const defaultValue = JSON.parse(slot.default)
          if (!Array.isArray(defaultValue)) errors.default = 'validate.invalidStringArray'
          const hasInvalidType = lodash.some(defaultValue, value => {
            return typeof value !== 'string'
          })
          if (hasInvalidType) errors.default = 'validate.invalidStringArray'
        } catch {
          errors.default = 'validate.invalidStringArray'
        }
      }

      return errors
    })
    if (!lodash.every(slotErrors, lodash.isEmpty)) errors.slots = slotErrors

    if (action.use_reminder) {
      if (!action.reminder_delay) {
        errors.start_timing = 'validate.required'
      } else if (!action.reminder_unit) {
        errors.start_timing = 'validate.unitRequired'
      } else {
        if (!/^\d+$/.test(action.reminder_delay)) {
          errors.start_timing = 'validate.invalid'
        } else {
          const maximums = {
            hours: 72,
            minutes: 4320,
            seconds: 259200,
          }

          const delay = parseInt(action.reminder_delay, 10)
          if (delay < 1) {
            errors.start_timing = { id: 'validate.minimumLimit', values: { minimum: 1 } }
          } else if (delay > maximums[action.reminder_unit]) {
            errors.start_timing = {
              id: 'validate.maximumLimit',
              values: { maximum: maximums[action.reminder_unit] },
            }
          }
        }
      }

      if (!action.reminder_topic_id) {
        errors.reminder_topic_id = 'validate.required'
      }
    }

    return errors
  }

  static getVariables = (action, _props, _context) => {
    return lodash.filter(action.slots, 'name').map(({ name }) => {
      return { variable: name, text: name }
    })
  }

  renderError = field => {
    const { t } = this.context
    const {
      meta: { touched, error },
    } = field
    if (!touched || !error) return null

    if (typeof error === 'object') {
      return <div className="error">{t(error.id, error.values)}</div>
    } else {
      return <div className="error">{t(error)}</div>
    }
  }

  renderReminderTimerFields = field => {
    const { t } = this.context
    const { name, action } = this.props

    const reminderUnits = [
      { id: 'hours', name: t('topic.actionList.slot.reminder.units.hours') },
      { id: 'minutes', name: t('topic.actionList.slot.reminder.units.minutes') },
      { id: 'seconds', name: t('topic.actionList.slot.reminder.units.seconds') },
    ]

    const delayMaximums = {
      hours: 72,
      minutes: 4320,
      seconds: 259200,
    }
    const delayMaximum = delayMaximums[action.reminder_unit] || delayMaximums.seconds

    return (
      <div className="row form-group form-inline with-indent">
        <LabelWithTooltip
          htmlFor={`${name}.reminder_delay`}
          className="control-label col-xs-12 col-sm-5 col-lg-4"
          name="topic.actionList.slot.reminder.timing"
        />
        <div className="col-xs-12 col-sm-6 col-md-7">
          <div className="timer">
            <div className="timer-fields">
              {t('topic.actionList.slot.reminder.beforeLabel') && (
                <label className="vertical-centering">
                  {t('topic.actionList.slot.reminder.beforeLabel')}
                </label>
              )}
              <Field
                type="number"
                name={`${name}.reminder_delay`}
                id={`${name}.reminder_delay`}
                className="form-control dm-form-control text-right"
                component={InputField}
                onBlur={() => field.meta.dispatch(touch('TopicEdit', field.input.name))}
                min="1"
                max={delayMaximum}
              />
              <Field
                name={`${name}.reminder_unit`}
                id={`${name}.reminder_unit`}
                className="form-control dm-form-control"
                component={SelectField}
                items={reminderUnits}
                valueKey="id"
                displayKey="name"
                onBlur={() => field.meta.dispatch(touch('TopicEdit', field.input.name))}
                empty="true"
              />
              {t('topic.actionList.slot.reminder.afterLabel') && (
                <label className="vertical-centering">{t('topic.actionList.slot.reminder.afterLabel')}</label>
              )}
            </div>
            <Field name={field.input.name} component={this.renderError} />
          </div>
        </div>
      </div>
    )
  }

  static STRONG_TYPES = ['@text', '@number', '@alphanumeric-symbol']

  renderBody() {
    const { t } = this.context
    const { name, action, entities, topics, onChangeSlotDefinition } = this.props

    const confirmationTypes = [
      { id: 'do_not', name: t('topic.actionList.slot.confirmationTypeList.doNot') },
      { id: 'each_slot', name: t('topic.actionList.slot.confirmationTypeList.eachSlot') },
      { id: 'entire_slots', name: t('topic.actionList.slot.confirmationTypeList.entireSlots') },
    ]

    // Get list of weak type names
    const weakTypes = lodash.map(
      lodash.reject(action.slots, x => !x || SlotFillingAction.STRONG_TYPES.includes(x.entity_name)),
      'entity_name'
    )
    const weakTypesExceptConflict =
      weakTypes.includes('@person') && weakTypes.includes('@persons')
        ? lodash.reject(weakTypes, type => ['@person', '@persons'].includes(type))
        : weakTypes
    const countByType = lodash.countBy(weakTypesExceptConflict)
    const hasWeakType = lodash.some(countByType, v => v === 1)

    return (
      <div className="slot_filling">
        <div className="form-group">
          <LabelWithTooltip htmlFor="question" name="topic.actionList.slot.question" />
          <TextField
            name={`${name}.question`}
            className="form-control"
            isTextArea={false}
            nullable={true}
            disabled={!hasWeakType || action.skip_question}
          />
          {!hasWeakType && (
            <>
              <label className="dm-note gray">{t('topic.actionList.slot.questionNote')}</label>&nbsp;
              <a
                href="https://guide.dialogplay.jp/hc/ja/articles/360050204932"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('common.detail')}
              </a>
            </>
          )}
        </div>
        <div className="form-group">
          <LabelWithTooltip htmlFor="slots" name="topic.actionList.slot.slots" />
          <FieldArray
            t={t}
            name={`${name}.slots`}
            slots={action.slots}
            entities={entities}
            onChange={onChangeSlotDefinition}
            skipQuestion={action.skip_question}
            component={SlotTable}
          />
        </div>
        <div className="form-group">
          <LabelWithTooltip htmlFor="confirmation_type" name="topic.actionList.slot.confirmationType" />
          <Field
            name={`${name}.confirmation_type`}
            items={confirmationTypes}
            valueKey="id"
            displayKey="name"
            className="form-control"
            component={SelectField}
          />
        </div>
        <div className="form-group form-inline">
          <Field
            name={`${name}.skip_question`}
            id={`${name}.skip_question`}
            type="checkbox"
            component={CheckboxField}
            className="form-control"
          />
          <LabelWithTooltip htmlFor={`${name}.skip_question`} name="topic.actionList.slot.skipQuestion" />
        </div>
        <div className="form-group form-inline">
          <Field
            name={`${name}.use_reminder`}
            id={`${name}.use_reminder`}
            type="checkbox"
            component={CheckboxField}
            className="form-control"
          />
          <LabelWithTooltip htmlFor={`${name}.use_reminder`} name="topic.actionList.slot.reminder.title" />
        </div>
        {action.use_reminder && (
          <React.Fragment>
            <Field name={`${name}.start_timing`} component={this.renderReminderTimerFields} />
            <div className="row form-group form-inline with-indent">
              <LabelWithTooltip
                htmlFor={`${name}.reminder_topic_id`}
                className="control-label col-xs-12 col-sm-5 col-lg-4"
                name="topic.actionList.slot.reminder.targetScenario"
              />
              <div className="form-inline col-xs-12 col-sm-6 col-md-7">
                <Field
                  name={`${name}.reminder_topic_id`}
                  id={`${name}.reminder_topic_id`}
                  className="form-control dm-form-control"
                  component={SelectField}
                  items={topics}
                  valueKey="id"
                  displayKey="name"
                  empty="true"
                />
              </div>
            </div>
          </React.Fragment>
        )}
        <div className="form-group form-inline">
          <Field
            name={`${name}.skip_known_memories`}
            id={`${name}.skip_known_memories`}
            type="checkbox"
            component={CheckboxField}
            className="form-control"
          />
          <LabelWithTooltip
            htmlFor={`${name}.skip_known_memories`}
            name="topic.actionList.slot.skipKnownMemories"
          />
        </div>
      </div>
    )
  }
}

export default SlotFillingAction
